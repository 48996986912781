import AccountCircleIcon from 'assets/SVGIcons/AccountCircleIcon';
import AccountTreeIcon from 'assets/SVGIcons/AccountTreeIcon';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SettingIcon from 'assets/SVGIcons/SettingIcon';
import { ROLES } from 'core/constants/roles';
import { AuthContextType, useAuth } from 'contexts/AuthProvider';
import AuthLayout from 'layout/AuthLayout';
import * as React from 'react';
import { Outlet } from 'react-router-dom';

interface IPEPAdminLayoutProps {
  children?: React.ReactNode;
}

export default function PEPAdminLayout(props: IPEPAdminLayoutProps) {
  const menu = [
    {
      url: '/pep/dashboard',
      label: 'Home',
      icon: <HomeOutlinedIcon />,
    },
    {
      url: '/pep/manage-clients',
      label: 'Manage Clients',
      icon: <AccountCircleIcon />,
    },
    {
      url: '/pep/manage-master-plans',
      label: 'Manage Master Plans',
      icon: <AccountTreeIcon />,
    },
    {
      url: '/pep/account-settings',
      label: 'Account Settings',
      icon: <SettingIcon />,
    },
  ];

  const { session } = useAuth() as AuthContextType;

  return (
    <AuthLayout menu={menu}>
      {session && session.currentRole === ROLES.PEP_ADMIN && (
        <>
          <Outlet />
        </>
      )}
    </AuthLayout>
  );
}
