import React, { ReactNode, useEffect, useState } from 'react';
import MuiTextField, { TextFieldProps as MUITextFieldProps } from '@mui/material/TextField';
import { forwardRef } from 'react';
import { FormHelperText, FormLabelProps, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { appPalette } from 'core/constants';
import { FormControl } from 'common/components';
import useId from '@mui/material/utils/useId';

type TextFieldProps = {
  error?: ReactNode | string | boolean;
  setValue: Function;
  value: string;
  registerForm?: any;
  formLabelProps?: FormLabelProps;
  required?: boolean;
  formLabelStyle?: any;
} & Omit<MUITextFieldProps, 'error'>;

export const TextFieldSSN = forwardRef(
  (
    {
      className,
      setValue,
      value,
      error,
      registerForm,
      label,
      id: propId,
      formLabelProps,
      required,
      formLabelStyle = {},
      sx = {},
      ...props
    }: TextFieldProps,
    ref
  ) => {
    const [display, setDisplay] = useState<string>('');
    const [show, setShow] = useState<boolean>(false);
    const id = useId(propId);

    useEffect(() => {
      setDisplay(replaceNumber(value));
    }, [value]);

    const onSSNChange = (text: string) => {
      if (display.length === 11 && text.length > 11) {
        return;
      }
      let appendText = '';

      if (display.length === 0) {
        const countItem = 9;
        for (let i = 0; i < countItem; i++) {
          if (isNumber(text[i])) {
            if (appendText.length === 3 || appendText.length === 6) {
              appendText += `-${text[i]}`;
            } else {
              appendText += `${text[i]}`;
            }
          }
        }
        setDisplay(`${display}${replaceNumber(appendText)}`);
        setValue(`${value}${appendText}`);
      } else if (text.length > display.length) {
        const countItem = 9 - display.replaceAll('-', '').length;
        const lastChars = text.slice(display.length, text.length);
        for (let i = 0; i < countItem; i++) {
          if (isNumber(lastChars[i])) {
            if (display.length + appendText.length === 3 || display.length + appendText.length === 6) {
              appendText += `-${lastChars[i]}`;
            } else {
              appendText += `${lastChars[i]}`;
            }
          }
        }
        setDisplay(`${replaceNumber(`${display}${appendText}`)}`);
        setValue(`${value}${appendText}`);
      } else {
        const last = text[text.length - 1];
        let temp = text;
        if ((text.length === 4 || text.length === 7) && isNumber(last)) {
          temp = `${text.slice(0, text.length - 1)}-${last}`;
        }
        let newValue = value.slice(0, temp.length);
        if (isNumber(last) && last !== value[temp.length]) {
          newValue = `${value.slice(0, temp.length - 1)}${last}`;
        } else {
          newValue = value.slice(0, temp.length);
        }
        setDisplay(replaceNumber(newValue));
        setValue(newValue);
      }
    };

    const replaceNumber = (value: string) => {
      if (display.length + value.length > 7) {
        const first = value.slice(0, 7).replace(/[0-9]/g, 'X');
        const second = value.slice(7, value.length + 1);
        return `${first}${second}`;
      }
      return value.replace(/[0-9]/g, 'X');
    };

    const isNumber = (value: string) => {
      return value >= '0' && value <= '9';
    };

    return (
      <FormControl
        label={label}
        id={id}
        fullWidth
        formLabelProps={formLabelProps}
        sx={{
          '.MuiFormLabel-root': {
            maxHeight: '16px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        }}
        required={required}
        {...formLabelStyle}
      >
        <MuiTextField
          inputRef={ref}
          fullWidth
          error={!!error}
          color="secondary"
          sx={{
            'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
              WebkitAppearance: 'none',
              margin: 0,
            },
            '.MuiOutlinedInput-input.MuiInputBase-input': {
              fontWeight: 600,
              color: (theme) => theme.palette.darkest.dark,
              '&::placeholder': {
                fontWeight: 600,
                fontSize: 13,
                color: (theme) => theme.palette.subTitle,
              },
            },
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: (theme) => theme.palette.borderColor,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: (theme) => `${theme.palette.mediumGreen} !important`,
            },
            '.Mui-disabled': {
              WebkitTextFillColor: appPalette.beige,
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: (theme) => `${theme.palette.mediumGrey} !important`,
              },
            },
            '.Mui-disabled.Mui-error': {
              WebkitTextFillColor: appPalette.beige,
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: (theme) => `${theme.palette.errorColor} !important`,
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: (theme) => `${theme.palette.errorColor} !important`,
              },
            },
            'input[type=number]': {
              MozAppearance: 'textfield',
            },
            'input[type=text]:disabled': {
              cursor: 'not-allowed',
            },
            'input::-ms-reveal': {
              display: 'none',
            },
            ...sx,
          }}
          onChange={(e) => {
            onSSNChange(e.target.value);
          }}
          onPaste={(e) => {
            e.preventDefault();
            const value = e.clipboardData.getData('Text').replaceAll(/(?!-)[^0-9]/g, '');

            onSSNChange(`${display}${value}`);
          }}
          value={show ? value : display}
          className={className}
          placeholder="SSN(000-00-0000)"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle ssn visibility"
                  onClick={() => setShow(!show)}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  {show ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...registerForm}
          {...props}
        />
        {error && typeof error !== 'boolean' && (
          <FormHelperText className="text-errorColor ml-0">{error}</FormHelperText>
        )}
      </FormControl>
    );
  }
);
