import { IPersonconfig } from './peopleDto.model';
import { EmployeeStatus } from 'core/enums/employee-status.enum';
import { Moment } from 'moment';
import { FormInstitution } from './EmployeeEditBeneficiary.model';
import { BankAccountDto } from './companyDto.model';
import { IPlanAdmin } from 'states/pepPlanAdminSlice';
import { SortEnum } from 'core/enums/pagination.enum';
import { Filter } from './pagination.model';

export interface EmployeeResponseSuccessDto {
  personId: string;
  fullName: string;
  userProfileId: string;
  email?: string;
  phone?: string;
  workEmail?: string;
  cpaAdmin?: boolean;
}

interface IFinchEmployeeDto {
  individualId: string;
}

export interface EmployeeDetailDto {
  createdOn: number | null;
  updatedOn: number | null;
  createdBy: string | null;
  updatedBy: string | null;
  id: string;
  userName: string;
  phone: string;
  address: EmployeeAddressDto;
  email: string;
  firstName: string;
  lastName: string;
  dob: number | null;
  user: boolean;
  pictureUrl?: string | null;
  ssn: string;
  tokenExpiresOn?: string | null;
  token?: string | null;
  role: { [id: string]: string[] };
  systemAdmin?: boolean | null;
  systemAdminRole?: string | null;
  cpaAdmin?: boolean | null;
  lastLogin: number | null;
  status: string;
  otpexpiresOn?: string;
  workEmail?: string;
  finch?: IFinchEmployeeDto;
}

interface EmployeeAddressDto {
  street: string;
  city: string;
  state: string;
  zip: string;
  zipPlus4: string | null;
}

export interface EmployeeDetailsForm {
  maritalStatus: string;
  numberOfDependents: number;
  numberOfChildren: number;
  person: {
    userName: string;
    firstName: string;
    lastName: string;
    email: string;
    dob: string;
    phone?: string;
    address: {
      street: string;
      streetOne: string;
      streetTwo: string;
      city: string;
      state: string;
      zip?: string;
      zipPlus4?: string;
    };
    personalEmail: string;
  };
  workEmail: string;
  workEmailPrimary: string;
}

export interface EmployeePasswordForm {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface VerifyCodeForm {
  verifyCode: string;
}

export interface EnabledTwoFARequest {
  personId: string;
  method: string;
  phoneNumber?: string;
}

export interface VerifiedTwoFARequest {
  action: string;
  data: {
    code: string;
    method: string;
  }[];
}

export interface VerifiedSMSCodeRequest extends VerifiedTwoFARequest {}

enum TermAndConditionsType {
  KAPITALL_ADV_PART_3CRS = 'KAPITALL_ADV_PART_3CRS',
  WEALTHYX_INVESTMENT_ADVISORY_AGREEMENT = 'WEALTHYX_INVESTMENT_ADVISORY_AGREEMENT',
  SAVE_FEE_DISCLOSURE = 'SAVE_FEE_DISCLOSURE',
}
export interface TermAndConditionDTO {
  id: string;
  name: string;
  url: string;
  fileType: TermAndConditionsType;
}
export interface EmployeeProfileDto {
  id?: string;
  personId?: string;
  companyId?: string;
  numberOfChildren?: number;
  numberOfDependents?: number;
  maritalStatus?: string;
  person?: {
    userName?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    dob?: string;
    phone?: string;
    phone2?: string;
    maritalStatus?: string;
    ssn?: string;
    termsAndConditions?: TermAndConditionDTO[];
    address?: {
      street?: string;
      city?: string;
      state?: string;
      zip?: string;
      zipPlus4?: string;
    };
    workEmailPrimary?: boolean;
    personalEmail?: string;
  };
  primaryBeneficiary?: FormInstitution[];
  secondaryBeneficiary?: FormInstitution[];
  status?: EmployeeStatus | string;
  workEmail?: string;
  autoEnroll?: boolean;
  traditionalAccountId?: string;
  rothAccountId?: string;
  planType?: string;
  ssn?: string;
  autoEscalate?: boolean;
  autoEscalatePercentage?: number;
  bankAccount?: BankAccountDto;
  workEmailPrimary?: boolean;
  autoRebalance?: boolean;
  autoEnrollmentType?: string;
  showAgeBasedPortfolioReview?: boolean;
  remindNextYear?: boolean;
  onboardingLatestStage?: string;
  highlyCompensated?: boolean;
  employmentType?: string | null;
}

export interface EmployeeProfileResponse {
  id?: string;
  personId?: string;
  companyId?: string;
  numberOfChildren?: number;
  numberOfDependents?: number;
  maritalStatus?: string;
  person: {
    userName?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    dob?: string;
    phone?: string;
    phone2?: string;
    maritalStatus?: string;
    ssn?: string;
    termsAndConditions?: TermAndConditionDTO[];
    address: {
      street?: string;
      city?: string;
      state?: string;
      zip?: string;
      zipPlus4?: string;
    };
    workEmailPrimary?: boolean;
    personalEmail?: string;
  };
  primaryBeneficiary?: FormInstitution[];
  secondaryBeneficiary?: FormInstitution[];
  status?: EmployeeStatus;
  workEmail?: string;
  autoEnrolled?: boolean;
  eligible?: boolean;
  traditionalAccountId?: string;
  rothAccountId?: string;
  planType?: string;
  ssn?: string;
  autoEscalate?: boolean;
  autoEscalatePercentage?: number;
  bankAccount?: BankAccountDto;
  workEmailPrimary?: boolean;
  autoRebalance?: boolean;
  autoEnrollmentType?: string;
  showAgeBasedPortfolioReview?: boolean;
  remindNextYear?: boolean;
  onboardingLatestStage?: string;
  spousalConsent?: string;
  spousalConsentLink?: string;
  portfolioId?: string;
  rothContributionPercentage?: number;
  traditionalContributionPercentage?: number;
}

interface EmployeeDetailForm {
  annualSalary: number;
  dateOfHire: Moment | null;
  dateOfTermination: Moment | null;
  principal?: string;
  person: {
    address: {
      city: string;
      state: string;
      zip: string;
      zipPlus4?: string;
      street: string;
    };
    dob: Moment | null;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    ssn: string;
  };
  workEmail?: string;
}

export interface PendingEmployeeDetailForm {
  annualSalary: string | number;
  dateOfHire: string | null | Moment;
  dateOfTermination: string | null | Moment;
  principal: string | boolean;
  address: {
    city: string;
    state: string;
    zip: string;
    zipPlus4?: string | null | Moment;
    street: string;
  };
  dob: string | null | Moment;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  ssn?: string;
}

export interface EditProfileDto
  extends Omit<EmployeeDetailForm, 'annualSalary' | 'dateOfHire' | 'dateOfTermination' | 'person' | 'principal'> {
  id?: string;
  annualSalary?: string;
  dateOfHire?: string;
  dateOfTermination?: string;
  person?: {
    dob?: string;
    address?: {
      city?: string;
      state?: string;
      zip?: string;
      zipPlus4?: string;
      street?: string;
    };
    email?: string;
    firstName?: string;
    lastName?: string;
    phone?: string;
    ssn?: string;
    personalEmail?: string;
  };
  status?: string;
  workEmail?: string;
  portfolioId?: string;
  principal?: boolean;
  personId?: string;
  companyId?: string;
  workEmailPrimary?: boolean;
  personalEmail?: string;
  autoEscalatePercentage?: number;
}

export interface PeoplePayload {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  dob?: string;
  phone?: string;
  ssn?: string;
  address?: {
    street?: string;
    city?: string;
    state?: any;
    zip?: string;
    zipPlus4?: string;
  };
  status?: string | EmployeeStatus;
  userName?: string;
  workEmail?: string;
  workEmailPrimary?: boolean;
  role?: { [key: string]: string[] };
  personalEmail?: string;
  personalConfig?: IPersonconfig;
}

export interface EmployeeProfilePayload {
  id?: string;
  autoEscalate?: boolean;
  autoEscalatePercentage?: number;
  planType?: string;
  maritalStatus?: string | null;
  numberOfChildren?: number;
  numberOfDependents?: number;
  portfolioId?: string;
  primaryBeneficiary?: FormInstitution[];
  secondaryBeneficiary?: FormInstitution[];
  status?: string;
  workEmail?: string;
  workEmailPrimary?: boolean;
  autoRebalance?: boolean;
  autoEnrollmentType?: string;
  person?: PeoplePayload;
  spousalConsent?: string;
}

export interface ChangePasswordPayload {
  action: string;
  newPassword: string;
  person: {
    id: string;
    password: string;
  };
}

export interface IUserProfileResponse {
  id: string;
  companyId: string;
  cpaAdmin: boolean;
  workEmail: string;
  personId: string;
  person: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
  };
}

export interface EmployeeError {
  type?: 'normal' | 'change-password';
  message?: string;
}

export interface PendingEmployeeResponse {
  createdOn: number | null;
  updatedOn: number | null;
  createdBy: string | null;
  updatedBy: string | null;
  id: string;
  finchIndividualId: string;
  firstName: string;
  lastName: string;
  ssn: string | null;
  dob: number;
  email: string;
  phone: string;
  dateOfTermination: string | null;
  dateOfHire: number;
  address: {
    street: string;
    city: string;
    state: string;
    zip: string;
    zipPlus4: string | null;
  };
  principal: boolean;
  annualSalary: number | null;
  finalizedPersonId: string | null;
  finalizedUserProfileId: string | null;
  companyId: string;
  finalizedDate: string | null;
  pendingUserProfile: boolean;
  duplicateEmail: boolean;
  pendingPerson: boolean;
  active: boolean;
}

export interface IFilterListEmployee {
  pageSize?: number;
  page?: number;
  search?: string;
  order?: SortEnum | string;
  field?: string;
  profileStatus?: string[];
}

export interface ISearchParticipantQuery {
  pageSize?: number;
  page?: number;
  search?: string;
  companyType: string;
  ignoreAdmin?: boolean | string;
}

interface IChangeConfirmationRequest {
  name: string;
  signedDate: string;
  signature: string;
}

export interface IAuthorizedSignatoryRequest extends IPlanAdminRequest {
  id?: string;
  personId: string;
  reason: string;
  ssn: string;
}
export interface IPlanAdminRequest {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  jobTitle?: string;
}

export interface IChangeAuthorizedSignatoryRequest {
  authorizedSignatory: IAuthorizedSignatoryRequest;
  confirmation: IChangeConfirmationRequest;
}
export interface ICreatePlanAdminsRequest {
  payload: {
    admins: IPlanAdminRequest[];
    confirmation: IChangeConfirmationRequest;
  };
  companyType: string;
  companyId?: string;
}

export interface ICreateUpdateBasicPlanAdmin extends IFilterListEmployee {
  companyId: string;
  admins: IPlanAdmin[];
}

export interface IFinchProvidersFilter extends Omit<IFilterListEmployee, 'profileStatus'> {
  providers?: string;
  companyId: string;
}

export interface IRecoveryAccountPayload {
  action: string;
  person: {
    userName: string;
    password: string;
  };
  token: string;
}

export enum TypeFeedbackEnum {
  RATING = 'RATING',
  QUESTION = 'QUESTION',
  INPUT = 'INPUT',
}
export interface IFeedbackItem {
  title?: string;
  description?: string;
  value?: string;
  type: TypeFeedbackEnum;
  id?: string;
  rating?: number;
}

export interface IPortfolioHistoryItem {
  dateOfChange: number | string;
  changeBy: string;
  oldPortfolio: string;
  newPortfolio: string;
  updatedOn: number | string;
  id: string;
}

export interface EmployeePortfolioHistoryResponse<T> {
  content: T[];
  totalPages: number;
  totalElements: number;
  last: boolean;
  size: number;
  number: number;
  numberOfElements: number;
  first: boolean;
  empty: boolean;
  pageable: {
    sort: {
      empty: boolean;
      unsorted: boolean;
      sorted: boolean;
    };
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
  };
  sort: {
    empty: boolean;
    unsorted: boolean;
    sorted: boolean;
  };
}

export interface UserProfileDashboardDto {
  contributions?: Contributions;
  balances?: Balances;
  performance?: Performance;
  contributionLimit?: number;
  traditionalGraphValues?: GraphValues;
  rothGraphValues?: GraphValues;
}

interface Contributions {
  allTimeEmployer?: number;
  allTimeEmployee?: number;
  allTimeTotal?: number;
  ytdEmployer?: number;
  ytdEmployee?: number;
  ytdTotal?: number;
}

interface Balances {
  total?: number;
  vested?: number;
  unvested?: number;
  rothBalance?: number;
  traditionalBalance?: number;
}

interface Performance {
  totalRateOfReturn?: number;
  totalGainsAndLosses?: number;
  ytdRateOfReturn?: number;
  ytdGainsAndLosses?: number;
}

interface GraphValues {
  ytd?: TimeData[];
  oneYear?: TimeData[];
  allTime?: TimeData[];
}
interface TimeData {
  month?: number;
  year?: number;
  day?: number;
  balance?: number;
}

export interface IPartnerItem {
  id: string;
  companyName: string;
  activeProfile: number;
  createdOn: number;
  status: string;
  totalContribution: number;
  totalProfile: number;
  ytdTotalContribution: number;
  type: string;
}

export interface IClientItem extends IPartnerItem {
  partner: Partial<IPartnerItem>;
}

export interface IClientPartnerFilter extends Filter {
  partnerName: string;
}
