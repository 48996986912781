import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PlanStatus } from 'core/constants/manage-plan-data';
import { REQUEST_STATUS } from 'core/constants/roles';
import { CompanyDto, EditCompanyDto } from 'core/models/companyDto.model';
import {
  ICreatePlanAdminsRequest,
  ICreateUpdateBasicPlanAdmin,
  IFinchProvidersFilter,
} from 'core/models/employeeDto.model';
import { PlanDetailDto } from 'core/models/PlanDetailDto.model';
import {
  createPlanAdmins,
  editCompanyDetails,
  getCompanyDetails,
  getPlanAdmins,
  getPlanDetail,
  updatePlanAdmins,
  getCompanyFinchProviders,
} from 'core/services/api.service';
import { IPlanAdmin } from 'states/pepPlanAdminSlice';
import { RootState } from 'states/store';

interface CompanyDetailsState {
  companyDetails: CompanyDto | null;
  isErisaBondModalOpen: boolean;
  isPlanActive: boolean;
  planDetails: PlanDetailDto | null;
  admins: IPlanAdmin[];
  finchProviders: any;
  getFinchProvidersStatus: REQUEST_STATUS;
  getCompanyDetailsStatus: REQUEST_STATUS;
}

const initialState: CompanyDetailsState = {
  companyDetails: null,
  isErisaBondModalOpen: false,
  isPlanActive: false,
  planDetails: null,
  admins: [],
  finchProviders: null,
  getFinchProvidersStatus: REQUEST_STATUS.IDLE,
  getCompanyDetailsStatus: REQUEST_STATUS.IDLE,
};

export const getCompanyDetailsAsync = createAsyncThunk(
  'companyDetails/getCompanyDetailsAsync',
  async (companyId: { id: string }): Promise<CompanyDto> => {
    const { id } = companyId;
    const response = await getCompanyDetails(id);
    return response.data;
  }
);

export const editCompanyDetailsAsync = createAsyncThunk(
  'companyDetails/editCompanyDetailsAsync',
  async (params: { editCompanyDetailsBody: EditCompanyDto; filter?: any }): Promise<CompanyDto> => {
    const { editCompanyDetailsBody, filter } = params;
    const response = await editCompanyDetails({
      editCompanyDetailsBody,
      filter,
    });
    return response.data;
  }
);

export const getPlanDetailAsync = createAsyncThunk(
  'companyDetails/getPlanDetailAsync',
  async (planDetailId: string): Promise<any> => {
    const response = await getPlanDetail(planDetailId);
    return response.data;
  }
);

export const createPlanAdminsAsync = createAsyncThunk(
  'companyDetails/createPlanAdminsAsync',
  async (body: ICreatePlanAdminsRequest): Promise<any> => {
    const response = await createPlanAdmins(body);
    return response.data;
  }
);

export const getPlanAdminsAsync = createAsyncThunk(
  'companyDetails/getPlanAdminsAsync',
  async (companyId: string): Promise<any> => {
    const response = await getPlanAdmins(companyId);
    return response.data;
  }
);

export const updatePlanAdminsAsync = createAsyncThunk(
  'companyDetails/updatePlanAdminsAsync',
  async (body: ICreateUpdateBasicPlanAdmin): Promise<any> => {
    const response = await updatePlanAdmins(body);
    return response.data;
  }
);

export const getFinchProvidersAsync = createAsyncThunk(
  'companyDetails/getFinchProviders',
  async (filter: IFinchProvidersFilter): Promise<any> => {
    const response = await getCompanyFinchProviders(filter);
    return response.data;
  }
);

const companyDetailsSlice = createSlice({
  name: 'companyDetails',
  initialState,
  reducers: {
    setIsPlanActive: (state, action) => {
      state.isPlanActive = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanyDetailsAsync.fulfilled, (state, action) => {
        state.companyDetails = action.payload;
        state.getCompanyDetailsStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(getCompanyDetailsAsync.rejected, (state) => {
        state.getCompanyDetailsStatus = REQUEST_STATUS.ERROR;
      })
      .addCase(getCompanyDetailsAsync.pending, (state) => {
        state.getCompanyDetailsStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(editCompanyDetailsAsync.fulfilled, (state, action) => {
        state.companyDetails = action.payload;
      })
      .addCase(getPlanDetailAsync.fulfilled, (state, action) => {
        if (!!action.payload.length) {
          const { status } = action.payload[0];
          if (status === PlanStatus.ACTIVE) {
            state.isPlanActive = true;
            state.planDetails = action.payload[0];
          }
        }
      })
      .addCase(getPlanAdminsAsync.fulfilled, (state, action) => {
        state.admins = action.payload;
      })
      .addCase(updatePlanAdminsAsync.fulfilled, (state, action) => {
        state.admins = action.payload;
      })
      .addCase(getFinchProvidersAsync.fulfilled, (state, action) => {
        state.finchProviders = action.payload;
        state.getFinchProvidersStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(getFinchProvidersAsync.pending, (state) => {
        state.getFinchProvidersStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(getFinchProvidersAsync.rejected, (state) => {
        state.getFinchProvidersStatus = REQUEST_STATUS.ERROR;
      });
  },
});

export const { setIsPlanActive } = companyDetailsSlice.actions;

export const selectCompanyDetails = (state: RootState) => state.companyDetails.companyDetails;
export const selectIsPlanActive = (state: RootState) => state.companyDetails.isPlanActive;

const companyDetailsReducer = companyDetailsSlice.reducer;
export default companyDetailsReducer;
