export function numToString(num: number): string {
  if (num < 1000) {
    return num.toString();
  }
  const isK = num < 1000000;
  const value = Math.round((num / (isK ? 1000 : 1000000)) * 10) / 10;
  return value.toFixed(Number.isSafeInteger(value) ? 0 : 1) + (isK ? "K" : "M");
}
export function formatCurrency(num: number): string {
  return new Intl.NumberFormat("us-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    currencyDisplay: "narrowSymbol",
  }).format(num);
}
