import ExpandMore from '@mui/icons-material/ExpandMore';
import MUIAutocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { SelectItem, TextField } from 'common/components';
import { CircularProgress } from '@mui/material';
import React, { ReactNode, forwardRef, useEffect, useMemo, useRef } from 'react';
import { appPalette } from 'core/constants';
import _ from 'lodash';
import clsx from 'clsx';
interface ISortable {
  field: 'value' | 'label';
  order?: 'desc' | 'asc';
}

type IAutocompleteProps = {
  label?: ReactNode | string;
  options?: SelectItem[];
  error?: any;
  value?: any;
  placeholder?: string;
  totalElements: number;
  onLoadMoreData?: Function;
  sortable?: ISortable | boolean;
  required?: boolean;
} & Omit<AutocompleteProps<SelectItem, boolean | undefined, boolean | undefined, boolean | undefined>, 'renderInput'>;

export const Autocomplete = forwardRef(
  (
    {
      label,
      error,
      options,
      placeholder,
      value,
      loading,
      totalElements,
      onLoadMoreData,
      sortable,
      required,
      ...props
    }: IAutocompleteProps,
    ref
  ) => {
    const observerItem = useRef(null);

    useEffect(() => {
      return () => {
        observerItem.current = null;
      };
    }, []);

    const observerEvent = (node: any, callback: any, observe: any, threshold: any) => {
      if (observe.current) {
        observe.current.disconnect();
      }

      observe.current = new IntersectionObserver(async (entries) => callback(entries), threshold);

      if (node) {
        observe.current.observe(node);
      }
    };

    const onLoadMore = (entries: any) => {
      if (loading) return;

      const observed = entries[0];
      const shouldLoadMore = observed.isIntersecting && options.length < totalElements;

      if (shouldLoadMore && onLoadMoreData) {
        onLoadMoreData();
      }
    };

    const formatOptions = useMemo(() => {
      if (!options.length) return [];
      const filterOptionsLabel = options.filter((option) => option?.label);
      if (typeof sortable === 'boolean' && sortable) {
        return _.orderBy(filterOptionsLabel, [(option) => option['label']?.trim()?.toLowerCase()], 'asc');
      }
      if (sortable && Object.keys(sortable).length) {
        const { order = 'asc', field } = sortable;
        if (!field) return filterOptionsLabel;
        return _.orderBy(filterOptionsLabel, [(option) => option[field]?.trim()?.toLowerCase()], order);
      }
      return filterOptionsLabel;
    }, [options]);

    return (
      <MUIAutocomplete
        sx={(theme) => ({
          '.MuiFormLabel-root': {
            color: theme.palette.primaryColor,
            fontSize: 13,
          },
          '.MuiInputBase-root': {
            padding: '8px 32px 8px 16px',
          },
          '.MuiAutocomplete-input': {
            padding: '0 !important',
            fontSize: 13,
            fontWeight: 600,
            color: `${theme.palette.darkest.dark} !important`,
            '&::placeholder': {
              color: theme.palette.subTitle,
              fontWeight: 600,
              fontSize: 13,
            },
          },
          '.MuiSvgIcon-root': {
            color: (theme) => theme.palette.darkGreen,
          },
        })}
        getOptionLabel={(option) => (typeof option !== 'string' ? option.label : option)}
        disablePortal
        renderOption={(props, option, state) => {
          return (
            <li
              {...props}
              key={option.id || option.value}
              className={clsx(
                props.className,
                'text-darkGreen py-1 px-4 text-sm hover:bg-frameBackground hover:cursor-pointer'
              )}
              ref={
                options.length - 1 === state.index
                  ? (e) =>
                      observerEvent(e, onLoadMore, observerItem, {
                        rootMargin: '0px 0px 100px 0px',
                      })
                  : null
              }
            >
              {option.label}
            </li>
          );
        }}
        options={formatOptions}
        noOptionsText={'No properties'}
        popupIcon={<ExpandMore />}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              ref={ref}
              label={label}
              placeholder={placeholder}
              error={!!error}
              helperText={error}
              required={required}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress
                        style={{
                          color: appPalette.secondary,
                          width: '24px',
                          height: '24px',
                        }}
                      />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          );
        }}
        {...props}
      />
    );
  }
);
