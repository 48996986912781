import { EMPLOYEE_SCREENS } from 'core/constants';
import { CookieNames } from 'core/enums/cookie-names.enum';
import { getCookie, setCookie } from 'core/services/cookie.service';
import { Dispatch, SetStateAction } from 'react';
import { OnboardingEmployeeStages } from 'core/enums';
import CreateYourLoginWithToken from './CreateYourLoginWithToken';
import VerifyYourLogin from './VerifyYourLogin';
import CreateYourLoginWithInfo from './CreateYourLoginWithInfo';
import { useSearchParams } from 'react-router-dom';
import OptOutWithGenericURL from './OptOutWithGenericURL';

export const employeeStageToScreen = (stage: string) => {
  switch (stage) {
    case OnboardingEmployeeStages.EP_CREATE_CREDENTIAL:
      return EMPLOYEE_SCREENS.CreateYourLogin;

    case OnboardingEmployeeStages.ACCOUNT_PREFERENCES:
      return EMPLOYEE_SCREENS.AccountPreference;

    case OnboardingEmployeeStages.PORTFOLIO_ALLOCATE_PREFERENCE:
      return EMPLOYEE_SCREENS.PortfolioPreference;

    case OnboardingEmployeeStages.PORTFOLIO_ALLOCATE_RECOMMENDATION:
      setCookie(CookieNames.OnboardingStep, EMPLOYEE_SCREENS.PortfolioRecommendation);
      return EMPLOYEE_SCREENS.PortfolioRecommendation;

    case OnboardingEmployeeStages.SET_CONTRIBUTION_AMOUNT:
      return EMPLOYEE_SCREENS.SetContributionAmount;

    case OnboardingEmployeeStages.TERM_AND_CONDITION:
      return EMPLOYEE_SCREENS.TermAndConditionsEmployee;
    default:
      return EMPLOYEE_SCREENS.CreateYourLogin;
  }
};

type Props = {
  setStep: Dispatch<SetStateAction<number>>;
};

const CreateYourLoginNew: React.FC<Props> = ({ setStep }) => {
  const view = getCookie(CookieNames.OnboardingCreateYourLoginCurrentView) || 'loginInfo';
  const [searchParams] = useSearchParams();

  return (
    <>
      {searchParams.has('optOut') && !searchParams.has('return') && <OptOutWithGenericURL setStep={setStep} />}
      {view === 'loginToken' && !searchParams.has('optOut') && !searchParams.has('return') && (
        <CreateYourLoginWithToken setStep={setStep} />
      )}
      {view === 'loginInfo' && !searchParams.has('optOut') && !searchParams.has('return') && (
        <CreateYourLoginWithInfo setStep={setStep} getStep={employeeStageToScreen} />
      )}
      {searchParams.has('return') && <VerifyYourLogin setStep={setStep} getStep={employeeStageToScreen} />}
    </>
  );
};

export default CreateYourLoginNew;
