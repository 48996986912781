import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { Link, useNavigate, useRouteError } from 'react-router-dom';

interface IErrorPageProps {}
interface ErrorResponse {
  status: number;
  statusText: string;
  message?: string;
  data: string;
}

export default function ErrorPage(props: IErrorPageProps) {
  const navigate = useNavigate();
  const error: ErrorResponse = useRouteError() as ErrorResponse;
  return (
    <Box className="flex items-center justify-center h-screen">
      <Box className="text-center -mt-6">
        <Typography variant="h4">Oops!</Typography>
        <Typography className="my-4">Sorry, an unexpected error has occurred.</Typography>
        <Typography>
          <i>{error?.statusText || error?.message}</i>
        </Typography>
        <div className="flex items-center justify-center mt-4">
          <Link
            to="./#"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBack /> Back
          </Link>
          <span className="mx-4">|</span>
          <Link to="/">
            Home <ArrowForward />
          </Link>
        </div>
      </Box>
    </Box>
  );
}
