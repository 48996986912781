import React from "react";

interface IBaseFormCompProps {
  title?: React.ReactNode;
  children: React.ReactNode;
  subTitle?: string;
}

const BaseRadioFormComp = (props: IBaseFormCompProps) => {
  const { title, children, subTitle } = props;
  return (
    <div className="flex flex-col gap-4">
      {title && (
        <span className="font-bold text-base text-superBlack">{title}</span>
      )}
      {subTitle && <span>{subTitle}</span>}
      {children}
    </div>
  );
};

export default BaseRadioFormComp;
