import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
type TProps = {
  value: string | "total" | "ytd";
  onChange: (val: string) => void | Promise<void>;
  disableBorder?: boolean;
};
export default function TotalYTDToggleButton(props: TProps) {
  const [subType, setSubType] = useState(props.value || "total");
  useEffect(() => {
    props.onChange(subType);
  }, [subType]);
  const isTotal = subType === "total";
  const selectedStyleProps = {
    backgroundColor: "#3B471C",
    maxWidth: "64px",
    maxHeight: "19px",
    borderRadius: "25px",
    fontSize: "10px",
    fontWeight: 600,
    lineHeight: "15px",
    whiteSpace: "nowrap",
    boxShadow: "none",
    marginBottom: "2px",
  };
  const deSelectedStyleProps = {
    backgroundColor: "transparent",
    color: "#75756F",
    maxWidth: "64px",
    maxHeight: "19px",
    borderRadius: "25px",
    fontSize: "10px",
    fontWeight: 500,
    lineHeight: "15px",
    whiteSpace: "nowrap",
    boxShadow: "none",
    marginBottom: "2px",
  };
  return (
    <Box display="flex" alignItems="center">
      <Box
        sx={{
          minWidth: "117px",
          maxHeight: "27px",
          border: `1px solid ${props.disableBorder ? "#f5f3e8" : "#E0DEC9"}`,
          borderRadius: "25px",
          backgroundColor: "#f5f3e8",
        }}
      >
        <Box mx={"2px"}>
          <Button
            variant={isTotal ? "contained" : "text"}
            sx={isTotal ? selectedStyleProps : deSelectedStyleProps}
            onClick={() => setSubType("total")}
          >
            All time
          </Button>
          <Button
            variant={!isTotal ? "contained" : "text"}
            sx={!isTotal ? selectedStyleProps : deSelectedStyleProps}
            onClick={() => setSubType("ytd")}
          >
            YTD
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
