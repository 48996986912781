import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Actions } from 'core/enums/action-enum';
import {
  CompanyAdminResponse,
  EditCompanyAdmin,
  GetAllExistingPerson,
  GetCompanyAdmin,
  NewCompanyAdmin,
} from 'core/models/companyAdminDto.model';
import {
  addCompanyAdminToCompany,
  createNewCompanyAdmin,
  manageCompanyAdmin,
  getAllExistingPersons,
  getCompanyAdminByCompanyId,
  getCompanyAdminByName,
} from 'core/services/api.service';
import { SelectedOption } from 'pages/adminManageCompanyAdmin';
import { RootState } from 'states/store';

interface ManageCompanyAdminState {
  error: string;
  action: Actions | null;
  companyAdminList: CompanyAdminResponse[];
  selectedOption: SelectedOption | null;
  allPersons: GetAllExistingPerson['content'];
  selectedPeopleList: CompanyAdminResponse[];
  selectedCompanyAdminId: string | null;
  selectedItem: CompanyAdminResponse | null;
  type: SelectedOption;
  isModalOpen: boolean;
  loading: boolean;
  getPeopleLoading: boolean;
  totalElements: number;
}

const initialState: ManageCompanyAdminState = {
  error: '',
  action: null,
  companyAdminList: [],
  selectedOption: null,
  allPersons: [],
  selectedPeopleList: [],
  selectedCompanyAdminId: null,
  selectedItem: null,
  type: SelectedOption.NEW,
  isModalOpen: false,
  loading: false,
  getPeopleLoading: false,
  totalElements: 0,
};

export const getCompanyAdminByCompanyIdAsync = createAsyncThunk(
  'manageCompanyAdmin/getCompanyAdminByCompanyIdAsync',
  async (getCompanyAdmin: GetCompanyAdmin): Promise<CompanyAdminResponse[]> => {
    const response = await getCompanyAdminByCompanyId(getCompanyAdmin);
    return response.data;
  }
);

export const getAllExistingPersonsAsync = createAsyncThunk(
  'manageCompanyAdmin/getAllExistingPersonsAsync',
  async ({ filter, isLoadMore }: { filter: any; isLoadMore?: boolean }): Promise<GetAllExistingPerson> => {
    const response = await getAllExistingPersons({ filter });
    return response.data;
  }
);

export const getPartiallyPersonsAsync = createAsyncThunk(
  'manageCompanyAdmin/getPartiallyPersonsAsync',
  async ({ filter }: { filter: any }): Promise<GetAllExistingPerson> => {
    const response = await getAllExistingPersons({ filter });
    return response.data;
  }
);

export const createNewCompanyAdminAsync = createAsyncThunk(
  'manageCompanyAdmin/createNewCompanyAdminAsync',
  async (newCompanyAdmin: { newCompanyAdmin: NewCompanyAdmin; id: string | null }): Promise<any> => {
    const response = await createNewCompanyAdmin(newCompanyAdmin);
    return response.data;
  }
);

export const getCompanyAdminByNameAsync = createAsyncThunk(
  'manageCompanyAdmin/getCompanyAdminByNameAsync',
  async (newCompanyAdmin: { name: string }): Promise<any> => {
    const response = await getCompanyAdminByName(newCompanyAdmin);
    return response.data;
  }
);

export const addCompanyAdminToCompanyAsync = createAsyncThunk(
  'manageCompanyAdmin/addCompanyAdminToCompanyAsync',
  async (newCompanyAdmin: { companyId: string | null; companyAdminId: string | null }): Promise<any> => {
    const response = await addCompanyAdminToCompany(newCompanyAdmin);
    return response.data;
  }
);

export const manageCompanyAdminAsync = createAsyncThunk(
  'manageCompanyAdmin/manageCompanyAdminAsync',
  async (body: {
    companyId: string | null;
    manageCompanyAdmin: EditCompanyAdmin | CompanyAdminResponse | null;
    action: Actions;
  }): Promise<any> => {
    const response = await manageCompanyAdmin(body);
    return response.data;
  }
);

const manageCompanyAdminSlice = createSlice({
  name: 'manageCompanyAdmin',
  initialState,
  reducers: {
    setAction: (state, action) => {
      state.action = action.payload;
    },
    setSelectedPeopleList: (state, action) => {
      state.selectedPeopleList = action.payload;
    },
    setSelectedCompanyAdminId: (state, action) => {
      state.selectedCompanyAdminId = action.payload;
    },
    setSelectedItem: (state, action) => {
      state.selectedItem = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
    setIsModalOpen: (state, action) => {
      state.isModalOpen = action.payload;
    },
    setAllPerson: (state, action) => {
      state.allPersons = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCompanyAdminByCompanyIdAsync.fulfilled, (state, action: PayloadAction<CompanyAdminResponse[]>) => {
        state.companyAdminList = action.payload;
      })
      .addCase(getAllExistingPersonsAsync.pending, (state) => {
        state.getPeopleLoading = true;
      })
      .addCase(getAllExistingPersonsAsync.fulfilled, (state, action) => {
        let newPeopleList = [...action.payload.content];
        for (let i = 0; i <= state.companyAdminList.length - 1; i++) {
          newPeopleList = newPeopleList.filter((item) => item.id !== state.companyAdminList[i].id);
        }
        state.allPersons = newPeopleList;
        state.getPeopleLoading = false;
        state.totalElements = action.payload.totalElements;
      })
      .addCase(getAllExistingPersonsAsync.rejected, (state) => {
        state.getPeopleLoading = false;
      })
      .addCase(getPartiallyPersonsAsync.pending, (state) => {
        state.getPeopleLoading = true;
      })
      .addCase(getPartiallyPersonsAsync.fulfilled, (state, action: PayloadAction<GetAllExistingPerson>) => {
        let newPeopleList = [...action.payload.content];
        for (let i = 0; i <= state.companyAdminList.length - 1; i++) {
          newPeopleList = newPeopleList.filter((item) => item.id !== state.companyAdminList[i].id);
        }
        state.allPersons = [...state.allPersons, ...newPeopleList];
        state.getPeopleLoading = false;
        state.totalElements = action.payload.totalElements;
      })
      .addCase(getPartiallyPersonsAsync.rejected, (state) => {
        state.getPeopleLoading = false;
      })
      .addCase(getCompanyAdminByNameAsync.fulfilled, (state, action) => {
        let newSelectedPeopleList = [...state.selectedPeopleList];
        for (let i = 0; i <= action.payload.length - 1; i++) {
          const indexInCompanyAdminList = state.companyAdminList.findIndex((item) => item.id === action.payload[i].id);
          const indexInSelectedPeopleList = state.selectedPeopleList.findIndex(
            (item) => item.id === action.payload[i].id
          );
          if (indexInCompanyAdminList === -1 && indexInSelectedPeopleList === -1) {
            newSelectedPeopleList.push(action.payload[i]);
            state.selectedPeopleList = newSelectedPeopleList;
          }
        }
      })
      .addCase(manageCompanyAdminAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(manageCompanyAdminAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(manageCompanyAdminAsync.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  setAction,
  setSelectedPeopleList,
  setSelectedCompanyAdminId,
  setSelectedItem,
  setType,
  setIsModalOpen,
  setAllPerson,
} = manageCompanyAdminSlice.actions;

export const selectAction = (state: RootState) => state.manageCompanyAdmin.action;
export const selectCompanyAdminList = (state: RootState) => state.manageCompanyAdmin.companyAdminList;
export const selectAllPersons = (state: RootState) => state.manageCompanyAdmin.allPersons;
export const selectSelectedPeopleList = (state: RootState) => state.manageCompanyAdmin.selectedPeopleList;
export const selectSelectedCompanyAdminId = (state: RootState) => state.manageCompanyAdmin.selectedCompanyAdminId;
export const selectEditItem = (state: RootState) => state.manageCompanyAdmin.selectedItem;
export const selectType = (state: RootState) => state.manageCompanyAdmin.type;
export const selectIsModalOpen = (state: RootState) => state.manageCompanyAdmin.isModalOpen;

const manageCompanyAdminReducer = manageCompanyAdminSlice.reducer;
export default manageCompanyAdminReducer;
