import SystemAdminLayout from 'layout/SystemAdminLayout';
import { lazy, Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import AdminDashboard from '../pages/adminDashboard';
const ManageEmployeePage = lazy(() => import('pages/admin/manage-employee'));
const ManagePendingEmployeePage = lazy(() => import('pages/admin/manage-pending-employee'));
const ManageEmployerPage = lazy(() => import('pages/admin/manage-employer'));
const AdminManagePayroll = lazy(() => import('pages/adminManagePayroll'));
const AdminPayrollContribution = lazy(() => import('pages/adminPayrollContribution'));
const FinchConnectionDashboard = lazy(() => import('pages/finchConnectionDashboard'));
const FinchConnectionDetails = lazy(() => import('pages/finchConnectionDetails'));
const AdminStatements = lazy(() => import('pages/adminStatements'));
const HomePlanPage = lazy(() => import('pages/admin/manage-plans'));
const ManagePlanPage = lazy(() => import('components/employerManagePlan/ManagePlanPage'));
const ActivateTerminalPlanPage = lazy(() => import('components/employerManagePlan/activate-terminal-plan'));
const UploadPlanPage = lazy(() => import('components/employerManagePlan/UploadPlan'));
const ManageCompanyAdmin = lazy(() => import('pages/adminManageCompanyAdmin'));
const PartnerDashboard = lazy(() => import('pages/admin/partner-dashboard'));
const LateContributionReport = lazy(() => import('pages/admin/late-contribution-report'));

export const adminRoutes: RouteObject[] = [
  {
    path: '/admin', // /admin
    element: <SystemAdminLayout />,
    children: [
      {
        path: 'dashboard', // /admin/dashboard
        element: <AdminDashboard />,
      },
      {
        path: 'manage-participants', // /admin/manage-employees
        element: (
          <Suspense>
            <ManageEmployeePage />
          </Suspense>
        ),
      },
      {
        path: 'manage-pending-employees', // /admin/manage-employees
        element: (
          <Suspense>
            <ManagePendingEmployeePage />
          </Suspense>
        ),
      },
      {
        path: 'manage-employers', // /admin/manage-employers
        element: (
          <Suspense>
            <ManageEmployerPage />
          </Suspense>
        ),
      },
      {
        path: 'managepayroll', // /admin/managepayroll
        element: (
          <Suspense>
            <AdminManagePayroll />
          </Suspense>
        ),
      },
      {
        path: 'finch-connection', // /admin/finch-connection
        element: (
          <Suspense>
            <FinchConnectionDashboard />
          </Suspense>
        ),
      },
      {
        path: 'finch-connection/:id', // /admin/finch-connection/:id
        element: (
          <Suspense>
            <FinchConnectionDetails />
          </Suspense>
        ),
      },
      {
        path: 'partner-dashboard', // /admin/partner-dashboard
        element: (
          <Suspense>
            <PartnerDashboard />
          </Suspense>
        ),
      },
      {
        path: 'payrollinfo', // /admin/payrollinfo
        element: (
          <Suspense>
            <AdminPayrollContribution />
          </Suspense>
        ),
      },
      // {
      //   path: "reset_password", // /admin/reset_password
      //   element: (
      //     <Suspense>
      //       <ResetPassword />
      //     </Suspense>
      //   ),
      // },
      {
        path: 'statements', // /dashboard/admin/statements
        element: (
          <Suspense>
            <AdminStatements />
          </Suspense>
        ),
      },
      {
        path: 'selectcompany', // /admin/selectcompany
        element: (
          <Suspense>
            <HomePlanPage />
          </Suspense>
        ),
      },
      // {
      //   path: "plan-legacy", // /admin/plancreation
      //   element: (
      //     <Suspense>
      //       <CreateEditPlanLegacyPage />
      //     </Suspense>
      //   ),
      // },
      {
        path: 'manage-plan',
        element: (
          <Suspense>
            <ManagePlanPage />
          </Suspense>
        ),
      },
      {
        path: 'planactivation', // /admin/planactivation
        element: (
          <Suspense>
            <ActivateTerminalPlanPage />
          </Suspense>
        ),
      },
      {
        path: 'planupload', // /admin/planupload
        element: (
          <Suspense>
            <UploadPlanPage />
          </Suspense>
        ),
      },
      {
        path: 'companyadmin',
        element: (
          <Suspense>
            <ManageCompanyAdmin />
          </Suspense>
        ),
      },
      {
        path: 'late-contribution-report', // /admin/late-contribution-report
        element: (
          <Suspense>
            <LateContributionReport />
          </Suspense>
        ),
      },
    ],
  },
];
