import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PayRollDto } from 'core/models/payrollDto.model';
import ApiService from 'core/services/api.service';

interface PayrollState {
  loading: boolean;
}

const initialState: PayrollState = {
  loading: false,
};

export const removePayrollContributionAsync = createAsyncThunk(
  'payroll/removePayrollContributionAsync',
  async (id: string | undefined) => {
    const response = await ApiService.removePayrollContribution(id);
    return response.data;
  }
);

export const updatePayrollContributionAsync = createAsyncThunk(
  'payroll/updatePayrollContributionAsync',
  async (payload: PayRollDto) => {
    const response = await ApiService.updatePayrollContribution(payload);
    return response.data;
  }
);

const payrollSlice = createSlice({
  name: 'payroll',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(removePayrollContributionAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(removePayrollContributionAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(removePayrollContributionAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updatePayrollContributionAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePayrollContributionAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updatePayrollContributionAsync.rejected, (state) => {
        state.loading = false;
      });
  },
});

const payrollReducer = payrollSlice.reducer;
export default payrollReducer;
