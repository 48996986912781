import { FormControl, Grid, IconButton, InputAdornment } from "@mui/material";
import LogoIcon from "assets/SVGIcons/LogoIcon";
import { Button, TextField } from "common/components";
import { REGEX_PATTERN } from "core/constants";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import CheckIcon from "@mui/icons-material/Check";

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { validatePasswordMessages } from "pages/resetPassword";
import { useAppDispatch, useAppSelector } from "states/hooks";
import { checkFieldExisted } from "core/services/api.service";
import { IRecoveryAccountPayload } from "core/models/employeeDto.model";
import { recoveryAccountAsync } from "states/auth/authSlice";
import { RequestStatus } from "core/enums/request-status.enum";
import {
  setErrorMessage,
  setSuccessMessage,
} from "states/snackbarMessage/snackbarMessageSlice";
import { useNavigate } from "react-router-dom";

type FormValues = {
  email: string;
  userName: string;
  password: string;
  confirmPassword: string;
};

export default function RecoveryAcount() {
  const navigate = useNavigate();
  const accountRecoveryToken = useAppSelector(
    (state) => state.auth.recoveryAccountToken
  );

  const dispatch = useAppDispatch();
  const accountRecoveryState =
    useAppSelector((state) => state.auth.recoveryAccountType) || "CREATE";
  const accountRecoveryEmail = useAppSelector(
    (state) => state.auth.recoveryAccountNewEmail
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const zodSchema = z
    .object({
      userName: z.string().superRefine((value, ctx) => {
        if (value === "") {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Username is required",
          });
        }
        if (!REGEX_PATTERN.USER_NAME.pattern.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: REGEX_PATTERN.USER_NAME.message,
          });
        }
      }),
      password: z.string().superRefine((value, ctx) => {
        if (value === "") {
          ctx.addIssue({
            message: "Password is required",
            code: z.ZodIssueCode.too_small,
            minimum: 8,
            exact: false,
            inclusive: true,
            type: "string",
          });
        }
        if (value.length < 8) {
          ctx.addIssue({
            message: "At least 8 characters",
            code: z.ZodIssueCode.custom,
          });
        }
        if (!REGEX_PATTERN.AT_LEAST_ONE_LOWERCASE.pattern.test(value)) {
          ctx.addIssue({
            message: REGEX_PATTERN.AT_LEAST_ONE_LOWERCASE.message,
            code: z.ZodIssueCode.custom,
          });
        }
        if (!REGEX_PATTERN.AT_LEAST_ONE_UPPERCASE.pattern.test(value)) {
          ctx.addIssue({
            message: REGEX_PATTERN.AT_LEAST_ONE_UPPERCASE.message,
            code: z.ZodIssueCode.custom,
          });
        }
        if (!REGEX_PATTERN.AT_LEAST_ONE_NUMBER.pattern.test(value)) {
          ctx.addIssue({
            message: REGEX_PATTERN.AT_LEAST_ONE_NUMBER.message,
            code: z.ZodIssueCode.custom,
          });
        }
        if (!REGEX_PATTERN.AT_LEAST_ONE_SPECIAL_CHARACTER.pattern.test(value)) {
          ctx.addIssue({
            message: REGEX_PATTERN.AT_LEAST_ONE_SPECIAL_CHARACTER.message,
            code: z.ZodIssueCode.custom,
          });
        }
      }),
      confirmPassword: z.string().superRefine((value, ctx) => {
        if (value === "") {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Password is required",
          });
        }
        if (!REGEX_PATTERN.PASSWORD.pattern.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: REGEX_PATTERN.PASSWORD.message,
          });
        }
      }),
      email: z.string().superRefine((value, ctx) => {
        if (value === "") {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Email is required",
          });
        }
        if (!REGEX_PATTERN.EMAIL.pattern.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: REGEX_PATTERN.EMAIL.message,
          });
        }
      }),
    })
    .refine(
      (schema) => {
        if (schema.password && schema.confirmPassword)
          return schema.password === schema.confirmPassword;
        return true;
      },
      {
        path: ["confirmPassword"],
        message: "Passwords do not match",
      }
    );

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, dirtyFields, isValid },
    register,
  } = useForm<FormValues>({
    reValidateMode: "onChange",
    criteriaMode: "all",
    mode: "onChange",
    resolver: zodResolver(zodSchema),
    defaultValues: {
      email: accountRecoveryEmail,
      userName: "",
      password: "",
      confirmPassword: "",
    },
  });

  const handleOnVerify = async (values: FormValues) => {
    setLoading(true);
    const username = values.userName;
    try {
      const checkUseranmeResponse = await checkFieldExisted(
        "userName",
        username
      );
      if (checkUseranmeResponse?.data?.existing) {
        setError("userName", {
          message: "Your username is registered.",
        });
        setLoading(false);
        return;
      }
    } catch (error) {
      dispatch(setErrorMessage("Something went wrong!"));
      setLoading(false);
      return;
    }
    const payload: IRecoveryAccountPayload = {
      action: "CREATE_CREDENTIAL",
      person: {
        userName: values.userName,
        password: values.password,
      },
      token: accountRecoveryToken,
    };
    const response = await dispatch(recoveryAccountAsync(payload));
    setLoading(false);
    if (response.meta.requestStatus === RequestStatus.FULFILLED) {
      dispatch(
        setSuccessMessage(
          `${
            accountRecoveryState === "CREATE" ? "Create" : "Update"
          } account successfully!`
        )
      );
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    }
  };
  if (!accountRecoveryToken) {
    window.location.href = "/login";
    return null;
  }
  return (
    <div className="login">
      <div className="login-wrap">
        <div className="login_component">
          <Grid container>
            <Grid className="information mt-44" item xs={12} sm={6}>
              <div className="medium-6 large-6 column text-primary">
                <span className="font-bold text-2xl leading-9">
                  Account Recovery
                </span>
                <div className="text-sm font-normal mt-2">
                  <p className="my-6">
                    Use this process to create a new login username and password
                    for your Saveday account.
                  </p>
                  <p className="my-6">
                    After you have completed the identity verification, you will
                    receive an authentication code to the mobile number you have
                    on file, please have your phone ready.
                  </p>
                  <p className="my-6">
                    If you run into any issues, please contact Saveday support
                    through our{" "}
                    <a
                      href="https://saveday.zendesk.com/hc/en-us/requests/new"
                      rel="noreferrer"
                      target="_blank"
                      className="underline text-darkGreen"
                    >
                      Help Center
                    </a>
                    .
                  </p>
                </div>
              </div>
            </Grid>
            <Grid className="space-y-4 form-card-wrap" item xs={12} sm={6}>
              <div className="flex justify-center w-full">
                <LogoIcon />
              </div>
              <h4 className="text-normal text-left font-semibold text-darkGreen">
                {`${
                  accountRecoveryState === "CREATE" ? "Create" : "Update"
                } account`}
              </h4>
              <form
                name="formRecoveryAccount"
                className="form-login"
                onSubmit={handleSubmit(handleOnVerify)}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Controller
                        control={control}
                        name="email"
                        rules={{
                          required: "Email is required",
                          pattern: {
                            value: REGEX_PATTERN.EMAIL.pattern,
                            message: REGEX_PATTERN.EMAIL.message,
                          },
                        }}
                        render={({ field, fieldState }) => {
                          return (
                            <>
                              <TextField
                                {...field}
                                required
                                disabled
                                placeholder="Your new email"
                                label="New Email"
                                error={fieldState.error?.message}
                                subLabel={
                                  <>
                                    <span className="italic font-normal text-xs leading-4">
                                      This is the email you would like us to use
                                      going forward
                                    </span>
                                  </>
                                }
                              />
                            </>
                          );
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <Controller
                        control={control}
                        name="userName"
                        rules={{
                          required: "Username is required",
                        }}
                        render={({ field, fieldState }) => {
                          return (
                            <>
                              <TextField
                                {...field}
                                required
                                placeholder="Your username"
                                label="Username"
                                error={fieldState.error?.message}
                              />
                            </>
                          );
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item zero={12}>
                    <>
                      <TextField
                        label={
                          <span className="text-xs font-semibold">{`Password`}</span>
                        }
                        type={showPassword ? "text" : "password"}
                        placeholder="Type password here"
                        registerForm={{ ...register("password") }}
                        autoComplete="new-password"
                        required={true}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOffOutlinedIcon />
                                ) : (
                                  <VisibilityOutlinedIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={errors.password?.message}
                      />
                      {dirtyFields.password &&
                        Object.entries(validatePasswordMessages).map(
                          ([type, message]) => (
                            <div
                              className="flex gap-1 items-center mt-2"
                              key={type}
                            >
                              <CheckIcon
                                className={[
                                  "text-xl",
                                  (
                                    (errors.password?.types
                                      ?.custom as string[]) || []
                                  ).includes(message)
                                    ? "text-primary"
                                    : "text-secondary",
                                ].join(" ")}
                              />
                              <p className="text-primary text-xs">{message}</p>
                            </div>
                          )
                        )}
                    </>
                  </Grid>
                  <Grid item zero={12}>
                    <TextField
                      label={
                        <span className="text-xs font-semibold">{`Confirm Password`}</span>
                      }
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Type password here"
                      registerForm={{ ...register("confirmPassword") }}
                      required={true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setShowConfirmPassword(!showConfirmPassword)
                              }
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <VisibilityOffOutlinedIcon />
                              ) : (
                                <VisibilityOutlinedIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={errors.confirmPassword?.message}
                    />
                  </Grid>
                </Grid>

                <div className="form-footer">
                  <Button
                    className="w-full background-color-secondary max-w-full"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    loading={loading}
                    disabled={!isValid}
                  >
                    Complete
                  </Button>
                </div>
              </form>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}
