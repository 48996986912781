import { Backdrop, Grid, Slide } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import { get, isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector } from 'states/hooks';
import { OnboardingFinchStatus } from 'core/enums';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getEmployeeStatusCountAsync, selectStatusCount } from 'states/employerDashboard/employerDashboardSlice';
import { getCookie } from 'core/services/cookie.service';
import { CookieNames } from 'core/enums/cookie-names.enum';
import { EmployeeStatus } from 'core/enums/employee-status.enum';
import { CONTRIBUTION_LOGS_STATUS } from 'core/constants';
import { CheckListItem } from './CheckListItem';
import { CheckListEnum, CheckListTitle } from 'core/enums/employer-checklist.enum';
import { ICheckListData, IValidState } from 'core/models/popupCheckListDto.model';
import { checkListByFinchConnected, checkListByFinchNotConnected } from 'core/constants/employer-check-list-data';
import { CompanyStatus } from 'core/enums/company.enum';
import { updateCompanyAsync } from 'states/manageEmployer/manageEmployerSlice';
import { RequestStatus } from 'core/enums/request-status.enum';
import { getProcessPayrolls } from 'core/services/api.service';

const CheckLiskPopUp = () => {
  const companyDetails = useAppSelector((state) => state.manageEmployer.companyDetail);
  const dispatch = useAppDispatch();
  const employeeStatusCount = useAppSelector(selectStatusCount);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [expandList, setExpandList] = useState<boolean>(false);
  const [valid, setValid] = useState<Partial<IValidState>>({});
  const [loading, setLoading] = useState(false);
  const [numberOfElementProcessPayroll, setNumberOfElementProcessPayroll] = useState(0);
  const finchStatus = companyDetails?.finchOnboardingStatus as OnboardingFinchStatus;
  const navigateValid = !(searchParams.get('welcome') === '1');
  const companyId = getCookie(CookieNames.CurrentCompanyId);
  const completedTaskCheckList = companyDetails?.completedTaskCheckList || [];
  const hideIcon = !loading || numberOfElementProcessPayroll > 0 || companyDetails?.status === CompanyStatus.ONBOARDING;
  const isFinchNotConnected = [OnboardingFinchStatus.DISCONNECTED, OnboardingFinchStatus.NaN].includes(finchStatus);
  const sessionId = getCookie(CookieNames.XCSSession);

  const checkTaskCompleted = (id: CheckListEnum) => {
    return completedTaskCheckList.includes(CheckListTitle[id]);
  };

  const checkUploadEmployeeValid = () => {
    const { ACTIVE, INVITED, NEW } = EmployeeStatus;
    return Object.keys(employeeStatusCount).some((key: string) => {
      return [ACTIVE, INVITED, NEW].includes(key as EmployeeStatus) && get(employeeStatusCount, key, 0) > 0;
    });
  };

  const setDefaultValue = () => {
    const {
      BANK_ACCOUNT,
      COMPLETE_PAYROLL_INTERGRATiON,
      EMPLOYEE_JOURNEY,
      NAVIGATE,
      PROCESS_CONTRIBUTION,
      UPLOAD_EMPLOYEE_RECORDS,
    } = CheckListEnum;
    const uploadEmployeeValid = checkUploadEmployeeValid();

    let params: Partial<IValidState> = {
      [NAVIGATE]: checkTaskCompleted(NAVIGATE),
      [EMPLOYEE_JOURNEY]: checkTaskCompleted(EMPLOYEE_JOURNEY),
      [BANK_ACCOUNT]: checkTaskCompleted(BANK_ACCOUNT),
    };

    if (isFinchNotConnected) {
      if (uploadEmployeeValid) {
        handleCheckList(CheckListEnum.UPLOAD_EMPLOYEE_RECORDS);
      }
      params = {
        ...params,
        [PROCESS_CONTRIBUTION]: checkTaskCompleted(PROCESS_CONTRIBUTION),
        [UPLOAD_EMPLOYEE_RECORDS]: checkTaskCompleted(UPLOAD_EMPLOYEE_RECORDS),
      };
    } else {
      params = {
        ...params,
        [COMPLETE_PAYROLL_INTERGRATiON]: checkTaskCompleted(COMPLETE_PAYROLL_INTERGRATiON),
      };
    }
    setValid(params);
  };

  const handleCheckList = async (id: CheckListEnum) => {
    const uploadEmployeeValid = checkUploadEmployeeValid();
    if ((id === CheckListEnum.UPLOAD_EMPLOYEE_RECORDS && !uploadEmployeeValid) || !companyDetails) return;
    const title = CheckListTitle[id];
    if (!completedTaskCheckList.includes(title)) {
      const res = await dispatch(
        updateCompanyAsync({
          companyDetail: {
            ...companyDetails,
            completedTaskCheckList: [...completedTaskCheckList, title],
          },
        })
      );
      if (res.meta.requestStatus === RequestStatus.FULFILLED) {
        setValid((prev) => ({ ...prev, [id]: true }));
      }
    }
  };

  const checkListContainer = () => {
    if (!companyDetails) return;

    return (
      <Slide in={expandList} direction="up">
        <div className="fixed right-8 zero:bottom-24 xl:bottom-24 z-50 bg-white border border-solid border-[#FCFAFA] p-6 max-w-[288px] rounded-lg shadow-[0_4px_16px_0_#0000001F]">
          <Grid container flexDirection="column">
            <div
              onClick={() => {
                setExpandList((prev) => !prev);
                if (!navigateValid) {
                  navigate('/dashboard/employer');
                }
              }}
              className="absolute -top-5 right-5 border border-solid rounded-full border-secondary bg-secondary h-10 w-10 flex items-center justify-center cursor-pointer shadow-[0_0_6px_rgba(0,0,0,0.5)]"
            >
              <ExpandMoreIcon className="text-lightGreen" />
            </div>
            <Grid item>
              <span className="text-base font-bold text-primary">Welcome on Board!</span>
            </Grid>
            <Grid item>
              <p className="text-xs font-normal text-deepGrey">
                Here are some resources to help you navigate your Saveday account:
              </p>
            </Grid>
            <Grid item className="mt-2">
              {(isFinchNotConnected ? checkListByFinchNotConnected : checkListByFinchConnected).map(
                (item: ICheckListData, idx) => {
                  return (
                    <CheckListItem
                      id={item.id}
                      step={String(idx + 1)}
                      title={item.title}
                      valid={get(valid, String(item.id), false)}
                      link={item?.link}
                      onClick={() => {
                        handleCheckList(item.id);
                        if (item?.link) {
                          window.open(item.link);
                        }
                      }}
                    />
                  );
                }
              )}
            </Grid>
          </Grid>
        </div>
      </Slide>
    );
  };

  const fetchEmployeeStatus = () => {
    if (isEmpty(employeeStatusCount)) {
      dispatch(getEmployeeStatusCountAsync(companyId));
    }
  };

  const fetchProcessPayroll = async () => {
    try {
      if (sessionId) {
        const res = await getProcessPayrolls({
          companyId,
          filter: {
            status: CONTRIBUTION_LOGS_STATUS.COMPLETED,
            pageSize: 1,
          },
        });
        if (res?.data?.numberOfElements) {
          setNumberOfElementProcessPayroll(res.data.numberOfElements);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(true);
    }
  };

  const handleSetExpandList = () => {
    if (
      companyDetails &&
      [CompanyStatus.ACTIVE, CompanyStatus.NEW].includes(companyDetails?.status as CompanyStatus) &&
      loading
    ) {
      setExpandList(true);
    }
  };

  useEffect(() => {
    fetchEmployeeStatus();
    fetchProcessPayroll();
  }, []);

  useEffect(() => {
    if (!hideIcon && companyDetails?.id) {
      handleSetExpandList();
      setDefaultValue();
    }
  }, [companyDetails, loading, employeeStatusCount]);

  return (
    <>
      {hideIcon ? (
        <></>
      ) : (
        <>
          {!expandList && (
            <div
              onClick={() => setExpandList((prev) => !prev)}
              className="fixed right-8 zero:bottom-24 xl:bottom-24 border border-solid rounded-full border-secondary bg-secondary h-10 w-10 flex items-center justify-center cursor-pointer z-50 shadow-[0_0_6px_rgba(0,0,0,0.5)]"
            >
              <PlaylistAddCheckIcon className="text-lightGreen" />
            </div>
          )}
          {!navigateValid ? (
            <Backdrop
              open={!navigateValid}
              sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
            >
              {checkListContainer()}
            </Backdrop>
          ) : (
            checkListContainer()
          )}
        </>
      )}
    </>
  );
};

export default CheckLiskPopUp;
