const PieChartIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8_16112)">
        <path
          d="M10 2.16666C5.4 2.16666 1.66667 5.89999 1.66667 10.5C1.66667 15.1 5.4 18.8333 10 18.8333C14.6 18.8333 18.3333 15.1 18.3333 10.5C18.3333 5.89999 14.6 2.16666 10 2.16666ZM16.6083 9.66666H10.8333V3.89166C13.8417 4.26666 16.2333 6.65832 16.6083 9.66666ZM3.33333 10.5C3.33333 7.10832 5.88333 4.29999 9.16667 3.89166V17.1083C5.88333 16.7 3.33333 13.8917 3.33333 10.5ZM10.8333 17.1083V11.3333H16.6083C16.2333 14.3417 13.8417 16.7333 10.8333 17.1083Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_8_16112">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PieChartIcon;
