import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardActions,
  CardContent,
  DialogActions,
  Button as MuiButton,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Controller, UseFormReturn, useFieldArray } from 'react-hook-form';
import { TEmployeeEditBeneficiaryV2Form } from '.';
import BeneficiaryInfo from './BeneficiaryInfo';
import { useDialog } from 'hooks/useDialog';
import { Button, DialogContainer } from 'common/components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UploadSpousalConsent from './SpousalConsent';
import _ from 'lodash';

type TProps = {
  type: string;
  name: 'primaryBeneficiary' | 'secondaryBeneficiary';
  title: string;
  form: UseFormReturn<TEmployeeEditBeneficiaryV2Form>;
  isShareError?: boolean;
  setOpenModalUpload: React.Dispatch<React.SetStateAction<boolean>>;
};

const renderFullName = (...params: string[]): string => {
  return params.filter(Boolean).join(' ');
};
const ShareErrorBlock = () => {
  return (
    <p className="text-sm font-medium leading-5 mt-3" style={{ color: '#FF005C' }}>
      Sum of shares between beneficiaries should be equal to 100%, please adjust accordingly
    </p>
  );
};

const defaultBeneficiary: any = {
  firstName: '',
  lastName: '',
  trustName: '',
  trustDate: '',
  percentage: 0,
  ssn: '',
  dob: '',
  relationship: '', // Set a default relationship (can be adjusted)
  address: {
    street: '',
    line2: '',
    city: '',
    state: '',
    zip: '',
  },
};

interface ITabValue {
  key: string;
  isExpand: boolean;
}

const BeneficiaryList: FC<TProps> = ({ name, type, title, form, isShareError, setOpenModalUpload }) => {
  const {
    control,
    watch,
    formState: { errors },
  } = form;
  const { openDialog } = useDialog();
  const watchedArray = watch()[name];

  const [expandList, setExpandList] = useState<ITabValue[]>([]);

  const fieldArray = useFieldArray({
    control,
    name,
  });

  const onRemoveItem = (action: () => void) => {
    openDialog((close) => (
      <DialogContainer close={close}>
        <div className="text-2xl text-center text-superBlack font-bold mb-3">Remove beneficiary</div>
        <div className="text-sm text-center text-darkest">
          Would you like to remove this beneficiary? If yes, please adjust the share percentages for the remaining
          beneficiaries accordingly.
        </div>
        <DialogActions className="flex items-center justify-center gap-2 mt-6">
          <Button variant="outlined" color="secondary" onClick={close}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              action();
              close();
            }}
            className="min-w-[170px] whitespace-nowrap"
          >
            Confirm
          </Button>
        </DialogActions>
      </DialogContainer>
    ));
  };

  const onRemoveSelectedBeneficiaryClick = (index: number) => {
    onRemoveItem(() => {
      fieldArray.remove(index);
      setExpandList(expandList.filter((f) => f.key !== `${index}`));
      if (index === 0 && name === 'primaryBeneficiary') {
        fieldArray.append(defaultBeneficiary);
        setExpandList([{ key: `${0}`, isExpand: true }]);
      }
    });
  };

  const onAddBeneficiaryClick = () => {
    fieldArray.append(defaultBeneficiary);
    setExpandList([
      ...expandList,
      {
        key: `${watchedArray.length - 1}`,
        isExpand: true,
      },
    ]);
  };

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    if (isExpanded) {
      setExpandList(
        _.uniq([
          ...expandList.filter((f) => f.key !== panel),
          {
            key: panel,
            isExpand: true,
          },
        ])
      );
    } else {
      setExpandList(
        _.uniq([
          ...expandList.filter((f) => f.key !== panel),
          {
            key: panel,
            isExpand: false,
          },
        ])
      );
    }
  };

  const checkError = () => {
    const shouldExpandList: number[] = [];
    for (let index = 0; index < watchedArray.length; index++) {
      const hasError = _.get(errors, `${name}.[${index}]`);
      if (hasError) {
        shouldExpandList.push(index);
      }
    }
    setExpandList(
      _.uniq([
        ...expandList.filter((f) => !shouldExpandList.includes(parseInt(f.key))),
        ...shouldExpandList.map((f) => ({ key: `${f}`, isExpand: true })),
      ])
    );
  };

  useEffect(() => {
    if (!_.isEmpty(errors)) {
      checkError();
    }
  }, [errors]);

  useEffect(() => {
    const indexs = watchedArray.map((arr, index) => index);
    const needToPush = indexs.filter((f) => !expandList.find((e) => e.key === `${f}`));
    setExpandList([...expandList, ...needToPush.map((np) => ({ key: `${np}`, isExpand: true }))]);
  }, [watchedArray]);

  return (
    <Card variant="outlined" sx={{ borderRadius: '10px !important', border: '1px solid #E0DEC9' }}>
      <CardContent>
        <p className="font-semibold text-sm leading-5 text-superBlack">{title}</p>
        {isShareError && <ShareErrorBlock />}
        <Box sx={{ mt: 2 }}>
          <>
            {fieldArray.fields.map((item, index) => {
              const values = watchedArray[index];
              const isExpand = expandList.find((f) => f.key === `${index}`)?.isExpand ?? false;
              return (
                <Accordion
                  key={item.id}
                  elevation={0}
                  expanded={isExpand}
                  onChange={handleChange(`${index}`)}
                  sx={{
                    borderRadius: '10px !important',
                    mt: 2,
                    '&:before': {
                      display: 'none',
                    },
                    bgcolor: '#FCFAFA',
                    border: '1px solid #E0DEC9',
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content">
                    <p className="text-sm font-semibold text-superBlack">
                      {renderFullName(values.firstName, values.lastName)}
                    </p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <BeneficiaryInfo
                      index={index}
                      type={name}
                      form={form}
                      onRemoveClick={onRemoveSelectedBeneficiaryClick}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </>
        </Box>
      </CardContent>
      <CardActions
        style={{
          width: '100%',
          justifyContent: name === 'primaryBeneficiary' ? 'space-between' : 'flex-end',
        }}
      >
        {name === 'primaryBeneficiary' && (
          <Controller
            control={control}
            name="spousalConsent"
            rules={{
              required: true,
              validate: (value) => {
                if (!value.url) {
                  return 'Spousal Consent is required';
                } else {
                  return true;
                }
              },
            }}
            render={({ field, fieldState }) => (
              <UploadSpousalConsent
                field={field}
                error={fieldState.error?.message}
                setOpenModalUpload={setOpenModalUpload}
              />
            )}
          />
        )}
        {watchedArray.length < 5 && (
          <MuiButton
            variant="text"
            className="text-sm font-semibold leading-5"
            color="secondary"
            onClick={onAddBeneficiaryClick}
          >
            {`Add additional ${type} beneficiary`}
          </MuiButton>
        )}
      </CardActions>
    </Card>
  );
};
export default BeneficiaryList;
