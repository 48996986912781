export const getCurrentYear = (): number[] => {
  const nextYear = new Date().getFullYear() + 1;
  const yearList = [];
  for (let i = 2015; i <= nextYear; i++) {
    yearList.push(i);
  }
  return yearList;
};

export const getPastTenYear = (): number[] => {
  const thisYear = new Date().getFullYear();
  const yearList = [];
  for (let i = thisYear; i > thisYear - 10; i--) {
    yearList.push(i);
  }
  return yearList;
};
