import { zodResolver } from "@hookform/resolvers/zod";
import { Grid, IconButton, InputAdornment } from "@mui/material";
import { Button, TextField, Tooltip } from "common/components";
import { EMPLOYEE_SCREENS, REGEX_PATTERN } from "core/constants";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useAppDispatch } from "states/hooks";
import { onboardingEmployeeCheckCredentialsAsync } from "states/onboardingSlice";
import { RequestStatus } from "core/enums/request-status.enum";
import { CredentialCodes, OnboardingEmployeeStages } from "core/enums";
import { setErrorMessage } from "states/snackbarMessage/snackbarMessageSlice";
import SelectYourCompanyModal from "./SelectYourCompanyModal";
import { CookieNames } from "core/enums/cookie-names.enum";
import { removeCookie, setCookie } from "core/services/cookie.service";
import { getEmployeeProfileAsync } from "states/manageEmployee/manageEmployeeSlice";
import CreateYourLoginErrorModal from "./CreateYourLoginErrorModal";
import { useAuth } from "contexts/AuthProvider";
import { ROLES } from "core/constants/roles";
import { EmployeeStatus } from "core/enums/employee-status.enum";

type Props = {
  setStep: Dispatch<SetStateAction<number>>;
  getStep?: (screen: string) => number;
};

interface IVerifyYourLogin {
  email: string;
  userName: string;
  password: string;
}
const DEFAULT_VALUES: IVerifyYourLogin = {
  email: "",
  userName: "",
  password: "",
};

const VerifyYourLogin: FC<Props> = ({ setStep, getStep }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [companyId, setCompanyId] = useState<string>("");
  const [companyList, setCompanyList] = useState<any[]>([]);
  const [isMultipleCompanySelectOpen, setIMultipleCompanySelectOpen] =
    useState<boolean>(false);
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
  const [profiles, setProfiles] = useState<any>();

  const zodSchema = z
    .object({
      email: z.string(),
      userName: z.string(),
      password: z.string(),
    })
    .superRefine((arg, ctx) => {
      if (!arg.email) {
        ctx.addIssue({
          message: "Email is required",
          code: z.ZodIssueCode.custom,
          path: ["email"],
        });
      }
      if (!arg.userName) {
        ctx.addIssue({
          message: "Username is required",
          code: z.ZodIssueCode.custom,
          path: ["userName"],
        });
      }
      if (!arg.password) {
        ctx.addIssue({
          message: "Password is required",
          code: z.ZodIssueCode.too_small,
          minimum: 8,
          exact: false,
          inclusive: true,
          type: "string",
          path: ["password"],
        });
      }
      if (!REGEX_PATTERN.EMAIL.pattern.test(arg.email)) {
        ctx.addIssue({
          message: REGEX_PATTERN.EMAIL.message,
          code: z.ZodIssueCode.custom,
          path: ["email"],
        });
      }
      if (!REGEX_PATTERN.USER_NAME.pattern.test(arg.userName)) {
        ctx.addIssue({
          message: REGEX_PATTERN.USER_NAME.message,
          code: z.ZodIssueCode.custom,
          path: ["userName"],
        });
      }
      if (!REGEX_PATTERN.PASSWORD.pattern.test(arg.password)) {
        ctx.addIssue({
          message: REGEX_PATTERN.PASSWORD.message,
          code: z.ZodIssueCode.custom,
          path: ["password"],
        });
      }
    });

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm<IVerifyYourLogin>({
    criteriaMode: "all",
    defaultValues: DEFAULT_VALUES,
    resolver: zodResolver(zodSchema),
    reValidateMode: "onChange",
    mode: "onChange",
  });

  const onSubmitHandler = async (values: IVerifyYourLogin) => {
    await checkLoginInfo(values);
  };

  const checkFormValid = () => {
    return Object.keys(dirtyFields).length >= 3;
  };

  const onConfirmedCompany = async () => {
    setIMultipleCompanySelectOpen(false);
    await processWithCompanyId(profiles, companyId);
  };

  const onCancel = () => {
    setCompanyId("");
    setIMultipleCompanySelectOpen(false);
    removeCookie(CookieNames.XCSSession);
    removeCookie(CookieNames.CurrentRole);
    removeCookie(CookieNames.CurrentCompanyId);
    removeCookie(CookieNames.PersonId);
  };

  const processWithCompanyId = async (profileList: any, cId: string) => {
    if (!profileList) return;
    const targetProfile = profileList?.find(
      (item: any) => item?.companyId === cId
    );
    setCookie(CookieNames.UserProfileId, targetProfile?.id);
    setCookie(CookieNames.CurrentCompanyId, cId);
    await auth?.updateSession();
    const res = await dispatch(getEmployeeProfileAsync());
    if (
      (res?.payload?.status === EmployeeStatus.ACTIVE &&
        res?.payload?.person.password !== undefined) ||
      res?.payload?.status === EmployeeStatus.EMPLOYMENT_ENDED
    ) {
      dispatch(
        setErrorMessage(
          "Your account is not associated with any invitation. If you already have the account, please login or contact to Saveday team instead."
        )
      );
      return;
    }
    if (
      targetProfile?.onboardingLatestStage !==
      OnboardingEmployeeStages.EP_CREATE_CREDENTIAL
    ) {
      if (getStep) {
        setStep(getStep(targetProfile?.onboardingLatestStage));
        setCookie(
          CookieNames.OnboardingStep,
          getStep(targetProfile?.onboardingLatestStage)
        );
      } else {
        setStep(EMPLOYEE_SCREENS.AccountPreference);
        setCookie(
          CookieNames.OnboardingStep,
          EMPLOYEE_SCREENS.AccountPreference
        );
      }
      return;
    } else {
      setStep(EMPLOYEE_SCREENS.AccountPreference);
      setCookie(CookieNames.OnboardingStep, EMPLOYEE_SCREENS.AccountPreference);
    }
  };

  const checkLoginInfo = async (values: any) => {
    setCompanyId("");
    setCompanyList([]);
    removeCookie(CookieNames.XCSSession);
    const payload: any = {
      person: {
        email: values.email,
        userName: values.userName,
        password: values.password,
      },
    };
    const checkCredentialsResponse = await dispatch(
      onboardingEmployeeCheckCredentialsAsync({
        payload,
      })
    );
    if (
      checkCredentialsResponse.meta.requestStatus === RequestStatus.FULFILLED
    ) {
      const { code, times } = checkCredentialsResponse.payload;
      if (code === CredentialCodes.EMAIL_NOT_FOUND) {
        dispatch(setErrorMessage("The credential hasn’t been created yet"));
        return false;
      } else if (code === CredentialCodes.USERNAME_NOT_FOUND) {
        dispatch(setErrorMessage("The username and password do not match!"));
        return false;
      } else if (code === CredentialCodes.INVALID_CREDENTIAL) {
        dispatch(
          setErrorMessage(
            `The password you have entered is invalid. You still have ${times}(s) to continue onboarding.`
          )
        );
        return false;
      } else if (code === CredentialCodes.USER_NOT_FOUND) {
        dispatch(setErrorMessage("The username and password do not match!"));
        return;
      } else if (code === CredentialCodes.USER_MATCHED) {
        const { companies, session, profiles } =
          checkCredentialsResponse.payload;
        setProfiles(profiles);
        const {
          id: sessionId,
          values: { personId },
        } = session;
        setCookie(CookieNames.PersonId, personId);
        setCookie(CookieNames.XCSSession, sessionId);
        setCookie(CookieNames.CurrentRole, ROLES.PARTICIPANT);
        if (!profiles?.length) {
          setIsErrorOpen(true);
          return false;
        }
        if (profiles.length === 1) {
          const targetCompanyId = profiles[0].companyId;
          setCompanyId(targetCompanyId);
          await processWithCompanyId(profiles, targetCompanyId);
        } else if (profiles.length > 1) {
          const remainCompanies = companies.filter((cp: any) =>
            profiles.map((pf: any) => pf.companyId).includes(cp.id)
          );
          if (!remainCompanies.length) {
            setIsErrorOpen(true);
            return false;
          }
          setCompanyList(remainCompanies);
          setIMultipleCompanySelectOpen(true);
        }

        return true;
      }
    }
  };

  return (
    <>
      <form className="my-4" onSubmit={handleSubmit(onSubmitHandler)}>
        <Grid container spacing={2}>
          <>
            <Grid
              item
              zero={12}
              className="text-md font-semibold text-primary mt-2"
            >
              Enter your login credentials
            </Grid>
            <Grid
              item
              zero={12}
              className="text-md font-normal text-primary mt-2"
            >
              <>
                <p className="text-sm text-primary">
                  Your progress has been saved! Please enter the login details
                  you created to seamlessly resume where you left off in the
                  plan selection process.
                </p>
                <p className="text-sm text-primary">
                  In case you cannot recall your{" "}
                  <span
                    className="underline text-textLink hover:text-textLinkHover hover:cursor-pointer italic"
                    onClick={() => navigate("/login/forgotUsername ")}
                  >
                    username
                  </span>{" "}
                  or{" "}
                  <span
                    className="underline text-textLink hover:text-textLinkHover hover:cursor-pointer italic"
                    onClick={() => navigate("/login/forgotPassword")}
                  >
                    password
                  </span>{" "}
                  , kindly click on the links to reset them.
                </p>
              </>
            </Grid>
            <Grid item zero={12} sm={4}>
              <TextField
                label="Email"
                registerForm={{ ...register("email") }}
                placeholder="mail@example.com"
                error={errors.email?.message}
              />
            </Grid>
            <Grid item zero={12} sm={4}>
              <TextField
                label="Username"
                placeholder="Type username"
                registerForm={{ ...register("userName") }}
                error={errors.userName?.message}
              />
            </Grid>
            <Grid item zero={12} sm={4}>
              <>
                <TextField
                  label={
                    <>
                      Password{" "}
                      <Tooltip title={REGEX_PATTERN.PASSWORD.message} />
                    </>
                  }
                  type={showPassword ? "text" : "password"}
                  placeholder="Type password"
                  registerForm={{ ...register("password") }}
                  autoComplete="new-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOffOutlinedIcon />
                          ) : (
                            <RemoveRedEyeOutlinedIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={errors.password?.message}
                />
              </>
            </Grid>
          </>
        </Grid>

        <div className="w-full text-right mt-10">
          <Button
            variant="contained"
            color="secondary"
            disabled={!checkFormValid()}
            type="submit"
            sx={{
              width: "100%",
              maxWidth: {
                zero: "100%",
                sm: "220px",
              },
            }}
          >
            Next
          </Button>
        </div>
      </form>
      <SelectYourCompanyModal
        isOpen={isMultipleCompanySelectOpen}
        onClose={onConfirmedCompany}
        onCancel={onCancel}
        companyId={companyId}
        setCompanyId={setCompanyId}
        companyList={companyList}
      />
      <CreateYourLoginErrorModal
        isOpen={isErrorOpen}
        onClose={() => setIsErrorOpen(!isErrorOpen)}
      />
    </>
  );
};
export default VerifyYourLogin;
