import { Component } from "react";
import { ZENDESK_CONFIG } from "../../../core/constants/zendesk-config";

declare global {
  interface Window {
    MyNamespace: any;
    zE: any;
    zESettings: any;
    clientWidth: number;
  }
}

window.MyNamespace = window.MyNamespace || {};

interface IZendeskProps {
  onLoaded: Function;
  defer: boolean;
  zendeskKey?: string;
}

const canUseDOM = () => {
  if (
    typeof window === "undefined" ||
    !window.document ||
    !window.document.createElement
  ) {
    return false;
  }
  return true;
};

export const zendeskAPI = (...args: any[]) => {
  if (canUseDOM() && window.zE) {
    window.zE.apply(null, args);
  } else {
    console.warn("Zendesk is not initialized yet");
  }
};

export default class Zendesk extends Component<IZendeskProps> {
  constructor(props: IZendeskProps | Readonly<IZendeskProps>) {
    super(props);
    this.insertScript = this.insertScript.bind(this);
    this.onScriptLoaded = this.onScriptLoaded.bind(this);
  }

  onScriptLoaded() {
    if (typeof this.props.onLoaded === "function") {
      this.props.onLoaded();
    }
  }

  insertScript(zendeskKey?: string, defer?: boolean) {
    const script = document.createElement("script");
    if (defer) {
      script.defer = true;
    } else {
      script.async = true;
    }
    script.id = ZENDESK_CONFIG.ID;
    script.src = `${ZENDESK_CONFIG.URL}?key=${zendeskKey}`;
    script.addEventListener("load", this.onScriptLoaded);
    document.body.appendChild(script);
  }

  isExistZendeskKey(key?: string) {
    return typeof key === "string" && key;
  }

  componentDidMount() {
    if (canUseDOM() && !window.zE) {
      const { defer, zendeskKey, ...other } = this.props;
      if (this.isExistZendeskKey(zendeskKey)) {
        this.insertScript(zendeskKey, defer);
        window.zESettings = other;
      } else {
        console.warn("zendesk key is missing!");
      }
    }
  }

  componentWillUnmount() {
    if (!canUseDOM() || !window.zE) {
      return;
    }
    delete window.zE;
    delete window.zESettings;
  }

  render() {
    return null;
  }
}
