import Box from '@mui/material/Box';
import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { Table } from 'common/components';
import { CompanyAdminResponse } from 'core/models/companyAdminDto.model';
import { useAppDispatch, useAppSelector } from 'states/hooks';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  selectAction,
  selectCompanyAdminList,
  setAction,
  setIsModalOpen,
  setSelectedItem,
} from 'states/manageCompanyAdmin/manageCompanyAdminSlice';
import { IconButton } from '@mui/material';
import { Actions } from 'core/enums/action-enum';

interface IProps {
  data: CompanyAdminResponse[];
}

interface ICompanyAdminRow {
  id: string;
  fullName: string;
}

const CompanyAdminTable = ({ data }: IProps) => {
  const dispatch = useAppDispatch();
  const companyAdminList = useAppSelector(selectCompanyAdminList);
  const action = useAppSelector(selectAction);

  const onAction = (e: any, values: any, action: Actions) => {
    if (action === Actions.DELETE) {
      dispatch(setIsModalOpen(true));
    }
    dispatch(setAction(action));
    const item = companyAdminList.filter((item) => item.id === values.id)[0] || null;
    dispatch(setSelectedItem(item));
  };

  const columns: GridColDef[] = [
    {
      field: 'fullName',
      headerName: '',
      flex: 1,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'edit',
      headerName: '',
      width: 60,
      align: 'left',
      renderCell: (params) => {
        return !action || action === Actions.DELETE ? (
          <IconButton onClick={(e) => onAction(e, params, Actions.EDIT)}>
            <EditIcon />
          </IconButton>
        ) : (
          <></>
        );
      },
    },
    {
      field: 'delete',
      headerName: '',
      width: 60,
      align: 'left',
      renderCell: (params) => {
        return !action || action === Actions.DELETE ? (
          <IconButton onClick={(e) => onAction(e, params, Actions.DELETE)}>
            <DeleteIcon />
          </IconButton>
        ) : (
          <></>
        );
      },
    },
  ];

  const mappingDataForTableRows = (data: CompanyAdminResponse[]): ICompanyAdminRow[] | [] => {
    if (!!data.length) {
      return data.map((item: CompanyAdminResponse) => ({
        id: item.id || '',
        fullName: `${item.firstName} ${item.lastName}`,
      }));
    }
    return [];
  };
  return (
    <Box className="max-w-md">
      <Table
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            display: 'none',
          },
        }}
        rows={mappingDataForTableRows(data)}
        columns={columns}
        disableColumnMenu
        hideFooterPagination
        hideFooter
      />
    </Box>
  );
};

export default CompanyAdminTable;
