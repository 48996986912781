import { PlanStatus } from 'core/constants/manage-plan-data';
import { DriverLicensesResponse, FileResponse, PeopleDto } from 'core/models/peopleDto.model';
import { formatDateSendToServer } from 'core/functions/format-date';
import { CompanyDto } from 'core/models/companyDto.model';
import moment from 'moment';
import { PlanDetailDto, TransferPlanResponse } from 'core/models/PlanDetailDto.model';
import {
  ContributionStartDateEnum,
  EmployerMatchEnum,
  HarborContributionEnum,
} from 'core/enums/employer-onboarding.enum';
import { convertPhoneNumber, formatPhoneNumberDB } from 'utils/input-formatter';
import { PlanTypes } from 'core/enums/plan-type.enum';
import { IUploadPlanDTO } from 'core/models/UploadPlanRequest.model';
import { get, isEmpty } from 'lodash';
import { OnboardingPlaidStatus, OnboardingFinchStatus } from 'core/enums';
import { formatCurrency, formatCurrencyToNumber } from 'core/functions/format-currency';
import { complianceRadioName } from './ComplianceDisclosures';
import { reguatoryRadioName } from './RegulatoryDisclosures';
import { IMultipleBusinessesForm } from 'core/models/PlanDetailDto.model';
import { checkSSNExisted } from 'core/services/api.service';
import EmployerService from 'core/services/employer.service';

const harborByEmployerMatch = {
  [EmployerMatchEnum.BASIC_SAFE_HARBOR]: HarborContributionEnum.ONE_HUNDRED,
  [EmployerMatchEnum.ENHANCED_SAFE_HARBOR_AT_SIX_PERCENT]: HarborContributionEnum.ONE_HUNDRED,
  [EmployerMatchEnum.NON_ELECTIVE_SAFE_HARBOR_CONTRIBUTION]: HarborContributionEnum.THREE_PERCENT,
};

interface IManagePlanDataResponse {
  companyDetail?: CompanyDto;
  planDetails?: PlanDetailDto;
}

interface IUploadBankVerification {
  achForm: FileResponse;
  proofOfBankAccount: FileResponse;
}

const getDataByEmployerMatch = (employerMatch: EmployerMatchEnum, schedule: any) => {
  switch (employerMatch) {
    case EmployerMatchEnum.BASIC_SAFE_HARBOR:
      return {
        isSafeHarbor: true,
        isMatching: false,
        matchingSchedule: null,
        safeHarborContribution: harborByEmployerMatch[EmployerMatchEnum.BASIC_SAFE_HARBOR],
        safeHarborSchedule: schedule,
        safeHarborOneHundredDeferralUpto: 3,
        matchingContribution: null,
        safeHarborOneHundredContribDetail: {
          match: true,
          matchOnNextCompensation: 2,
          matchPercentageOfDeferral: 50,
        },
      };
    case EmployerMatchEnum.ENHANCED_SAFE_HARBOR_AT_SIX_PERCENT:
      return {
        isSafeHarbor: true,
        safeHarborContribution: harborByEmployerMatch[EmployerMatchEnum.ENHANCED_SAFE_HARBOR_AT_SIX_PERCENT],
        safeHarborOneHundredDeferralUpto: 6,
        safeHarborSchedule: schedule,
        isMatching: false,
        matchingSchedule: null,
        matchingContribution: null,
        safeHarborOneHundredContribDetail: {
          match: false,
          matchOnNextCompensation: 0,
          matchPercentageOfDeferral: 0,
        },
      };
    case EmployerMatchEnum.DISCRETIONARY_MATCH:
      return {
        isMatching: true,
        matchingContribution: EmployerMatchEnum.DISCRETIONARY_MATCH,
        matchingSchedule: schedule,
        isSafeHarbor: false,
        safeHarborSchedule: null,
        safeHarborContribution: null,
        safeHarborOneHundredDeferralUpto: null,
        safeHarborOneHundredContribDetail: {
          match: false,
          matchOnNextCompensation: 0,
          matchPercentageOfDeferral: 0,
        },
      };
    case EmployerMatchEnum.NON_ELECTIVE_SAFE_HARBOR_CONTRIBUTION:
      return {
        isMatching: false,
        matchingContribution: null,
        matchingSchedule: null,
        isSafeHarbor: true,
        safeHarborContribution: harborByEmployerMatch[EmployerMatchEnum.NON_ELECTIVE_SAFE_HARBOR_CONTRIBUTION],
        safeHarborSchedule: schedule,
        safeHarborOneHundredDeferralUpto: null,
        safeHarborOneHundredContribDetail: {
          match: false,
          matchOnNextCompensation: 0,
          matchPercentageOfDeferral: 0,
        },
      };
  }
};

export const initOverview = ({
  companyDetail,
  planDetails,
}: {
  companyDetail?: CompanyDto;
  planDetails?: PlanDetailDto;
}) => {
  return {
    id: planDetails?.id,
    createdOn: companyDetail?.createdOn ? moment.utc(companyDetail.createdOn) : moment(),
    companyStatus: companyDetail?.status || '',
    planStatus: planDetails?.status || '',
    brokerageAccountId: companyDetail?.brokerageAccountId || '',
  };
};

export const initSponsorDetails = ({
  companyDetail,
  employee,
}: {
  companyDetail?: CompanyDto;
  employee?: PeopleDto;
}) => {
  return {
    firstName: companyDetail?.legalContact?.firstName || '',
    lastName: companyDetail?.legalContact?.lastName || '',
    title: companyDetail?.legalContact?.title || '',
    email: companyDetail?.legalContact?.email || '',
    dob: employee?.dob ? moment.utc(employee.dob) : null,
    userName: employee?.userName || undefined,
    employeeId: employee?.id,
    phone: companyDetail?.legalContact?.phone ? formatPhoneNumberDB(companyDetail?.legalContact?.phone) : '',
    ssn: employee?.ssn ? atob(employee?.ssn) : undefined,
  };
};

export const initAccountAdmin = ({ account }: { account?: PeopleDto }) => {
  return {
    firstName: account?.firstName || '',
    lastName: account?.lastName || '',
    email: account?.email || '',
    jobTitle: account?.jobTitle || '',
    phone: account?.phone ? formatPhoneNumberDB(account?.phone) : '',
  };
};

export const initCompanyDetail = ({ companyDetail }: { companyDetail?: CompanyDto & { industry?: string } }) => {
  return {
    registedBusinessCompany: companyDetail?.companyName || '',
    businessAs: companyDetail?.businessAs || '',
    ein: companyDetail?.ein || '',
    registrationType: companyDetail?.legalInfo?.registrationType || '',
    stateRegistered: companyDetail?.legalInfo?.stateRegistered || '',
    city: companyDetail?.address?.city || '',
    state: companyDetail?.address?.state || '',
    street: companyDetail?.address?.street || '',
    zip: companyDetail?.address?.zip || '',
    businessCode: companyDetail?.businessCode || '',
    payFrequency: companyDetail?.payroll?.payFrequency || '',
    industry: companyDetail?.industry || '',
    companyURL: companyDetail?.domainName || '',
    asPhone: companyDetail?.contact?.phone ? formatPhoneNumberDB(companyDetail?.contact?.phone) : '',
  };
};

const initmultipleFormInfo = ({ complianceDisclosure }: { complianceDisclosure?: TransferPlanResponse }) => {
  return {
    otherBusinessRetirementPlan: convertBooleanValueToYesNo(complianceDisclosure?.otherBusinessRetirementPlan),
    eachBusinessPayroll: complianceDisclosure?.eachBusinessPayroll,
    numberOf80PercentOwnership: complianceDisclosure?.numberOf80PercentOwnership
      ? String(complianceDisclosure?.numberOf80PercentOwnership)
      : undefined,
  };
};

const initRetirementPlan = ({
  complianceDisclosure,
}: {
  complianceDisclosure?: TransferPlanResponse | IMultipleBusinessesForm;
}) => {
  return {
    financialInstitution: complianceDisclosure?.retirementPlan?.financialInstitution,
    type: complianceDisclosure?.retirementPlan?.type,
    roughEstimate: complianceDisclosure?.retirementPlan?.roughEstimate
      ? formatCurrency(complianceDisclosure?.retirementPlan?.roughEstimate, 0)
      : undefined,
    numberOfEmployee: complianceDisclosure?.retirementPlan?.numberOfEmployee
      ? String(complianceDisclosure?.retirementPlan?.numberOfEmployee)
      : undefined,
    adoptionAgreement: normalizeUpload(complianceDisclosure?.retirementPlan?.adoptionAgreement),
  };
};

export const initBusinesses = (multipleBusinesses?: IMultipleBusinessesForm[]) => {
  if (!multipleBusinesses) return;
  return {
    businesses: (multipleBusinesses || []).map((business) => ({
      ...business,
      hasRetirementPlan: business.retirementPlan ? 'yes' : 'no',
      retirementPlan: initRetirementPlan({ complianceDisclosure: business }),
    })),
  };
};

export const initFileValue = (): IUploadPlanDTO => {
  return {
    fileType: '',
    size: 0,
    name: '',
    file: undefined,
    isUpdatedFile: false,
  };
};

const normalizeUpload = (values: any) => {
  if (isEmpty(values)) return initFileValue();
  return { ...values, isUpdatedFile: false };
};

export const initUploadPlan = (values: any) => {
  return {
    plansummary: normalizeUpload(values?.summary),
    planbooklet: normalizeUpload(values?.booklet),
    planexecution: normalizeUpload(values?.execution),
    plantpaa: normalizeUpload(values?.tppa),
    planerisa: normalizeUpload(values?.erisa338Ima),
  };
};

export const initUploadDriverLicenses = (values?: DriverLicensesResponse[]) => {
  const driverLicenses = values?.length ? values[0] : undefined;
  return {
    driverLicenses: normalizeUpload(driverLicenses),
  };
};

export const initUploadBankVerification = ({ achForm, proofOfBankAccount }: Partial<IUploadBankVerification>) => {
  return {
    achForm: normalizeUpload(achForm),
    proofOfBankAccount: normalizeUpload(proofOfBankAccount),
  };
};

export const initContact = ({ companyDetail }: { companyDetail?: CompanyDto }) => {
  return {
    contactFirstName: companyDetail?.contact?.firstName || '',
    contactLastName: companyDetail?.contact?.lastName || '',
    contactEmail: companyDetail?.contact?.email || '',
  };
};

export const init401K = (planDetail?: PlanDetailDto) => {
  return {
    create401KPlan: planDetail ? 'yes' : 'no',
  };
};

const convertBooleanValueToYesNo = (value?: boolean | string) => {
  if (typeof value !== 'boolean') return '';
  return value ? 'yes' : 'no';
};

const convertYesNoToBooleanValue = (value?: string) => {
  if (typeof value === 'undefined' || !['yes', 'no'].includes(value)) return;
  return value === 'yes';
};

export const initComplianceDisclosure = ({ complianceDisclosure }: { complianceDisclosure?: TransferPlanResponse }) => {
  const ownersHaveMajorityStakeInAnotherBusiness = convertBooleanValueToYesNo(
    complianceDisclosure?.ownersHaveMajorityStakeInAnotherBusiness
  );
  const otherBusinessEmployeesIncludedIn401K =
    ownersHaveMajorityStakeInAnotherBusiness === 'no'
      ? ''
      : convertBooleanValueToYesNo(complianceDisclosure?.otherBusinessEmployeesIncludedIn401K);
  const isOfferingRetirementPlan = convertBooleanValueToYesNo(complianceDisclosure?.isOfferingRetirementPlan);
  const hasOfferedSimpleIRA =
    isOfferingRetirementPlan === 'no' ? '' : convertBooleanValueToYesNo(complianceDisclosure?.hasOfferedSimpleIRA);
  const totalEmployee = complianceDisclosure?.totalEmployee ? String(complianceDisclosure?.totalEmployee) : undefined;
  return {
    complianceDisclosure: {
      id: complianceDisclosure?.id,
      ownersHaveMajorityStakeInAnotherBusiness,
      otherBusinessEmployeesIncludedIn401K,
      isOfferingRetirementPlan,
      hasOfferedSimpleIRA,
      retirementPlan: initRetirementPlan({ complianceDisclosure }),
      companyId: complianceDisclosure?.companyId,
      totalEmployee,
      ...initmultipleFormInfo({ complianceDisclosure }),
    },
  };
};

export const initRegulatoryDisclosures = ({ companyDetail }: { companyDetail?: CompanyDto }) => {
  return {
    regulatoryDisclosure: {
      currentOrFormerPEPOfPublicOfficial: convertBooleanValueToYesNo(
        companyDetail?.regulatoryDisclosure?.currentOrFormerPEPOfPublicOfficial
      ),
      representFFI31CFR: convertBooleanValueToYesNo(companyDetail?.regulatoryDisclosure?.representFFI31CFR),
      representFFI31CFR103175: convertBooleanValueToYesNo(companyDetail?.regulatoryDisclosure?.representFFI31CFR103175),
      internalNote: companyDetail?.regulatoryDisclosure?.internalNote,
    },
  };
};

export const initAuthorizatedSignatory = ({ companyDetail }: { companyDetail?: CompanyDto }) => {
  return {
    hasCompanyContact: convertBooleanValueToYesNo(
      companyDetail && JSON.stringify(companyDetail?.contact) === JSON.stringify(companyDetail?.legalContact)
    ),
    asFirstName: companyDetail?.contact?.firstName,
    asLastName: companyDetail?.contact?.lastName,
    asEmail: companyDetail?.contact?.email,
    asTitle: companyDetail?.contact?.title,
    asPhone: companyDetail?.contact?.phone ? formatPhoneNumberDB(companyDetail?.contact?.phone) : '',
  };
};

const formatEmployerMatch = ({ planDetail }: { planDetail?: PlanDetailDto }) => {
  const employerMatch = planDetail?.matchingContribution || planDetail?.safeHarborContribution;
  const safeHarborOneHundredDeferralUpto = planDetail?.safeHarborOneHundredDeferralUpto;
  if (!employerMatch) return '';
  if (employerMatch === EmployerMatchEnum.DISCRETIONARY_MATCH) {
    return EmployerMatchEnum.DISCRETIONARY_MATCH;
  }
  if (employerMatch === HarborContributionEnum.ONE_HUNDRED) {
    if (safeHarborOneHundredDeferralUpto === 6) {
      return EmployerMatchEnum.ENHANCED_SAFE_HARBOR_AT_SIX_PERCENT;
    }
    if (safeHarborOneHundredDeferralUpto === 3) {
      return EmployerMatchEnum.BASIC_SAFE_HARBOR;
    }
  }
  return EmployerMatchEnum.NON_ELECTIVE_SAFE_HARBOR_CONTRIBUTION;
};

const getVesting = (data?: PlanDetailDto) => {
  if (data) {
    if (data.isSafeHarbor) {
      return data.safeHarborSchedule?.yearsForVesting || 0;
    }
    if (data.isMatching) {
      return data.matchingSchedule?.yearsForVesting || 0;
    }
  }
  return 0;
};

export const initPlanCustomizationTop = ({ planDetail }: { planDetail?: PlanDetailDto }) => {
  return {
    minAge: planDetail?.minAge || '',
    eligibilityStart: planDetail?.eligibilityStart || '',
    employerMatch: formatEmployerMatch({ planDetail }),
    profitSharing: convertBooleanValueToYesNo(planDetail?.isProfitSharing),
    vesting: getVesting(planDetail),
    contributionStartDate: planDetail?.contributionStartDate ? moment.utc(planDetail.contributionStartDate) : undefined,
    contributionStartDateOptions: !planDetail
      ? ''
      : !!planDetail?.contributionStartDate
      ? ContributionStartDateEnum.ON_SELECTED_DATE
      : ContributionStartDateEnum.AS_SOON_AS_POSSIBLE,
    excludeHCE: planDetail?.excludeHCE || false,
    employmentTypesToExclude: planDetail?.employmentTypesToExclude || [],
  };
};

export const initPlanCustomizationBottom = ({ planDetail }: { planDetail?: PlanDetailDto }) => {
  return {
    planType: planDetail?.planType || PlanTypes.BOTH,
    offerLoan: planDetail?.offerLoan ?? true,
    allowWithdrawal: planDetail?.allowWithdrawal ?? true,
    allowRollover: planDetail?.allowRollover ?? true,
    autoEnrollment: planDetail?.autoEnrollment ?? true,
  };
};

export const initAllowCompleteOnboarding = ({ companyDetail }: { companyDetail?: CompanyDto }) => {
  return {
    allowCompleteOnboarding: convertBooleanValueToYesNo(companyDetail?.allowCompleteOnboarding),
  };
};

const formatFinchStatus = (value?: OnboardingFinchStatus, hasPayrollProvider?: boolean) => {
  if (!value || !hasPayrollProvider) return;
  if (value === OnboardingFinchStatus.CONNECTED) {
    return 'Successful';
  }
  if ([OnboardingFinchStatus.DISCONNECTED, OnboardingFinchStatus.NaN].includes(value)) {
    return 'Integration Unavailable';
  }
  return 'Unsuccessful';
};

const formatPlaidStatus = (plaidStatus?: OnboardingPlaidStatus, hasBankAccount = false) => {
  if (!plaidStatus) return;
  const { ASSISTED_CONNECTION, INTEGRATED, USED_ALTERNATIVE_VERIFICATION } = OnboardingPlaidStatus;
  const isUsedAlternative = [OnboardingPlaidStatus.NaN, ASSISTED_CONNECTION].includes(plaidStatus) && hasBankAccount;
  if (plaidStatus === INTEGRATED) {
    return 'Successful';
  } else if ([ASSISTED_CONNECTION, USED_ALTERNATIVE_VERIFICATION].includes(plaidStatus) || isUsedAlternative) {
    return 'Used Alternative Verification';
  } else {
    return 'No attempt made';
  }
};

export const initPlaidApexFinchInfo = ({ companyDetail }: { companyDetail?: CompanyDto }) => {
  const hasPayrollProvider = !!companyDetail?.payroll?.payrollProvider;
  return {
    finchStatus: formatFinchStatus(companyDetail?.finchOnboardingStatus, hasPayrollProvider),
    plaidStatus: formatPlaidStatus(companyDetail?.plaidOnboardingStatus, !!companyDetail?.bankAccount),
    apexStatus: companyDetail?.achRelationId ? 'Active' : 'Not active',
    payrollProvider: companyDetail?.payroll?.payrollProvider,
  };
};

export const showVesting = (employerMatch?: string, profitSharing?: string) => {
  if (!employerMatch || !profitSharing) return false;
  if (employerMatch && employerMatch !== EmployerMatchEnum.DISCRETIONARY_MATCH && profitSharing === 'no') {
    return false;
  }
  return true;
};

export const initBankVerification = ({ companyDetail }: IManagePlanDataResponse) => {
  return {
    bankAccount: {
      bankName: companyDetail?.bankAccount?.bankName,
      bankAccountName: companyDetail?.bankAccount?.bankAccountName,
      bankAccountNumber: companyDetail?.bankAccount?.bankAccountNumber,
      bankRoutingNumber: companyDetail?.bankAccount?.bankRoutingNumber,
    },
  };
};

const getSchedule = (vesting: number | null, showVesting: boolean) => {
  if (showVesting && vesting) {
    const contributionSchedule = vesting ? Array(vesting).fill(0) : [];
    if (contributionSchedule?.length) {
      contributionSchedule[0] = 100;
    }
    return {
      yearsForVesting: vesting,
      contributionSchedule,
    };
  } else {
    return {
      yearsForVesting: 0,
      contributionSchedule: null,
    };
  }
};

const getProfitSharingValue = (isProfitSharing?: string, profitSchedule?: any) => {
  const profitSharing = convertYesNoToBooleanValue(isProfitSharing);
  const initProfitSharing = {
    isProfitSharing: profitSharing,
    profitSchedule: null,
    profitSharing: null,
    proRataFrequency: null,
  };

  if (profitSharing) {
    return {
      ...initProfitSharing,
      profitSharing: 'PRO_RATA_FREQUENCY',
      proRataFrequency: 'DISCRETIONARY',
      profitSchedule,
    };
  }
  return initProfitSharing;
};

const getAutoEnrollmentValue = (isAutoEnrollment?: boolean) => {
  const initAutoEnrollment = {
    autoEnrollment: isAutoEnrollment,
    autoEnrollmentIncrementPerYear: undefined,
    autoEnrollmentAt: undefined,
  };
  if (isAutoEnrollment) {
    return {
      ...initAutoEnrollment,
      autoEnrollmentAt: '3',
      autoEnrollmentIncrementPerYear: '1',
    };
  }
  return initAutoEnrollment;
};

const formatPlan = (values: any) => {
  const isShowVesting = showVesting(values?.employerMatch, values?.profitSharing);
  const schedule = getSchedule(values.vesting, isShowVesting);
  const profitSharingValue = getProfitSharingValue(values?.profitSharing, schedule);
  const employerMathValue = getDataByEmployerMatch(values?.employerMatch, schedule);
  const autoEnrollmentValue = getAutoEnrollmentValue(values?.autoEnrollment);
  const contributionStartDate =
    values?.contributionStartDateOptions === ContributionStartDateEnum.AS_SOON_AS_POSSIBLE
      ? undefined
      : values?.contributionStartDate
      ? formatDateSendToServer(values?.contributionStartDate)
      : undefined;

  return {
    ...profitSharingValue,
    ...employerMathValue,
    ...autoEnrollmentValue,
    createdOn: values?.createdOn,
    status: values?.planStatus || values?.status || PlanStatus.DRAFT,
    minAge: values?.minAge,
    eligibilityStart: values?.eligibilityStart || undefined,
    contributionStartDate,
    excludeHCE: values?.excludeHCE,
    employmentTypesToExclude: values?.employmentTypesToExclude || [],
    planType: values?.planType,
    offerLoan: values?.offerLoan,
    allowWithdrawal: values?.allowWithdrawal,
    allowRollover: values?.allowRollover,
    id: values?.planId || undefined,
  };
};

const normalizeRetirementPlan = (values: any) => {
  if (['', 'no'].includes(values?.complianceDisclosure?.isOfferingRetirementPlan)) {
    return;
  }
  return {
    financialInstitution: values?.complianceDisclosure?.retirementPlan?.financialInstitution,
    type: values?.complianceDisclosure?.retirementPlan?.type,
    roughEstimate: formatCurrencyToNumber(values?.complianceDisclosure?.retirementPlan?.roughEstimate),
    numberOfEmployee: +values?.complianceDisclosure?.retirementPlan?.numberOfEmployee,
  };
};

const normalizeCompleteOnboarding = (values: any) => {
  return [undefined, ''].includes(values?.allowCompleteOnboarding)
    ? undefined
    : convertYesNoToBooleanValue(values?.allowCompleteOnboarding);
};

const formatSSN = (ssn: string) => {
  if (!ssn) return;
  if (ssn.lastIndexOf('-') < 0) {
    ssn = `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5)}`;
  }
  return btoa(ssn);
};

export const formatBasicPlanValue = (values: any) => {
  const cloneEmployers = values?.employers?.length ? [...values?.employers] : [];
  const authorizatedSignatoryValue = getAuthorizatedSignatoryValue({
    ...values,
    ...cloneEmployers[0],
    title: cloneEmployers[0]?.jobTitle,
  });
  const employers = cloneEmployers.length
    ? cloneEmployers.map(
        (
          employer: PeopleDto & {
            accountId?: string;
          },
          idx: number
        ) => ({
          phone: employer?.phone && convertPhoneNumber(employer?.phone),
          dob: idx === 0 ? moment.utc(values?.dob) : undefined,
          ssn: idx === 0 ? formatSSN(values?.ssn || '') : undefined,
          userName: idx === 0 ? values?.userName : undefined,
          email: employer?.email,
          firstName: employer?.firstName,
          lastName: employer?.lastName,
          id: employer?.accountId,
          jobTitle: employer?.jobTitle,
        })
      )
    : undefined;
  const retirementPlan = normalizeRetirementPlan(values);
  const complianceDisclosure = normalizeComplianceDisclosure(values);
  const legalInfo = normalizeLegalInfo(values);
  const address = normalizeAddress(values);
  const regulatoryDisclosure = nomalizeRegulatoryDisclosure(values);
  const planValue = formatPlan(values);
  const allowCompleteOnboarding = normalizeCompleteOnboarding(values);
  const businesses = normalizeMultipleBusinesses(values?.businesses);

  const payroll = {
    payFrequency: values?.payFrequency,
  };

  const companyValue = {
    type: values?.type,
    companyName: values?.registedBusinessCompany,
    businessAs: values?.businessAs,
    businessCode: values.businessCode,
    brokerageAccountId: values?.brokerageAccountId,
    status: values?.companyStatus || undefined,
    ein: values.ein,
    legalInfo,
    address,
    regulatoryDisclosure,
    contact: authorizatedSignatoryValue.contact,
    legalContact: authorizatedSignatoryValue.legalContact,
    industry: values?.industry,
    phone: values?.phone ? convertPhoneNumber(values?.phone) : undefined,
    payroll: checkAndNullifyObject(payroll),
  };

  return {
    plans: planValue,
    company: companyValue,
    complianceDisclosure: {
      ...complianceDisclosure,
      retirementPlan,
    },
    employers,
    allowCompleteOnboarding,
    businesses,
  };
};

export const formatPEPPlanValue = (values: any) => {
  const legalInfo = {
    stateRegistered: values?.stateRegistered,
    registrationType: values?.registrationType,
  };

  const address = {
    street: values?.street,
    city: values?.city,
    state: values?.state,
    zip: values?.zip,
  };

  const plans = (values?.plans || []).map(formatPlan);

  const employers = (values?.employers || []).map((employer: any) => ({
    email: employer?.email,
    firstName: employer?.firstName,
    lastName: employer?.lastName,
    id: employer?.accountId,
    jobTitle: employer?.jobTitle,
    phone: employer?.phone ? convertPhoneNumber(employer?.phone) : undefined,
  }));

  const contact = {
    firstName: values?.contactFirstName,
    lastName: values?.contactLastName,
    email: values?.contactEmail,
    phone: convertPhoneNumber(values?.asPhone),
  };

  const company = {
    type: values?.type,
    companyName: values?.registedBusinessCompany,
    businessAs: values?.businessAs || undefined,
    brokerageAccountId: values?.brokerageAccountId,
    ein: values.ein,
    legalInfo,
    address,
    contact,
    id: values.id || undefined,
  };

  return { company, employers, plans };
};

const normalizeContact = (values: any) => {
  return {
    firstName: values?.asFirstName || undefined,
    lastName: values?.asLastName || undefined,
    email: values?.asEmail || undefined,
    title: values?.asTitle,
    phone: values?.asPhone ? convertPhoneNumber(values?.asPhone) : undefined,
  };
};

const checkAndNullifyObject = (obj: { [key: string]: any }) => {
  if (isEmpty(obj)) {
    return;
  }
  return Object.keys(obj).every((key) => [undefined, null, ''].includes(obj[key])) ? undefined : obj;
};

const normalizeLegalInfo = (values: any) => {
  const legalInfo = {
    stateRegistered: values?.stateRegistered || undefined,
    registrationType: values?.registrationType || undefined,
  };
  return checkAndNullifyObject(legalInfo);
};

const normalizeAddress = (values: any) => {
  const address = {
    street: values?.street || undefined,
    city: values?.city || undefined,
    state: values?.state || undefined,
    zip: values?.zip || undefined,
  };
  return checkAndNullifyObject(address);
};

const normalizeMultipleBusinesses = (multipleBusinesses: IMultipleBusinessesForm[]) => {
  if (!multipleBusinesses) return;
  if (Array.isArray(multipleBusinesses)) {
    return multipleBusinesses.map((item) => ({
      ...item,
      retirementPlan:
        item.hasRetirementPlan === 'yes'
          ? {
              ...item.retirementPlan,
              roughEstimate: item.retirementPlan?.roughEstimate
                ? formatCurrencyToNumber(String(item.retirementPlan.roughEstimate))
                : undefined,
            }
          : undefined,
      hasRetirementPlan: undefined,
    }));
  }
};

const normalizeComplianceDisclosure = (values: any) => {
  return {
    id: values?.complianceDisclosure?.id,
    ownersHaveMajorityStakeInAnotherBusiness: convertYesNoToBooleanValue(
      values?.complianceDisclosure?.ownersHaveMajorityStakeInAnotherBusiness
    ),
    otherBusinessEmployeesIncludedIn401K: convertYesNoToBooleanValue(
      values?.complianceDisclosure?.otherBusinessEmployeesIncludedIn401K
    ),
    isOfferingRetirementPlan: convertYesNoToBooleanValue(values?.complianceDisclosure?.isOfferingRetirementPlan),
    hasOfferedSimpleIRA: convertYesNoToBooleanValue(values?.complianceDisclosure?.hasOfferedSimpleIRA),
    companyId: values?.complianceDisclosure?.companyId,
    numberOf80PercentOwnership: +values?.complianceDisclosure?.numberOf80PercentOwnership,
    eachBusinessPayroll: values?.complianceDisclosure?.eachBusinessPayroll,
    otherBusinessRetirementPlan: convertYesNoToBooleanValue(values?.complianceDisclosure?.otherBusinessRetirementPlan),
    totalEmployee: +values?.complianceDisclosure?.totalEmployee,
  };
};

const nomalizeRegulatoryDisclosure = (values: any) => {
  return checkAndNullifyObject({
    ...values?.regulatoryDisclosure,
    currentOrFormerPEPOfPublicOfficial: convertYesNoToBooleanValue(
      values?.regulatoryDisclosure?.currentOrFormerPEPOfPublicOfficial
    ),
    representFFI31CFR: convertYesNoToBooleanValue(values?.regulatoryDisclosure?.representFFI31CFR),
    representFFI31CFR103175: convertYesNoToBooleanValue(values?.regulatoryDisclosure?.representFFI31CFR103175),
  });
};

const normalizeLegalContact = (values: any) => {
  return {
    firstName: values?.firstName || undefined,
    lastName: values?.lastName || undefined,
    email: values?.email || undefined,
    title: values?.title,
    phone: values?.phone ? convertPhoneNumber(values?.phone) : undefined,
  };
};

const getAuthorizatedSignatoryValue = (values: any) => {
  const hasCompanyContactValue = convertYesNoToBooleanValue(values?.hasCompanyContact);
  const legalContact = normalizeLegalContact(values);

  if ([undefined, ''].includes(values?.hasCompanyContact) || hasCompanyContactValue) {
    return {
      contact: legalContact,
      legalContact,
    };
  } else {
    return {
      contact: normalizeContact(values),
      legalContact,
    };
  }
};

export const formatCPAPlanValue = (values: any) => {
  const legalInfo = normalizeLegalInfo(values);
  const address = normalizeAddress(values);
  const complianceDisclosure = normalizeComplianceDisclosure(values);
  const retirementPlan = normalizeRetirementPlan(values);
  const regulatoryDisclosure = nomalizeRegulatoryDisclosure(values);
  const planValue = formatPlan(values);
  const create401KPlan = convertYesNoToBooleanValue(values?.create401KPlan);
  const cloneEmployers = values?.employers.length ? [...values?.employers] : [];
  const legalContact = values?.employers.length
    ? {
        ...values?.employers[0],
        title: values?.employers[0]?.jobTitle,
        phone: values?.employers[0]?.phone && convertPhoneNumber(values?.employers[0]?.phone),
        jobTitle: undefined,
        accountId: undefined,
      }
    : undefined;
  const allowCompleteOnboarding = normalizeCompleteOnboarding(values);
  const businesses = normalizeMultipleBusinesses(values?.businesses);

  const employers = cloneEmployers.length
    ? cloneEmployers.map(
        (
          employer: PeopleDto & {
            accountId?: string;
          },
          idx: number
        ) => ({
          phone: employer?.phone && convertPhoneNumber(employer?.phone),
          dob: idx === 0 ? moment.utc(values?.dob) : undefined,
          ssn: idx === 0 ? formatSSN(values?.ssn || '') : undefined,
          email: employer?.email,
          firstName: employer?.firstName,
          lastName: employer?.lastName,
          id: employer?.accountId,
          jobTitle: employer?.jobTitle,
          userName: idx === 0 ? values?.userName : undefined,
        })
      )
    : undefined;

  const companyValue = {
    type: values?.type,
    companyName: values?.registedBusinessCompany || undefined,
    businessAs: values?.businessAs || undefined,
    businessCode: values.businessCode || undefined,
    payroll: checkAndNullifyObject({
      payFrequency: values?.payFrequency,
    }),
    brokerageAccountId: values?.brokerageAccountId || undefined,
    status: values?.companyStatus || undefined,
    ein: values.ein || undefined,
    legalInfo: create401KPlan ? legalInfo : null,
    address,
    regulatoryDisclosure,
    contact: {
      firstName: values?.contactFirstName || undefined,
      lastName: values?.contactLastName || undefined,
      email: values?.contactEmail || undefined,
      phone: values?.asPhone ? convertPhoneNumber(values?.asPhone) : undefined,
    },
    legalContact: create401KPlan ? legalContact : undefined,
    industry: values?.industry || undefined,
    domainName: values?.companyURL || undefined,
  };

  const mergeComplianceDisclosure = {
    ...complianceDisclosure,
    retirementPlan,
  };

  return {
    create401KPlan,
    plans: planValue,
    company: companyValue,
    complianceDisclosure: mergeComplianceDisclosure,
    employers,
    allowCompleteOnboarding,
    businesses,
  };
};

export const formatCorporatePlatform = (values: any) => {
  const legalInfo = normalizeLegalInfo(values);
  const address = normalizeAddress(values);
  const complianceDisclosure = normalizeComplianceDisclosure(values);
  const retirementPlan = normalizeRetirementPlan(values);
  const regulatoryDisclosure = nomalizeRegulatoryDisclosure(values);
  const allowCompleteOnboarding = normalizeCompleteOnboarding(values);
  const businesses = normalizeMultipleBusinesses(values?.businesses);
  const cloneEmployers = values?.employers.length ? [...values?.employers] : [];
  const authorizatedSignatoryValue = getAuthorizatedSignatoryValue({
    ...values,
    ...cloneEmployers[0],
    title: cloneEmployers[0]?.jobTitle,
  });
  const planValue = formatPlan(values);
  const employers = cloneEmployers.length
    ? cloneEmployers.map(
        (
          employer: PeopleDto & {
            accountId?: string;
          },
          idx: number
        ) => ({
          phone: employer?.phone && convertPhoneNumber(employer?.phone),
          dob: idx === 0 ? moment.utc(values?.dob) : undefined,
          ssn: idx === 0 ? formatSSN(values?.ssn || '') : undefined,
          userName: idx === 0 ? values?.userName : undefined,
          email: employer?.email,
          firstName: employer?.firstName,
          lastName: employer?.lastName,
          id: employer?.accountId,
          jobTitle: employer?.jobTitle,
        })
      )
    : undefined;

  const payroll = {
    payFrequency: values?.payFrequency,
  };

  const companyValue = {
    type: values?.type,
    companyName: values?.registedBusinessCompany || undefined,
    businessAs: values?.businessAs || undefined,
    businessCode: values.businessCode || undefined,
    payroll: checkAndNullifyObject(payroll),
    brokerageAccountId: values?.brokerageAccountId || undefined,
    status: values?.companyStatus || undefined,
    ein: values.ein || undefined,
    legalInfo,
    address,
    regulatoryDisclosure,
    contact: authorizatedSignatoryValue.contact,
    legalContact: authorizatedSignatoryValue.legalContact,
    industry: values?.industry || undefined,
    partOfMultiBusinessAccount: convertYesNoToBooleanValue(values?.partOfMultiBusinessAccount),
  };

  return {
    plans: planValue,
    company: companyValue,
    complianceDisclosure: {
      ...complianceDisclosure,
      retirementPlan,
    },
    employers,
    allowCompleteOnboarding,
    businesses,
  };
};

export const initMultiBusinessAccount = (company?: CompanyDto) => {
  return {
    partOfMultiBusinessAccount: company?.partOfMultiBusinessAccount ? 'yes' : 'no',
  };
};

export const setFormName = (prefixName?: string, isArray?: boolean, idx?: number) => {
  return (name: string) => {
    if (!prefixName || !isArray) return name;
    return `${prefixName}[${idx}].${name}`;
  };
};

export const validateShowSubmitModal = (values: any) => {
  const isOfferingRetirementPlan = get(values, complianceRadioName.isOffering);
  const currentOrFormer = get(values, reguatoryRadioName.currentOrFormer);
  const FFI31CFR = get(values, reguatoryRadioName.FFI31CFR);
  const FI31CFR103175 = get(values, reguatoryRadioName.FI31CFR103175);

  return [isOfferingRetirementPlan, currentOrFormer, FFI31CFR, FI31CFR103175].includes('yes');
};

export const validateComplianceDisclosure = (values: any) => {
  const isOffering = get(values, complianceRadioName.isOffering);
  const owner = get(values, complianceRadioName.owner);
  const otherBusiness = get(values, complianceRadioName.otherBusiness);
  const hasOfferedSimple = get(values, complianceRadioName.hasOfferedSimple);
  if (!["yes", "no"].includes(owner) || !["yes", "no"].includes(isOffering)) {
    return true;
  }
  if (owner === "yes") {
    return !["yes", "no"].includes(otherBusiness);
  }
  if (isOffering === 'yes') {
    return !['yes', 'no'].includes(hasOfferedSimple);
  }
};

export const validateRegulatoryDisclosure = (values: any) => {
  const currentOrFormer = get(values, reguatoryRadioName.currentOrFormer);
  const FFI31CFR = get(values, reguatoryRadioName.FFI31CFR);
  const FI31CFR103175 = get(values, reguatoryRadioName.FI31CFR103175);

  return (
    !['yes', 'no'].includes(currentOrFormer) ||
    !['yes', 'no'].includes(FFI31CFR) ||
    !['yes', 'no'].includes(FI31CFR103175)
  );
};

export const showAllowOnboardingQuestion = (values?: string[]) => {
  return Array.isArray(values) ? values.some((item) => item === 'yes') : false;
};

export const sortAdmins = (arr: PeopleDto[], companyDetail?: CompanyDto) => {
  const cloneData = [...arr];
  const currentPlanAdminIdx = cloneData.findIndex((item) => item?.email === companyDetail?.legalContact?.email);

  return [...(currentPlanAdminIdx === -1 ? [] : cloneData.splice(currentPlanAdminIdx, 1)), ...cloneData];
};

export const checkSSNExistedInDB = async (ssnValue: string) => {
  const ssnEncodedValue = window.btoa(ssnValue);
  const response = await checkSSNExisted(ssnEncodedValue);
  return response.data.existing;
};

export const checkExistingPersonByEmail = async (email: string) => {
  const res = await EmployerService.findExistingPersonByEmail({ email });
  return !!res.data;
};
