import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompanyDto } from 'core/models/companyDto.model';
import { ContributionsLimitDto } from 'core/models/ContributionsLimitDto.model';
import { OtherMatchingFormulaValuesDto } from 'core/models/OtherMatchingFormulaValuesDto.model';
import { PlanDetailDto, TransferPlanResponse } from 'core/models/PlanDetailDto.model';
import { DriverLicensesResponse, FileResponse, PeopleDto } from 'core/models/peopleDto.model';
import { UpdatePlanStatusDto } from 'core/models/UpdatePlanStatusDto.model';
import { UploadFileDto } from 'core/models/UploadFileDto.model';
import ApiService from 'core/services/api.service';
import { setIsDisplay } from 'states/snackbarMessage/snackbarMessageSlice';
import { RootState, store } from 'states/store';
import { IMultipleBusinessesForm } from 'core/models/PlanDetailDto.model';
import { RequestStatus } from 'core/enums/request-status.enum';
import { sortAdmins } from 'components/employerManagePlan/init-func';
interface IPEPManagePlanDetail {
  company?: CompanyDto;
  employers: PeopleDto[];
  plans?: PlanDetailDto[];
  planTransfer?: TransferPlanResponse;
  complianceDisclosure?: TransferPlanResponse;
  driverLicenses?: DriverLicensesResponse[];
  businesses?: IMultipleBusinessesForm[];
  achForm?: FileResponse;
  proofOfBankAccount?: FileResponse;
}
interface PlanState {
  loading: boolean;
  error: string;
  success: boolean;
  otherMatchingFormulaValues: OtherMatchingFormulaValuesDto[];
  contributionsLimitList: ContributionsLimitDto[];
  planDetails: PlanDetailDto[];
  uploadFile?: UploadFileDto;
  pepManagePlanDetail: IPEPManagePlanDetail;
  requestStatus?: RequestStatus;
}

const initPEPManagePlanDetail = {
  company: undefined,
  employers: [],
  plans: [],
};

const initialState: PlanState = {
  loading: false,
  error: '',
  success: false,
  otherMatchingFormulaValues: [],
  contributionsLimitList: [],
  planDetails: [],
  uploadFile: undefined,
  pepManagePlanDetail: initPEPManagePlanDetail,
  requestStatus: undefined,
};

export const createPEPManagePlan = createAsyncThunk('plan/createPEPManagePlan', async (data: any): Promise<any> => {
  const response = await ApiService.createPEPManagePlan(data);
  return response.data;
});

export const getPEPManagePlanDetail = createAsyncThunk(
  'plan/getPEPManagePlanDetail',
  async (companyId: string): Promise<any> => {
    const response = await ApiService.getPEPManagePlanDetail(companyId);
    return response.data;
  }
);

export const updatePEPManagePlan = createAsyncThunk('plan/updatePEPManagePlan', async (data: any): Promise<any> => {
  const response = await ApiService.updatePEPManagePlan(data);
  return response.data;
});

export const createDraftManagePlan = createAsyncThunk('plan/createDraftManagePlan', async (data: any): Promise<any> => {
  const response = await ApiService.createDraftManagePlan(data);
  return response.data;
});

export const updateDraftManagePlan = createAsyncThunk('plan/updateDraftManagePlan', async (data: any): Promise<any> => {
  const response = await ApiService.updateDraftManagePlan(data);
  return response.data;
});

export const updateListPEPMasterPlanActive = createAsyncThunk(
  'plan/updateListPEPMasterPlanActive',
  async (data: any): Promise<any> => {
    const response = await ApiService.updateListPEPManagePlanActive(data);
    return response.data;
  }
);

export const getOtherMatchingFormulaValuesAsync = createAsyncThunk(
  'plan/getOtherMatchingFormulaValuesAsync',
  async (): Promise<any> => {
    const response = await ApiService.getOtherMatchingFormulaValues();
    return response.data;
  }
);

export const getContributionsLimitAsync = createAsyncThunk(
  'plan/getContributionsLimitAsync',
  async (): Promise<any> => {
    const response = await ApiService.getContributionsLimit();
    return response.data;
  }
);

export const getPlanDetailAsync = createAsyncThunk(
  'plan/getPlanDetailAsync',
  async (planDetailId: string | undefined): Promise<any> => {
    const response = await ApiService.getPlanDetail(planDetailId);
    return response.data;
  }
);

export const updatePlanStatusAsync = createAsyncThunk(
  'plan/updatePlanStatusAsync',
  async (request: UpdatePlanStatusDto): Promise<any> => {
    const { planId, status } = request;
    const response = await ApiService.updatePlanStatus(planId, status);
    return response.data;
  }
);

const planSlice = createSlice({
  name: 'plan',
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = '';
    },
    resetPEPManagePlan: (state) => {
      state.pepManagePlanDetail = initPEPManagePlanDetail;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOtherMatchingFormulaValuesAsync.pending, (state) => {
        state.error = '';
      })
      .addCase(getOtherMatchingFormulaValuesAsync.rejected, (state, action) => {
        state.error = JSON.parse(action.error.message ?? '').error as string;
      })
      .addCase(
        getOtherMatchingFormulaValuesAsync.fulfilled,
        (state, action: PayloadAction<OtherMatchingFormulaValuesDto[]>) => {
          state.otherMatchingFormulaValues = action.payload;
        }
      )
      .addCase(getContributionsLimitAsync.pending, (state) => {
        state.error = '';
      })
      .addCase(getContributionsLimitAsync.rejected, (state, action) => {
        state.error = JSON.parse(action.error.message ?? '').error as string;
      })
      .addCase(getContributionsLimitAsync.fulfilled, (state, action: PayloadAction<ContributionsLimitDto[]>) => {
        state.contributionsLimitList = action.payload;
      })
      .addCase(getPlanDetailAsync.pending, (state) => {
        state.error = '';
        state.loading = true;
        state.planDetails = [];
        state.requestStatus = RequestStatus.PENDING;
      })
      .addCase(getPlanDetailAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = JSON.parse(action.error.message ?? '').error as string;
        state.requestStatus = RequestStatus.REJECTED;
      })
      .addCase(getPlanDetailAsync.fulfilled, (state, action: PayloadAction<PlanDetailDto[]>) => {
        state.loading = false;
        state.planDetails = action.payload ?? [];
        state.requestStatus = RequestStatus.FULFILLED;
      })
      .addCase(updatePlanStatusAsync.pending, (state) => {
        state.error = '';
        state.loading = true;
      })
      .addCase(updatePlanStatusAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = JSON.parse(action.error.message ?? '').error as string;
        setTimeout(() => {
          store.dispatch(setIsDisplay(false));
        }, 0);
      })
      .addCase(getPEPManagePlanDetail.pending, (state, action) => {
        state.error = '';
        state.loading = true;
      })
      .addCase(getPEPManagePlanDetail.rejected, (state, action) => {
        state.loading = false;
        state.error = JSON.parse(action.error.message ?? '').error as string;
      })
      .addCase(getPEPManagePlanDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.pepManagePlanDetail =
          {
            ...action.payload,
            employers: sortAdmins(action.payload?.employers, action.payload?.company),
          } ?? [];
      })
      .addCase(createPEPManagePlan.pending, (state, action) => {
        state.error = '';
        state.loading = true;
      })
      .addCase(createPEPManagePlan.rejected, (state, action) => {
        state.loading = false;
        state.error = JSON.parse(action.error.message ?? '').error as string;
      })
      .addCase(createPEPManagePlan.fulfilled, (state, action) => {
        state.loading = false;
        state.pepManagePlanDetail = action.payload ?? [];
      })
      .addCase(updatePEPManagePlan.pending, (state, action) => {
        state.error = '';
        state.loading = true;
      })
      .addCase(updatePEPManagePlan.rejected, (state, action) => {
        state.loading = false;
        state.error = JSON.parse(action.error.message ?? '').error as string;
      })
      .addCase(updatePEPManagePlan.fulfilled, (state, action) => {
        state.loading = false;
        state.pepManagePlanDetail = action.payload ?? [];
      })
      .addCase(updateListPEPMasterPlanActive.pending, (state, action) => {
        state.error = '';
        state.loading = true;
      })
      .addCase(updateListPEPMasterPlanActive.rejected, (state, action) => {
        state.loading = false;
        state.error = JSON.parse(action.error.message ?? '').error as string;
      })
      .addCase(updateListPEPMasterPlanActive.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createDraftManagePlan.pending, (state, action) => {
        state.error = '';
        state.loading = true;
      })
      .addCase(createDraftManagePlan.rejected, (state, action) => {
        state.loading = false;
        state.error = JSON.parse(action.error.message ?? '').error as string;
      })
      .addCase(createDraftManagePlan.fulfilled, (state, action) => {
        state.loading = false;
        state.pepManagePlanDetail = action.payload ?? [];
      })
      .addCase(updateDraftManagePlan.pending, (state, action) => {
        state.error = '';
        state.loading = true;
      })
      .addCase(updateDraftManagePlan.rejected, (state, action) => {
        state.loading = false;
        state.error = JSON.parse(action.error.message ?? '').error as string;
      })
      .addCase(updateDraftManagePlan.fulfilled, (state, action) => {
        state.loading = false;
        state.pepManagePlanDetail = action.payload ?? [];
      });
  },
});

export const { resetError, resetPEPManagePlan } = planSlice.actions;

export const selectOtherMatchingFormulaValues = (state: RootState) => state.plan.otherMatchingFormulaValues;

export const selectPlanError = (state: RootState) => state.plan.error;

export const selectPlanLoading = (state: RootState) => state.plan.loading;

export const selectPEPManagePlanDetail = (state: RootState) => state.plan.pepManagePlanDetail;

const planSliceReducer = planSlice.reducer;
export default planSliceReducer;
