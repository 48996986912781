import { Typography, Box, Stack, Grid } from "@mui/material";
import TotalYTDToggleButton from "./TotalYTDToggleButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useMemo, useState } from "react";
import { useAppSelector } from "states/hooks";
import { Tooltip } from "common/components";

const formatCurrency = (num: number): string => {
  return new Intl.NumberFormat("us-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    currencyDisplay: "narrowSymbol",
  }).format(num);
};
export function RateAndGains() {
  const [subType, setSubType] = useState("total");
  const profileDashboardInfo = useAppSelector(
    (root) => root.manageEmployee.dashboardInfos
  );
  const rateOfReturn = useMemo(() => {
    return Math.round(
        ((subType === "ytd"
          ? profileDashboardInfo?.performance?.ytdRateOfReturn
          : profileDashboardInfo?.performance?.totalRateOfReturn) ?? 0) * 100
    );
  }, [subType, profileDashboardInfo]);

  const gainsAndLosses = useMemo(() => {
    return (
      (subType === "ytd"
        ? profileDashboardInfo?.performance?.ytdGainsAndLosses
        : profileDashboardInfo?.performance?.totalGainsAndLosses) ?? 0
    );
  }, [subType, profileDashboardInfo]);

  return (
    <Box
      sx={{
        padding: 1,
        maxWidth: "100%",
        minHeight: "82px",
        border: "1px solid #E0DEC9",
        borderRadius: "8px",
      }}
    >
      <Grid container marginLeft={{ zero: 0, xs: 1.5 }}>
        <Grid item zero={6}>
          <Stack direction={{ zero: "column", xs: "row" }} spacing={1}>
            <Box>
              <Typography
                component={"span"}
                color={"#9E9E91"}
                fontSize={"12px"}
                fontWeight={600}
                lineHeight={"18px"}
              >
                Rate of Return
              </Typography>{" "}
              <Tooltip
                title={
                  "The percentage change in the value of your 401(k) investments over a specific period, reflecting the overall performance of your investments."
                }
              >
                <InfoOutlinedIcon className="w-3" sx={{ color: "#9E9E91" }} />
              </Tooltip>
              <Typography
                fontWeight={600}
                fontSize={"20px"}
                lineHeight={"32px"}
              >
                {`${rateOfReturn}%`}
              </Typography>
            </Box>
            <Box>
              <Typography
                component={"span"}
                color={"#9E9E91"}
                fontSize={"12px"}
                fontWeight={600}
                lineHeight={"18px"}
              >
                Gains & Losses
              </Typography>{" "}
              <Tooltip
                title={
                  "The increases (gains) or decreases (losses) in the value of your 401(k) investments due to changes in the market or performance of the underlying assets."
                }
              >
                <InfoOutlinedIcon className="w-3" sx={{ color: "#9E9E91" }} />
              </Tooltip>
              <Typography
                fontWeight={600}
                fontSize={"20px"}
                lineHeight={"32px"}
              >
                {`${formatCurrency(gainsAndLosses)}`}
              </Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item zero={6}>
          <Stack
            direction={"row"}
            justifyContent={"end"}
            marginRight={{ zero: 0, xs: 2.5 }}
          >
            <TotalYTDToggleButton
              value={subType}
              onChange={(val) => setSubType(val)}
            />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}