import * as React from 'react';
import { useEffect } from 'react';

export type AppContextType = {
  openMenu: boolean;
  setOpenMenu: Function;
};

const AppContext = React.createContext<AppContextType | null>(null);

interface Props {
  children: React.ReactNode;
}
const AppProvider: React.FC<Props> = ({ children }) => {
  const [openMenu, setOpenMenu] = React.useState<boolean>(true);

  let debounce: any;
  useEffect(() => {
    const onResize = () => {
      clearTimeout(debounce);
      debounce = setTimeout(function () {
        if (window.innerWidth < 1024) {
          setOpenMenu(false);
        } else {
          setOpenMenu(true);
        }
      }, 350);
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return <AppContext.Provider value={{ openMenu, setOpenMenu }}>{children}</AppContext.Provider>;
};
export const useApp = () => {
  return React.useContext(AppContext);
};
export default AppProvider;
