import * as React from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress sx={{
                    height: "20px",
                    borderRadius: "8px",
                }} variant="determinate" color='secondary' {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography className='font-bold text-sm' color="secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

function LinearWithValueLabel({ value }: { value: number }) {

    return (
        <Box sx={{ width: 450 }}>
            <LinearProgressWithLabel value={value} />
        </Box>
    );
}

export default LinearWithValueLabel;