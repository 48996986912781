export enum DateFormatPattern {
  DayMonthYear = "DD/MM/YYYY",
  YearMonthDay = "YYYY-MM-DD",
  MonthDayYear = "MM/DD/YYYY",
  MonthDayYear2 = "MM-DD-YYYY",
  MonthDayYear3 = "MMM DD YYYY",
  YearMonthDay2 = "YYYYMMDD",
  MonthYear = "MM/YYYY",
  Year = "YYYY",
}
