import { createSlice } from '@reduxjs/toolkit';
import { Actions } from 'core/enums/action-enum';
import { enqueueSnackbar } from 'notistack';
import { createErisaBondAsync } from 'states/formFiling/formFilingSlice';
import { updatePlanStatusAsync } from 'states/manage-plan/managePlanSlice';
import {
  addCompanyAdminToCompanyAsync,
  createNewCompanyAdminAsync,
  manageCompanyAdminAsync,
} from 'states/manageCompanyAdmin/manageCompanyAdminSlice';
import { RootState } from 'states/store';

interface ISnackbarMessage {
  type: any;
  message: string;
  isDisplay: boolean;
}

const initialState: ISnackbarMessage = {
  type: 'warning',
  message: '',
  isDisplay: false,
};

const snackbarMessageSlice = createSlice({
  name: 'snackbarMessageSlice',
  initialState,
  reducers: {
    setErrorMessage: (state, action: { payload: string }) => {
      state.isDisplay = true;
      state.type = 'error';
      state.message = action.payload;
      enqueueSnackbar(action.payload, { variant: 'error' });
    },
    setSuccessMessage: (state, action: { payload: string }) => {
      state.isDisplay = true;
      state.type = 'success';
      state.message = action.payload;
      enqueueSnackbar(action.payload, { variant: 'success' });
    },
    setWarningMessage: (state, action: { payload: string }) => {
      state.isDisplay = true;
      state.type = 'warning';
      state.message = action.payload;
      enqueueSnackbar(action.payload, { variant: 'warning' });
    },
    setIsDisplay: (state, action) => {
      state.isDisplay = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createErisaBondAsync.fulfilled, (state) => {
        snackbarMessageSlice.caseReducers.setSuccessMessage(state, {
          payload: 'Your ERISA Bond has sucessfully been updated!',
        });
      })
      .addCase(createNewCompanyAdminAsync.fulfilled, (state) => {
        snackbarMessageSlice.caseReducers.setSuccessMessage(state, {
          payload: 'New company admin added successfully!',
        });
      })
      .addCase(addCompanyAdminToCompanyAsync.fulfilled, (state) => {
        snackbarMessageSlice.caseReducers.setSuccessMessage(state, {
          payload: 'New company admin added successfully!',
        });
      })
      .addCase(manageCompanyAdminAsync.fulfilled, (state, action) => {
        if (action.meta.arg.action === Actions.DELETE) {
          snackbarMessageSlice.caseReducers.setSuccessMessage(state, {
            payload: 'Delete company admin successfully!',
          });
        }
        if (action.meta.arg.action === Actions.EDIT) {
          snackbarMessageSlice.caseReducers.setSuccessMessage(state, {
            payload: 'All changes are saved successfully!',
          });
        }
        if (action.meta.arg.action === Actions.CREATE) {
          snackbarMessageSlice.caseReducers.setSuccessMessage(state, {
            payload: 'New company admin created successfully!',
          });
        }
      })
      .addCase(updatePlanStatusAsync.fulfilled, (state) => {
        snackbarMessageSlice.caseReducers.setSuccessMessage(state, {
          payload: 'Plan status updated successfully!',
        });
      });
  },
});

export const { setErrorMessage, setSuccessMessage, setWarningMessage, setIsDisplay } = snackbarMessageSlice.actions;

export const selectMessage = (state: RootState) => state.snackbarMessage.message;
export const selectIsDisplay = (state: RootState) => state.snackbarMessage.isDisplay;
export const selectType = (state: RootState) => state.snackbarMessage.type;

const snackbarMessageReducer = snackbarMessageSlice.reducer;
export default snackbarMessageReducer;
