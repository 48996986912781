import { CloseOutlined } from '@mui/icons-material';
import { DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import clsx from 'clsx';
import { Button } from 'common/components';
import { PropsWithChildren, ReactNode } from 'react';

type DialogContainerProps = {
  title?: ReactNode;
  className?: string;
  classes?: {
    wrapper?: string;
    title?: string;
    content?: string;
    actions?: string;
  };
  // close modal function
  close?: () => void;
  // auto enable close button
  enableCloseAction?: boolean;
  // text inside close button
  closeLabel?: ReactNode;
  // render custom actions, this will override enableCloseAction
  renderActions?: (close: () => void) => ReactNode;
};

export const DialogContainer = ({
  title,
  children,
  className,
  classes,
  close,
  enableCloseAction = false,
  closeLabel = 'Close',
  renderActions,
}: PropsWithChildren<DialogContainerProps>) => {
  return (
    <div className={clsx('relative p-4 min-w-[300px]', classes?.wrapper, className)}>
      {close && (
        <div className="absolute top-0 right-0">
          <IconButton className="rounded-none rounded-tr text-darkGreen p-3" disableRipple size="small" onClick={close}>
            <CloseOutlined />
          </IconButton>
        </div>
      )}
      {title && (
        <DialogTitle className={clsx('text-center text-secondary font-bold', classes?.title)}>{title}</DialogTitle>
      )}
      <DialogContent className={clsx('text-center', classes?.content)}>{children}</DialogContent>
      {renderActions && close
        ? renderActions(close)
        : enableCloseAction && (
            <DialogActions className={clsx('justify-center', classes?.actions)}>
              <Button onClick={close}>{closeLabel}</Button>
            </DialogActions>
          )}
    </div>
  );
};
