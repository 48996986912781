import { PropsWithChildren, HTMLProps } from 'react';

type ContainerProps = {
  className?: string;
} & PropsWithChildren &
  HTMLProps<HTMLDivElement>;

export const ContainerComp = ({ className, children, ...rest }: ContainerProps) => {
  return (
    <div className={`py-6 px-4 sm:px-8 ${className ? className : ''}`} {...rest}>
      {children}
    </div>
  );
};
