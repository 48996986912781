import CORPAdminLayout from "layout/CORPAdminLayout";
import { lazy, Suspense } from "react";
import { RouteObject } from "react-router-dom";
const CPADashboard = lazy(() => import("pages/cpaDashboard"));
const CPAManageClients = lazy(() => import("pages/cpaManageClients"));
const CPAAccountSettings = lazy(() => import("pages/cpaAccountSettings"));

export const corpRoutes: RouteObject[] = [
	{
		path: "/corp", // /dashboard
		element: <CORPAdminLayout />,
		children: [
			{
				path: "dashboard", // /cpa/dashboard
				element: (
					<Suspense>
						<CPADashboard />
					</Suspense>
				),
			},
			{
				path: "manage-businesses", // /cpa/manage-clients
				element: (
					<Suspense>
						<CPAManageClients />
					</Suspense>
				),
			},
			{
				path: "account-settings", // /cpa/account-settings
				element: (
					<Suspense>
						<CPAAccountSettings />
					</Suspense>
				),
			},
		],
	},
];
