export enum ErrorType {
  DUPLICATED_FIELD = 'DUPLICATED_FIELD',
}

export enum CredentialCodes {
  EMAIL_NOT_MATCH = 'EMAIL_NOT_MATCH',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  CREDENTIAL_MATCHED = 'CREDENTIAL_MATCHED',
  INVALID_CREDENTIAL = 'INVALID_CREDENTIAL',
  EMAIL_NOT_FOUND = 'EMAIL_NOT_FOUND',
  USERNAME_NOT_FOUND = 'USERNAME_NOT_FOUND',
  USER_MATCHED = 'USER_MATCHED',
  INVALID_USER_INFO = 'INVALID_USER_INFO',
  COMPANY_NOT_FOUND = 'COMPANY_NOT_FOUND',
}

export enum OnboardingStages {
  CREATE_CREDENTIAL = 'CREATE_CREDENTIAL',
  AUTHORIZE_SIGNATORY = 'AUTHORIZE_SIGNATORY',
  IDENTITY_VERIFICATION = 'IDENTITY_VERIFICATION',
  FINCH_CONNECTION = 'FINCH_CONNECTION',
  COMPANY_DETAILS = 'COMPANY_DETAILS',
  COMPLIANCE_DISCLOSURES = 'COMPLIANCE_DISCLOSURES',
  RETIREMENT_PLAN = 'RETIREMENT_PLAN',
  RETIREMENT_PLAN_COLLECT_INFO = 'RETIREMENT_PLAN_COLLECT_INFO',
  REGULATORY_DISCLOSURES = 'REGULATORY_DISCLOSURES',
  PLAN_CUSTOMIZATION = 'PLAN_CUSTOMIZATION',
  PLAID_VERIFICATION = 'PLAID_VERIFICATION',
  ALTERNATIVE_BANK_ACCOUNT_VERIFICATION = 'ALTERNATIVE_BANK_ACCOUNT_VERIFICATION',
  PLAN_DOCUMENTS = 'PLAN_DOCUMENTS',
  ALL_DONE = 'ALL_DONE',
  COMPLEX_PLAN = 'COMPLEX_PLAN',
  MULTIPLE_BUSINESSES_INCLUDED_401K = 'MULTIPLE_BUSINESSES_INCLUDED_401K',
  MULTIPLE_BUSINESSES_COLLECT_INFO = 'MULTIPLE_BUSINESSES_COLLECT_INFO',
  MULTIPLE_BUSINESSES_COLLECT_DETAIL_INFO = 'MULTIPLE_BUSINESSES_COLLECT_DETAIL_INFO',
}

export enum OnboardingFinchStatus {
  INTEGRATED = 'INTEGRATED',
  ASSISTED_CONNECTION = 'ASSISTED_CONNECTION',
  SKIPPED = 'SKIPPED',
  REAUTH = 'REAUTH',
  NO_ACCOUNT_SET_UP = 'NO_ACCOUNT_SET_UP',
  PROCESSING = 'PROCESSING',
  PENDING = 'PENDING',
  CONNECTED = 'CONNECTED',
  ERROR_PERMISSIONS = 'ERROR_PERMISSIONS',
  DISCONNECTED = 'DISCONNECTED',
  CONNECTION_NOT_WORKING = 'CONNECTION_NOT_WORKING',
  NaN = 'NaN',
}

export enum OnboardingPlaidStatus {
  INTEGRATED = 'INTEGRATED',
  ASSISTED_CONNECTION = 'ASSISTED_CONNECTION',
  SKIPPED = 'SKIPPED',
  NaN = 'NaN',
  USED_ALTERNATIVE_VERIFICATION = 'USED_ALTERNATIVE_VERIFICATION',
}

export enum OnboardingEmployeeStages {
  EP_CREATE_CREDENTIAL = 'EP_CREATE_CREDENTIAL',
  ACCOUNT_PREFERENCES = 'ACCOUNT_PREFERENCES',
  PORTFOLIO_ALLOCATE_PREFERENCE = 'PORTFOLIO_ALLOCATE_PREFERENCE',
  PORTFOLIO_ALLOCATE_RECOMMENDATION = 'PORTFOLIO_ALLOCATE_RECOMMENDATION',
  SET_CONTRIBUTION_AMOUNT = 'SET_CONTRIBUTION_AMOUNT',
  TERM_AND_CONDITION = 'TERM_AND_CONDITION',
  EP_ALL_DONE = 'EP_ALL_DONE',
}

export enum FinchStatus {
  CENSUS_FAILED = 'CENSUS_FAILED',
  PAYROLL_FAILED = 'PAYROLL_FAILED',
  NaN = 'NaN',
  INTEGRATED = 'INTEGRATED',
  ASSISTED_CONNECTION = 'ASSISTED_CONNECTION',
  SKIPPED = 'SKIPPED',
  DISCONNECTED = 'DISCONNECTED',
  REAUTH = 'REAUTH',
  NO_ACCOUNT_SET_UP = 'NO_ACCOUNT_SET_UP',
  PROCESSING = 'PROCESSING',
  PENDING = 'PENDING',
  CONNECTED = 'CONNECTED',
  ERROR_PERMISSIONS = 'ERROR_PERMISSIONS',
}
