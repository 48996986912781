import React, { HTMLAttributes } from "react";

interface IPros extends HTMLAttributes<HTMLDivElement> {}
const BorderProfile = (props: IPros) => {
  const { children, ...rest } = props;
  return (
    <div
      className="border rounded-lg border-[#E0DEC9] p-6 border-solid w-full mt-16"
      {...rest}
    >
      {children}
    </div>
  );
};

export default BorderProfile;
