import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import AccountTreeIcon from 'assets/SVGIcons/AccountTreeIcon';
import BellIcon from 'assets/SVGIcons/BellIcon';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import PieChartIcon from 'assets/SVGIcons/PieChartIcon';
import SettingIcon from 'assets/SVGIcons/SettingIcon';
import { ROLES } from 'core/constants/roles';
import { AuthContextType, useAuth } from 'contexts/AuthProvider';
import AuthLayout from 'layout/AuthLayout';
import * as React from 'react';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'states/hooks';
import { getEmployeeProfileAsync } from 'states/manageEmployee/manageEmployeeSlice';
import { getCookie } from 'core/services/cookie.service';
import { CookieNames } from 'core/enums/cookie-names.enum';

interface IParticipantLayoutProps {
  children?: React.ReactNode;
}

export default function ParticipantLayout(props: IParticipantLayoutProps) {
  const dispatch = useAppDispatch();
  const userProfile = useAppSelector((state) => state.manageEmployee.employeeProfile);
  const pendingNotificationCount = useAppSelector((state) => state.manageEmployer.pendingNotificationsCount);
  const sessionId = getCookie(CookieNames.XCSSession);
  const menu = [
    {
      url: '/dashboard/employee',
      label: 'Home',
      icon: <HomeOutlinedIcon />,
    },
    {
      url: '/dashboard/employee/settings?tab=profile',
      label: 'Settings',
      icon: <SettingIcon />,
    },
    {
      url: '/dashboard/employee/notification',
      label: 'Notifications',
      icon: <BellIcon />,
      subLabel:
        pendingNotificationCount > 0 ? (
          <div className="border border-solid px-2 py-1 text-white text-xs border-secondary bg-secondary rounded text-center">
            {pendingNotificationCount}
          </div>
        ) : null,
    },
    {
      url: '/dashboard/employee/contributions',
      label: 'Contributions',
      icon: <AttachMoneyOutlinedIcon />,
      hidden: userProfile?.status === 'EMPLOYMENT_ENDED',
    },
    {
      url: '/dashboard/employee/allocations',
      label: 'Allocations',
      icon: <AccountTreeIcon />,
    },
    {
      label: 'Documents',
      icon: <TextSnippetOutlinedIcon />,
      childrens: [
        {
          url: '/dashboard/employee/plandetails',
          label: 'Plan Details',
          icon: <TextSnippetOutlinedIcon />,
        },
        {
          url: '/dashboard/employee/transactions',
          label: 'Transactions',
          icon: <TextSnippetOutlinedIcon />,
        },
        {
          url: '/dashboard/employee/statements',
          label: 'Statements',
          icon: <TextSnippetOutlinedIcon />,
        },
      ],
    },
    {
      label: 'Services',
      icon: <PieChartIcon />,
      childrens: [
        {
          url: '/dashboard/employee/distributions',
          label: 'Distributions',
          icon: <PieChartIcon />,
        },
        {
          url: '/dashboard/employee/loans',
          label: 'Loans',
          icon: <PieChartIcon />,
        },
        {
          url: '/dashboard/employee/rollover',
          label: 'Incoming Rollover',
          icon: <PieChartIcon />,
        },
      ],
    },
  ];

  const { session } = useAuth() as AuthContextType;

  useEffect(() => {
    if (session && session.currentRole === ROLES.PARTICIPANT && sessionId) {
      dispatch(getEmployeeProfileAsync());
    }
  }, [session]);

  return (
    <AuthLayout menu={menu}>
      {session && session.currentRole === ROLES.PARTICIPANT && (
        <>
          <Outlet />
        </>
      )}
    </AuthLayout>
  );
}
