import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import BaseRadioFormComp from './BaseRadioFormComp';
import ManagerPlanBorder from './ManageBorder';
import RadioComp from './RadioComp';
import { useSchemaContext } from 'contexts/SchemaContext';
import { z } from 'zod';
import {
  baseMultipleBusinessSchema,
  complianceDisclosuresSchema,
  multipleBusinessFormRequiredSchema,
  multipleBusinessRequiredSchema,
  retirementPlanOptionalSchema,
} from './zoSchema';

export const complianceRadioName = {
  owner: 'complianceDisclosure.ownersHaveMajorityStakeInAnotherBusiness',
  otherBusiness: 'complianceDisclosure.otherBusinessEmployeesIncludedIn401K',
  isOffering: 'complianceDisclosure.isOfferingRetirementPlan',
  hasOfferedSimple: 'complianceDisclosure.hasOfferedSimpleIRA',
  numberOf80PercentOwnership: 'complianceDisclosure.numberOf80PercentOwnership',
  eachBusinessPayroll: 'complianceDisclosure.eachBusinessPayroll',
  numberOfEmployee: 'complianceDisclosure.numberOfEmployee',
  otherBusinessRetirementPlan: 'complianceDisclosure.otherBusinessRetirementPlan',
};

const RADIO_LIST = [
  {
    id: '1',
    description: 'Do the owners of this organization have more than 80% ownership of another business?',
    name: complianceRadioName.owner,
  },
  {
    id: '2',
    description: 'Will the employees, of the other business, be included in this 401(k) plan?',
    name: complianceRadioName.otherBusiness,
  },
  {
    id: '3',
    description: 'Is your company currently offering a retirement plan?',
    name: complianceRadioName.isOffering,
  },
  {
    id: '4',
    description: 'Has your organization offered a Simple IRA in the past two years?',
    name: complianceRadioName.hasOfferedSimple,
  },
];

const disabled = (name: string, values: any) => {
  switch (name) {
    case complianceRadioName.otherBusiness:
      return values['owner'] === 'no';
    case complianceRadioName.hasOfferedSimple:
      return values['isOffering'] === 'no';
    default:
      return false;
  }
};

const ComplianceDisclosures = ({ isCreatePlan }: { isCreatePlan?: boolean }) => {
  const { control, watch, setValue } = useFormContext();
  const { updateSchema } = useSchemaContext();
  const [owner, isOffering] = watch([complianceRadioName.owner, complianceRadioName.isOffering]);

  const validationFieldByComplianceDisclosure = () => {
    const complianceDisclosure = {
      ...complianceDisclosuresSchema,
      ...(owner === 'yes' ? multipleBusinessRequiredSchema : {}),
      retirementPlan: z.object(retirementPlanOptionalSchema),
    };
    updateSchema({
      complianceDisclosure: z.object(complianceDisclosure),
      businesses:
        owner === 'yes'
          ? z.array(z.object(multipleBusinessFormRequiredSchema))
          : z.array(z.object(baseMultipleBusinessSchema)).optional(),
    });
  };

  useEffect(() => {
    if (owner === 'no') {
      setValue(complianceRadioName.otherBusiness, '');
    }
    if (isOffering === 'no') {
      setValue(complianceRadioName.hasOfferedSimple, '');
    }
    validationFieldByComplianceDisclosure();
  }, [owner, isOffering]);

  return (
    <>
      <BaseRadioFormComp title="Compliance Disclosures">
        <ManagerPlanBorder className={isCreatePlan ? 'pointer-events-none' : ''}>
          {RADIO_LIST.map((item, idx) => (
            <RadioComp
              key={item.id}
              {...item}
              control={control}
              description={
                idx % 2 === 0 ? (
                  <span className="w-full text-sm font-semibold text-primary">{item.description}</span>
                ) : (
                  item.description
                )
              }
              radioProps={{
                disabled: disabled(item.name, { owner, isOffering }),
              }}
            />
          ))}
        </ManagerPlanBorder>
      </BaseRadioFormComp>
    </>
  );
};

export default ComplianceDisclosures;
