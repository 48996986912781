import SAVEDAY_LOGO from "assets/images/saveday-horizontal-color.png";
import useSize from "hooks/useSize";
import { useMemo, useRef } from "react";
import theme from "theme";

const HeaderBackground = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [width] = useSize(ref);
  const positionProperties = useMemo(() => {
    let x = "73%";
    let y = "35%";
    let w = "80px";
    let h = "25px";
    if (width >= theme.breakpoints.values.xl) {
      w = "70px";
      h = "25px";
    }

    return { x, y, w, h };
  }, [width]);
  return (
    <div ref={ref}>
      <svg viewBox="0 0 500 100" width={"100%"} height={"20%"}>
        <path
          d="M 0,50 C 150,-20 350,120 500,60 L 500,0 L 0,0 Z"
          fill="#E3F2E3"
        />
        <image
          href={SAVEDAY_LOGO}
          x={positionProperties.x}
          y={positionProperties.y}
          style={{
            width: positionProperties.w,
            height: positionProperties.h,
            transition: "width 0.3s ease 0s",
          }}
        />
      </svg>
    </div>
  );
};
export default HeaderBackground;
