import { Accordion, AccordionDetails, AccordionProps, AccordionSummary } from '@mui/material';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import { appPalette } from 'core/constants';
import React, { createContext, HTMLAttributes, ReactNode, useContext, useState } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CollapseContext = createContext<any>(undefined);

interface IProps {
  defaultValue: { [key: string]: boolean };
  children: React.ReactNode;
}

export const useCollase = () => {
  return useContext(CollapseContext);
};

const isChildNull = (child: ReactNode) => {
  return !!(child as any).type((child as any)?.props)?.props?.children;
};

const Collapse = (
  props: HTMLAttributes<HTMLDivElement> &
    AccordionProps & {
      title: string;
      name: string;
    }
) => {
  const { setExpand, expand } = useCollase();
  const { children, title, sx = {}, name, ...accordionProps } = props;
  return isChildNull(children) ? (
    <Accordion
      elevation={0}
      sx={{
        borderRadius: '10px !important',
        '&:before': {
          display: 'none',
        },
        '&.Mui-expanded': {
          margin: 0,
        },
        bgcolor: appPalette.lightGreen,
        border: '1px solid #E0DEC9',
        margin: 0,
        ...sx,
      }}
      expanded={expand[name]}
      onChange={() => setExpand({ ...expand, [name]: !expand[name] })}
      {...accordionProps}
    >
      <AccordionSummary expandIcon={<GridExpandMoreIcon />} aria-controls="panel2-content">
        <div className="text-lg font-bold text-superBlack">{title}</div>
      </AccordionSummary>

      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  ) : null;
};

export const CollapseProvider = (props: IProps) => {
  const { defaultValue = {}, children } = props;
  const [expand, setExpand] = useState(defaultValue);
  const covertExpandToArr = Object.keys(expand);
  const isHasExpand = covertExpandToArr.some((key) => expand[key]);

  const onCollapseAll = (value?: boolean) => {
    let result = covertExpandToArr.reduce((accumulator, currentKey) => {
      return {
        ...accumulator,
        [currentKey]: typeof value === 'boolean' ? value : !isHasExpand,
      };
    }, {});
    setExpand(result);
  };

  const values = {
    expand,
    setExpand,
    onCollapseAll,
    isHasExpand,
  };

  return <CollapseContext.Provider value={values}>{children}</CollapseContext.Provider>;
};

const CollapseAll = () => {
  const { onCollapseAll, isHasExpand } = useCollase();

  return (
    <div
      className="flex items-center cursor-pointer text-secondary gap-2 text-sm font-semibold"
      onClick={() => onCollapseAll()}
    >
      <span>{isHasExpand ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>
      <span>{`${isHasExpand ? 'Collapse' : 'Expand'} All`}</span>
    </div>
  );
};

CollapseProvider.Collapse = Collapse;
CollapseProvider.CollapseAll = CollapseAll;

export default Collapse;
