import Box, { BoxProps } from '@mui/material/Box';
import React from 'react';

type Props = {
  icon?: React.ReactNode;
  description?: React.ReactNode;
} & BoxProps;

export const EmptyDataShow: React.FC<Props> = ({ icon = '', description = '', ...props }) => {
  return (
    <Box
      sx={{
        width: '100%',
        flexGrow: 1,
        minHeight: 500,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        color: (theme) => theme.palette.beige,
      }}
      {...props}
    >
      {icon}
      <span className="text-mediumGrey text-sm font-semibold mt-3.5">{description}</span>
    </Box>
  );
};
