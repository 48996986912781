import SelectRoleLayout from "layout/SelectRoleLayout";
import { lazy, Suspense } from "react";
import { RouteObject } from "react-router-dom";
const DashboardSelectRoles = lazy(() => import("pages/selectRoles"));

export const dashboardRoutes: RouteObject[] = [
  {
    path: "/dashboard", // /dashboard
    element: <SelectRoleLayout />,
    children: [
      {
        path: "selectRole", // /dashboard/selectRole
        element: (
          <Suspense>
            <DashboardSelectRoles />
          </Suspense>
        ),
      },
    ],
  },
];
