import React, { useEffect, useRef, useState } from "react";
import { FormControl, Grid, IconButton, InputAdornment } from "@mui/material";
import { Button, ErrorMassage, TextField } from "common/components";
import ReCAPTCHA from "react-google-recaptcha";
import { Controller, useForm } from "react-hook-form";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { REGEX_PATTERN } from "core/constants";
import ContactComponent from "components/login/ContactComponent";
import { removeAllCookie, setCookie } from "core/services/cookie.service";
import { useAppDispatch, useAppSelector } from "states/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { resetPasswordAsync } from "states/auth/authSlice";
import { RequestStatus } from "core/enums/request-status.enum";
import { setSuccessMessage } from "states/snackbarMessage/snackbarMessageSlice";
import {
  REQUEST_STATUS,
  TWO_FA_STATUS,
  VERIFY_2FA_ACTION,
} from "core/constants/roles";
import ModalTwoFA from "components/employeeProfile/ModalTwoFA";
import { CookieNames } from "core/enums/cookie-names.enum";
import { setTwoFA } from "states/manageEmployee/manageEmployeeSlice";
import LogoIcon from "assets/SVGIcons/LogoIcon";
import CheckIcon from "@mui/icons-material/Check";

type FormValues = {
  newPassword: string;
  confirmPassword: string;
  token: string;
};

export const validatePasswordMessages = {
  minLength: "At least 8 characters",
  atLeastOneLowercase: REGEX_PATTERN.AT_LEAST_ONE_LOWERCASE.message,
  atLeastOneUppercase: REGEX_PATTERN.AT_LEAST_ONE_UPPERCASE.message,
  atLeastOneNumber: REGEX_PATTERN.AT_LEAST_ONE_NUMBER.message,
  atLeastOneSpecialCharacter:
    REGEX_PATTERN.AT_LEAST_ONE_SPECIAL_CHARACTER.message,
};

export default function ResetPasswordPage() {
  const {
    control,
    setError,
    handleSubmit,
    getValues,
    formState: { errors, dirtyFields },
  } = useForm<FormValues>({
    mode: "onChange",
    reValidateMode: "onChange",
    criteriaMode: "all",
    defaultValues: {
      token: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { resetToken } = useParams();
  const [isPassword, setShowPassword] = useState(true);
  const [isConfirmPassword, setShowConfirmPassword] = useState(true);
  const [isShowTwoFA, setShowTwoFA] = useState(false);
  const reCaptchaRef = useRef<any>(null);
  const resetPasswordStatus = useAppSelector(
    (state) => state.auth.resetPasswordStatus
  );

  const onSubmit = async (values: FormValues) => {
    const data = {
      token: resetToken,
      newPassword: values.newPassword,
      action: "SET_NEW_PASSWORD",
    };

    const response = await dispatch(
      resetPasswordAsync({
        payload: data,
        config: { headers: { ReCaptcha: values.token } },
      })
    );
    if (response.meta.requestStatus === RequestStatus.FULFILLED) {
      if (response.payload?.methods) {
        setCookie(CookieNames.XCSSession, response.payload.id);
        dispatch(
          setTwoFA({
            status: TWO_FA_STATUS.ACTIVE,
            ...response.payload.methods[0],
          })
        );
        setShowTwoFA(true);
      } else {
        dispatch(setSuccessMessage("Password was changed successfully."));
        navigate("/login"); // navigate to /login page:
      }
    }
    reCaptchaRef?.current?.reset();
  };

  useEffect(() => {
    removeAllCookie();
  }, []);

  return (
    <div className="login">
      <div className="login-wrap">
        <div className="login_component">
          <Grid container>
            <Grid className="information" item xs={12} sm={6}>
              <div className="medium-6 large-6 column text-primary">
                <h2 className="mb-2">Reset Password</h2>
                <p>
                  Your security is important to us! We need responses to these
                  questions to assure your account security.
                </p>
                <ContactComponent />
              </div>
            </Grid>
            <Grid className="space-y-4 form-card-wrap" item xs={12} sm={6}>
              <div className="flex justify-center w-full">
                <LogoIcon />
              </div>
              <h4 className="text-normal text-center font-semibold text-darkGreen">
                Reset password
              </h4>
              <form
                name="formLogin"
                className="form-login"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      rules={{
                        required: "Password is required",
                        validate: {
                          minLength: (value) =>
                            value.length >= 8 || "At least 8 characters",
                          atLeastOneLowercase: (value) =>
                            REGEX_PATTERN.AT_LEAST_ONE_LOWERCASE.pattern.test(
                              value
                            ) || REGEX_PATTERN.AT_LEAST_ONE_LOWERCASE.message,
                          atLeastOneUppercase: (value) =>
                            REGEX_PATTERN.AT_LEAST_ONE_UPPERCASE.pattern.test(
                              value
                            ) || REGEX_PATTERN.AT_LEAST_ONE_UPPERCASE.message,
                          atLeastOneNumber: (value) =>
                            REGEX_PATTERN.AT_LEAST_ONE_NUMBER.pattern.test(
                              value
                            ) || REGEX_PATTERN.AT_LEAST_ONE_NUMBER.message,
                          atLeastOneSpecialCharacter: (value) =>
                            REGEX_PATTERN.AT_LEAST_ONE_SPECIAL_CHARACTER.pattern.test(
                              value
                            ) ||
                            REGEX_PATTERN.AT_LEAST_ONE_SPECIAL_CHARACTER
                              .message,
                        },
                      }}
                      name="newPassword"
                      render={({ field, fieldState }) => {
                        return (
                          <>
                            <TextField
                              {...field}
                              placeholder="Type New password"
                              label="New password"
                              type={isPassword ? "password" : "text"}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      className="-mr-3"
                                      onClick={() => {
                                        setShowPassword(!isPassword);
                                      }}
                                    >
                                      {!isPassword ? (
                                        <VisibilityOff />
                                      ) : (
                                        <Visibility />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              error={fieldState.error?.types?.required}
                            />
                            {dirtyFields.newPassword &&
                              Object.entries(validatePasswordMessages).map(
                                ([type, message]) => (
                                  <div className="flex gap-1 items-center mt-2">
                                    <CheckIcon
                                      className={[
                                        "text-xl",
                                        Object.keys(
                                          fieldState.error?.types || {}
                                        ).includes(type)
                                          ? "text-primary"
                                          : "text-secondary",
                                      ].join(" ")}
                                    />
                                    <p
                                      key={type}
                                      className="text-primary text-xs"
                                    >
                                      {message}
                                    </p>
                                  </div>
                                )
                              )}
                          </>
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      rules={{
                        required: "Confirm password is required.",
                        validate: (value) => {
                          return value === getValues("newPassword");
                        },
                      }}
                      name="confirmPassword"
                      render={({ field }) => {
                        return (
                          <TextField
                            {...field}
                            placeholder="Type Confirm password"
                            label="Confirm password"
                            type={isConfirmPassword ? "password" : "text"}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    className="-mr-3"
                                    onClick={() => {
                                      setShowConfirmPassword(
                                        !isConfirmPassword
                                      );
                                    }}
                                  >
                                    {!isConfirmPassword ? (
                                      <VisibilityOff />
                                    ) : (
                                      <Visibility />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            error={
                              errors.confirmPassword?.type === "required"
                                ? "Confirm password is required."
                                : errors.confirmPassword?.type === "validate"
                                  ? "Confirm password does not match."
                                  : ""
                            }
                          />
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl>
                      <Controller
                        name="token"
                        control={control}
                        rules={{
                          required: "Please check the reCaptcha.",
                        }}
                        render={({ field: { onChange } }) => {
                          return (
                            <ReCAPTCHA
                              ref={reCaptchaRef}
                              sitekey={
                                process.env.REACT_APP_RECAPTCHA_SITE_KEY!
                              }
                              onChange={onChange}
                              onExpired={() => {
                                onChange("");
                                setError("token", {
                                  message:
                                    "Expired, Please check the reCaptcha.",
                                });
                              }}
                            />
                          );
                        }}
                      />
                      {errors.token?.message && (
                        <ErrorMassage message={errors.token?.message} />
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                <div className="form-footer">
                  <Button
                    className="fullwidth-button background-color-secondary max-w-full"
                    type="submit"
                    variant="contained"
                    disabled={resetPasswordStatus === REQUEST_STATUS.SENDING}
                    loading={resetPasswordStatus === REQUEST_STATUS.SENDING}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </Grid>
          </Grid>
        </div>
      </div>
      <ModalTwoFA
        title="Verifying Two-factor Authentication."
        isOpen={isShowTwoFA}
        action={VERIFY_2FA_ACTION.FORGOT_PASSWORD}
        onClose={async (response) => {
          if (response) {
            dispatch(setSuccessMessage("Password was changed successfully."));
            navigate("/login"); // navigate to /login page:
          }
          removeAllCookie();
          setShowTwoFA(false);
        }}
      />
    </div>
  );
}
