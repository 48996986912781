import { getCookie, removeCookie, setCookie } from 'core/services/cookie.service';
import { useDialog } from 'hooks/useDialog';
import moment from 'moment';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { logoutSessionSync } from 'states/auth/authSlice';
import { useAppDispatch, useAppSelector } from 'states/hooks';
import { AuthContextType, useAuth } from 'contexts/AuthProvider';
import { EmployeeStatus } from 'core/enums/employee-status.enum';
import { Button, DialogContainer } from 'common/components';
import { ROLES } from 'core/constants/roles';
import { CompanyStatus } from 'core/enums/company.enum';
import { CookieNames } from 'core/enums/cookie-names.enum';
import { getActiveAnswerAsync } from 'states/employeeAccountsSlice';
import { RequestStatus } from 'core/enums/request-status.enum';
import { createAnswerDataAsync } from 'states/employeeRiskQuestion/employeeRiskQuestionSlice';
import { setEmployeeProfile } from 'states/manageEmployee/manageEmployeeSlice';
import { setCompanyDetail } from 'states/manageEmployer/manageEmployerSlice';
import { OnboardingStages } from 'core/enums';
import { EMPLOYER_SCREENS, EMPLOYER_SCREENS_NAME } from 'core/constants';
import { employeeStageToScreen } from 'components/employeeOnboarding/CreateYourLoginNew';
import { setErrorMessage } from 'states/snackbarMessage/snackbarMessageSlice';
import TrackingScript from 'common/components/TrackingScript';

interface IMainLayoutProps {
  children: React.ReactNode;
}
export default function MainLayout(props: IMainLayoutProps) {
  const { children } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const mainPage = useAppSelector((state) => state.auth.mainPage);
  const userProfileIdCookie = getCookie(CookieNames.UserProfileId);
  const profile = useAppSelector((state) => state.manageEmployee.employeeProfile);
  const companyDetails = useAppSelector((state) => state.manageEmployer.companyDetail);

  const detectLayout = () => {
    const spreadPathname = location.pathname.split('/').filter(Boolean);
    if (spreadPathname.length <= 1) {
      if (spreadPathname.length === 1) {
        if (
          spreadPathname[0] === 'admin' ||
          spreadPathname[0] === 'cpa' ||
          spreadPathname[0] === 'pep' ||
          spreadPathname[0] === 'dashboard' ||
          spreadPathname[0] === 'login' ||
          spreadPathname[0] === 'onboarding' ||
          spreadPathname[0] === 'corp'
        ) {
          navigate(mainPage);
        }
      }
    } else {
      if (spreadPathname[0] === 'admin') {
        if (!mainPage.includes('admin')) {
          navigate(mainPage);
        }
      } else if (spreadPathname[0] === 'cpa' || spreadPathname[0] === 'pep' || spreadPathname[0] === 'corp') {
        if (!mainPage.includes('cpa') && !mainPage.includes('pep') && !mainPage.includes('corp')) {
          navigate(mainPage);
        }
      } else if (spreadPathname[0] === 'dashboard') {
        if (!mainPage.includes(spreadPathname[1])) {
          navigate(mainPage);
        }
      }
    }
  };

  useEffect(() => {
    if (auth?.session && !getCookie(CookieNames.XCSSession)) {
      navigate('/login');
    } else {
      if (mainPage && mainPage !== location.pathname) {
        detectLayout();
      }

      if (mainPage && mainPage === location.pathname) {
        const reqParams = getCookie(CookieNames.RequestParams);
        if (reqParams && userProfileIdCookie && reqParams.userProfileId === userProfileIdCookie) {
          removeCookie(CookieNames.RequestParams);
        }
      }
    }
  }, [mainPage]);

  const auth = useAuth() as AuthContextType;
  const dispatch = useAppDispatch();
  const { session, setSession, getSession } = auth;
  const { openDialog } = useDialog();
  const history = useLocation();
  const isOnboardingPage = history.pathname.includes('onboarding');

  const getLatestStepMapping = (stepName: string) => {
    if (!stepName) return;
    if (stepName === OnboardingStages.CREATE_CREDENTIAL) {
      return EMPLOYER_SCREENS.SponsorDetails;
    } else {
      return EMPLOYER_SCREENS[
        EMPLOYER_SCREENS_NAME[stepName as keyof typeof EMPLOYER_SCREENS_NAME] as keyof typeof EMPLOYER_SCREENS
      ];
    }
  };

  const onNavigateOnboarding = async () => {
    if (session?.currentRole === ROLES.PARTICIPANT) {
      if (profile && profile.id) {
        setCookie(CookieNames.OnboardingStep, employeeStageToScreen(profile.onboardingLatestStage || ''));
        const answers = await dispatch(getActiveAnswerAsync(profile.id));
        if (answers.meta.requestStatus === RequestStatus.FULFILLED) {
          if (answers.payload === '') {
            const createAnswer = await dispatch(createAnswerDataAsync(profile.id));
            if (createAnswer.meta.requestStatus === RequestStatus.FULFILLED) {
              navigate('/onboarding/employee');
            }
          }
          navigate('/onboarding/employee');
        }
      }
    }
    if (session?.currentRole === ROLES.COMPANY_ADMIN) {
      let slug = '';
      if (companyDetails?.tokens?.length) {
        slug = `?token=${companyDetails.tokens[0].token || ''}&payload=${companyDetails.tokens[0].payload || ''}`;
      }
      if (companyDetails?.onboardingLatestStage) {
        setCookie(CookieNames.OnboardingStep, getLatestStepMapping(companyDetails.onboardingLatestStage));
      }
      navigate(`/onboarding/employer${slug}`);
    }
  };

  const onCloseModal = async () => {
    if (session && session.allRole.length > 1) {
      removeCookie(CookieNames.AdminLoginAsUser);
      setCookie(CookieNames.CurrentCompanyId, '');
      setCookie(CookieNames.CurrentRole, ROLES.SELECT_ROLE);
      setCookie(CookieNames.UserProfileId, '');
      dispatch(setEmployeeProfile(null));
      dispatch(setCompanyDetail(null));
      await auth.updateSession();
      navigate('/dashboard/selectRole');
    } else {
      await dispatch(logoutSessionSync());
      setSession(null);
    }
  };

  let debounce: any;
  useEffect(() => {
    clearInterval(debounce);
    if (session) {
      const now = moment.utc();
      const expiredDate = moment.utc(session.expiresOn);
      if (!now.isBefore(expiredDate) && getCookie(CookieNames.XCSSession)) {
        setSession(null);
        dispatch(logoutSessionSync());
        return;
      }
      const duration = moment.duration(expiredDate.diff(now));
      const timer = parseInt(duration.asMinutes().toFixed(0), 10);
      const currentRoleCookie = getCookie(CookieNames.CurrentRole);
      let countDown = 0;
      let isDialogOpenned = false;
      debounce = setInterval(function () {
        countDown++;
        isDialogOpenned = false;
        if (countDown >= timer && !isDialogOpenned) {
          isDialogOpenned = true;
          clearInterval(debounce);
          openDialog((close) => {
            if (getCookie(CookieNames.XCSSession)) {
              setSession(null);
              dispatch(logoutSessionSync());
            } else {
              navigate('/login');
            }
            clearInterval(debounce);
            return (
              <DialogContainer title="Warning!" close={close}>
                <span className="text-sm text-primary">You have been logged out due to inactivity</span>
              </DialogContainer>
            );
          });
        }
        if (countDown + 2 >= timer && !isDialogOpenned) {
          isDialogOpenned = true;
          openDialog((close) => {
            return (
              <DialogContainer
                title="Warning!"
                close={async () => {
                  if (getCookie(CookieNames.XCSSession)) {
                    await getSession();
                  } else {
                    dispatch(setErrorMessage('Session expired.'));
                    navigate('/login');
                  }
                  close();
                }}
              >
                <span className="text-sm text-primary">
                  You will be logged out in {timer - countDown} minutes due to inactivity
                </span>
              </DialogContainer>
            );
          });
        }
      }, 1000 * 60);
      if (
        !isDialogOpenned &&
        !isOnboardingPage &&
        ((profile && currentRoleCookie === ROLES.PARTICIPANT && profile.status === EmployeeStatus.INVITED) ||
          (companyDetails &&
            currentRoleCookie === ROLES.COMPANY_ADMIN &&
            companyDetails.status === CompanyStatus.ONBOARDING))
      ) {
        isDialogOpenned = true;
        openDialog((close) => {
          return (
            <DialogContainer
              close={async () => {
                isDialogOpenned = false;
                onCloseModal();
                close();
              }}
            >
              <div className="text-sm text-primary">
                Hey there! It seems like we're missing a few finishing touches on your account setup. Don't worry, it'll
                only take a moment! Click the button below to continue where you left off.
              </div>
              <Button
                color="secondary"
                variant="contained"
                className="mt-6 w-[220px]"
                onClick={() => {
                  close();
                  onNavigateOnboarding();
                }}
              >
                Complete Onboarding
              </Button>
            </DialogContainer>
          );
        });
      }
    }
    return () => {
      clearInterval(debounce);
    };
  }, [session, profile, companyDetails]);

  return (
    <>
      <TrackingScript />
      {children}
    </>
  );
}
