import { FC, useEffect, useState } from 'react';
import { Box, Card, Stack, Typography, Grid, Paper } from '@mui/material';
import { ResponsiveContainer, Tooltip as ChartTooltip, Bar, BarChart, XAxis, YAxis } from 'recharts';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import { Tooltip as MuiTooltip } from 'common/components';
import { formatCurrency } from 'core/services/utils/dashboardUtils';
import { selectAuthState } from 'states/auth/authSlice';
import { selectCashBalance, getCashBalanceAsync } from 'states/employerDashboard/employerDashboardSlice';
import { useAppDispatch, useAppSelector } from 'states/hooks';
import { CashBalanceDto } from 'core/models/employerDashboardDto.model';

const labels = [
  {
    key: 'unvested',
    label: 'Unvested',
    tooltip: `The portion of the employer's contributions to the 401(k) that does not belong to participants yet.`,
    color: '#E0DEC9',
  },
  {
    key: 'vested',
    label: 'Vested',
    tooltip: `The portion of the employer's contributions to the 401(k) that is fully owned by participants and can be taken with them if they leave the company.`,
    color: '#69AB00',
  },
  {
    key: 'forfeiture',
    label: 'Forfeiture',
    tooltip: `Funds forfeited by employees who left your company before being fully vested in the company’s contributions to their retirement account.`,
    color: '#3B471C',
  },
];

const CustomTooltip = ({ active, payload, currentHover }: any) => {
  if (active && payload && payload.length && payload.find((f: any) => f.dataKey === currentHover)) {
    return (
      <Paper elevation={6}>
        <div
          className="flex items-center justify-center mx-1"
          style={{
            backgroundColor: '#fff',
            borderRadius: '4px',
            minHeight: '24px',
            minWidth: '45px',
          }}
        >
          <Typography fontWeight={600} fontSize={'10px'} lineHeight={'16px'}>
            {`${formatCurrency(payload.find((f: any) => f.dataKey === currentHover)?.value)}`}
          </Typography>
        </div>
      </Paper>
    );
  }

  return null;
};

const safeCast = (source?: number) => {
  return source || 0;
};
const mappingData = (cash: CashBalanceDto) => {
  return {
    currentPlanAssets: Math.max(0, safeCast(cash?.totalAmount) + safeCast(cash?.forfeitureBalance)),
    vested: Math.max(0, safeCast(cash?.vestedBalance)),
    unvested: Math.max(0, safeCast(cash?.totalAmount) - safeCast(cash?.vestedBalance)),
    forfeiture: Math.max(0, safeCast(cash?.forfeitureBalance)),
  };
};
const RenderTitle = () => {
  return (
    <Typography
      sx={{
        fontSize: '12px',
        fontWeight: 600,
        color: 'beige',
        lineHeight: '16px',
      }}
      gutterBottom
    >
      Current Plan Assets
      <MuiTooltip
        title="This is the total amount of money currently in your company’s 401(k) account. This amount includes: Contributions (both employer & employee contributions); Investment Gains/Losses; Fees; and Withdrawals/Distributions."
        arrow
      >
        <InfoOutlinedIcon className="w-3" sx={{ color: '#9E9E91', marginLeft: '4px' }} />
      </MuiTooltip>
    </Typography>
  );
};
const RenderLegend: FC<{ data: any[] }> = ({ data }) => {
  if (!data?.length) return null;
  const keys = Object.keys(data[0]);
  return (
    <Stack direction={'row'} justifyContent={'space-evenly'}>
      {keys.map((key: string) => {
        const item = labels.find((lb) => lb.key === key);
        const color = item?.color || '#000';
        const label = item?.label;
        const tooltip = item?.tooltip;
        return (
          <p key={key}>
            {keys.length > 1 && (
              <CircleIcon
                style={{
                  color: color,
                  fontSize: '8px',
                  marginRight: '2px',
                }}
              />
            )}
            <span
              style={{
                color: '#2E3721',
                fontWeight: 500,
                fontSize: '10px',
                lineHeight: '15px',
                marginRight: '2px',
              }}
            >
              {label}
            </span>
            <MuiTooltip
              title={tooltip}
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -12],
                      },
                    },
                  ],
                },
              }}
            >
              <InfoOutlinedIcon className="w-3" sx={{ color: '#9E9E91' }} />
            </MuiTooltip>
          </p>
        );
      })}
    </Stack>
  );
};
export default function PlanAssetChart() {
  const [currentHover, setCurrentHover] = useState('');
  const dispatch = useAppDispatch();
  const { info } = useAppSelector(selectAuthState);
  const cashBalanceState = useAppSelector(selectCashBalance);
  const [data, setData] = useState<any>();
  const [chart, setChart] = useState<any>();
  const fetchData = () => {
    if (info && info.currentCompanyId) {
      dispatch(getCashBalanceAsync(info.currentCompanyId));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (cashBalanceState) {
      setData(mappingData(cashBalanceState));
      setChart([
        {
          unvested: data?.unvested,
          vested: data?.vested,
          forfeiture: data?.forfeiture,
        },
      ]);
    }
  }, [cashBalanceState]);

  const renderRadius = (key: string): [number, number, number, number] => {
    let radius: [number, number, number, number] = [0, 0, 0, 0];
    const line = chart?.length > 0 ? chart[0] : undefined;
    if (!line) return radius;

    if (key === 'unvested') {
      if (line?.unvested) {
        radius = [25, 0, 0, 25];
      }
      if (!line?.vested && !line?.forfeiture) {
        radius = [25, 25, 25, 25];
      }
    }
    if (key === 'vested') {
      if (!line?.unvested) {
        radius = [25, 0, 0, 25];
      }
      if (!line?.forfeiture) {
        radius = [0, 25, 25, 0];
      }
      if (!line?.unvested && !line?.forfeiture) {
        radius = [25, 25, 25, 25];
      }
    }
    if (key === 'forfeiture') {
      if (data?.forfeiture) {
        radius = [0, 25, 25, 0];
      }
      if (!data?.vested && !data?.unvested) {
        radius = [25, 25, 25, 25];
      }
    }
    return radius;
  };

  return (
    <Card
      sx={{
        maxWidth: '100%',
        border: 'none',
      }}
      variant="outlined"
    >
      <Grid container>
        <Grid container item zero={12} mt={0.5} mb={0.5}>
          <RenderTitle />
        </Grid>
        <Grid item zero={12}>
          <Box
            sx={{
              borderRadius: '8px 40px 40px 8px',
              minHeight: '64px',
              maxWidth: '100%',
              backgroundColor: '#9E9E9114',
            }}
          >
            <Stack direction={{ zero: 'column', mobile414: 'row' }} justifyContent={'space-between'}>
              <Box mt={3} ml={2}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: '25px',
                    lineHeight: '16px',
                  }}
                >
                  {formatCurrency(data?.currentPlanAssets)}
                </Typography>
              </Box>
            </Stack>
          </Box>
        </Grid>
        <Grid item zero={12}>
          <Box mt={3} ml={1}>
            <ResponsiveContainer width="100%" height="99%" minHeight={'30px'}>
              <BarChart layout="vertical" data={chart} stackOffset="expand">
                <XAxis hide type="number" />
                <YAxis type="category" hide />

                <ChartTooltip
                  cursor={{ fill: 'transparent' }}
                  wrapperStyle={{
                    zIndex: 1,
                    marginTop: 10,
                    border: 'none',
                    outline: 'none',
                  }}
                  content={<CustomTooltip currentHover={currentHover} />}
                />

                <Bar
                  dataKey="unvested"
                  fill={'#E0DEC9'}
                  stackId="solo"
                  barSize={16}
                  onMouseOver={() => setCurrentHover('unvested')}
                  radius={renderRadius('unvested')}
                />
                <Bar
                  dataKey="vested"
                  fill={'#69AB00'}
                  stackId="solo"
                  barSize={16}
                  onMouseOver={() => setCurrentHover('vested')}
                  radius={renderRadius('vested')}
                />
                <Bar
                  dataKey="forfeiture"
                  fill={'##3B471C'}
                  stackId="solo"
                  barSize={16}
                  onMouseOver={() => setCurrentHover('forfeiture')}
                  radius={renderRadius('forfeiture')}
                />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Grid>
        <Grid item zero={12}>
          <RenderLegend data={chart} />
        </Grid>
      </Grid>
    </Card>
  );
}
