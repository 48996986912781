import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const RedirectToWithQuery = ({ to }: { to: string }) => {
  let navigate = useNavigate();
  let location = useLocation();

  React.useEffect(() => {
    navigate(to + location.search, { replace: true });
  }, []);

  return null;
};
