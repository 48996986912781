import { ZodError, ZodSchema } from "zod";
export const validate = <T extends ZodSchema>(fn: T) => {
  return (value: any) => {
    try {
      fn.parse(value);
      return true;
    } catch (e) {
      const error = e as ZodError;
      return error.message;
    }
  };
};
