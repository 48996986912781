import { AxiosRequestConfig } from 'axios';
import { CONTRIBUTION_LOGS_STATUS } from 'core/constants';
import { OnboardingStages } from 'core/enums';
import {
  EmployerContributionResponse,
  GetZeroContributionPayload,
  SaveAnnualLimitExceededContributionPayload,
  SubmitContributionPayload,
  UpdateContributionByFieldPayload,
  ZeroContributionResponse,
} from 'core/models/EmployerContributionDto.model';
import { PlanFormData, TransferPlanResponse } from 'core/models/PlanDetailDto.model';
import { CreateNewEmployerDto, CreateNewEmployerResponse } from 'core/models/companyDto.model';
import {
  EmployerContributionLogsResponse,
  CheckAllCompletedResponse,
} from 'core/models/employerContributionLogsDto.model';
import { ClientCpaSignatureForm, OnboardingCheckCredentialsPayload } from 'core/models/employerOnboardingDto.model';
import { UpdatePayrollCheckDateResponse } from 'core/models/payrollDto.model';
import { IContributionLogsParams, ILogsCheckParams } from 'states/employerInboxSlice';
import publicApi from './utils/HttpRequest';
import { API } from './utils/app.routes';
import { getApiPath } from './utils/routesUtils';

export const getContributionLogs = async ({
  companyId = '',
  status = CONTRIBUTION_LOGS_STATUS.PENDING,
  pageSize = 10,
  page = 0,
  search = '',
  sortModel,
}: IContributionLogsParams): Promise<EmployerContributionLogsResponse> => {
  const path = getApiPath(`/api/contributionlogs/${companyId}`, {
    filter: {
      status,
      pageSize,
      page,
      search,
      order: sortModel?.length > 0 ? sortModel[0].sort : '',
      field: sortModel?.length > 0 ? sortModel[0].field : '',
    },
  });
  const { data } = await publicApi.get<EmployerContributionLogsResponse>(path);
  return Promise.resolve(data);
};

export const checkIfAllTasksCompleted = async ({
  companyId = '',
}: ILogsCheckParams): Promise<CheckAllCompletedResponse> => {
  const path = getApiPath(`/api/contributionlogs/${companyId}/checkAllCompleted`);
  const { data } = await publicApi.get<CheckAllCompletedResponse>(path);
  return Promise.resolve(data);
};

export const setStatusContributionLogs = async (logId: string, status: string): Promise<any> => {
  const { data } = await publicApi.post<any>(`/api/contributionlogs/${logId}/status/${status}`);
  return Promise.resolve(data);
};

export const getEmployees = async (params: any): Promise<any> => {
  const { companyId, sortModel, filterModel, ...restParams } = params;
  const path = getApiPath(`/api/profiles/list/${companyId}`, {
    filter: {
      ...restParams,
      order: sortModel.length > 0 ? sortModel[0].sort : '',
      field: sortModel.length > 0 ? sortModel[0].field : '',
      profileStatus: filterModel?.items?.length > 0 ? filterModel.items[0].value : '',
    },
  });
  const { data } = await publicApi.get<any>(path);
  return Promise.resolve(data);
};

export const getPendingEmployees = async (params: any): Promise<any> => {
  const { companyId, sortModel, filterModel, ...restParams } = params;
  const path = getApiPath(`/api/pendingUsers/list/${companyId}`, {
    filter: {
      ...restParams,
      order: sortModel.length > 0 ? sortModel[0].sort : '',
      field: sortModel.length > 0 ? sortModel[0].field : '',
      profileStatus: filterModel.items.length > 0 ? filterModel.items[0].value : '',
    },
  });
  const { data } = await publicApi.get<any>(path);
  return Promise.resolve(data);
};

export const deletePendingEmployee = async (params: any): Promise<any> => {
  const { id } = params;
  const path = getApiPath(`/api/pendingUsers/${id}`);
  const { data } = await publicApi.delete<any>(path);
  return Promise.resolve(data);
};

export const updateStatusEmployees = async (params: any): Promise<any> => {
  const { toStatus, selectedEmployees } = params;
  const path = getApiPath('/api/profiles', {
    filter: {
      action: toStatus,
    },
  });
  const { data } = await publicApi.post<any>(path, selectedEmployees);
  return Promise.resolve(data);
};

const onBehalfOptOutParticipant = async ({ payload }: { payload: any }) => {
  const path = '/api/profiles/sendMailOptOutParticipants';
  return publicApi.post(path, payload);
};

export const getNotifications = async ({ filter }: { filter: any }): Promise<any> => {
  const path: string = getApiPath(API.NOTIFICATIONS, {
    filter,
  });
  return publicApi.get(path);
};

export const deleteNotification = async (notificationId: string): Promise<any> => {
  const { data } = await publicApi.patch<any>(`/api/notifications/${notificationId}/deleted?actionValue=true`);
  return Promise.resolve(data);
};

export const readNotification = async (notificationId: string): Promise<any> => {
  const { data } = await publicApi.patch<any>(`/api/notifications/${notificationId}/read?actionValue=true`);
  return Promise.resolve(data);
};

const getContributions = async () => {
  const path: string = getApiPath(API.GET_CONTRIBUTION, {
    filter: {
      random: Math.random(),
    },
  });
  return publicApi.get(path);
};

const updatePaycheckDate = ({
  lastPayrollId,
  filter,
}: {
  lastPayrollId: string;
  filter: { payCheckDate: string; submittingOffCycleContribution: string };
}): Promise<{ data: UpdatePayrollCheckDateResponse }> => {
  const path: string = getApiPath(API.UPDATE_PAYCHECK_DATE, {
    lastPayrollId,
    filter,
  });
  return publicApi.put(path);
};

const getZeroContribution = ({
  payConId,
  payrollContribution,
}: GetZeroContributionPayload): Promise<{ data: ZeroContributionResponse }> => {
  const path: string = getApiPath(API.GET_ZERO_CONTRIBUTION);
  return publicApi.post(path, {
    payConId,
    payrollContribution,
  });
};

const getPreviewContributions = ({ id }: { id?: string }): Promise<{ data: EmployerContributionResponse }> => {
  const path: string = getApiPath(API.GET_PREVIEW_CONTRIBUTIONS, {
    id,
    filter: {
      random: Math.random(),
    },
  });
  return publicApi.get(path);
};

const submitContributions = (payload: SubmitContributionPayload) => {
  const path: string = getApiPath(API.SUBMIT_CONTRIBUTIONS);
  return publicApi.post(path, payload);
};

const updateContributionByField = ({ id, field, value }: UpdateContributionByFieldPayload) => {
  const path: string = getApiPath(API.UPDATE_CONTRIBUTION_BY_FIELD, {
    id,
    field,
    value,
  });
  return publicApi.patch(path, value);
};

const saveAnnualLimitExceededContributions = (payload: SaveAnnualLimitExceededContributionPayload) => {
  const path: string = getApiPath(API.SAVE_ANNUAL_LIMIT_EXCEEDED_CONTRIBUTIONS);
  return publicApi.post(path, payload);
};

const updateProfitSharingCheck = ({ id, value }: { id: string; value: 0 | 1 }) => {
  const path: string = getApiPath(API.UPDATE_PROFIT_SHARING_CHECK, { id });
  return publicApi.patch(path, value);
};

const getEmployer = ({ filter = {} }: { filter?: any }): Promise<{ data: CreateNewEmployerResponse }> => {
  const path: string = getApiPath('/api/onboarding/employer', { filter });
  return publicApi.get(path);
};

const createEmployer = (
  payload: CreateNewEmployerDto,
  config: AxiosRequestConfig<any> = {}
): Promise<{ data: CreateNewEmployerResponse }> => {
  const path: string = getApiPath('/api/onboarding/employer');
  return publicApi.put(path, payload, config);
};

const updateEmployer = (
  payload: any,
  filter: any = {},
  config: AxiosRequestConfig<any> = {}
): Promise<{ data: CreateNewEmployerResponse }> => {
  const path: string = getApiPath('/api/onboarding/employer', { filter });
  return publicApi.patch(path, payload, config);
};

const updateOnboardingCompany = (payload: any, filter: any = {}) => {
  const path: string = getApiPath('/api/onboarding/employer/company', {
    filter,
  });
  return publicApi.patch(path, payload);
};

const updateAlternativeBank = (companyId: string, payload: any, filter: any = {}) => {
  const path: string = getApiPath(`/api/onboarding/employer/company/${companyId}/manualBank`, {
    filter,
  });
  return publicApi.patch(path, payload);
};

const createOnboardingPlan = (payload: { plan: PlanFormData; stage: OnboardingStages }) => {
  const path: string = getApiPath('/api/onboarding/employer/plan');
  return publicApi.put(path, payload);
};

const updateOnboardingPlan = (payload: { plan: PlanFormData; stage: OnboardingStages }) => {
  const path: string = getApiPath('/api/onboarding/employer/plan');
  return publicApi.patch(path, payload);
};

const createOnboardingPlanTransfer = (payload: any) => {
  const path: string = getApiPath('/api/onboarding/employer/planTransfer');
  return publicApi.put(path, payload);
};

const updateOnboardingPlanTransfer = (payload: any) => {
  const path: string = getApiPath('/api/onboarding/employer/planTransfer');
  return publicApi.patch(path, payload);
};

const createOnboardingComplianceDisclosure = (payload: any) => {
  const path: string = getApiPath('/api/onboarding/employer/complianceDisclosure');
  return publicApi.put(path, payload);
};

const updateOnboardingComplianceDisclosure = (payload: any) => {
  const path: string = getApiPath('/api/onboarding/employer/complianceDisclosure');
  return publicApi.patch(path, payload);
};

const createMultipleBusinesses = (payload: any) => {
  const path: string = getApiPath('/api/onboarding/employer/complianceDisclosure/business');
  return publicApi.put(path, payload);
};

const updateMultipleBusinesses = (payload: any) => {
  const path: string = getApiPath('/api/onboarding/employer/complianceDisclosure/business');
  return publicApi.patch(path, payload);
};

const getMultipleBusinesses = () => {
  const path: string = getApiPath('/api/onboarding/employer/complianceDisclosure/business');
  return publicApi.get(path);
};

const getLatestDraftTransferPlan = (
  companyId: string
): Promise<{
  data: TransferPlanResponse;
}> => {
  const path: string = getApiPath(API.GET_LATEST_DRAFT_TRANSFER_PLAN, {
    companyId,
  });
  return publicApi.get(path);
};

const getAllEmployeesForDownload = ({ filter }: { filter: any }) => {
  const path: string = getApiPath(API.GET_ALL_EMPLOYEES_FOR_DOWNLOAD, {
    filter,
  });
  return publicApi.get(path);
};

const getPlaidLinkToken = ({ filter }: { filter?: any }) => {
  const path: string = getApiPath(API.PLAID_LINK_TOKEN, { filter });
  return publicApi.get(path);
};

const grantPlaidPermission = (payload: {
  publicToken: string;
  institutionId: string;
  institutionName: string;
  accountId: string;
}) => {
  const path: string = getApiPath(API.GRANT_PLAID_PERMISSION);
  return publicApi.post(path, payload);
};

const grantOnboardingPlaidPermission = ({
  payload,
}: {
  payload: {
    plaid: {
      publicToken: string;
      institutionId: string;
      institutionName: string;
      accountId: string;
    };
    stage: OnboardingStages;
  };
}) => {
  const path: string = getApiPath('/api/onboarding/employer/bank');
  return publicApi.post(path, payload);
};

const getAllEmployerReportForDownload = ({ filter }: { filter: any }) => {
  const path: string = getApiPath(API.GET_ALL_EMPLOYER_REPORT_FOR_DOWNLOAD, {
    filter,
  });
  return publicApi.get(path);
};

const findExistingPersonByEmail = ({ email, filter }: { email: string; filter?: any }) => {
  const path: string = getApiPath(API.FIND_PERSON, { email, filter });
  return publicApi.get(path);
};

const getLoanTasks = ({ companyId, filter }: { companyId: string; filter?: any }) => {
  const path: string = getApiPath(API.LOAN_TASKS, { companyId, filter });
  return publicApi.get(path);
};

const updateLoanStatus = ({ filter }: { filter: any }) => {
  const path: string = getApiPath(API.UPDATE_LOAN_STATUS, { filter });
  return publicApi.patch(path);
};

const sendEmailIncompleteOnboarding = ({ companyId }: { companyId: string }) => {
  const path: string = getApiPath('/api/companies/:companyId/onboardingNoCompletion/email', {
    companyId,
  });
  return publicApi.post(path);
};

const convertFTWilliam = ({ filter }: { filter?: any }) => {
  const path: string = getApiPath('/api/onboarding/employer/convertToFtWilliam', {
    filter,
  });
  return publicApi.patch(path);
};

const updateTemplateSign = ({
  payload,
  filter,
}: {
  payload: {
    signature: string;
    tpaAgreement: boolean;
    erisa338InvestmentManagementAgreement: boolean;
    trustAgreement?: boolean;
  };
  filter?: any;
}) => {
  const path: string = getApiPath('/api/onboarding/employer/forms/_ACCEPT', {
    filter,
  });
  return publicApi.patch(path, payload);
};

const getPdfDocs = ({ filter }: { filter?: any }) => {
  const path: string = getApiPath(API.GET_PDF_DOCS, { filter });
  return publicApi.get(path);
};

const getMasterPlan = ({ filter }: { filter: any }) => {
  const path: string = getApiPath(API.MASTER_PLAN, {
    filter,
  });
  return publicApi.get(path);
};

const cpaClientStoreSignature = ({
  payload,
  token,
  filter,
}: {
  payload: ClientCpaSignatureForm;
  token: string;
  filter?: any;
}) => {
  const path: string = getApiPath(API.CPA_CLIENT_STORE_SIGNATURE, {
    token,
    filter,
  });
  return publicApi.patch(path, payload);
};

const storeSignPlanDocument = ({
  payload,
  companyId,
  filter,
}: {
  payload: { signature: string };
  companyId: string;
  filter?: any;
}) => {
  const path: string = getApiPath('/api/onboarding/employer/signFtWilliamDoc', {
    companyId,
    filter,
  });
  return publicApi.patch(path, payload);
};

const onboardingCheckCredentials = ({ payload }: { payload: OnboardingCheckCredentialsPayload }) => {
  const path: string = getApiPath('/api/onboarding/employer');
  return publicApi.post(path, payload, {
    headers: {
      'X-Cs-Session': null,
    },
  });
};
const updateOnboardingStage = async (payload: any) => {
  const path = getApiPath('/api/onboarding/employer/stages');
  return publicApi.patch(path, payload);
};

const checkLateContribution = async (payload: any) => {
  const path = getApiPath('/api/contributions/checkLateContribution');
  return publicApi.post(path, payload);
};

const employeeStatusReportDownload = ({
  companyId,
  profileStatus,
}: {
  companyId?: string;
  profileStatus?: string[];
}) => {
  const path: string = getApiPath(API.DOWNLOAD_EMPLOYEE_STATUS_REPORT_BY_COMPANY, {
    companyId,
    filter: {
      profileStatus,
    },
  });
  return publicApi.get(path);
};

const retrieveAllParticipantsForfeitureReports = ({ companyId, filter = {} }: { companyId: string; filter?: any }) => {
  const path = getApiPath(`/api/companies/${companyId}/retrieveForfeitureReports`, { filter });
  return publicApi.get(path);
};

const getCompanyForfeitureReport = ({ companyId, filter = {} }: { companyId: string; filter?: any }) => {
  const path = getApiPath(`/api/companies/${companyId}/forfeitureReport`, filter);
  return publicApi.get(path);
};

const updateCompanyForfeitureReport = ({ companyId, payload }: { companyId: string; payload: any }) => {
  const path = `/api/companies/${companyId}/forfeitureReport`;
  return publicApi.patch(path, payload);
};

const createCompanyForfeitureReport = ({ companyId, payload }: { companyId: string; payload: any }) => {
  const path = `/api/companies/${companyId}/forfeitureReport`;
  return publicApi.put(path, payload);
};

const EmployerService = {
  getContributions,
  updatePaycheckDate,
  getZeroContribution,
  getPreviewContributions,
  submitContributions,
  updateContributionByField,
  saveAnnualLimitExceededContributions,
  updateProfitSharingCheck,
  createEmployer,
  updateEmployer,
  getLatestDraftTransferPlan,
  getEmployees,
  getAllEmployeesForDownload,
  getPlaidLinkToken,
  grantPlaidPermission,
  getAllEmployerReportForDownload,
  findExistingPersonByEmail,
  getLoanTasks,
  updateLoanStatus,
  sendEmailIncompleteOnboarding,
  convertFTWilliam,
  getMasterPlan,
  cpaClientStoreSignature,
  storeSignPlanDocument,
  getPdfDocs,
  updateTemplateSign,
  onboardingCheckCredentials,
  updateOnboardingCompany,
  createOnboardingPlanTransfer,
  updateOnboardingPlanTransfer,
  createOnboardingPlan,
  updateOnboardingPlan,
  grantOnboardingPlaidPermission,
  getEmployer,
  updateOnboardingStage,
  checkLateContribution,
  createOnboardingComplianceDisclosure,
  updateOnboardingComplianceDisclosure,
  createMultipleBusinesses,
  updateMultipleBusinesses,
  getMultipleBusinesses,
  employeeStatusReportDownload,
  updateAlternativeBank,
  deletePendingEmployee,
  onBehalfOptOutParticipant,
  retrieveAllParticipantsForfeitureReports,
  getCompanyForfeitureReport,
  updateCompanyForfeitureReport,
  createCompanyForfeitureReport,
};

export default EmployerService;
