import { ThemeProvider } from "@mui/material";
import "assets/css/App.css";
import Analytics from "common/components/Analytics";
import CustomAlert from "common/components/CustomAlert";
import Zendesk, { zendeskAPI } from "common/components/Zendesk";
import AppProvider from "contexts/AppProvider";
import AuthProvider from "contexts/AuthProvider";
import DialogProvider from "contexts/DialogProvider";
import { SnackbarProvider } from "notistack";
import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import {
  adminRoutes,
  corpRoutes,
  cpaRoutes,
  dashboardRoutes,
  employeeRoutes,
  employerRoutes,
  homeRoutes,
  pepRoutes,
  redirectRoutes,
} from "routes";
import "./styles/index.scss";
import theme from "./theme";

const router = createBrowserRouter([
  ...homeRoutes,
  ...dashboardRoutes,
  ...employeeRoutes,
  ...employerRoutes,
  ...adminRoutes,
  ...cpaRoutes,
  ...pepRoutes,
  ...corpRoutes,
  ...redirectRoutes,
]);

export default class App extends React.Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <DialogProvider>
          <AppProvider>
            <Analytics trackingId={process.env.REACT_APP_GA_TRACKING_ID} />
            <AuthProvider>
              <Zendesk
                defer
                zendeskKey={process.env.REACT_APP_ZENDESK_KEY}
                onLoaded={() => {
                  zendeskAPI("messenger", "close");
                }}
              />
              <RouterProvider router={router} />
              <SnackbarProvider
                maxSnack={3}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                Components={{
                  success: CustomAlert,
                  error: CustomAlert,
                  warning: CustomAlert,
                }}
                preventDuplicate
                autoHideDuration={5000}
                disableWindowBlurListener
              />
            </AuthProvider>
          </AppProvider>
        </DialogProvider>
      </ThemeProvider>
    );
  }
}
