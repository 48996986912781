import { Box, Grid, Paper } from "@mui/material";
import ParticipationRate from "components/employerDashboard/ParticipationRate";
import ParticipantsTable from "components/employerDashboard/ParticipantsTable";
import { HeaderDashboard } from "components/employerDashboard/HeaderDashboard";
import PlanAssetChart from "components/employerDashboard/PlanAssetChart";
import TotalContributionsChart from "components/employerDashboard/TotalContributionsChart";

const EmployerDashboardV2Page = () => {
  return (
    <Box margin={1.5} marginTop={{ zero: 1.5 }}>
      <Box>
        <div className="flex flex-col">
          <Paper
            elevation={4}
            sx={{
              margin: { zero: 1.5, xs: 3.5 },
              padding: { zero: 2, xs: 3 },
              border: "5px",
            }}
          >
            <Grid container spacing={2}>
              <Grid item zero={12} lg={6} xl={4}>
                <PlanAssetChart />
              </Grid>
              <Grid item zero={12} lg={6} xl={4}>
                <TotalContributionsChart />
              </Grid>
              <Grid item zero={12} lg={12} xl={4}>
                <Grid
                  container
                  justifyContent={"center"}
                  alignContent={"center"}
                >
                  <Grid item zero={12} lg={6} lg2={6} xl={12}>
                    <ParticipationRate />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <Box
            sx={{
              padding: { zero: 0, xs: 3 },
            }}
          >
            <ParticipantsTable />
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export default EmployerDashboardV2Page;
