import moment from 'moment';
import { REGEX_PATTERN } from 'core/constants';
import { z } from 'zod';

const validateDate = (value: any, ctx: any, isOptional?: boolean) => {
  if (!value) {
    if (!isOptional) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Date is Required',
      });
    }
  } else {
    if (moment(value).isAfter(moment()) || moment(value).isBefore('01/01/1900')) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Invalid Date',
      });
    }
  }
};

const goDateValidate = (isEdit?: boolean) => (date: any, ctx: any) => {
  if (typeof date === 'undefined' || (typeof date === 'boolean' && date)) {
    return true;
  } else {
    const selectedDate = moment(date);
    const minDate = moment().add(7, 'days');
    if (!date) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Start date is required.`,
      });
      return false;
    }
    if (!isEdit) {
      if (selectedDate.isBefore(minDate)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `Start date must be in next 7 days.`,
        });
        return false;
      } else {
        return true;
      }
    }
  }
};

const overviewSchema = {
  planStatus: z.string(),
  companyStatus: z.string().optional(),
  createdOn: z.custom().optional(),
  brokerageAccountId: z.string().optional(),
};

const addressSchema = {
  city: z.string().nonempty('City is required'),
  state: z.string().nonempty('State is required'),
  street: z.string().nonempty('Street is required'),
  zip: z.string().nonempty('Zip code is required').regex(REGEX_PATTERN.ZIP.pattern, REGEX_PATTERN.ZIP.message),
};

const baseCompanyDetailSchema = {
  registedBusinessCompany: z
    .string()
    .nonempty('Company name is required')
    .regex(REGEX_PATTERN.BUSINESS_NAME.pattern, REGEX_PATTERN.BUSINESS_NAME.message),
  businessAs: z.string().optional(),
  ein: z
    .string()
    .nonempty('EIN is required')
    .refine((value) => value.replace(/[^0-9]/g, '').length === 9, {
      message: 'Invalid ein',
    }),
  registrationType: z.string().nonempty('Registration type is required'),
  stateRegistered: z.string().nonempty('State of Business Registration is required'),
  phone: z.string().optional(),
  ...addressSchema,
  payFrequency: z.string().nonempty('Pay frequency is required'),
};

const companyDetailSchema = {
  ...baseCompanyDetailSchema,
  businessCode: z.string().nonempty('Business code is required'),
  industry: z.string().nonempty('Industry is required'),
};

export const pepCompanyDetailSchema = {
  ...baseCompanyDetailSchema,
};

export const multipleBusinessRequiredSchema = {
  numberOf80PercentOwnership: z.string().nonempty('Required'),
  eachBusinessPayroll: z.string().nonempty('Payroll is required'),
  otherBusinessRetirementPlan: z.string().nonempty('Business Retirement plan is required'),
  totalEmployee: z
    .number()
    .or(z.string().regex(/\d+/).transform(Number))
    .refine((n) => n >= 0, 'Number of employees greater 0'),
};

const multipleBusinessOptionalSchema = {
  numberOf80PercentOwnership: z.string().optional(),
  eachBusinessPayroll: z.string().optional(),
  otherBusinessRetirementPlan: z.string().optional(),
  totalEmployee: z.string().optional(),
};

export const complianceDisclosuresSchema = {
  ownersHaveMajorityStakeInAnotherBusiness: z.string(),
  otherBusinessEmployeesIncludedIn401K: z.string(),
  isOfferingRetirementPlan: z.string(),
  hasOfferedSimpleIRA: z.string(),
  id: z.string().optional(),
  companyId: z.string().optional(),
  ...multipleBusinessOptionalSchema,
};

const regulatoryDisclosuresSchema = {
  currentOrFormerPEPOfPublicOfficial: z.string(),
  representFFI31CFR: z.string(),
  representFFI31CFR103175: z.string(),
  internalNote: z.string().nullish(),
};

const planCustomizationOneSchema = (isEdit?: boolean) => {
  return {
    minAge: z.number(),
    eligibilityStart: z.string().nonempty('Service Requirement is required'),
    employerMatch: z.string().nonempty('Employer Match is required'),
    profitSharing: z.string().nonempty('Profit sharing is required'),
    vesting: z.any().optional(),
    contributionStartDate: z.custom().superRefine(goDateValidate(isEdit)),
    contributionStartDateOptions: z.string().nonempty('Preferred Contribution Start Date is required'),
    excludeHCE: z.boolean().optional(),
    employmentTypesToExclude: z.array(z.string()).optional(),
  };
};

const planCustomizationTwoSchema = {
  planType: z.string().nonempty(),
  offerLoan: z.boolean(),
  allowWithdrawal: z.boolean(),
  allowRollover: z.boolean(),
  autoEnrollment: z.boolean(),
};

const baseSponsorSchema = {
  firstName: z
    .string()
    .nonempty('First name is required')
    .regex(REGEX_PATTERN.TEXT.pattern, REGEX_PATTERN.TEXT.message),
  lastName: z.string().nonempty('Last name is required').regex(REGEX_PATTERN.TEXT.pattern, REGEX_PATTERN.TEXT.message),
  email: z.string().nonempty('Email is required').regex(REGEX_PATTERN.EMAIL.pattern, REGEX_PATTERN.EMAIL.message),
  employeeId: z.string().optional(),
};

export const accountAdminSchema = {
  ...baseSponsorSchema,
  accountId: z.string().optional(),
  jobTitle: z.string().optional(),
  phone: z.string().optional(),
};

const fileSchema = {
  file: z.any(),
  isUpdatedFile: z.boolean().optional(),
};

const baseUploadOptionalSchema = {
  plansummary: z.object({
    ...fileSchema,
    fileType: z.string().optional(),
  }),
  planbooklet: z.object({
    ...fileSchema,
    fileType: z.string().optional(),
  }),
  planexecution: z.object({
    ...fileSchema,
    fileType: z.string().optional(),
  }),
  plantpaa: z.object({
    ...fileSchema,
    fileType: z.string().optional(),
  }),
  planerisa: z.object({
    ...fileSchema,
    fileType: z.string().optional(),
  }),
};

const uploadPlanOptionalSchema = {
  ...baseUploadOptionalSchema,
  achForm: z.object({
    ...fileSchema,
    fileType: z.string().optional(),
  }),
  proofOfBankAccount: z.object({
    ...fileSchema,
    fileType: z.string().optional(),
  }),
};

const uploadDriverLicenseOptionalSchema = {
  driverLicenses: z.object({
    ...fileSchema,
    fileType: z.string().optional(),
  }),
};

export const pepOverviewSchema = {
  companyStatus: z.string().optional(),
  createdOn: z.custom().superRefine(validateDate),
  brokerageAccountId: z.string().optional(),
};

export const contactSchema = {
  contactFirstName: z
    .string()
    .nonempty('First name is required')
    .regex(REGEX_PATTERN.TEXT.pattern, REGEX_PATTERN.TEXT.message),
  contactLastName: z
    .string()
    .nonempty('Last name is required')
    .regex(REGEX_PATTERN.TEXT.pattern, REGEX_PATTERN.TEXT.message),
  contactEmail: z
    .string()
    .nonempty('Email is required')
    .regex(REGEX_PATTERN.EMAIL.pattern, REGEX_PATTERN.EMAIL.message),
};

export const retirementPlanOptionalSchema = {
  financialInstitution: z.string().optional(),
  type: z.custom().optional(),
  roughEstimate: z.string().optional(),
  numberOfEmployee: z.string().optional(),
};

const cpaOverviewSchema = {
  ...addressSchema,
  registedBusinessCompany: z
    .string()
    .nonempty('Company name is required')
    .regex(REGEX_PATTERN.BUSINESS_NAME.pattern, REGEX_PATTERN.BUSINESS_NAME.message),
  companyURL: z
    .string()
    .optional()
    .superRefine((value, ctx) => {
      const regex = new RegExp(REGEX_PATTERN.DOMAIN.pattern);
      if (!value) {
        return true;
      }
      if (regex.test(value)) {
        return true;
      } else {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: REGEX_PATTERN.DOMAIN.message,
        });
        return false;
      }
    }),
};

export const planSchema = (isEdit?: boolean) => {
  return z.object({
    planId: z.string().optional(),
    status: z.string().optional(),
    ...planCustomizationOneSchema(isEdit),
    ...planCustomizationTwoSchema,
    ...baseUploadOptionalSchema,
  });
};

const validateByRegex = (name: string, regexPattern: RegExp, regexMessage: string, isOptional?: boolean) => {
  return (value: any, ctx: any) => {
    if (typeof value === 'string' || typeof value === 'undefined') {
      if (value) {
        if (!regexPattern.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: regexMessage,
          });
        }
      } else {
        if (!isOptional) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `${name} is required`,
          });
        }
      }
    }
  };
};

export const legalContactSchema = {
  asFirstName: z.string().regex(REGEX_PATTERN.TEXT.pattern, REGEX_PATTERN.TEXT.message),
  asLastName: z.string().regex(REGEX_PATTERN.TEXT.pattern, REGEX_PATTERN.TEXT.message),
  asEmail: z.string().regex(REGEX_PATTERN.EMAIL.pattern, REGEX_PATTERN.EMAIL.message),
  asTitle: z.string().optional(),
  asPhone: z.string().optional(),
  hasCompanyContact: z.string().optional(),
};

export const legalContactOptionalSchema = {
  asFirstName: z.string().nullish(),
  asLastName: z.string().nullish(),
  asEmail: z.string().nullish(),
  asTitle: z.string().nullish(),
  asPhone: z.string().nullish(),
};

const allowCompleteOnboardingSchema = {
  allowCompleteOnboarding: z.string().nullish(),
};

export const multipleBusinessFormRequiredSchema = {
  name: z.string().nonempty('Name is required'),
  ein: z.string().nonempty('ein is required'),
  status: z.string().optional(),
  id: z.string().optional(),
  hasRetirementPlan: z.string().optional(),
  retirementPlan: z.object({
    ...retirementPlanOptionalSchema,
  }),
};

export const baseMultipleBusinessSchema = {
  status: z.string().optional(),
  id: z.string().optional(),
  name: z.string().optional(),
  ein: z.string().optional(),
  hasRetirementPlan: z.string().optional(),
  retirementPlan: z.object({
    ...retirementPlanOptionalSchema,
  }),
};

const multipleBusinessFormSchema = {
  businesses: z.array(z.object(baseMultipleBusinessSchema)).optional(),
};

export const basicPlanSchema = (isEdit?: boolean, isCreateNewAccount?: boolean) => {
  return z.object({
    ...allowCompleteOnboardingSchema,
    ...overviewSchema,
    ...companyDetailSchema,
    ...planCustomizationOneSchema(isEdit),
    ...planCustomizationTwoSchema,
    ...uploadPlanOptionalSchema,
    ...legalContactOptionalSchema,
    ...uploadDriverLicenseOptionalSchema,
    ssn: z.custom().superRefine(validateByRegex('SSN', REGEX_PATTERN.SSN.pattern, REGEX_PATTERN.SSN.message)),
    regulatoryDisclosure: z.object({
      ...regulatoryDisclosuresSchema,
    }),
    complianceDisclosure: z.object({
      ...complianceDisclosuresSchema,
      retirementPlan: z.object({
        ...retirementPlanOptionalSchema,
      }),
    }),
    ...multipleBusinessFormSchema,
    title: z.string().optional(),
    dob: z.custom().superRefine((value, ctx) => validateDate(value, ctx, true)),
    userName: z.string().optional(),
    employers: z.array(z.object(accountAdminSchema)).optional(),
  });
};

export const cpaSchemaIsNot401K = z.object({
  ...cpaOverviewSchema,
  ...contactSchema,
  employers: z.array(z.object(accountAdminSchema)).optional(),
});

export const cpaSchemaIs401K = (isEdit?: boolean, isCreateNewAccount?: boolean) =>
  z.object({
    ...allowCompleteOnboardingSchema,
    ...contactSchema,
    ...cpaOverviewSchema,
    ...overviewSchema,
    ...companyDetailSchema,
    ...planCustomizationOneSchema(isEdit),
    ...planCustomizationTwoSchema,
    ...uploadPlanOptionalSchema,
    employers: z.array(z.object(accountAdminSchema)).optional(),
    create401KPlan: z.string(),
    jobTitle: z.string().optional(),
    dob: z.custom().superRefine(validateDate),
    userName: z.string().optional(),
    asPhone: z.string().optional(),
    ssn: z.custom().superRefine(validateByRegex('SSN', REGEX_PATTERN.SSN.pattern, REGEX_PATTERN.SSN.message)),
    regulatoryDisclosure: z.object({
      ...regulatoryDisclosuresSchema,
    }),
    ...uploadDriverLicenseOptionalSchema,
    complianceDisclosure: z.object({
      ...complianceDisclosuresSchema,
      retirementPlan: z.object({
        ...retirementPlanOptionalSchema,
      }),
    }),
    ...multipleBusinessFormSchema,
  });

export const cooperateSchema = (isEdit?: boolean, isCreateNewAccount?: boolean) =>
  z.object({
    ...allowCompleteOnboardingSchema,
    ...overviewSchema,
    ...companyDetailSchema,
    ...planCustomizationOneSchema(isEdit),
    ...planCustomizationTwoSchema,
    ...uploadPlanOptionalSchema,
    ...legalContactOptionalSchema,
    partOfMultiBusinessAccount: z.string(),
    ssn: z.custom().superRefine(validateByRegex('SSN', REGEX_PATTERN.SSN.pattern, REGEX_PATTERN.SSN.message)),
    regulatoryDisclosure: z.object({
      ...regulatoryDisclosuresSchema,
    }),
    ...uploadDriverLicenseOptionalSchema,
    complianceDisclosure: z.object({
      ...complianceDisclosuresSchema,
      retirementPlan: z.object({
        ...retirementPlanOptionalSchema,
      }),
    }),
    ...multipleBusinessFormSchema,
    employers: z.array(z.object(accountAdminSchema)).optional(),
    title: z.string().optional(),
    dob: z.custom().superRefine(validateDate),
    userName: z.string().optional(),
  });

const addressOptionalSchema = {
  city: z.string().optional(),
  state: z.string().optional(),
  street: z.string().optional(),
  zip: z.string().optional(),
};

const companyDetailOptionalSchema = {
  ...baseCompanyDetailSchema,
  ...addressOptionalSchema,
  registedBusinessCompany: z
    .string()
    .nonempty('Company name is required')
    .regex(REGEX_PATTERN.BUSINESS_NAME.pattern, REGEX_PATTERN.BUSINESS_NAME.message),
  businessAs: z.string().optional(),
  ein: z
    .string()
    .nonempty('EIN is required')
    .refine((value) => value.replace(/[^0-9]/g, '').length === 9, {
      message: 'Invalid ein',
    }),
  registrationType: z.string().optional(),
  stateRegistered: z.string().optional(),
  phone: z.string().optional(),
  payFrequency: z.string().optional(),
  businessCode: z.string().optional(),
  industry: z.string().optional(),
};

const planCustomizationOneOptionalSchema = {
  minAge: z.any().optional(),
  eligibilityStart: z.string().optional(),
  employerMatch: z.string().optional(),
  profitSharing: z.string().optional(),
  vesting: z.any().optional(),
  contributionStartDate: z.string().optional(),
  contributionStartDateOptions: z.string().optional(),
  excludeHCE: z.boolean().optional(),
  employmentTypesToExclude: z.array(z.string()).optional(),
};

const planCustomizationTwoOptionalSchema = {
  planType: z.string().optional(),
  offerLoan: z.boolean().optional(),
  allowWithdrawal: z.boolean().optional(),
  allowRollover: z.boolean().optional(),
  autoEnrollment: z.boolean().optional(),
};

const regulatoryDisclosuresOptionalSchema = {
  currentOrFormerPEPOfPublicOfficial: z.string().optional(),
  representFFI31CFR: z.string().optional(),
  representFFI31CFR103175: z.string().optional(),
  internalNote: z.string().nullish(),
};

const complianceDisclosuresOptionalSchema = {
  ownersHaveMajorityStakeInAnotherBusiness: z.string().optional(),
  otherBusinessEmployeesIncludedIn401K: z.string().optional(),
  isOfferingRetirementPlan: z.string().optional(),
  hasOfferedSimpleIRA: z.string().optional(),
  id: z.string().optional(),
  companyId: z.string().optional(),
  ...multipleBusinessOptionalSchema,
};

export const contactOptionalSchema = {
  contactFirstName: z
    .custom()
    .superRefine(validateByRegex('First Name', REGEX_PATTERN.TEXT.pattern, REGEX_PATTERN.TEXT.message, true)),
  contactLastName: z
    .custom()
    .superRefine(validateByRegex('Last Name', REGEX_PATTERN.TEXT.pattern, REGEX_PATTERN.TEXT.message, true)),
  contactEmail: z
    .custom()
    .superRefine(validateByRegex('Email', REGEX_PATTERN.EMAIL.pattern, REGEX_PATTERN.EMAIL.message, true)),
};

const accountAdminOptionalSchema = {
  ...baseSponsorSchema,
  firstName: z
    .custom()
    .superRefine(validateByRegex('firstName', REGEX_PATTERN.TEXT.pattern, REGEX_PATTERN.TEXT.message, true)),
  lastName: z
    .custom()
    .superRefine(validateByRegex('lastName', REGEX_PATTERN.TEXT.pattern, REGEX_PATTERN.TEXT.message, true)),
  accountId: z.string().optional(),
  jobTitle: z.string().optional(),
  phone: z.string().optional(),
};

export const draftBasicPlanSchema = z.object({
  ...allowCompleteOnboardingSchema,
  ...overviewSchema,
  ...companyDetailOptionalSchema,
  ...planCustomizationOneOptionalSchema,
  ...planCustomizationTwoOptionalSchema,
  ...uploadPlanOptionalSchema,
  ...legalContactOptionalSchema,
  ...uploadDriverLicenseOptionalSchema,
  ssn: z.custom().superRefine(validateByRegex('SSN', REGEX_PATTERN.SSN.pattern, REGEX_PATTERN.SSN.message)),
  regulatoryDisclosure: z.object({
    ...regulatoryDisclosuresOptionalSchema,
  }),
  complianceDisclosure: z.object({
    ...complianceDisclosuresOptionalSchema,
    retirementPlan: z.object({
      ...retirementPlanOptionalSchema,
    }),
  }),
  ...multipleBusinessFormSchema,
  title: z.string().optional(),
  dob: z.custom().superRefine((value, ctx) => validateDate(value, ctx, true)),
  userName: z.string().optional(),
  employers: z.array(z.object(accountAdminOptionalSchema)).optional(),
});

const cpaOverviewOptionalSchema = {
  ...addressOptionalSchema,
  registedBusinessCompany: z
    .string()
    .nonempty('Company name is required')
    .regex(REGEX_PATTERN.BUSINESS_NAME.pattern, REGEX_PATTERN.BUSINESS_NAME.message),
  companyURL: z.string().optional(),
};

export const draftCPASchemaIsNot401K = z.object({
  ...cpaOverviewOptionalSchema,
  ...contactOptionalSchema,
  employers: z.array(z.object(accountAdminOptionalSchema)).optional(),
});

export const draftCPASchemaIs401K = z.object({
  ...allowCompleteOnboardingSchema,
  ...contactOptionalSchema,
  ...cpaOverviewOptionalSchema,
  ...companyDetailOptionalSchema,
  ...planCustomizationOneOptionalSchema,
  ...planCustomizationTwoOptionalSchema,
  ...uploadPlanOptionalSchema,
  employers: z.array(z.object(accountAdminOptionalSchema)).optional(),
  create401KPlan: z.string().optional(),
  jobTitle: z.string().optional(),
  dob: z.custom().superRefine((value, ctx) => validateDate(value, ctx, true)),
  userName: z.string().optional(),
  asPhone: z.string().optional(),
  ssn: z.custom().superRefine(validateByRegex('SSN', REGEX_PATTERN.SSN.pattern, REGEX_PATTERN.SSN.message)),
  regulatoryDisclosure: z.object({
    ...regulatoryDisclosuresOptionalSchema,
  }),
  ...uploadDriverLicenseOptionalSchema,
  complianceDisclosure: z.object({
    ...complianceDisclosuresOptionalSchema,
    retirementPlan: z.object({
      ...retirementPlanOptionalSchema,
    }),
  }),
  ...multipleBusinessFormSchema,
});

export const draftCooperateOptionalSchema = z.object({
  ...allowCompleteOnboardingSchema,
  ...overviewSchema,
  ...companyDetailOptionalSchema,
  ...planCustomizationOneOptionalSchema,
  ...planCustomizationTwoOptionalSchema,
  ...uploadPlanOptionalSchema,
  ...legalContactOptionalSchema,
  partOfMultiBusinessAccount: z.string().optional(),
  ssn: z.custom().superRefine(validateByRegex('SSN', REGEX_PATTERN.SSN.pattern, REGEX_PATTERN.SSN.message)),
  regulatoryDisclosure: z.object({
    ...regulatoryDisclosuresOptionalSchema,
  }),
  ...uploadDriverLicenseOptionalSchema,
  complianceDisclosure: z.object({
    ...complianceDisclosuresOptionalSchema,
    retirementPlan: z.object({
      ...retirementPlanOptionalSchema,
    }),
  }),
  ...multipleBusinessFormSchema,
  employers: z.array(z.object(accountAdminOptionalSchema)).optional(),
  title: z.string().optional(),
  dob: z.custom().superRefine((value, ctx) => validateDate(value, ctx, true)),
  userName: z.string().optional(),
});
