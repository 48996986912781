import { useMemo, useState } from "react";
import { Box, Card, Stack, Typography, Grid } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip as MuiTooltip } from "common/components";
import { formatCurrency } from "core/services/utils/dashboardUtils";
import { useAppSelector } from "states/hooks";
import TotalVestedUnvestedToggleButton from "./TotalVestedUnvestedToggleButton";

const RenderTitle = () => {
  return (
    <Typography
      sx={{
        fontSize: "12px",
        fontWeight: 600,
        color: "beige",
        lineHeight: "16px",
      }}
      gutterBottom
    >
      Current Balance
      <MuiTooltip
        title="This is the total amount of money currently in your company’s 401(k) account. This amount includes: Contributions (both employer & employee contributions); Investment Gains/Losses; Fees; and Withdrawals/Distributions."
        arrow
      >
        <InfoOutlinedIcon
          className="w-3"
          sx={{ color: "#9E9E91", marginLeft: "4px" }}
        />
      </MuiTooltip>
    </Typography>
  );
};
export default function PlanAssets() {
  const [subType, setSubType] = useState<any>("total");
  const profileDashboardInfo = useAppSelector(
    (root) => root.manageEmployee.dashboardInfos
  );

  const value = useMemo(() => {
    if (!profileDashboardInfo) return 0;
    if (subType === "total") {
      return profileDashboardInfo?.balances?.total ?? 0;
    } else if (subType === "vested") {
      return profileDashboardInfo?.balances?.vested ?? 0;
    } else if (subType === "unvested") {
      return profileDashboardInfo?.balances?.unvested ?? 0;
    }
    return 0;
  }, [subType, profileDashboardInfo]);

  const hasUnvested =
    profileDashboardInfo?.balances?.unvested !== undefined &&
    profileDashboardInfo?.balances?.unvested > 0;

  return (
    <Card
      sx={{
        maxWidth: "100%",
        border: "none",
      }}
      variant="outlined"
    >
      <Grid container>
        <Grid container item zero={12} mt={0.5} mb={0.5}>
          <RenderTitle />
        </Grid>
        <Grid item zero={12}>
          <Box
            sx={{
              borderRadius: "8px 40px 40px 8px",
              minHeight: "64px",
              maxWidth: "100%",
              backgroundColor: "#9E9E9114",
            }}
          >
            <Stack
              direction={{ zero: "column", mobile414: "row" }}
              justifyContent={"space-between"}
            >
              <Box mt={3} ml={2}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "25px",
                    lineHeight: "16px",
                  }}
                >
                  {formatCurrency(value)}
                </Typography>
              </Box>

              {hasUnvested && (
                <Box
                  mt={2.5}
                  ml={{ zero: 2, mobile414: 0 }}
                  mb={{ zero: 2, mobile414: 0 }}
                  mr={{ zero: 0, mobile414: 2.5 }}
                >
                  <TotalVestedUnvestedToggleButton
                    value={subType}
                    onChange={(val) => setSubType(val)}
                  />
                </Box>
              )}
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}
