import {
  Typography,
  Box,
  Grid,
  Popover,
  Paper,
  Stack,
  Card,
} from "@mui/material";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useMemo, useState } from "react";
import TotalYTD1YToggleButton from "./TotalYTD1YToggleButton";
import { Tooltip as MuiTooltip } from "common/components";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import useMousePosition from "hooks/useMousePosition";
import { formatCurrency, numToString } from "../../core/services/utils/dashboardUtils";
import { useAppSelector } from "states/hooks";
import moment from "moment";
import _ from "lodash";

const CustomizedYAxisTick = ({ x, y, payload }: any) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        fill="#75756F"
        textAnchor="end"
        fontSize={"10px"}
        fontWeight={400}
      >
        {payload.value === 0 ? "0" : `$ ${numToString(payload.value)}`}
      </text>
    </g>
  );
};
const CustomizedXAxisTick = ({ x, y, payload }: any) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={10}
        fill="#75756F"
        textAnchor="middle"
        fontSize={"10px"}
        fontWeight={400}
      >
        {payload.value}
      </text>
    </g>
  );
};
export default function TotalContributionByAccount() {
  const [isOpen, setIsOpen] = useState(false);
  const [subType, setSubType] = useState<any>("total");
  const mousePosition = useMousePosition();
  const [activeDot, setActiveDot] = useState<{
    x: number | undefined;
    y: number | undefined;
  }>({ x: undefined, y: undefined });
  const [activeValue, setActiveValue] = useState<any>(null);

  const profileDashboardInfo = useAppSelector(
    (root) => root.manageEmployee?.dashboardInfos
  );

  const data = useMemo(() => {
    let type: "allTime" | "oneYear" | "ytd" = "allTime";
    if (subType === "1y") {
      type = "oneYear";
    } else if (subType === "ytd") {
      type = "ytd";
    }
    const tradGraph = profileDashboardInfo?.traditionalGraphValues?.[type];
    const rothGraph = profileDashboardInfo?.rothGraphValues?.[type];
    const processArray =
      (tradGraph?.length ?? 0) > (rothGraph?.length ?? 0)
        ? tradGraph
        : rothGraph;
    let array: any[] = [];
    if (processArray) {
      for (const item of processArray) {
        if (!item?.day || !item?.month || !item?.year) {
          continue;
        }

        const time = moment()
          .month((item?.month || 1) - 1) // month is zero-based
          .date(item?.day || 1)
          .year(item?.year || 1970);

        const name = time.format("MMM YYYY");
        const selectTrad = tradGraph?.find(
          (f) =>
            f.month === item.month &&
            f.day === item?.day &&
            f.year === item?.year
        );
        const selectRoth = rothGraph?.find(
          (f) =>
            f.month === item.month &&
            f.day === item?.day &&
            f.year === item?.year
        );
        array.push({
          name,
          time,
          traditional: selectTrad?.balance ?? 0,
          roth: selectRoth?.balance ?? 0,
          total: (selectTrad?.balance ?? 0) + (selectRoth?.balance ?? 0),
        });
      }
      array = _.orderBy(array, "time", "asc");
    }
    return array;
  }, [profileDashboardInfo, subType]);

  return (
    <Card
      sx={{
        minHeight: "275px",
        maxHeight: "100%",
        border: "none",
      }}
      variant="outlined"
    >
      {activeValue && (
        <Popover
          anchorReference="anchorPosition"
          anchorPosition={{
            top: Math.max(0, (activeDot.y ?? 0) - 10),
            left: activeDot.x ?? 0,
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={isOpen}
          onClose={() => setIsOpen(false)}
        >
          <Paper
            elevation={6}
            sx={{
              width: "76px",
              height: "86px",
            }}
          >
            <Paper
              elevation={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "76px",
                height: "32px",
              }}
            >
              <Typography
                fontWeight={600}
                fontSize={"12px"}
                lineHeight={"16px"}
              >
                {`${formatCurrency(activeValue?.total)}`}
              </Typography>
            </Paper>
            <Stack>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  component={"span"}
                  fontWeight={400}
                  fontSize={"10px"}
                  lineHeight={"15px"}
                  color={"#75756F"}
                >
                  {`${formatCurrency(activeValue?.traditional)}`}
                </Typography>
                <MuiTooltip
                  title={
                    <span>
                      <strong>
                        This is how much is in your Traditional 401(k):
                      </strong>{" "}
                      A retirement savings plan where contributions are made
                      with pre-tax income, reducing your taxable income now, and
                      taxes are paid upon withdrawal in retirement.
                    </span>
                  }
                >
                  <InfoOutlinedIcon className="w-3" sx={{ color: "#9E9E91" }} />
                </MuiTooltip>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  component={"span"}
                  fontWeight={400}
                  fontSize={"10px"}
                  lineHeight={"15px"}
                  color={"#75756F"}
                >
                  {`${formatCurrency(activeValue?.roth)}`}
                </Typography>
                <MuiTooltip
                  title={
                    <span>
                      <strong>This is how much is in Roth 401(k):</strong> A
                      retirement savings plan where contributions are made with
                      after-tax income, allowing for tax-free withdrawals in
                      retirement as long as certain conditions are met.
                    </span>
                  }
                >
                  <InfoOutlinedIcon className="w-3" sx={{ color: "#9E9E91" }} />
                </MuiTooltip>
              </Box>
            </Stack>
          </Paper>
        </Popover>
      )}
      <Grid container>
        <Grid item zero={12} xs={6}>
          <Box mx={2}>
            <Typography
              component={"span"}
              color={"#9E9E91"}
              fontSize={"12px"}
              fontWeight={600}
              lineHeight={"18px"}
              gutterBottom
            >
              Account Balance
            </Typography>{" "}
            <MuiTooltip
              title={
                <span>
                  This graph breaks down how much is in each of your retirement
                  accounts. *Performance figures presented do not account for
                  fees, including Section 31 Fees. Please refer to{" "}
                  <a
                    href="https://www.saveday.com/kapitall-generation-disclosures-and-notices/"
                    rel="noreferrer"
                    target="_blank"
                    style={{
                      textDecoration: "underline",
                    }}
                  >
                    Additional Assessment details on the Saveday website
                  </a>
                  {/**/}. Furthermore, the account value is based on the
                  previous day's closing value.
                </span>
              }
            >
              <InfoOutlinedIcon className="w-3" sx={{ color: "#9E9E91" }} />
            </MuiTooltip>
          </Box>
        </Grid>
        <Grid item zero={12} xs={6}>
          <Box
            display={"flex"}
            justifyContent={{ zero: "start", xs: "end" }}
            mt={0.5}
            ml={{ zero: 1.5 }}
          >
            <TotalYTD1YToggleButton
              value={subType}
              onChange={(val: any) => setSubType(val)}
            />
          </Box>
        </Grid>
        <Grid item zero={12}>
          <Box m={1.5}>
            <ResponsiveContainer minHeight={350} height={"100%"} width={"99%"}>
              <ComposedChart
                data={data}
                onClick={(payload) => {
                  if (isOpen) {
                    if (
                      mousePosition.x !== activeDot.x ||
                      mousePosition.y !== activeDot.y
                    ) {
                      setIsOpen(false);
                    }
                  } else {
                    setIsOpen(true);
                    setActiveDot({
                      x: mousePosition.x ?? 0,
                      y: mousePosition.y ?? 0,
                    });
                    setActiveValue({
                      total:
                        payload.activePayload?.find(
                          (f) => f.dataKey === "total"
                        )?.value ?? 0,
                      roth:
                        payload.activePayload?.find((f) => f.dataKey === "roth")
                          ?.value ?? 0,
                      traditional:
                        payload.activePayload?.find(
                          (f) => f.dataKey === "traditional"
                        )?.value ?? 0,
                    });
                  }
                }}
              >
                <defs>
                  <linearGradient id="colorRoth" x1="0" y1="0" x2="1" y2="0">
                    <stop offset="60%" stopColor="rgba(105, 171, 0, 0.9)" />
                    <stop offset="85%" stopColor="rgba(105, 171, 0, 0.3)" />
                  </linearGradient>
                  <linearGradient id="colorTrad" x1="0" y1="0" x2="1" y2="0">
                    <stop offset="90%" stopColor="rgba(215, 230, 188, 0.9)" />
                    <stop offset="95%" stopColor="rgba(215, 230, 188, 0.3)" />
                  </linearGradient>
                </defs>

                <CartesianGrid
                  stroke="#E0DEC9"
                  strokeDasharray="8"
                  vertical={false}
                />
                <XAxis
                  dataKey="name"
                  tickLine={false}
                  tick={<CustomizedXAxisTick />}
                />
                <YAxis tickLine={false} tick={<CustomizedYAxisTick />} />
                <Tooltip cursor={false} content={<></>} />
                <Area
                  dataKey="roth"
                  stackId="1"
                  fill="url(#colorRoth)"
                  stroke="#bcd78d"
                />
                <Area
                  dataKey="traditional"
                  stackId="1"
                  fill="url(#colorTrad)"
                  stroke="#d7e6bc"
                />
                <Line
                  dataKey="total"
                  stroke="#69AB00"
                  dot={false}
                  strokeWidth={4}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}
