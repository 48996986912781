import { Grid } from '@mui/material';
import { Dash } from 'assets/SVGIcons/Dash';
import { Graph } from 'assets/SVGIcons/Graph';
import { Button } from 'common/components';
import BasicModal, { ModalProps } from 'common/components/BasicModal';
import { EmployeeStatus } from 'core/enums/employee-status.enum';
import { RequestStatus } from 'core/enums/request-status.enum';
import moment from 'moment';
import React, { ReactNode, useEffect, useState } from 'react';
import { updatePeopleSync } from 'states/auth/authSlice';
import { selectWebVersion } from 'states/employee/employeeSlice';
import { useAppDispatch, useAppSelector } from 'states/hooks';

type IBorderProps = {
  title: string;
  icon: ReactNode;
  description: string;
};

const compareVersion = (webVersionId?: string, personWebVersion?: string) => {
  const formatVersion = (webVersionId || '').replace('v', '');
  if (!personWebVersion || !moment(formatVersion).isValid()) return true;
  const convertToMoment = moment(formatVersion);
  return convertToMoment.isAfter((personWebVersion || '').replace('v', ''));
};

const BorderItem = (props: IBorderProps) => {
  const { title, icon, description } = props;
  return (
    <Grid
      container
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      className="border border-solid border-borderColor p-0 rounded-lg w-[200px] h-[223px]"
      gap={1}
    >
      <Grid item className="font-semibold text-[#2E3721] text-xs">
        {title}
      </Grid>
      <Grid item>{icon}</Grid>
      <Grid item className="font-normal text-deepGrey text-[10px] px-6">
        {description}
      </Grid>
    </Grid>
  );
};

const EmployeeNotificationRealeaseModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useAppDispatch();
  const people = useAppSelector((state) => state.auth.people);
  const profile = useAppSelector((state) => state.manageEmployee.employeeProfile);
  const webVersionId = useAppSelector(selectWebVersion);
  const shouldUpdateVersion = compareVersion(webVersionId, people?.personalConfig?.webVersionId);

  const updateWebVersion = async () => {
    if (webVersionId) {
      const response = await dispatch(
        updatePeopleSync({
          ...people,
          personalConfig: {
            ...(people?.personalConfig || {}),
            webVersionId,
            releaseAnnouncementAndFeedback: true,
          },
        })
      );
      if (response.meta.requestStatus === RequestStatus.FULFILLED) {
        setIsModalOpen(false);
      }
    } else {
      setIsModalOpen(false);
    }
  };

  const modalProps: ModalProps = {
    isOpen: isModalOpen,
    className: 'w-full max-w-[1062px]',
    handleClose: updateWebVersion,
  };

  useEffect(() => {
    if (webVersionId && shouldUpdateVersion && profile && profile.status !== EmployeeStatus.INVITED) {
      setIsModalOpen(true);
    }
  }, [people, profile]);

  return (
    <>
      {isModalOpen && (
        <BasicModal {...modalProps}>
          <Grid container flexDirection="column" alignItems="center" className="text-center" spacing={4}>
            <Grid item zero={12} flexDirection="column" justifyContent="center" alignItems="center">
              <Grid item zero={12}>
                <span className="font-bold text-base">Welcome to the new Saveday</span>
              </Grid>
              <Grid item zero={12}>
                <span className="font-normal text-sm">You asked, we listened! 😉</span>
              </Grid>
            </Grid>
            <Grid item zero={12} className="py-2 font-normal text-sm text-deepGrey">
              We're dedicated to continuously enhancing our platform based on your valuable feedback. Here's what's new:
            </Grid>
            <Grid item zero={12} container gap={6} justifyContent="center">
              <Grid item>
                <BorderItem
                  title="Revamped Home Page"
                  description="Get all the important information you need at a glance with our newly redesigned home page."
                  icon={<Graph className="w-[120px] h-[80px]" />}
                />
              </Grid>
              <Grid item>
                <BorderItem
                  title="Simplified Menu Bar"
                  description="Find what you’re looking for faster, with our streamlined menu bar."
                  icon={<Dash className="w-[64px] h-[92px]" />}
                />
              </Grid>
            </Grid>
            <Grid item zero={12} className="w-full">
              <Button
                className="min-w-full xs:min-w-[280px]"
                variant="contained"
                color="secondary"
                onClick={updateWebVersion}
              >
                I can’t wait to see!
              </Button>
            </Grid>
          </Grid>
        </BasicModal>
      )}
    </>
  );
};

export default EmployeeNotificationRealeaseModal;
