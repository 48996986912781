import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Button, FormControl, RadioButtonGroup, Select, TextField, TextFieldWithMask } from 'common/components';
import BasicModal from 'common/components/BasicModal';
import { REGEX_PATTERN } from 'core/constants';
import { MARITAL_OPTIONS, USSTATE_OPTIONS } from 'core/constants/employee-profile-data';
import { formatDateSendToServer } from 'core/functions/format-date';
import { useAuth } from 'contexts/AuthProvider';
import { DateFormatPattern } from 'core/enums/date-format-pattern.enum';
import { RequestStatus } from 'core/enums/request-status.enum';
import { EmployeeDetailsForm } from 'core/models/employeeDto.model';
import { convertPhoneNumber } from 'utils/input-formatter';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'states/hooks';
import {
  getEmployeeProfileAsync,
  selectEmployeeProfile,
  updateEmployeeProfileAsync,
  updatePeopleAsync,
} from 'states/manageEmployee/manageEmployeeSlice';
import { setSuccessMessage, setWarningMessage } from 'states/snackbarMessage/snackbarMessageSlice';
import BorderProfile from './BorderProfile';
import { getCash } from 'core/services/employee.service';
import { CashDTO } from 'core/models/Cash.model';

interface ITabDetailsProps {}

const YesNoOptions = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
];

interface ICastEmployee {
  [key: string]: Partial<CashDTO>;
}

interface IBrokerageAccount {
  brokerageAccountId?: string;
}
interface IEmployeeDetailForm extends EmployeeDetailsForm {
  isNumberOfChildren: string;
  isDependents: string;
  roth?: IBrokerageAccount;
  traditional?: IBrokerageAccount;
}

const formatData = (value?: string) => {
  if (!value) return 'N/A';
  return value;
};

const formLabelProps = {
  className: 'max-h-none mx-4 whitespace-pre-line font-bold',
};

const TabDetails = (props: ITabDetailsProps) => {
  const [isLoading, setLoading] = useState(false);
  const [isModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [, setCashEmployee] = useState<ICastEmployee>({
    traditional: {},
    roth: {},
  });
  const dispatch = useAppDispatch();
  const employeeProfile = useAppSelector(selectEmployeeProfile);
  const auth = useAuth();
  const ref = useRef(false);
  const theme = useTheme();
  const dimensionRange = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
  const isSystemAdmin = auth?.session?.values.systemAdmin;
  const streets = (employeeProfile?.person?.address?.street || ',').split(',');

  const DEFAULT_VALUES = {
    person: {
      userName: employeeProfile?.person.userName,
      firstName: employeeProfile?.person.firstName,
      lastName: employeeProfile?.person.lastName,
      dob: moment.utc(employeeProfile?.person.dob).format(DateFormatPattern.MonthDayYear) ?? '',
      phone: employeeProfile?.person.phone,
      address: {
        ...employeeProfile?.person.address,
        streetOne: streets[0] || '',
        streetTwo: streets[1] || '',
        state: employeeProfile?.person.address?.state || '',
      },
      personalEmail: employeeProfile?.person?.personalEmail,
    },
    maritalStatus: employeeProfile?.maritalStatus || 'INITIAL',
    numberOfChildren: employeeProfile?.numberOfChildren ?? 0,
    numberOfDependents: employeeProfile?.numberOfDependents ?? 0,
    workEmail: employeeProfile?.workEmail,
    workEmailPrimary: employeeProfile?.workEmailPrimary ? 'yes' : 'no',
    isNumberOfChildren: +(employeeProfile?.numberOfChildren ?? 0) > 0 ? 'yes' : 'no',
    isDependents: +(employeeProfile?.numberOfDependents ?? 0) > 0 ? 'yes' : 'no',
  };

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
    watch,
    setValue,
  } = useForm<IEmployeeDetailForm>({
    reValidateMode: 'onChange',
    defaultValues: DEFAULT_VALUES,
  });
  const isWorkEmailPrimary = watch('workEmailPrimary') === 'yes';
  const customFormLabelProps = {
    sx: {
      '.MuiFormLabel-root': {
        minHeight: dimensionRange ? 35 : 18,
        overflow: 'visible',
        textOverflow: 'unset',
        textWrap: 'balance',
      },
    },
  };

  const handleOnSubmit = async (values: IEmployeeDetailForm) => {
    const { personalEmail = undefined, ...personRest } = values.person;

    if (!isDirty) {
      return dispatch(setWarningMessage('Please edit the fields!'));
    } else {
      if (values.person.userName !== employeeProfile?.person.userName && !isModalOpen) {
        setConfirmModalOpen(true);
      } else {
        setLoading(true);
        const streets = [values.person.address.streetOne];
        if (values.person.address.streetTwo) {
          streets.push(values.person.address.streetTwo);
        }
        const responsePeople = await dispatch(
          updatePeopleAsync({
            payload: {
              id: employeeProfile?.personId,
              ...personRest,
              dob: formatDateSendToServer(values.person.dob),
              phone: convertPhoneNumber(values.person.phone || ''),
              address: {
                city: values.person.address.city,
                zip: values.person.address.zip,
                street: streets.join(','),
                state: values.person.address.state !== 'INITIAL' ? values.person.address.state : null,
              },
            },
          })
        );

        if (responsePeople.meta.requestStatus === RequestStatus.FULFILLED) {
          const response = await dispatch(
            updateEmployeeProfileAsync({
              id: employeeProfile?.id,
              maritalStatus: values.maritalStatus !== 'INITIAL' ? values.maritalStatus : null,
              numberOfChildren: values.numberOfChildren,
              numberOfDependents: values.numberOfDependents,
              workEmail: values.workEmail || undefined,
              workEmailPrimary: values.workEmailPrimary === 'yes',
              person: {
                personalEmail,
                email: (values.workEmailPrimary === 'yes' ? values.workEmail : personalEmail) || undefined,
              },
            })
          );
          if (response.meta.requestStatus === RequestStatus.FULFILLED) {
            await dispatch(getEmployeeProfileAsync());
            dispatch(setSuccessMessage('Update profile successfully.'));
            setConfirmModalOpen(false);
          }
        }
        setLoading(false);
      }
    }
  };

  const onCancel = () => {
    reset({});
  };

  const getCashEmployee = async () => {
    let params: ICastEmployee = {
      traditional: {},
      roth: {},
    };
    try {
      if (employeeProfile?.traditionalAccountId) {
        const res = await getCash(employeeProfile.traditionalAccountId);
        params.traditional = res;
      }
      if (employeeProfile?.rothAccountId) {
        const res = await getCash(employeeProfile.rothAccountId);
        params.roth = res;
      }
      setCashEmployee(params);
      ref.current = true;
      return params;
    } catch (error) {
      console.error(error);
    }
  };

  const handleAction = async () => {
    if (!ref.current) {
      const res = await getCashEmployee();
      reset({
        ...DEFAULT_VALUES,
        traditional: {
          brokerageAccountId: formatData(res?.traditional?.brokerageAccountId),
        },
        roth: {
          brokerageAccountId: formatData(res?.roth?.brokerageAccountId),
        },
      });
    } else {
      reset(DEFAULT_VALUES);
    }
  };

  useEffect(() => {
    if (employeeProfile) {
      handleAction();
    }
  }, [employeeProfile]);

  return (
    <form name="formEmployeeProfile" onSubmit={handleSubmit(handleOnSubmit)}>
      <BorderProfile>
        <Grid container spacing={2}>
          <Grid item lg={6} zero={12}>
            <Controller
              control={control}
              name="traditional.brokerageAccountId"
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    disabled={true}
                    label="Account Number: Traditional 401(k)"
                    placeholder="Input"
                  />
                );
              }}
            />
          </Grid>
          <Grid item lg={6} zero={12}>
            <Controller
              control={control}
              name="roth.brokerageAccountId"
              render={({ field }) => {
                return <TextField {...field} disabled={true} label="Account Number: ROTH 401(k)" />;
              }}
            />
          </Grid>
        </Grid>
      </BorderProfile>
      <BorderProfile>
        <Grid container spacing={2}>
          <Grid item lg={3} zero={12}>
            <Controller
              control={control}
              name="person.firstName"
              rules={{
                required: 'First Name is required.',
                pattern: {
                  value: REGEX_PATTERN.TEXT.pattern,
                  message: REGEX_PATTERN.TEXT.message,
                },
              }}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    disabled={true}
                    label="First Name"
                    placeholder="Type First Name"
                    error={errors.person?.firstName?.message}
                    required
                  />
                );
              }}
            />
          </Grid>
          <Grid item lg={3} zero={12}>
            <Controller
              control={control}
              name="person.lastName"
              rules={{
                required: 'Last Name is required.',
                pattern: {
                  value: REGEX_PATTERN.TEXT.pattern,
                  message: REGEX_PATTERN.TEXT.message,
                },
              }}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    disabled={true}
                    label="Last Name"
                    placeholder="Type Last Name"
                    error={errors.person?.lastName?.message}
                    required
                  />
                );
              }}
            />
          </Grid>
          <Grid item lg={3} zero={12}>
            <Controller
              control={control}
              name="person.userName"
              rules={{
                required: 'User Name is required.',
                pattern: {
                  value: REGEX_PATTERN.USER_NAME.pattern,
                  message: REGEX_PATTERN.USER_NAME.message,
                },
              }}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    disabled={!isSystemAdmin}
                    label="Username"
                    placeholder="Type User Name"
                    error={errors.person?.userName?.message}
                    required
                  />
                );
              }}
            />
          </Grid>
          <Grid item lg={3} zero={12}>
            <Controller
              control={control}
              name="person.dob"
              rules={{
                required: 'Date of Birth is required.',
              }}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    disabled={true}
                    label="Date of Birth"
                    placeholder="Date of Birth"
                    error={errors.person?.dob?.message}
                    required
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className="mt-2">
          <Grid item lg={2.4} zero={12}>
            <Controller
              control={control}
              name="person.address.streetOne"
              rules={{
                required: 'Address 1 is required.',
              }}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    label="Address Line 1"
                    placeholder="Type address"
                    error={errors.person?.address?.streetOne?.message}
                    required
                  />
                );
              }}
            />
          </Grid>
          <Grid item lg={2.4} zero={12}>
            <Controller
              control={control}
              name="person.address.streetTwo"
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    label="Address Line 2"
                    placeholder="Type address"
                    error={errors.person?.address?.streetTwo?.message}
                  />
                );
              }}
            />
          </Grid>
          <Grid item lg={2.4} zero={12}>
            <Controller
              control={control}
              name="person.address.city"
              rules={{
                required: 'City is required.',
              }}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    label="City"
                    placeholder="Type City"
                    error={errors.person?.address?.city?.message}
                    required
                  />
                );
              }}
            />
          </Grid>
          <Grid item lg={2.4} zero={12}>
            <Controller
              control={control}
              name="person.address.state"
              rules={{ required: 'State is required.' }}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    label="State"
                    data={USSTATE_OPTIONS.map((item) => ({
                      value: item.value,
                      label: item.label,
                    }))}
                    error={errors.person?.address?.state?.message}
                    required
                  />
                );
              }}
            />
          </Grid>
          <Grid item lg={2.4} zero={12}>
            <Controller
              control={control}
              name="person.address.zip"
              rules={{
                required: 'Zip code is required.',
                pattern: {
                  value: /\d{5}/,
                  message: 'Zip code has 5 numbers',
                },
              }}
              render={({ field: { ref, ...restField } }) => {
                return (
                  <TextFieldWithMask
                    {...restField}
                    label="Zip code"
                    placeholder="Type Zip code"
                    format="#####"
                    mask=""
                    error={errors.person?.address?.zip?.message}
                    required
                  />
                );
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className="mt-2">
          <Grid item lg={4} zero={12}>
            <Controller
              control={control}
              name="person.phone"
              rules={{ required: 'Phone is required.' }}
              render={({ field: { ref, ...restField } }) => {
                return (
                  <TextFieldWithMask
                    {...restField}
                    label="Phone Number"
                    placeholder="Type Phone"
                    format="(###) ### ####"
                    error={errors.person?.phone?.message}
                    required
                  />
                );
              }}
            />
          </Grid>
          <Grid item lg={4} zero={12}>
            <Controller
              control={control}
              name="workEmail"
              rules={{
                required: isWorkEmailPrimary ? 'Work Email is required!' : false,
                pattern: {
                  value: REGEX_PATTERN.EMAIL.pattern,
                  message: REGEX_PATTERN.EMAIL.message,
                },
              }}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    type="email"
                    label="Work Email"
                    placeholder="Type Work Email"
                    error={errors.workEmail?.message}
                    required={isWorkEmailPrimary}
                  />
                );
              }}
            />
          </Grid>
          <Grid item lg={4} zero={12}>
            <Controller
              control={control}
              name="person.personalEmail"
              rules={{
                required: !isWorkEmailPrimary ? 'Personal Email is required!' : false,
                pattern: {
                  value: REGEX_PATTERN.EMAIL.pattern,
                  message: REGEX_PATTERN.EMAIL.message,
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    {...field}
                    type="email"
                    label="Personal Email"
                    placeholder="Type Personal Email"
                    error={fieldState.error?.message}
                    required={!isWorkEmailPrimary}
                  />
                );
              }}
            />
          </Grid>
          <Grid item lg={12} zero={12} className="mt-4">
            <Controller
              control={control}
              name="workEmailPrimary"
              render={({ field }) => {
                return (
                  <FormControl
                    label="Please specify the email address you prefer for us to use as your primary contact:"
                    sx={{
                      '.MuiFormLabel-root': {
                        margin: '0px !important',
                        fontSize: '14px !important',
                      },
                    }}
                    required
                  >
                    <RadioButtonGroup
                      {...field}
                      row
                      options={[
                        { label: 'Work', value: 'yes' },
                        { label: 'Personal', value: 'no' },
                      ]}
                    />
                  </FormControl>
                );
              }}
            />
          </Grid>
        </Grid>
      </BorderProfile>
      <BorderProfile>
        <Grid container spacing={2}>
          <Grid item lg={2.4} zero={12}>
            <Controller
              control={control}
              name="maritalStatus"
              rules={{ required: 'Marital Status is required.' }}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    label="Marital Status"
                    data={MARITAL_OPTIONS.map((item) => ({
                      value: item.value,
                      label: item.label,
                    }))}
                    error={errors.maritalStatus?.message}
                    required
                    formLabelProps={formLabelProps}
                    formLabelStyle={customFormLabelProps}
                  />
                );
              }}
            />
          </Grid>
          <Grid item lg={2.4} zero={12}>
            <Controller
              control={control}
              name="isNumberOfChildren"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    label="Do you have children?"
                    data={YesNoOptions}
                    onChange={(event) => {
                      field.onChange(event);
                      if (event.target.value === 'no') {
                        setValue('numberOfChildren', 0);
                      }
                    }}
                    required
                    formLabelProps={formLabelProps}
                    formLabelStyle={customFormLabelProps}
                  />
                );
              }}
            />
          </Grid>
          <Grid item lg={2.4} zero={12}>
            <Controller
              control={control}
              name="numberOfChildren"
              rules={{
                validate: {
                  greaterZero: (value) => {
                    if (!value && watch('isNumberOfChildren') === 'yes') {
                      return 'Number of Employees greater 0';
                    }
                  },
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    {...field}
                    label="If yes, how many?"
                    disabled={watch('isNumberOfChildren') === 'no'}
                    onChange={(event) => {
                      const value = Number(event.target.value.replace(/[^0-9]/g, ''));
                      field.onChange(value);
                    }}
                    error={fieldState.error?.message}
                    required={watch('isNumberOfChildren') === 'yes'}
                    formLabelProps={formLabelProps}
                    formLabelStyle={customFormLabelProps}
                  />
                );
              }}
            />
          </Grid>
          <Grid item lg={2.4} zero={12}>
            <Controller
              control={control}
              name="isDependents"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    label="Do you have dependents?"
                    data={YesNoOptions}
                    onChange={(event) => {
                      field.onChange(event);
                      if (event.target.value === 'no') {
                        setValue('numberOfDependents', 0);
                      }
                    }}
                    required
                    formLabelProps={formLabelProps}
                    formLabelStyle={customFormLabelProps}
                  />
                );
              }}
            />
          </Grid>
          <Grid item lg={2.4} zero={12}>
            <Controller
              control={control}
              name="numberOfDependents"
              rules={{
                validate: {
                  greaterZero: (value) => {
                    if (!value && watch('isDependents') === 'yes') {
                      return 'Number of Dependents greater 0';
                    }
                  },
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    {...field}
                    label="If yes, how many?"
                    disabled={watch('isDependents') === 'no'}
                    onChange={(event) => {
                      const value = Number(event.target.value.replace(/[^0-9]/g, ''));
                      field.onChange(value);
                    }}
                    error={fieldState.error?.message}
                    required={watch('isDependents') === 'yes'}
                    formLabelProps={formLabelProps}
                    formLabelStyle={customFormLabelProps}
                  />
                );
              }}
            />
          </Grid>
        </Grid>
      </BorderProfile>
      <div className="w-full mt-10 text-right">
        <div className="inline-flex items-center justify-end w-full gap-6 sm:w-1/3 sm:pl-4 zero:flex-col-reverse sm:flex-row">
          <Button
            className="zero:w-full zero:max-w-none sm:w-1/2 sm:min-w-[140px]"
            variant="outlined"
            color="secondary"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            className="zero:w-full zero:max-w-none sm:w-1/2 sm:min-w-[140px]"
            type="submit"
            variant="contained"
            color="secondary"
            disabled={isLoading}
            loading={isLoading}
          >
            Save
          </Button>
        </div>
      </div>
      <BasicModal className="w-full max-w-[550px]" isOpen={isModalOpen} handleClose={() => setConfirmModalOpen(false)}>
        <Typography className="text-xl font-bold text-center text-superBlack">CONFIRMATION!</Typography>
        <Typography className="mt-6 text-sm font-normal text-center text-superBlack">
          Are you sure to change the username of this user? An email will send to this user for notification
          accordingly.
        </Typography>
        <div className="flex items-center justify-center gap-4 mt-6">
          <Button variant="outlined" color="secondary" onClick={() => setConfirmModalOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            type="submit"
            loading={isLoading}
            disabled={isLoading}
            onClick={handleSubmit(handleOnSubmit)}
          >
            Yes
          </Button>
        </div>
      </BasicModal>
    </form>
  );
};

export default TabDetails;
