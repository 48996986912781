import { createSvgIcon } from "@mui/material";

export const Graph = createSvgIcon(
  <svg viewBox="0 0 120 81" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="16" height="80" rx="8" fill="#E0DEC9" />
    <rect x="24.5" y="1" width="95" height="79" rx="15.5" stroke="#E0DEC9" />
    <rect
      x="32"
      y="72.5"
      width="16"
      height="16"
      rx="8"
      transform="rotate(-90 32 72.5)"
      fill="#F4F3E9"
    />
    <rect
      x="52"
      y="72.5"
      width="16"
      height="16"
      rx="8"
      transform="rotate(-90 52 72.5)"
      fill="#F4F3E9"
    />
    <rect
      x="32"
      y="52.5"
      width="16"
      height="16"
      rx="8"
      transform="rotate(-90 32 52.5)"
      fill="#F4F3E9"
    />
    <rect
      x="52"
      y="52.5"
      width="16"
      height="16"
      rx="8"
      transform="rotate(-90 52 52.5)"
      fill="#F4F3E9"
    />
    <rect
      x="32"
      y="28.5"
      width="20"
      height="38"
      rx="8"
      transform="rotate(-90 32 28.5)"
      fill="#F4F3E9"
    />
    <rect
      x="74"
      y="72.5"
      width="16"
      height="16"
      rx="8"
      transform="rotate(-90 74 72.5)"
      fill="#F4F3E9"
    />
    <rect
      x="94"
      y="72.5"
      width="16"
      height="16"
      rx="8"
      transform="rotate(-90 94 72.5)"
      fill="#69AB00"
    />
    <rect
      x="74"
      y="52.5"
      width="16"
      height="16"
      rx="8"
      transform="rotate(-90 74 52.5)"
      fill="#F4F3E9"
    />
    <rect
      x="94"
      y="52.5"
      width="16"
      height="16"
      rx="8"
      transform="rotate(-90 94 52.5)"
      fill="#F4F3E9"
    />
    <rect
      x="74"
      y="28.5"
      width="20"
      height="38"
      rx="8"
      transform="rotate(-90 74 28.5)"
      fill="#E0DEC9"
    />
  </svg>,
  "Graph"
);
