import { FC, useEffect, useState } from 'react';
import { Box, Card, Stack, Typography, Grid, Paper } from '@mui/material';
import { ResponsiveContainer, Tooltip as ChartTooltip, Bar, BarChart, XAxis, YAxis } from 'recharts';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import { Tooltip as MuiTooltip } from 'common/components';
import { formatCurrency } from 'core/services/utils/dashboardUtils';
import { useAppSelector } from 'states/hooks';

const labels = [
  {
    key: 'traditional',
    label: 'Traditional Balance',
    tooltip: ``,
    color: '#69AB00',
  },
  {
    key: 'roth',
    label: 'Roth Balance',
    tooltip: ``,
    color: '#3B471C',
  },
];

const CustomTooltip = ({ active, payload, currentHover }: any) => {
  if (active && payload?.length && payload?.find((f: any) => f.dataKey === currentHover)) {
    return (
      <Paper elevation={6}>
        <div
          className="flex items-center justify-center mx-1"
          style={{
            backgroundColor: '#fff',
            borderRadius: '4px',
            minHeight: '24px',
            minWidth: '45px',
          }}
        >
          <Typography fontWeight={600} fontSize={'10px'} lineHeight={'16px'}>
            {`${formatCurrency(payload.find((f: any) => f.dataKey === currentHover)?.value)}`}
          </Typography>
        </div>
      </Paper>
    );
  }

  return null;
};

const RenderLegend: FC<{ data: any[] }> = ({ data }) => {
  if (!data?.length) return null;
  const keys = Object.keys(data[0]);
  return (
    <Stack direction={'row'} justifyContent={'space-evenly'}>
      {keys.map((key: string) => {
        const item = labels.find((lb) => lb.key === key);
        const color = item?.color ?? '#000';
        const label = item?.label;
        const tooltip = item?.tooltip;
        return (
          <p key={key}>
            {keys.length > 1 && (
              <CircleIcon
                style={{
                  color: color,
                  fontSize: '8px',
                  marginRight: '2px',
                }}
              />
            )}
            <span
              style={{
                color: '#2E3721',
                fontWeight: 500,
                fontSize: '10px',
                lineHeight: '15px',
                marginRight: '2px',
              }}
            >
              {label}
            </span>
            {tooltip && (
              <MuiTooltip
                title={tooltip}
                slotProps={{
                  popper: {
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, -12],
                        },
                      },
                    ],
                  },
                }}
              >
                <InfoOutlinedIcon className="w-3" sx={{ color: '#9E9E91' }} />
              </MuiTooltip>
            )}
          </p>
        );
      })}
    </Stack>
  );
};

export default function TotalRothTradBalanceChart() {
  const [currentHover, setCurrentHover] = useState('');
  const [chart, setChart] = useState<any>([]);
  const [isRenderLegend, setIsRenderLegend] = useState<boolean>(true);

  const profileDashboardInfo = useAppSelector((root) => root.manageEmployee.dashboardInfos);

  useEffect(() => {
    const final = {
      roth: profileDashboardInfo?.balances?.rothBalance || 0,
      traditional: profileDashboardInfo?.balances?.traditionalBalance || 0,
    };
    setChart([
      {
        traditional: Math.max(0, final.traditional),
        roth: Math.max(0, final.roth),
      },
    ]);

    setIsRenderLegend(!(final.roth === 0 && final.traditional === 0));
  }, [profileDashboardInfo]);

  const renderRadius = (key: string): [number, number, number, number] => {
    let radius: [number, number, number, number] = [0, 0, 0, 0];
    const line = chart?.length > 0 ? chart[0] : undefined;
    if (key === 'roth') {
      if (line?.roth) {
        radius = [0, 25, 25, 0];
      }
      if (!line?.traditional) {
        radius = [25, 25, 25, 25];
      }
    }
    if (key === 'traditional') {
      if (line?.traditional) {
        radius = [25, 0, 0, 25];
      }
      if (!line?.roth) {
        radius = [25, 25, 25, 25];
      }
    }
    return radius;
  };

  return (
    <Card
      sx={{
        maxWidth: '100%',
        border: 'none',
      }}
      variant="outlined"
    >
      <Grid container>
        <Grid item xs={12}>
          <Box ml={1}>
            <ResponsiveContainer width="100%" height="99%" minHeight={'30px'}>
              <BarChart layout="vertical" data={chart} stackOffset="expand">
                <XAxis hide type="number" />
                <YAxis type="category" hide />

                <ChartTooltip
                  cursor={{ fill: 'transparent' }}
                  wrapperStyle={{
                    zIndex: 1,
                    marginTop: 10,
                    border: 'none',
                    outline: 'none',
                  }}
                  content={<CustomTooltip currentHover={currentHover} />}
                />

                <Bar
                  dataKey="traditional"
                  fill={labels.find((f) => f.key === 'traditional')?.color}
                  stackId="solo"
                  barSize={16}
                  onMouseOver={() => setCurrentHover('traditional')}
                  radius={renderRadius('traditional')}
                />
                <Bar
                  dataKey="roth"
                  fill={labels.find((f) => f.key === 'roth')?.color}
                  stackId="solo"
                  barSize={16}
                  onMouseOver={() => setCurrentHover('roth')}
                  radius={renderRadius('roth')}
                />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {isRenderLegend && <RenderLegend data={chart} />}
        </Grid>
      </Grid>
    </Card>
  );
}
