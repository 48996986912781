import PEPAdminLayout from "layout/PEPAdminLayout";
import { lazy, Suspense } from "react";
import { RouteObject } from "react-router-dom";
const CPADashboard = lazy(() => import("pages/cpaDashboard"));
const CPAManageClients = lazy(() => import("pages/cpaManageClients"));
const PEPManageMasterPlans = lazy(() => import("pages/pepManageMasterPlans"));
const CPAAccountSettings = lazy(() => import("pages/cpaAccountSettings"));

export const pepRoutes: RouteObject[] = [
  {
    path: "/pep", // /dashboard
    element: <PEPAdminLayout />,
    children: [
      {
        path: "dashboard", // /cpa/dashboard
        element: (
          <Suspense>
            <CPADashboard />
          </Suspense>
        ),
      },
      {
        path: "manage-clients", // /cpa/manage-clients
        element: (
          <Suspense>
            <CPAManageClients />
          </Suspense>
        ),
      },
      {
        path: "manage-master-plans", // /cpa/manage-master-plans
        element: (
          <Suspense>
            <PEPManageMasterPlans />
          </Suspense>
        ),
      },
      {
        path: "account-settings", // /cpa/account-settings
        element: (
          <Suspense>
            <CPAAccountSettings />
          </Suspense>
        ),
      },
    ],
  },
];
