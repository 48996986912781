import { FormControl, SelectChangeEvent } from "@mui/material";
import { getPastTenYear } from "core/functions/get-current-year";
import {
  Button,
  PageHeader,
  Select,
  SelectItem,
  TextField,
} from "common/components";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Controller, useForm } from "react-hook-form";
import { updateCompanyStatement } from "core/services/api.service";

type FormValue = {
  accountNumber: string;
  year: number;
};

const AdminStatements = () => {
  const { register, handleSubmit, control } = useForm<FormValue>({
    defaultValues: {
      year: new Date().getFullYear(),
    },
  });
  const mapDataForSelectYear = (data: number[]): SelectItem[] => {
    if (!!data.length) {
      return data.map((item: number) => ({
        label: item,
        value: item.toString(),
      }));
    }
    return [];
  };
  const onSubmit = (data: FormValue) => {
    const updatePayroll = {
      ...data,
      companyId: null,
    };
    updateCompanyStatement(updatePayroll);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PageHeader
        pageTitle="Statements"
        subTitle="For your convenience, Saveday lets you view, download and print your transaction statements."
      />
      <section className="px-8">
        <TextField
          className="md:w-[420px]"
          placeholder="Account Number"
          onlyNumber
          type="number"
          {...register("accountNumber")}
        />
      </section>
      <section className="w-full px-8 py-4 flex gap-5">
        <FormControl className="w-full flex-1 md:w-[300px] md:flex-none">
          <Controller
            control={control}
            name="year"
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  data={mapDataForSelectYear(getPastTenYear())}
                  value={value.toString()}
                  onChange={(e: SelectChangeEvent<string>) => {
                    onChange(Number(e.target.value));
                  }}
                />
              );
            }}
          />
        </FormControl>
        <FormControl>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            startIcon={<ArrowForwardIosIcon />}
            sx={{
              padding: "14px 24px",
              ".MuiButton-startIcon": {
                margin: 0,
              },
            }}
          />
        </FormControl>
      </section>
      <section className="px-8">
        <table className="w-full">
          <tr className="bg-lightGreen">
            <th className="py-2">Date</th>
            <th>Account Type</th>
            <th>Transaction Type</th>
          </tr>
        </table>
      </section>
    </form>
  );
};

export default AdminStatements;
