import BasicModal from "common/components/BasicModal";
import { OnboardingPlaidStatus } from "core/enums";
import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "states/hooks";
import {
  grantPlaidPermissionAsync,
  onboardingConnectPlaidAsync,
} from "states/onboardingSlice";
import { getCompanyDetailsAsync } from "states/companyDetails/companyDetailsSlice";
import { PlaidLinkOptions, usePlaidLink } from "react-plaid-link";
import { RequestStatus } from "core/enums/request-status.enum";
import { useAuth } from "contexts/AuthProvider";
import { CookieNames } from "core/enums/cookie-names.enum";
import { getCookie } from "core/services/cookie.service";
import { Grid } from "@mui/material";
import { Button, TextField } from "common/components";
import { Controller, UseFormReturn } from "react-hook-form";
import UploadProofOfBankAccount from "./UploadProofOfBankAccount";
import { FormValueType, PlaidStatusTemp } from "pages/employerBankPayroll";
import PlaidAlertContent from "./PlaidAlertContent";
import { setErrorMessage } from "states/snackbarMessage/snackbarMessageSlice";
import { LoadingComponent } from "components/employerManagePlan/LoadingComponent";

const PlaidConnectionComp = ({
  form,
  setUsedAlternativeStatus,
  isUsedAlternativeStatus,
}: {
  form: UseFormReturn<FormValueType>;
  isUsedAlternativeStatus: PlaidStatusTemp;
  setUsedAlternativeStatus: Dispatch<SetStateAction<PlaidStatusTemp>>;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dataRef = useRef("");
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const companyDetailsState = useAppSelector(
    (state) => state.companyDetails.companyDetails
  );
  const plaidLinkToken = useAppSelector(
    (state) => state.onboarding.plaidLinkToken
  );
  const {
    formState: { errors },
    control,
    register,
    setValue,
  } = form;
  const { ASSISTED_CONNECTION, INTEGRATED } = OnboardingPlaidStatus;
  const plaidStatus =
    companyDetailsState?.plaidOnboardingStatus as OnboardingPlaidStatus;
  const isCompanyAdminOfCurrentCompany =
    auth?.session?.isCompanyAdminOfCurrentCompany;
  const companyId = getCookie<string>(CookieNames.CurrentCompanyId);
  const plaidConntected = [ASSISTED_CONNECTION, INTEGRATED].includes(
    plaidStatus
  );
  const isExistBankAccount = !!companyDetailsState?.bankAccount;
  const proofOfBankAccountUrl =
    companyDetailsState?.bankAccount?.proofOfBankAccount;
  const [loading, setLoading] = useState(false);

  const config: PlaidLinkOptions = {
    token: plaidLinkToken.linkToken || "",
    onSuccess: async (public_token, metadata: any) => {
      setLoading(true);
      if (isCompanyAdminOfCurrentCompany) {
        const response = await dispatch(
          grantPlaidPermissionAsync({
            publicToken: public_token,
            institutionId: metadata.institution?.institution_id as string,
            institutionName: metadata.institution?.name as string,
            accountId: metadata.account_id as string,
          })
        );
        if (response.meta.requestStatus === RequestStatus.FULFILLED) {
          dispatch(getCompanyDetailsAsync({ id: companyId }));
          setIsModalOpen(false);
          setUsedAlternativeStatus(PlaidStatusTemp.DEFAULT);
        }
      }
      setLoading(false);
    },
    onExit: async (err, metadata) => {
      setLoading(true);
      console.log("Connect Plaid Error:  ", err);
      console.log("Connect Plaid Metadata ", metadata);
      if (dataRef.current === "OPEN_OAUTH") {
        await updatePlaidStatus(ASSISTED_CONNECTION);
        setUsedAlternativeStatus(PlaidStatusTemp.UN_SUCCESSFUL);
      }
      dataRef.current = "";
      await dispatch(getCompanyDetailsAsync({ id: companyId }));
      setIsModalOpen(false);
      setLoading(false);
    },
    onEvent: (err) => {
      if (err === "OPEN_OAUTH") {
        dataRef.current = err;
      }
    },
  };
  const { open: plaidConnectOpen } = usePlaidLink(config);

  const updatePlaidStatus = async (plaidStatus: OnboardingPlaidStatus) => {
    const res = await dispatch(
      onboardingConnectPlaidAsync({
        payload: {
          plaidStatus,
        },
      })
    );
    if (res.meta.requestStatus === RequestStatus.FULFILLED) {
      if (plaidStatus === OnboardingPlaidStatus.ASSISTED_CONNECTION) {
        const msg =
          "Please re-verify another Bank account or used alternative verification!";
        dispatch(setErrorMessage(msg));
      }
    }
  };

  const isRequired = () => {
    return (
      isUsedAlternativeStatus === PlaidStatusTemp.USED_ALTERNATIVE_VERIFICATION
    );
  };

  const setMessage = (message: string) => {
    if (isRequired()) {
      return message;
    }
    return "";
  };

  const resetBankAccount = () => {
    const proofOfBankAccountValue = {
      name: "",
      size: 0,
      type: "",
      url: "",
      updated: false,
      file: null,
    };
    setValue("bankAccount.bankName", "");
    setValue("bankAccount.bankAccountName", "");
    setValue("bankAccount.bankAccountNumber", "");
    setValue("bankAccount.bankRoutingNumber", "");
    setValue("bankAccount.proofOfBankAccount", proofOfBankAccountValue);
  };

  const fieldProps = {
    disabled: !isRequired(),
    required: true,
  };

  return (
    <>
      <div className="space-y-4">
        <h2 className="text-sm font-bold text-superBlack">Bank Details</h2>
        <p className="text-xs text-deepGrey">
          Simply follow the prompts to verify your business bank account, if you
          are unable to complete the verification using Plaid, select “Use
          Alternative Verification”.
        </p>
        {plaidConntected && <PlaidAlertContent type={plaidStatus} />}
        <Grid container gap={2} justifyContent="flex-end" className="pr-4">
          <Grid item>
            <Button
              className="py-4 space-x-2 min-w-[300px]"
              color="secondary"
              type="button"
              onClick={() => setIsModalOpen(true)}
              disabled={!plaidLinkToken.linkToken}
            >
              {plaidConntected ? "Re-verify using Plaid" : "Verify using Plaid"}
            </Button>
          </Grid>
          <Grid item>
            <Button
              className="py-4 space-x-2 min-w-[300px]"
              variant="outlined"
              color="secondary"
              type="button"
              onClick={() => {
                setUsedAlternativeStatus(
                  PlaidStatusTemp.USED_ALTERNATIVE_VERIFICATION
                );
                resetBankAccount();
              }}
            >
              Use Alternative Verification
            </Button>
          </Grid>
        </Grid>
        {!(
          plaidStatus === OnboardingPlaidStatus.NaN && !isExistBankAccount
        ) && (
          <>
            <Grid container zero={12} spacing={2}>
              <Grid item zero={12} lg={3}>
                <TextField
                  {...fieldProps}
                  placeholder="Bank Name"
                  label="Bank Name"
                  {...register("bankAccount.bankName", {
                    required: setMessage("Bank name is required"),
                  })}
                  error={errors.bankAccount?.bankName?.message}
                />
              </Grid>
              <Grid item zero={12} lg={3}>
                <TextField
                  {...fieldProps}
                  placeholder="Account Name"
                  label="Account Name"
                  {...register("bankAccount.bankAccountName", {
                    required: setMessage("Account name is required"),
                  })}
                  error={errors.bankAccount?.bankAccountName?.message}
                />
              </Grid>
              <Grid item zero={12} lg={3}>
                <Controller
                  name="bankAccount.bankAccountNumber"
                  control={control}
                  rules={{
                    required: setMessage("Account number is required"),
                  }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      {...fieldProps}
                      label="Account Number"
                      placeholder="Account Number"
                      onChange={(e) =>
                        field.onChange(e.target.value.replaceAll(/[^0-9]/g, ""))
                      }
                      inputProps={{
                        maxLength: 16,
                      }}
                      error={fieldState?.error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid item zero={12} lg={3}>
                <Controller
                  name="bankAccount.bankRoutingNumber"
                  control={control}
                  rules={{
                    required: setMessage("Bank routing number is required"),
                    validate: {
                      minLength: (value) =>
                        isRequired()
                          ? value.length === 9 ||
                            setMessage("Bank routing number is invalid")
                          : true,
                    },
                  }}
                  render={({ field, fieldState }) => {
                    return (
                      <TextField
                        {...field}
                        {...fieldProps}
                        label="Routing Number"
                        placeholder="Routing Number"
                        onChange={(e) =>
                          field.onChange(
                            e.target.value.replaceAll(/[^0-9]/g, "")
                          )
                        }
                        inputProps={{
                          maxLength: 9,
                        }}
                        error={fieldState?.error?.message}
                      />
                    );
                  }}
                />
              </Grid>
            </Grid>
            {(isRequired() || proofOfBankAccountUrl) && (
              <Controller
                control={control}
                name="bankAccount.proofOfBankAccount"
                rules={{
                  required: true,
                  validate: (value) =>
                    !!value.url ||
                    setMessage("Proof Of Bank Account is required"),
                }}
                render={({ field, fieldState }) => (
                  <UploadProofOfBankAccount
                    field={field}
                    error={fieldState.error?.message}
                  />
                )}
              />
            )}
          </>
        )}
      </div>
      <BasicModal
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        className="w-full max-w-[450px]"
      >
        <div className="text-sm text-center text-darkest">
          In order to connect to a new bank account, we’ll have to disconnect
          from your current bank account. Would you like to proceed?
        </div>
        <div className="flex items-center justify-center gap-4 mt-6">
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setIsModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => plaidConnectOpen()}
          >
            Proceed
          </Button>
        </div>
      </BasicModal>
      <LoadingComponent isLoading={loading} />
    </>
  );
};

export default PlaidConnectionComp;
