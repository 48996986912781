import axios from "axios";
import { setErrorMessage } from "states/snackbarMessage/snackbarMessageSlice";
import { store } from "states/store";

const uploadFileApi = axios.create({
  timeout: 30000,
});

uploadFileApi.interceptors.response.use((config) => {
  return config;
});

uploadFileApi.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    store.dispatch(setErrorMessage("Can not upload file!"));
    return Promise.reject(error.response?.data?.error);
  }
);

export default uploadFileApi;
