export const formatCurrency = (number: number, maximumFractionDigits: number = 2) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits,
  })
    .format(number)
    .replace(/\D00$/, '');
};

export const formatNumber = (number: number, options?: Intl.NumberFormatOptions) => {
  return new Intl.NumberFormat(
    'en-US',
    options
      ? options
      : {
          maximumFractionDigits: 2,
        }
  ).format(number);
};

export const formatNumberQty = (number: number, options?: Intl.NumberFormatOptions) => {
  return new Intl.NumberFormat(
    'en-US',
    options
      ? options
      : {
          maximumFractionDigits: 3,
          minimumFractionDigits: 3,
        }
  ).format(number);
};

export const formatPercent = (number: number, options?: Intl.NumberFormatOptions) => {
  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: 2,
    ...options,
  }).format(number);
};

export const formatPercentAllocation = (number: number, options?: Intl.NumberFormatOptions) => {
  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    ...options,
  }).format(number);
};

export const abbreviateNumber = (num: number) => {
  const SI_SYMBOL = ['', 'k', 'M', 'G', 'T', 'P', 'E'];
  const tier = Math.floor(Math.log10(num) / 3) || 0;
  let result = '' + num;
  if (tier > 0) {
    const suffix = SI_SYMBOL[tier];
    const scale = Math.pow(10, tier * 3);
    const scaled = num / scale;
    result = scaled.toFixed(1).replace('.0', '') + suffix;
  }
  return result;
};
