import { CloseOutlined } from "@mui/icons-material";
import { CircularProgress, IconButton } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import Modal, { ModalProps as IModalProps } from "@mui/material/Modal";
import { useEffect, useRef } from "react";

export type ModalProps = {
  isOpen: boolean;
  handleClose?: () => void;
  modalProps?: IModalProps | any;
  loading?: boolean;
} & BoxProps;

const modalStyle = {
  position: "relative" as "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: {
    zero: 300,
    xs: 450,
  },
  width: "100%",
  minHeight: 150,
  height: "auto",
  backgroundColor: "white",
  fontFamily: `'Poppins', sans-serif`,
  padding: "40px 24px",
  outline: "none",
  borderRadius: 2,
  overflowY: "hidden",
  maxHeight: "100%",
};

let debounce: undefined | NodeJS.Timeout;

const BasicModal = ({
  isOpen,
  handleClose,
  children,
  modalProps,
  loading = false,
  ...props
}: ModalProps) => {
  const outterRef: any = useRef(null);
  const innerRef: any = useRef(null);
  useEffect(() => {
    const onResize = () => {
      clearTimeout(debounce);
      debounce = setTimeout(function () {
        if (outterRef.current && innerRef.current) {
          const padding = window
            .getComputedStyle(outterRef.current)
            .getPropertyValue("padding-top");
          const paddingValue = Number(padding ? padding.replace("px", "") : 0);
          if (paddingValue && paddingValue > 0) {
            const maxHeight = outterRef.current.clientHeight - paddingValue * 2;
            if (maxHeight >= 180) {
              innerRef.current.style.maxHeight = `${maxHeight + 10}px`;
              innerRef.current.style["padding-bottom"] = "10px";
              innerRef.current.style["overflow-y"] = "auto";
            } else {
              innerRef.current.style["overflow-y"] = "unset";
              innerRef.current.style.maxHeight = "unset";
            }
          }
        }
      }, 350);
    };
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);
  return (
    <Modal
      open={isOpen}
      sx={{
        mx: 2,
        zIndex: 500,
      }}
      {...modalProps}
    >
      <Box sx={modalStyle} {...props} ref={outterRef}>
        {handleClose && (
          <IconButton
            className="rounded-none rounded-tr text-darkGreen top-0 right-0 absolute p-3"
            disableRipple
            size="small"
            onClick={handleClose}
          >
            <CloseOutlined />
          </IconButton>
        )}
        <div className="relative" ref={innerRef}>
          {children}
          {loading && (
            <div className="w-full h-full absolute top-0 left-0 bg-[rgba(0, 0, 0)] opacity-[0.5] z-[1000] flex items-center justify-center">
              <CircularProgress color="secondary" />
            </div>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default BasicModal;
