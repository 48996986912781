import { Grid, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import React, { useState } from 'react';
import { fileUrlToBase64, readFileToBase64 } from 'core/functions';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModalUpload from 'components/employerManagePlan/UploadModal';
import { MAX_FILE_SIZE, MIN_FILE_SIZE, PDF_FILE_ACCEPTED_TYPES } from 'core/constants/file-data';
import { FileTypeEnum } from 'components/employerManagePlan/UploadPlan';
import { ControllerRenderProps } from 'react-hook-form';
import { FormValueType } from 'pages/employerBankPayroll';

type Props = {
  field: ControllerRenderProps<FormValueType, 'bankAccount.proofOfBankAccount'>;
  error?: string;
};

const UploadProofOfBankAccount = ({ field, error }: Props) => {
  const [isOpenModalUpload, setOpenModalUpload] = useState<boolean>(false);

  const onFileChange = async (file: File) => {
    const fileBase64String = await readFileToBase64(file);
    const fileData = {
      name: file.name,
      size: file.size,
      type: file.type,
      url: fileBase64String as string,
      updated: true,
      file,
    };
    field.onChange(fileData);
  };

  return (
    <div className="mt-8">
      <Typography className="text-primary text-sm font-bold mt-8">
        Please upload one of the following documents: A bank statement, a letter from your bank or a voided check.
      </Typography>
      <Grid
        container
        gap={2}
        alignItems="center"
        className="text-textLink hover:text-textLinkHover hover:cursor-pointer text-sm font-semibold w-auto mt-4 ml-4"
      >
        {!field.value.url && (
          <Grid
            item
            onClick={() => {
              setOpenModalUpload(true);
            }}
            className="flex items-center gap-2"
          >
            <div>
              <CloudUploadIcon />
            </div>
            <div>Proof of Bank Account *</div>
          </Grid>
        )}
        <Grid item>
          {field.value.url && (
            <span
              onClick={async () => {
                let base64String = '';
                if (field.value.updated) {
                  base64String = field.value.url;
                } else {
                  base64String = (await fileUrlToBase64(field.value.url)) as string;
                }
                const newTab = window.open();
                if (newTab) {
                  newTab.document.title = 'Proof of Bank Account';
                  newTab.document.body.innerHTML = `<iframe title="pdfDoc" src="${base64String}" width="100%" height="100%"></iframe>`;
                }
              }}
              className="text-textLink hover:text-textLinkHover hover:cursor-pointer inline-block font-semibold text-sm"
            >
              <Grid item className="flex items-center gap-2">
                <div>
                  <CloudUploadIcon />
                </div>
                <div>Proof of Bank Account *</div>
                <VisibilityIcon className="mr-2" />
              </Grid>
            </span>
          )}
        </Grid>
      </Grid>
      <ModalUpload
        minFileSize={MIN_FILE_SIZE}
        maxFileSize={MAX_FILE_SIZE}
        acceptFiles={PDF_FILE_ACCEPTED_TYPES}
        isOpen={isOpenModalUpload}
        title="Upload Proof of Bank Account"
        onCancel={() => {
          setOpenModalUpload(false);
        }}
        onSuccess={onFileChange}
        fileType={FileTypeEnum.EMPLOYER_PROOF_BANK_ACCOUNT}
      />
      {error && <span className="ml-4 text-errorColor text-xs">{error}</span>}
    </div>
  );
};

export default UploadProofOfBankAccount;
