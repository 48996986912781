import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { z } from 'zod';
import { Box, DialogActions, Typography } from '@mui/material';
import { Button, ModalUploadFiles, DialogContainer } from 'common/components';
import { useDialog } from 'hooks/useDialog';
import BeneficiaryList from './BeneficiaryList';
import { useAppDispatch, useAppSelector } from 'states/hooks';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';
import { setErrorMessage, setSuccessMessage, setWarningMessage } from 'states/snackbarMessage/snackbarMessageSlice';
import { getEmployeeProfileAsync, updateEmployeeProfileAsync } from 'states/manageEmployee/manageEmployeeSlice';
import { FormInstitution } from 'core/models/EmployeeEditBeneficiary.model';
import { RequestStatus } from 'core/enums/request-status.enum';
import { zodResolver } from '@hookform/resolvers/zod';
import { MAX_FILE_SIZE, MIN_FILE_SIZE, PDF_FILE_ACCEPTED_TYPES } from 'core/constants/file-data';
import { FileTypeEnum } from 'components/employerManagePlan/UploadPlan';
import { getCookie } from 'core/services/cookie.service';
import { CookieNames } from 'core/enums/cookie-names.enum';
import { IUploadPlanDTO } from 'core/models/UploadPlanRequest.model';
import { getEmployeeImg } from 'core/services/employee.service';
import { REGEX_PATTERN } from 'core/constants';

const addressSchema = z.object({
  street: z.string().optional(),
  line2: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  zip: z.string().optional(),
});
const validateDate = (value: any, ctx: any) => {
  if (value) {
    const minDate = moment().set('date', 1).set('month', 0).set('year', 1900);
    if (moment(value).isAfter(moment()) || moment(value).isBefore(minDate)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Invalid Date',
      });
    }
  }
};

const beneficiarySchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  trustName: z.string().optional(),
  trustDate: z.custom().superRefine(validateDate),
  percentage: z.preprocess((a) => {
    return parseInt(`${a}`.replace('%', '') || '0', 10);
  }, z.number().max(100)), // Must be a positive number
  dob: z.custom().superRefine(validateDate),
  ssn: z.string(),
  relationship: z.string(),
  address: addressSchema,
});

const schema = z.object({
  id: z.string(),
  spousalConsent: z.any().optional(),
  primaryBeneficiary: z.array(beneficiarySchema),
  secondaryBeneficiary: z.array(beneficiarySchema),
});

export type TEmployeeEditBeneficiaryV2Form = z.infer<typeof schema>;

type Dictionary<Key extends keyof any, Value> = {
  [key in Key]: Value; // Mapped types syntax
};
const replaceNullToEmptyString = (obj: Dictionary<string, any>) => {
  for (const [key, value] of Object.entries(obj)) {
    if (value === null) {
      obj[key] = '';
    }
  }
};
const parseSsn = (encoded: string) => {
  try {
    if (!encoded) {
      return '';
    }
    const rawParse = window.atob(encoded);
    if (REGEX_PATTERN.SSN.pattern.test(rawParse)) {
      return rawParse;
    }
    return '';
  } catch (error) {
    return '';
  }
};
const defaultBeneficiary: any = {
  firstName: '',
  lastName: '',
  trustName: '',
  trustDate: '',
  percentage: 0,
  ssn: '',
  dob: '',
  relationship: '',
  address: {
    street: '',
    line2: '',
    city: '',
    state: '',
    zip: '',
  },
};
const DEFAULT_VALUES: any = {
  id: '',
  spousalConsent: '',
  primaryBeneficiary: [defaultBeneficiary],
  secondaryBeneficiary: [],
};
const EmployeeEditBeneficiaryV2: FC = () => {
  const { openDialog } = useDialog();
  const dispatch = useAppDispatch();
  const form = useForm<TEmployeeEditBeneficiaryV2Form>({
    defaultValues: DEFAULT_VALUES,
    resolver: zodResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const {
    control,
    formState: { isDirty },
    setError,
    watch,
    reset,
    handleSubmit,
    setValue,
  } = form;
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      isDirty && currentLocation.pathname !== nextLocation.pathname && nextLocation.pathname !== 'dashboard/selectRole'
  );

  const secondaryFieldArray = useFieldArray({
    control,
    name: 'secondaryBeneficiary',
  });
  const openConfirmPromps = (save: () => Promise<void> | void, closeConfirm: () => Promise<void> | void) => {
    openDialog((close) => (
      <DialogContainer close={close}>
        <div className="text-2xl text-center text-superBlack font-bold mb-3">Would you like to save your changes?</div>
        <div className="text-sm text-center text-darkest">
          We wouldn't want you to lose any of the valuable information you've just entered, please let us know how you’d
          like to proceed.
        </div>
        <DialogActions className="flex items-center justify-center gap-2 mt-6">
          <Button
            variant="outlined"
            color="secondary"
            className="min-w-[170px] whitespace-nowrap"
            onClick={() => {
              close();
              closeConfirm();
            }}
          >
            Proceed without Saving
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={async () => {
              await save();
              close();
            }}
            className="min-w-[170px] whitespace-nowrap"
          >
            Save
          </Button>
        </DialogActions>
      </DialogContainer>
    ));
  };

  const openGuideLine = () => {
    openDialog((close) => (
      <DialogContainer close={close}>
        <div className="text-2xl text-center text-superBlack font-bold">Guidelines for Assigning Beneficiaries</div>
        <br />
        <div className="text-sm text-left text-darkest font-bold mb-2">Primary and Contingent Beneficiaries</div>
        <div className="text-xs text-left text-darkest mb-2">
          Account holders can designate primary beneficiaries who are first in line to receive assets, and contingent
          beneficiaries who will receive assets if no primary beneficiaries are available at the time of the account
          holder's death.
        </div>
        <div className="text-sm text-left text-darkest font-bold mb-2">Spousal Rights</div>
        <div className="text-xs text-left text-darkest mb-2">
          In many cases, the spouse of the account holder is automatically considered the primary beneficiary for a
          401(k) plan, unless the spouse has provided written consent for another beneficiary to be designated. Saveday
          will not be responsible enforcing this, it is your responsibility to ensure that you are following IRS
          regulations.
        </div>
        <div className="text-sm text-left text-darkest font-bold mb-2">Multiple Beneficiaries</div>
        <div className="text-xs text-left text-darkest mb-2">
          Account holders can name multiple beneficiaries and specify the percentage of assets each beneficiary will
          receive, allowing for flexibility in estate planning.
        </div>
        <div className="text-sm text-left text-darkest font-bold mb-2">Updates and Changes</div>
        <div className="text-xs text-left text-darkest mb-2">
          Beneficiary designations should be reviewed and updated regularly, especially after major life events such as
          marriage, divorce, or the birth of a child, to ensure they reflect the account holder's current wishes.
        </div>
        <div className="text-sm text-left text-darkest font-bold mb-2">
          For additional information, please visit the IRS website by clicking on this{' '}
          <a
            href="https://www.irs.gov/"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'underline', cursor: 'pointer' }}
            className="text-textLink"
          >
            link
          </a>
          {/* */}.
        </div>
      </DialogContainer>
    ));
  };

  const calculatorPrimaryShare = () => {
    const val = watch();
    const primaries = val.primaryBeneficiary;
    let total = 0;
    for (const item of primaries) {
      if (typeof item.percentage === 'string') {
        item.percentage = parseInt((item.percentage as string).replace('%', '') || '0');
      }
      total += item.percentage;
    }
    return total;
  };

  const calculatorSecondaryShare = () => {
    const val = watch();
    const secondaries = val.secondaryBeneficiary;
    let total = 0;
    for (const item of secondaries) {
      if (typeof item.percentage === 'string') {
        item.percentage = parseInt((item.percentage as string).replace('%', '') || '0');
      }
      total += item.percentage;
    }
    return total;
  };

  const userProfile = useAppSelector((state) => state.manageEmployee.employeeProfile);
  const personId = getCookie(CookieNames.PersonId);
  const [shareError, setShareError] = useState<string>('');
  const [isOpenModalUpload, setOpenModalUpload] = useState(false);

  const getFile = async (fileId: string) => {
    const data = await getEmployeeImg(fileId);
    setValue(
      'spousalConsent',
      {
        name: data.name,
        size: data.size,
        type: data.fileType,
        url: data.url,
        updated: false,
        file: null,
        id: data.id,
      },
      { shouldValidate: true, shouldDirty: true }
    );
  };

  const nonSpouseWarning = (items: any[], type: 'primaryBeneficiary' | 'secondaryBeneficiary'): void => {
    for (let index = 0; index < items.length; index++) {
      const element = items[index];
      if (element.relationship === 'NON_SPOUSE') {
        setError(`${type}.${index}.relationship`, {
          message: `"Non Spouse" is no longer supported.`,
        });
      }
    }
  };

  const resetForm = () => {
    const primaryList: any = (userProfile?.primaryBeneficiary || [defaultBeneficiary]).map((item: any) => {
      let address = item.address;
      if (address) {
        address = JSON.parse(JSON.stringify(item.address));
        replaceNullToEmptyString(address);
      }
      return {
        ...item,
        ssn: parseSsn(item.ssn),
        dob: item?.dob ? moment(item?.dob) : '',
        trustDate: item?.trustDate ? moment(item?.trustDate) : '',
        address,
      };
    });

    const secondaryList: any = (userProfile?.secondaryBeneficiary || []).map((item: any) => {
      let address = item.address;
      if (address) {
        address = JSON.parse(JSON.stringify(item.address));
        replaceNullToEmptyString(address);
      }
      return {
        ...item,
        ssn: parseSsn(item.ssn),
        dob: item?.dob ? moment(item?.dob) : '',
        trustDate: item?.trustDate ? moment(item?.trustDate) : '',
        address,
      };
    });
    const values = {
      id: userProfile?.id,
      spousalConsent: {
        name: '',
        size: 0,
        type: '',
        url: userProfile?.spousalConsentLink ?? '',
        updated: false,
        file: null,
        id: userProfile?.spousalConsent ?? '',
      },
      primaryBeneficiary: primaryList.length ? primaryList : [defaultBeneficiary],
      secondaryBeneficiary: secondaryList,
    };
    reset(values);
    nonSpouseWarning(values.primaryBeneficiary, 'primaryBeneficiary');
    nonSpouseWarning(values.secondaryBeneficiary, 'secondaryBeneficiary');
  };
  const validateBeneficiaries = (items: any[], type: 'primaryBeneficiary' | 'secondaryBeneficiary') => {
    let hasError = false;
    for (let index = 0; index < items.length; index++) {
      const item = items[index];
      const relationship = item.relationship;
      if (relationship === 'TRUST') {
        if (!item.trustName) {
          hasError = true;
          setError(`${type}.${index}.trustName`, {
            message: 'Trust Name is required',
          });
        }
        if (!item.trustDate) {
          hasError = true;
          setError(`${type}.${index}.trustDate`, {
            message: 'Trust Date is required',
          });
        }
        if (!item.lastName) {
          hasError = true;
          setError(`${type}.${index}.lastName`, {
            message: 'Contact Person Last Name is required',
          });
        }
        if (!item.firstName) {
          hasError = true;
          setError(`${type}.${index}.firstName`, {
            message: 'Contact Person First Name is required',
          });
        }
      } else {
        if (!item.relationship) {
          hasError = true;
          setError(`${type}.${index}.relationship`, {
            message: 'Relationship is required',
          });
        }
        if (item.relationship === 'NON_SPOUSE') {
          hasError = true;
          setError(`${type}.${index}.relationship`, {
            message: `"Non Spouse" is no longer supported.`,
          });
        }

        if (!item.ssn) {
          hasError = true;
          setError(`${type}.${index}.ssn`, {
            message: 'SSN is required',
          });
        }
        if (!item.lastName) {
          hasError = true;
          setError(`${type}.${index}.lastName`, {
            message: 'Last Name is required',
          });
        }
        if (!item.firstName) {
          hasError = true;
          setError(`${type}.${index}.firstName`, {
            message: 'First Name is required',
          });
        }
      }
    }
    return hasError;
  };
  const handleUpdateData = async (values: TEmployeeEditBeneficiaryV2Form) => {
    if (!isDirty) {
      dispatch(setWarningMessage('Please edit the fields'));
      return false;
    }
    setShareError('');

    const primaryBeneficiary = values.primaryBeneficiary;
    const secondaryBeneficiary = values.secondaryBeneficiary;
    let hasError = false;

    hasError = validateBeneficiaries(primaryBeneficiary, 'primaryBeneficiary');
    if (hasError) {
      return false;
    }

    hasError = validateBeneficiaries(secondaryBeneficiary, 'secondaryBeneficiary');
    if (hasError) {
      return false;
    }

    const primary = calculatorPrimaryShare();
    if (primaryBeneficiary.length > 0 && primary !== 100) {
      for (let index = 0; index < primaryBeneficiary.length; index++) {
        setError(`primaryBeneficiary.${index}.percentage`, {
          type: 'manual',
          message: ' ',
        });
      }
      setShareError('primary');
      return false;
    }

    const secondary = calculatorSecondaryShare();
    if (secondaryBeneficiary.length > 0 && secondary !== 100) {
      for (let index = 0; index < secondaryBeneficiary.length; index++) {
        setError(`secondaryBeneficiary.${index}.percentage`, {
          type: 'manual',
          message: ' ',
        });
      }
      setShareError('secondary');
      return false;
    }

    const response = await dispatch(
      updateEmployeeProfileAsync({
        id: userProfile?.id,
        spousalConsent: values.spousalConsent.id,
        primaryBeneficiary: [
          ...primaryBeneficiary.map((item) => {
            if (item.relationship === 'TRUST') {
              return {
                firstName: item.firstName,
                lastName: item.lastName,
                trustName: item.trustName,
                trustDate: item.trustDate,
                percentage: item.percentage,
                ssn: '',
                relationship: item.relationship,
                dob: item.dob,
                address: item.address,
              };
            } else {
              return {
                firstName: item.firstName,
                lastName: item.lastName,
                trustName: '',
                trustDate: '',
                percentage: item.percentage,
                ssn: window.btoa(item.ssn),
                relationship: item.relationship,
                dob: item.dob,
                address: item.address,
              };
            }
          }),
        ] as FormInstitution[],
        secondaryBeneficiary: [
          ...secondaryBeneficiary.map((item) => {
            if (item.relationship === 'TRUST') {
              return {
                firstName: item.firstName,
                lastName: item.lastName,
                trustName: item.trustName,
                trustDate: item.trustDate,
                percentage: item.percentage,
                ssn: '',
                relationship: item.relationship,
                dob: item.dob,
                address: item.address,
              };
            } else {
              return {
                firstName: item.firstName,
                lastName: item.lastName,
                trustName: '',
                trustDate: '',
                percentage: item.percentage,
                ssn: window.btoa(item.ssn),
                relationship: item.relationship,
                dob: item.dob,
                address: item.address,
              };
            }
          }),
        ] as FormInstitution[],
      })
    );

    if (response.meta.requestStatus === RequestStatus.FULFILLED) {
      dispatch(setSuccessMessage('Update beneficiaries successfully.'));
      await dispatch(getEmployeeProfileAsync());
    }
    return true;
  };
  useEffect(() => {
    if (userProfile) {
      resetForm();
    }
  }, [userProfile]);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      if (!isDirty) {
        blocker.proceed();
        return;
      }
      openConfirmPromps(
        async () => {
          //save
          const response = await handleUpdateData(watch());
          if (response) {
            blocker.proceed();
          } else {
            dispatch(
              setErrorMessage('It Looks Like Something Has Gone Wrong. Please Check the Error below before Proceeding')
            );
            blocker.reset();
          }
        },
        () => {
          //discard
          blocker.proceed();
        }
      );
    }
  }, [blocker]);

  return (
    <>
      <form onSubmit={handleSubmit(handleUpdateData)}>
        <Box sx={{ background: 'white', mt: 2 }}>
          <Typography className="text-sm font-medium text-deepGrey my-5">
            Beneficiaries in a 401(k) plan are individuals designated by the account holder to receive the assets in the
            event of the account holder's death. Learn more about the{' '}
            <a
              className="text-sm font-medium text-secondary"
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={openGuideLine}
            >
              guidelines for appointing beneficiaries here
            </a>
            .
          </Typography>
          <BeneficiaryList
            form={form}
            isShareError={shareError === 'primary'}
            name="primaryBeneficiary"
            type="primary"
            title="Primary Beneficiaries"
            setOpenModalUpload={setOpenModalUpload}
          />
          <Box sx={{ mt: 3 }}>
            {watch().secondaryBeneficiary.length > 0 ? (
              <BeneficiaryList
                form={form}
                isShareError={shareError === 'secondary'}
                name="secondaryBeneficiary"
                type="contingent"
                title="Contingent Beneficiaries"
                setOpenModalUpload={setOpenModalUpload}
              />
            ) : (
              <Button
                onClick={() => {
                  secondaryFieldArray.append(defaultBeneficiary);
                }}
              >
                Add Contingent Beneficiaries
              </Button>
            )}
          </Box>
          <div className="flex items-center justify-end gap-4 mt-6">
            <Button
              variant="outlined"
              color="secondary"
              sx={{
                width: '120px',
              }}
              onClick={() => {
                if (isDirty) {
                  resetForm();
                }
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              sx={{
                width: '120px',
              }}
            >
              Save
            </Button>
          </div>
        </Box>
      </form>
      <ModalUploadFiles
        isOpen={isOpenModalUpload}
        onCancel={() => setOpenModalUpload(false)}
        minFileSize={MIN_FILE_SIZE}
        maxFileSize={MAX_FILE_SIZE}
        acceptFiles={PDF_FILE_ACCEPTED_TYPES}
        title="Upload Spousal Consent"
        fileType={FileTypeEnum.SPOUSAL_CONSENT}
        personId={personId}
        onSuccess={async (data: IUploadPlanDTO) => {
          if (data.id) {
            await getFile(data.id);
          }
          setOpenModalUpload(false);
        }}
      />
    </>
  );
};
export default EmployeeEditBeneficiaryV2;
