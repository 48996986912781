import Box from '@mui/material/Box';
import { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { CompanyAdminResponse } from 'core/models/companyAdminDto.model';
import { useAppDispatch } from 'states/hooks';
import { setSelectedCompanyAdminId } from 'states/manageCompanyAdmin/manageCompanyAdminSlice';
import { Table } from 'common/components';

interface IProps {
  data: CompanyAdminResponse[];
}

interface ICompanyAdminRow {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  companyNames: string;
}

const SelectedCompanyAdminTable = ({ data }: IProps) => {
  const dispatch = useAppDispatch();

  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'First Name',
      align: 'left',
      headerAlign: 'left',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      align: 'left',
      headerAlign: 'left',
      flex: 1,
      minWidth: 100,
    },

    {
      field: 'email',
      headerName: 'Email',
      width: 400,
    },
    {
      field: 'phone',
      headerName: 'Phone Number',
      width: 180,
    },
    {
      field: 'companyNames',
      headerName: 'Employer',
      width: 360,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return !!params.value.length ? (
          <div className="text-center whitespace-nowrap">
            {params.value.map((item: any) => (
              <div key={item} className="MuiDataGrid-cellContent" title={item}>
                {item}
              </div>
            ))}
          </div>
        ) : (
          <></>
        );
      },
    },
  ];

  const mappingDataForTableRows = (data: CompanyAdminResponse[]): ICompanyAdminRow[] | [] => {
    if (!!data.length) {
      return data.map((item: any) => ({
        id: item.id || '',
        firstName: item.firstName || '',
        lastName: item.lastName || '',
        email: item.email || '',
        phone: item.phone || '',
        companyNames: item.companyNames || '',
      }));
    }
    return [];
  };

  return (
    <Box sx={{ marginTop: 2 }}>
      <Table
        sx={{
          '& .MuiDataGrid-withBorderColor': {
            border: 'none',
          },
        }}
        rows={mappingDataForTableRows(data)}
        columns={columns}
        disableRowSelectionOnClick={false}
        onRowSelectionModelChange={(rowSelectionModel) => dispatch(setSelectedCompanyAdminId(rowSelectionModel[0]))}
        disableColumnMenu
        getRowSpacing={() => ({
          bottom: 10,
        })}
      />
    </Box>
  );
};

export default SelectedCompanyAdminTable;
