import { IRadioOption } from 'common/components';
import { PlanTypes } from 'core/enums/plan-type.enum';
import { Option } from 'core/models/Option.model';
import { PlanFormData } from 'core/models/PlanDetailDto.model';
import { capitalize, capitalizeConst } from 'utils/text';

export const EXIST_DARFT_PLAN_MESSAGE = 'There is already DRAFT plan.';

export const YES_VALUE = 'Yes';
export const NO_VALUE = 'No';

export enum PlanStatus {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  TERMINATED = 'TERMINATED',
  OBSOLETE = 'OBSOLETE',
}

export enum CompensationType {
  dollar = 'DOLLAR_AMOUNT',
  percentage = 'PERCENTAGE_COMPENSATION',
}

export const minAgeOptions: Option[] = [
  {
    label: '18',
    value: 18,
  },
  {
    label: '21',
    value: 21,
  },
];

export const yesNoOptions: Option[] = [
  {
    label: YES_VALUE,
    value: true,
  },
  {
    label: NO_VALUE,
    value: false,
  },
];

export const eligibilityStartOptions: Option[] = [
  {
    label: 'Immediately upon hire',
    value: 'IMMEDIATELY_UPON_HIRE',
  },
  {
    label: 'One month after hire',
    value: 'ONE_MONTH_AFTER_HIRE',
  },
  {
    label: 'Two months after hire',
    value: 'TWO_MONTHS_AFTER_HIRE',
  },
  {
    label: 'Three Months After Hire',
    value: 'THREE_MONTHS_AFTER_HIRE',
  },
  {
    label: 'Four Months After Hire',
    value: 'FOUR_MONTHS_AFTER_HIRE',
  },
  {
    label: 'Five Months After Hire',
    value: 'FIVE_MONTHS_AFTER_HIRE',
  },
  {
    label: 'Six Months After Hire',
    value: 'SIX_MONTHS_AFTER_HIRE',
  },
  {
    label: 'Seven Months After Hire',
    value: 'SEVEN_MONTHS_AFTER_HIRE',
  },
  {
    label: 'Eight Months After Hire',
    value: 'EIGHT_MONTHS_AFTER_HIRE',
  },
  {
    label: 'Nine Months After Hire',
    value: 'NINE_MONTHS_AFTER_HIRE',
  },
  {
    label: 'Ten Months After Hire',
    value: 'TEN_MONTHS_AFTER_HIRE',
  },
  {
    label: 'Eleven Months After Hire',
    value: 'ELEVEN_MONTHS_AFTER_HIRE',
  },
  {
    label: 'One Year After Hire',
    value: 'ONE_YEAR_AFTER_HIRE',
  },
];

export const percent1To25Options: Option[] = Array.from(Array(25).keys()).map((key) => ({
  label: `${key + 1}%`,
  value: key + 1,
}));

export const percent1To100Options: Option[] = Array.from(Array(100).keys()).map((key) => ({
  label: `${key + 1}%`,
  value: key + 1,
}));

export const percent0To5Options: Option[] = Array.from(Array(6).keys()).map((key) => ({
  label: `${key}%`,
  value: key,
}));

export const number0To6Options: Option[] = Array.from(Array(7).keys()).map((key) => ({
  label: key.toString(),
  value: key,
}));

export const number0To10Options: Option[] = Array.from(Array(11).keys()).map((key) => ({
  label: key.toString(),
  value: key,
}));

export const percent1To12Options: Option[] = Array.from(Array(12).keys()).map((key) => ({
  label: `${key + 1}%`,
  value: key + 1,
}));

export const percent5075ptions: Option[] = [
  {
    label: '50%',
    value: 50,
  },
  {
    label: '75%',
    value: 75,
  },
];

export const dollarOrPercentageOptions: Option[] = [
  {
    label: 'dollar amount',
    value: CompensationType.dollar,
  },
  {
    label: 'percentage compensation',
    value: CompensationType.percentage,
  },
];

export const safeHarborContribution = {
  ONE_HUNDRED: 'ONE_HUNDRED',
  THREE_PERCENT: 'THREE_PERCENT',
  CHOOSE_TO_WAIT: 'CHOOSE_TO_WAIT',
};

export const matchingContribution = {
  DISCRETIONARY_MATCH: 'DISCRETIONARY_MATCH',
  FIXED_MATCH: 'FIXED_MATCH',
  OTHER_MATCH: 'OTHER_MATCH',
  MATCHING_BASED_SERVICE: 'MATCHING_BASED_SERVICE',
};

export const profitSharing = {
  PRO_RATA_FREQUENCY: 'PRO_RATA_FREQUENCY',
  SOCIAL_SECURITY_INTEGRATION: 'SOCIAL_SECURITY_INTEGRATION',
  AGE_WAIGHTED_PERCENTAGE: 'AGE_WAIGHTED_PERCENTAGE',
};

export const proRataFrequency = {
  ANNUALLY: 'ANNUALLY',
  QUARTERLY: 'QUARTERLY',
  MONTHLY: 'MONTHLY',
  PAYROLL: 'PAYROLL',
};

export const PLAN_STATUS: IRadioOption[] = [
  {
    label: capitalizeConst(PlanStatus.DRAFT),
    value: PlanStatus.DRAFT,
  },
  {
    label: capitalizeConst(PlanStatus.ACTIVE),
    value: PlanStatus.ACTIVE,
  },
  {
    label: capitalizeConst(PlanStatus.OBSOLETE),
    value: PlanStatus.OBSOLETE,
  },
  {
    label: capitalizeConst(PlanStatus.TERMINATED),
    value: PlanStatus.TERMINATED,
  },
];

export const playTypeOptions = [
  {
    label: capitalize(PlanTypes.BOTH),
    value: PlanTypes.BOTH,
  },
  {
    label: capitalize(PlanTypes.TRADITIONAL),
    value: PlanTypes.TRADITIONAL,
  },
  {
    label: capitalize(PlanTypes.ROTH),
    value: PlanTypes.ROTH,
  },
];
export const createPlanInitialState: PlanFormData = {
  minAge: undefined,
  offerLoan: undefined,
  allowRollover: undefined,
  eligibilityStart: undefined,
  waiverDate: undefined,
  allowWithdrawal: undefined,
  planType: undefined,
  autoEnrollment: undefined,
  autoEnrollmentAt: undefined,
  autoEnrollmentIncrementPerYear: undefined,
  isSafeHarbor: undefined,
  safeHarborContribution: undefined,
  safeHarborOneHundredDeferralUpto: undefined,
  excludeHCE: undefined,
  employmentTypesToExclude: undefined,
  isMatching: undefined,
  matchingContribution: undefined,
  fixedMatchPercOfDeferrals: undefined,
  numberOfTierMatching: undefined,
  isMatchContribLimitPerEmployee: undefined,
  matchContribLimitPerEmployee: undefined,
  isPlanYearContribLimit: undefined,
  planYearContribLimit: undefined,
  numberOfTierMatchingBasedService: undefined,
  isProfitSharing: undefined,
  profitSharing: undefined,
  proRataFrequency: undefined,
  ssiPercOfWage: undefined,
  ageWeightedPerc: undefined,
  isEmployedForProfitSharing: undefined,
  isMinHrsForProfitSharing: undefined,
  minHrsForProfitSharing: undefined,
};

export const basicPlanKey = {
  overview: 'basic.overview',
  bankVerification: 'basic.bankVerification',
  planSponsor: 'basic.planSponsor',
  companyContact: 'basic.companyContact',
  companyDetail: 'basic.companyDetail',
  complianceDisclosures: 'basic.complianceDisclosures',
  multipleBusinesses: 'basic.multipleBusinesses',
  planTransfer: 'basic.planTransfer',
  regulatoryDisClosures: 'basic.regulatoryDisClosures',
  planCustomize: 'basic.planCustomize',
  accountAdmin: 'basic.accountAdmin',
};

export const pepKey = {
  overview: 'pep.overview',
  pepDetails: 'pep.pepDetails',
  planMaster: 'pep.planMaster',
  contact: 'pep.contact',
  accountAdmin: 'pep.accountAdmin',
};

export const cpaKey = {
  overview: 'cpa.overview',
  contact: 'cpa.contact',
  accountAdmin: 'cpa.accountAdmin',
};

export const initCollapseValue = {
  [basicPlanKey.overview]: true,
  [basicPlanKey.bankVerification]: true,
  [basicPlanKey.planSponsor]: true,
  [basicPlanKey.companyContact]: true,
  [basicPlanKey.companyDetail]: true,
  [basicPlanKey.multipleBusinesses]: true,
  [basicPlanKey.planTransfer]: true,
  [basicPlanKey.planCustomize]: true,
  [basicPlanKey.accountAdmin]: true,
};

export const initCollapseValueByCreatePlan = Object.keys(initCollapseValue).reduce((total, key) => {
  return {
    ...total,
    [key]: [basicPlanKey.overview, basicPlanKey.planCustomize].includes(key),
  };
}, {});

export const initPEPCollapseValue = {
  [pepKey.overview]: true,
  [pepKey.pepDetails]: true,
  [pepKey.planMaster]: true,
  [pepKey.contact]: true,
  [pepKey.accountAdmin]: true,
};

export const initCPACollapseValue = {
  ...initCollapseValue,
  [cpaKey.overview]: true,
  [cpaKey.contact]: true,
  [cpaKey.accountAdmin]: true,
};
