import { Collapse, List } from '@mui/material';
import * as React from 'react';
import { ReactNode } from 'react';
import MenuListItem from './MenuListItem';

export interface MenuItemProps {
  label: string;
  icon: ReactNode;
  childrens?: MenuItemProps[];
  url?: string;
  callback?: Function;
  hidden?: boolean;
  isOpenChildren?: boolean;
  subLabel?: ReactNode;
}

interface IMenuListProps {
  list: MenuItemProps[];
}

export default function MenuList(props: IMenuListProps) {
  const { list } = props;
  const [currentTab, setCurrentTab] = React.useState('');
  const handleToggleTab = (item: MenuItemProps) => {
    if (item.label === currentTab) {
      setCurrentTab('');
    } else {
      setCurrentTab(item.label);
    }
  };
  React.useEffect(() => {
    const willBeOpened: MenuItemProps[] = list.filter((item) => item.isOpenChildren);
    if (willBeOpened.length > 0) {
      setCurrentTab(willBeOpened[0].label);
    }
  }, [list]);
  return (
    <List>
      {list.map((item): ReactNode => {
        const { label, childrens = [] } = item;
        if (item.hidden) {
          return null;
        }
        return (
          <div key={item.label}>
            <MenuListItem item={item} currentTab={currentTab} handleToggleTab={handleToggleTab} />

            <Collapse in={currentTab === label} timeout="auto" unmountOnExit>
              {childrens.map((subItem): ReactNode => {
                if (subItem.hidden) {
                  return <></>;
                }
                return <MenuListItem item={subItem} key={subItem.label} child={true} />;
              })}
            </Collapse>
          </div>
        );
      })}
    </List>
  );
}
