import { CheckListEnum } from "core/enums/employer-checklist.enum";
import { ICheckListData } from "core/models/popupCheckListDto.model";

export const checkListByFinchConnected: ICheckListData[] = [
  {
    id: CheckListEnum.NAVIGATE,
    title: "Navigate Saveday Dashboard",
    link: "https://savedaycom.notion.site/How-to-Navigate-the-Saveday-Dashboard-For-Plan-Admins-caff24c011ee4d108c894179b470eb15",
  },
  {
    id: CheckListEnum.COMPLETE_PAYROLL_INTERGRATiON,
    title: "Complete Payroll Integration",
    link: "https://savedaycom.notion.site/Payroll-Integration-Guide-499771e35b5d4d7186540ddcb86ca68b",
  },
  {
    id: CheckListEnum.EMPLOYEE_JOURNEY,
    title: "Employee Journey",
    link: "https://www.notion.so/How-do-employees-get-invited-faca87b07a83429784749a07e469f3b6?pvs=21",
  },
  {
    id: CheckListEnum.BANK_ACCOUNT,
    title: "Change your bank account",
    link: "https://www.notion.so/savedaycom/How-to-change-bank-accounts-c8b123190b6f4836a71914ab23acdcc4",
  },
];

export const checkListByFinchNotConnected: ICheckListData[] = [
  {
    id: CheckListEnum.NAVIGATE,
    title: "Navigate Saveday Dashboard",
    link: "https://savedaycom.notion.site/How-to-Navigate-the-Saveday-Dashboard-For-Plan-Admins-caff24c011ee4d108c894179b470eb15",
  },
  {
    id: CheckListEnum.UPLOAD_EMPLOYEE_RECORDS,
    title: "Upload Employee Records",
    link: "https://www.notion.so/6bbc2db06a6e45a79a55e5c8311045bd?pvs=21",
  },
  {
    id: CheckListEnum.PROCESS_CONTRIBUTION,
    title: "Process Contributions",
    link: "https://www.notion.so/savedaycom/How-to-process-contributions-cd09eac76a404a4baa7ba63bda5c3f83",
  },
  {
    id: CheckListEnum.EMPLOYEE_JOURNEY,
    title: "Employee Journey",
    link: "https://www.notion.so/How-do-employees-get-invited-faca87b07a83429784749a07e469f3b6?pvs=21",
  },
  {
    id: CheckListEnum.BANK_ACCOUNT,
    title: "Change your bank account ",
    link: "https://www.notion.so/savedaycom/How-to-change-bank-accounts-c8b123190b6f4836a71914ab23acdcc4",
  },
];
