import { Backdrop, CircularProgress } from "@mui/material";

interface ILoadingCompProps {
  isLoading: boolean;
  renderIcon?: React.ReactNode;
}
export const LoadingComponent = (props: ILoadingCompProps) => {
  const { renderIcon, isLoading } = props;
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
        opacity: "0.5 !important",
        backgroundColor: "unset",
        justifyContent: "center",
        alignItems: "flex-start",
        display: "flex",
      }}
      open={!!isLoading}
    >
      <div className="p-2 w-full flex justify-center items-center bg-lightGreen rounded-lg h-screen">
        {renderIcon ? renderIcon : <CircularProgress color="secondary" />}
      </div>
    </Backdrop>
  );
};
