import { Dialog, DialogProps as MuiDialogProps } from '@mui/material';
import { PropsWithChildren, ReactNode, useState, createContext } from 'react';

type DialogVariant = 'info' | 'warn' | 'danger' | 'error' | 'success';

interface DialogProps extends MuiDialogProps {
  variant?: DialogVariant;
}

type DialogContextProps = {
  dialog: DialogProps;
  openDialog: (children: (close: () => void) => ReactNode, props?: Partial<DialogProps>) => void;
  closeDialog: () => void;
};

export const DialogContext = createContext<DialogContextProps>({
  dialog: { open: false },
  openDialog: () => {},
  closeDialog: () => {},
});

const DialogProvider = ({ children }: PropsWithChildren) => {
  const [dialog, setDialog] = useState<DialogProps>({ open: false });

  const closeDialog = () => {
    setDialog({ open: false });
  };

  const openDialog = (children: (close: () => void) => ReactNode, props: Partial<DialogProps> = {}) => {
    setDialog({
      children: children(closeDialog),
      ...props,
      open: true,
    });
  };

  return (
    <DialogContext.Provider
      value={{
        dialog,
        openDialog,
        closeDialog,
      }}
    >
      {children}
      <Dialog
        sx={{
          zIndex: 600,
        }}
        PaperProps={{
          style: { borderRadius: 8 },
        }}
        {...dialog}
      />
    </DialogContext.Provider>
  );
};

export default DialogProvider;
