const AccountCircleIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8_16098)">
        <path
          d="M10 2.16666C5.4 2.16666 1.66667 5.89999 1.66667 10.5C1.66667 15.1 5.4 18.8333 10 18.8333C14.6 18.8333 18.3333 15.1 18.3333 10.5C18.3333 5.89999 14.6 2.16666 10 2.16666ZM5.89167 15.7333C6.25 14.9833 8.43333 14.25 10 14.25C11.5667 14.25 13.7583 14.9833 14.1083 15.7333C12.975 16.6333 11.55 17.1667 10 17.1667C8.45 17.1667 7.025 16.6333 5.89167 15.7333ZM15.3 14.525C14.1083 13.075 11.2167 12.5833 10 12.5833C8.78333 12.5833 5.89167 13.075 4.7 14.525C3.85 13.4083 3.33333 12.0167 3.33333 10.5C3.33333 6.82499 6.325 3.83332 10 3.83332C13.675 3.83332 16.6667 6.82499 16.6667 10.5C16.6667 12.0167 16.15 13.4083 15.3 14.525ZM10 5.49999C8.38333 5.49999 7.08333 6.79999 7.08333 8.41666C7.08333 10.0333 8.38333 11.3333 10 11.3333C11.6167 11.3333 12.9167 10.0333 12.9167 8.41666C12.9167 6.79999 11.6167 5.49999 10 5.49999ZM10 9.66666C9.30833 9.66666 8.75 9.10832 8.75 8.41666C8.75 7.72499 9.30833 7.16666 10 7.16666C10.6917 7.16666 11.25 7.72499 11.25 8.41666C11.25 9.10832 10.6917 9.66666 10 9.66666Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_8_16098">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AccountCircleIcon;
