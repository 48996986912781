import {
  Avatar,
  Box,
  BoxProps,
  CSSObject,
  Divider,
  IconButton,
  ListItemButton,
  Theme,
  Toolbar,
  styled,
} from '@mui/material';
import Footer from 'common/components/Footer';
import { useEffect, useRef, useState } from 'react';
import MenuList, { MenuItemProps } from 'common/components/MenuList';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import { getFullName } from 'utils/text';
import { useAppDispatch, useAppSelector } from 'states/hooks';
import { getPeopleSync, logoutSessionSync } from 'states/auth/authSlice';
import LogoutIcon from 'assets/SVGIcons/LogoutIcon';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import { AuthContextType, ISession, useAuth } from 'contexts/AuthProvider';
import CompareArrowIcon from 'assets/SVGIcons/CompareArrowIcon';
import { getCookie, removeCookie, setCookie } from 'core/services/cookie.service';
import { CookieNames } from 'core/enums/cookie-names.enum';
import { ROLES } from 'core/constants/roles';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InfoIcon from '@mui/icons-material/Info';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SAVEDAY_LOGO from 'assets/images/saveday-horizontal-color.png';
import MainLayout from 'layout/MainLayout';
import { AppContextType, useApp } from 'contexts/AppProvider';
import { Button, ModalUploadFiles } from 'common/components';
import moment from 'moment';
import { DateFormatPattern } from 'core/enums/date-format-pattern.enum';
import { setEmployeeProfile } from 'states/manageEmployee/manageEmployeeSlice';
import { setSuccessMessage } from 'states/snackbarMessage/snackbarMessageSlice';
import './styles.scss';
import { selectWebVersion } from 'states/employee/employeeSlice';
import useWindowScrollPosition from 'hooks/useWindowScrollPosition';
import { DASHBOARD_ROUTES } from 'core/constants';
import { HeaderDashboard } from 'components/employerDashboard/HeaderDashboard';
import EmployeeReleaseFeedbackModal from 'components/employeeReleaseFeedback/EmployeeReleaseFeedbackModal';

const drawerWidth = 260;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  border: 0,
  color: theme.palette.lighter,
  backgroundColor: theme.palette.darkest.main,
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: 0,
  border: 0,
  color: theme.palette.lighter,
  backgroundColor: theme.palette.darkest.main,
});

interface DrawerContentProps extends BoxProps {
  open?: boolean;
}
const DrawerContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open',
})<DrawerContentProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerLeft = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': { ...openedMixin(theme), zIndex: 99 },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const DrawerRight = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': { ...openedMixin(theme), zIndex: 1204 },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

interface IAuthLayoutProps {
  children: React.ReactNode;
  menu?: MenuItemProps[];
}
export default function AuthLayout(props: IAuthLayoutProps) {
  const { children, menu = [] } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { openMenu, setOpenMenu } = useApp() as AppContextType;
  const { session, updateSession } = useAuth() as AuthContextType;
  const [openRight, setOpenRight] = useState(false);
  // const [companyLogo, setCompanyLogo] = useState("");
  const [isOpenModalUpload, setOpenModalUpload] = useState<boolean>(false);
  const [isOpenFeedbackModal, setFeedbackModal] = useState<boolean>(false);
  const currentRole = getCookie(CookieNames.CurrentRole);
  const refContent = useRef(null);
  const refFooter = useRef(null);
  const refHeader = useRef(null);
  const people = useAppSelector((state: any) => state.auth.people);
  const webVersionId = useAppSelector(selectWebVersion);
  const MAX_FILE_SIZE = 1024 * 1024 * 4; // 4MB
  const MIN_FILE_SIZE = 1024 * 2; // 2KB
  const menuFooter = [
    {
      label: 'Help Center',
      icon: <HelpOutlineIcon className="w-5" />,
      callback: () => {
        window.location.href = 'https://saveday.zendesk.com/hc/en-us';
      },
    },
    {
      label: 'Log Out',
      icon: <LogoutIcon />,
      callback: async () => {
        if (currentRole === ROLES.PARTICIPANT) {
          if (webVersionId && people?.personalConfig?.releaseAnnouncementAndFeedback) {
            setFeedbackModal(true);
            return;
          }
        }
        await dispatch(logoutSessionSync());
      },
    },
  ];
  const menuLearning = [
    {
      label: 'Help',
      icon: <InfoIcon className="w-5" />,
      url: 'https://saveday.zendesk.com/hc/en-us',
    },
    {
      label: 'FAQ',
      icon: <VisibilityIcon className="w-5" />,
      url: '/policies/faq',
    },
  ];

  const personIdCookie = getCookie(CookieNames.PersonId);

  let loginAs = getCookie(CookieNames.AdminLoginAsUser) || getCookie(CookieNames.SysAdminLoginAsUser);
  const mainMenu = [...menu];
  if (session) {
    const { allRole, currentRole } = session as ISession;
    if (allRole && allRole.length > 1 && currentRole !== ROLES.SELECT_ROLE) {
      const item = {
        url: '/dashboard/selectRole',
        label: 'Switch Roles',
        icon: <CompareArrowIcon />,
        callback: async () => {
          removeCookie(CookieNames.AdminLoginAsUser);
          setCookie(CookieNames.CurrentCompanyId, '');
          setCookie(CookieNames.CurrentRole, ROLES.SELECT_ROLE);
          setCookie(CookieNames.UserProfileId, '');
          // setCompanyLogo("");
          dispatch(setEmployeeProfile(null));
          await updateSession();
          return true;
        },
      };
      menuFooter.unshift(item);
    }
  }

  const sessionId = getCookie(CookieNames.XCSSession);
  useEffect(() => {
    if (!sessionId) {
      navigate('/login');
    }
  }, [sessionId]);

  // useEffect(() => {
  //   const cookieCurrentRole = getCookie(CookieNames.CurrentRole);
  //   if (
  //     companyDetail &&
  //     cookieCurrentRole !== ROLES.SELECT_ROLE &&
  //     cookieCurrentRole !== ROLES.SYSTEM_ADMIN &&
  //     cookieCurrentRole !== ROLES.CPA_ADMIN
  //   ) {
  //     setCompanyLogo(companyDetail.logoUrl as string);
  //   }
  // }, [companyDetail]);

  const handleDrawerLeftToggle = () => {
    setOpenMenu(!openMenu);
  };
  const handleDrawerRightToggle = () => {
    setOpenRight(!openRight);
  };

  const backToManage = async () => {
    const companyId =
      loginAs === 'cpa'
        ? getCookie(CookieNames.ParentCompanyId) || session?.values?.currentCpaCompanies?.[0]
        : loginAs === 'pep'
        ? getCookie(CookieNames.ParentCompanyId) || session?.values?.currentPepCompanies?.[0]
        : loginAs === 'corp'
        ? getCookie(CookieNames.ParentCompanyId) || session?.values?.currentCorpCompanies?.[0]
        : '';
    if (loginAs === getCookie(CookieNames.AdminLoginAsUser)) {
      removeCookie(CookieNames.AdminLoginAsUser);
    }
    removeCookie(CookieNames.ParentCompanyId);
    if (loginAs === getCookie(CookieNames.SysAdminLoginAsUser)) {
      removeCookie(CookieNames.SysAdminLoginAsUser);
    }
    setCookie(
      CookieNames.CurrentRole,
      loginAs === 'cpa'
        ? ROLES.CPA_ADMIN
        : loginAs === 'corp'
        ? ROLES.CORP_ADMIN
        : loginAs === 'pep'
        ? ROLES.PEP_ADMIN
        : ROLES.SYSTEM_ADMIN
    );
    if (
      (loginAs === 'cpa' ||
        loginAs === 'corp' ||
        loginAs === 'pep' ||
        loginAs === 'sys_cpa' ||
        loginAs === 'sys_corp' ||
        loginAs === 'sys_pep') &&
      companyId
    ) {
      setCookie(CookieNames.CurrentCompanyId, companyId);
    } else {
      setCookie(CookieNames.CurrentCompanyId, '');
    }
    setCookie(CookieNames.UserProfileId, '');
    // setCompanyLogo("");
    await updateSession();
    navigate(
      loginAs === 'cpa'
        ? '/cpa/dashboard'
        : loginAs === 'corp'
        ? '/corp/dashboard'
        : loginAs === 'pep'
        ? '/pep/dashboard'
        : loginAs.startsWith('sys_')
        ? '/admin/manage-employers'
        : `/admin/manage-${loginAs === 'employee' ? 'participant' : loginAs}s`
    );
  };

  let debounce: any;
  useEffect(() => {
    const onResize = () => {
      clearTimeout(debounce);
      debounce = setTimeout(function () {
        if (refContent.current && refFooter.current && refHeader.current) {
          const elementContent = refContent.current as HTMLElement;
          const elementFooter = refFooter.current as HTMLElement;
          const elementHeader = refHeader.current as HTMLElement;
          elementContent.style.minHeight = `${window.innerHeight}px`;
          elementContent.style.paddingBottom = `${elementFooter.offsetHeight}px`;
          elementContent.style.paddingTop = `${elementHeader.offsetHeight}px`;
        }
      }, 350);
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  const canEditProfile = session?.currentRole === ROLES.COMPANY_ADMIN || session?.currentRole === ROLES.PARTICIPANT;
  const goToEditProfilePage = async () => {
    if (session?.currentRole === ROLES.COMPANY_ADMIN) {
      await dispatch(getPeopleSync());
      navigate('/dashboard/employer/editdetail');
    }
    if (session?.currentRole === ROLES.PARTICIPANT) {
      navigate('/dashboard/employee/settings?tab=profile');
    }
  };

  const { y } = useWindowScrollPosition();
  const isHomePage = () => {
    return DASHBOARD_ROUTES.includes(location.pathname);
  };

  return (
    <MainLayout>
      <DrawerLeft
        variant="permanent"
        open={openMenu}
        sx={{ '& .MuiDrawer-paper': { justifyContent: 'space-between' } }}
      >
        <div>
          <div className="px-6 pt-6 flex flex-col items-center bg-darkest">
            <div className="mb-2 relative">
              <Avatar src={people?.pictureUrl} className="h-[72px] w-[72px]" alt="" />
              <span className="absolute top-0 left-0 opacity-0 hover:opacity-100 transition-opacity">
                <IconButton
                  onClick={() => setOpenModalUpload(true)}
                  className="bg-[rgba(0,0,0,0.5)] h-[72px] w-[72px] flex items-center justify-center border border-white border-solid"
                >
                  <EditIcon sx={{ width: 24, color: 'white' }} />
                </IconButton>
              </span>
            </div>
            <ModalUploadFiles
              isOpen={isOpenModalUpload}
              onCancel={() => setOpenModalUpload(false)}
              minFileSize={MIN_FILE_SIZE}
              maxFileSize={MAX_FILE_SIZE}
              acceptFiles=".png, .jpg, .jpeg, .gif"
              title="Change Avatar"
              fileType="PROFILE_PIC"
              personId={personIdCookie}
              onSuccess={() => {
                dispatch(setSuccessMessage('Change avatar successfully!'));
                setOpenModalUpload(false);
              }}
            />
            {canEditProfile ? (
              <ListItemButton
                className="p-2 font-bold rounded relative max-w-[200px] text-center whitespace-normal"
                onClick={goToEditProfilePage}
              >
                {getFullName(people)}
                <span className="absolute left-full top-1 ml-1 opacity-1 transition-opacity duration-200 hover:opacity-1">
                  <EditIcon sx={{ width: 20 }} />
                </span>
              </ListItemButton>
            ) : (
              <span className="p-2 font-bold rounded max-w-[200px] text-center whitespace-normal">
                {getFullName(people)}
              </span>
            )}
            <div className="user-info__last-login text-body-8">
              {'Last login: ' + moment.utc(people?.lastLogin).format(DateFormatPattern.MonthDayYear)}
            </div>
            <Divider className="w-full border-borderColor mt-6" />
          </div>
          <div>
            <MenuList list={mainMenu} />
          </div>
        </div>
        <div className="pb-4">
          <div className="px-6 mb-2">
            <Divider className="border-borderColor" />
          </div>
          <MenuList list={menuFooter} />
        </div>
      </DrawerLeft>
      <DrawerContent open={openMenu} className={openMenu ? 'opened-left-menu' : 'closed-left-menu'}>
        <div className="relative">
          <AppBar
            position="fixed"
            open={openMenu}
            sx={{
              backgroundColor: y || !isHomePage() ? 'white' : 'transparent',
              boxShadow: y || !isHomePage() ? '0px 2px 10px rgba(83, 86, 89, 0.1)' : 'none',
              zIndex: 400,
              WebkitTransition: 'background-color 200ms linear',
              msTransition: 'background-color 200ms linear',
              transition: 'background-color 200ms linear',
            }}
            className="layoutHeader"
            ref={refHeader}
          >
            <Toolbar
              sx={{
                padding: {
                  sm: '0 32px',
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexGrow: 1,
                  flexWrap: 'nowrap',
                  minHeight: 'unset',
                  alignItems: {
                    zero: 'flex-start',
                  },
                  flexDirection: {
                    zero: 'column',
                  },
                  justifyContent: 'space-between',
                }}
              >
                <div className="flex items-center gap-2">
                  <IconButton className={`md:hidden w-6 h-10`} onClick={handleDrawerLeftToggle}>
                    {!openMenu && (
                      <MenuIcon
                        sx={{
                          color: (theme) => theme.palette.secondaryColor,
                          zIndex: 10,
                        }}
                      />
                    )}
                    {openMenu && (
                      <CloseOutlinedIcon
                        sx={{
                          color: (theme) => theme.palette.secondaryColor,
                          zIndex: 10,
                        }}
                      />
                    )}
                  </IconButton>
                  {(loginAs || location.state?.from) && (
                    <Button
                      sx={{
                        minWidth: 150,
                        width: 'fit-content',
                        justifyContent: 'left',
                        border: 'none',
                        backgroundColor: 'transparent',
                        display: {
                          zero: openMenu ? 'none' : 'block',
                          xs: 'block',
                        },
                        '&:hover': {
                          border: 'none',
                          backgroundColor: 'transparent',
                        },
                        padding: 0,
                      }}
                      disableTouchRipple
                      variant="outlined"
                      color="secondary"
                      onClick={loginAs ? () => backToManage() : () => navigate(location.state?.from)}
                    >
                      <ArrowBackIcon sx={{ marginRight: '8px' }} /> Back to Saveday Admin
                    </Button>
                  )}
                </div>
              </Box>
              {(y || !isHomePage()) && (
                <div className={[`absolute right-5`, openMenu ? 'hidden xs:block' : 'block'].join(' ')}>
                  <img src={SAVEDAY_LOGO} alt="saveday-logo" className={'max-h-8 w-[120px] h-[32px]'} />
                </div>
              )}
            </Toolbar>
          </AppBar>
          <main className={['content min-h-[calc(100vh)]', 'pt-[64px]'].join(' ')} ref={refContent}>
            <>
              {isHomePage() && (
                <Box>
                  <HeaderDashboard />
                </Box>
              )}
              {!!people && children}
            </>
          </main>
          <div ref={refFooter} className="absolute bottom-0 left-0 right-0">
            <Footer />
          </div>
        </div>
      </DrawerContent>
      <DrawerRight variant="permanent" anchor="right" open={openRight}>
        <div className="px-6 pt-6">
          HELP CENTER
          <Divider className="border-borderColor mt-6 mb-2" />
        </div>
        <div>
          <MenuList list={menuLearning} />
        </div>
      </DrawerRight>
      <Box
        className={[
          'absolute top-0 left-0 w-full h-full bg-white bg-opacity-30 md:hidden',
          openMenu ? 'block' : 'hidden',
        ].join(' ')}
        style={{ zIndex: 98 }}
        onClick={() => handleDrawerLeftToggle()}
      ></Box>
      <Box
        className={['absolute top-0 left-0 w-full h-full', openRight ? 'block' : 'hidden'].join(' ')}
        style={{ zIndex: 1203 }}
        onClick={() => handleDrawerRightToggle()}
      ></Box>
      {isOpenFeedbackModal && (
        <EmployeeReleaseFeedbackModal isOpenFeedbackModal={isOpenFeedbackModal} setFeedbackModal={setFeedbackModal} />
      )}
    </MainLayout>
  );
}
