import { FormLabel, Radio, RadioGroup } from '@mui/material';
import React, { ChangeEvent } from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Tooltip } from 'common/components';
import { TWO_FA_METHOD } from 'core/constants/roles';

interface IReceiveCodeOptionsProps {
  onChange: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
  isDisabled: boolean;
  value: string;
}

const ReceiveCodeOptions = ({ onChange, isDisabled, value }: IReceiveCodeOptionsProps) => {
  const RenderTitle = () => {
    const title = (
      <ol className="list-decimal ml-4 my-1.5 mr-1">
        <li>
          Download an authenticator app, such as Authy, Google Authenticator or Microsoft Authenticator, on your phone
          from the App Store or GooglePlay
        </li>
        <li className="mt-1.5">Create an authenticator app account when you open the app.</li>
        <li className="mt-1.5">Add your by scanning the QR.</li>
      </ol>
    );
    return title;
  };

  return (
    <RadioGroup
      name="receiveCode"
      onChange={onChange}
      value={value}
      className={`grid gap-4 ${value === TWO_FA_METHOD.BACKUP_CODE ? 'mb-0' : 'mb-6'}`}
    >
      {(!isDisabled || value === TWO_FA_METHOD.SMS) && (
        <FormLabel
          className={[
            'flex items-center rounded-lg border border-solid py-3 pl-2 pr-4 text-sm font-normal',
            isDisabled ? '' : 'cursor-pointer',
            value === TWO_FA_METHOD.SMS ? 'border-secondary' : 'border-borderColor',
          ].join(' ')}
        >
          <Radio
            sx={{
              color: (theme) => theme.palette.lightBeige,
            }}
            color="secondary"
            value={TWO_FA_METHOD.SMS}
            disabled={isDisabled}
          />
          <div className="ml-2">
            <p className="text-superBlack">
              <strong>Send me a text</strong>
            </p>
            <p className="text-deepGrey mt-0.5">Receive a 6-digit verification code on your phone via text message</p>
          </div>
        </FormLabel>
      )}
      {(!isDisabled || value === TWO_FA_METHOD.AUTH_APP) && (
        <FormLabel
          className={[
            'flex items-center rounded-lg border border-solid py-3 pl-2 pr-4 text-sm font-normal',
            isDisabled ? '' : 'cursor-pointer',
            value === TWO_FA_METHOD.AUTH_APP ? 'border-secondary' : 'border-borderColor',
          ].join(' ')}
        >
          <Radio
            sx={{
              color: (theme) => theme.palette.lightBeige,
            }}
            color="secondary"
            value={TWO_FA_METHOD.AUTH_APP}
            disabled={isDisabled}
          />
          <div className="ml-2">
            <p className="text-superBlack">
              <strong>Authenticate with an app</strong>
              <Tooltip title={<RenderTitle />}>
                <HelpOutlineIcon className="w-[16px] h-[16px] ml-1 text-secondary" />
              </Tooltip>
            </p>
            <p className="text-deepGrey mt-0.5">
              Receive a 6-digit verification code on your phone via an authenticator app like Authy.
            </p>
          </div>
        </FormLabel>
      )}
    </RadioGroup>
  );
};

export default ReceiveCodeOptions;
