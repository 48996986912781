import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { REQUEST_STATUS } from 'core/constants/roles';
import CORPService from 'core/services/corp.service';
import { getSummary, getClients, sendInvites, terminateClients } from 'core/services/cpa.service';
import PEPService from 'core/services/pep.service';

export interface ICPAClient {
  activeProfile: number;
  companyName: string;
  id: string;
  status: string;
  totalContribution: number;
  totalProfile: number;
  upcomingPayrollDate: number;
  ytdTotalContribution: number;
}

interface ICPAClientState {
  getSummaryStatus: string;
  summary: {
    activeProfile: number;
    totalContribution: number;
    totalProfile: number;
    ytdContribution: number;
  };
  getClientsStatus: string;
  clients: {
    content: ICPAClient[];
    totalElements: number;
  };
  sendInvitesStatus: string;
  terminateClientsStatus: string;
}

export const getSummaryAsync = createAsyncThunk(
  'cpaClient/getSummaryAsync',
  async ({ apiPath = 'cpa' }: { apiPath: string }): Promise<any> => {
    if (apiPath === 'cpa') {
      const response = await getSummary();
      return response.data;
    } else if (apiPath === 'pep') {
      const response = await PEPService.getSummary();
      return response.data;
    } else {
      const response = await CORPService.getSummary();
      return response.data;
    }
  }
);

export const getClientsAsync = createAsyncThunk(
  'cpaClient/getClientsAsync',
  async ({ apiPath = 'cpa', filter }: { filter: any; apiPath: string }): Promise<any> => {
    if (apiPath === 'cpa') {
      const response = await getClients({ filter });
      return response.data;
    } else if (apiPath === 'pep') {
      const response = await PEPService.getClients({ filter });
      return response.data;
    } else {
      const response = await CORPService.getClients({ filter });
      return response.data;
    }
  }
);

export const sendInvitesAsync = createAsyncThunk(
  'cpaClient/sendInvitesAsync',
  async ({ apiPath = 'cpa', ...params }: { data: any[]; resend: boolean; apiPath: string }): Promise<any> => {
    if (apiPath === 'cpa') {
      const response = await sendInvites(params);
      return response.data;
    } else if (apiPath === 'pep') {
      const response = await PEPService.sendInvites(params);
      return response.data;
    } else {
      const response = await CORPService.sendInvites(params);
      return response.data;
    }
  }
);

export const terminateClientsAsync = createAsyncThunk(
  'cpaClient/terminateClientsAsync',
  async ({ apiPath = 'cpa', ...params }: { companyIds: any[]; apiPath: string }): Promise<any> => {
    if (apiPath === 'cpa') {
      const response = await terminateClients(params);
      return response.data;
    } else if (apiPath === 'pep') {
      const response = await PEPService.terminateClients(params);
      return response.data;
    } else {
      const response = await CORPService.terminateClients(params);
      return response.data;
    }
  }
);

const initialState: ICPAClientState = {
  getSummaryStatus: REQUEST_STATUS.IDLE,
  summary: {
    activeProfile: 0,
    totalContribution: 0,
    totalProfile: 0,
    ytdContribution: 0,
  },
  getClientsStatus: REQUEST_STATUS.IDLE,
  clients: {
    content: [],
    totalElements: 0,
  },
  sendInvitesStatus: REQUEST_STATUS.IDLE,
  terminateClientsStatus: REQUEST_STATUS.IDLE,
};

const cpaClientSlice = createSlice({
  name: 'cpaClient',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSummaryAsync.pending, (state) => {
        state.getSummaryStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(getSummaryAsync.fulfilled, (state, action) => {
        state.getSummaryStatus = REQUEST_STATUS.SUCCESS;
        state.summary = action.payload;
      })
      .addCase(getSummaryAsync.rejected, (state, action) => {
        state.getSummaryStatus = REQUEST_STATUS.ERROR;
      })

      .addCase(getClientsAsync.pending, (state) => {
        state.getClientsStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(getClientsAsync.fulfilled, (state, action) => {
        state.getClientsStatus = REQUEST_STATUS.SUCCESS;
        state.clients = action.payload;
      })
      .addCase(getClientsAsync.rejected, (state, action) => {
        state.getClientsStatus = REQUEST_STATUS.ERROR;
      })

      .addCase(sendInvitesAsync.pending, (state) => {
        state.sendInvitesStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(sendInvitesAsync.fulfilled, (state, action) => {
        state.sendInvitesStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(sendInvitesAsync.rejected, (state, action) => {
        state.sendInvitesStatus = REQUEST_STATUS.ERROR;
      })

      .addCase(terminateClientsAsync.pending, (state) => {
        state.terminateClientsStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(terminateClientsAsync.fulfilled, (state, action) => {
        state.terminateClientsStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(terminateClientsAsync.rejected, (state, action) => {
        state.terminateClientsStatus = REQUEST_STATUS.ERROR;
      });
  },
});

export default cpaClientSlice.reducer;
