import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { FormHelperText, Grid, IconButton, InputAdornment } from '@mui/material';
import { Button, ErrorMassage, RadioButtonGroup, TextField } from 'common/components';
import { CookieNames } from 'core/enums/cookie-names.enum';
import { getCookie, setCookie } from 'core/services/cookie.service';
import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'states/hooks';
import CheckIcon from '@mui/icons-material/Check';
import { z } from 'zod';
import { validatePasswordMessages } from 'pages/resetPassword';
import { checkTokenValidAsync, employeeSignUpAsync } from 'states/onboardingSlice';
import { RequestStatus } from 'core/enums/request-status.enum';
import { logoutSessionSync } from 'states/auth/authSlice';
import { useAuth } from 'contexts/AuthProvider';
import { EMPLOYEE_SCREENS, REGEX_PATTERN } from 'core/constants';
import { setErrorMessage } from 'states/snackbarMessage/snackbarMessageSlice';
import { zodResolver } from '@hookform/resolvers/zod';
import { EmployeeSignUpDto } from 'core/models/loginDto.model';
import { createAnswerDataAsync } from 'states/employeeRiskQuestion/employeeRiskQuestionSlice';
import { ROLES } from 'core/constants/roles';
import { ErrorType } from 'core/enums';

//#region types and interfaces
interface IYourLoginWithToken {
  userName: string;
  password: string;
  confirmPassword: string;
  workEmail: string;
  personalEmail: string;
  reCaptchaToken: string;
  isWorkEmailPrimary: string;
}

type Props = {
  setStep: Dispatch<SetStateAction<number>>;
};
//#endregion

//#region constants and global functions

const DEFAULT_VALUES: IYourLoginWithToken = {
  userName: '',
  password: '',
  confirmPassword: '',
  workEmail: '',
  personalEmail: '',
  reCaptchaToken: '',
  isWorkEmailPrimary: '',
};
//#endregion

const CreateYourLoginWithToken: FC<Props> = ({ setStep }) => {
  //#region Define helpers
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  //#endregion

  //#region Define schema, state and params
  const token = searchParams.get('token') || '';
  const loading = useAppSelector((state) => state.onboarding.loading);
  const sessionId = getCookie(CookieNames.XCSSession);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const reCaptchaRef = useRef<any>(null);
  const userProfileIdCookie = getCookie(CookieNames.UserProfileId);
  const sessionIdCookie = getCookie(CookieNames.XCSSession);

  const zodSchema = z
    .object({
      userName: z.string().superRefine((value, ctx) => {
        if (sessionIdCookie && userProfileIdCookie) return true;
        if (value === '') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Username is required',
          });
        }
        if (!REGEX_PATTERN.USER_NAME.pattern.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: REGEX_PATTERN.USER_NAME.message,
          });
        }
      }),
      password: z.string().superRefine((value, ctx) => {
        if (sessionIdCookie && userProfileIdCookie) return true;
        if (value === '') {
          ctx.addIssue({
            message: 'Password is required',
            code: z.ZodIssueCode.too_small,
            minimum: 8,
            exact: false,
            inclusive: true,
            type: 'string',
          });
        }
        if (value.length < 8) {
          ctx.addIssue({
            message: 'At least 8 characters',
            code: z.ZodIssueCode.custom,
          });
        }
        if (!REGEX_PATTERN.AT_LEAST_ONE_LOWERCASE.pattern.test(value)) {
          ctx.addIssue({
            message: REGEX_PATTERN.AT_LEAST_ONE_LOWERCASE.message,
            code: z.ZodIssueCode.custom,
          });
        }
        if (!REGEX_PATTERN.AT_LEAST_ONE_UPPERCASE.pattern.test(value)) {
          ctx.addIssue({
            message: REGEX_PATTERN.AT_LEAST_ONE_UPPERCASE.message,
            code: z.ZodIssueCode.custom,
          });
        }
        if (!REGEX_PATTERN.AT_LEAST_ONE_NUMBER.pattern.test(value)) {
          ctx.addIssue({
            message: REGEX_PATTERN.AT_LEAST_ONE_NUMBER.message,
            code: z.ZodIssueCode.custom,
          });
        }
        if (!REGEX_PATTERN.AT_LEAST_ONE_SPECIAL_CHARACTER.pattern.test(value)) {
          ctx.addIssue({
            message: REGEX_PATTERN.AT_LEAST_ONE_SPECIAL_CHARACTER.message,
            code: z.ZodIssueCode.custom,
          });
        }
      }),
      confirmPassword: z.string().superRefine((value, ctx) => {
        if (sessionIdCookie && userProfileIdCookie) return true;
        if (value === '') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Password is required',
          });
        }
        if (!REGEX_PATTERN.PASSWORD.pattern.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: REGEX_PATTERN.PASSWORD.message,
          });
        }
      }),
      workEmail: z.string().superRefine((value, ctx) => {
        if (token) return true;
        if (sessionIdCookie && userProfileIdCookie) return true;
        if (value === '') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Email is required',
          });
        }
        if (!REGEX_PATTERN.EMAIL.pattern.test(value) && !sessionIdCookie) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: REGEX_PATTERN.EMAIL.message,
          });
        }
      }),
      personalEmail: z.string().superRefine((value, ctx) => {
        if (token) return true;
        if (sessionIdCookie && userProfileIdCookie) return true;
        if (value === '') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Email is required',
          });
        }
        if (value === '') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Email is required',
          });
        }
        if (!REGEX_PATTERN.EMAIL.pattern.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: REGEX_PATTERN.EMAIL.message,
          });
        }
      }),
      isWorkEmailPrimary: z.string().refine(
        (value) => {
          if (token) return true;
          if (value === '') return false;
          return true;
        },
        { message: 'This question is required the answer.' }
      ),
      reCaptchaToken: z.string().refine(
        (value) => {
          if ((userProfileIdCookie && sessionIdCookie) || !token) return true;
          if (!value) return false;
          return true;
        },
        { message: 'Please check the reCaptcha' }
      ),
    })
    .refine(
      (schema) => {
        if (userProfileIdCookie && sessionIdCookie) return true;
        if (schema.password && schema.confirmPassword) return schema.password === schema.confirmPassword;
        return true;
      },
      {
        path: ['confirmPassword'],
        message: 'Passwords do not match',
      }
    )
    .refine(
      (schema) => {
        if (userProfileIdCookie && sessionIdCookie) return true;
        if (schema.isWorkEmailPrimary === 'no') {
          if (schema.personalEmail === '') {
            return false;
          }
          if (!REGEX_PATTERN.EMAIL.pattern.test(schema.personalEmail)) {
            return false;
          }
        }
        return true;
      },
      {
        path: ['personalEmail'],
        message: 'Personal Email invalid',
      }
    );
  //#endregion

  //#region Define functions
  const {
    handleSubmit,
    register,
    setError,
    control,
    watch,
    reset,
    formState: { errors, dirtyFields },
  } = useForm<IYourLoginWithToken>({
    defaultValues: DEFAULT_VALUES,
    resolver: zodResolver(zodSchema),
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const cancelOnboardingFLow = async () => {
    if (sessionId) {
      await dispatch(logoutSessionSync());
      auth?.setSession(null);
    }
    navigate('/onboarding/employee/type');
  };

  const checkTokenValid = async () => {
    const response = await dispatch(checkTokenValidAsync(token));
    if (response.meta.requestStatus === RequestStatus.FULFILLED) {
      const workEmail = response.payload.workEmail;
      DEFAULT_VALUES.workEmail = workEmail;
      reset(DEFAULT_VALUES);
      return;
    } else {
      await cancelOnboardingFLow();
      setTimeout(() => {
        dispatch(setErrorMessage('The link is no longer valid.'));
      }, 1000);
    }
  };

  const checkFormValid = () => {
    if (userProfileIdCookie && sessionIdCookie) {
      return true;
    }
    return Object.keys(dirtyFields).length >= (watch('isWorkEmailPrimary') === 'no' ? 6 : 5);
  };

  const createAnswerAndSetCookie = async (payload: any) => {
    const {
      id: sessionId,
      currentUserProfileId,
      currentCompanyId,
      values: { personId },
    } = payload;
    setCookie(CookieNames.PersonId, personId);
    setCookie(CookieNames.XCSSession, sessionId);
    setCookie(CookieNames.UserProfileId, currentUserProfileId);
    setCookie(CookieNames.CurrentCompanyId, currentCompanyId);
    setCookie(CookieNames.CurrentRole, ROLES.PARTICIPANT);
    const createAnswerResponse = await dispatch(createAnswerDataAsync(currentUserProfileId));
    if (createAnswerResponse.meta.requestStatus === RequestStatus.FULFILLED) {
      setCookie(CookieNames.OnboardingStep, EMPLOYEE_SCREENS.AccountPreference);
      setStep(EMPLOYEE_SCREENS.AccountPreference);
    }
  };

  const onSubmitHandler = async (values: IYourLoginWithToken) => {
    const payload: EmployeeSignUpDto = {
      password: values.password,
      userName: values.userName,
      workEmail: values.workEmail,
      personalEmail: values.personalEmail,
      workEmailPrimary: values.isWorkEmailPrimary === 'yes' ? true : false,
    };
    const params = {
      token,
      filter: {
        onboarding: true,
      },
    };
    const response: any = await dispatch(
      employeeSignUpAsync({
        payload,
        params,
        config: { headers: { ReCaptcha: values.reCaptchaToken } },
      })
    );
    if (response.meta.requestStatus === RequestStatus.FULFILLED) {
      await createAnswerAndSetCookie(response.payload);
      auth?.updateSession();
    } else if (response.meta.requestStatus === RequestStatus.REJECTED) {
      const errorResponse: any = JSON.parse(response.error.message as string);
      if (errorResponse.errorType === ErrorType.DUPLICATED_FIELD) {
        const fieldName = errorResponse.error.split(' ')[1];
        dispatch(setErrorMessage(`The ${fieldName} is already used. Please use another ${fieldName}!`));
      }
    }
    reCaptchaRef?.current?.reset();
  };

  //#endregion

  //#region React hooks

  useEffect(() => {
    if (searchParams.has('token')) {
      checkTokenValid();
    }
  }, []);

  //#endregion

  return (
    <>
      <form className="my-4" onSubmit={handleSubmit(onSubmitHandler)}>
        <Grid container spacing={2}>
          <Grid item zero={12} className="text-md font-semibold text-primary mt-2">
            Create your login
          </Grid>
          <Grid item zero={12} sm={12}>
            <TextField
              registerForm={{ ...register('userName') }}
              placeholder="Type username"
              error={errors.userName?.message}
              label={<span className="text-xs font-semibold">Username</span>}
              disabled={userProfileIdCookie}
              required={true}
            />
          </Grid>
          <Grid item zero={12} sm={6}>
            <>
              <TextField
                required={true}
                label={<span className="text-xs font-semibold">Password</span>}
                type={showPassword ? 'text' : 'password'}
                placeholder="Type password here"
                registerForm={{ ...register('password') }}
                autoComplete="new-password"
                disabled={userProfileIdCookie}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={errors.password?.type === z.ZodIssueCode.too_small ? errors.password?.message : ''}
              />
              {dirtyFields.password &&
                Object.entries(validatePasswordMessages).map(([type, message]) => (
                  <div className="flex gap-1 items-center mt-2" key={type}>
                    <CheckIcon
                      className={[
                        'text-xl',
                        ((errors.password?.types?.custom as string[]) || []).includes(message)
                          ? 'text-primary'
                          : 'text-secondary',
                      ].join(' ')}
                    />
                    <p className="text-primary text-xs">{message}</p>
                  </div>
                ))}
            </>
          </Grid>
          <Grid item zero={12} sm={6}>
            <TextField
              required={true}
              label={<span className="text-xs font-semibold">Confirm Password</span>}
              type={showConfirmPassword ? 'text' : 'password'}
              placeholder="Type password here"
              registerForm={{ ...register('confirmPassword') }}
              disabled={userProfileIdCookie}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                      {showConfirmPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={errors.confirmPassword?.message}
            />
          </Grid>
          <Grid item zero={12} sm={6}>
            <TextField
              label={<span className="text-xs font-semibold">Personal Email</span>}
              registerForm={{ ...register('personalEmail') }}
              placeholder="mail@example.com"
              error={errors.personalEmail?.message}
              disabled={userProfileIdCookie}
              required={watch('isWorkEmailPrimary') === 'no'}
            />
          </Grid>
          <Grid item zero={12} sm={6}>
            <TextField
              required={true}
              label={<span className="text-xs font-semibold">Work Email</span>}
              registerForm={{ ...register('workEmail') }}
              placeholder="mail@example.com"
              error={errors.workEmail?.message}
              disabled={true}
            />
          </Grid>
          <Grid item className="flex items-center" zero={12} md={9}>
            <div className="text-xs text-primary">
              Please specify the email address you prefer for us to use as your primary contact:
            </div>
          </Grid>
          <Grid item className="flex items-center flex-col" zero={12} md={3}>
            <Controller
              control={control}
              name="isWorkEmailPrimary"
              render={({ field, fieldState }) => {
                return (
                  <>
                    <RadioButtonGroup
                      {...field}
                      disabled={userProfileIdCookie}
                      row
                      sx={{
                        justifyContent: {
                          zero: 'flex-start',
                          md: 'flex-end',
                        },
                        gap: 2,
                        '.MuiFormControlLabel-root': {
                          fontSize: '14px !important',
                          fontWeight: '600 !important',
                          color: (theme) => theme.palette.normalText,
                          margin: 0,
                        },
                      }}
                      options={[
                        { label: 'Work', value: 'yes' },
                        { label: 'Personal', value: 'no' },
                      ]}
                      error={!!fieldState.error?.message}
                    />
                    {fieldState.error?.message && (
                      <FormHelperText
                        className="w-full sm:text-right text-left text-xs text-errorColor mt-2"
                        error={!!fieldState.error.message}
                      >
                        {fieldState.error.message}
                      </FormHelperText>
                    )}
                  </>
                );
              }}
            />
          </Grid>
          {!sessionIdCookie && !userProfileIdCookie && (
            <Grid item zero={12}>
              <Controller
                name="reCaptchaToken"
                control={control}
                render={({ field: { onChange } }) => {
                  return (
                    <ReCAPTCHA
                      className="w-full"
                      ref={reCaptchaRef}
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
                      onChange={onChange}
                      onExpired={() => {
                        onChange(null);
                        setError('reCaptchaToken', {
                          message: 'Expire, Please check the reCaptcha',
                        });
                      }}
                    />
                  );
                }}
              />
              {errors.reCaptchaToken?.message && <ErrorMassage message={errors.reCaptchaToken?.message} />}
            </Grid>
          )}
        </Grid>

        <div className="w-full text-right mt-10">
          <Button
            variant="contained"
            color="secondary"
            disabled={!checkFormValid() || loading}
            type="submit"
            sx={{
              width: '100%',
              maxWidth: {
                zero: '100%',
                sm: '220px',
              },
            }}
          >
            Next
          </Button>
        </div>
      </form>
    </>
  );
};

export default CreateYourLoginWithToken;
