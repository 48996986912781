export enum CompanyStatus {
  ACTIVE = "ACTIVE",
  NEW = "NEW",
  APPROVED = "APPROVED",
  NEW_LEAD = "NEW_LEAD",
  VERIFIED = "VERIFIED",
  // INVITED IS ONBOARDING
  ONBOARDING = "ONBOARDING",
  PENDING = "PENDING",
  DRAFT = "DRAFT",
}

export enum AccountTypes {
  LEGACY_PLAN = "LEGACY_PLAN",
  BASIC_PLAN = "BASIC_PLAN",
  CPA = "CPA",
  PEP = "PEP",
  CORPORATE_PLATFORM = "CORP",
}

export enum ManagePlanTaskTypes {
  CREATE_NEW_ACCOUNT = "create-new-account",
  EDIT_EXISTING_ACCOUNT = "edit-account",
  CREATE_PLAN = "create-plan",
}
