import { ROLES } from 'core/constants/roles';
import { CookieNames } from 'core/enums/cookie-names.enum';
import { UserRole } from 'core/enums/user-role.enum';
import AuthService from 'core/services/auth.service';
import { getCookie, removeAllCookie } from 'core/services/cookie.service';
import * as React from 'react';
import { useEffect } from 'react';
import { getPeopleSync, getSessionSync, updateSessionSync } from 'states/auth/authSlice';
import { useAppDispatch, useAppSelector } from 'states/hooks';
import {
  getAllCompanyPendingNotificationsCountAsync,
  getCompanyDetailsAsync,
} from 'states/manageEmployer/manageEmployerSlice';

export interface ICookie {
  currentCompanyId?: string;
  currentCompanyName?: string;
  currentUserProfileId?: string;
  currentRole: ROLES | string;
  systemAdminRole?: string;
  mainPage?: string;
}
export interface ISession {
  id: string;
  companyIdAndName: any;
  companyIdAndUserProfile: any;
  currentCompanyId: string;
  currentUserProfileId: string;
  expiresOn: Date;
  currentRole: string;
  values: {
    firstName: string;
    lastName: string;
    personId: string;
    role: any;
    systemAdmin: boolean | null;
    systemAdminRole: string;
    cpaAdmin: boolean | null;
    currentCpaCompanies?: string[];
    currentCorpCompanies?: string[];
    currentPepCompanies?: string[];
    corpAdmin: boolean | null;
    pepAdmin: boolean | null;
  };
  allRole: ICookie[];
  isSystemAdminAndNotParticipateInCurrentCompany: boolean;
  isCompanyAdminOfCurrentCompany: boolean;
  isParticipantOfCurrentCompany: boolean;
}

export type AuthContextType = {
  session: ISession | null;
  setSession: Function;
  getSession: Function;
  updateSession: Function;
};

const AuthContext = React.createContext<AuthContextType | null>(null);

interface Props {
  children: React.ReactNode;
}
const AuthProvider: React.FC<Props> = ({ children }) => {
  const [session, setSession] = React.useState<ISession | null>(null);
  const loginSuccess = useAppSelector((state) => state.auth.loginSuccess);
  const companyDetail = useAppSelector((state) => state.manageEmployer.companyDetail);
  const people = useAppSelector((state) => state.auth.people);
  const dispatch = useAppDispatch();

  useEffect(() => {
    updateSession();
  }, [loginSuccess]);

  const getPeopleProfile = async () => {
    if (!people) {
      await dispatch(getPeopleSync());
    }
  };

  const getCompanyDetails = async () => {
    const cookieCompanyId = getCookie(CookieNames.CurrentCompanyId);
    const cookieCurrentRole = getCookie(CookieNames.CurrentRole);
    if (cookieCompanyId && (!companyDetail || (companyDetail && companyDetail.id !== cookieCompanyId))) {
      dispatch(getCompanyDetailsAsync(cookieCompanyId));
      if (cookieCurrentRole === ROLES.COMPANY_ADMIN || cookieCurrentRole === ROLES.PARTICIPANT) {
        dispatch(getAllCompanyPendingNotificationsCountAsync(cookieCompanyId));
      }
    }
  };

  const updateSession = async () => {
    if (session && !getCookie(CookieNames.XCSSession)) {
      return Promise.reject();
    }
    const cookieCurrentRole = getCookie(CookieNames.CurrentRole);
    const cookieCompanyId = getCookie(CookieNames.CurrentCompanyId);
    const cookieUserProfileId = getCookie(CookieNames.UserProfileId);
    if (cookieCurrentRole && session && cookieCurrentRole === session?.currentRole) {
      return Promise.resolve({ data: session, status: 200 });
    }
    const params: ICookie = {
      currentRole: getCookie(CookieNames.CurrentRole),
    };
    if (cookieCompanyId) {
      params.currentCompanyId = cookieCompanyId;
    }
    if (cookieUserProfileId) {
      params.currentUserProfileId = cookieUserProfileId;
    }
    if (Object.values(params).find(Boolean)) {
      const { payload } = (await dispatch(updateSessionSync(params))) || {};
      if (!payload) {
        removeAllCookie();
        setSession(null);
        return Promise.reject();
      } else {
        const allRole: ICookie[] = AuthService.getAllRole(payload);
        setSession({
          ...(typeof payload === 'object' ? payload : {}),
          ...checkCurrentRole(payload as ISession),
          allRole,
        } as ISession);
        getPeopleProfile();
        getCompanyDetails();
        return Promise.resolve({ data: payload, status: 200 });
      }
    }
  };

  const getSession = async () => {
    const { payload } = (await dispatch(getSessionSync())) as any;
    if (!payload) {
      removeAllCookie();
      setSession(null);
      return Promise.reject();
    } else {
      setSession({
        ...session,
        ...checkCurrentRole(payload as ISession),
        expiresOn: payload.expiresOn,
      } as ISession);
      getPeopleProfile();
      getCompanyDetails();
      return Promise.resolve({ data: payload, status: 200 });
    }
  };

  const checkCurrentRole = (session: ISession) => {
    const role = {
      isSystemAdminAndNotParticipateInCurrentCompany: false,
      isCompanyAdminOfCurrentCompany: false,
      isParticipantOfCurrentCompany: false,
    };
    const currentCompanyIdCookie = getCookie(CookieNames.CurrentCompanyId);
    const currentRoleCookie = getCookie(CookieNames.CurrentRole);
    if (session?.values.systemAdmin && (!session?.values.role || !session?.values.role[currentCompanyIdCookie])) {
      role.isSystemAdminAndNotParticipateInCurrentCompany = true;
    }
    if (session?.values.role && session?.values.role[currentCompanyIdCookie]) {
      if (
        session?.values.role[currentCompanyIdCookie].indexOf(UserRole.COMPANY_ADMIN) !== -1 &&
        currentRoleCookie === UserRole.COMPANY_ADMIN
      ) {
        role.isCompanyAdminOfCurrentCompany = true;
      }
      if (
        session?.values.role[currentCompanyIdCookie].indexOf(UserRole.PARTICIPANT) !== -1 &&
        currentRoleCookie === UserRole.PARTICIPANT
      ) {
        role.isParticipantOfCurrentCompany = true;
      }
    }
    return role;
  };

  return (
    <AuthContext.Provider
      value={{
        session,
        setSession,
        getSession,
        updateSession,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export const useAuth = () => {
  return React.useContext(AuthContext);
};
export default AuthProvider;
