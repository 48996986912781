import { Box, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { Button } from 'common/components';
import BasicModal from 'common/components/BasicModal';
import { FC } from 'react';

type TSelectYourCompanyModalProps = {
  companyList: any[];
  setCompanyId: (companyId: string) => void;
  companyId: string;
  onClose: () => void;
  onCancel: () => void;
  isOpen: boolean;
};
const SelectYourCompanyModal: FC<TSelectYourCompanyModalProps> = ({
  companyList,
  setCompanyId,
  companyId,
  onClose,
  onCancel,
  isOpen,
}) => {
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCompanyId((event.target as HTMLInputElement).value);
  };
  return (
    <BasicModal isOpen={isOpen} className="w-full max-w-[750px]">
      <Box>
        <div className="text-center text-darkGreen pt-8">
          {
            'We found that your registered email is associated with more than one company. Please select the one you are setting up for.'
          }
        </div>
        <RadioGroup className="w-full mt-5" value={companyId} onChange={handleRadioChange}>
          <Grid container spacing={2}>
            {Boolean(companyList.length) &&
              companyList.map((item) => (
                <Grid item zero={12} sm={6} key={item.id}>
                  <FormControlLabel
                    className="border border-secondary border-solid rounded-lg w-full h-[62px] text-darkGreen"
                    value={item.id}
                    control={
                      <Radio
                        sx={{
                          color: (theme) => theme.palette.lightBeige,
                        }}
                        color="secondary"
                      />
                    }
                    label={item.companyName}
                  />
                </Grid>
              ))}
          </Grid>
        </RadioGroup>
        <div className="flex items-center justify-center gap-2 gap-2 w-full text-center mt-10">
          <>
            <Button
              variant="outlined"
              color="secondary"
              sx={{
                width: '100%',
                maxWidth: {
                  zero: '100%',
                  sm: '220px',
                },
              }}
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              disabled={!companyId}
              sx={{
                width: '100%',
                maxWidth: {
                  zero: '100%',
                  sm: '220px',
                },
              }}
              onClick={() => {
                onClose();
              }}
            >
              Confirm
            </Button>
          </>
        </div>
      </Box>
    </BasicModal>
  );
};
export default SelectYourCompanyModal;
