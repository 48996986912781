import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { IconButton, Slider as MuiSlider } from '@mui/material';
import { appPalette } from 'core/constants';
import React from 'react';
import { useEffect, useState } from 'react';

export const Slider = React.forwardRef<HTMLElement, any>((refProps, ref) => {
  const {
    setFormValue = () => {},
    className,
    value,
    onChange = () => {},
    onChangeCommitted = () => {},
    error,
    sx,
    ...props
  } = refProps;

  const [defaultValue, setDefaultValue] = useState<number[]>([0]);

  useEffect(() => {
    if (value) {
      setDefaultValue(typeof value === 'number' ? [value] : (value as number[]));
    } else {
      setDefaultValue([props.min as number]);
    }
    setFormValue(props.name, value || 0);
  }, [value]);

  const handleChangeValue = (event: Event, value: number | number[], activeThumb: number) => {
    setDefaultValue(typeof value === 'number' ? [value] : (value as number[]));
    onChange(event, value, activeThumb);
    setFormValue(props.name, value);
  };
  const handlePrevious = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (defaultValue[0] > (props.min as number)) {
      const tmp = [...defaultValue];
      tmp[0] = tmp[0] - (props.step as number);
      setDefaultValue(tmp);
      if (tmp.length === 1) {
        onChange({} as Event, tmp[0], 0);
        onChangeCommitted({} as Event, tmp[0]);
        setFormValue(props.name, tmp[0]);
      } else {
        onChange({} as Event, tmp, 0);
        onChangeCommitted({} as Event, tmp);
        setFormValue(props.name, tmp);
      }
    }
  };
  const handleNext = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (defaultValue[defaultValue.length - 1] < (props.max as number)) {
      const tmp = [...defaultValue];
      tmp[tmp.length - 1] = tmp[tmp.length - 1] + (props.step as number);
      setDefaultValue(tmp);
      if (tmp.length === 1) {
        onChange({} as Event, tmp[0], tmp.length - 1);
        onChangeCommitted({} as Event, tmp[0]);
        setFormValue(props.name, tmp[0]);
      } else {
        onChange({} as Event, tmp, tmp.length - 1);
        onChangeCommitted({} as Event, tmp);
        setFormValue(props.name, tmp);
      }
    }
  };

  const defaultProps = {
    ref,
    valueLabelDisplay: 'on',
    onChange: handleChangeValue,
    onChangeCommitted: onChangeCommitted,
    value: defaultValue.length === 1 ? defaultValue[0] : defaultValue,
    color: 'secondary',
    sx: {
      '& .MuiSlider-thumb': {
        width: 40,
        borderRadius: 10,
      },
      '& .MuiSlider-valueLabel': {
        top: 23,
        background: 'none',
        fontSize: 12,
      },
      ...sx,
    },
  };

  return (
    <div className={`flex items-center w-full color-primary ${props.disabled ? 'hover:cursor-not-allowed' : ''}`}>
      <IconButton disabled={props.disabled} onClick={handlePrevious} sx={{ marginRight: '10px' }}>
        <RemoveCircleOutline htmlColor={appPalette.beige} />
      </IconButton>
      <MuiSlider {...defaultProps} {...props} />
      <IconButton disabled={props.disabled} onClick={handleNext} sx={{ marginLeft: '10px' }}>
        <AddCircleOutline htmlColor={appPalette.beige} />
      </IconButton>
    </div>
  );
});
