import ExpandMore from '@mui/icons-material/ExpandMore';
import { FormHelperText, FormLabelProps, MenuItem, MenuItemProps } from '@mui/material';
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select';
import clsx from 'clsx';
import { appPalette } from 'core/constants';
import { forwardRef } from 'react';
import { FormControl } from '../FormControl';

export type SelectItem = {
  id?: string;
  value: any;
  label: any;
  props?: MenuItemProps;
  order?: number;
};

type SelectProps = {
  error?: any;
  data?: SelectItem[];
  registerForm?: any;
  hasValidate?: boolean;
  formLabelProps?: FormLabelProps;
  placeholder?: any;
  required?: boolean;
  formLabelStyle?: any;
} & MuiSelectProps<any>;

const findLabel = (data: SelectItem[], value: string | number) => {
  const item = data.find((item) => item.value === value);
  return item?.label || value;
};

export const Select = forwardRef(
  (
    {
      className,
      placeholder,
      data = [],
      defaultValue = '',
      label,
      registerForm,
      error,
      hasValidate,
      sx,
      formLabelProps,
      required = false,
      formLabelStyle = {},

      ...props
    }: SelectProps,
    ref
  ) => {
    return (
      <FormControl
        required={required}
        label={label}
        formLabelProps={formLabelProps}
        sx={{
          '.MuiFormLabel-root': {
            maxHeight: '16px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color: '#181C11 !important',
          },
        }}
        {...formLabelStyle}
      >
        <MuiSelect<string | number>
          fullWidth
          className={clsx(className)}
          inputRef={ref}
          displayEmpty
          error={!!error}
          defaultValue={defaultValue}
          IconComponent={ExpandMore}
          color="secondary"
          sx={(theme) => ({
            '.MuiOutlinedInput-input.MuiInputBase-input': {
              fontWeight: 600,
              color: (theme) => `${theme.palette.darkest.dark} !important`,
              '&::placeholder': {
                fontWeight: 600,
                fontSize: 13,
                color: (theme) => theme.palette.subTitle,
              },
            },
            backgroundColor: theme.palette.white,
            '.MuiSvgIcon-root': {
              color: (theme) => theme.palette.primaryColor,
            },
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: (theme) => theme.palette.borderColor,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: (theme) => theme.palette.borderHover,
            },
            '.Mui-disabled': {
              cursor: 'not-allowed !important',
            },
            '&.Mui-disabled': {
              WebkitTextFillColor: appPalette.beige,
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: (theme) => `${theme.palette.mediumGrey} !important`,
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: (theme) => `${theme.palette.mediumGrey} !important`,
              },
              '.MuiSvgIcon-root': {
                color: theme.palette.mediumGrey,
              },
            },
            ...sx,
          })}
          renderValue={(value) => {
            return (value === '' || value === null) && placeholder ? (
              typeof placeholder === 'string' ? (
                <div className="py-2 overflow-hidden text-mediumGrey text-[13px] opacity-50 overflow-ellipsis whitespace-nowrap">
                  {placeholder && String(placeholder)}
                </div>
              ) : (
                placeholder
              )
            ) : (
              <div className="py-2 overflow-hidden text-md overflow-ellipsis whitespace-nowrap text-darkest">
                {findLabel(data, value)}
              </div>
            );
          }}
          {...registerForm}
          {...props}
        >
          {data.length !== 0 ? (
            data?.map((item) => (
              <MenuItem
                key={item.id ? String(item.id) : String(item.value)}
                value={item.value}
                className="text-sm font-normal text-primary break-words	whitespace-normal"
                {...(item?.props || {})}
              >
                {item.label}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="">No properties</MenuItem>
          )}
        </MuiSelect>
        {!!error && <FormHelperText error={!!error}>{error}</FormHelperText>}
        {hasValidate && !error && <p className="h-4"></p>}
      </FormControl>
    );
  }
);
