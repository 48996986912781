import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ProfileDto } from 'core/models/profileDto.model';
import { TransactionResponse, TransactionType } from 'core/models/transactionDto.model';
import ApiService from 'core/services/api.service';
import { RootState } from 'states/store';

export interface FilterValue {
  transactionType: string;
  from: string;
  to: string;
  search: string;
  order?: any;
  field?: string;
  page?: number;
  pageSize?: number;
}

interface EmployeeTransactionsState {
  error: string;
  transactions: TransactionResponse;
  profile: ProfileDto | null;
  transactionType: TransactionType[];
  // filterValue: FilterValue;
  loading: boolean;
}

const initialState: EmployeeTransactionsState = {
  error: '',
  transactions: {
    content: [],
  },
  profile: null,
  transactionType: [],
  loading: false,
};

export const getEmployeeTransactionsAsync = createAsyncThunk(
  'employeeTransactions/getEmployeeTransactionsAsync',
  async ({ filter }: { filter?: FilterValue }): Promise<any> => {
    const response = await ApiService.getTransactions(filter);
    return response.data;
  }
);

export const getAccountTransactionTypeAsync = createAsyncThunk(
  'employeeTransactions/getAccountTransactionTypeAsync',
  async (): Promise<any> => {
    const response = await ApiService.getAccountTransactionType();
    return response.data;
  }
);

const employeeTransactionsSlice = createSlice({
  name: 'employeeTransactions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEmployeeTransactionsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEmployeeTransactionsAsync.fulfilled, (state, action) => {
        state.transactions = action.payload;
        state.loading = false;
      })
      .addCase(getEmployeeTransactionsAsync.rejected, (state) => {
        state.transactions = {
          content: [],
        };
        state.loading = false;
      })
      .addCase(getAccountTransactionTypeAsync.fulfilled, (state, action) => {
        state.transactionType = action.payload;
      });
  },
});

export const selectEmployeeTransaction = (state: RootState) => state.employeeTransactions.transactions;
export const selectTransactionTypeList = (state: RootState) => state.employeeTransactions.transactionType;
export const selectLoading = (state: RootState) => state.employeeTransactions.loading;

const employeeTransactionsReducer = employeeTransactionsSlice.reducer;
export default employeeTransactionsReducer;
