import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { REQUEST_STATUS } from 'core/constants/roles';
import PEPService from 'core/services/pep.service';

export interface IPlanAdmin {
  id: string;
  firstName: string;
  lastName: string;
  phone?: string;
  jobTitle?: string;
  email: string;
  title?: string;
  createdOn?: number;
}

interface IPepPlanAdminState {
  getPlanAdminsStatus: string;
  actionPlanAdminsStatus: string;
  admins: IPlanAdmin[];
}

const initialState: IPepPlanAdminState = {
  getPlanAdminsStatus: REQUEST_STATUS.IDLE,
  actionPlanAdminsStatus: REQUEST_STATUS.IDLE,
  admins: [],
};

export const getPepPlanAdminsAsync = createAsyncThunk('pepPlanAdmin/getPepPlanAdminsAsync', async (): Promise<any> => {
  const response = await PEPService.getPepPlanAdmins();
  return response.data;
});

export const updatePepPlanAdminsAsync = createAsyncThunk(
  'pepPlanAdmin/updatePepPlanAdminsAsync',
  async (body: any): Promise<any> => {
    const response = await PEPService.updatePepPlanAdmins(body);
    return response.data;
  }
);

const pepPlanAdminSlice = createSlice({
  name: 'pepPlanAdmin',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPepPlanAdminsAsync.pending, (state) => {
        state.getPlanAdminsStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(getPepPlanAdminsAsync.fulfilled, (state, action) => {
        state.getPlanAdminsStatus = REQUEST_STATUS.SUCCESS;
        state.admins = action.payload;
      })
      .addCase(getPepPlanAdminsAsync.rejected, (state, action) => {
        state.getPlanAdminsStatus = REQUEST_STATUS.ERROR;
      })

      .addCase(updatePepPlanAdminsAsync.pending, (state) => {
        state.actionPlanAdminsStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(updatePepPlanAdminsAsync.fulfilled, (state, action) => {
        state.actionPlanAdminsStatus = REQUEST_STATUS.SUCCESS;
        state.admins = action.payload;
      })
      .addCase(updatePepPlanAdminsAsync.rejected, (state, action) => {
        state.actionPlanAdminsStatus = REQUEST_STATUS.ERROR;
      });
  },
});

export default pepPlanAdminSlice.reducer;
