import { ThemeOptions, createTheme } from "@mui/material/styles";
import type {} from "@mui/x-data-grid/themeAugmentation";
import { LinkBehavior } from "common/components";
import { LinkProps } from "@mui/material/Link";

import "@mui/material/styles/createPalette";
declare module "@mui/material/styles" {
  interface CustomPalette {
    white: string;
    lightGrey: string;
    darkBlue: string;
    primaryColor: string;
    secondaryColor: string;
    lightGreen: string;
    green: string;
    normalGrey: string;
    darkGrey: string;
    darkGreen: string;
    mediumGrey: string;
    mediumGreen: string;
    black: string;
    lightBlack: string;
    darkBlack: string;
    orange: string;
    beige: string;
    accent: string;
    lightAccent: string;
    red: string;
    violet: string;
    purple: string;
    lightPurple: string;
    lightBeige: string;
    lighter: string;
    borderColor: string;
    borderHover: string;
    normalText: string;
    title: string;
    subTitle: string;
    checkBoxSecondary: string;
    checkBoxLightBeige: string;
    selected: string;
    button: string;
    frameBackground: string;
    label: string;
    disabled: string;
    icon: string;
    textLink: string;
    textLinkHover: string;
    tableHeading: string;
    tableContent: string;
    tabSelectedBackground: string;
    errorColor: string;
    placeHolder: string;
    dividerColor: string;
    deleteNotiColor: string;
    warningColor: string;
    active: string;
    new: string;
    approved: string;
    newLead: string;
    verified: string;
    onboarding: string;
    pending: string;
    invited: string;
    terminated: string;
    redPeach: string;
    bgTooltip: string;
    textTooltip: string;
  }

  interface Palette extends CustomPalette {
    darkest: Palette["primary"];
    superBlack: Palette["primary"];
  }
  interface PaletteOptions extends CustomPalette {
    darkest?: Palette["primary"];
    superBlack?: Palette["primary"];
  }
  interface BreakpointOverrides {
    zero: true;
    esm: true;
    sm: true;
    mobile360: true;
    mobile390: true;
    mobile414: true;
    lg2: true;
    xxl: true;
    fhd: true;
    "2k": true;
    "4k": true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    terminate: true;
  }
}

declare module "@mui/x-data-grid" {
  interface NoRowsOverlayPropsOverrides {
    label: string;
  }

  interface NoResultsOverlayPropsOverrides {
    label: string;
  }
}

export const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#3B471C",
    },
    secondary: {
      main: "#69AB00",
    },
    white: "#fff",
    lightGrey: "#EAEAEB",
    darkBlue: "#031526",
    primaryColor: "#3B471C",
    secondaryColor: "#69AB00",
    green: "#6FD1BE",
    normalGrey: "#717476",
    darkGrey: "#343D46",
    darkGreen: "#3B471C",
    mediumGrey: "#BCBCBD",
    mediumGreen: "#88D014",
    black: "#000000",
    lightBlack: "#283037",
    darkBlack: "#2C2D2E",
    orange: "#f58220",
    accent: "#048BBA",
    lightAccent: "#1DA1F2",
    red: "#FF4357",
    violet: "#1C165C",
    purple: "#660FF2",
    lightPurple: "#B4AFDA",
    lightGreen: "#E3F2E3",
    beige: "#9E9E91",
    lightBeige: "#F4F3E9",
    lighter: "#F5F5F5",
    borderColor: "#F4F3E9",
    borderHover: "#88D014",
    normalText: "#3B471C",
    title: "#3B471C",
    subTitle: "#9E9E91",
    checkBoxSecondary: "#69AB00",
    checkBoxLightBeige: "#F4F3E9",
    selected: "#69AB00",
    button: "#69AB00",
    frameBackground: "#E3F2E3",
    label: "#3B471C",
    disabled: "#BCBCBD",
    icon: "#69AB00",
    textLink: "#69AB00",
    textLinkHover: "#88D014",
    tableHeading: "#9E9E91",
    tableContent: "#3B471C",
    tabSelectedBackground: "#88D014",
    errorColor: "#d32f2f",
    placeHolder: "#BCBCBD",
    dividerColor: "#F4F3E9",
    deleteNotiColor: "#05396B",
    warningColor: "#fabc38",
    active: "#3EB175",
    new: "#048BBA",
    approved: "#69AB00",
    newLead: "#fabc38",
    verified: "#9E9E91",
    onboarding: "#FF005C",
    pending: "#FF005C",
    invited: "#fabc38",
    terminated: "#BCBCBD",
    redPeach: "#FF7575",
    darkest: {
      main: "#222917",
      light: "#222917",
      dark: "#222917",
      contrastText: "#222917",
    },
    superBlack: {
      main: "#181C11",
      light: "#181C11",
      dark: "#181C11",
      contrastText: "#181C11",
    },
    terminate: {
      main: "#181C11",
    },
    bgTooltip: "#222917",
    textTooltip: "#FCFAFA",
  },
  breakpoints: {
    values: {
      zero: 0,
      mobile360: 360,
      mobile390: 390,
      mobile414: 414,
      xs: 600,
      sm: 768,
      esm: 912,
      md: 1024,
      lg: 1280,
      lg2: 1366,
      xl: 1440,
      xxl: 1536,
      fhd: 1920,
      "2k": 2560,
      "4k": 3840,
    },
  },
} as ThemeOptions);

export default createTheme(theme, {
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
      styleOverrides: {
        root: {
          "&.MuiMenuItem-root.MuiTablePagination-menuItem.Mui-selected": {
            backgroundColor: "#69AB00",
          },
        },
      },
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          ".Mui-selected": {
            backgroundColor: "#69AB00 !important",
            color: "#fff",
            fontWeight: 500,
            webkitTransition:
              "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            transition:
              "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          display: "flex",
          alignItems: "center",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#3B471C",
          fontSize: "12px",
          lineHeight: "18px",
          fontWeight: 600,
        },
        asterisk: {
          color: "#FF4357",
          "&$error": {
            color: "#FF4357",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          lineHeight: "20px",
          fontWeight: "600",
          padding: "10px 24px",
          height: 40,
          borderRadius: "8px",
          fontFamily: `'Poppins', sans-serif`,
          "&:disabled": {
            cursor: "not-allowed",
            pointerEvents: "auto",
          },
          textTransform: "none",
        },
      },
      variants: [
        {
          props: { variant: "contained", color: "primary" },
          style: {
            color: "#fff",
            backgroundColor: "#3B471C",
            ":disabled": {
              color: "#fff",
              backgroundColor: "#E3F2E3",
              "&:hover": {
                backgroundColor: "#E3F2E3",
              },
            },
            "&:hover": {
              backgroundColor: "#3B471C",
            },
          },
        },
        {
          props: { variant: "contained", color: "secondary" },
          style: {
            color: "#fff",
            backgroundColor: "#69AB00",
            ":disabled": {
              color: "#fff",
              backgroundColor: "#E3F2E3",
              "&:hover": {
                backgroundColor: "#E3F2E3",
              },
            },
            "&:hover": {
              backgroundColor: "#69AB00",
            },
          },
        },
        {
          props: { variant: "outlined", color: "primary" },
          style: {
            color: "#3B471C",
            backgroundColor: "#fff",
            border: "1px solid #3B471C",
            ":disabled": {
              color: "#E3F2E3",
              borderColor: "#E3F2E3",
              "&:hover": {
                backgroundColor: "#fff",
                borderColor: "#E3F2E3",
              },
            },
            "&:hover": {
              backgroundColor: "#fff",
              borderColor: "#3B471C",
            },
          },
        },
        {
          props: { variant: "outlined", color: "secondary" },
          style: {
            color: "#69AB00",
            backgroundColor: "#E3F2E3",
            border: "1px solid #69AB00",
            ":disabled": {
              color: "#E3F2E3",
              borderColor: "#E3F2E3",
              backgroundColor: "#fff",
              "&:hover": {
                backgroundColor: "#fff",
                borderColor: "#E3F2E3",
              },
            },
            "&:hover": {
              borderColor: "#69AB00",
              backgroundColor: "#D0EDCE",
            },
          },
        },
        {
          props: { color: "terminate", variant: "outlined" },
          style: {
            color: "#FF005C",
            backgroundColor: "#fff",
            border: "1px solid #FF005C",
            ":disabled": {
              color: "#FFD9EA",
              borderColor: "#FFD9EA",
              "&:hover": {
                backgroundColor: "#fff",
                borderColor: "#FFD9EA",
              },
            },
            "&:hover": {
              borderColor: "#FF005C",
            },
          },
        },
        {
          props: { color: "terminate", variant: "contained" },
          style: {
            color: "#fff",
            backgroundColor: "#FF005C",
            ":disabled": {
              color: "#fff",
              backgroundColor: "#FFD9EA",
              "&:hover": {
                backgroundColor: "#FFD9EA",
              },
            },
            "&:hover": {
              backgroundColor: "#FF005C",
            },
          },
        },
      ],
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: 48,
          borderColor: "#BCBCBD",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            backgroundColor: "white",
            fontSize: "13px",
            lineHeight: "18px",
            fontFamily: `"Poppins", sans-serif`,
            fontWeight: 600,
          },
          "& .MuiOutlinedInput-input.MuiInputBase-input": {
            padding: "8px 16px",
            height: "32px",
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: "none",
          fontFamily: `'Poppins', sans-serif`,
        },
        columnHeaders: {
          border: "none",
          borderRadius: "8px",
        },
        cell: {
          border: "none",
          color: "#3B471C",
          fontWeight: 600,
          fontSize: "14px",
          lineHeight: "21px",
        },
        columnHeaderTitle: {
          color: "#9E9E91",
        },
        columnSeparator: {
          display: "none",
        },
      },
    },
  },
});
