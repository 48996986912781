export const downloadFile = (url: string, fileName: string = "download") => {
  // Create a new link
  const link = document.createElement("a");

  // Append to the document
  link.setAttribute("hidden", "");
  link.setAttribute("href", url);
  link.setAttribute("download", fileName);
  link.setAttribute("target", "_blank");

  document.body.appendChild(link);
  // Trigger the click event
  link.click();

  //   // Remove the element
  document.body.removeChild(link);
};
