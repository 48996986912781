import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ContributionDto,
  EmployerContributionResponse,
  ICheckLateContributionPayload,
  SubmitContributionPayload,
} from 'core/models/EmployerContributionDto.model';
import EmployerService from 'core/services/employer.service';
import { RootState } from 'states/store';

interface ILateContributionDataResponse {
  lateContribution: boolean;
  totalEmployee: number;
}

interface EmployerPayrollPreview {
  data: EmployerContributionResponse | null;
  contributions: ContributionDto[];
  isModalOpen: boolean;
  isLoading: boolean;
  lateContributionData?: ILateContributionDataResponse;
}

const initialState: EmployerPayrollPreview = {
  data: null,
  contributions: [],
  isModalOpen: false,
  isLoading: false,
  lateContributionData: undefined,
};

export const submitContributionsAsync = createAsyncThunk(
  'employerPayrollPreview/submitContributionsAsync',
  async ({ payload }: { payload: SubmitContributionPayload }) => {
    const response = await EmployerService.submitContributions(payload);
    return response;
  }
);

export const checkLateContributionAsync = createAsyncThunk(
  'employerPayrollPreview/checkLateContributionAsync',
  async ({ payload }: { payload: ICheckLateContributionPayload }) => {
    const response = await EmployerService.checkLateContribution(payload);
    return response;
  }
);

const employerPayrollPreviewSlice = createSlice({
  name: 'EmployerPayrollPreview',
  initialState,
  reducers: {
    setIsModalOpen: (state, action) => {
      state.isModalOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitContributionsAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(submitContributionsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isModalOpen = true;
      })
      .addCase(submitContributionsAsync.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(checkLateContributionAsync.fulfilled, (state, action) => {
        state.lateContributionData = action.payload.data;
      });
  },
});

export const { setIsModalOpen } = employerPayrollPreviewSlice.actions;

export const selectEmployerPayrollPreviewIsModalOpen = (state: RootState) => state.employerPayrollPreview.isModalOpen;
export const selectEmployerPayrollPreviewIsLoading = (state: RootState) => state.employerPayrollPreview.isLoading;
export const selectEmployerLateContributionData = (state: RootState) =>
  state.employerPayrollPreview.lateContributionData;
const employerPayrollPreviewReducer = employerPayrollPreviewSlice.reducer;
export default employerPayrollPreviewReducer;
