import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { REQUEST_STATUS } from 'core/constants/roles';
import { EmployeeQuestionnairesDto } from 'core/models/EmployeeQuestionnairesDto.model';
import ApiService from 'core/services/api.service';
import EmployeeService, { getAnswers, updateAnswer } from 'core/services/employee.service';

interface IEmployerAccountsState {
  getActiveAnswerStatus: string;
  updateActiveAnswerStatus: string;
  getActivePortfoliosStatus: string;
  createActiveAnswerStatus: string;
  error: any;
  activeAnswer: any;
  savedAnswer: any;
  questionnaires: EmployeeQuestionnairesDto | null;
  activePortfolios: any[];
}

const initialState: IEmployerAccountsState = {
  getActiveAnswerStatus: REQUEST_STATUS.IDLE,
  updateActiveAnswerStatus: REQUEST_STATUS.IDLE,
  getActivePortfoliosStatus: REQUEST_STATUS.IDLE,
  createActiveAnswerStatus: REQUEST_STATUS.IDLE,
  error: null,
  activeAnswer: null,
  savedAnswer: null,
  questionnaires: null,
  activePortfolios: [],
};

export const createActiveAnswerAsync = createAsyncThunk(
  'employeeAccounts/createActiveAnswerAsync',
  async (profileId: string): Promise<any> => {
    const response = await ApiService.createAnswerData(profileId);
    return response.data;
  }
);

export const getActiveAnswerAsync = createAsyncThunk(
  'employeeAccounts/getActiveAnswerAsync',
  async (profileId: string): Promise<any> => {
    const response = await getAnswers(profileId);
    return response;
  }
);

export const updateActiveAnswerAsync = createAsyncThunk(
  'employeeAccounts/updateActiveAnswerAsync',
  async (payload: any): Promise<any> => {
    const response = await updateAnswer(payload);
    return response;
  }
);

export const getQuestionnairesAsync = createAsyncThunk(
  'employeeAccounts/getQuestionnairesAsync',
  async (): Promise<any> => {
    const response = await EmployeeService.getQuestionnaires();
    return response;
  }
);

export const getActivePortfoliosAsync = createAsyncThunk(
  'employeeAccounts/getPortfoliosAsync',
  async (): Promise<any> => {
    const response = await EmployeeService.getPortfolios();
    return response;
  }
);

export const changeAgeBasedPortfolioAsync = createAsyncThunk(
  'employeeAccounts/changeAgeBasedPortfolioAsync',
  async (payload: any): Promise<any> => {
    const response = await EmployeeService.changeAgeBasedPortfolio({ payload });
    return response;
  }
);

const employeeAccountsSlice = createSlice({
  name: 'employeeAccounts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getActiveAnswerAsync.pending, (state) => {
        state.getActiveAnswerStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(getActiveAnswerAsync.fulfilled, (state, action) => {
        state.getActiveAnswerStatus = REQUEST_STATUS.SUCCESS;
        state.activeAnswer = action.payload;
        state.savedAnswer = action.payload;
        if (action.payload === '') {
          state.activeAnswer = { answers: Array(104).fill(0) };
          state.savedAnswer = { answers: Array(104).fill(0) };
        }
      })
      .addCase(getActiveAnswerAsync.rejected, (state, action) => {
        state.getActiveAnswerStatus = REQUEST_STATUS.ERROR;
      })

      .addCase(updateActiveAnswerAsync.pending, (state) => {
        state.updateActiveAnswerStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(updateActiveAnswerAsync.fulfilled, (state, action) => {
        state.updateActiveAnswerStatus = REQUEST_STATUS.SUCCESS;
        state.activeAnswer = action.payload;
      })
      .addCase(updateActiveAnswerAsync.rejected, (state, action) => {
        state.updateActiveAnswerStatus = REQUEST_STATUS.ERROR;
      })

      .addCase(getActivePortfoliosAsync.pending, (state) => {
        state.getActivePortfoliosStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(getActivePortfoliosAsync.fulfilled, (state, action) => {
        state.getActivePortfoliosStatus = REQUEST_STATUS.SUCCESS;
        state.activePortfolios = action.payload;
      })
      .addCase(getActivePortfoliosAsync.rejected, (state, action) => {
        state.getActivePortfoliosStatus = REQUEST_STATUS.ERROR;
      })

      .addCase(getQuestionnairesAsync.fulfilled, (state, action) => {
        state.questionnaires = action.payload;
      })

      .addCase(createActiveAnswerAsync.pending, (state) => {
        state.createActiveAnswerStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(createActiveAnswerAsync.fulfilled, (state, action) => {
        state.createActiveAnswerStatus = REQUEST_STATUS.SUCCESS;
        state.activeAnswer = action.payload;
      })
      .addCase(createActiveAnswerAsync.rejected, (state, action) => {
        state.createActiveAnswerStatus = REQUEST_STATUS.ERROR;
      });
  },
});

const employeeAccountsReducer = employeeAccountsSlice.reducer;
export default employeeAccountsReducer;
