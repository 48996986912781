const BellIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_157_4753)">
        <path
          d="M9.99992 18.8333C10.9166 18.8333 11.6666 18.0833 11.6666 17.1667H8.33325C8.33325 18.0833 9.08325 18.8333 9.99992 18.8333ZM14.9999 13.8333V9.66668C14.9999 7.10834 13.6416 4.96668 11.2499 4.40001V3.83334C11.2499 3.14168 10.6916 2.58334 9.99992 2.58334C9.30825 2.58334 8.74992 3.14168 8.74992 3.83334V4.40001C6.36659 4.96668 4.99992 7.10001 4.99992 9.66668V13.8333L3.33325 15.5V16.3333H16.6666V15.5L14.9999 13.8333ZM13.3333 14.6667H6.66659V9.66668C6.66659 7.60001 7.92492 5.91668 9.99992 5.91668C12.0749 5.91668 13.3333 7.60001 13.3333 9.66668V14.6667Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_157_4753">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BellIcon;
