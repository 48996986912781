import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
type TProps = {
  value: string | "total" | "ytd";
  onChange: (val: string) => void | Promise<void>;
  disableBorder?: boolean;
  clickable?: boolean;
  labels?: any[];
};
const defaults = [
  {
    key: "total",
    label: "All time",
  },
  {
    key: "ytd",
    label: "YTD",
  },
];
export default function TotalYTDToggleButton(props: TProps) {
  const [subType, setSubType] = useState(props.value || "total");
  useEffect(() => {
    props.onChange(subType);
  }, [subType]);
  const isTotal = subType === "total";
  const selectedStyleProps = {
    backgroundColor: "#3B471C",
    maxWidth: "64px",
    maxHeight: "19px",
    borderRadius: "25px",
    fontSize: "10px",
    fontWeight: 600,
    lineHeight: "15px",
    whiteSpace: "nowrap",
    boxShadow: "none",
    marginBottom: "2px",
  };
  const deSelectedStyleProps = {
    backgroundColor: "transparent",
    color: "#75756F",
    maxWidth: "64px",
    maxHeight: "19px",
    borderRadius: "25px",
    fontSize: "10px",
    fontWeight: 500,
    lineHeight: "15px",
    whiteSpace: "nowrap",
    boxShadow: "none",
    marginBottom: "2px",
  };
  const renderTitle = (key: string) => {
    if (props?.labels) {
      const item = props?.labels?.find((f) => f.key === key);
      if (item) {
        return item?.label;
      }
    }
    return defaults.find((f) => f.key === key)?.label || key;
  };
  const isClickableProps = (): any => {
    if (props?.clickable === undefined || props?.clickable === true) return {};
    return {
      cursor: "not-allowed",
      pointerEvents: "none",
    };
  };
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        cursor:
          props?.clickable === undefined || props?.clickable === true
            ? "auto"
            : "not-allowed",
      }}
    >
      <Box
        sx={{
          minWidth: "117px",
          maxHeight: "27px",
          border: `1px solid ${props.disableBorder ? "#f5f3e8" : "#E0DEC9"}`,
          borderRadius: "25px",
          backgroundColor: "#f5f3e8",
        }}
      >
        <Box mx={"2px"}>
          <Button
            variant={isTotal ? "contained" : "text"}
            sx={{
              ...(isTotal ? selectedStyleProps : deSelectedStyleProps),
              ...isClickableProps(),
            }}
            onClick={() => setSubType("total")}
          >
            {renderTitle("total")}
          </Button>
          <Button
            variant={!isTotal ? "contained" : "text"}
            sx={{
              ...(!isTotal ? selectedStyleProps : deSelectedStyleProps),
              ...isClickableProps(),
            }}
            onClick={() => setSubType("ytd")}
          >
            {renderTitle("ytd")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
