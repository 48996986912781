interface ICounterProps {
  disable: boolean;
  value: number;
  onChange: (newValue: number) => void;
}

export const Counter = ({ disable, value, onChange }: ICounterProps) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let finalValue = 0;
    let wholeNewValue = +e.target.value;

    if (wholeNewValue) {
      let newValue = +e.target.value[+e.target.value.length - 1];
      finalValue = wholeNewValue === 10 || wholeNewValue % 10 === 0 ? 10 : newValue;
    } else {
      finalValue = 1;
    }

    onChange(finalValue);
  };

  return (
    <div
      className={`w-28 p-[8px] flex justify-between content-center border rounded-lg border-solid ${
        disable ? 'cursor-not-allowed text-mediumGrey border-mediumGrey' : 'cursor-pointer border-primary text-primary'
      }`}
    >
      <div
        className={`w-5 h-5 flex justify-center items-center border-2 rounded-full  border-solid text-2xl font-bold  select-none  `}
        onClick={() => !disable && onChange(value > 1 ? value - 1 : value)}
      >
        -
      </div>
      <input
        color="primary"
        type="number"
        className="w-6  y text-center outline-none border-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
        value={value}
        onChange={handleOnChange}
      />

      <div
        className={`w-5 h-5 flex justify-center items-center border-2 rounded-full border-solid text-xl font-bold select-none`}
        onClick={() => !disable && onChange(value < 10 ? value + 1 : value)}
      >
        +
      </div>
    </div>
  );
};
