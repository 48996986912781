import { AddNewBusinessPayload } from 'components/cpaManageClients/ModalInviteClients';
import publicApi from './utils/HttpRequest';
import { getApiPath } from './utils/routesUtils';
import { API } from './utils/app.routes';

const getSummary = () => {
  return publicApi.get('/api/corp/summary');
};

const getClients = (params: any) => {
  const path: string = getApiPath({ path: '/api/corp/business' }, params);
  return publicApi.get(path);
};

const getClientsActive = () => {
  return publicApi.get(`/api/corp/business/ACTIVE`);
};

const sendInvites = (params: { data: any[]; resend: boolean }) => {
  return publicApi.post(`/api/corp/business/resend`, params);
};

const terminateClients = (params: { companyIds: any[] }) => {
  return publicApi.patch('/api/corp/business/_DELETE', params);
};

const getCorpPlanAdmins = () => {
  return publicApi.get('/api/corp/admins');
};

const updateCorpPlanAdmins = (body: any) => {
  return publicApi.patch('/api/corp/admins', body);
};

function addNewBusiness({ payload }: { payload: AddNewBusinessPayload }): Promise<any> {
  const path: string = getApiPath(API.CONNECT_FINCH_CORP);

  return publicApi.put(path, payload);
}

function connectFinchCorp({
  payload,
}: {
  payload: {
    finch: {
      authorizationCode: string;
    };
  };
}): Promise<any> {
  const path: string = getApiPath(API.CONNECT_FINCH_CORP);

  return publicApi.post(path, payload);
}

const CORPService = {
  getSummary,
  getClients,
  getClientsActive,
  sendInvites,
  terminateClients,
  getCorpPlanAdmins,
  updateCorpPlanAdmins,
  addNewBusiness,
  connectFinchCorp,
};

export default CORPService;
