import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { REQUEST_STATUS } from 'core/constants/roles';
import { AddNewBusinessPayload } from 'components/cpaManageClients/ModalInviteClients';
import CORPService from 'core/services/corp.service';
import { IPlanAdmin } from './pepPlanAdminSlice';
interface ICorpPlanAdminState {
  getPlanAdminsStatus: string;
  actionPlanAdminsStatus: string;
  admins: IPlanAdmin[];
  connectFinchCorpStatus: string;
  addNewBusinessStatus: string;
}

const initialState: ICorpPlanAdminState = {
  getPlanAdminsStatus: REQUEST_STATUS.IDLE,
  actionPlanAdminsStatus: REQUEST_STATUS.IDLE,
  admins: [],
  connectFinchCorpStatus: REQUEST_STATUS.IDLE,
  addNewBusinessStatus: REQUEST_STATUS.IDLE,
};

export const getCorpPlanAdminsAsync = createAsyncThunk(
  'corpPlanAdminSlice/getCorpPlanAdminsAsync',
  async (): Promise<any> => {
    const response = await CORPService.getCorpPlanAdmins();
    return response.data;
  }
);

export const updateCorpPlanAdminsAsync = createAsyncThunk(
  'corpPlanAdminSlice/updateCorpPlanAdminsAsync',
  async (body: any): Promise<any> => {
    const response = await CORPService.updateCorpPlanAdmins(body);
    return response.data;
  }
);

export const addNewBusinessAsync = createAsyncThunk(
  'corpPlanAdminSlice/addNewBusinessAsync',
  async ({ payload }: { payload: AddNewBusinessPayload }): Promise<any> => {
    const response = await CORPService.addNewBusiness({ payload });
    return response.data;
  }
);

export const connectFinchCorpAsync = createAsyncThunk(
  'corpPlanAdminSlice/connectFinchCorpAsync',
  async ({
    payload,
  }: {
    payload: {
      finch: {
        authorizationCode: string;
      };
    };
  }): Promise<any> => {
    const response = await CORPService.connectFinchCorp({ payload });
    return response.data;
  }
);

const corpPlanAdminSlice = createSlice({
  name: 'corpPlanAdminSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCorpPlanAdminsAsync.pending, (state) => {
        state.getPlanAdminsStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(getCorpPlanAdminsAsync.fulfilled, (state, action) => {
        state.getPlanAdminsStatus = REQUEST_STATUS.SUCCESS;
        state.admins = action.payload;
      })
      .addCase(getCorpPlanAdminsAsync.rejected, (state) => {
        state.getPlanAdminsStatus = REQUEST_STATUS.ERROR;
      })

      .addCase(updateCorpPlanAdminsAsync.pending, (state) => {
        state.actionPlanAdminsStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(updateCorpPlanAdminsAsync.fulfilled, (state, action) => {
        state.actionPlanAdminsStatus = REQUEST_STATUS.SUCCESS;
        state.admins = action.payload;
      })
      .addCase(updateCorpPlanAdminsAsync.rejected, (state) => {
        state.actionPlanAdminsStatus = REQUEST_STATUS.ERROR;
      })
      .addCase(connectFinchCorpAsync.pending, (state) => {
        state.connectFinchCorpStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(connectFinchCorpAsync.fulfilled, (state) => {
        state.connectFinchCorpStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(connectFinchCorpAsync.rejected, (state) => {
        state.connectFinchCorpStatus = REQUEST_STATUS.ERROR;
      })
      .addCase(addNewBusinessAsync.pending, (state) => {
        state.addNewBusinessStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(addNewBusinessAsync.fulfilled, (state) => {
        state.addNewBusinessStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(addNewBusinessAsync.rejected, (state) => {
        state.addNewBusinessStatus = REQUEST_STATUS.ERROR;
      });
  },
});

export default corpPlanAdminSlice.reducer;
