import { styled } from '@mui/material';
import { DataGrid, DataGridProps, GridSlotsComponentsProps, NoRowsOverlayPropsOverrides } from '@mui/x-data-grid';
import FileXIcon from 'assets/SVGIcons/FileX';
import React from 'react';
import TableWrapper from './TableWrapper';

type Props = DataGridProps & {
  emptyLocaleText?: string;
};

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  color: theme.palette.mediumGrey,
}));

export function CustomNoRowsOverlay(props: GridSlotsComponentsProps & NoRowsOverlayPropsOverrides) {
  return (
    <StyledGridOverlay>
      <FileXIcon />
      <span className="text-mediumGrey text-sm font-semibold mt-3.5">{props.label}</span>
    </StyledGridOverlay>
  );
}

export const Table: React.FC<Props> = ({
  rows,
  columns,
  sx,
  emptyLocaleText = 'No records found',
  initialState = {},
  ...props
}) => {
  return (
    <TableWrapper>
      <DataGrid
        getEstimatedRowHeight={() => 100}
        getRowHeight={() => 'auto'}
        sx={{
          width: '100%',
          '.MuiCheckbox-root': {
            color: (theme) => `${theme.palette.lightBeige} !important`,
          },
          '.MuiDataGrid-cellCheckbox.MuiDataGrid-cellCheckbox': {
            outline: 'none',
            border: 'none',
          },
          '.Mui-checked': {
            color: (theme) => `${theme.palette.secondaryColor} !important`,
          },
          '& .MuiDataGrid-row': {
            borderRadius: '8px',
            boxShadow: (theme) => `inset 0 0 0 1px ${theme.palette.borderColor}`,
          },
          '& .MuiDataGrid-cell': {
            color: (theme) => theme.palette.primaryColor,
            fontWeight: 600,
            '&:focus': {
              outline: 'none',
            },
          },
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
            py: '6px',
          },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
            py: '16px',
          },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
            py: '22px',
          },
          '& .MuiDataGrid-columnHeader': {
            '&:focus,:focus-within': {
              outline: 'none',
            },
          },
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-columnHeader': {
            height: '56px',
          },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-columnHeader': {
            height: '56px',
          },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-columnHeader': {
            height: '56px',
          },
          '& .MuiDataGrid-footerContainer': {
            border: 'none',
            '.MuiTablePagination-toolbar': {
              color: (theme) => theme.palette.normalText,
            },
            '.MuiTablePagination-selectIcon': {
              color: (theme) => theme.palette.normalText,
            },
          },
          '.MuiDataGrid-overlayWrapper': {
            height: !rows.length ? 400 : 0,
          },
          '.MuiDataGrid-overlayWrapperInner': {
            height: !rows.length ? '100% !important' : 0,
          },
          '.MuiDataGrid-virtualScrollerContent': {
            display: rows.length > 0 ? 'block' : 'none',
          },
          ...sx,
        }}
        autoHeight={true}
        disableRowSelectionOnClick={true}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
              page: 0,
            },
          },
          ...initialState,
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        getRowSpacing={() => ({
          bottom: 10,
        })}
        hideFooter={props.paginationMode === 'server' ? (props.rowCount || 0) <= 10 : rows.length <= 10}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          noResultsOverlay: CustomNoRowsOverlay,
        }}
        slotProps={{
          noRowsOverlay: { label: emptyLocaleText },
          noResultsOverlay: { label: emptyLocaleText },
          loadingOverlay: {
            sx: {
              '.MuiCircularProgress-root': {
                color: (theme) => theme.palette.secondaryColor,
              },
            },
          },
        }}
        {...props}
      />
    </TableWrapper>
  );
};
