import * as React from 'react';
import { TextField } from 'common/components';
import { NumberFormatBaseProps, NumericFormat } from 'react-number-format';

export const TextCurrency = React.forwardRef<HTMLInputElement, NumberFormatBaseProps<any>>(({ ...restProps }, ref) => {
  return (
    <NumericFormat
      getInputRef={ref}
      customInput={TextField}
      thousandSeparator={true}
      decimalScale={2}
      prefix={'$'}
      {...restProps}
    />
  );
});
