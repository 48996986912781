export const API = {
  LOGIN: {
    path: "/api/sessions",
  },
  EMPLOYEE_SIGN_UP: {
    path: "/api/people/token/:token",
  },
  EMPLOYEE_SIGN_UP_WITHOUT_TOKEN: {
    path: "/api/people/:companyId/employee",
  },
  PING_SESSION: {
    path: "/api/sessions/pingSession",
  },
  FORGOT_USERNAME: {
    path: "/api/people/credentials",
  },
  PEOPLE: {
    path: "/api/people",
  },
  PEOPLE_BY_EMAIL: {
    path: "/api/people/fromemail/:email",
  },
  RE_CAPTCHA_SITE_KEY: {
    path: "/api/people/reCaptchaSiteKey",
  },
  LAST_LOGIN: {
    path: "/api/logins/:id/_LAST",
  },
  PROFILE: {
    path: "/api/profiles",
  },
  COMPANY_PEOPLE: {
    path: "/api/companies/people",
  },
  RETRIEVE_COMPANY_NAMES_AND_IDS: {
    path: "/api/companies/retrieveCompanyNamesAndIDs",
  },
  GET_EMPLOYEE_BY_COMPANY_ID: {
    path: "/api/profiles/:id/getEmployeeByCompanyId",
  },
  GET_PENDING_EMPLOYEE_BY_COMPANY_ID: {
    path: "/api/pendingUsers/:id/getEmployeeByCompanyId",
  },
  GET_ACCOUNT_DETAILS: {
    path: "/api/accounts/getAccountDetails",
  },
  GET_EMPLOYEE_DETAILS: {
    path: "/api/profiles/:personId",
  },
  PENDING_EMPLOYEE_DETAILS: {
    path: "/api/pendingUsers/:pendingUserId",
  },
  PENDING_EMPLOYEE: {
    path: "/api/pendingUsers",
  },
  VALIDATE_ACCOUNT_NUMBER: {
    path: "/api/accounts/validateAccountNumber",
  },
  UPDATE_ACCOUNT_DETAIL: {
    path: "/api/accounts/updateAccountdetail",
  },
  COMPANIES: {
    path: "/api/companies/:id",
  },
  COMPLETE_FINCH_AUTH: {
    path: "/api/companies/:id/finch",
  },
  DISCONNECT_FINCH: {
    path: "/api/companies/:id/disconnectFinch",
  },
  SYNC_EMPLOYEE_WITH_FINCH: {
    path: "/api/companies/:id/syncEmployees",
  },
  COMPANIES_STATEMENT: {
    path: "/api/companies/statements",
  },
  START_STOP_ELIGIBILITY: {
    path: "/api/companies/:id/updateEligibility/:action",
  },
  FORM_FILING: {
    path: "/api/companies/:companyId/ERISA/:page",
  },
  CREATE_ERISA_BOND: {
    path: "/api/companies/:companyId/createErisaBond",
  },
  LAST_CASH_JOURNAL_FILE: {
    path: "/api/files/LATEST_CASH_JOURNAL/:id",
  },
  GET_CASH_JOURNAL_FILE: {
    path: "/api/files/GET_CASH_JOURNAL/:id",
  },
  AWS_FILE: {
    path: "/api/aws/files/:params",
  },
  FILE: {
    path: "/api/files/:params",
  },
  GET_PAYROLL_CONTRIBUTION: {
    path: "/api/contributions/getPayrollcontribution/:year/:id",
  },
  CREATE_CASH_JOURNAL_FILE: {
    path: "/api/contributions/createCashJournalFile/:id",
  },
  COMPANY: {
    path: "/api/companies",
  },
  GET_ALL_REPORTS: {
    path: "/api/reports/getAllReports",
  },
  GET_ACTIVE_PLAN_PARTICIPANT: {
    path: "/api/profiles/list/:companyId",
  },
  GET_YTD_CONTRIBUTIONS: {
    path: "/api/reports/ytdContributions",
  },
  GET_SUMMARY_REPORT: {
    path: "/api/reports/summaryReport",
  },
  GET_ACCOUNT_BALANCE: {
    path: "/api/reports/accountBalance",
  },
  GET_ALL_CONTRIBUTION: {
    path: "/api/reports/getAllContributions",
  },
  GET_CONTRIBUTION: {
    path: "/api/contributions",
  },
  NOTIFICATIONS: {
    path: "/api/notifications",
  },
  RETRIEVE_EMPLOYEE_DETAIL: {
    path: "/api/companies/retrieveEmployeeDetails/:id?search=:search",
  },
  PEOPLE_CREDENTIALS: {
    path: "/api/people/credentials",
  },
  CREATE_CREDENTIALS: {
    path: "/api/people/credentials/token/{token}",
  },
  ACCOUNTS: {
    path: "/api/accounts",
  },
  TRANSACTIONS: {
    path: "/api/accounts/transactions",
  },
  ACCOUNT_TRANSACTION_TYPE: {
    path: "/api/accounts/accocuntTrasactionType",
  },
  ANNUAL_LIMITS: {
    path: "/api/contributions/_limits",
  },
  GET_PAYROLL_CONTRIBUTION_DETAIL: {
    path: "/api/contributions/:payrollContributionId?contributionType=total&order=ASC",
  },
  REMOVE_PAYROLL_CONTRIBUTION: {
    path: "/api/contributions/removePayrollContribution/:id",
  },
  UPDATE_PAYROLL_CONTRIBUTION: {
    path: "/api/contributions/editPayrollContribution",
  },
  GET_ACTIVE_ANSWERS: {
    path: "/api/answers/:profileId/_active",
  },
  UPDATE_RISK_QUESTION: {
    path: "/api/answers",
  },
  CREATE_ANSWERS_DATA: {
    path: "/api/answers/:profileId",
  },
  EMPLOYER_STATEMENTS: {
    path: "/api/companies/statements",
  },
  EMPLOYEE_STATEMENTS: {
    path: "/api/profiles/:id/statements/:year",
  },
  GET_QUOTE: {
    path: "/api/quote",
  },
  GET_EMPLOYEE_STATUS_REPORT: {
    path: "/api/profiles/employeeStatusReport/:companyId",
  },
  GET_EMPLOYEE_STATUS_COUNT: {
    path: "/api/profiles/statusCount/:companyId",
  },
  GET_COMPANY_HOLDINGS: {
    path: "/api/companies/:companyId/holdings",
  },
  GET_CASH_BALANCE: {
    path: "/api/companies/:companyId/cash",
  },
  OTHER_MATCHING_FORMULAVALUES: {
    path: "/api/plans/getOtherMatchingFormulaValues",
  },
  CONTRIBUTIONS_LIMITS: {
    path: "/api/contributions/_limits",
  },
  PLAN: {
    path: "/api/plans",
  },
  PLAN_DETAIL: {
    path: "/api/plans/:id",
  },
  UPDATE_PLAN_STATUS: {
    path: "/api/plans/updateStatus?planId=:planId&planStatus=:status",
  },
  UPLOAD_FILE: {
    path: "/api/files",
  },
  UPDATE_UPLOAD_STATUS: {
    path: "/api/files/:fileId",
  },
  GET_EMPLOYEE_ROLLOVER_INFO: {
    path: "api/accounts/:id/getEmployeeRollOverInfo",
  },
  GET_COMPANY_ADMIN_BY_COMPANY_ID: {
    path: "/api/people/:id/:action/getCompanyAdminsByCompanyId",
  },
  GET_ALL_EXISTING_PERSONS: {
    path: "/api/people/search",
  },
  NEW_COMPANY_ADMIN: {
    path: "/api/people/:id/save/manageCompanyAdmin",
  },
  GET_COMPANY_ADMIN_BY_NAME: {
    path: "/api/people/search/:name",
  },
  ADD_COMPANY_ADMIN: {
    path: "/api/people/:companyId/:companyAdminId/addCompanyAdmin",
  },
  MANAGE_COMPANY_ADMIN: {
    path: "/api/people/:companyId/:action/manageCompanyAdmin",
  },
  UPDATE_PAYCHECK_DATE: {
    path: "/api/contributions/updatePaycheckDate/:lastPayrollId",
  },
  GET_ZERO_CONTRIBUTION: {
    path: "/api/contributions/zeroContribution",
  },
  GET_PREVIEW_CONTRIBUTIONS: {
    path: "/api/contributions/preview/:id",
  },
  SUBMIT_CONTRIBUTIONS: {
    path: "/api/contributions/submitContributions",
  },
  UPDATE_CONTRIBUTION_BY_FIELD: {
    path: "/api/contributions/:id/:field",
  },
  SAVE_ANNUAL_LIMIT_EXCEEDED_CONTRIBUTIONS: {
    path: "/api/contributions/saveAnnualLimitExceededContributions",
  },
  GET_PLAN_FILES: {
    path: "/api/files/:planType/:id",
  },
  GET_TRUST_REPORTS: {
    path: "/api/trust-report/:companyId",
  },
  TRUST_REPORTS: {
    path: "/api/trust-report",
  },
  UPDATE_PROFIT_SHARING_CHECK: {
    path: "/api/contributions/:id/PROFITSHARINGCHK",
  },
  SEARCH_FIELD: {
    path: "/api/people/search/:fieldName/:value",
  },
  GENERIC_FILE_UPLOAD: {
    path: "/api/files/genericFileUpload/NEW",
  },
  UPDATE_CONTRIBUTIONS_FROM_FILE: {
    path: "/api/contributions/:fileId",
  },
  PEOPLE_EMPLOYER: {
    path: "/api/people/employer",
  },
  PLAN_TRANSFER: {
    path: "/api/plans/:companyId/transfer",
  },
  GET_COMPANY_BY_PERSON: {
    path: "/api/people/invitedProfiles",
  },
  GET_LATEST_DRAFT_TRANSFER_PLAN: {
    path: "/api/plans/:companyId/draftTransfer/latest",
  },
  UPDATE_USER_PROFILE_STATUS: {
    path: "/api/profiles/:profileId/status/:status/false",
  },
  CHECK_TOKEN_VALID: {
    path: "/api/profiles/:token/workEmail",
  },
  COMPLETE_MANAGE_EMPLOYEE_FILE_SUBMISSION: {
    path: "/api/people/completeEmployeeUpload/:fileId",
  },
  GET_ALL_EMPLOYEES_FOR_DOWNLOAD: {
    path: "/api/profiles/download",
  },
  GET_ALL_EMPLOYER_REPORT_FOR_DOWNLOAD: {
    path: "/api/reports/download",
  },
  GET_ALL_TRANSACTION_FOR_DOWNLOAD: {
    path: "/api/accounts/download",
  },
  PLAID_LINK_TOKEN: {
    path: "/api/people/plaidLinkToken",
  },
  GRANT_PLAID_PERMISSION: {
    path: "/api/people/plaidGrantPermission",
  },
  UPDATE_STATUS_PROFILE_ACTIVE: {
    path: "/api/profiles/employee/active",
  },
  FIND_PERSON: {
    path: "/api/people/fromemail/:email",
  },
  LOAN_TASKS: {
    path: "/api/loans/:companyId/tasks",
  },
  UPDATE_LOAN_STATUS: {
    path: "/api/loans/updateStatus",
  },
  DISTRIBUTION_BASIC_INFO: {
    path: "/api/distributions/basicInfo",
  },
  GET_DRAFT_DISTRIBUTION: {
    path: "/api/distributions",
  },
  DISTRIBUTION_HISTORY: {
    path: "/api/distributions/:userProfileId/history",
  },
  DISTRIBUTIONS: {
    path: "/api/distributions",
  },
  GET_EMPLOYEE_ROLLOVER_INFO_LIST: {
    path: "/api/accounts/:userProfileId/getEmployeeRollOverInfoList",
  },
  DELETE_EMPLOYEE_ROLLOVER_ACCOUNT_STAMENT: {
    path: "/api/accounts/employeeRollOver/:rolloverId",
  },
  CALC_NET_AMOUNT: {
    path: "/api/distributions/calculateNetAmount",
  },
  CONVERT_FT_WILLIAM: {
    path: "/api/plans/:companyId/convertToFtWilliam",
  },
  MASTER_PLAN: {
    path: "/api/plans/master",
  },
  CPA_CLIENT_STORE_SIGNATURE: {
    path: "/api/cpa/clients/_ACCEPT/:token",
  },
  STORE_SIGN_PLAN_DOC: {
    path: "/api/plans/:companyId/signFtWilliamDoc",
  },
  CREATE_PEP_MANAGE_PLAN: {
    path: "/api/companies/sysAdmin/_CREATE",
  },
  GET_PEP_MANAGE_PLAN_DETAIL: {
    path: "/api/companies/sysAdmin/:companyId",
  },
  UPDATE_PEP_MANAGE_PLAN: {
    path: "/api/companies/sysAdmin/_UPDATE",
  },
  CREATE_AND_UPDATE_DRAFT_MANAGE_PLAN: {
    path: "/api/companies/sysAdmin/_DRAFT",
  },
  UPDATE_PEP_MANAGE_PLAN_ACTIVE: {
    path: "/api/plans/master/:companyId/_ACTIVE",
  },
  GET_PDF_DOCS: {
    path: "/api/plans/forms",
  },
  UPDATE_TPA_AND_338_IMA_TEMPLATE: {
    path: "/api/onboarding/employer/plans/forms/_ACCEPT",
  },
  CONNECT_FINCH_CORP: {
    path: "/api/corp/business",
  },
  GET_LIST_EMPLOYEE: {
    path: "/api/profiles/sysAdmin/list",
  },
  POST_CHECK_SSN: {
    path: "/api/people/ssnValidation",
  },
  SEARCH_PARTICIPANT_BY_COMPANY: (companyType: string) => ({
    path: `/api/${companyType}/employees`,
  }),
  POST_CHANGE_AUTHORIZED_SIGNATORY: {
    path: "/api/companies/requestChangeAS",
  },
  PUT_CREATE_PLAN_ADMIN: (companyType: string) => ({
    path: `/api/${companyType}/admins`,
  }),
  GET_PLAN_ADMIN: (companyId: string) => ({
    path: `/api/companies/${companyId}/admins`,
  }),
  PUT_CREATE_PLAN_ADMIN_BASIC: (companyId: string) => ({
    path: `/api/companies/${companyId}/admins`,
  }),
  GET_COMPANY_FINCH_PROVIDERS: {
    path: "/api/companies/:companyId/providers",
  },
  DOWNLOAD_FINCH: {
    path: "/api/companies/finch/download",
  },
  DOWNLOAD_EMPLOYEE_STATUS_REPORT_BY_COMPANY: {
    path: "/api/profiles/employeeStatusReport/:companyId/download",
  },
  GET_AND_UPDATE_FEEDBACK: {
    path: "/api/collect/feedbacks",
  },
  GET_PORTFOLIO_HISTORY: {
    path: "/api/profiles/portfolioUpdateHistory",
  },
  DOWNLOAD_PORTFOLIO_HISTORY: {
    path: "/api/profiles/portfolioUpdateHistory/download",
  },
  GET_PROFILE_DASHBOARD: {
    path: "/api/profiles/dashboard",
  },
  POST_PEOPLE_ACCOUNT_RECOVERY: {
    path: "/api/people/recovery",
  },
  PATCH_VERIFY_CODE: {
    path: "/api/verificationCode/verify",
  },
  PATCH_VERIFY_2FA_CODE: {
    path: "/api/2fa/verify",
  },
  PATCH_RESEND_VERIFY_CODE: {
    path: "/api/verificationCode/resend",
  },
  GET_COMPANY_PARTNERS: {
    path: "/api/companies/partners",
  },
  GET_PARTNERS_SORT_INFO: {
    path: "/api/companies/partners/shortInfo",
  },
  GET_LIST_CLIENTS: {
    path: "/api/companies/partners/clients",
  },
  GET_LIST_LATE_CONTRIBUTION: {
    path: "/api/companies/lateOnContributions",
  },
  GET_LIST_LATE_SUBMISSION: {
    path: "/api/contributions/lateSubmit",
  },
  UPDATE_LATE_CONTRIBUTION: {
    path: "/api/companies/lateOnContributions",
  },
  DOWNLOAD_LATE_CONTRIBUTION: {
    path: "/api/companies/lateOnContributions/download",
  },
  DOWNLOAD_LATE_SUBMISSION: {
    path: "/api/contributions/lateSubmit/download",
  },
};
