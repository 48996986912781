import { Divider, IconButton } from '@mui/material';
import React from 'react';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import SystemUpdateAltOutlinedIcon from '@mui/icons-material/SystemUpdateAltOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import BasicModal, { ModalProps } from 'common/components/BasicModal';
import { Button } from 'common/components';

interface IModalBackupCodeProps {
  isOpen: boolean;
  backupCodes: string[];
  setBackupCodes: Function;
}

const ModalBackupCode = ({ isOpen, backupCodes, setBackupCodes }: IModalBackupCodeProps) => {
  const saveData = (function () {
    var a = document.createElement('a');
    document.body.appendChild(a);
    a.className = 'hidden';
    return function (data: string, fileName: string) {
      const blob = new Blob([data], { type: 'octet/stream' }),
        url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    };
  })();

  const basicModalProps: ModalProps = {
    isOpen,
    className: 'twoStepAuth w-full max-w-[640px] min-w-0 text-darkGreen max-h-full overflow-y-auto',
  };
  return (
    <BasicModal {...basicModalProps}>
      <div>
        <div className="text-center">
          <div>
            <CheckCircleOutlineOutlinedIcon fontSize="large" />
          </div>
          <h2 className="font-bold text-xl mt-4">Two-step authentication</h2>
          <p className="text-primary text-sm mt-4 mb-6">
            Save these emergency backup codes and store it somewhere safe. We will not be able to retrieve these for
            you. <br className="hidden xs:inline" />
            If you lose your phone, you can use backup codes to sign in.
          </p>
          <div className="bg-lightGreen p-6 rounded-xl">
            <ul className="grid grid-cols-3 gap-6 font-semibold">
              {backupCodes.map((code) => {
                return <li key={code}>{code}</li>;
              })}
            </ul>
            <div className="actionBackupCode">
              <Divider className="my-4 border-borderColor" />
              <div className="flex items-center justify-between max-w-[300px] mx-auto">
                <IconButton
                  onClick={() => {
                    saveData(backupCodes.join('\n'), 'backup-code.txt');
                  }}
                >
                  <SystemUpdateAltOutlinedIcon sx={{ color: (theme) => theme.palette.secondaryColor }} />
                </IconButton>
                <IconButton
                  onClick={() => {
                    window.print();
                  }}
                >
                  <LocalPrintshopOutlinedIcon sx={{ color: (theme) => theme.palette.secondaryColor }} />
                </IconButton>
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(backupCodes.join('\n'));
                  }}
                >
                  <ContentCopyOutlinedIcon sx={{ color: (theme) => theme.palette.secondaryColor }} />
                </IconButton>
              </div>
            </div>
          </div>
          <div className="button2FADone mt-8">
            <Button
              className="w-full h-[48px]"
              variant="contained"
              color="secondary"
              onClick={() => {
                setBackupCodes([]);
              }}
            >
              Done!
            </Button>
          </div>
        </div>
      </div>
    </BasicModal>
  );
};

export default ModalBackupCode;
