import React from 'react';
import { RadioButtonGroup } from 'common/components';
import { Control, Controller } from 'react-hook-form';

interface IRadioComp {
  description: string | React.ReactNode;
  control: Control;
  name: string;
  radioProps?: any;
}

const options = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
];

const RadioComp = ({ description, control, name, radioProps = {} }: IRadioComp) => {
  return (
    <div className="flex items-center zero:flex-col xs:flex-row">
      {React.isValidElement(description) ? (
        description
      ) : (
        <span className="font-normal text-sm text-primary  w-full">{description}</span>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <>
              <RadioButtonGroup
                {...field}
                onChange={(e) => field.onChange(e.target.value)}
                row
                classes="radio-button-group max-w-[140px] flex justify-end"
                options={options}
                {...radioProps}
              />
            </>
          );
        }}
      />
    </div>
  );
};

export default RadioComp;
