import React from 'react';
import { UploadFileComp } from './UploadPlanComp';

export enum FileTypeEnum {
  SUMMARY = 'SUMMARY',
  BOOKLET = 'BOOKLET',
  EXECUTION = 'EXECUTION',
  TPAA_PLAN = 'TPAA_PLAN',
  ERISA_338_IMA_PLAN = 'ERISA_338_IMA_PLAN',
  EMPLOYER_PROOF_BANK_ACCOUNT = 'EMPLOYER_PROOF_BANK_ACCOUNT',
  SPOUSAL_CONSENT = 'SPOUSAL_CONSENT',
  EMPLOYEE_OPTOUT_FORM = 'EMPLOYEE_OPTOUT_FORM',
}

enum NameFileEnum {
  SUMMARY = 'plansummary',
  BOOKLET = 'planbooklet',
  EXECUTION = 'planexecution',
  TPAA_PLAN = 'plantpaa',
  ERISA_338_IMA_PLAN = 'planerisa',
}

export interface IUploadPlanType {
  title: string;
  fileType: FileTypeEnum;
  type: string;
  name: NameFileEnum;
}

const uploadPlans = [
  {
    title: 'Summary Plan Description *',
    fileType: FileTypeEnum.SUMMARY,
    name: NameFileEnum.SUMMARY,
    type: 'file',
  },
  {
    title: 'Trust Agreement *',
    fileType: FileTypeEnum.BOOKLET,
    name: NameFileEnum.BOOKLET,
    type: 'file',
  },
  {
    title: 'Adoption Agreement *',
    fileType: FileTypeEnum.EXECUTION,
    name: NameFileEnum.EXECUTION,
    type: 'file',
  },
  {
    title: 'Third Party Administrator (TPA) Agreement *',
    fileType: FileTypeEnum.TPAA_PLAN,
    name: NameFileEnum.TPAA_PLAN,
    type: 'file',
  },
  {
    title: 'ERISA 3(38) Investment Management Agreement *',
    fileType: FileTypeEnum.ERISA_338_IMA_PLAN,
    name: NameFileEnum.ERISA_338_IMA_PLAN,
    type: 'file',
  },
];

const UploadPlan = ({ field, idx }: { field?: any; idx?: number }) => {
  return (
    <div className="mt-8">
      <span className="font-bold text-base text-superBlack">Plan Documents</span>
      <div className="mt-4">
        {uploadPlans.map((item) => {
          return <UploadFileComp key={JSON.stringify(item)} fileInfo={item} field={field} idx={idx} />;
        })}
      </div>
    </div>
  );
};

export default UploadPlan;
