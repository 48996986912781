export interface IAnswers {
  spouse: any;
  userStartRetire: any;
  userEndRetire: any;
  spouseStartRetire: any;
  spouseEndRetire: any;
  age: any;
  spouseAge: any;
  monthIncome: any;
  initMonthIncome: any;
  spouseMonthIncome: any;
  otherIncome: any;
  spouseOtherIncome: any;
  monthSave: any;
  spouseMonthSave: any;
  lifestyle: any;
  investBalance: any;
  saveBalance: any;
  otherAsset: any;
  totalAsset: any;
  includeSSI: any;
  ssnAmount: any;
  inflation: any;
  totalRetirementNeed: any;
  annualRetirementNeed: any;
  monthRetirementNeed: any;
  yearlyRetirementNeed: any;
  monthlyRetirementNeed: any;
  paycheckRetirementNeed: any;
  payrollFrequency: any;
  maximumContributionPeriod: any;
  totalCompensation: any;
  monthlyGoal: any;
  monthlyPoorMarket: any;
  monthlyAverageMarket: any;
  annualGoal: any;
  annualPoorMarket: any;
  annualAverageMarket: any;
  traditionalContribution: any;
  rothContribution: any;
  autoEscalate: any;
  federalTax: any;
  stateTax: any;
  traditionalContributionAmount: any;
  rothContributionAmount: any;
  grossPay: any;
  traditionalRecommended: any;
  traditionalNationalAverage: any;
  rothRecommended: any;
  rothNationalAverage: any;
  stateTaxAmount: any;
  federalTaxAmount: any;
  planType: any;
  avgMarketProject: any;
  poorMarketProject: any;
  netPay: any;
  paycheckContribution: any;
  employerMatch: any;
  retirementSpending: any;
  likelyNextMonth: any;
  willingToWait: any;
  recommendedPortfolio: any;
  portfolio: any;
  companyPlanType: any;
}
export const buildAnswers = (answers: any[]): IAnswers => {
  return {
    spouse: { key: 17, value: answers[17] },
    userStartRetire: { key: 21, value: answers[21] },
    userEndRetire: { key: 26, value: answers[26] },
    spouseStartRetire: {
      key: 22,
      value: answers[22] && answers[27] ? answers[22] : 67,
    },
    spouseEndRetire: {
      key: 27,
      value: answers[22] && answers[27] ? answers[27] : 90,
    },
    age: { key: 19, value: answers[19] },
    spouseAge: { key: 20, value: answers[20] },
    monthIncome: { key: 31, value: answers[31] },
    initMonthIncome: { key: 31, value: answers[31] },
    spouseMonthIncome: { key: 32, value: answers[32] },
    otherIncome: { key: 33, value: answers[33] },
    spouseOtherIncome: { key: 34, value: answers[34] },
    monthSave: { key: 36, value: answers[36] },
    spouseMonthSave: { key: 37, value: answers[37] },
    lifestyle: { key: 38, value: answers[38] },
    investBalance: { key: 39, value: answers[39] },
    saveBalance: { key: 40, value: answers[40] },
    otherAsset: { key: 41, value: answers[41] },
    totalAsset: { key: 42, value: answers[42] },
    includeSSI: { key: 49, value: answers[49] },
    ssnAmount: { key: 54, value: answers[54] },
    inflation: { key: 50, value: answers[50] },
    totalRetirementNeed: { key: 58, value: answers[58] },
    annualRetirementNeed: { key: 60, value: answers[60] },
    monthRetirementNeed: { key: 61, value: answers[61] },
    yearlyRetirementNeed: { key: 62, value: answers[62] },
    monthlyRetirementNeed: { key: 63, value: answers[63] },
    paycheckRetirementNeed: { key: 64, value: answers[64] },
    payrollFrequency: { key: 5, value: answers[5] },
    maximumContributionPeriod: { key: 0, value: answers[6] / answers[5] },
    totalCompensation: { key: 35, value: answers[35] },
    monthlyGoal: { key: 61, value: answers[61] },
    monthlyPoorMarket: { key: 48, value: answers[48] },
    monthlyAverageMarket: { key: 46, value: answers[46] },
    annualGoal: { key: 60, value: answers[60] },
    annualPoorMarket: { key: 47, value: answers[47] },
    annualAverageMarket: { key: 45, value: answers[45] },
    traditionalContribution: { key: 79, value: parseFloat(answers[79]) },
    rothContribution: { key: 78, value: parseFloat(answers[78]) },
    autoEscalate: { key: 91, value: answers[91] },
    federalTax: { key: 69, value: parseFloat(answers[69]) },
    stateTax: { key: 70, value: parseFloat(answers[70]) },
    traditionalContributionAmount: { key: 81, value: parseFloat(answers[81]) },
    rothContributionAmount: { key: 80, value: parseFloat(answers[80]) },
    grossPay: { key: 83, value: parseFloat(answers[83]) },
    traditionalRecommended: { key: 77, value: parseFloat(answers[77]) },
    traditionalNationalAverage: { key: 2, value: parseFloat(answers[2]) },
    rothRecommended: { key: 76, value: parseFloat(answers[76]) },
    rothNationalAverage: { key: 1, value: parseFloat(answers[1]) },
    stateTaxAmount: { key: 87, value: parseFloat(answers[87]) },
    federalTaxAmount: { key: 86, value: parseFloat(answers[86]) },
    planType: { key: 75, value: answers[75] || answers[74] },
    avgMarketProject: { key: 89, value: answers[89] },
    poorMarketProject: { key: 90, value: answers[90] },
    netPay: { key: 88, value: parseFloat(answers[88]) },
    paycheckContribution: { key: 84, value: parseFloat(answers[84]) },
    employerMatch: { key: 85, value: parseFloat(answers[85]) },
    retirementSpending: { key: 92, value: answers[92] },
    likelyNextMonth: { key: 93, value: answers[93] },
    willingToWait: { key: 94, value: answers[94] },
    recommendedPortfolio: { key: 95, value: answers[95] },
    portfolio: { key: 96, value: answers[96] },
    companyPlanType: { key: 97, value: answers[0] },
  };
};
