import publicApi from './utils/HttpRequest';
import { getApiPath } from './utils/routesUtils';

const getSummary = () => {
  return publicApi.get('/api/pep/summary');
};

const getClients = (params: any) => {
  const path: string = getApiPath({ path: '/api/pep/clients' }, params);
  return publicApi.get(path);
};

const getClientsActive = () => {
  return publicApi.get(`/api/pep/clients/ACTIVE`);
};

const sendInvites = (params: { data: any[]; resend: boolean }) => {
  return publicApi.post(`/api/pep/invites`, params);
};

const terminateClients = (params: { companyIds: any[] }) => {
  return publicApi.patch('/api/pep/clients/_DELETE', params);
};

const getMasterPlans = () => {
  return publicApi.get('/api/plans/master');
};

const updateMasterPlans = (body: any) => {
  return publicApi.patch('/api/plans/master', body);
};

const getPepPlanAdmins = () => {
  return publicApi.get('/api/pep/admins');
};

const updatePepPlanAdmins = (body: any) => {
  return publicApi.patch('/api/pep/admins', body);
};

const PEPService = {
  getSummary,
  getClients,
  getClientsActive,
  sendInvites,
  terminateClients,

  getMasterPlans,
  updateMasterPlans,

  getPepPlanAdmins,
  updatePepPlanAdmins,
};

export default PEPService;
