import Footer from 'common/components/Footer';
import { Outlet, useLocation } from 'react-router-dom';

import SAVEDAY_LOGO from 'assets/images/saveday-stacked-color.png';
import './styles.scss';
import './LoginPage.scss';
import MainLayout from 'layout/MainLayout';
import { useEffect, useRef, useState } from 'react';

const LoginLayout = () => {
  const refContent = useRef(null);
  const refFooter = useRef(null);
  const refHeader = useRef(null);
  const { pathname } = useLocation();
  const [isReady, setIsReady] = useState<boolean>(false);
  let debounce: any;

  useEffect(() => {
    const onResize = () => {
      clearTimeout(debounce);
      debounce = setTimeout(function () {
        if (refContent.current && refFooter.current && refHeader.current) {
          const elementContent = refContent.current as HTMLElement;
          const elementFooter = refFooter.current as HTMLElement;
          const elementHeader = refHeader.current as HTMLElement;
          elementContent.style.minHeight = `${window.innerHeight - elementHeader.offsetHeight}px`;
          elementContent.style.paddingBottom = `${elementFooter.offsetHeight}px`;
          if (!isReady) {
            setIsReady(true);
          }
        }
      }, 350);
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <MainLayout>
      <div className="login-main-wrapper">
        <header ref={refHeader}>
          <div
            className={[
              'flex zero:justify-center zero:pl-0 xs:justify-start xs:pl-14 pt-10 w-full',
              pathname === '/onboarding/employee' || pathname === '/onboarding/employer'
                ? 'zero:pointer-events-none xs:pointer-events-auto'
                : '',
            ].join(' ')}
          >
            <a href="https://www.saveday.com/">
              <img src={SAVEDAY_LOGO} alt="saveday-logo" width={100} />
            </a>
          </div>
        </header>
        <main className="relative overflow-hidden content" ref={refContent}>
          {isReady && <Outlet />}
        </main>
        <footer className="footer sm:fixed bottom-0 w-full" ref={refFooter}>
          <Footer />
        </footer>
      </div>
    </MainLayout>
  );
};

export default LoginLayout;
