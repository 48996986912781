import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  EmployerReportType,
  activePlanColumns,
  allContributionColumns,
  getDynamicColumns,
  pendingColumns,
} from 'core/constants/employer-report-data';
import { GetActivePlanPayload, ReportTypeDto, TableData } from 'core/models/employerReport.Dto.model';
import ApiService from 'core/services/api.service';
import { RootState } from 'states/store';

interface ReportState {
  loading: boolean;
  error: string;
  success: boolean;
  allReports: ReportTypeDto[];
  tableData: TableData;
}

const initialState: ReportState = {
  loading: false,
  error: '',
  success: false,
  allReports: [],
  tableData: {
    data: {},
    columns: [],
    type: EmployerReportType.ActivePlanParticipants,
    totalElements: 0,
  },
};

export const getAllReportsAsync = createAsyncThunk('report/getAllReportsAsync', async (): Promise<any> => {
  const response = await ApiService.getAllReports();
  return response.data;
});

export const getActivePlanParticipantAsync = createAsyncThunk(
  'report/getActivePlanParticipantAsync',
  async (data: GetActivePlanPayload): Promise<any> => {
    const response = await ApiService.getActivePlanParticipant(data.companyId ?? '', data.filter);
    return response.data;
  }
);

export const getAllContributionsAsync = createAsyncThunk(
  'report/getAllContributionsAsync',
  async (filter: any): Promise<any> => {
    const response = await ApiService.getAllContributions(filter);
    return response.data;
  }
);

export const getPendingParticipantAsync = createAsyncThunk(
  'report/getPendingParticipantAsync',
  async (data: GetActivePlanPayload): Promise<any> => {
    const response = await ApiService.getActivePlanParticipant(data.companyId ?? '', data.filter);
    return response.data;
  }
);

export const getYtdContributionsAsync = createAsyncThunk(
  'report/getYtdContributionsAsync',
  async (filter: any): Promise<any> => {
    const response = await ApiService.getYtdContributions(filter);
    return response.data;
  }
);

export const getSummaryReportAsync = createAsyncThunk(
  'report/getSummaryReportAsync',
  async (filter: any): Promise<any> => {
    const response = await ApiService.getSummaryReport(filter);
    return response.data;
  }
);

export const getAccountBalanceAsync = createAsyncThunk(
  'report/getAccountBalanceAsync',
  async (filter: any): Promise<any> => {
    const response = await ApiService.getAccountBalance(filter);
    return response.data;
  }
);

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllReportsAsync.pending, (state) => {
        state.error = '';
        state.loading = true;
        state.success = false;
      })
      .addCase(getAllReportsAsync.rejected, (state, action) => {
        state.error = JSON.parse(action.error.message || '').error as string;
        state.loading = false;
        state.success = false;
      })
      .addCase(getAllReportsAsync.fulfilled, (state, action) => {
        state.error = '';
        state.loading = false;
        state.success = true;
        state.allReports = action.payload;
      })
      .addCase(getActivePlanParticipantAsync.pending, (state) => {
        state.error = '';
        state.loading = true;
        state.success = false;
      })
      .addCase(getActivePlanParticipantAsync.rejected, (state, action) => {
        state.error = JSON.parse(action.error.message || '').error as string;
        state.loading = false;
        state.success = false;
      })
      .addCase(getActivePlanParticipantAsync.fulfilled, (state, action) => {
        state.error = '';
        state.loading = false;
        state.success = true;
        state.tableData.data = action.payload;
        state.tableData.columns = activePlanColumns;
        state.tableData.type = EmployerReportType.ActivePlanParticipants;
        state.tableData.totalElements = action.payload.totalElements;
      })
      .addCase(getAllContributionsAsync.pending, (state) => {
        state.error = '';
        state.loading = true;
        state.success = false;
      })
      .addCase(getAllContributionsAsync.rejected, (state, action) => {
        state.error = JSON.parse(action.error.message || '').error as string;
        state.loading = false;
        state.success = false;
      })
      .addCase(getAllContributionsAsync.fulfilled, (state, action) => {
        state.error = '';
        state.loading = false;
        state.success = true;
        state.tableData.data = action.payload;
        state.tableData.columns = allContributionColumns;
        state.tableData.type = EmployerReportType.ContributionsReport;
        state.tableData.totalElements = action.payload.totalElements;
      })
      .addCase(getPendingParticipantAsync.pending, (state) => {
        state.error = '';
        state.loading = true;
        state.success = false;
      })
      .addCase(getPendingParticipantAsync.rejected, (state, action) => {
        state.error = JSON.parse(action.error.message || '').error as string;
        state.loading = false;
        state.success = false;
      })
      .addCase(getPendingParticipantAsync.fulfilled, (state, action) => {
        state.error = '';
        state.loading = false;
        state.success = true;
        state.tableData.data = action.payload;
        state.tableData.columns = pendingColumns;
        state.tableData.type = EmployerReportType.PendingParticipantsReport;
        state.tableData.totalElements = action.payload.totalElements;
      })
      .addCase(getYtdContributionsAsync.pending, (state) => {
        state.error = '';
        state.loading = true;
        state.success = false;
      })
      .addCase(getYtdContributionsAsync.rejected, (state, action) => {
        state.error = JSON.parse(action.error.message || '').error as string;
        state.loading = false;
        state.success = false;
      })
      .addCase(getYtdContributionsAsync.fulfilled, (state, action) => {
        state.error = '';
        state.loading = false;
        state.success = true;
        state.tableData.data = action.payload;
        state.tableData.columns = getDynamicColumns(action.payload.headerList);
        state.tableData.type = EmployerReportType.YearToDateContribution;
        state.tableData.totalElements = action.payload.totalElements;
      })
      .addCase(getSummaryReportAsync.pending, (state) => {
        state.error = '';
        state.loading = true;
        state.success = false;
      })
      .addCase(getSummaryReportAsync.rejected, (state, action) => {
        state.error = JSON.parse(action.error.message || '').error as string;
        state.loading = false;
        state.success = false;
      })
      .addCase(getSummaryReportAsync.fulfilled, (state, action) => {
        state.error = '';
        state.loading = false;
        state.success = true;
        state.tableData.data = action.payload;
        state.tableData.columns = getDynamicColumns(action.payload.headerList, false);
        state.tableData.type = EmployerReportType.SummaryReport;
        state.tableData.totalElements = action.payload ? action.payload.dataObjList.length : 0;
      })
      .addCase(getAccountBalanceAsync.pending, (state) => {
        state.error = '';
        state.loading = true;
        state.success = false;
      })
      .addCase(getAccountBalanceAsync.rejected, (state, action) => {
        state.error = JSON.parse(action.error.message || '').error as string;
        state.loading = false;
        state.success = false;
      })
      .addCase(getAccountBalanceAsync.fulfilled, (state, action) => {
        state.error = '';
        state.loading = false;
        state.success = true;
        state.tableData.data = action.payload;
        state.tableData.columns = getDynamicColumns(action.payload.headerList);
        state.tableData.type = EmployerReportType.AccountBalance;
        state.tableData.totalElements = action.payload.totalElements;
      });
  },
});

export const selectAllReports = (state: RootState) => state.report.allReports;
export const selectTableData = (state: RootState) => state.report.tableData;

const employerReportSlice = reportSlice.reducer;
export default employerReportSlice;
