import { Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { Table } from 'common/components';
import BasicModal from 'common/components/BasicModal';

export const TaxBracketTableModal = ({ isOpen, handleClose }: { isOpen: boolean; handleClose: Function }) => {
  const tableRowData = [
    {
      id: 1,
      name: 'Contributions',
      traditional: 'Traditional 401(k) pre-tax employee elective contributions are made with before-tax dollars.',
      roth: 'Roth 401(k) employee elective contributions are made with after-tax dollars.',
    },
    {
      id: 2,
      name: 'Income Limits',
      traditional: 'No income limitation to participate.',
      roth: 'No income limitation to participate.',
    },
    {
      id: 3,
      name: 'Maximum Elective Contributions',
      traditional:
        'Aggregate* employee elective contributions limited to $22500 in $2023 (plus an additional $7500 for employees age 50 or over).',
      roth: 'Aggregate* employee elective contributions limited to $22500 in $2023 (plus an additional $7500 for employees age 50 or over).',
    },
    {
      id: 4,
      name: 'Taxation of Withdrawals',
      traditional: 'Withdrawals of contributions and earnings are subject to Federal and most State income taxes.',
      roth: '<div>Withdrawals of contributions and earnings are not taxed provided it’s a qualified distribution – the account is held for at least 5 years and made:</div><ul><li>• On account of disability,</li><li>• On or after death, or</li><li>• On or after attainment of age 59½.</li></ul>',
    },
    {
      id: 5,
      name: 'Required Distributions',
      traditional: 'Distributions must begin no later than age 70½, unless still working and not a 5% owner.',
      roth: 'Distributions must begin no later than age 70½, unless still working and not a 5% owner.',
    },
  ];

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: '',
      sortable: false,
      disableColumnMenu: true,
      minWidth: 120,
      align: 'center',
      renderCell: ({ value }) => (
        <div className="text-darkGreen font-semibold zero:text-[10px] sm:text-sm text-center w-full">{value}</div>
      ),
    },
    {
      field: 'traditional',
      headerAlign: 'center',
      align: 'left',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderHeader: () => (
        <Tooltip title="Traditional 401(K)">
          <div className="flex sm:flex-row zero:flex-col items-center">
            <div className="text-darkGreen font-semibold zero:text-[10px] zero:leading-[18px] sm:text-sm">
              Traditional&nbsp;
            </div>
            <div className="text-darkGreen font-semibold zero:text-[10px] zero:leading-[18px] sm:text-sm">401(K)</div>
          </div>
        </Tooltip>
      ),
    },
    {
      field: 'roth',
      align: 'left',
      headerAlign: 'center',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: ({ value }) => (
        <div
          className="text-primary zero:text-[10px] leading-[18px] sm:text-sm w-full flex items-start zero:justify-start md:justify-center flex-col h-full font-normal"
          dangerouslySetInnerHTML={{ __html: value }}
        ></div>
      ),
      renderHeader: () => (
        <Tooltip title="Roth 401(K)">
          <div className="flex sm:flex-row zero:flex-col items-center">
            <div className="text-darkGreen font-semibold zero:text-[10px] zero:leading-[18px] sm:text-sm">
              Roth&nbsp;
            </div>
            <div className="text-darkGreen font-semibold zero:text-[10px] zero:leading-[18px] sm:text-sm">401(K)</div>
          </div>
        </Tooltip>
      ),
    },
  ];

  const modalProps = {
    onClose: handleClose,
  };

  return (
    <BasicModal
      handleClose={() => handleClose()}
      isOpen={isOpen}
      className="min-w-0 zero:w-[350px] xs:w-[550px] sm:w-[600px] md:w-[1000px] zero:h-[650px] xs:h-[700px] overflow-auto scrollbar"
      modalProps={modalProps}
    >
      <Table
        columns={columns}
        rows={tableRowData}
        sx={{
          '.MuiDataGrid-cell': {
            '&:not(:first-child)': {
              alignItems: 'flex-start',
              justifyContent: {
                zero: 'flex-start',
                xs: 'flex-start',
                md: 'center',
              },
              flexDirection: 'column',
            },
            borderRight: (theme) => `1px solid ${theme.palette.borderColor}`,
            color: (theme) => theme.palette.primaryColor,
            '&:last-child': {
              borderRight: 'none !important',
            },
            '.MuiDataGrid-cellContent': {
              fontSize: {
                zero: 10,
                xs: 10,
                sm: 14,
              },
              lineHeight: '18px',
              fontWeight: 400,
              overflow: 'unset',
              textOverflow: 'unset',
            },
          },
        }}
      />
      <span className="text-primary text-sm">
        Please note, this is simply a high level overview of the differences between Traditional 401(k) and Roth 401(k)
        to learn more about what’s best for your situation please speak to your financial advisor.
      </span>
    </BasicModal>
  );
};
