export const ROUTES = {
  HOME: "/",
  LOGIN: {
    path: "login",
    routes: {
      forgotUserName: {
        name: "forgotUsername",
        path: "/login/forgotUsername",
      },
      forgotPassword: {
        name: "forgotPassword",
        path: "/login/forgotPassword",
      },
    },
  },
  DASHBOARD: {
    path: "/dashboard",
    name: "dashboard",
    routes: {
      employerReport: {
        path: "/dashboard/employer/reports",
      },
      employerTasks: {
        path: "/dashboard/employer/tasks",
      },
      employerFormFilling: {
        path: "/dashboard/employer/formfiling",
      },
      employeeProfile: {
        path: "/dashboard/employee",
      },
      employer: {
        path: "employer",
      },
      employee: {
        path: "employee",
        routes: {
          profile: {
            path: "profile",
          },
          beneficiary: {
            path: "beneficiary",
          },
          notification: {
            path: "notification",
          },
          transactions: { path: "transactions" },
          contributions: { path: "contributions" },
          statements: { path: "statements" },
          rollover: { path: "rollover" },
          allocations: { path: "allocations" },
          plandetails: { path: "plandetails" },
          planforms: { path: "planforms" },
          riskQuestions: { path: "/dashboard/employee/riskquestions" },
        },
      },

      selectRole: {
        path: "/dashboard/selectRole",
      },
    },
    admin: {
      path: "/admin/dashboard",
      statements: {
        name: "statements",
        path: "/admin/statements",
      },
      managepayroll: {
        name: "managepayroll",
        path: "/admin/managepayroll",
        detail: {
          name: "payrollinfo",
          path: "/admin/payrollinfo",
        },
      },
      accountmanagement: {
        name: "accountmanagement",
        path: "/admin/accountmanagement",
      },
      rollover: {
        name: "rollover",
        path: "/admin/rollover",
      },
    },
  },
  ADMIN: {
    path: "/admin",
    name: "admin",
    routes: {
      dashboard: {
        name: "admin dashboard",
        path: "/admin/dashboard",
      },
      manageemployee: {
        name: "manage employee",
        path: "manage-employee",
      },
      manageemployers: {
        name: "manage employers",
        path: "manage-employers",
      },
      homeplan: {
        name: "home plans",
        path: "/admin/selectcompany",
      },
      plancreation: {
        name: "create plan",
        path: "/admin/plancreation",
      },
      editplan: {
        name: "edit plan",
        path: "/admin/editplan",
      },
      planupload: {
        name: "upload plan",
        path: "/admin/planupload",
      },
      planactivation: {
        name: "active/terminal plan",
        path: "/admin/planactivation",
      },
    },
  },
  POLICIES: "policies",
  PRIVACY: "privacy",
  ABOUT: "about",
  PRIVACY_POLICY: "policies/privacy",
  TERMS_AND_CONDITIONS: "policies/terms",
  // EMPLOYEE: {
  //   path: "employee",
  //   routes: {
  //     profile: {
  //       path: "profile",
  //     },
  //   },
  // },
  EMPLOYER: "EMPLOYER",
  MANAGE_EMPLOYEE: "manage-employees",
  MANAGE_EMPLOYER: "manage-employers",
  COMPANY_ADMIN: "companyadmin",
  TASK: "task",
  RESET_PASSWORD: "reset_password",
  PLAN_FORMS: "planforms",
  STATEMENTS: "statements",
  ANNUAL_LIMITS: "annuallimits",
};
