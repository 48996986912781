import { Box, FormControl, Typography } from '@mui/material';
import FileXIcon from 'assets/SVGIcons/FileX';
import { EmptyDataShow, PageHeader, Autocomplete, Button, Select, SelectItem } from 'common/components';
import BasicModal from 'common/components/BasicModal';
import AddEditCompanyAdminForm from 'components/manageCompanyAdmin/AddEditCompanyAdminForm';
import CompanyAdminTable from 'components/manageCompanyAdmin/CompanyAdminTable';
import SelectedCompanyAdminTable from 'components/manageCompanyAdmin/SelectedPeopleTable';
import { Actions } from 'core/enums/action-enum';
import { RequestStatus } from 'core/enums/request-status.enum';
import {
  CompanyAdminResponse,
  EditCompanyAdmin,
  GetAllExistingPerson,
  NewCompanyAdmin,
} from 'core/models/companyAdminDto.model';
import { convertPhoneNumber, formatPhoneNumberDB } from 'utils/input-formatter';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppSelector } from 'states/hooks';
import {
  addCompanyAdminToCompanyAsync,
  createNewCompanyAdminAsync,
  getAllExistingPersonsAsync,
  getCompanyAdminByCompanyIdAsync,
  getCompanyAdminByNameAsync,
  getPartiallyPersonsAsync,
  manageCompanyAdminAsync,
  selectAction,
  selectAllPersons,
  selectCompanyAdminList,
  selectEditItem,
  selectIsModalOpen,
  selectSelectedCompanyAdminId,
  selectSelectedPeopleList,
  selectType,
  setAction,
  setAllPerson,
  setIsModalOpen,
  setSelectedCompanyAdminId,
  setSelectedItem,
  setSelectedPeopleList,
  setType,
} from 'states/manageCompanyAdmin/manageCompanyAdminSlice';
import { setWarningMessage } from 'states/snackbarMessage/snackbarMessageSlice';

export enum SelectedOption {
  NEW = 'NEW',
  EXISTING = 'EXISTING',
}

const AdminManageCompanyAdmin = () => {
  const [isConfirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const selectedItemValues = useAppSelector(selectEditItem);
  const dispatch = useDispatch<any>();
  const companyId = searchParams.get('companyId');
  const companyAdminList = useAppSelector(selectCompanyAdminList);
  const action = useAppSelector(selectAction);
  const allPersons = useAppSelector(selectAllPersons);
  const selectedPeopleList = useAppSelector(selectSelectedPeopleList);
  const selectedCompanyAdminId = useAppSelector(selectSelectedCompanyAdminId);
  const type = useAppSelector(selectType);
  const isModalOpen = useAppSelector(selectIsModalOpen);
  const loading = useAppSelector((state) => state.manageCompanyAdmin.loading);
  const [paginationModel, setPaginationModel] = useState<{
    page: number;
    pageSize: number;
  }>({ page: 0, pageSize: 30 });
  const [searchText, setSearchText] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const getPeopleLoading = useAppSelector((state) => state.manageCompanyAdmin.getPeopleLoading);
  const totalElements = useAppSelector((state) => state.manageCompanyAdmin.totalElements);
  const navigate = useNavigate();
  const location = useLocation();

  let debounce: undefined | NodeJS.Timeout;

  const DEFAULT_EMPTY_VALUES = {
    companyAdminName: '',
    option: 'NEW',
    firstName: '',
    lastName: '',
    userName: '',
    jobTitle: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
  };

  const form = useForm<{
    companyAdminName: string;
    option: string;
    firstName: string;
    lastName: string;
    userName: string;
    jobTitle: string;
    email: string;
    phone: string;
    password: string;
    confirmPassword: string;
  }>({
    mode: 'all',
    defaultValues: DEFAULT_EMPTY_VALUES,
  });

  const {
    control,
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = form;

  const isAddNewPerson = !!action && !!type && action === Actions.CREATE && type === SelectedOption.NEW;
  const isAddExistingPerson = !!action && !!type && action === Actions.CREATE && type === SelectedOption.EXISTING;
  const isEditPerson = !!action && action === Actions.EDIT;

  useEffect(() => {
    dispatch(
      getCompanyAdminByCompanyIdAsync({
        id: companyId,
        action: 'COMPANY_ADMIN',
      })
    );

    return () => {
      onCancel();
    };
  }, []);

  useEffect(() => {
    if (open && paginationModel.page > 0) {
      dispatch(getPartiallyPersonsAsync({ filter: { ...paginationModel } }));
    }
  }, [paginationModel, open]);

  useEffect(() => {
    if (searchText && open) {
      dispatch(getAllExistingPersonsAsync({ filter: { search: searchText } }));
    } else if (!searchText && open) {
      dispatch(getAllExistingPersonsAsync({ filter: { page: 0, pageSize: 30 } }));
    }
  }, [searchText, open]);

  useEffect(() => {
    setValue('firstName', selectedItemValues?.firstName || '');
    setValue('lastName', selectedItemValues?.lastName || '');
    setValue('userName', selectedItemValues?.userName || '');
    setValue('jobTitle', selectedItemValues?.jobTitle || '');
    setValue('email', selectedItemValues?.email || '');
    setValue('phone', selectedItemValues?.phone || '');
    setValue('password', selectedItemValues?.password || '');
    setValue('confirmPassword', selectedItemValues?.password || '');
    setValue('phone', formatPhoneNumberDB(selectedItemValues?.phone || ''));
  }, [selectedItemValues]);

  const selectOptions: SelectItem[] = [
    {
      label: 'New Person',
      value: SelectedOption.NEW,
    },
    {
      label: 'Existing person',
      value: SelectedOption.EXISTING,
    },
  ];

  const onSubmitHandler = async (values: NewCompanyAdmin) => {
    if (action === Actions.CREATE) {
      if (type === SelectedOption.NEW) {
        const newCompanyAdmin = {
          email: values.email,
          firstName: values.firstName,
          jobTitle: values.jobTitle,
          lastName: values.lastName,
          password: values.password,
          phone: convertPhoneNumber(values.phone),
          userName: values.userName,
        };
        const result = await dispatch(createNewCompanyAdminAsync({ newCompanyAdmin, id: companyId }));
        if (result.meta.requestStatus === RequestStatus.FULFILLED) {
          await dispatch(
            getCompanyAdminByCompanyIdAsync({
              id: companyId,
              action: 'COMPANY_ADMIN',
            })
          );
          reset();
          dispatch(setAction(null));
        }
      } else if (type === SelectedOption.EXISTING) {
        const result = await dispatch(
          addCompanyAdminToCompanyAsync({
            companyId,
            companyAdminId: selectedCompanyAdminId,
          })
        );
        if (result.meta.requestStatus === RequestStatus.FULFILLED) {
          const newSelectedPeopleList = selectedPeopleList.filter(
            (item: CompanyAdminResponse) => item.id !== selectedCompanyAdminId
          );
          await dispatch(setSelectedPeopleList(newSelectedPeopleList));
          if (!newSelectedPeopleList.length) {
            onCancel();
          }
          await dispatch(
            getCompanyAdminByCompanyIdAsync({
              id: companyId,
              action: 'COMPANY_ADMIN',
            })
          );
        }
      }
    }

    if (action === Actions.EDIT) {
      if (!isDirty) {
        return dispatch(setWarningMessage('Please edit the fields!'));
      } else {
        if (selectedItemValues?.userName !== values.userName && !isConfirmModalOpen) {
          setConfirmModalOpen(true);
        } else {
          const editCompanyAdmin: EditCompanyAdmin | null = {
            companyId: null,
            companyNames: null,
            email: values.email,
            firstName: values.firstName,
            id: selectedItemValues?.id || '',
            jobTitle: values.jobTitle,
            lastName: values.lastName,
            password: values.password || null,
            phone: convertPhoneNumber(values.phone),
            role: selectedItemValues?.role || [],
            userName: values.userName,
          };
          const result = await dispatch(
            manageCompanyAdminAsync({
              companyId,
              manageCompanyAdmin: editCompanyAdmin,
              action: Actions.EDIT,
            })
          );
          if (result.meta.requestStatus === RequestStatus.FULFILLED) {
            await dispatch(
              getCompanyAdminByCompanyIdAsync({
                id: companyId,
                action: 'COMPANY_ADMIN',
              })
            );
            setConfirmModalOpen(false);
            onCancel();
          }
        }
      }
    }
  };
  const onCancel = () => {
    reset();
    dispatch(setAction(null));
    dispatch(setSelectedPeopleList([]));
    dispatch(setSelectedCompanyAdminId(null));
    dispatch(setSelectedItem(null));
    dispatch(setIsModalOpen(false));
    dispatch(setType(SelectedOption.NEW));
    dispatch(setSelectedPeopleList([]));
  };

  const mappingDataAutocomplete = (data: GetAllExistingPerson['content']): SelectItem[] => {
    if (!!data.length) {
      return data.map((item) => ({
        id: item.id || '',
        value: item.id || '',
        label: `${item?.firstName || ''} ${item.lastName || ''}`,
      }));
    }
    return [];
  };

  const onSelectCompanyAdmin = (event: any, value: any) => {
    if (value) {
      dispatch(getCompanyAdminByNameAsync({ name: value?.label }));
    }
  };

  const onDeleteCompanyAdmin = async (selectedItem: CompanyAdminResponse | null) => {
    await dispatch(
      manageCompanyAdminAsync({
        companyId,
        manageCompanyAdmin: selectedItem,
        action: Actions.DELETE,
      })
    );
    await dispatch(
      getCompanyAdminByCompanyIdAsync({
        id: companyId,
        action: 'COMPANY_ADMIN',
      })
    );
    onCancel();
  };

  return (
    <>
      <PageHeader pageTitle={`Manage Company Admin : ${location.state?.companyName}`} />
      <section className="px-8 mt-6 mb-10">
        {!!companyAdminList.length ? (
          <CompanyAdminTable data={companyAdminList} />
        ) : (
          <EmptyDataShow
            sx={{
              width: '100%',
              flexGrow: 1,
              minHeight: 100,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              color: (theme) => theme.palette.beige,
            }}
            icon={<FileXIcon />}
            description="No users found"
          />
        )}
        <div className="pt-4">
          {(!action || action === Actions.DELETE) && (
            <span
              onClick={() => dispatch(setAction(Actions.CREATE))}
              className="text-base text-textLink hover:text-textLinkHover hover:cursor-pointer inline-block"
            >
              Add Company Admin
            </span>
          )}
          {action && action !== Actions.DELETE && (
            <span
              className="text-base text-textLink hover:text-textLinkHover hover:cursor-pointer ml-2"
              onClick={onCancel}
            >
              Cancel
            </span>
          )}
        </div>

        <form onSubmit={handleSubmit(onSubmitHandler)}>
          {!!action && action === Actions.CREATE && (
            <FormControl className="mt-4">
              <Controller
                name="option"
                control={control}
                rules={{
                  required: 'Select option is required',
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder="Select an option"
                    data={selectOptions}
                    registerForm={{ ...register('option') }}
                    onChange={(e) => {
                      field.onChange(e);
                      dispatch(setType(e.target.value));
                    }}
                    error={errors.option?.message}
                  />
                )}
              ></Controller>
            </FormControl>
          )}

          <Box>
            {(isAddNewPerson || isEditPerson) && <AddEditCompanyAdminForm form={form} type={type} />}
            {isAddExistingPerson && (
              <>
                <FormControl
                  sx={{
                    width: '50%',
                    marginTop: 2,
                    '& .MuiTextField-root .MuiOutlinedInput-input.MuiInputBase-input': {
                      height: '16px',
                    },
                  }}
                >
                  <Controller
                    name="companyAdminName"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        {...field}
                        onOpen={() => setOpen(true)}
                        onClose={() => {
                          setOpen(false);
                          setPaginationModel({ page: 0, pageSize: 30 });
                          dispatch(setAllPerson([]));
                        }}
                        loading={getPeopleLoading}
                        options={mappingDataAutocomplete(allPersons)}
                        totalElements={totalElements}
                        onChange={(event, value, reason) => {
                          onSelectCompanyAdmin(event, value);
                          if (reason === 'clear' || reason === 'selectOption') {
                            dispatch(setSelectedPeopleList([]));
                          }
                        }}
                        filterOptions={(x) => x}
                        onInputChange={(event: any) => {
                          clearTimeout(debounce);
                          debounce = setTimeout(() => {
                            const { value } = event.target;
                            setSearchText(value);
                          }, 500);
                        }}
                        placeholder="Please search people"
                        error={errors.companyAdminName?.message}
                        onLoadMoreData={() => {
                          if (searchText) {
                            return;
                          } else {
                            setPaginationModel({
                              page: paginationModel.page + 1,
                              pageSize: 30,
                            });
                          }
                        }}
                      />
                    )}
                  ></Controller>
                </FormControl>
              </>
            )}

            {isAddExistingPerson && !!selectedPeopleList.length && (
              <SelectedCompanyAdminTable data={selectedPeopleList} />
            )}
          </Box>
          <div className="mt-10 flex gap-4 justify-center">
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                onCancel();
                if (!action) {
                  navigate(-1);
                }
              }}
            >
              Previous
            </Button>
            <Button
              sx={{ minWidth: '120px', width: '30%', maxWidth: '280px' }}
              disabled={(type === SelectedOption.EXISTING && !selectedCompanyAdminId) || !action}
              variant="contained"
              color="secondary"
              type="submit"
            >
              Save
            </Button>
          </div>
          <BasicModal
            className="w-full max-w-[550px]"
            isOpen={isConfirmModalOpen}
            handleClose={() => setConfirmModalOpen(false)}
          >
            <Typography className="text-darkGreen text-xl font-bold text-center">CONFIRMATION!</Typography>
            <Typography className="text-primary text-sm font-normal mt-6 text-center">
              Are you sure to change the username of this user? An email will send to this user for notification
              accordingly.
            </Typography>
            <div className="mt-6 flex gap-4 items-center justify-center">
              <Button variant="outlined" color="secondary" onClick={() => setConfirmModalOpen(false)}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                loading={loading}
                disabled={loading}
                onClick={handleSubmit(onSubmitHandler)}
              >
                Yes
              </Button>
            </div>
          </BasicModal>
        </form>
        <BasicModal
          isOpen={isModalOpen}
          handleClose={() => dispatch(setIsModalOpen(false))}
          className="w-full max-w-[450px]"
        >
          <div className="text-sm text-primary text-center">
            Are you sure want to delete {selectedItemValues?.firstName} {selectedItemValues?.lastName} as a company
            admin?
          </div>
          <div className="mt-6 flex gap-4 items-center justify-center">
            <Button variant="outlined" color="secondary" onClick={() => onCancel()}>
              Cancel
            </Button>
            <Button variant="contained" color="secondary" onClick={() => onDeleteCompanyAdmin(selectedItemValues)}>
              Continue
            </Button>
          </div>
        </BasicModal>
      </section>
    </>
  );
};

export default AdminManageCompanyAdmin;
