import { UploadFileToS3Dto } from 'core/models/UploadFileDto.model';
import { API } from './utils/app.routes';
import { getApiPath } from './utils/routesUtils';
import uploadFileApi from './utils/UploadFileRequest';
import publicApi from './utils/HttpRequest';

export const uploadFileToS3 = async ({ url, file, onUploadProgress }: UploadFileToS3Dto): Promise<{ data: any }> => {
  return uploadFileApi.put(url, file, {
    headers: {
      'Content-Type': file?.type,
    },
    onUploadProgress: (progressEvent) => {
      if (progressEvent && progressEvent.progress && onUploadProgress) {
        const progress = Math.round(progressEvent.progress * 100);
        onUploadProgress(progress);
      }
    },
  });
};

export const updateUploadStatus = async (id: string) => {
  const path = getApiPath(API.UPDATE_UPLOAD_STATUS, { fileId: id });
  return publicApi.patch(path);
};
