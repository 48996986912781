import { ProfileDto } from 'core/models/profileDto.model';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { EmployerStatementDto, StatementResponse } from 'core/models/statementsDto.model';
import ApiService from 'core/services/api.service';
import { RootState } from 'states/store';

interface EmployerStatementsState {
  error: string;
  statements: StatementResponse[];
  profile: ProfileDto | null;
  year: number;
  loading: boolean;
}

const initialState: EmployerStatementsState = {
  error: '',
  statements: [],
  profile: null,
  year: new Date().getFullYear(),
  loading: false,
};

export const getEmployerStatementsAsync = createAsyncThunk(
  'employerStatements/getEmployerStatementsAsync',
  async (body: EmployerStatementDto): Promise<any> => {
    const response = await ApiService.getEmployerStatements(body);
    return response.data;
  }
);

const employerStatementsSlice = createSlice({
  name: 'employerStatements',
  initialState,
  reducers: {
    setYear(state, action) {
      state.year = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmployerStatementsAsync.pending, (state) => {
        state.error = '';
        state.loading = true;
      })
      .addCase(getEmployerStatementsAsync.fulfilled, (state, action) => {
        state.statements = action.payload;
        state.loading = false;
      })
      .addCase(getEmployerStatementsAsync.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { setYear } = employerStatementsSlice.actions;
export const selectEmployerStatements = (state: RootState) => state.employerStatements.statements;
export const selectYear = (state: RootState) => state.employerStatements.year;
export const selectLoading = (state: RootState) => state.employerStatements.loading;

const employerStatementsReducer = employerStatementsSlice.reducer;
export default employerStatementsReducer;
