export function normalizePhoneNumber(str: string) {
  return str.replaceAll('-', '');
}

//CONVERT PHONE NUMBER FROM FORMAT `xxx-xxx-xxxx` to `(xxx) xxx-xxxx`
export function formatPhoneNumberDB(input: string) {
  if (!input) {
    return '';
  }
  const str = normalizePhoneNumber(input);
  return `(${str.substring(0, 3)}) ${str.substring(3, 6)} ${str.substring(6, 10)}`;
}

//CONVERT PHONE NUMBER FROM FORMAT `(xxx) xxx-xxxx` to `xxx-xxx-xxxx`
export const convertPhoneNumber = (phoneNumber: string | null | undefined) => {
  if (!phoneNumber) {
    return 'N/A';
  }
  // Remove all non-digits from the phone number
  const digitsOnly = phoneNumber.replace(/\D/g, '');

  // Split the digits into three groups
  const firstGroup = digitsOnly.slice(0, 3);
  const secondGroup = digitsOnly.slice(3, 6);
  const thirdGroup = digitsOnly.slice(6);

  // Join the groups with hyphens and return the result
  return `${firstGroup}-${secondGroup}-${thirdGroup}`;
};

export function toUTC(date: string) {
  var d = date.split('/');
  var mm = parseInt(d[0], 10) - 1;
  var yyyy = parseInt(d[2], 10);
  var dd = parseInt(d[1], 10);
  var str = new Date(Date.UTC(yyyy, mm, dd, 0, 0, 0)).toISOString();
  return str;
}

export const formatSSNToDB = (ssn: string) => {
  if (!ssn) return;
  if (ssn.lastIndexOf('-') < 0) {
    ssn = `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5)}`;
  }
  return btoa(ssn);
};
