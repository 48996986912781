import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  CashBalanceDto,
  CompanyHoldingDto,
  EmployeeStatusCount,
  EmployeeStatusReportDto,
  StatusReportPayload,
} from 'core/models/employerDashboardDto.model';
import { QuoteDTO } from 'core/models/quoteDto.model';
import ApiService from 'core/services/api.service';
import { RootState } from 'states/store';

interface EmployerDashboardState {
  loading: boolean;
  success: boolean;
  error: string;
  quotes: Array<QuoteDTO>;
  holdingQuotes: Array<QuoteDTO>;
  employeeStatusReport: EmployeeStatusReportDto;
  employeeStatusCount: EmployeeStatusCount;
  holdings: Array<CompanyHoldingDto>;
  cashBalance: CashBalanceDto;
  taskQuantity: number;
}

const initialState: EmployerDashboardState = {
  loading: false,
  success: false,
  error: '',
  quotes: [],
  holdingQuotes: [],
  employeeStatusReport: {},
  employeeStatusCount: {},
  holdings: [],
  cashBalance: {},
  taskQuantity: 0,
};

export const getQuoteAsync = createAsyncThunk(
  'employerDashboard/getQuoteAsync',
  async (payload: string[]): Promise<any> => {
    const response = await ApiService.getQuote(payload);
    return response.data;
  }
);

export const getHoldingQuoteAsync = createAsyncThunk(
  'employerDashboard/getHoldingQuoteAsync',
  async (payload: string[]): Promise<any> => {
    const response = await ApiService.getQuote(payload);
    return response.data;
  }
);

export const getEmployeeStatusReportAsync = createAsyncThunk(
  'employerDashboard/getEmployeeStatusReportAsync',
  async (data: StatusReportPayload): Promise<any> => {
    const response = await ApiService.getStatusReport(data);
    return response.data;
  }
);

export const getEmployeeStatusCountAsync = createAsyncThunk(
  'employerDashboard/getEmployeeStatusCountAsync',
  async (companyId: string): Promise<any> => {
    const response = await ApiService.getStatusCount(companyId);
    return response.data;
  }
);

export const getCompanyHoldingsAsync = createAsyncThunk(
  'employerDashboard/getCompanyHoldingsAsync',
  async (companyId: string): Promise<any> => {
    const response = await ApiService.getHoldings(companyId);
    return response.data;
  }
);

export const getCashBalanceAsync = createAsyncThunk(
  'employerDashboard/getCashBalanceAsync',
  async (companyId: string): Promise<any> => {
    const response = await ApiService.getCashBalance(companyId);
    return response.data;
  }
);

const employerDashboardSlice = createSlice({
  name: 'employerDashboard',
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getQuoteAsync.pending, (state) => {
        state.error = '';
        state.loading = true;
        state.success = false;
      })
      .addCase(getQuoteAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.quotes = action.payload;
      })
      .addCase(getQuoteAsync.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = JSON.parse(action.error.message || '').error as string;
      })
      .addCase(getHoldingQuoteAsync.pending, (state) => {
        state.error = '';
        state.loading = true;
        state.success = false;
      })
      .addCase(getHoldingQuoteAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.holdingQuotes = action.payload;
      })
      .addCase(getHoldingQuoteAsync.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = JSON.parse(action.error.message || '').error as string;
      })
      .addCase(getEmployeeStatusReportAsync.pending, (state) => {
        state.error = '';
        state.loading = true;
        state.success = false;
      })
      .addCase(getEmployeeStatusReportAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.employeeStatusReport = action.payload;
      })
      .addCase(getEmployeeStatusReportAsync.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = JSON.parse(action.error.message || '').error as string;
      })
      .addCase(getEmployeeStatusCountAsync.pending, (state) => {
        state.error = '';
        state.loading = true;
        state.success = false;
      })
      .addCase(getEmployeeStatusCountAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.employeeStatusCount = action.payload;
      })
      .addCase(getEmployeeStatusCountAsync.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = JSON.parse(action.error.message || '').error as string;
      })
      .addCase(getCompanyHoldingsAsync.pending, (state) => {
        state.error = '';
        state.loading = true;
        state.success = false;
      })
      .addCase(getCompanyHoldingsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        if (action.payload) {
          state.holdings = action.payload;
        }
      })
      .addCase(getCompanyHoldingsAsync.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = JSON.parse(action.error.message || '').error as string;
      })
      .addCase(getCashBalanceAsync.pending, (state) => {
        state.error = '';
        state.loading = true;
        state.success = false;
      })
      .addCase(getCashBalanceAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.cashBalance = action.payload;
      })
      .addCase(getCashBalanceAsync.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = JSON.parse(action.error.message || '').error as string;
      });
  },
});

export const selectHoldingQuotes = (state: RootState) => state.employerDashboard.holdingQuotes;
export const selectStatusReport = (state: RootState) => state.employerDashboard.employeeStatusReport;
export const selectStatusCount = (state: RootState) => state.employerDashboard.employeeStatusCount;
export const selectHoldings = (state: RootState) => state.employerDashboard.holdings;
export const selectCashBalance = (state: RootState) => state.employerDashboard.cashBalance;

const employerDashboardReducer = employerDashboardSlice.reducer;
export default employerDashboardReducer;
