import AccountCircleIcon from "assets/SVGIcons/AccountCircleIcon";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SettingIcon from "assets/SVGIcons/SettingIcon";
import { ROLES } from "core/constants/roles";
import { AuthContextType, useAuth } from "contexts/AuthProvider";
import AuthLayout from "layout/AuthLayout";
import * as React from "react";
import { Outlet } from "react-router-dom";

export default function CORPAdminLayout() {
  const menu = [
    {
      url: "/corp/dashboard",
      label: "Home",
      icon: <HomeOutlinedIcon />,
    },
    {
      url: "/corp/manage-businesses",
      label: "Manage Businesses",
      icon: <AccountCircleIcon />,
    },
    {
      url: "/corp/account-settings",
      label: "Account Settings",
      icon: <SettingIcon />,
    },
  ];

  const { session } = useAuth() as AuthContextType;

  return (
    <AuthLayout menu={menu}>
      {session && session.currentRole === ROLES.CORP_ADMIN && (
        <>
          <Outlet />
        </>
      )}
    </AuthLayout>
  );
}
