import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { PlanTypes } from "core/enums/plan-type.enum";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "states/hooks";
import { selectEmployeeProfile } from "states/manageEmployee/manageEmployeeSlice";
const parsePercent = (val?: number) => {
  return `${(val || 0) * 100}%`;
};
export default function TraditionalRoth() {
  const profile = useAppSelector(selectEmployeeProfile);
  const navigate = useNavigate();
  const isOptOut =
    profile?.status === "ACTIVE" &&
    !profile?.rothContributionPercentage &&
    !profile?.traditionalContributionPercentage;
  const planType = profile?.planType;
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <Card
      sx={{
        maxWidth: "100%",
        height: "100%",
        border: "1px solid #E0DEC9",
        borderRadius: "8px",
      }}
      variant="outlined"
    >
      <CardHeader
        sx={{
          paddingBottom: 0,
        }}
        title={
          <Grid container>
            <Grid item zero={8}>
              <Stack direction={"row"} spacing={2}>
                <Box>
                  <Typography
                    color={"#9E9E91"}
                    fontSize={"12px"}
                    fontWeight={600}
                    lineHeight={"18px"}
                  >
                    {mobileScreen ? "Trad." : "Traditional"}
                  </Typography>
                  <Typography
                    fontWeight={600}
                    fontSize={"20px"}
                    lineHeight={"32px"}
                  >
                    {parsePercent(profile?.traditionalContributionPercentage)}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    color={"#9E9E91"}
                    fontSize={"12px"}
                    fontWeight={600}
                    lineHeight={"18px"}
                  >
                    {`Roth 401(k)`}
                  </Typography>
                  <Typography
                    fontWeight={600}
                    fontSize={"20px"}
                    lineHeight={"32px"}
                  >
                    {planType === PlanTypes.TRADITIONAL
                      ? `-`
                      : parsePercent(
                          isOptOut ? 0 : profile?.rothContributionPercentage
                        )}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
            <Grid item zero={4}>
              <Box display="flex" justifyContent="end">
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    borderRadius: "8px",
                    fontWeight: 700,
                    fontSize: "10px",
                    maxWidth: "52px",
                    maxHeight: "23px",
                  }}
                  onClick={() => navigate("/dashboard/employee/contributions")}
                >
                  Update
                </Button>
              </Box>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Typography
          fontWeight={400}
          fontSize={"12px"}
          lineHeight={"18px"}
          color="#75756F"
        >
          *This is the percentage of your paycheck you have currently allocated
          towards your retirement savings.
        </Typography>
      </CardContent>
    </Card>
  );
}
