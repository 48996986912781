import { useState, useEffect, BaseSyntheticEvent } from "react";

export interface ISingleAndDoubleClick {
  actionSimpleClick?: Function;
  actionDoubleClick?: Function;
  delay?: number;
}

export const useSingleAndDoubleClick = (props: ISingleAndDoubleClick) => {
  const {
    actionSimpleClick = () => {},
    actionDoubleClick = () => {},
    delay = 250,
  } = props;
  const [click, setClick] = useState(0);
  const [event, setEvent] = useState<BaseSyntheticEvent>();

  useEffect(() => {
    const func = () => {
      if (click === 1) {
        setClick((prev) => prev + 1);
        actionSimpleClick(event);
      }
      if (click === 2) {
        actionDoubleClick(event);
        setClick(0);
        return;
      }
    };
    func();
    const timer = setInterval(func, delay);
    return () => clearTimeout(timer);
  }, [click]);

  return (event: BaseSyntheticEvent) => {
    setEvent(event);
    setClick((prev) => prev + 1);
  };
};
