import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CompanyDto } from 'core/models/companyDto.model';
import { EmployeeDetailDto } from 'core/models/employeeDto.model';
import ApiService from 'core/services/api.service';
import { IResetPassword } from 'core/models/resetPassword.model';

interface ResetPasswordState {
  error: string;
  companyList: CompanyDto[];
  employeeList: EmployeeDetailDto[];
}

const initialState: ResetPasswordState = {
  error: '',
  companyList: [],
  employeeList: [],
};

export const resetPasswordAsync = createAsyncThunk(
  'resetPassword/resetNewPassword',
  async (body: IResetPassword): Promise<any> => {
    const response = await ApiService.resetNewPassword(body);
    return response.data;
  }
);

const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetPasswordAsync.pending, (state) => {
        state.error = '';
      })
      .addCase(resetPasswordAsync.fulfilled, (state) => {});
  },
});

const resetPasswordSliceReducer = resetPasswordSlice.reducer;
export default resetPasswordSliceReducer;
