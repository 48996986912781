import {
  RadioGroupProps as MuiRadioGroupProps,
  RadioGroup as MuiRadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import clsx from 'clsx';
import { forwardRef, ReactNode } from 'react';
import { FormControl } from '../FormControl';
import { SelectItem } from '../Select';

type RadioGroupProps = {
  data?: SelectItem[];
  label?: ReactNode;
  value?: string | number;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  orientation?: 'horizontal' | 'vertical';
  disabled?: boolean;
  error?: string;
} & MuiRadioGroupProps;

export const RadioGroup = forwardRef(
  (
    {
      className,
      data,
      id,
      label,
      orientation = 'horizontal',
      value,
      onChange,
      disabled,
      error,
      ...props
    }: RadioGroupProps,
    ref
  ) => {
    return (
      <FormControl label={label}>
        <MuiRadioGroup
          ref={ref}
          aria-labelledby={id}
          className={clsx(
            'flex flex-row',
            {
              'flex-col space-y-2': orientation === 'vertical',
              'flex-row items-center space-x-4 flex-nowrap': orientation === 'horizontal',
            },
            className
          )}
          {...props}
        >
          {data?.map((item) => (
            <FormControlLabel
              key={item.value.toString()}
              classes={{ label: 'text-sm font-normal' }}
              className="mr-0 first:ml-0"
              value={item.value}
              sx={{
                '&.Mui-disabled': {
                  cursor: 'not-allowed',
                  color: (theme) => `${theme.palette.disabled} !important`,
                  '.MuiFormControlLabel-label': {
                    color: (theme) => theme.palette.disabled,
                  },
                },
              }}
              control={
                <Radio
                  disabled={disabled}
                  disableRipple
                  checked={value === item.value}
                  onChange={onChange}
                  classes={{
                    root: clsx('p-0 mr-2'),
                  }}
                  sx={{
                    color: (theme) => `${error ? theme.palette.errorColor : theme.palette.lightBeige} !important`,
                    '&.Mui-checked': {
                      color: (theme) => `${theme.palette.secondaryColor} !important`,
                    },
                  }}
                />
              }
              label={item.label}
            />
          ))}
        </MuiRadioGroup>
      </FormControl>
    );
  }
);
