const AccountTreeIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8_16105)">
        <path
          d="M18.3333 9.66667V3H12.5V5.5H7.5V3H1.66667V9.66667H7.5V7.16667H9.16667V15.5H12.5V18H18.3333V11.3333H12.5V13.8333H10.8333V7.16667H12.5V9.66667H18.3333ZM5.83333 8H3.33333V4.66667H5.83333V8ZM14.1667 13H16.6667V16.3333H14.1667V13ZM14.1667 4.66667H16.6667V8H14.1667V4.66667Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_8_16105">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AccountTreeIcon;
