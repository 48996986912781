import publicApi from './utils/HttpRequest';
import { getApiPath } from './utils/routesUtils';

export const getSummary = () => {
  return publicApi.get('/api/cpa/summary');
};

export const getClients = (params: any) => {
  const path: string = getApiPath({ path: '/api/cpa/clients' }, params);
  return publicApi.get(path);
};

const getClientsActive = () => {
  return publicApi.get('/api/cpa/clients/ACTIVE');
};

export const sendInvites = (params: { data: any[]; resend: boolean }) => {
  return publicApi.post('/api/cpa/invites', params);
};

export const terminateClients = (params: { companyIds: any[] }) => {
  return publicApi.patch('/api/cpa/clients/_DELETE', params);
};

export const getListCompanyCpa = (filter: any) => {
  const path = getApiPath('/api/cpa', { filter });
  return publicApi.get(path);
};

export const createCpaCompany = (body: any) => {
  return publicApi.put('/api/cpa', body);
};

export const grantCpaCompany = (params: any) => {
  return publicApi.patch(`/api/cpa/creates`, params);
};

export const deleteCpaCompany = (companyId: string) => {
  return publicApi.delete(`/api/cpa/${companyId}`);
};

export const getListUserCpa = (filter: any) => {
  const path = getApiPath('/api/cpa/admins', { filter });
  return publicApi.get(path);
};

export const createCpaUser = (params: any) => {
  const { companyId, ...body } = params;
  return publicApi.put(`/api/cpa/${companyId}/admins`, body);
};

export const grantCpaUser = (params: any) => {
  const { companyId, userProfileIds } = params;
  return publicApi.patch(`/api/cpa/${companyId}/admins`, {
    userProfileIds,
  });
};

export const updateCpaUser = (params: any) => {
  const { ...body } = params;
  return publicApi.patch(`/api/cpa/admins`, body);
};

export const deleteCpaUser = (params: any) => {
  const { companyId, userProfileId } = params;
  return publicApi.delete(`/api/cpa/${companyId}/admins/${userProfileId}`);
};

export const getCpaPlanAdmins = () => {
  return publicApi.get('/api/cpa/admins');
};

export const updateCpaPlanAdmins = (body: any) => {
  return publicApi.patch('/api/cpa/admins', body);
};

const CPAService = {
  getSummary,
  getClients,
  getClientsActive,
  sendInvites,
  terminateClients,

  getListCompanyCpa,
  createCpaCompany,
  grantCpaCompany,
  deleteCpaCompany,

  getListUserCpa,
  createCpaUser,
  grantCpaUser,
  updateCpaUser,
  deleteCpaUser,

  getCpaPlanAdmins,
  updateCpaPlanAdmins,
};

export default CPAService;
