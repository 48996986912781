import { DateFormatPattern } from "core/enums/date-format-pattern.enum";
import moment, { Moment } from "moment";

export const formattedDateWithCurrentTimeSendToServer = (
  value: Moment | string | number
) => {
  if (!value) return "N/A";
  const date = moment(value).format(DateFormatPattern.YearMonthDay);
  const nowTime = moment().format("HH:mm:ss.SSS");
  const timeZoneOffSet = moment().utcOffset();

  return moment(`${date} ${nowTime}`).utcOffset(timeZoneOffSet).toISOString();
};

export const formatDateSendToServer = (value: Moment | string | number) => {
  if (!value) return "N/A";
  return (
    moment(value).format(DateFormatPattern.YearMonthDay) + "T00:00:00.000Z"
  );
};
