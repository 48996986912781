import React from "react";
import { NavLink as NavLinkBase } from "react-router-dom";

const NavLink = React.forwardRef<any, any>((props, ref) => (
  <NavLinkBase
    ref={ref}
    to={props.to}
    className={({ isActive }) =>
      `${props.className} ${isActive ? props.activeClassName : ""}`
    }
    end
  >
    {props.children}
  </NavLinkBase>
));

export default NavLink;
