import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import React from 'react';
import TableWrapper from './TableWrapper';
import { appPalette } from 'core/constants';
import { CustomNoRowsOverlay } from 'common/components';

type Props = DataGridProps & {
  emptyLocaleText?: string;
};

export const TableV2: React.FC<Props> = ({
  rows,
  columns,
  sx,
  emptyLocaleText = 'No records found',
  initialState = {},
  ...props
}) => {
  return (
    <TableWrapper>
      <DataGrid
        getEstimatedRowHeight={() => 100}
        getRowHeight={() => 'auto'}
        sx={{
          width: '100%',
          '& .MuiDataGrid-columnHeaders': {
            minHeight: '32px !important',
            borderRadius: '8px',
          },
          '& .MuiDataGrid-columnHeaderTitle': {
            color: '#181C11',
            fontWeight: 600,
          },
          '.MuiCheckbox-root': {
            color: (theme) => `${theme.palette.lightBeige} !important`,
          },
          '.MuiDataGrid-cellCheckbox.MuiDataGrid-cellCheckbox': {
            outline: 'none',
            border: 'none',
          },
          '.Mui-checked': {
            color: (theme) => `${theme.palette.secondaryColor} !important`,
          },
          '& .MuiDataGrid-row': {
            cursor: 'pointer',
            borderBottom: (theme) => `1px solid ${theme.palette.lightGreen}`,
          },
          '& .MuiDataGrid-cell': {
            '&:focus': {
              outline: 'none',
            },
            fontSize: '12px',
            fontWeight: 400,
            color: '#222917',
          },
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': {
            py: '6px',
          },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
            py: '16px',
          },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': {
            py: '22px',
          },
          '& .MuiDataGrid-columnHeader': {
            '&:focus,:focus-within': {
              outline: 'none',
            },
            backgroundColor: appPalette.lightBeige,
            fontSize: '11px',
            height: '32px !important',
          },
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-columnHeader': {
            height: '56px',
          },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-columnHeader': {
            height: '56px',
          },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-columnHeader': {
            height: '56px',
          },
          '& .MuiDataGrid-footerContainer': {
            border: 'none',
            '.MuiTablePagination-toolbar': {
              color: (theme) => theme.palette.normalText,
            },
            '.MuiTablePagination-selectIcon': {
              color: (theme) => theme.palette.normalText,
            },
          },
          '.MuiDataGrid-overlayWrapper': {
            height: !rows.length ? 400 : 0,
          },
          '.MuiDataGrid-overlayWrapperInner': {
            height: !rows.length ? '100% !important' : 0,
          },
          '.MuiDataGrid-virtualScrollerContent': {
            display: rows.length > 0 ? 'block' : 'none',
          },
          ...sx,
        }}
        autoHeight={true}
        disableRowSelectionOnClick={true}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
              page: 0,
            },
          },
          ...initialState,
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        getRowSpacing={() => ({
          bottom: 10,
        })}
        hideFooter={props.paginationMode === 'server' ? (props.rowCount || 0) <= 10 : rows.length <= 10}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          noResultsOverlay: CustomNoRowsOverlay,
        }}
        slotProps={{
          noRowsOverlay: { label: emptyLocaleText },
          noResultsOverlay: { label: emptyLocaleText },
          loadingOverlay: {
            sx: {
              '.MuiCircularProgress-root': {
                color: (theme) => theme.palette.secondaryColor,
              },
            },
          },
        }}
        {...props}
      />
    </TableWrapper>
  );
};
