import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { REQUEST_STATUS } from 'core/constants/roles';
import { TLoanBasicInfoResponse, TLoanResponse } from 'core/models/employeeLoan.model';
import EmployeeService from 'core/services/employee.service';

interface ILoansState {
  getLoansStatus: string;
  getBasicInfoStatus: string;
  createLoanStatus: string;
  updateLoanStatus: string;
  loans: TLoanResponse[];
  loan: TLoanResponse | null;
  basicInfo: TLoanBasicInfoResponse | null;
  defaultPlanType: string;
}

const initialState: ILoansState = {
  getLoansStatus: REQUEST_STATUS.IDLE,
  getBasicInfoStatus: REQUEST_STATUS.IDLE,
  createLoanStatus: REQUEST_STATUS.IDLE,
  updateLoanStatus: REQUEST_STATUS.IDLE,
  loans: [],
  loan: null,
  basicInfo: null,
  defaultPlanType: '',
};

export const requestPayOffLoanAsync = createAsyncThunk(
  'employeeLoan/requestPayOffLoanAsync',
  async (payload: any): Promise<any> => {
    const response = await EmployeeService.requestPayOffLoan(payload);
    return response.data;
  }
);

export const getLoansAsync = createAsyncThunk('employeeLoan/getLoansAsync', async (): Promise<any> => {
  const response = await EmployeeService.getLoans();
  return response.data;
});

export const getBasicInformationAsync = createAsyncThunk(
  'employeeLoan/getBasicInformationAsync',
  async (): Promise<any> => {
    const response = await EmployeeService.getBasicInformation();
    return response.data;
  }
);

export const createLoanAsync = createAsyncThunk('employeeLoan/createLoanAsync', async (payload: any): Promise<any> => {
  const response = await EmployeeService.createLoan(payload);
  return response.data;
});

export const updateLoanAsync = createAsyncThunk('employeeLoan/updateLoanAsync', async (payload: any): Promise<any> => {
  const response = await EmployeeService.updateLoan(payload);
  return response.data;
});

const employeeLoanSlice = createSlice({
  name: 'employeeLoan',
  initialState,
  reducers: {
    updateBasicInfo: (state, action) => {
      const { basicInfo = {} } = state;
      state.basicInfo = { ...basicInfo, ...action.payload };
    },
    setDefaultPlanType: (state, action) => {
      state.defaultPlanType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLoansAsync.pending, (state) => {
        state.getLoansStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(getLoansAsync.fulfilled, (state, action) => {
        state.getLoansStatus = REQUEST_STATUS.SUCCESS;
        state.loan = action.payload;
      })
      .addCase(getLoansAsync.rejected, (state) => {
        state.getLoansStatus = REQUEST_STATUS.ERROR;
      })

      .addCase(getBasicInformationAsync.pending, (state) => {
        state.getBasicInfoStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(getBasicInformationAsync.fulfilled, (state, action) => {
        state.getBasicInfoStatus = REQUEST_STATUS.SUCCESS;
        state.basicInfo = action.payload;
      })
      .addCase(getBasicInformationAsync.rejected, (state) => {
        state.getBasicInfoStatus = REQUEST_STATUS.ERROR;
      })

      .addCase(createLoanAsync.pending, (state) => {
        state.createLoanStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(createLoanAsync.fulfilled, (state, action) => {
        state.createLoanStatus = REQUEST_STATUS.SUCCESS;
        state.loan = action.payload;
      })
      .addCase(createLoanAsync.rejected, (state) => {
        state.createLoanStatus = REQUEST_STATUS.ERROR;
      })

      .addCase(updateLoanAsync.pending, (state) => {
        state.updateLoanStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(updateLoanAsync.fulfilled, (state, action) => {
        state.updateLoanStatus = REQUEST_STATUS.SUCCESS;
        state.loan = action.payload;
      })
      .addCase(updateLoanAsync.rejected, (state) => {
        state.updateLoanStatus = REQUEST_STATUS.ERROR;
      })
      .addCase(requestPayOffLoanAsync.pending, (state) => {
        state.updateLoanStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(requestPayOffLoanAsync.fulfilled, (state, action) => {
        state.updateLoanStatus = REQUEST_STATUS.SUCCESS;
        state.loan = action.payload;
      })
      .addCase(requestPayOffLoanAsync.rejected, (state) => {
        state.updateLoanStatus = REQUEST_STATUS.ERROR;
      });
  },
});

export const { updateBasicInfo, setDefaultPlanType } = employeeLoanSlice.actions;

export default employeeLoanSlice.reducer;
