import { REGEX_PATTERN } from './validate-pattern';
import { CONFIG } from './config';
import { RESET_PASSWORD_ACTION } from './reset-password-action';
import { ROUTES } from './routes-path';
import { DISTRIBUTION_OPTIONS } from './employee-distributions';

export const YES_NO_OPTION = [
  {
    label: 'Yes',
    value: 'yes',
  },
  { label: 'No', value: 'no' },
];

export const LOAN_SCREENS_SUBTITLE = [
  { step: 1, title: 'Complete the next form' },
  { step: 2, title: 'Complete the next form' },
  { step: 3, title: 'Loan Amortization Schedule' },
  { step: 4, title: 'Now, just read the terms and conditions' },
  { step: 5, title: "That's it!" },
];

export const LOAN_SCREENS = {
  ParticipantDetails: 0,
  LoanDetails: 1,
  LoanAmortizationSchedule: 2,
  TermsAndConditions: 3,
  LoanComplete: 4,
};

export const CONTRIBUTION_LOGS_STATUS = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
};

export const LOAN_LOGS_STATUS = {
  PENDING: 'PENDING',
  COMPLETED: 'COMPLETED',
};

export const TYPES_OF_COMPANY = {
  SOLE_PROPRIETORSHIP: 'Sole Proprietorship',
  PARTNERSHIP: 'Partnership',
  CORPORATION: 'Corporation',
  LLC: 'LLC',
  OTHER: 'Other',
};

export const REGISTRATION_TYPE = {
  C_CORPORATION: 'C Corporation',
  S_CORPORATION: 'S Corporation',
  NON_PROFIT: 'Non Profit',
  PARTNERSHIP: 'Partnership',
  LIMITED_LIABILITY_COMPANY: 'Limited Liability Company',
  LIMITED_LIABILITY_PARTNERSHIP: 'Limited Liability Partnership',
  SOLE_PROPRIETORSHIP: 'Sole Proprietorship',
  UNION: 'Union',
  GOVERNMENT_AGENCY: 'Government Agency',
  OTHER: 'Other',
};

export const appPalette = {
  primary: '#3B471C',
  secondary: '#69AB00',
  white: '#fff',
  lightGrey: '#EAEAEB',
  darkBlue: '#3B471C',
  lightGreen: '#E3F2E3',
  green: '#6FD1BE',
  grey: '#717476',
  darkGrey: '#343D46',
  darkGreen: '#3B471C',
  mediumGrey: '#BCBCBD',
  black: '#000000',
  lightBlack: '#283037',
  darkBlack: '#2C2D2E',
  orange: '#f58220',
  accent: '#048BBA',
  lightAccent: '#1DA1F2',
  red: '#FF4357',
  violet: '#1C165C',
  purple: '#660FF2',
  lightPurple: '#B4AFDA',
  mediumGreen: '#88D014',
  beige: '#9E9E91',
  lightBeige: '#F4F3E9',
  borderColor: '#F4F3E9',
  borderHover: '#88D014',
  normalText: '#3B471C',
  title: '#3B471C',
  subTitle: '#9E9E91',
  checkBoxSecondary: '#69AB00',
  checkBoxLightBeige: '#F4F3E9',
  selected: '#69AB00',
  button: '#69AB00',
  frameBackground: '#E3F2E3',
  label: '#3B471C',
  disabled: '#BCBCBD',
  icon: '#69AB00',
  textLink: '#69AB00',
  textLinkHover: '#88D014',
  tableHeading: '#9E9E91',
  tableContent: '#3B471C',
  tabSelectedBackground: '#88D014',
  errorColor: '#d32f2f',
  dividerColor: '#F4F3E9',
  warningColor: '#FABC38',
  active: '#25AC65',
  new: '#0981D8',
  approved: '#69AB00',
  newLead: '#fabc38',
  verified: '#9E9E91',
  onboarding: '#EDA814',
  pending: '#75756F',
  invited: '#fabc38',
  terminated: '#BCBCBD',
  redPeach: '#FF7575',
  darkest: '#222917',
  superBlack: '#181C11',
  deepGrey: '#75756F',
  bgTooltip: '#222917',
  textTooltip: '#FCFAFA',
};

export const EXTENSION_MIME_TYPE = {
  doc: 'application/msword',
  dot: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  dotx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  docm: 'application/vnd.ms-word.document.macroEnabled.12',
  dotm: 'application/vnd.ms-word.template.macroEnabled.12',
  xls: 'application/vnd.ms-excel',
  xlt: 'application/vnd.ms-excel',
  xla: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xltx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  xlsm: 'application/vnd.ms-excel.sheet.macroEnabled.12',
  xltm: 'application/vnd.ms-excel.template.macroEnabled.12',
  xlam: 'application/vnd.ms-excel.addin.macroEnabled.12',
  xlsb: 'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  ppt: 'application/vnd.ms-powerpoint',
  pot: 'application/vnd.ms-powerpoint',
  pps: 'application/vnd.ms-powerpoint',
  ppa: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  potx: 'application/vnd.openxmlformats-officedocument.presentationml.template',
  ppsx: 'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  ppam: 'application/vnd.ms-powerpoint.addin.macroEnabled.12',
  pptm: 'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
  potm: 'application/vnd.ms-powerpoint.template.macroEnabled.12',
  ppsm: 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
  pdf: 'application/pdf',
  mdb: 'application/vnd.ms-access',
  csv: 'text/csv',
  gif: 'image/gif',
  jpeg: 'image/jpeg',
  jpg: 'image/jpeg',
  png: 'image/png',
};

const PLAN_TYPES = [
  {
    label: 'Individual Retirement Arrangements',
    value: 'INDIVIDUAL_RETIREMENT_ARRANGEMENTS',
  },
  { label: 'Roth IRAs', value: 'ROTH_IRAS' },
  { label: '401(k) Plans', value: '401K_PLANS' },
  { label: 'SIMPLE 401(k) Plans', value: 'SIMPLE_401K_PLANS' },
  { label: '403(b) Plans', value: '403B_PLANS' },
  {
    label: 'SIMPLE IRA Plans (Savings Incentive Match Plans for Employees)',
    value: 'SIMPLE_IRA_PLANS_SAVINGS_INCENTIVE_MATCH_PLANS_FOR_EMPLOYEES',
  },
  {
    label: 'SEP Plans(Simplified Employee Pension)',
    value: 'SEP_PLANS_SIMPLIFIED_EMPLOYEE_PENSION',
  },
  {
    label: 'SARSEP Plans (Salary Reduction Simplified Employee Pension)',
    value: 'SARSEP_PLANS_SALARY_REDUCTION_SIMPLIFIED_EMPLOYEE_PENSION',
  },
  { label: 'Payroll Deduction IRAs', value: 'PAYROLL_DEDUCTION_IRAS' },
  { label: 'Profit-Sharing Plans', value: 'PROFIT_SHARING_PLANS' },
  { label: 'Defined Benefit Plans', value: 'DEFINED_BENEFIT_PLANS' },
  { label: 'Money Purchase Plans', value: 'MONEY_PURCHASE_PLANS' },
  {
    label: 'Employee Stock Ownership Plans (ESOPs)',
    value: 'EMPLOYEE_STOCK_OWNERSHIP_PLANS_ESOPS',
  },
  { label: 'Governmental Plans', value: 'GOVERNMENTAL_PLANS' },
  { label: '457 Plans', value: '457_PLANS' },
];

export const EMPLOYER_SCREENS = {
  Welcome: 1,
  BeforeYouBegin: 2,
  CreateCredentials: 3,
  SponsorDetails: 4,
  CompanyDetails: 5,
  ComplianceDisclosuresHave80OwnershipOfAnotherBusiness: 6,
  ComplianceDisclosuresCurrentCompanyOfferingRetirementPlanQuestion: 7,
  OtherBusinessesIncluded401kPlanQuestion: 8,
  MultipleBusinessesCollectInfo: 9,
  ControlGroup: 10,
  CurrentCompanyRetirementPlanCollectInfo: 11,
  MultipleBusinesses: 12,
  RegulatoryDisclosures: 13,
  PickYourPlan: 14,
  ConnectToPayroll: 15,
  BankAccountVerification: 16,
  AlternativeBankAccountVerification: 17,
  PlanDocuments: 18,
  TransitionPage_1: 19,
  TransitionPage_2: 20,
  TransitionPage_3: 21,
  TransitionPage_End: 22,
};

export const EMPLOYEE_SCREENS = {
  Welcome: 1,
  CreateYourLogin: 2,
  AccountPreference: 3,
  PortfolioPreference: 4,
  PortfolioRecommendation: 5,
  SetContributionAmount: 6,
  TermAndConditionsEmployee: 7,
  OptOutForm: 8,
  AllDone: 9,
};

export const EMPLOYER_SCREENS_NAME = {
  CREATE_CREDENTIAL: 'CreateCredentials',
  AUTHORIZE_SIGNATORY: 'SponsorDetails',
  AUTHORIZED: 'SponsorDetails',
  IDENTITY_VERIFICATION: 'SponsorDetails',
  FINCH_CONNECTION: 'ConnectToPayroll',
  COMPANY_DETAILS: 'CompanyDetails',
  COMPLIANCE_DISCLOSURES: 'ComplianceDisclosuresHave80OwnershipOfAnotherBusiness',
  RETIREMENT_PLAN: 'ComplianceDisclosuresCurrentCompanyOfferingRetirementPlanQuestion',
  RETIREMENT_PLAN_COLLECT_INFO: 'CurrentCompanyRetirementPlanCollectInfo',
  REGULATORY_DISCLOSURES: 'RegulatoryDisclosures',
  PLAN_CUSTOMIZATION: 'PickYourPlan',
  PLAID_VERIFICATION: 'BankAccountVerification',
  ALTERNATIVE_BANK_ACCOUNT_VERIFICATION: 'AlternativeBankAccountVerification',
  PLAN_DOCUMENTS: 'PlanDocuments',
  ALL_DONE: 'TransitionPage_3',
  MULTIPLE_BUSINESSES_INCLUDED_401K: 'OtherBusinessesIncluded401kPlanQuestion',
  MULTIPLE_BUSINESSES_COLLECT_INFO: 'MultipleBusinessesCollectInfo',
  MULTIPLE_BUSINESSES_COLLECT_DETAIL_INFO: 'ControlGroup',
};

export const EMPLOYER_STEPS = [
  {
    title: 'Create your login credentials',
    hidden: true,
    step: 1,
    onboardingStep: EMPLOYER_SCREENS.CreateCredentials,
  },
  {
    title: 'Authorized Signatory Details',
    subTitle: 'An Authorized Signatory is a person allowed to act on behalf of the business',
    step: 2,
    onboardingStep: EMPLOYER_SCREENS.SponsorDetails,
  },
  {
    title: 'Company Details',
    subTitle: "Now that we've met, let's dive into your business!",
    step: 3,
    onboardingStep: EMPLOYER_SCREENS.CompanyDetails,
  },
  {
    title: 'Compliance Disclosures',
    subTitle: 'These questions are just to make sure we set up the right kind of plan for your organization. ',
    step: 4,
    onboardingStep: EMPLOYER_SCREENS.ComplianceDisclosuresHave80OwnershipOfAnotherBusiness,
  },
  {
    title: 'Compliance Disclosures',
    subTitle: 'These questions are just to make sure we set up the right kind of plan for your organization. ',
    step: 4,
    onboardingStep: EMPLOYER_SCREENS.ComplianceDisclosuresCurrentCompanyOfferingRetirementPlanQuestion,
  },
  {
    title: 'Multiple Businesses',
    subTitle:
      'In the previous page, you answered Yes to the question “Do the owners of this organization have more than 80% ownership of another business”. We kindly request clarification regarding the number of additional businesses you oversee and the respective employee counts within each. If this response was a mistake, please click back and modify your answer.',
    step: 4,
    onboardingStep: EMPLOYER_SCREENS.OtherBusinessesIncluded401kPlanQuestion,
  },
  {
    title: 'Multiple Businesses',
    subTitle:
      'In the previous page, you answered Yes to the question “Do the owners of this organization have more than 80% ownership of another business”. We kindly request clarification regarding the number of additional businesses you oversee and the respective employee counts within each. If this response was a mistake, please click back and modify your answer.',
    step: 4,
    onboardingStep: EMPLOYER_SCREENS.MultipleBusinessesCollectInfo,
  },
  {
    title: 'Control Groups',
    subTitle:
      'Please provide the Registered Business Name, and Employer Identification Number (EIN) and Retirement Plan (if any) for each of the businesses you own.',
    step: 4,
    onboardingStep: EMPLOYER_SCREENS.ControlGroup,
  },
  {
    title: 'Current Retirement Plan',
    subTitle:
      'We’d love to know a few details about your current retirement plan so that we can ensure we’re a right fit your organization. ',
    step: 4,
    onboardingStep: EMPLOYER_SCREENS.CurrentCompanyRetirementPlanCollectInfo,
  },
  {
    title: 'Regulatory Disclosures',
    subTitle:
      'We understand these questions can be quite intricate, but we are legally required to ask them. Hang in there, these are the last of them!',
    step: 5,
    onboardingStep: EMPLOYER_SCREENS.RegulatoryDisclosures,
  },
  {
    title: 'Customize Your Plan',
    step: 6,
    onboardingStep: EMPLOYER_SCREENS.PickYourPlan,
  },
  {
    title: 'Connect To Payroll',
    subTitle: 'Sync payroll, save time effortlessly!',
    hidden: true,
    step: 7,
    onboardingStep: EMPLOYER_SCREENS.ConnectToPayroll,
  },
  {
    title: 'Bank Account Verification',
    hidden: true,
    step: 8,
    onboardingStep: EMPLOYER_SCREENS.BankAccountVerification,
  },
  {
    title: 'Alternative Bank Account Verification',
    hidden: true,
    step: 8,
    onboardingStep: EMPLOYER_SCREENS.AlternativeBankAccountVerification,
  },
  {
    title: 'Plan Documents',
    hidden: true,
    step: 9,
    onboardingStep: EMPLOYER_SCREENS.PlanDocuments,
  },
];

export const WELCOME_STEP = [
  {
    step: 1,
    title: 'Share your authorized signatory details',
  },
  {
    step: 2,
    title: 'Provide your company details',
  },
  {
    step: 3,
    title: 'Customize your company’s  401(k) plan',
  },
  {
    step: 4,
    title: 'Sign Plan Documents',
  },
];

export const RUN_PAYROLL_SEPARATE_OPTIONS = [
  {
    label: 'Separate Payroll for Each Business',
    value: 'SEPARATE_FOR_EACH',
  },
  {
    label: 'Combined Payroll for All Businesses',
    value: 'COMBINED_FOR_ALL',
  },
  { label: "I'm Not Sure", value: 'NOT_SURE' },
];

export const NUMBER_OF_BUSINESSES_THEY_OWN_OPTIONS = Array.from({ length: 15 }, (_, i) => ({
  label: String(i + 1),
  value: String(i + 1),
}));

export { CONFIG, ROUTES, RESET_PASSWORD_ACTION, REGEX_PATTERN, DISTRIBUTION_OPTIONS, PLAN_TYPES };

export const NUMBER_OF_BUSINESSESS = [
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '2',
  },
  {
    label: '3',
    value: '3',
  },
  {
    label: '4',
    value: '4',
  },
  {
    label: '5',
    value: '5',
  },
  {
    label: '6',
    value: '6',
  },
  {
    label: '7',
    value: '7',
  },
  {
    label: '8',
    value: '8',
  },
  {
    label: '9',
    value: '9',
  },
  {
    label: '10',
    value: '10',
  },
  {
    label: '11',
    value: '11',
  },
  {
    label: '12',
    value: '12',
  },
  {
    label: '13',
    value: '13',
  },
  {
    label: '14',
    value: '14',
  },
  {
    label: '15',
    value: '15',
  },
];

export const ENROLLMENT_TYPE = {
  PROCEED_WITH_AUTO_ENROLLMENT: 'PROCEED_WITH_AUTO_ENROLLMENT',
  OPT_OUT_OF_CONTRIBUTION: 'OPT_OUT_OF_CONTRIBUTION',
  CUSTOMIZATION: 'CUSTOMIZATION',
};

export const FINCH_STATUS_OPTIONS = [
  {
    label: 'Re-Auth Required',
    value: 'REAUTH',
  },
  {
    label: 'No Account Set Up',
    value: 'NO_ACCOUNT_SET_UP',
  },
  {
    label: 'Processing',
    value: 'PROCESSING',
  },
  {
    label: 'Pending',
    value: 'PENDING',
  },
  {
    label: 'Connected',
    value: 'CONNECTED',
  },
  {
    label: 'Improper Permissions',
    value: 'ERROR_PERMISSIONS',
  },
];

export const DASHBOARD_ROUTES = [
  '/dashboard/employer',
  '/dashboard/employee',
  '/cpa/dashboard',
  '/pep/dashboard',
  '/corp/dashboard',
];
