import { CookieNames } from 'core/enums/cookie-names.enum';
import Cookies from 'universal-cookie';

const $cookies = new Cookies();

export const setCookie = (name: CookieNames, value: any): void => {
  $cookies.remove(name, { path: '/' });
  $cookies.set(name, value, { path: '/' });
};

export const getCookie = <T = any>(name: CookieNames): T => {
  return $cookies.get(name);
};

export const removeCookie = (name: CookieNames): void => {
  $cookies.remove(name, { path: '/' });
};

export const removeAllCookie = (): void => {
  Object.keys($cookies.getAll()).forEach((key) => {
    $cookies.remove(key, { path: '/' });
  });
};
