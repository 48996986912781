import { CompanyStatus } from "core/enums/company.enum";
import { EmployeeStatus } from "core/enums/employee-status.enum";

type WithName = {
  firstName?: string;
  lastName?: string;
  [key: string]: any;
};

export const getFullName = (obj?: WithName) => {
  return `${obj?.firstName || ""} ${obj?.lastName || ""}`.trim();
};

export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLocaleLowerCase();
};

export const capitalizeConst = (str: string) => {
  if (!str) return "";
  return str
    .split("_")
    .map((word) => capitalize(word))
    .join(" ");
};

export const getStatusColor = (status: string) => {
  switch (status) {
    case CompanyStatus.ACTIVE:
    case CompanyStatus.APPROVED:
    case CompanyStatus.VERIFIED:
    case EmployeeStatus.ACTIVE:
      return "text-success";
    case CompanyStatus.NEW:
    case CompanyStatus.NEW_LEAD:
    case EmployeeStatus.NEW:
      return "text-info";
    case CompanyStatus.ONBOARDING:
    case EmployeeStatus.INVITED:
      return "text-error";
    case CompanyStatus.PENDING:
    case CompanyStatus.DRAFT:
    case EmployeeStatus.EMPLOYMENT_ENDED:
      return "text-mediumGrey";
    default:
      return "";
  }
};
