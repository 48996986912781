import { Divider } from '@mui/material';
import { Button, TextFieldWithMask } from 'common/components';
import { REGEX_PATTERN } from 'core/constants';
import { REQUEST_STATUS, TWO_FA_METHOD, TWO_FA_STATUS } from 'core/constants/roles';
import { convertPhoneNumber, normalizePhoneNumber, formatPhoneNumberDB } from 'utils/input-formatter';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'states/hooks';
import { resendCodeSMS2FAAsync } from 'states/manageEmployee/manageEmployeeSlice';

interface IReceiveCodeBySMSProps {
  handleEnabledTwoFA: Function;
}

const ReceiveCodeBySMS = ({ handleEnabledTwoFA }: IReceiveCodeBySMSProps) => {
  const enabledTwoFAStatus = useAppSelector((state) => state.manageEmployee.enabledTwoFAStatus);
  const resendCodeSMSStatus = useAppSelector((state) => state.manageEmployee.resendCodeSMSStatus);
  const twoFA = useAppSelector((state) => state.manageEmployee.twoFA);
  const employeeProfile = useAppSelector((state) => state.manageEmployee.employeeProfile);
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      phoneNumber: formatPhoneNumberDB(employeeProfile?.person?.phone || ''),
    },
  });
  const allValues = watch();
  const disabledSubmit = !(allValues.phoneNumber && !errors.phoneNumber);
  const handleOnSubmit = ({ phoneNumber }: any) => {
    if (disabledSubmit || enabledTwoFAStatus === REQUEST_STATUS.SENDING) {
      return;
    }
    handleEnabledTwoFA(TWO_FA_METHOD.SMS, convertPhoneNumber(phoneNumber));
  };

  const handleOnResend = () => {
    if (!twoFA || enabledTwoFAStatus === REQUEST_STATUS.SENDING || resendCodeSMSStatus === REQUEST_STATUS.SENDING) {
      return;
    }
    dispatch(resendCodeSMS2FAAsync());
  };

  return (
    <>
      {(!twoFA || (twoFA && twoFA.status !== TWO_FA_STATUS.ACTIVE)) && (
        <>
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="xs:flex text-left w-full">
              <Controller
                control={control}
                name="phoneNumber"
                rules={{
                  required: 'Phone number is required.',
                  pattern: {
                    value: REGEX_PATTERN.PHONE_NUMBER.pattern,
                    message: REGEX_PATTERN.PHONE_NUMBER.message,
                  },
                }}
                render={({ field: { ref, ...restField } }) => (
                  <TextFieldWithMask
                    {...restField}
                    label="Phone number"
                    placeholder="(xxx) xxx xxxx"
                    format="(###) ### ####"
                    error={!!errors.phoneNumber?.message}
                  />
                )}
              />
              <div className="mt-4 xs:mt-6">
                <Button
                  className="xs:ml-4 xs:min-w-[140px] xs:w-auto max-w-full w-full xs:h-[46px]"
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={disabledSubmit}
                  loading={enabledTwoFAStatus === REQUEST_STATUS.SENDING}
                >
                  Confirm
                </Button>
              </div>
            </div>
          </form>
        </>
      )}
      {twoFA?.phoneNumber && resendCodeSMSStatus === REQUEST_STATUS.SUCCESS && (
        <p
          className={['text-sm text-primary text-left', twoFA?.status === TWO_FA_STATUS.ACTIVE ? '-mt-4' : 'mt-2'].join(
            ' '
          )}
        >
          A verification code was sent to phone number: ***-***-
          {normalizePhoneNumber(twoFA?.phoneNumber).slice(6)}
        </p>
      )}
      <div className="text-sm text-superBlack font-medium flex items-center mt-4">
        <p>Didn’t receive your code?</p>
        <span
          className={[
            'w-auto min-w-0 ml-2',
            !twoFA?.phoneNumber || resendCodeSMSStatus === REQUEST_STATUS.SENDING
              ? 'pointer-events-none text-deepGrey'
              : 'text-textLink hover:text-textLinkHover hover:cursor-pointer',
          ].join(' ')}
          onClick={handleOnResend}
        >
          Resend
        </span>
      </div>
      <Divider className="my-6 border-borderColor" />
      <p className="text-sm text-superBlack">Enter the 6-digit authentication code that we’ve sent to you:</p>
    </>
  );
};

export default ReceiveCodeBySMS;
