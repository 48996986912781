import { Alert, AlertColor } from '@mui/material';
import { FC, ReactNode } from 'react';
import { OnboardingFinchStatus } from 'core/enums';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const referLinks = (content: string) => {
  return (
    <a
      style={{
        textDecoration: 'underline',
      }}
      href="https://savedaycom.notion.site/5e6ed8fa2ac74a4b85744f05465fb070?v=7de35f631c2c4c408a621072fb52199f"
      target="_blank"
      rel="noreferrer"
    >
      {content}
    </a>
  );
};
type AlertContentProps = {
  type?: OnboardingFinchStatus;
};
const AlertContent: FC<AlertContentProps> = ({ type }) => {
  let title: ReactNode | undefined;
  let severity: AlertColor = 'success';
  let color = '#25AC65';
  let bgColor = '#E2F3EA';
  switch (type) {
    case OnboardingFinchStatus.PENDING:
      title = 'Finch connection is pending.';
      severity = 'info';
      bgColor = '#D9EDFB';
      color = '#0981D8';
      break;
    case OnboardingFinchStatus.PROCESSING:
      title = 'Finch connection is processing.';
      severity = 'info';
      bgColor = '#D9EDFB';
      color = '#0981D8';
      break;
    case OnboardingFinchStatus.NO_ACCOUNT_SET_UP:
      title = (
        <>
          {`There was an error connecting to the payroll provider.
          Error type: Third-party admin not setup`}
        </>
      );
      severity = 'warning';
      bgColor = '#FEF5E1';
      color = '#EDA814';
      break;
    case OnboardingFinchStatus.ERROR_PERMISSIONS:
      title = (
        <>
          {`There was an error connecting to the payroll provider.
          Error type: Improper Permissions`}
        </>
      );
      severity = 'warning';
      bgColor = '#FEF5E1';
      color = '#EDA814';
      break;
    case OnboardingFinchStatus.REAUTH:
      title = (
        <>
          {`There was an error connecting to the payroll provider.
          Error type: Payroll account access lapsed`}
        </>
      );
      severity = 'warning';
      bgColor = '#FEF5E1';
      color = '#EDA814';
      break;
    case OnboardingFinchStatus.CONNECTED:
      title = 'Awesome! Your payroll has been successfully integrated with Saveday.';
      severity = 'success';
      break;
  }
  if (!title) return null;

  return (
    <Alert
      severity={severity}
      iconMapping={{
        success: <CheckCircleOutlineIcon fontSize="inherit" />,
      }}
      sx={{
        '& .MuiAlert-icon': {
          color,
        },
        backgroundColor: bgColor,
      }}
    >
      <div className={`text-sm font-medium`} style={{ color, whiteSpace: 'pre-line' }}>
        {title}
      </div>

      {type === OnboardingFinchStatus.PENDING ? (
        <>
          <div className="text-xs font-normal text-info mt-2">
            We’re now reviewing that all the permission settings are correct. Some payroll providers require you to add
            Saveday as an authorized user through your payroll portal. Please find your payroll provider on this{' '}
            {referLinks('list')} and make sure you have completed all the required steps. If you have already completed
            all the steps, hang tight, the connection should be up and running in a few days.
          </div>
          <div className="text-xs font-normal text-info mt-2">
            In the meantime, please continue processing your contributions manually, until we inform you that the
            integration has been successful.
          </div>
        </>
      ) : type === OnboardingFinchStatus.PROCESSING ? (
        <>
          <div className="text-xs font-normal text-info mt-2">
            Your integration has been successful and the first data sync is in process. You no longer have to process
            your contributions manually for the following deduction types: Roth Contribution, Traditional Contribution,
            Employer Match, Loan Replacement.
          </div>
        </>
      ) : type === OnboardingFinchStatus.NO_ACCOUNT_SET_UP ? (
        <>
          <div className="text-error text-xs font-normal mt-2">
            It seems you have not added Saveday as a third-party admin. Please find your payroll provider on this{' '}
            {referLinks('list')} and make sure you have completed all the required steps through your payroll portal.
          </div>
        </>
      ) : type === OnboardingFinchStatus.ERROR_PERMISSIONS ? (
        <>
          <div className="text-error text-xs font-normal mt-2">
            We can see that you have added Saveday as a third-party admin, in your payroll portal, but with the wrong
            permission settings. Please find your payroll provider on this {referLinks('list')} and review the
            permission settings required, and make the necessary updates through your payroll portal.
          </div>
        </>
      ) : type === OnboardingFinchStatus.REAUTH ? (
        <>
          <div className="text-error text-xs font-normal mt-2">
            Sometimes payroll providers change their permission settings or require you to change your password, in
            these instances, it may disconnect Saveday’s access to your payroll. Please click the button
            <span className="font-bold">"Re-authenticate"</span>, so that we can get the integration up and running.
          </div>
        </>
      ) : (
        <></>
      )}
    </Alert>
  );
};
export default AlertContent;
