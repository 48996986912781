import {
  FormControlLabel,
  FormHelperText,
  Checkbox as MuiCheckBox,
  CheckboxProps as MuiCheckBoxProps,
} from '@mui/material';
import React from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { appPalette } from 'core/constants';

type CheckBoxProps = MuiCheckBoxProps & {
  error?: string;
  label?: React.ReactNode;
};

export const CheckBox = ({
  color = 'secondary',
  icon = <CheckBoxOutlineBlankIcon htmlColor={appPalette.lightBeige} />,
  checkedIcon = <CheckBoxIcon color="secondary" />,
  sx = {},
  label,
  error,
  ...props
}: CheckBoxProps) => {
  return (
    <>
      <FormControlLabel
        label={label}
        sx={{
          gap: '10px',
          color: (theme) => theme.palette.primaryColor,
          margin: 0,
          '.MuiCheckbox-root': {
            padding: 0,
          },
          ...sx,
        }}
        control={<MuiCheckBox color={color} icon={icon} checkedIcon={checkedIcon} {...props} />}
      />
      {error && typeof error !== 'boolean' && <FormHelperText className="text-errorColor ml-0">{error}</FormHelperText>}
    </>
  );
};
