import { Box, Typography } from '@mui/material';
import { Button, ErrorMassage, IModalUploadFilesProps } from 'common/components';
import BasicModal from 'common/components/BasicModal';
import { EXTENSION_MIME_TYPE } from 'core/constants';
import { useRef, useState } from 'react';
import { DragAndDropZone } from 'common/components/ModalUploadFiles';

const ModalUpload = (props: IModalUploadFilesProps) => {
  const {
    isOpen,
    title,
    acceptFiles,
    onSuccess,
    onCancel,
    fileType,
    onFileChange,
    maxFileSize,
    minFileSize,
    onOfflineProcess = () => {},
    isLoading = false,
    buttonSaveText = 'Save',
    subTitle,
  } = props;
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [error, setError] = useState<string>('');
  const submitButtonRef = useRef<HTMLButtonElement>(null);

  let formattedAcceptFiles: any = acceptFiles
    .replace('image/*', '.jpg, .jpeg, .gif, .png')
    .replace(/[\s.]+/g, '')
    .split(',')
    .map((item: string) => {
      if (EXTENSION_MIME_TYPE[item as keyof typeof EXTENSION_MIME_TYPE]) {
        return EXTENSION_MIME_TYPE[item as keyof typeof EXTENSION_MIME_TYPE];
      } else {
        return item;
      }
    });

  const onResetHandler = () => {
    onCancel();
    setSelectedFile(null);
    setError('');
  };
  const onChange = async (event: any) => {
    setError('');
    let file: File | null = null;
    if (event && event.target && event.type === 'change') {
      file = event.target.files && event.target.files[0];
    }
    if (event && event.dataTransfer?.items && event.dataTransfer?.items.length && event.type === 'drop') {
      file = event.dataTransfer?.items[0].getAsFile();
    }
    if (onFileChange) {
      onFileChange(file);
    }

    if (
      file &&
      maxFileSize &&
      minFileSize &&
      (file.size >= maxFileSize || file.size <= minFileSize || formattedAcceptFiles.indexOf(file.type) === -1)
    ) {
      setError(
        `Please select the file as ${acceptFiles} with file size larger than ${
          minFileSize / 1024 < 1 ? `${minFileSize} bytes` : `${minFileSize / 1024} KB`
        } and smaller than ${maxFileSize / (1024 * 1024)}MB`
      );
      setSelectedFile(null);
    } else if (file && !maxFileSize && !minFileSize && formattedAcceptFiles.indexOf(file.type) === -1) {
      setError(`Please select the file as ${acceptFiles}`);
      setSelectedFile(null);
    } else {
      setSelectedFile(file);
    }
  };

  const onSubmitHandler = async (event: any) => {
    event.preventDefault();
    if (fileType === 'OFFLINE') {
      await onOfflineProcess(selectedFile);
      return;
    }
    onSuccess?.(selectedFile);
    onResetHandler();
  };

  return (
    <>
      <BasicModal isOpen={isOpen} className="w-full max-w-[550px]" handleClose={onResetHandler}>
        <Box>
          <Typography className="text-secondary text-xl font-semibold mt-3">{title}</Typography>
          {subTitle && <Typography className="text-primary text-sm font-normal">{subTitle}</Typography>}
          <form onReset={onResetHandler}>
            <DragAndDropZone
              onChange={onChange}
              acceptFiles={acceptFiles}
              file={selectedFile}
              setSelectedFile={setSelectedFile}
            />
            {error && <ErrorMassage message={error} />}
            <div className="mt-8 flex gap-4 items-center justify-end">
              <Button variant="outlined" color="secondary" type="reset" disabled={isLoading}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                // type="submit"
                onClick={onSubmitHandler}
                disabled={!selectedFile || isLoading}
                loading={isLoading}
                ref={submitButtonRef}
              >
                {buttonSaveText}
              </Button>
            </div>
          </form>
        </Box>
      </BasicModal>
    </>
  );
};

export default ModalUpload;
