import { useEffect, useState } from "react";

export const useDetectKeyboardOpen = (defaultValue = false) => {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(defaultValue);

  useEffect(() => {
    const listener = (event: any) => {
      const newState =
        (event.target?.height * event.target?.scale) / window.screen.height <
        0.75;
      if (isKeyboardOpen !== newState) {
        setIsKeyboardOpen(newState);
      }
    };
    if (typeof visualViewport != "undefined") {
      window?.visualViewport?.addEventListener("resize", listener);
    }
    return () => {
      if (typeof visualViewport != "undefined") {
        window?.visualViewport?.removeEventListener("resize", listener);
      }
    };
  });

  return isKeyboardOpen;
};
