import { zodResolver } from '@hookform/resolvers/zod';
import { Button, PageHeader } from 'common/components';
import { ROUTES } from 'core/constants';
import { LoadingComponent } from 'components/employerManagePlan/LoadingComponent';
import React, { BaseSyntheticEvent, useEffect, useMemo, useState } from 'react';
import { FieldErrors, FieldValues, FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SchemaContext } from 'contexts/SchemaContext';
import { SingleDoubleElement } from './SingleDoubleElement';
import { useAppSelector } from 'states/hooks';
import { selectPEPManagePlanDetail } from 'states/manage-plan/managePlanSlice';
import { AccountTypes, CompanyStatus } from 'core/enums/company.enum';
import { useCollase } from 'components/employerManagePlan/Collapse';

export enum TypeButton {
  SUBMIT = 'Submit',
  DRAFT = 'Draft',
}

type ISchema = {
  [key in TypeButton]: any;
};

export const BaseManagePlan = ({
  defaultValues,
  isEdit,
  isCreatePlan,
  onSubmit,
  children,
  zodSchema,
  fetchData,
  companyName,
  resetValue,
  isLoading,
  planId,
  draftSchema,
}: {
  defaultValues: any;
  isEdit: boolean;
  onSubmit: any;
  children: React.ReactNode;
  zodSchema?: any;
  fetchData: any;
  companyName?: string;
  resetValue?: () => void;
  isCreatePlan?: boolean;
  isLoading?: boolean;
  planId?: string;
  draftSchema?: any;
}) => {
  const { company: companyDetail } = useAppSelector(selectPEPManagePlanDetail);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [firstRender, setFirstRender] = useState(false);
  const [typeBtn, setTypeBtn] = useState<TypeButton>(TypeButton.SUBMIT);
  const [schema, setSchema] = useState<ISchema>({
    [TypeButton.SUBMIT]: zodSchema,
    [TypeButton.DRAFT]: draftSchema,
  });
  const accountType = searchParams.get('accountType');
  const ZodSchema = schema
    ? {
        resolver: zodResolver(typeBtn === TypeButton.SUBMIT ? schema[TypeButton.SUBMIT] : schema[TypeButton.DRAFT]),
      }
    : {};
  const methods = useForm({
    ...ZodSchema,
    defaultValues,
    reValidateMode: 'onChange',
    mode: 'onChange',
  });
  const { onCollapseAll } = useCollase();
  const { handleSubmit, reset } = methods;
  const isHideDraftBtn =
    (isEdit && companyDetail?.status !== CompanyStatus.DRAFT) || isCreatePlan || accountType === AccountTypes.PEP;

  const goToHomePlanPage = () => {
    navigate(ROUTES.ADMIN.routes.homeplan.path);
  };

  const pageTitle = useMemo(() => {
    let actionTitle = 'Create Account';
    if (isCreatePlan) {
      actionTitle = 'Create Plan';
    }
    if (isEdit) {
      actionTitle = 'Edit Plan';
    }
    if (isEdit || isCreatePlan) {
      return `${actionTitle} - ${companyName}`;
    }
    return actionTitle;
  }, [companyName]);

  const handleFetchData = async () => {
    await fetchData();
    setFirstRender(true);
  };

  const handleCollapse = (error: FieldErrors<FieldValues>) => {
    if (Object.keys(error).length > 0) {
      const errorField = Object.keys(error)[0];
      const errorElement = document.querySelector(`[name="${errorField}"]`);
      onCollapseAll(true);
      if (errorElement) {
        //Perform scroll to error after collapse
        setTimeout(() => {
          errorElement.scrollIntoView({ behavior: 'smooth' });
          (errorElement as any).focus();
        }, 200);
      }
    }
  };

  const paramsSchema = {
    setSchema,
    initSchema: {
      [TypeButton.SUBMIT]: zodSchema,
      [TypeButton.DRAFT]: draftSchema,
    },
    typePrimarySchema: typeBtn,
  };

  useEffect(() => {
    if (firstRender) {
      reset(defaultValues);
      setSchema({
        [TypeButton.SUBMIT]: zodSchema.merge(schema[TypeButton.SUBMIT]),
        [TypeButton.DRAFT]: draftSchema,
      });
    }
  }, [firstRender]);

  useEffect(() => {
    if ((isEdit || isCreatePlan) && !companyDetail) {
      handleFetchData();
    } else {
      setFirstRender(true);
    }
    return () => {
      resetValue?.();
    };
  }, [isEdit, isCreatePlan]);

  useEffect(() => {
    reset(defaultValues);
  }, [planId]);

  return (
    <>
      <LoadingComponent isLoading={!!isLoading} />
      <PageHeader pageTitle={pageTitle} />
      <SchemaContext {...paramsSchema}>
        <div className="m-8 mt-0">
          <FormProvider {...methods}>
            <form>
              <div className="flex flex-col gap-8">{children}</div>
              <div className="flex flex-col xs:flex-row justify-end items-center gap-2 mt-8">
                <Button
                  variant="outlined"
                  className="w-full zero:max-w-full xs:max-w-[160px]"
                  onClick={goToHomePlanPage}
                  sx={{
                    background: 'white',
                  }}
                >
                  Cancel
                </Button>
                {!isHideDraftBtn && (
                  <SingleDoubleElement
                    btnProps={{
                      color: 'secondary',
                      variant: 'outlined',
                      className: 'w-full zero:max-w-full xs:max-w-[160px]',
                    }}
                    actionSimpleClick={() => {
                      if (typeBtn === TypeButton.SUBMIT) {
                        setTypeBtn(TypeButton.DRAFT);
                      }
                    }}
                    actionDoubleClick={(e: BaseSyntheticEvent) => {
                      handleSubmit(onSubmit, handleCollapse)(e);
                    }}
                  >
                    Save As Draft
                  </SingleDoubleElement>
                )}
                <SingleDoubleElement
                  btnProps={{
                    color: 'secondary',
                    variant: 'contained',
                    className: 'w-full zero:max-w-full xs:max-w-[160px]',
                  }}
                  actionSimpleClick={() => {
                    if (typeBtn === TypeButton.DRAFT) {
                      setTypeBtn(TypeButton.SUBMIT);
                    }
                  }}
                  actionDoubleClick={(e: BaseSyntheticEvent) => {
                    handleSubmit(onSubmit, handleCollapse)(e);
                  }}
                >
                  Submit
                </SingleDoubleElement>
              </div>
            </form>
          </FormProvider>
        </div>
      </SchemaContext>
    </>
  );
};
