import React from "react";
import ReactGA from "react-ga4";

interface AnalyticsProps {
  trackingId: string | undefined;
}

const Analytics: React.FC<AnalyticsProps> = ({ trackingId }) => {
  React.useEffect(() => {
    if (trackingId) {
      ReactGA.initialize(trackingId);
    } else {
      console.warn("GA tracking ID is missing!");
    }
  }, [trackingId]);

  return null; // This component does not render anything
};

export default Analytics;
