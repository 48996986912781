import { Grid } from "@mui/material";

interface IProps {
  label: string;
  value: string;
  options: any[];
}

export enum ProviderStatus {
  INTERGRATION_AVAILABLE = "Integration Available",
  CURRENTLY_UNAVAILABLE = "Currently Unavailable",
}

export const CustomOptionLabel = (props: IProps) => {
  const { label, value, options } = props;
  const status = options.find((fo) => fo?.value === value)?.status;

  const statusColorText = () => {
    return status === ProviderStatus.INTERGRATION_AVAILABLE
      ? "text-secondary"
      : "";
  };
  return (
    <Grid container justifyContent="space-between">
      <Grid item className="text-xs font-medium">
        {label}
      </Grid>
      <Grid item className={`${statusColorText()} text-xs font-normal`}>
        {status}
      </Grid>
    </Grid>
  );
};
