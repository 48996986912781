import StarIcon from '@mui/icons-material/Star';
import { Grid, Rating } from '@mui/material';
import { Button, TextField } from 'common/components';
import BasicModal, { ModalProps } from 'common/components/BasicModal';
import Stepper from 'components/onboardingStepper/Stepper';
import { RequestStatus } from 'core/enums/request-status.enum';
import { IFeedbackItem, TypeFeedbackEnum } from 'core/models/employeeDto.model';
import React, { BaseSyntheticEvent, Dispatch, SetStateAction, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { logoutSessionSync, updatePeopleSync } from 'states/auth/authSlice';
import { getFeedbacksAsync, selectFeedbacks, updateFeedbacksAsync } from 'states/employee/employeeSlice';
import { useAppDispatch, useAppSelector } from 'states/hooks';

enum StepFeedbackEnum {
  STEP_1 = 1,
  STEP_2 = 2,
  STEP_3 = 3,
  STEP_4 = 4,
}

const StepsBeforeLogout = [
  {
    step: StepFeedbackEnum.STEP_1,
    title: 'Before You Head Out!',
    subTitle: 'We’d love to know what you think of our improvements! 🤓',
    hideStepper: true,
  },
  {
    step: StepFeedbackEnum.STEP_2,
    title: 'How satisfied are you with our improvements?',
    description: '1 star can say “It’s ok”, 5 can say “love it!” 💖',
  },
  {
    step: StepFeedbackEnum.STEP_3,
    title: 'How effectively can you find what you need on our platform?',
    description: '1 star can say “very complicated”, 5 can say “super easy!” 🤩',
  },
  {
    step: StepFeedbackEnum.STEP_4,
    title: 'You Did It!  🏆 🎉',
    subTitle: 'Do you have any comments or feedback you would like to share?',
  },
];

type SubmitValue = {
  feedback: string;
  ratingStepOne: number | undefined;
  ratingStepTwo: number | undefined;
};

const ChildrenComByStep = ({ step }: { step: number }) => {
  const { STEP_2, STEP_3, STEP_4 } = StepFeedbackEnum;
  const { control } = useFormContext();
  if ([STEP_2, STEP_3].includes(step)) {
    return (
      <Controller
        name={step === STEP_2 ? 'ratingStepOne' : 'ratingStepTwo'}
        control={control}
        render={({ field }) => {
          return (
            <Rating {...field} className="text-warningColor" emptyIcon={<StarIcon className="text-beigeTint" />} />
          );
        }}
      />
    );
  }
  if (step === STEP_4) {
    return (
      <Controller
        name="feedback"
        control={control}
        render={({ field }) => {
          return <TextField {...field} placeholder="Type here" />;
        }}
      />
    );
  }
  return <></>;
};

const EmployeeReleaseFeedbackModal = ({
  isOpenFeedbackModal,
  setFeedbackModal,
}: {
  isOpenFeedbackModal: boolean;
  setFeedbackModal: Dispatch<SetStateAction<boolean>>;
}) => {
  const { STEP_1, STEP_2, STEP_3, STEP_4 } = StepFeedbackEnum;
  const [step, setStep] = useState<StepFeedbackEnum>(STEP_1);
  const dispatch = useAppDispatch();
  const feedbacks = useAppSelector(selectFeedbacks);
  const people = useAppSelector((state) => state.auth.people);
  const form = useForm<SubmitValue>({
    defaultValues: {
      feedback: '',
      ratingStepOne: undefined,
      ratingStepTwo: undefined,
    },
  });
  const { handleSubmit, watch } = form;

  const handleNextStep = () => {
    if (step < STEP_4) {
      setStep((prev) => prev + 1);
    } else {
      setFeedbackModal(false);
    }
  };

  const getInfoByStep = useMemo(() => {
    return StepsBeforeLogout.find((item) => item.step === step);
  }, [step]);

  const onSubmit = async (values: SubmitValue) => {
    let params: IFeedbackItem = {
      type: TypeFeedbackEnum.QUESTION,
    };
    if ([STEP_2, STEP_3].includes(step)) {
      params = {
        ...params,
        type: TypeFeedbackEnum.RATING,
        rating: step === STEP_2 ? values.ratingStepOne : values.ratingStepTwo,
      };
    }
    if (step === STEP_4) {
      params = {
        ...params,
        type: TypeFeedbackEnum.INPUT,
        value: values.feedback || undefined,
      };
    }
    const newValue = {
      ...params,
      title: getInfoByStep?.title,
      description: getInfoByStep?.description,
    };

    await updateFeedbacksInfo(newValue);
  };

  const getButtonByStep = () => {
    const handeClick = (e: BaseSyntheticEvent) => {
      handleSubmit(onSubmit)(e);
    };
    if (step === STEP_1) {
      return (
        <>
          <Button className="max-w-[210px]" variant="contained" color="secondary" onClick={handeClick}>
            Sure!
          </Button>
          <Button color="secondary" className="max-w-[210px]" disabled>
            Log out
          </Button>
        </>
      );
    }
    if ([STEP_2, STEP_3].includes(step)) {
      const suffix = step === STEP_2 ? 'ratingStepOne' : 'ratingStepTwo';
      return (
        <Button
          variant="contained"
          color="secondary"
          onClick={handeClick}
          disabled={!watch(suffix)}
          className="max-w-[144px]"
        >
          Next
        </Button>
      );
    }
    if (step === STEP_4) {
      return (
        <Button variant="contained" color="secondary" className="max-w-[149px]" onClick={handeClick}>
          Done
        </Button>
      );
    }
  };

  const getFeedbacksInfo = async () => {
    await dispatch(getFeedbacksAsync());
  };

  const updateWebVersion = async () => {
    const response = await dispatch(
      updatePeopleSync({
        ...people,
        personalConfig: {
          ...(people?.personalConfig || {}),
          releaseAnnouncementAndFeedback: false,
        },
      })
    );
    if (response.meta.requestStatus === RequestStatus.FULFILLED) {
      await dispatch(logoutSessionSync());
    }
  };

  const updateFeedbacksInfo = async (value: IFeedbackItem) => {
    const params =
      feedbacks.length < step
        ? [...feedbacks, value]
        : feedbacks.map((item, idx) => (idx + 1 === step ? { id: item?.id, ...value } : item));
    const res = await dispatch(updateFeedbacksAsync(params));
    if (res.meta.requestStatus === RequestStatus.FULFILLED) {
      if (step === 4) {
        await updateWebVersion();
      } else {
        getFeedbacksInfo();
      }
      handleNextStep();
    }
  };

  const handleModalProps = () => {
    let modalProps: ModalProps = {
      isOpen: isOpenFeedbackModal,
      className: 'w-full max-w-[1062px]',
    };

    if (step === STEP_1) {
      modalProps = {
        ...modalProps,
        handleClose: () => setFeedbackModal(false),
      };
    }
    return modalProps;
  };

  const childCompProps = {
    step,
  };

  return (
    <>
      {isOpenFeedbackModal && (
        <BasicModal {...handleModalProps()}>
          <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {!getInfoByStep?.hideStepper && (
                <div className="w-full flex gap-1">
                  <Stepper steps={StepsBeforeLogout.filter((item) => item.step !== STEP_1)} step={step - 2} />
                </div>
              )}
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignContent="center"
                className={`text-center ${step === STEP_1 ? '' : 'mt-10'}`}
              >
                <Grid item>
                  <span
                    className={`font-bold ${[STEP_1, STEP_4].includes(step) ? 'text-base' : 'text-sm'} text-[#181C11]`}
                  >
                    {getInfoByStep?.title}
                  </span>
                </Grid>
                <Grid item>
                  <span className="font-normal text-sm text-[#031526]">{getInfoByStep?.subTitle}</span>
                </Grid>
                <Grid item className="mt-8">
                  {step === STEP_2 && <ChildrenComByStep {...childCompProps} />}
                  {step === STEP_3 && <ChildrenComByStep {...childCompProps} />}
                  {step === STEP_4 && <ChildrenComByStep {...childCompProps} />}
                </Grid>
                <Grid item className="font-normal text-xs text-[#222917]">
                  {getInfoByStep?.description}
                </Grid>
              </Grid>
              <div className="flex items-center justify-center gap-4 mt-8">{getButtonByStep()}</div>
            </form>
          </FormProvider>
        </BasicModal>
      )}
    </>
  );
};

export default EmployeeReleaseFeedbackModal;
