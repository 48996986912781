import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import { Backdrop, Box, FormLabel, SvgIcon, Typography } from '@mui/material';
import { EXTENSION_MIME_TYPE, appPalette } from 'core/constants';
import { UploadPlanRequest } from 'core/models/UploadPlanRequest.model';
import { updateUploadStatus, uploadFileToS3 } from 'core/services/api-upload-file.service';
import { uploadFile } from 'core/services/api.service';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import BasicModal from '../BasicModal';
import { Button, ErrorMassage } from 'common/components';
import LinearWithValueLabel from 'common/components/LinearProgressBar';

export interface IModalUploadFilesProps {
  isOpen: boolean;
  title: string;
  acceptFiles: string;
  onSuccess?: Function;
  onError?: Function;
  onCancel: Function;
  fileType: string;
  companyId?: string;
  personId?: string;
  maxFileSize?: number;
  planId?: string;
  onFileChange?: Function;
  minFileSize?: number;
  onOfflineProcess?: Function;
  isLoading?: boolean;
  buttonSaveText?: string;
  subTitle?: string;
}

interface DragAndDropZoneProps {
  acceptFiles: string;
  onChange: any;
  file: File | null;
  setSelectedFile: React.Dispatch<React.SetStateAction<File | null>>;
}

export const DragAndDropZone = forwardRef<HTMLDivElement, DragAndDropZoneProps>(
  ({ acceptFiles, onChange, file, setSelectedFile }, ref) => {
    const dragAndDropRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
      dragAndDropRef.current?.addEventListener('dragover', (e) => {
        e.preventDefault();
        dragAndDropRef.current?.classList.add('bg-lighter', 'scale-[.95]');
      });
      dragAndDropRef.current?.addEventListener('dragleave', (e: DragEvent) => {
        e.preventDefault();
        dragAndDropRef.current?.classList.remove('bg-lighter', 'scale-[.95]');
      });
      dragAndDropRef.current?.addEventListener('drop', (e: DragEvent) => {
        e.preventDefault();
        if (e.dataTransfer?.items) {
          [...e.dataTransfer.items].forEach((item) => {
            if (item.kind === 'file') {
              onChange(e);
              dragAndDropRef.current?.classList.remove('bg-lighter', 'scale-[.95]');
            }
          });
        }
      });
      return () => {
        dragAndDropRef.current?.removeEventListener('dragover', (e) => {});
        dragAndDropRef.current?.removeEventListener('dragleave', (e) => {});
        dragAndDropRef.current?.removeEventListener('drop', (e) => {});
      };
    }, [dragAndDropRef]);

    return (
      <div>
        <div
          ref={dragAndDropRef}
          className="border border-dashed border-borderColor h-[300px] w-full flex-col rounded-lg transition-transform mt-4"
          id="dropzone"
        >
          {file && (
            <div>
              <div className="flex px-2">
                <div className="text-primary font-semibold w-[45%] select-none p-1">File name</div>
                <div className="text-primary font-semibold w-[45%] select-none p-1">Size</div>
                <div className="text-primary font-semibold w-[10%] p-1"></div>
              </div>
              <div className="flex text-primary px-2">
                <p className="w-[45%] line-clamp-3 break-words select-none p-1">{file.name}</p>
                <p className="w-[45%] line-clamp-3 break-words select-none p-1">
                  {file.size / (1024 * 1024) >= 1
                    ? `${(file.size / (1024 * 1024)).toFixed(2)} MB`
                    : file.size / 1024 >= 1
                    ? `${(file.size / 1024).toFixed(2)} KB`
                    : `${file.size} bytes`}
                </p>
                <div className="w-[10%] text-center">
                  <HighlightOffOutlinedIcon
                    className="hover:text-errorColor hover:cursor-pointer"
                    htmlColor={appPalette.primary}
                    onClick={() => {
                      setSelectedFile(null);
                      if (inputRef.current) {
                        inputRef.current.value = '';
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          )}
          <div className={['flex flex-col justify-center items-center flex-4', file ? 'h-[80%]' : 'h-full'].join(' ')}>
            <div className="w-full text-center">
              <SvgIcon>
                <CloudUploadOutlinedIcon htmlColor={appPalette.primary} />
              </SvgIcon>
            </div>
            <div className="flex items-center justify-center">
              <FormLabel className="cursor-pointer text-sm select-none hover:text-textLinkHover text-textLink">
                <input
                  ref={inputRef}
                  type="file"
                  accept={acceptFiles}
                  className="absolute hidden"
                  onChange={onChange}
                />
                Choose a file
              </FormLabel>
              <p className="text-center text-normalText select-none">&nbsp;or drag and drop here</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export const ModalUploadFiles = (props: IModalUploadFilesProps) => {
  const {
    isOpen,
    title,
    acceptFiles,
    onSuccess,
    onError,
    onCancel,
    fileType,
    companyId,
    personId,
    planId,
    onFileChange,
    maxFileSize,
    minFileSize,
    onOfflineProcess = () => {},
    isLoading = false,
    buttonSaveText = 'Save',
    subTitle,
  } = props;
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>('');
  const submitButtonRef = useRef<HTMLButtonElement>(null);
  const [progressValue, setProgressValue] = useState<number>(0);

  let formattedAcceptFiles: any = acceptFiles
    .replace('image/*', '.jpg, .jpeg, .gif, .png')
    .replace(/[\s.]+/g, '')
    .split(',')
    .map((item: string) => {
      if (EXTENSION_MIME_TYPE[item as keyof typeof EXTENSION_MIME_TYPE]) {
        return EXTENSION_MIME_TYPE[item as keyof typeof EXTENSION_MIME_TYPE];
      } else {
        return item;
      }
    });

  const onResetHandler = () => {
    onCancel();
    setSelectedFile(null);
    setError('');
  };
  const onChange = async (event: any) => {
    setError('');
    let file: File | null = null;
    if (event && event.target && event.type === 'change') {
      file = event.target.files && event.target.files[0];
    }
    if (event && event.dataTransfer?.items && event.dataTransfer?.items.length && event.type === 'drop') {
      file = event.dataTransfer?.items[0].getAsFile();
    }
    if (onFileChange) {
      onFileChange(file);
    }

    if (
      file &&
      maxFileSize &&
      minFileSize &&
      (file.size >= maxFileSize || file.size <= minFileSize || formattedAcceptFiles.indexOf(file.type) === -1)
    ) {
      setError(
        `Please select the file as ${acceptFiles} with file size larger than ${
          minFileSize / 1024 < 1 ? `${minFileSize} bytes` : `${minFileSize / 1024} KB`
        } and smaller than ${maxFileSize / (1024 * 1024)}MB`
      );
      setSelectedFile(null);
    } else if (file && !maxFileSize && !minFileSize && formattedAcceptFiles.indexOf(file.type) === -1) {
      setError(`Please select the file as ${acceptFiles}`);
      setSelectedFile(null);
    } else {
      setSelectedFile(file);
    }
  };

  const onUploadProgress = (progress: number) => {
    setProgressValue(progress);
  };

  const onSubmitHandler = async (event: any) => {
    event.preventDefault();
    if (fileType === 'OFFLINE') {
      await onOfflineProcess(selectedFile);
      return;
    }
    setLoading(true);
    if (selectedFile) {
      try {
        const payload: UploadPlanRequest = {
          name: selectedFile.name,
          mime: selectedFile.type,
          size: selectedFile.size,
          fileType,
        };
        if (companyId) {
          payload.companyId = companyId;
        }
        if (personId) {
          payload.personId = personId;
        }
        if (planId) {
          payload.planId = planId;
        }
        const { data } = await uploadFile(payload);
        const { id, url } = data;
        await uploadFileToS3({ url, file: selectedFile, onUploadProgress });
        await updateUploadStatus(id);
        if (onSuccess) {
          await onSuccess(data);
          setSelectedFile(null);
        }
      } catch (exception) {
        if (onError) {
          onError(exception);
        }
      }
    }
    setLoading(false);
    setProgressValue(0);
  };

  return (
    <>
      <BasicModal isOpen={isOpen} className="w-full max-w-[550px]" handleClose={onResetHandler}>
        <Box>
          <Typography className="text-secondary text-xl font-semibold mt-3">{title}</Typography>
          {subTitle && <Typography className="text-primary text-sm font-normal">{subTitle}</Typography>}
          <form onSubmit={onSubmitHandler} onReset={onResetHandler}>
            <DragAndDropZone
              onChange={onChange}
              acceptFiles={acceptFiles}
              file={selectedFile}
              setSelectedFile={setSelectedFile}
            />
            {error && <ErrorMassage message={error} />}
            <div className="mt-8 flex gap-4 items-center justify-end">
              <Button variant="outlined" color="secondary" type="reset" disabled={loading || isLoading}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={!selectedFile || loading || isLoading}
                loading={loading || isLoading}
                ref={submitButtonRef}
              >
                {buttonSaveText}
              </Button>
            </div>
          </form>
        </Box>
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
            opacity: '0.9 !important',
            backgroundColor: 'unset',
            justifyContent: 'center',
            alignItems: 'flex-start',
            display: 'flex',
          }}
          open={loading}
        >
          <div className="mt-2">
            <LinearWithValueLabel value={progressValue} />
          </div>
        </Backdrop>
      </BasicModal>
    </>
  );
};
