import { Grid } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { fileUrlToBase64 } from "core/functions";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { ControllerRenderProps } from "react-hook-form";
import { TEmployeeEditBeneficiaryV2Form } from ".";

type Props = {
  field: ControllerRenderProps<
    TEmployeeEditBeneficiaryV2Form,
    "spousalConsent"
  >;
  error?: string;
  setOpenModalUpload: React.Dispatch<React.SetStateAction<boolean>>
};

const UploadSpousalConsent = ({ field, error, setOpenModalUpload }: Props) => {
  return (
    <div className="mb-5">
      <Grid
        container
        gap={2}
        alignItems="center"
        className="text-textLink hover:text-textLinkHover hover:cursor-pointer text-sm font-semibold w-auto mt-4 ml-4"
      >
        {!field.value.url && (
          <Grid
            item
            onClick={() => {
              setOpenModalUpload(true);
            }}
            className="flex items-center gap-2"
          >
            <div>
              <CloudUploadIcon />
            </div>
            <div>
              Spousal Consent
            </div>
          </Grid>
        )}
        <Grid item>
          {field.value.url && (
            <span
              onClick={async () => {
                let base64String = "";
                if (field.value.updated) {
                  base64String = field.value.url;
                } else {
                  base64String = (await fileUrlToBase64(
                    field.value.url
                  )) as string;
                }
                const newTab = window.open();
                if (newTab) {
                  newTab.document.title = "Spousal Consent";
                  newTab.document.body.innerHTML = `<iframe title="pdfDoc" src="${base64String}" width="100%" height="100%"></iframe>`;
                }
              }}
              className="text-textLink hover:text-textLinkHover hover:cursor-pointer inline-block font-semibold text-sm"
            >
              <Grid item className="flex items-center gap-2">
                <div>
                  <CloudUploadIcon />
                </div>
                <div>
                  Spousal Consent
                </div>
                <VisibilityIcon className="mr-2" />
              </Grid>
            </span>
          )}
        </Grid>
      </Grid>
      {error && <span className="ml-4 text-errorColor text-xs">{error}</span>}
    </div>
  );
};

export default UploadSpousalConsent;
