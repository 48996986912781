import React from 'react';
import { PatternFormat, PatternFormatProps } from 'react-number-format';
import { TextField } from 'common/components';

export const TextFieldWithMask = React.forwardRef<HTMLInputElement, PatternFormatProps<any>>(
  ({ format = '###-##-####', mask = '_', ...restProps }, ref) => {
    return (
      <PatternFormat
        getInputRef={ref}
        customInput={TextField}
        placeholder="SSN(000-00-0000)"
        format={format}
        mask={mask}
        {...restProps}
      />
    );
  }
);
