import { FormHelperText, FormLabelProps } from '@mui/material';
import { DatePicker as MuiDatePicker, DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { appPalette } from 'core/constants';
import { forwardRef, ReactNode, Ref } from 'react';
import { FormControl } from 'common/components';
import useId from '@mui/material/utils/useId';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

type IDatePickerProps = {
  label?: ReactNode | string;
  format?: string;
  defaultUI?: boolean;
  placeholder?: string;
  error?: string;
  classes?: string;
  formLabelProps?: FormLabelProps;
  id?: string;
  required?: boolean;
  formLabelStyle?: any;
} & DatePickerProps<any>;

const DatePicker = forwardRef(
  (
    {
      label,
      format = 'MM / DD / YYYY',
      defaultUI = true,
      placeholder,
      error,
      sx,
      classes = '',
      formLabelProps,
      id: propId,
      required = false,
      formLabelStyle = {},
      ...props
    }: IDatePickerProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const id = useId(propId);
    return (
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'en'}>
        <FormControl
          label={label}
          id={id}
          fullWidth
          formLabelProps={formLabelProps}
          sx={{
            '.MuiFormLabel-root': {
              maxHeight: '16px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            },
          }}
          required={required}
          {...formLabelStyle}
        >
          <MuiDatePicker
            className={classes}
            slotProps={{
              textField: {
                placeholder: placeholder ?? format,
                error: !!error,
              },
              actionBar: {
                actions: ['clear'],
              },
            }}
            slots={{
              openPickerIcon: CalendarMonthOutlinedIcon,
            }}
            sx={{
              boxShadow: 0,
              p: 0,
              width: '100%',
              borderRadius: '8px',
              backgroundColor: (theme) => theme.palette.white,
              '.MuiOutlinedInput-input.MuiInputBase-input': {
                borderRadius: '8px',
                padding: '8px 0px 8px 16px',
                backgroundColor: (theme) => theme.palette.white,
                color: (theme) => theme.palette.darkest.dark,
                '&::placeholder': {
                  color: (theme) => theme.palette.subTitle,
                  fontWeight: '600',
                  fontSize: '13px',
                },
              },
              '.MuiInputAdornment-root': {
                paddingLeft: -8,
                '.MuiIconButton-root': {
                  paddingLeft: -8,
                },
              },
              '& .MuiSvgIcon-root': {
                color: (theme) => theme.palette.darkGreen,
              },
              '.Mui-focused': {
                '&.Mui-error': {
                  '.MuiOutlinedInput-notchedOutline': {
                    borderColor: (theme) => `${theme.palette.errorColor} !important`,
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: (theme) => `${theme.palette.secondaryColor} !important`,
                  },
                },
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: (theme) => `${theme.palette.secondaryColor} !important`,
                },
              },
              '.Mui-disabled': {
                '.MuiSvgIcon-root': {
                  color: (theme) => theme.palette.mediumGrey,
                },
                cursor: 'not-allowed !important',
                WebkitTextFillColor: appPalette.beige,
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: (theme) => `${theme.palette.mediumGrey} !important`,
                },
                '.MuiInputAdornment-root': {
                  cursor: 'not-allowed',
                },
              },
              '.Mui-disabled.Mui-error': {
                WebkitTextFillColor: appPalette.beige,
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: (theme) => `${theme.palette.errorColor} !important`,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: (theme) => `${theme.palette.errorColor} !important`,
                },
              },
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: (theme) => theme.palette.borderColor,
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: (theme) => `${theme.palette.borderHover} !important`,
              },
              '& .MuiInputBase-input': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
              ...sx,
            }}
            format={format}
            inputRef={ref}
            {...props}
          />
        </FormControl>
        {error && (
          <FormHelperText error={!!error} className="text-xs p-0 m-0 mt-2">
            {error}
          </FormHelperText>
        )}
      </LocalizationProvider>
    );
  }
);

export default DatePicker;
