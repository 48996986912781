import { FC, useEffect, useState } from 'react';
import { Box, Card, Stack, Typography, Grid, Paper } from '@mui/material';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip as ChartTooltip, Label } from 'recharts';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Tooltip } from 'common/components';
import { numToString } from 'core/services/utils/dashboardUtils';
import { selectAuthState } from 'states/auth/authSlice';
import { getEmployeeStatusCountAsync, selectStatusCount } from 'states/employerDashboard/employerDashboardSlice';
import { useAppDispatch, useAppSelector } from 'states/hooks';

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <Paper elevation={6}>
        <div
          className="flex items-center justify-center"
          style={{
            backgroundColor: '#fff',
            borderRadius: '4px',
            minHeight: '24px',
            minWidth: '45px',
          }}
        >
          <Typography fontWeight={600} fontSize={'10px'} lineHeight={'16px'}>
            {`${numToString(payload[0].value)}%`}
          </Typography>
        </div>
      </Paper>
    );
  }

  return null;
};

const RenderLegend: FC<{ data: any[]; dropLabel?: boolean }> = ({ data, dropLabel }) => {
  return (
    <>
      {data.map((entry: any) => (
        <p
          key={entry.name}
          style={{
            width: dropLabel && entry.name?.length > 30 ? '168px' : '100%',
          }}
        >
          <CircleIcon
            style={{
              color: entry.color,
              fontSize: '8px',
              marginRight: '2px',
            }}
          />
          <span
            style={{
              color: '#2E3721',
              fontWeight: 500,
              fontSize: '10px',
              lineHeight: '15px',
              marginRight: '2px',
            }}
          >
            {entry.name}
          </span>
          <Tooltip
            title={entry.tooltip}
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -12],
                    },
                  },
                ],
              },
            }}
          >
            <InfoOutlinedIcon className="w-3" sx={{ color: '#9E9E91' }} />
          </Tooltip>
        </p>
      ))}
    </>
  );
};

const RenderChart: FC<{ data: any[]; rate: number; setRate: any }> = ({ data, rate, setRate }) => {
  if (!data?.length) return null;
  return (
    <Box minHeight={'180px'}>
      <ResponsiveContainer width="100%" height="99.5%" minHeight={'180px'}>
        <PieChart>
          <Pie
            data={data}
            innerRadius={45}
            outerRadius={70}
            startAngle={90}
            endAngle={-270}
            stroke="none"
            paddingAngle={0}
            dataKey="value"
            style={{ outline: 'none' }}
            animationDuration={1000}
          >
            {rate !== undefined && (
              <Label
                value={`${rate}%`}
                position="center"
                fontSize={'20px'}
                fontWeight={600}
                fill="#181C11"
                style={{
                  lineHeight: '32px',
                }}
              />
            )}
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} onClick={() => setRate(entry.value)} />
            ))}
          </Pie>
          <ChartTooltip cursor={false} wrapperStyle={{ outline: 'none' }} content={CustomTooltip} />
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default function ParticipationRate() {
  const theme = useTheme();
  const mobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const tabletScreen = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const desktopScreen = useMediaQuery(theme.breakpoints.up('lg'));
  const { info } = useAppSelector(selectAuthState);
  const statusCountState = useAppSelector(selectStatusCount);

  const dispatch = useAppDispatch();
  const [chart, setChart] = useState<any[]>([]);
  const [rate, setRate] = useState<number>(0);

  const fetchData = () => {
    if (info && info.currentCompanyId) {
      dispatch(getEmployeeStatusCountAsync(info.currentCompanyId));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const activeUser = statusCountState.ACTIVE ?? 0;
    const otherUser = (statusCountState.INVITED ?? 0) + (statusCountState.NEW ?? 0);
    const total = activeUser + otherUser;
    const activePercent = Math.round((activeUser * 100) / (total || 1));
    const inActivePercent = 100 - activePercent;

    setChart([
      {
        key: 'active',
        name: 'Active Participants',
        color: '#69AB00',
        tooltip: `These are participants in your organization who are eligible to partake in the retirement benefits and are contributing to their retirement.`,
        value: activePercent,
      },
      {
        key: 'inactive',
        name: 'Inactive Participants',
        color: '#3B471C',
        tooltip: `These are participants in your organization who are eligible to partake in the retirement benefits but are not contributing to their retirement.`,
        value: inActivePercent,
      },
    ]);

    // Set the rate based on the active percent
    setRate(activePercent);
  }, [statusCountState]);

  const RenderTitle = () => {
    return (
      <Typography
        sx={{
          fontSize: '12px',
          fontWeight: 600,
          color: 'beige',
          lineHeight: '16px',
        }}
        gutterBottom
      >
        Participation Rate
      </Typography>
    );
  };

  return (
    <Card
      sx={{
        maxWidth: '100%',
        border: 'none',
      }}
      variant="outlined"
    >
      {mobileScreen && (
        <>
          <Grid container>
            <Grid item zero={12}>
              <RenderTitle />
            </Grid>
            <Grid item zero={5}>
              <RenderChart data={chart} rate={rate} setRate={setRate} />
            </Grid>
            <Grid item zero={7} mt={4} pl={1}>
              <Stack spacing={-0.5}>
                <Typography
                  fontWeight={700}
                  fontSize={'10px'}
                  lineHeight={'15px'}
                  color="#181C11"
                  marginBottom={0.5}
                  marginTop={2.5}
                >
                  Breakdown
                </Typography>
                <RenderLegend data={chart} />
              </Stack>
            </Grid>
          </Grid>
        </>
      )}
      {tabletScreen && (
        <>
          <Grid container>
            <Grid item zero={12}>
              <RenderTitle />
            </Grid>
            <Grid item zero={4}>
              <RenderChart data={chart} rate={rate} setRate={setRate} />
            </Grid>
            <Grid item zero={8} mt={4}>
              <Stack spacing={-0.5}>
                <Typography
                  fontWeight={700}
                  fontSize={'10px'}
                  lineHeight={'15px'}
                  color="#181C11"
                  marginBottom={0.5}
                  marginTop={2.5}
                >
                  Breakdown
                </Typography>
                <RenderLegend data={chart} />
              </Stack>
            </Grid>
          </Grid>
        </>
      )}
      {desktopScreen && (
        <>
          <Grid container>
            <Grid container item zero={12}>
              <RenderTitle />
            </Grid>
            <Grid item zero={6}>
              <RenderChart data={chart} rate={rate} setRate={setRate} />
            </Grid>
            <Grid item zero={6} mt={4}>
              <Stack spacing={-0.5}>
                <Typography
                  fontWeight={700}
                  fontSize={'10px'}
                  lineHeight={'15px'}
                  color="#181C11"
                  marginBottom={0.5}
                  marginTop={2.5}
                >
                  Breakdown
                </Typography>
                <RenderLegend data={chart} />
              </Stack>
            </Grid>
          </Grid>
        </>
      )}
    </Card>
  );
}
