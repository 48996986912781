export const getYearList = (
  start: number,
  end: number = new Date().getFullYear()
): number[] => {
  const years: number[] = [];
  for (let year = end; year >= start; year--) {
    years.push(year);
  }
  return years;
};

export const calculateNextPayDate = (payFrequency: string) => {
  if (!payFrequency) {
    return 0;
  }
  const payFrequencyPlan: any = {
    MONTHLY: 2592000000,
    SEMI_MONTHLY: 1296000000,
    WEEKLY: 604800000,
    BI_WEEKLY: 1209600000,
    QUARTERLY: 7776000000,
    SEMI_ANNUALLY: 15552000000,
    ANNUALLY: 31536000000,
  };
  return payFrequencyPlan[payFrequency];
};

export const readFileToBase64 = async (file: File | Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
    reader.onerror = () => {
      reject("Read file failed!");
    };
  });
};

export const fileUrlToBase64 = async (url: string) => {
  if (!url) return null;
  const data = await fetch(url);
  const blob = await data.blob();
  return await readFileToBase64(blob);
};

export const fileToBase64 = async (file: File) => {
  return await readFileToBase64(file);
};
