import React, { useEffect, useState } from 'react';
import { Button, TextField } from 'common/components';
import { ChangePasswordPayload, EmployeePasswordForm } from 'core/models/employeeDto.model';
import { Controller, useForm } from 'react-hook-form';
import { REGEX_PATTERN, RESET_PASSWORD_ACTION, ROUTES } from 'core/constants';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { IconButton, InputAdornment } from '@mui/material';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {
  changePasswordAsync,
  getTwoFAAsync,
  selectEmployeeProfile,
  resendCodeSMS2FAAsync,
} from 'states/manageEmployee/manageEmployeeSlice';
import { useAppDispatch, useAppSelector } from 'states/hooks';
import { RequestStatus } from 'core/enums/request-status.enum';
import { setSuccessMessage } from 'states/snackbarMessage/snackbarMessageSlice';
import ModalTwoFA from './ModalTwoFA';
import { TWO_FA_STATUS, VERIFY_2FA_ACTION, TWO_FA_METHOD } from 'core/constants/roles';
import ModalBackupCode from './ModalBackupCode';
import CheckIcon from '@mui/icons-material/Check';
import { validatePasswordMessages } from 'pages/resetPassword';
import BasicModal from 'common/components/BasicModal';
import { useNavigate } from 'react-router-dom';
import { CookieNames } from 'core/enums/cookie-names.enum';
import { getCookie } from 'core/services/cookie.service';

interface ITabSecurityProps {}

const TabSecurity = (props: ITabSecurityProps) => {
  const [isShowNewPassword, setShowNewPassword] = useState(false);
  const [isShowConfirmPassword, setShowConfirmPassword] = useState(false);
  const dispatch = useAppDispatch();
  const employeeProfile = useAppSelector(selectEmployeeProfile);
  const twoFA = useAppSelector((state) => state.manageEmployee.twoFA);
  const [isLoading, setLoading] = useState(false);
  const [isShowModalTwoFA, setShowModalTwoFA] = useState(false);
  const [isShowModalForgotPassword, setShowModalForgotPassword] = useState(false);
  const [backupCodes, setBackupCodes] = useState<string[]>([]);
  const navigate = useNavigate();
  const sessionId = getCookie(CookieNames.XCSSession);
  const {
    control,
    handleSubmit,
    getValues,
    reset: resetForm,
    formState: { errors, dirtyFields },
  } = useForm<EmployeePasswordForm>({
    reValidateMode: 'onChange',
    mode: 'onChange',
    criteriaMode: 'all',
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
  });

  useEffect(() => {
    if (!twoFA && sessionId) {
      dispatch(getTwoFAAsync());
    }
  }, []);

  const handleOnSubmit = async (values: EmployeePasswordForm) => {
    setLoading(true);
    const payload: ChangePasswordPayload = {
      action: RESET_PASSWORD_ACTION.PASSWORD_RESET,
      newPassword: values.newPassword,
      person: {
        id: employeeProfile?.personId ?? '',
        password: values.currentPassword,
      },
    };

    const response = await dispatch(changePasswordAsync(payload));
    if (response.meta.requestStatus === RequestStatus.FULFILLED) {
      dispatch(setSuccessMessage('Update password successful.'));
      resetForm({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      });
    }
    setLoading(false);
  };

  const handleForgotPassword = async () => {
    navigate(ROUTES.LOGIN.routes.forgotPassword.path);
    setShowModalForgotPassword(false);
  };

  return (
    <>
      <form name="formEmployeeProfile" onSubmit={handleSubmit(handleOnSubmit)}>
        <h3 className="font-bold mb-4 text-superBlack">Password</h3>
        <div className="grid sm:grid-cols-3 gap-6">
          <div className="grid gap-6">
            <Controller
              control={control}
              name="currentPassword"
              rules={{ required: 'Current password is required.' }}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    label="Current password"
                    placeholder="Type Current password"
                    type="password"
                    error={errors.currentPassword?.message}
                  />
                );
              }}
            />
            <Controller
              control={control}
              name="newPassword"
              rules={{
                required: true,
                validate: {
                  minLength: (value) => value.length >= 8 || 'At least 8 characters',
                  atLeastOneLowercase: (value) =>
                    REGEX_PATTERN.AT_LEAST_ONE_LOWERCASE.pattern.test(value) ||
                    REGEX_PATTERN.AT_LEAST_ONE_LOWERCASE.message,
                  atLeastOneUppercase: (value) =>
                    REGEX_PATTERN.AT_LEAST_ONE_UPPERCASE.pattern.test(value) ||
                    REGEX_PATTERN.AT_LEAST_ONE_UPPERCASE.message,
                  atLeastOneNumber: (value) =>
                    REGEX_PATTERN.AT_LEAST_ONE_NUMBER.pattern.test(value) || REGEX_PATTERN.AT_LEAST_ONE_NUMBER.message,
                  atLeastOneSpecialCharacter: (value) =>
                    REGEX_PATTERN.AT_LEAST_ONE_SPECIAL_CHARACTER.pattern.test(value) ||
                    REGEX_PATTERN.AT_LEAST_ONE_SPECIAL_CHARACTER.message,
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <>
                    <TextField
                      {...field}
                      label="New password"
                      placeholder="Type New password"
                      type={isShowNewPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowNewPassword(!isShowNewPassword)} edge="end">
                              {isShowNewPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={errors.newPassword?.type === 'required' ? 'New password is required.' : ''}
                    />
                    {dirtyFields.newPassword && (
                      <div className="flex flex-col -mt-4">
                        {Object.entries(validatePasswordMessages).map(([type, message], index) => (
                          <div className="flex gap-1 items-center">
                            <CheckIcon
                              className={[
                                'text-xl',
                                Object.keys(fieldState.error?.types || {}).includes(type)
                                  ? 'text-primary'
                                  : 'text-secondary',
                              ].join(' ')}
                            />
                            <p key={index} className="text-primary text-xs">
                              {message}
                            </p>
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                );
              }}
            />
            <Controller
              control={control}
              name="confirmPassword"
              rules={{
                required: 'Confirm password is required.',
                validate: (value) => {
                  return value === getValues('newPassword');
                },
              }}
              render={({ field }) => {
                return (
                  <TextField
                    {...field}
                    label="Confirm password"
                    placeholder="Type Confirm password"
                    type={isShowConfirmPassword ? 'text' : 'password'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setShowConfirmPassword(!isShowConfirmPassword)} edge="end">
                            {isShowConfirmPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={
                      errors.confirmPassword?.type === 'required'
                        ? 'Confirm password is required.'
                        : errors.confirmPassword?.type === 'validate'
                        ? 'Confirm password does not match.'
                        : ''
                    }
                  />
                );
              }}
            />
            <div className="cursor-pointer text-body-4 text-textLink hover:text-textLinkHover">
              <p onClick={() => setShowModalForgotPassword(true)}>Forgot Password?</p>
            </div>
          </div>
        </div>
        {true && (
          <div className="flex flex-col lg:flex-row mt-8 text-xs rounded-xl bg-lightGreen p-4">
            <div className="flex items-center mb-2 lg:w-[200px] lg:flex-grow-0 lg:flex-shrink-0 lg:mb-0 lg:mr-1 xs:flex-row xs:gap-0 zero:flex-col zero:gap-4">
              <LockOutlinedIcon color="primary" />
              <p className="ml-2 text-darkGreen zero:text-center xs:text-left">
                <strong>Add on extra layer of security to your account!</strong>
              </p>
            </div>
            <div className="zero:text-center sm:flex sm:items-center text-primary">
              <p className="xs:text-left">
                Before you view your dashboard, take two minutes to activate two-factor{' '}
                <br className="zero:block xs:hidden" /> authentication to protect you account. To activate 2FA, you'll
                enter a login code that you'll receive on your phone.
              </p>
              <Button
                className="xs:w-auto min-w-[140px] mt-4 sm:mt-0 sm:ml-3 zero:max-w-full zero:w-full xs:max-w-auto"
                variant="contained"
                color="secondary"
                onClick={() => {
                  setShowModalTwoFA(true);
                  if (twoFA?.status === TWO_FA_STATUS.ACTIVE && twoFA?.method === TWO_FA_METHOD.SMS) {
                    dispatch(resendCodeSMS2FAAsync());
                  }
                }}
              >
                {twoFA?.status === 'ACTIVE' ? 'Deactivate' : 'Get Started'}
              </Button>
            </div>
          </div>
        )}
        <div className="w-full text-right mt-10">
          <div className="inline-flex items-center justify-end w-full sm:w-1/3 sm:pl-4 zero:flex-col zero:gap-4 xs:flex-row xs:gap-0">
            <Button
              className="xs:w-1/2 min-w-[140px] zero:max-w-full zero:w-full zero:order-2 xs:order-1"
              variant="outlined"
              color="secondary"
              type="reset"
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              className="xs:w-1/2 xs:ml-6 min-w-[140px] zero:max-w-full zero:w-full zero:order-1 xs:order-2"
              type="submit"
              variant="contained"
              color="secondary"
              disabled={isLoading}
              loading={isLoading}
            >
              Save
            </Button>
          </div>
        </div>
      </form>

      <ModalTwoFA
        title={
          twoFA?.status === TWO_FA_STATUS.ACTIVE
            ? 'Deactivating Two-factor Authentication.'
            : 'How do want to receive your login code?'
        }
        subTitle={
          twoFA?.status === TWO_FA_STATUS.ACTIVE
            ? ''
            : 'Select your preferred method and follow the instructions below.'
        }
        isOpen={isShowModalTwoFA}
        action={twoFA?.status === TWO_FA_STATUS.ACTIVE ? VERIFY_2FA_ACTION.REMOVE_2FA : VERIFY_2FA_ACTION.ADD_2FA}
        onClose={(response: any) => {
          if (response?.meta?.arg?.action === VERIFY_2FA_ACTION.ADD_2FA) {
            setBackupCodes(response.payload.map(atob));
          }
          setShowModalTwoFA(false);
        }}
      />
      <BasicModal
        isOpen={isShowModalForgotPassword}
        handleClose={() => setShowModalForgotPassword(false)}
        className="w-full max-w-[450px]"
      >
        <div className="text-sm text-center text-darkest">By this action, you will be logged out of system.</div>
        <div className="flex items-center justify-center gap-4 mt-6">
          <Button variant="outlined" color="secondary" onClick={() => setShowModalForgotPassword(false)}>
            Cancel
          </Button>
          <Button variant="contained" color="secondary" onClick={handleForgotPassword}>
            Got It!
          </Button>
        </div>
      </BasicModal>
      <ModalBackupCode isOpen={backupCodes.length > 0} backupCodes={backupCodes} setBackupCodes={setBackupCodes} />
    </>
  );
};

export default TabSecurity;
