import HomeLayout from 'layout/HomeLayout';
import LoginLayout from 'layout/LoginLayout';
import ErrorPage from 'pages/error';
import OnboardingLandingPage from 'pages/onboardingLandingPage';
import RecoveryAcountPage from 'pages/recoveryAccount';
import { lazy, Suspense } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

const AboutPage = lazy(() => import('pages/about'));
const PrivacyPolicyPage = lazy(() => import('pages/privacyPolicy'));
const TermsAndConditionsPage = lazy(() => import('pages/termsAndConditions'));
const LoginPage = lazy(() => import('pages/login'));
// const ForgotUserNamePage = lazy(() => import('pages/forgotUsername'));
// const ForgotPasswordPage = lazy(() => import('pages/forgotPassword'));
const AccountRecoveryPage = lazy(() => import('pages/accountRecoveryPage'));
const ResetPasswordPage = lazy(() => import('pages/resetPassword'));
const CreateCredentialsPage = lazy(() => import('pages/createCredentials'));
const FAQPage = lazy(() => import('pages/privacyFaq'));
const EmployeeOnboarding = lazy(() => import('pages/employeeOnboarding'));
const EmployerOnboarding = lazy(() => import('pages/employerOnboarding'));
const BusinessOnboardingLandingPage = lazy(() => import('pages/businessOnboardingLandingPage'));
const RecoveryEmailPage = lazy(() => import('pages/recoveryEmail'));
const EmployeeOnboardingLandingPage = lazy(() => import('pages/employeeOnboardingLandingPage'));

export const homeRoutes: RouteObject[] = [
  {
    id: 'home',
    path: '/', // /
    element: <HomeLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        id: 'index',
        index: true, // /
        element: (
          <Suspense>
            <Navigate to="/login" replace />
          </Suspense>
        ),
      },
      {
        id: 'about',
        path: 'about', // /about
        element: (
          <Suspense>
            <AboutPage />
          </Suspense>
        ),
      },
      {
        id: 'privacyPolicy',
        path: 'policies/privacy', // /privacy-policy
        element: (
          <Suspense>
            <PrivacyPolicyPage />
          </Suspense>
        ),
      },
      {
        id: 'termsAndConditions',
        path: 'policies/terms', // /terms-and-conditions
        element: (
          <Suspense>
            <TermsAndConditionsPage />
          </Suspense>
        ),
      },
      {
        id: 'FAQ',
        path: 'policies/faq', // /policies/faq
        element: (
          <Suspense>
            <FAQPage />
          </Suspense>
        ),
      },
    ],
  },
  {
    id: 'login',
    path: '/login', // login
    element: <LoginLayout />,
    children: [
      {
        id: 'loginIndex', // login
        index: true,
        element: (
          <Suspense>
            <LoginPage />
          </Suspense>
        ),
      },
      {
        id: 'forgotUsername',
        path: 'forgotUsername', // login/forgotUsername
        element: (
          <Suspense>
            <AccountRecoveryPage />
          </Suspense>
        ),
      },
      {
        id: 'forgotPassword',
        path: 'forgotPassword', // login/forgotPassword
        element: (
          <Suspense>
            <AccountRecoveryPage />
          </Suspense>
        ),
      },
      {
        id: 'recovery',
        path: 'recovery', // login/recovery
        element: (
          <Suspense>
            <AccountRecoveryPage />
          </Suspense>
        ),
      },
      {
        id: 'recoveryEmail',
        path: 'recoveryEmail', // login/recoveryEmail
        element: (
          <Suspense>
            <RecoveryEmailPage />
          </Suspense>
        ),
      },
      {
        id: 'recoveryAccount',
        path: 'recoveryAccount', // login/recoveryAccount
        element: (
          <Suspense>
            <RecoveryAcountPage />
          </Suspense>
        ),
      },
      {
        id: 'resetPassword',
        path: 'resetPassword/:resetToken', // login/resetPassword
        element: (
          <Suspense>
            <ResetPasswordPage />
          </Suspense>
        ),
      },
      {
        id: 'createCredentials',
        path: 'createCredentials', // login/createCredentials
        element: (
          <Suspense>
            <CreateCredentialsPage />
          </Suspense>
        ),
      },
      {
        id: 'createCredentialsOld',
        path: 'createCredentials/:createToken', // login/createCredentials/:createToken
        element: (
          <Suspense>
            <CreateCredentialsPage />
          </Suspense>
        ),
      },
    ],
  },
  {
    id: 'employerOnboarding',
    path: '/onboarding', // onboarding
    element: <LoginLayout />,
    children: [
      {
        id: '', // welcome screen
        path: '',
        element: (
          <Suspense>
            <OnboardingLandingPage />
          </Suspense>
        ),
      },
      {
        id: 'type-employer', // welcome screen
        path: 'employer/type',
        element: (
          <Suspense>
            <BusinessOnboardingLandingPage />
          </Suspense>
        ),
      },
      {
        id: 'type-employee', // welcome screen
        path: 'employee/type',
        element: (
          <Suspense>
            <EmployeeOnboardingLandingPage />
          </Suspense>
        ),
      },
      {
        id: 'employee', // welcome screen
        path: 'employee',
        element: (
          <Suspense>
            <EmployeeOnboarding />
          </Suspense>
        ),
      },
      {
        id: 'employer', // welcome screen
        path: 'employer',
        element: (
          <Suspense>
            <EmployerOnboarding />
          </Suspense>
        ),
      },
    ],
  },
];
