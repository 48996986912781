import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AnnualLimitsDto } from 'core/models/annualLimitsDto.model';
import ApiService from 'core/services/api.service';
import { RootState } from 'states/store';

interface EmployerAnnualLimitsState {
  annualLimits: AnnualLimitsDto[];
  error: string;
}

const initialState: EmployerAnnualLimitsState = {
  annualLimits: [],
  error: '',
};

export const getAnnualLimitsAsync = createAsyncThunk(
  'employerAnnualLimits/getAnnualLimitsAsync',
  async (): Promise<any> => {
    const response = await ApiService.getAnnualLimits();
    return response.data;
  }
);

const employerAnnualLimitsSlice = createSlice({
  name: 'employerAnnualLimits',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAnnualLimitsAsync.fulfilled, (state, action) => {
      state.annualLimits = action.payload;
    });
  },
});

export const selectEmployerAnnualLimits = (state: RootState) => state.employerAnnualLimits.annualLimits;

const employerAnnualLimitsReducer = employerAnnualLimitsSlice.reducer;
export default employerAnnualLimitsReducer;
