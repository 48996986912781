export const roundTwoDecimal = (num: number) => {
  if (!num) return 0;
  return Math.floor(num * 100) / 100;
};

export const calcTotalTaxes = (distributionTaxes: {
  federalWithholdingTax: number;
  stateWithholdingTax: number;
  additionalFederalWithholdingTax: number;
  additionalStateWithholdingTax: number;
}) => {
  return Number(
    Object.values(distributionTaxes ?? [])
      .filter(Number)
      .map(Number)
      .reduce((pre, curr) => pre + curr, 0)
      .toFixed(2)
  );
};
