export enum CookieNames {
  UserData = "UserData",
  CurrentRole = "CurrentRole",
  CurrentCompanyId = "CurrentCompanyId",
  AllRoles = "AllRoles",
  XCSSession = "X-CS-SESSION",
  SystemAdminRole = "SystemAdminRole",
  LastLogin = "LastLogin",
  PictureUrl = "PictureUrl",
  IsActiveEmployee = "IsActiveEmployee",
  UserProfileId = "UserProfileId",
  AdminLoginAsUser = "AdminLoginAsUser",
  UserProfileStatus = "UserProfileStatus",
  CompanyStatus = "CompanyStatus",
  OnboardingStep = "OnboardingStep",
  OnboardingCompanyDetailsCurrentView = "OnboardingCompanyDetailsCurrentView",
  OnboardingContributionAllocationView = "OnboardingContributionAllocationView",
  PersonId = "PersonId",
  OnboardingPlanId = "OnboardingPlanId",
  OnboardingHaveRetirementPlan = "OnboardingHaveRetirementPlan",
  OnboardingRecommendedPortfolio = "OnboardingRecommendedPortfolio",
  OnboardingAnswersId = "OnboardingAnswersId",
  OnboardingCreateYourLoginCurrentView = "OnboardingCreateYourLoginCurrentView",
  EmployeeRead = "EmployeeRead",
  OnboardingPreviousStep = "OnboardingPreviousStep",
  OnboardingMasterPlan = "OnboardingMasterPlan",
  CorpBusinessOnboarding = "CorpBusinessOnboarding",
  ParentCompanyId = "ParentCompanyId",
  SysAdminLoginAsUser = "SysAdminLoginAsUser",
  RequestParams = "RequestParams",
  IsOptingOut = "IsOptingOut",
  WebVersionId = "WebVersionId",
}
