import { setErrorMessage } from "states/snackbarMessage/snackbarMessageSlice";
import axios, { AxiosError } from "axios";
import { CookieNames } from "core/enums/cookie-names.enum";
import { getCookie } from "core/services/cookie.service";
import { store } from "states/store";
declare module "axios" {
  export interface AxiosRequestConfig {
    skipErrorHandle?: boolean;
  }
}
const publicApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-CS-Auth": process.env.REACT_APP_API_KEY,
  },
  timeout: 60000,
});

publicApi.interceptors.request.use(
  (config) => {
    if (config.headers["X-Cs-Session"] === undefined) {
      config.headers.set("X-Cs-Session", getCookie(CookieNames.XCSSession));
    }
    for (let headerName in config.headers) {
      if (config.headers[headerName] === null) {
        delete config.headers[headerName];
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

publicApi.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error: any | AxiosError) => {
    const errorResponse: string = error?.response?.data || "Error";
    try {
      //try-catch handle custom error for account recovery page
      if (axios.isAxiosError(error)) {
        const skipErrorHandle = error?.config?.skipErrorHandle;
        if (skipErrorHandle) {
          return Promise.reject(JSON.stringify(errorResponse));
        }
      }
    } catch (error) {
      //ignored
    }
    store.dispatch(
      setErrorMessage(error?.response?.data?.error || error.message || "Error")
    );
    return Promise.reject(JSON.stringify(errorResponse));
  }
);

export default publicApi;
