import { ROLES } from "core/constants/roles";
import { CookieNames } from "core/enums/cookie-names.enum";
import { getCookie, setCookie } from "core/services/cookie.service";
import { ICookie, ISession } from "contexts/AuthProvider";

export default class AuthService {
  static getAllRole = (payload: any): ICookie[] => {
    const allRole: ICookie[] = [];
    const { values, companyIdAndName, companyIdAndUserProfile } =
      payload as ISession;
    const { role, systemAdmin, cpaAdmin, corpAdmin, pepAdmin } = values;
    if (systemAdmin) {
      allRole.push({
        currentRole: ROLES.SYSTEM_ADMIN,
        systemAdminRole: values.systemAdminRole,
        currentCompanyName: "System Admin",
        mainPage: "/admin/manage-employers",
      });
    }
    // START: process old data
    let listRole: any[] = role ? Object.values(role) : [];
    listRole = [].concat(...listRole);
    if (cpaAdmin && !listRole.includes(ROLES.CPA_ADMIN)) {
      allRole.push({
        currentRole: ROLES.CPA_ADMIN,
        currentCompanyName: "Plan Admin Portal",
        mainPage: "/cpa/dashboard",
      });
    }

    if (corpAdmin && !listRole.includes(ROLES.CORP_ADMIN)) {
      allRole.push({
        currentRole: ROLES.CORP_ADMIN,
        currentCompanyName: "Corporate Platform Plan Admin",
        mainPage: "/corp/dashboard",
      });
    }

    if (pepAdmin && !listRole.includes(ROLES.PEP_ADMIN)) {
      allRole.push({
        currentRole: ROLES.PEP_ADMIN,
        currentCompanyName: "Pep Admin Portal",
        mainPage: "/pep/dashboard",
      });
    }
    // END: process old data
    if (role) {
      Object.keys(role).forEach((companyId) => {
        const arrayRole = role[companyId];
        let arrayUserProfile = companyIdAndUserProfile
          ? companyIdAndUserProfile[companyId]
          : "";
        let arrayName = companyIdAndName[companyId];
        for (let i = 0; i < arrayRole.length; i++) {
          const reqParams = getCookie(CookieNames.RequestParams);
          const roleObject = {
            currentRole: arrayRole[i],
            currentCompanyId: companyId,
            currentUserProfileId: arrayUserProfile || "",
            currentCompanyName: arrayName || "",
            mainPage:
              arrayRole[i] === ROLES.COMPANY_ADMIN
                ? "/dashboard/employer"
                : arrayRole[i] === ROLES.PARTICIPANT
                ? reqParams &&
                  reqParams.redirectUrl &&
                  reqParams.userProfileId === arrayUserProfile
                  ? reqParams.redirectUrl
                  : "/dashboard/employee"
                : arrayRole[i] === ROLES.CPA_ADMIN
                ? "/cpa/dashboard"
                : arrayRole[i] === ROLES.CORP_ADMIN
                ? "/corp/dashboard" // CORPORATE PLATFORM ADMIN
                : "/pep/dashboard", // PEP_ADMIN
          };
          allRole.push(roleObject);
        }
      });
    }
    return allRole;
  };

  static getPathAfterLoginSuccess = (payload: any) => {
    const allRole: ICookie[] = this.getAllRole(payload);
    let mainPage = "";
    if (allRole.length > 1) {
      setCookie(CookieNames.CurrentRole, ROLES.SELECT_ROLE);
      mainPage = "/dashboard/selectRole";
    } else {
      setCookie(CookieNames.CurrentRole, allRole[0].currentRole);
      setCookie(
        CookieNames.CurrentCompanyId,
        allRole[0].currentCompanyId || ""
      );
      setCookie(
        CookieNames.UserProfileId,
        allRole[0].currentUserProfileId || ""
      );
      mainPage = allRole[0].mainPage as string;
    }
    return mainPage;
  };
}
