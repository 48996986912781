// import { Tooltip } from "@mui/material";
import { TextCurrency } from "common/components";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const BaseTotalContribution = ({
  title,
  bgColor = "",
  ...props
}: {
  title: string;
  bgColor?: string;
}) => {
  return (
    <div className={`bg-${bgColor} p-2 rounded-lg flex flex-col mb-8 gap-2`}>
      <div>
        <span className="text-xs font-semibold pl-4 text-superBlack">
          {title}
        </span>
        {/* <Tooltip title="">
          <InfoOutlinedIcon
            className="text-primary w-[16px] h-[16px] ml-1"
            onClick={}
          />
        </Tooltip> */}
      </div>
      <div>
        <TextCurrency allowNegative={false} disabled {...props} />
      </div>
    </div>
  );
};
