export const CONFIG = {
  APP_NAME: "Saveday",
  PHONE: "(650) 684-SAVE(7283)",
  PHONE_VALUE: "650-684-7283",
  ADDRESS: "123 Main St.",
  EMAIL: "support@saveday.com",
  CONTACT_EMAIL: "contact@saveday.com",
  SAFE_HARBOR_ADDRESS: "https://www.export.gov/safeharbor",
  PRIVACY_SAVEDAY_EMAIL: "Privacy@Saveday.com",
  RETIREMENT_PLANS_URL:
    "https://www.irs.gov/retirement-plans/plan-sponsor/types-of-retirement-plans",
  SAVEDAY_WEBSITE: "https://go.saveday.com/login",
  ERISA_BOND_URL:
    "https://www.saveday.com/erisa-fidelity-bonds-what-you-need-to-know/",
  COMPLIANCE_EMAIL: "compliance@saveday.com",
  KAPITALL_GENERATION_DISCLOSURES_AND_NOTICES:
    "https://www.saveday.com/legal-center/kapitall-generation-disclosures-and-notices/",
  HELP_CENTER: "https://saveday.zendesk.com/hc/en-us",
  FE_VERSION: "v2024-06-26",
};
