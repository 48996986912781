import React from 'react';
import MuiTooltip, { TooltipProps as MUITooltipProps } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

type TooltipProps = {
  children?: React.ReactElement<any, any>;
} & Omit<MUITooltipProps, 'children'>;

export const Tooltip = (props: TooltipProps) => {
  const { children = <InfoOutlinedIcon className="text-beige w-[16px] h-[16px] ml-1" />, ...restProps } = props;
  return (
    <MuiTooltip
      componentsProps={{
        tooltip: {
          sx: {
            color: (theme) => theme.palette.textTooltip,
            background: (theme) => theme.palette.bgTooltip,
          },
        },
        arrow: {
          sx: {
            color: (theme) => theme.palette.bgTooltip,
          },
        },
      }}
      arrow
      placement="bottom"
      enterTouchDelay={0}
      leaveTouchDelay={60000}
      {...restProps}
    >
      {children}
    </MuiTooltip>
  );
};
