import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompanyStatus } from 'core/enums/company.enum';
import { CompanyDto, CompanyPaginationDto } from 'core/models/companyDto.model';
import ApiService from 'core/services/api.service';
import { RootState } from 'states/store';
import { EmployerContributionResponse } from '../../core/models/EmployerContributionDto.model';
import { REQUEST_STATUS } from 'core/constants/roles';

interface ManageEmployerState {
  companyList: CompanyPaginationDto;
  cpaCompanyList: CompanyPaginationDto;
  companyDetail: CompanyDto | null;
  lastCashJournalFile: string;
  loading: boolean;
  getCompanyDetailsStatus: REQUEST_STATUS;
  updateCompanyStatus: REQUEST_STATUS;
  pendingNotificationsCount: number;
}

const initialState: ManageEmployerState = {
  companyList: {
    content: [],
    empty: true,
    first: true,
    last: true,
    number: 0,
    numberOfElements: 0,
    size: 0,
    totalElements: 0,
    totalPages: 0,
  },
  cpaCompanyList: {
    content: [],
    empty: true,
    first: true,
    last: true,
    number: 0,
    numberOfElements: 0,
    size: 0,
    totalElements: 0,
    totalPages: 0,
  },
  companyDetail: null,
  lastCashJournalFile: '',
  loading: false,
  getCompanyDetailsStatus: REQUEST_STATUS.IDLE,
  updateCompanyStatus: REQUEST_STATUS.IDLE,
  pendingNotificationsCount: 0,
};

export const getRetrieveCompanyNamesAndIdsAsync = createAsyncThunk(
  'manageEmployer/getRetrieveCompanyNamesAndIdsAsync',
  async (filter: any = {}): Promise<CompanyPaginationDto> => {
    const response = await ApiService.getRetrieveCompanyNamesAndIds(filter);
    return response.data;
  }
);

export const getCompanyDetailsAsync = createAsyncThunk(
  'manageEmployer/getCompanyDetailsSync',
  async (id: string): Promise<any> => {
    const response = await ApiService.getCompanyDetails(id);
    return response.data;
  }
);

export const getAllCompanyPendingNotificationsCountAsync = createAsyncThunk(
  'manageEmployer/getAllCompanyPendingNotificationsCountAsync',
  async (id: string): Promise<any> => {
    const response = await ApiService.getAllCompanyPendingNotificationsCount(id);
    return response.data;
  }
);

export const updateCompanyAsync = createAsyncThunk(
  'manageEmployer/updateCompanyAsync',
  async (params: { companyDetail: CompanyDto; filter?: any }): Promise<any> => {
    const { companyDetail, filter } = params;
    const response = await ApiService.updateCompany(companyDetail, filter);
    return response.data;
  }
);

export const getLastCashJournalFileAsync = createAsyncThunk(
  'manageEmployer/getLastCashJournalFileAsync',
  async (id: string): Promise<any> => {
    const response = await ApiService.getLastCashJournalFile(id);
    return response.data;
    // if (response?.status === StatusCode.SUCCESS) {
    //   const downloadLashCashJournalFile =
    // await ApiService.downloadLashCashJournalFile(response.data.url);
    //   if (downloadLashCashJournalFile?.status === StatusCode.SUCCESS) {
    //     return downloadLashCashJournalFile;
    //   }
    // }
  }
);

export const getCashJournalFileAsync = createAsyncThunk(
  'manageEmployer/getCashJournalFileAsync',
  async (id: string): Promise<any> => {
    const response = await ApiService.getCashJournalFile(id);
    return response.data;
  }
);

export const verifyCompanyAsync = createAsyncThunk(
  'manageEmployer/verifyCompanyAsync',
  async (verifyCompany: { id: string; payload: { status: CompanyStatus }; filter?: any }): Promise<any> => {
    const response = await ApiService.verifyCompany(verifyCompany);
    return response.data;
  }
);

export const createCashJournalFileAsync = createAsyncThunk(
  'manageEmployer/createCashJournalFileAsync',
  async ({
    id,
    payrollContribution,
  }: {
    id: string;
    payrollContribution: EmployerContributionResponse;
  }): Promise<any> => {
    const response = await ApiService.createCashJournalFile({
      id,
      payrollContribution,
    });
    return response.data;
  }
);

const manageEmployerSlice = createSlice({
  name: 'manageEmployer',
  initialState,
  reducers: {
    setCompanyDetail: (state, action: PayloadAction<CompanyDto | null>) => {
      state.companyDetail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRetrieveCompanyNamesAndIdsAsync.pending, (state) => {})
      .addCase(getRetrieveCompanyNamesAndIdsAsync.fulfilled, (state, action) => {
        state.companyList = action.payload;
      })
      .addCase(getLastCashJournalFileAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLastCashJournalFileAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.lastCashJournalFile = action.payload;
      })
      .addCase(getLastCashJournalFileAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(createCashJournalFileAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCashJournalFileAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createCashJournalFileAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getCashJournalFileAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCashJournalFileAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getCashJournalFileAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(verifyCompanyAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(verifyCompanyAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(updateCompanyAsync.pending, (state) => {
        state.loading = true;
        state.updateCompanyStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(updateCompanyAsync.fulfilled, (state, action) => {
        state.companyDetail = action.payload;
        state.loading = false;
        state.updateCompanyStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(updateCompanyAsync.rejected, (state) => {
        state.loading = false;
        state.updateCompanyStatus = REQUEST_STATUS.ERROR;
      })
      .addCase(getCompanyDetailsAsync.pending, (state) => {
        state.loading = true;
        state.getCompanyDetailsStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(getCompanyDetailsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.companyDetail = action.payload;
        state.getCompanyDetailsStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(getCompanyDetailsAsync.rejected, (state) => {
        state.loading = false;
        state.getCompanyDetailsStatus = REQUEST_STATUS.ERROR;
      })
      .addCase(getAllCompanyPendingNotificationsCountAsync.fulfilled, (state, action) => {
        state.pendingNotificationsCount = action.payload;
      });
  },
});

export const { setCompanyDetail } = manageEmployerSlice.actions;

export const selectCompanyDetail = (state: RootState) => state.manageEmployer.companyDetail;
export const selectLoading = (state: RootState) => state.manageEmployer.loading;

const manageEmployeeReducer = manageEmployerSlice.reducer;
export default manageEmployeeReducer;
