import React from 'react';
import { Tab as MuiTab, TabProps as MuiTabProps } from '@mui/material';

type TTabProps = MuiTabProps & {};

export const Tab = ({ sx, ...props }: TTabProps) => {
  return (
    <>
      <MuiTab
        sx={{
          minHeight: 0,
          padding: '10px 16px',
          margin: '0 8px',
          borderRadius: '50px',
          '&:hover': {
            backgroundColor: 'rgba(56, 149, 131, 0.16)',
          },
          '&.Mui-selected': {
            color: 'white',
          },
          ...sx,
        }}
        {...props}
      />
    </>
  );
};
