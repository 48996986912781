import React from "react";

export default function LiveChatIcon() {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.25892 17.0935C3.14073 15.2085 2.74913 12.9802 3.15765 10.8269C3.56618 8.67359 4.74672 6.7435 6.47761 5.39903C8.2085 4.05455 10.3707 3.3882 12.5581 3.52508C14.7455 3.66196 16.8077 4.59267 18.3575 6.14244C19.9073 7.6922 20.838 9.75442 20.9749 11.9418C21.1118 14.1293 20.4454 16.2914 19.101 18.0223C17.7565 19.7532 15.8264 20.9338 13.6731 21.3423C11.5198 21.7508 9.29146 21.3593 7.40646 20.2411L7.40648 20.241L4.29805 21.1291C4.16944 21.1658 4.03335 21.1675 3.90387 21.134C3.7744 21.1004 3.65625 21.0329 3.56167 20.9383C3.4671 20.8437 3.39953 20.7256 3.36598 20.5961C3.33243 20.4666 3.33412 20.3305 3.37086 20.2019L4.25898 17.0935L4.25892 17.0935Z"
        stroke="#69AB00"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.49988 13.6288C8.1212 13.6288 8.62488 13.1251 8.62488 12.5038C8.62488 11.8825 8.1212 11.3788 7.49988 11.3788C6.87856 11.3788 6.37488 11.8825 6.37488 12.5038C6.37488 13.1251 6.87856 13.6288 7.49988 13.6288Z"
        fill="#69AB00"
      />
      <path
        d="M12 13.6288C12.6213 13.6288 13.125 13.1251 13.125 12.5038C13.125 11.8825 12.6213 11.3788 12 11.3788C11.3787 11.3788 10.875 11.8825 10.875 12.5038C10.875 13.1251 11.3787 13.6288 12 13.6288Z"
        fill="#69AB00"
      />
      <path
        d="M16.5001 13.6288C17.1214 13.6288 17.6251 13.1251 17.6251 12.5038C17.6251 11.8825 17.1214 11.3788 16.5001 11.3788C15.8788 11.3788 15.3751 11.8825 15.3751 12.5038C15.3751 13.1251 15.8788 13.6288 16.5001 13.6288Z"
        fill="#69AB00"
      />
    </svg>
  );
}
