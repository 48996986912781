import { DateFormatPattern } from "core/enums/date-format-pattern.enum";
import moment from "moment";

/**
 *
 * @param timestamp1 timestamp or DD/MM/YYYY
 * @param timestamp2 timestamp or DD/MM/YYYY
 */
export const sortByYMD = (
  timestamp1: number | string,
  timestamp2: number | string
) => {
  return moment.utc(timestamp1).diff(moment.utc(timestamp2));
};

export const formatTimestamp = (
  timestamp: number,
  format: DateFormatPattern = DateFormatPattern.MonthDayYear
) => {
  return moment.utc(timestamp).format(format);
};
