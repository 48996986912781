import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";

export const BaseQuickLink = ({
  title,
  onRedirect,
}: {
  title: string;
  onRedirect: () => any;
}) => {
  return (
    <div className="flex items-center	gap-4 cursor-pointer" onClick={onRedirect}>
      <span className="p-2 bg-secondary">
        <OpenInNewOutlinedIcon className="text-lighter" />
      </span>{" "}
      <span className="text-secondary font-medium	text-sm">{title}</span>
    </div>
  );
};
