import { appPalette } from 'core/constants';
import * as React from 'react';
import { Cell, Label, Pie, PieChart, Sector } from 'recharts';

const CustomLabel = ({ cx, cy, labelText, value }: any) => {
  return (
    <g>
      <text
        x={cx}
        y={cy - 3}
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
        alignmentBaseline="middle"
        fill={appPalette.primary}
        fontSize="26"
      >
        {value}
      </text>
      <text
        x={cx}
        y={cy + 15}
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
        alignmentBaseline="middle"
        fill={appPalette.secondary}
        fontSize="12"
      >
        {labelText}
      </text>
    </g>
  );
};

const renderActiveShape = (props: any) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, percent } = props;

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill} fontSize="26">
        {`${(percent * 100).toFixed(2)}%`}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

interface IDonutChartProps {
  data: any[];
  width: number;
  height: number;
}

export default function DonutChart({ data, width, height }: IDonutChartProps) {
  const [activeIndex, setActiveIndex] = React.useState(-1);

  return (
    <PieChart width={width} height={height} className="mx-auto">
      <Pie
        activeIndex={activeIndex}
        activeShape={renderActiveShape}
        data={data}
        cx={width / 2}
        cy={height / 2}
        innerRadius={88}
        outerRadius={105}
        dataKey="value"
        startAngle={90}
        endAngle={360 + 90}
        onMouseEnter={(data: any, index: number) => {
          if (index === 0) {
            setActiveIndex(index);
          } else {
            setActiveIndex(-1);
          }
        }}
        onMouseLeave={() => {
          setActiveIndex(-1);
        }}
      >
        <Cell fill={appPalette.secondary} strokeLinecap="round" />
        <Cell fill="#eee" />
        {activeIndex !== 0 && (
          <Label
            content={
              <CustomLabel
                cx={width / 2}
                cy={height / 2}
                labelText={data[0].value !== 1 ? 'Active Users' : 'Active User'}
                value={data[0].value}
              />
            }
            position="center"
          />
        )}
      </Pie>
    </PieChart>
  );
}
