import React, { useEffect, useRef, useState } from 'react';
import ModalUpload from 'components/employerManagePlan/UploadModal';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Controller, useFormContext } from 'react-hook-form';
import { Button, TextField } from 'common/components';
import { initFileValue, setFormName } from 'components/employerManagePlan/init-func';
import { FormHelperText } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IUploadPlanType } from './UploadPlan';
import { IUploadPlanDTO } from 'core/models/UploadPlanRequest.model';
import { fileToBase64, fileUrlToBase64 } from 'core/functions';
import { useSearchParams } from 'react-router-dom';
import { AccountTypes } from 'core/enums/company.enum';

const FILE_ACCEPTED_TYPES = '.pdf';
const MAX_FILE_SIZE = 20 * 1024 * 1024;
const MIN_FILE_SIZE = 1024 * 2; // 2KB

export const UploadFileComp = ({
  field,
  fileInfo,
  idx,
  acceptFiles,
}: {
  acceptFiles?: string;
  fileInfo: IUploadPlanType;
  field: any;
  idx?: number;
}) => {
  const { name, title, fileType } = fileInfo;
  const {
    control,
    setValue,
    watch,
    formState: { errors },
    clearErrors,
  } = useFormContext();
  const [searchParams] = useSearchParams();
  const accountType = searchParams.get('accountType');
  const prefixName = 'plans';
  const [file, setFile] = useState<IUploadPlanDTO>(initFileValue());
  const fileRef = useRef<string>(JSON.stringify(initFileValue()));
  const [isShowModal, setShowModal] = useState(false);
  const hasURL = useRef(false);
  const fieldName = setFormName(prefixName, Boolean(field), idx);
  const fileWatch = watch(fieldName(name));
  const hasFileExist = !!file.name;
  const formatTitle = [AccountTypes.LEGACY_PLAN].includes(accountType as AccountTypes)
    ? title
    : title.replaceAll('*', '');
  const error = errors[prefixName] && Array.isArray(errors[prefixName]) ? errors[prefixName][idx || 0] : errors;

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const getFileValue = (file: IUploadPlanDTO) => {
    if (!file) return {};
    return {
      name: file?.name,
      lastModified: file?.lastModified,
      size: file?.size,
      type: file?.fileType,
      url: file?.url,
    };
  };

  const handlePreview = async () => {
    const newTab = window.open();
    const isImage = (acceptFiles ?? '').indexOf('image') !== -1;

    if (newTab) {
      if (isImage) {
        if (hasURL.current) {
          newTab.document.body.innerHTML = `<img title="imgDoc" src="${fileWatch?.url}" width="100%" height="100%"></img>`;
        } else {
          const base64String = hasURL.current
            ? await fileUrlToBase64(fileWatch?.url)
            : await fileToBase64(fileWatch?.file);
          newTab.document.title = fileType;
          newTab.document.body.innerHTML = `<img title="imgDoc" src="${base64String}" width="100%" height="100%"></img>`;
        }
      } else {
        const base64String = hasURL.current
          ? await fileUrlToBase64(fileWatch?.url)
          : await fileToBase64(fileWatch?.file);
        newTab.document.title = fileType;
        newTab.document.body.innerHTML = `<iframe title="pdfDoc" src="${base64String}" width="100%" height="100%"></iframe>`;
      }
    }
  };

  const handleCacheFile = (values: IUploadPlanDTO) => {
    fileRef.current = JSON.stringify(getFileValue(values?.file ?? values));
  };

  const handleChangeFile = (value: IUploadPlanDTO) => {
    setFile((prev) => ({
      ...prev,
      ...getFileValue(value?.file ?? value),
    }));
  };

  const handleFile = (value: IUploadPlanDTO) => {
    const formatValue = {
      ...value,
      isUpdatedFile: true,
    };
    setValue(fieldName(name), formatValue);
    clearErrors(fieldName(name));
  };

  const handleActionFile = async () => {
    if (hasURL.current) {
      await handlePreview();
    } else {
      handleShowModal();
    }
  };

  useEffect(() => {
    if (JSON.stringify(fileWatch) !== fileRef.current) {
      if (fileWatch?.url) {
        hasURL.current = true;
      } else {
        hasURL.current = false;
      }
      handleChangeFile(fileWatch);
      handleCacheFile(fileWatch);
    }
  }, [fileWatch]);

  return (
    <>
      <div className="flex items-center gap-2 mt-0">
        <div className="flex gap-4 items-center">
          <Button
            className={`w-auto min-w-0 max-w-full m-0 p-0 px-1 hover:text-secondary ${
              hasFileExist ? 'text-secondary' : 'text-darkest'
            }`}
            variant="text"
            startIcon={hasURL.current ? <VisibilityIcon /> : <CloudUploadIcon />}
            onClick={handleActionFile}
          >
            {formatTitle}
          </Button>
          {hasFileExist && !hasURL.current && (
            <span
              onClick={handlePreview}
              className="text-secondary text-sm cursor-pointer flex items-center gap-1 font-semibold"
            >
              <VisibilityIcon />
              Preview
            </span>
          )}
        </div>
        <ModalUpload
          minFileSize={MIN_FILE_SIZE}
          maxFileSize={MAX_FILE_SIZE}
          acceptFiles={acceptFiles ?? FILE_ACCEPTED_TYPES}
          isOpen={isShowModal}
          title={formatTitle}
          fileType={fileType}
          onCancel={handleCloseModal}
          onSuccess={(file: File) => {
            handleFile({
              file,
              fileType,
            });
          }}
        />
        <div hidden>
          <Controller control={control} name={name} render={({ field }) => <TextField {...field} />} />
        </div>
      </div>
      <FormHelperText className="text-errorColor ml-0 mt-0">
        {error && (error?.[name]?.fileType?.message as string)}
      </FormHelperText>
    </>
  );
};
