import { ProfileDto } from 'core/models/profileDto.model';
import { Actions } from 'core/enums/action-enum';
import { EditCompanyAdmin, GetAllExistingPerson, NewCompanyAdmin } from './../models/companyAdminDto.model';
import { EmployerStatementDto } from 'core/models/statementsDto.model';
import {
  ChangePasswordPayload,
  EditProfileDto,
  EmployeeProfileDto,
  EmployeeProfilePayload,
  EmployeeResponseSuccessDto,
  EnabledTwoFARequest,
  IChangeAuthorizedSignatoryRequest,
  ICreatePlanAdminsRequest,
  ICreateUpdateBasicPlanAdmin,
  IFeedbackItem,
  IFilterListEmployee,
  IFinchProvidersFilter,
  IRecoveryAccountPayload,
  ISearchParticipantQuery,
  PendingEmployeeDetailForm,
  PendingEmployeeResponse,
  PeoplePayload,
  VerifiedSMSCodeRequest,
  VerifiedTwoFARequest,
} from 'core/models/employeeDto.model';
import { Filter } from 'core/models/pagination.model';
import { ForgotUserNameDto } from 'core/models/forgotUserNameDto.model';
import { LoginResponseSuccessDto } from 'core/models/loginResponseSuccessDto.model';
import { EmployerContributionResponse } from 'core/models/EmployerContributionDto.model';
import { UserRoleDto } from 'core/models/userRoleDto.model';
import { API_AUTH } from 'core/services/api-auth.service';
import { IResetPassword } from 'core/models/resetPassword.model';
import { LoginDto } from 'core/models/loginDto.model';
import { API } from './utils/app.routes';
import publicApi from './utils/HttpRequest';
import { getApiPath } from './utils/routesUtils';
import { ContributionDto, PayRollDto } from 'core/models/payrollDto.model';
import { RiskQuestionPayload } from 'core/models/riskQuestionDto.model';
import { OtherMatchingFormulaValuesDto } from 'core/models/OtherMatchingFormulaValuesDto.model';
import { ContributionsLimitDto } from 'core/models/ContributionsLimitDto.model';
import { PlanDetailDto, PlanFormData } from 'core/models/PlanDetailDto.model';
import { CompanyDto, CompanyPaginationDto, EditCompanyDto } from 'core/models/companyDto.model';
import {
  UploadFileDto,
  GetPlanFileResponse,
  GetTrustReportResponse,
  UploadGenericFileResponse,
  PlanTransferDto,
} from 'core/models/UploadFileDto.model';
import { UploadPlanRequest } from 'core/models/UploadPlanRequest.model';
import { FormStatement } from 'core/models/statementDto.model';
import { CompanyAdminResponse, GetCompanyAdmin } from 'core/models/companyAdminDto.model';
import { CreateErisaBondPayload, GetFormFilingPayload } from 'core/models/formFilingDto.model';
import { StatusReportPayload } from 'core/models/employerDashboardDto.model';
import { AxiosRequestConfig } from 'axios';
import {
  AccountDetailsDto,
  AccountDetailsPayload,
  validateAccountNumberPayload,
} from 'core/models/accountManagementDto.model';
import { PlanTypes } from 'core/enums/plan-type.enum';
import { OnboardingFinchStatus, OnboardingPlaidStatus, OnboardingStages } from 'core/enums';
import {
  ILateContributionFilter,
  ILateSubmissionFilter,
  LateContributionItem,
} from 'core/models/LateContribution.model';

const login = (login: LoginDto): Promise<any> => {
  const path: string = getApiPath(API.LOGIN);
  return publicApi.put(path, login);
};

const forgotUsername = (forgotUserNameDto: ForgotUserNameDto, config: AxiosRequestConfig<any> = {}): Promise<any> => {
  return publicApi.post(API.FORGOT_USERNAME.path, forgotUserNameDto, config);
};

function getRetrieveCompanyNamesAndIds(filter: any = {}): Promise<{
  data: CompanyPaginationDto;
}> {
  const path: string = getApiPath(API.RETRIEVE_COMPANY_NAMES_AND_IDS, {
    filter,
  });
  return publicApi.get(path);
}

function getEmployeeByCompanyId(id: string): Promise<{ data: EmployeeResponseSuccessDto }> {
  const path: string = getApiPath(API.GET_EMPLOYEE_BY_COMPANY_ID, { id });
  return publicApi.get(path);
}

function getPendingEmployeeByCompanyId(id: string): Promise<{ data: EmployeeResponseSuccessDto }> {
  const path: string = getApiPath(API.GET_PENDING_EMPLOYEE_BY_COMPANY_ID, {
    id,
  });
  return publicApi.get(path);
}

function getAccountDetails(payload: AccountDetailsPayload): Promise<{ data: EmployeeResponseSuccessDto }> {
  const path: string = getApiPath(API.GET_ACCOUNT_DETAILS);
  return publicApi.post(path, payload);
}

export function getEmployeeDetails(personId: string | undefined): Promise<{ data: ProfileDto }> {
  const path: string = getApiPath(API.GET_EMPLOYEE_DETAILS, { personId });
  return publicApi.get(path);
}

export function getPendingEmployeeDetails(pendingUserId: string | undefined): Promise<{ data: ProfileDto }> {
  const path: string = getApiPath(API.PENDING_EMPLOYEE_DETAILS, {
    pendingUserId,
  });
  return publicApi.get(path);
}

function updatePendingEmployeeDetails(
  payload: PendingEmployeeDetailForm & { id?: string }
): Promise<{ data: PendingEmployeeResponse }> {
  const path: string = getApiPath(API.PENDING_EMPLOYEE);
  return publicApi.patch(path, payload);
}

function validateAccountNumber(payload: validateAccountNumberPayload): Promise<any> {
  const path: string = getApiPath(API.VALIDATE_ACCOUNT_NUMBER);
  return publicApi.post(path, payload);
}

function updateAccountDetail(payload: AccountDetailsDto): Promise<any> {
  const path: string = getApiPath(API.UPDATE_ACCOUNT_DETAIL);
  return publicApi.post(path, payload);
}

function updateSession(userRole: UserRoleDto): Promise<{ data: LoginResponseSuccessDto }> {
  const path: string = getApiPath(API.LOGIN);
  return publicApi.patch(path, userRole);
}

function getSession(): Promise<{ data: LoginResponseSuccessDto }> {
  const path: string = getApiPath(API.LOGIN);
  return publicApi.get(path);
}

function getOtherMatchingFormulaValues(): Promise<{
  data: OtherMatchingFormulaValuesDto[];
}> {
  const path: string = getApiPath(API.OTHER_MATCHING_FORMULAVALUES);
  return publicApi.get(path);
}

function getContributionsLimit(): Promise<{
  data: ContributionsLimitDto[];
}> {
  const path: string = getApiPath(API.CONTRIBUTIONS_LIMITS);
  return publicApi.get(path);
}

function createPlan(data: PlanFormData): Promise<any> {
  const path: string = getApiPath(API.PLAN);
  return publicApi.put(path, data);
}

function updatePlan(data: PlanFormData): Promise<any> {
  const path: string = getApiPath(API.PLAN);
  return publicApi.patch(path, data);
}

export function getPlanDetail(planDetailId: string | undefined): Promise<{
  data: PlanDetailDto[];
}> {
  const path: string = getApiPath(API.PLAN_DETAIL, { id: planDetailId });
  return publicApi.get(path);
}

function updatePlanStatus(planId: string, status: string): Promise<any> {
  const path: string = getApiPath(API.UPDATE_PLAN_STATUS, {
    planId: planId,
    status: status,
  });
  return publicApi.patch(path, { planId: planId, status: status });
}

export function uploadFile(data: UploadPlanRequest): Promise<{ data: UploadFileDto }> {
  const { file, ...res } = data;
  const path: string = getApiPath(API.UPLOAD_FILE);
  return publicApi.put(path, res);
}

function updateUploadStatus(fileLocId: string): Promise<any> {
  const path: string = getApiPath(API.UPDATE_UPLOAD_STATUS, {
    fileId: fileLocId,
  });
  return publicApi.patch(path);
}

export function getCompanyDetails(id: string): Promise<{ data: CompanyDto }> {
  const path: string = getApiPath(API.COMPANIES, { id });
  return publicApi.get(path);
}

function getAllCompanyPendingNotificationsCount(id: string): Promise<{ data: CompanyDto }> {
  const path: string = getApiPath(`/api/notifications/${id}/getAllPendingNotificationsCount`, { id });
  return publicApi.get(path);
}

export function completeFinchAuth({ code, id }: { code: string; id: string }): Promise<any> {
  const path: string = getApiPath(API.COMPLETE_FINCH_AUTH, { id });

  return publicApi.post(path, {
    authorizationCode: code,
  });
}

export function completeOnboardingFinchAuth({
  payload,
}: {
  payload: {
    finch: {
      authorizationCode: string;
    };
  };
}): Promise<any> {
  const path: string = getApiPath('/api/onboarding/employer/finch');

  return publicApi.post(path, payload);
}

export function disconnectFinch(id: string): Promise<any> {
  const path: string = getApiPath(API.DISCONNECT_FINCH, { id });

  return publicApi.post(path);
}

export function syncEmployeeWithFinch({ id, code }: { id: string; code: string }): Promise<any> {
  const path: string = getApiPath(API.SYNC_EMPLOYEE_WITH_FINCH, { id });
  return publicApi.post(path, {
    authorizationCode: code,
  });
}

export function onboardingSyncEmployeeWithFinch({
  payload,
}: {
  payload: { finchStatus: OnboardingFinchStatus; authorizationCode?: string };
}): Promise<any> {
  const path: string = getApiPath('/api/onboarding/employer/finch');
  return publicApi.patch(path, payload);
}

export function sendCodeToSyncFinch({
  payload,
}: {
  payload: {
    finch: {
      authorizationCode: string;
    };
    stage: OnboardingStages;
  };
}): Promise<any> {
  const path: string = getApiPath('/api/onboarding/employer/finch');
  return publicApi.post(path, payload);
}

export function onboardingConnectPlaid({ payload }: { payload: { plaidStatus: OnboardingPlaidStatus } }): Promise<any> {
  const path: string = getApiPath('/api/onboarding/employer/bank');
  return publicApi.patch(path, payload);
}

export const apiGetUserData = (
  currentRole: string | undefined,
  currentCompanyId?: string,
  currentUserProfileId?: string
): Promise<any> => {
  const path: string = getApiPath(API.LOGIN);

  return publicApi.patch(path, {
    currentRole: currentRole,
    currentCompanyId: currentCompanyId,
    currentUserProfileId: currentUserProfileId,
  });
};

export function getCompanyPeople(): Promise<any> {
  const path: string = getApiPath(API.COMPANY_PEOPLE);
  return publicApi.get(path);
}

function getAllReports(): Promise<any> {
  const path: string = getApiPath(API.GET_ALL_REPORTS);
  return publicApi.get(path);
}

function getActivePlanParticipant(companyId: string, filter: any): Promise<any> {
  const path: string = getApiPath(API.GET_ACTIVE_PLAN_PARTICIPANT, {
    companyId,
    filter,
  });
  return publicApi.get(path);
}

function getYtdContributions(filter: any): Promise<any> {
  const path: string = getApiPath(API.GET_YTD_CONTRIBUTIONS, {
    filter,
  });
  return publicApi.get(path);
}

function getSummaryReport(filter: any): Promise<any> {
  const path: string = getApiPath(API.GET_SUMMARY_REPORT, {
    filter,
  });
  return publicApi.get(path);
}

function getAccountBalance(filter: any): Promise<any> {
  const path: string = getApiPath(API.GET_ACCOUNT_BALANCE, {
    filter,
  });
  return publicApi.get(path);
}

function getAllContributions(filter: any): Promise<any> {
  const path: string = getApiPath(API.GET_ALL_CONTRIBUTION, {
    filter,
  });
  return publicApi.get(path);
}

export const getProfile = (): Promise<any> => {
  const path: string = getApiPath(API.PROFILE);
  return publicApi.get(path);
};

export const getPendingProfile = (): Promise<any> => {
  const path: string = getApiPath(API.PROFILE);
  return publicApi.get(path);
};

export const updateProfile = (payload: EmployeeProfilePayload | EditProfileDto): Promise<any> => {
  const path: string = getApiPath(API.PROFILE);
  return publicApi.patch(path, payload);
};

const createProfile = (payload: EmployeeProfilePayload | EditProfileDto): Promise<any> => {
  const path: string = getApiPath('/api/profiles');
  return publicApi.put(path, payload);
};

function getPeople(filter?: any): Promise<any> {
  const path: string = getApiPath(API.PEOPLE, { filter });
  return publicApi.get(path);
}

function getPeopleByEmail(email: string): Promise<any> {
  const path: string = getApiPath(API.PEOPLE_BY_EMAIL, { email });
  return publicApi.get(path);
}

function getReCaptchaSiteKey(): Promise<any> {
  const path: string = getApiPath(API.RE_CAPTCHA_SITE_KEY);
  return publicApi.get(path);
}

function updatePeople({ payload, filter = {} }: { payload: PeoplePayload; filter?: any }): Promise<any> {
  const path: string = getApiPath(API.PEOPLE, { filter });
  return publicApi.patch(path, payload);
}

function createPeople({ payload, filter = {} }: { payload: PeoplePayload; filter?: any }): Promise<any> {
  const path: string = getApiPath('/api/people', { filter });
  return publicApi.put(path, payload);
}

function getLastLogin(id: string): Promise<any> {
  const path: string = getApiPath(API.LAST_LOGIN, { id });
  return publicApi.get(path);
}

export function getPayrollcontribution(
  year: string,
  companyId: string
): Promise<{
  data: EmployerContributionResponse[];
}> {
  const path: string = getApiPath(API.GET_PAYROLL_CONTRIBUTION);
  const replacePath = path.replace(':year', year).replace(':id', companyId);
  return publicApi.get(replacePath);
}

export function getPayrollContributionDetail(payrollContributionId: string): Promise<{
  data: ContributionDto[];
}> {
  const path: string = getApiPath(API.GET_PAYROLL_CONTRIBUTION_DETAIL);
  const replacePath = path.replace(':payrollContributionId', payrollContributionId);
  return publicApi.get(replacePath);
}

function removePayrollContribution(id: string | undefined): Promise<{
  data: boolean;
}> {
  const path: string = getApiPath(API.REMOVE_PAYROLL_CONTRIBUTION, { id });
  return publicApi.delete(path);
}

function updatePayrollContribution(payload: PayRollDto): Promise<{
  data: boolean;
}> {
  const path: string = getApiPath(API.UPDATE_PAYROLL_CONTRIBUTION);
  return publicApi.post(path, payload);
}

function apiLogout() {
  const path: string = getApiPath(API_AUTH.LOGOUT);
  return publicApi.patch(path);
}

function verifyCompany(verifyCompany: { id: string; payload: { status: string }; filter?: any }): Promise<any> {
  const { id, payload, filter } = verifyCompany;
  const path: string = getApiPath(API.COMPANIES, { id, filter });
  return publicApi.patch(path, payload);
}

function startStopEligibilityTracking({ id, action }: { id: string; action: boolean }): Promise<any> {
  const path: string = getApiPath(API.START_STOP_ELIGIBILITY, { id, action });
  return publicApi.patch(path);
}

function getLastCashJournalFile(id: string): Promise<any> {
  const path: string = getApiPath(API.LAST_CASH_JOURNAL_FILE, { id });
  return publicApi.get(path);
}

function getCashJournalFile(id: string): Promise<any> {
  const path: string = getApiPath(API.GET_CASH_JOURNAL_FILE, { id });
  return publicApi.get(path);
}

function downloadLashCashJournalFile(url: string): Promise<any> {
  const params = url.split('/')[url.split('/').length - 1];
  const path: string = getApiPath(API.AWS_FILE, {
    params,
  });
  return publicApi.get(path);
}

function getPayrollContribution(year: string | number, id: string | undefined): Promise<any> {
  const path: string = getApiPath(API.GET_PAYROLL_CONTRIBUTION, {
    year,
    id,
  });
  return publicApi.get(path);
}

export function getProcessPayrolls(params: { companyId: string; filter: any }): Promise<any> {
  const { companyId, filter } = params;
  const path: string = getApiPath(`/api/contributions/payrollContributions/${companyId}`, { filter });
  return publicApi.get(path);
}

function getProcessedPayrollDetails(params: { companyId: string; payrollId: string; filter?: any }): Promise<any> {
  const { payrollId, companyId } = params;
  const path: string = getApiPath(`/api/contributions/payrollContributions/${companyId}/${payrollId}`);
  return publicApi.get(path);
}

function deletePendingProcessPayrolls(payrollId: string): Promise<any> {
  const path: string = getApiPath(`/api/contributions/pendingPayrollContributions/${payrollId}`);
  return publicApi.delete(path);
}

function createCashJournalFile({
  id,
  payrollContribution,
}: {
  id: string;
  payrollContribution: EmployerContributionResponse;
}): Promise<any> {
  const path: string = getApiPath(API.CREATE_CASH_JOURNAL_FILE, { id });
  return publicApi.post(path, payrollContribution);
}

function updateCompany(companyDetail: Partial<CompanyDto>, filter?: any): Promise<any> {
  const path: string = getApiPath(API.COMPANY, { filter });
  return publicApi.patch(path, companyDetail);
}

function retrieveEmployeeDetails(id: string, searchText: string = ''): Promise<any> {
  const path: string = getApiPath(API.RETRIEVE_EMPLOYEE_DETAIL, {
    id,
    search: searchText,
  });
  return publicApi.get(path);
}

function resetNewPassword(body: IResetPassword): Promise<any> {
  const path: string = getApiPath(API.PEOPLE_CREDENTIALS);
  return publicApi.post(path, body);
}

function getTransactions(params?: any): Promise<any> {
  const path: string = getApiPath(API.TRANSACTIONS, {
    filter: params,
  });
  return publicApi.get(path);
}

function getAccountTransactionType(): Promise<any> {
  const path: string = getApiPath(API.ACCOUNT_TRANSACTION_TYPE);
  return publicApi.get(path);
}

export function changePassword(body: ChangePasswordPayload): Promise<any> {
  const path: string = getApiPath(API.PEOPLE_CREDENTIALS);
  return publicApi.post(path, body);
}

function forgotPassword(body: any, config: AxiosRequestConfig<any> = {}): Promise<any> {
  const path: string = getApiPath(API.PEOPLE_CREDENTIALS);
  return publicApi.post(path, body, config);
}

function recoveryEmail(body: any, config: AxiosRequestConfig<any> = {}): Promise<any> {
  const path: string = getApiPath('/api/people/recovery');
  return publicApi.post(path, body, config);
}

function resetPassword(body: any, config: AxiosRequestConfig<any> = {}): Promise<any> {
  const path: string = getApiPath(API.PEOPLE_CREDENTIALS);
  return publicApi.post(path, body, config);
}

function createCredentials(body: any, config: AxiosRequestConfig<any> = {}): Promise<any> {
  const path: string = getApiPath(API.PEOPLE_CREDENTIALS);
  return publicApi.post(path, body, config);
}

export function getPlanFormUrl(): Promise<any> {
  const path: string = getApiPath(API.FILE, {
    params: '_sample',
    filter: {
      fileType: 'MANAGE_COMPANY',
    },
  });
  return publicApi.get(path);
}

function getAnnualLimits(): Promise<any> {
  const path: string = getApiPath(API.ANNUAL_LIMITS);
  return publicApi.get(path);
}

function getActiveAnswers(profileId: string): Promise<any> {
  const path: string = getApiPath(API.GET_ACTIVE_ANSWERS);
  const replacePath = path.replace(':profileId', profileId);
  return publicApi.get(replacePath);
}

function updateRiskQuestion(payload: RiskQuestionPayload): Promise<any> {
  const path: string = getApiPath(API.UPDATE_RISK_QUESTION);
  return publicApi.patch(path, payload);
}

function createAnswerData(profileId: string): Promise<any> {
  const path: string = getApiPath(API.CREATE_ANSWERS_DATA);
  const replacePath = path.replace(':profileId', profileId);
  return publicApi.put(replacePath, null);
}

function getQuote(payload: string[]): Promise<any> {
  const path: string = getApiPath(API.GET_QUOTE);
  return publicApi.post(path, payload);
}

function getStatusReport(data: StatusReportPayload): Promise<any> {
  const path: string = getApiPath(API.GET_EMPLOYEE_STATUS_REPORT, {
    companyId: data.companyId,
    filter: data.filter,
  });
  return publicApi.get(path);
}

function getStatusCount(companyId: string): Promise<any> {
  const path: string = getApiPath(API.GET_EMPLOYEE_STATUS_COUNT, {
    companyId,
  });
  return publicApi.get(path);
}

function getHoldings(companyId: string): Promise<any> {
  const path: string = getApiPath(API.GET_COMPANY_HOLDINGS, {
    companyId,
  });
  return publicApi.get(path);
}

function getCashBalance(companyId: string): Promise<any> {
  const path: string = getApiPath(API.GET_CASH_BALANCE, {
    companyId,
  });
  return publicApi.get(path);
}

export function getAccounts(): Promise<{ data: AccountDetailsDto[] }> {
  const path: string = getApiPath(API.ACCOUNTS);
  return publicApi.get(path);
}

function getEmployeeStatements({ id, year }: { id: string; year: number }): Promise<any> {
  const path: string = getApiPath(API.EMPLOYEE_STATEMENTS, { id, year });
  return publicApi.get(path);
}

function getEmployerStatements(body: EmployerStatementDto): Promise<any> {
  const path: string = getApiPath(API.EMPLOYER_STATEMENTS);
  return publicApi.post(path, body);
}

export function updateCompanyStatement(
  updatePayroll: FormStatement,
  callback?: any
): Promise<{
  data: ContributionDto[];
}> {
  const path: string = getApiPath(API.COMPANIES_STATEMENT);
  return publicApi.post(path, updatePayroll);
}

export function getCompanyAdminByCompanyId(
  getCompanyAdmin: GetCompanyAdmin
): Promise<{ data: CompanyAdminResponse[] }> {
  const path: string = getApiPath(API.GET_COMPANY_ADMIN_BY_COMPANY_ID, getCompanyAdmin);
  return publicApi.get(path);
}

export function getAllExistingPersons({ filter }: { filter: any }): Promise<{
  data: GetAllExistingPerson;
}> {
  const path: string = getApiPath(API.GET_ALL_EXISTING_PERSONS, { filter });
  return publicApi.get(path);
}

function getFormFiling(data: GetFormFilingPayload) {
  const path: string = getApiPath(API.FORM_FILING, {
    companyId: data.companyId,
    page: data.page,
    filter: data.filter,
  });
  return publicApi.get(path);
}

export function createErisaBond(data: CreateErisaBondPayload) {
  const path: string = getApiPath(API.CREATE_ERISA_BOND, {
    companyId: data.companyId,
  });
  return publicApi.put(path, data);
}

export function createNewCompanyAdmin({
  newCompanyAdmin,
  id,
}: {
  newCompanyAdmin: NewCompanyAdmin;
  id: string | null;
}): Promise<{ data: boolean }> {
  const path: string = getApiPath(API.NEW_COMPANY_ADMIN, { id });
  return publicApi.post(path, newCompanyAdmin);
}

export function getCompanyAdminByName({ name }: { name: string }) {
  const path: string = getApiPath(API.GET_COMPANY_ADMIN_BY_NAME, { name });
  return publicApi.get(path);
}

export function addCompanyAdminToCompany({
  companyId,
  companyAdminId,
}: {
  companyId: string | null;
  companyAdminId: string | null;
}) {
  const path: string = getApiPath(API.ADD_COMPANY_ADMIN, {
    companyId,
    companyAdminId,
  });
  return publicApi.post(path, null);
}

export function manageCompanyAdmin({
  companyId,
  manageCompanyAdmin,
  action,
}: {
  companyId: string | null;
  manageCompanyAdmin: EditCompanyAdmin | CompanyAdminResponse | null;
  action: Actions;
}): Promise<{ data: boolean }> {
  const path: string = getApiPath(API.MANAGE_COMPANY_ADMIN, {
    companyId,
    manageCompanyAdmin,
    action: action.toLowerCase(),
  });

  return publicApi.post(path, manageCompanyAdmin);
}

export function editCompanyDetails({
  editCompanyDetailsBody,
  filter,
}: {
  editCompanyDetailsBody: EditCompanyDto;
  filter?: any;
}): Promise<{ data: CompanyDto }> {
  const path: string = getApiPath(API.COMPANY, { filter });
  return publicApi.patch(path, editCompanyDetailsBody);
}

export function getPlanFile({
  planType,
  id,
  filter,
}: {
  planType: PlanTypes;
  id?: string;
  filter?: {
    planStatus: string;
  };
}): Promise<{ data: GetPlanFileResponse }> {
  const path: string = getApiPath(API.GET_PLAN_FILES, {
    planType,
    id,
    filter,
  });
  return publicApi.get(path);
}

export function getTrustReports({ companyId }: { companyId: string }): Promise<{ data: GetTrustReportResponse[] }> {
  const path: string = getApiPath(API.GET_TRUST_REPORTS, { companyId });
  return publicApi.get(path);
}

function putTrustReports(payload: { year: string; companyId: string }): Promise<any> {
  const path: string = getApiPath(API.TRUST_REPORTS);
  return publicApi.put(path, payload);
}

function postProfile(payload: { data: EditProfileDto; filter: any }): Promise<any> {
  const path: string = getApiPath(API.PROFILE, {
    filter: payload.filter,
  });
  return publicApi.post(path, payload.data);
}

export function checkFieldExisted(fieldName: string, value: string): Promise<{ data: { existing: boolean } }> {
  const path: string = getApiPath(API.SEARCH_FIELD, { fieldName, value });
  return publicApi.get(path);
}

export function genericFileUpload(payload: UploadFileDto[]): Promise<{ data: UploadGenericFileResponse }> {
  const path: string = getApiPath(API.GENERIC_FILE_UPLOAD);
  return publicApi.post(path, payload);
}

function updateContributionsFromFile(fileId: string | undefined): Promise<any> {
  const path: string = getApiPath(API.UPDATE_CONTRIBUTIONS_FROM_FILE, {
    fileId,
  });
  return publicApi.patch(path);
}

function createPlanTransfer(companyId: string, payload: PlanTransferDto): Promise<any> {
  const path: string = getApiPath(API.PLAN_TRANSFER, {
    companyId,
  });
  return publicApi.put(path, payload);
}

function enableTwoFA(payload: EnabledTwoFARequest): Promise<any> {
  return publicApi.post('/api/2fa', payload);
}

function getTwoFA(): Promise<any> {
  return publicApi.get('/api/2fa');
}

function verifyTwoFA(payload: VerifiedTwoFARequest): Promise<any> {
  return publicApi.patch('/api/2fa/verify', payload);
}

function verifySMSCode(payload: VerifiedSMSCodeRequest): Promise<any> {
  return publicApi.patch('/api/verificationCode/verify', payload);
}

function resendSMSCode(): Promise<any> {
  return publicApi.patch('/api/verificationCode/resendSMS');
}

function resendCodeSMS2FA(): Promise<any> {
  return publicApi.patch('/api/2fa/resendSMS');
}

function deactivateTwoFA(): Promise<any> {
  return publicApi.patch('/api/2fa/deactivate');
}

export function completeManageEmployeeFileSubmission(fileId: string): Promise<any> {
  const path = getApiPath(API.COMPLETE_MANAGE_EMPLOYEE_FILE_SUBMISSION, {
    fileId,
  });
  return publicApi.patch(path, null);
}

const getPEPManagePlanDetail = async (companyId: string) => {
  const path = getApiPath(API.GET_PEP_MANAGE_PLAN_DETAIL, {
    companyId,
  });
  return publicApi.get(path);
};

const createPEPManagePlan = async (data: any) => {
  const path = getApiPath(API.CREATE_PEP_MANAGE_PLAN);
  return publicApi.put(path, data);
};

const updatePEPManagePlan = async (data: any) => {
  const path = getApiPath(API.UPDATE_PEP_MANAGE_PLAN);
  return publicApi.patch(path, data);
};

const createDraftManagePlan = async (data: any) => {
  const path = getApiPath(API.CREATE_AND_UPDATE_DRAFT_MANAGE_PLAN);
  return publicApi.put(path, data);
};

const updateDraftManagePlan = async (data: any) => {
  const path = getApiPath(API.CREATE_AND_UPDATE_DRAFT_MANAGE_PLAN);
  return publicApi.patch(path, data);
};

const updateListPEPManagePlanActive = async (data: any) => {
  const path = getApiPath(API.UPDATE_PEP_MANAGE_PLAN_ACTIVE, {
    companyId: data?.companyId,
  });
  return publicApi.patch(path, { plans: data?.planIds });
};

const getListEmployeeByFilter = async (data: IFilterListEmployee) => {
  const path = getApiPath(API.GET_LIST_EMPLOYEE, {
    filter: data,
  });
  return publicApi.get(path);
};

export const getListParticipantByCompany = async (data: ISearchParticipantQuery) => {
  const path = getApiPath(API.SEARCH_PARTICIPANT_BY_COMPANY(data.companyType), {
    filter: data,
  });
  return publicApi.get(path);
};

export function checkSSNExisted(ssnEncodedValue: string): Promise<{ data: { existing: boolean } }> {
  const { path } = API.POST_CHECK_SSN;
  return publicApi.post(path, {
    ssn: ssnEncodedValue,
  });
}

export const changeAuthorizedSignatory = async (payload: IChangeAuthorizedSignatoryRequest) => {
  const { path } = API.POST_CHANGE_AUTHORIZED_SIGNATORY;

  return publicApi.post(path, payload);
};

export const createPlanAdmins = async (body: ICreatePlanAdminsRequest) => {
  const { path } = !body.companyId
    ? API.PUT_CREATE_PLAN_ADMIN(body.companyType)
    : API.PUT_CREATE_PLAN_ADMIN_BASIC(body.companyId || '');
  return await publicApi.put(path, body.payload);
};

const getFinchConnections = async ({ filter = {} }: { filter?: any }) => {
  const path = getApiPath('/api/companies/finch', {
    filter: {
      filter: filter.filter,
      ...filter,
    },
  });
  return await publicApi.get(path);
};

const getFinchProviders = async ({ filter = {} }: { filter?: any }) => {
  const path = getApiPath('/api/companies/providers', { filter });
  return await publicApi.get(path);
};

const getPayrollProviders = async ({ filter = {} }: { filter?: any }) => {
  const path = getApiPath('/api/companies/providers/connected', { filter });
  return await publicApi.get(path);
};

const getCompanyFinchDetails = async ({ companyId, filter = {} }: { companyId: string; filter?: any }) => {
  const path = getApiPath('/api/companies/:companyId/finchInfo', {
    companyId,
    filter,
  });
  return await publicApi.get(path);
};

export const getPlanAdmins = async (companyId: string) => {
  const { path } = API.GET_PLAN_ADMIN(companyId);
  return await publicApi.get(path);
};

export const updatePlanAdmins = async (body: ICreateUpdateBasicPlanAdmin) => {
  const { companyId, ...restParams } = body;
  const { path } = API.GET_PLAN_ADMIN(body.companyId ?? '');
  return await publicApi.patch(path, restParams);
};

export const getCompanyFinchProviders = async (filter: IFinchProvidersFilter) => {
  const { companyId, ...restFilter } = filter;
  const path = getApiPath(API.GET_COMPANY_FINCH_PROVIDERS, {
    companyId,
    filter: restFilter,
  });
  return await publicApi.get(path);
};

const downloadFinchPayment = async ({ companyId, paymentId }: { companyId: string; paymentId: string }) => {
  const path = getApiPath('/api/companies/:companyId/finchPayStatement/:paymentId/download', { companyId, paymentId });
  return await publicApi.get(path, { responseType: 'blob' });
};

const manualSyncFinch = async ({ companyId }: { companyId: string }) => {
  const path = getApiPath('/api/companies/:companyId/syncEmployees', {
    companyId,
  });
  return await publicApi.post(path);
};

const addParticipant = async ({ companyId, payload }: { companyId: string; payload: EmployeeProfileDto }) => {
  const path = getApiPath('/api/people/:companyId/addParticipant', {
    companyId,
  });
  return await publicApi.put(path, payload);
};

function recoveryAccount(body: IRecoveryAccountPayload): Promise<any> {
  const path: string = getApiPath(API.PEOPLE_CREDENTIALS);
  return publicApi.post(path, body);
}

const downloadFinch = ({ filter }: { filter: any }) => {
  const path: string = getApiPath(API.DOWNLOAD_FINCH, {
    filter,
  });
  return publicApi.get(path);
};

const getFeedbacks = () => {
  const path: string = getApiPath(API.GET_AND_UPDATE_FEEDBACK);
  return publicApi.get(path);
};

const updateFeedbacks = (feedbacks: IFeedbackItem[]) => {
  const path: string = getApiPath(API.GET_AND_UPDATE_FEEDBACK);
  return publicApi.post(path, { feedbacks });
};

const getListPortfolioHistory = (filter: Omit<IFilterListEmployee, 'profileStatus'>) => {
  const path: string = getApiPath(API.GET_PORTFOLIO_HISTORY, {
    filter: {
      page: filter?.page || 0,
      pageSize: filter?.pageSize || 0,
    },
  });
  return publicApi.get(path);
};

const downloadListPortfolioHistory = ({ filter }: { filter: any }) => {
  const path: string = getApiPath(API.DOWNLOAD_PORTFOLIO_HISTORY, {
    filter,
  });
  return publicApi.get(path);
};

const getProfileDashBoardInfo = () => {
  const path: string = getApiPath(API.GET_PROFILE_DASHBOARD);
  return publicApi.get(path);
};

const postPeopleAccountRecovery = (payload?: any, config?: AxiosRequestConfig<any>) => {
  const path: string = getApiPath(API.POST_PEOPLE_ACCOUNT_RECOVERY);
  return publicApi.post(path, payload, config);
};

const patchVerificationCode = (payload?: any) => {
  const path: string = getApiPath(API.PATCH_VERIFY_CODE);
  return publicApi.patch(path, payload);
};

const patchVerification2FACode = (payload?: any) => {
  const path: string = getApiPath(API.PATCH_VERIFY_2FA_CODE);
  return publicApi.patch(path, payload);
};

const patchResendCode = (payload?: any) => {
  const path: string = getApiPath(API.PATCH_RESEND_VERIFY_CODE);
  return publicApi.patch(path, payload);
};

const postRecoveryUpdateAccount = (payload?: any) => {
  const path: string = getApiPath(API.FORGOT_USERNAME);
  return publicApi.post(path, payload);
};

const getCompanyPartners = (filter: Partial<Filter>) => {
  const path: string = getApiPath(API.GET_COMPANY_PARTNERS, {
    filter,
  });
  return publicApi.get(path);
};

const getPartnersShortInfo = () => {
  const path: string = getApiPath(API.GET_PARTNERS_SORT_INFO);
  return publicApi.get(path);
};

const getListClient = ({ page = 0, pageSize = 10, ...filter }: Partial<Filter>) => {
  const path: string = getApiPath(API.GET_LIST_CLIENTS, {
    filter: {
      page,
      pageSize,
      ...(filter || {}),
    },
  });
  return publicApi.get(path);
};

const getListLateContribution = ({ page = 0, pageSize = 10, ...filter }: Partial<ILateContributionFilter>) => {
  const path: string = getApiPath(API.GET_LIST_LATE_CONTRIBUTION, {
    filter: {
      page,
      pageSize,
      ...(filter || {}),
    },
  });
  return publicApi.get(path);
};

const getListLateSubmission = ({ page = 0, pageSize = 10, ...filter }: Partial<ILateSubmissionFilter>) => {
  const path: string = getApiPath(API.GET_LIST_LATE_SUBMISSION, {
    filter: {
      page,
      pageSize,
      ...(filter || {}),
    },
  });
  return publicApi.get(path);
};

const updateLateContribution = (
  payload: Partial<Pick<LateContributionItem, 'id' | 'lateOnContributionNote' | 'lateOnContributionStatus'>>
) => {
  const path: string = getApiPath(API.UPDATE_LATE_CONTRIBUTION);
  return publicApi.patch(path, payload);
};

const downloadLateContribution = ({ filter }: { filter: any }) => {
  const path: string = getApiPath(API.DOWNLOAD_LATE_CONTRIBUTION, { filter });
  return publicApi.get(path);
};

const downloadLateSubmission = ({ filter }: { filter: any }) => {
  const path: string = getApiPath(API.DOWNLOAD_LATE_SUBMISSION, {
    filter,
  });
  return publicApi.get(path);
};

const ApiService = {
  login,
  apiLogout,
  forgotUsername,
  getRetrieveCompanyNamesAndIds,
  getEmployeeByCompanyId,
  getPendingEmployeeByCompanyId,
  getAccountDetails,
  getEmployeeDetails,
  updateAccountDetail,
  validateAccountNumber,
  getOtherMatchingFormulaValues,
  getContributionsLimit,
  createPlan,
  updatePlan,
  getPlanDetail,
  updatePlanStatus,
  uploadFile,
  updateUploadStatus,
  updateSession,
  getSession,
  getAllReports,
  getActivePlanParticipant,
  getYtdContributions,
  getSummaryReport,
  getAccountBalance,
  getAllContributions,
  verifyCompany,
  startStopEligibilityTracking,
  getLastCashJournalFile,
  downloadLashCashJournalFile,
  getPayrollContribution,
  createCashJournalFile,
  updateCompany,
  getPeople,
  getPeopleByEmail,
  getReCaptchaSiteKey,
  updatePeople,
  getLastLogin,
  getProfile,
  updateProfile,
  getCompanyDetails,
  retrieveEmployeeDetails,
  resetNewPassword,
  createCredentials,
  getTransactions,
  getAccountTransactionType,
  changePassword,
  getPlanFormUrl,
  getAnnualLimits,
  getActiveAnswers,
  updateRiskQuestion,
  createAnswerData,
  getQuote,
  getStatusReport,
  getStatusCount,
  getHoldings,
  getCashBalance,
  getEmployeeStatements,
  getEmployerStatements,
  updateCompanyStatement,
  getCompanyAdminByCompanyId,
  getAllExistingPersons,
  getFormFiling,
  createErisaBond,
  createNewCompanyAdmin,
  getCompanyAdminByName,
  addCompanyAdminToCompany,
  manageCompanyAdmin,
  getCashJournalFile,
  editCompanyDetails,
  forgotPassword,
  resetPassword,
  postProfile,
  putTrustReports,
  genericFileUpload,
  removePayrollContribution,
  updatePayrollContribution,
  getProcessPayrolls,
  deletePendingProcessPayrolls,
  updateContributionsFromFile,
  checkFieldExisted,
  syncEmployeeWithFinch,
  createPlanTransfer,
  getTwoFA,
  enableTwoFA,
  verifyTwoFA,
  resendCodeSMS2FA,
  deactivateTwoFA,
  completeManageEmployeeFileSubmission,
  updatePendingEmployeeDetails,
  createPEPManagePlan,
  getPEPManagePlanDetail,
  updatePEPManagePlan,
  updateListPEPManagePlanActive,
  getProcessedPayrollDetails,
  createProfile,
  createPeople,
  recoveryEmail,
  verifySMSCode,
  getListEmployeeByFilter,
  resendSMSCode,
  getListParticipantByCompany,
  checkSSNExisted,
  changeAuthorizedSignatory,
  getFinchConnections,
  getCompanyFinchProviders,
  getCompanyFinchDetails,
  getPlanAdmins,
  updatePlanAdmins,
  getFinchProviders,
  getPayrollProviders,
  downloadFinchPayment,
  manualSyncFinch,
  addParticipant,
  recoveryAccount,
  downloadFinch,
  createDraftManagePlan,
  updateDraftManagePlan,
  getAllCompanyPendingNotificationsCount,
  getFeedbacks,
  updateFeedbacks,
  getListPortfolioHistory,
  downloadListPortfolioHistory,
  getProfileDashBoardInfo,
  postPeopleAccountRecovery,
  patchVerificationCode,
  patchVerification2FACode,
  postRecoveryUpdateAccount,
  getCompanyPartners,
  getPartnersShortInfo,
  getListClient,
  patchResendCode,
  getListLateContribution,
  getListLateSubmission,
  updateLateContribution,
  downloadLateContribution,
  downloadLateSubmission,
};

export default ApiService;
