import React from "react";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

type Props = {
  steps: any[];
  step: number;
};

const Stepper: React.FC<Props> = ({ step, steps }): any => {
  const stepArr = _.uniqBy(steps, "step");
  return (
    stepArr.length &&
    stepArr.map((_, idx) => (
      <div
        key={uuidv4()}
        className={`${idx <= step ? "bg-secondary" : "bg-[#d9dcde]"
          } h-2 rounded-lg`}
        style={{
          width: `calc(calc(100%/${stepArr.length}) - 4px)`,
        }}
      />
    ))
  );
};

export default Stepper;
