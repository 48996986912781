import {
  Card,
  CardHeader,
  Stack,
  Typography,
  Button,
  CardContent,
  Box,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { FC, useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "states/hooks";
import { selectEmployeeProfile } from "states/manageEmployee/manageEmployeeSlice";
import CircleIcon from "@mui/icons-material/Circle";
import { Tooltip as MuiTooltip } from "common/components";
const labels = [
  {
    key: "cash",
    label: "100% Cash",
    tooltip: `The portion of your 401(k) investments held in cash or cash equivalents.`,
    color: "#3B471C",
  },
  {
    key: "sp500",
    label: "S&P 500",
    tooltip: `An index of 500 of the largest publicly traded companies in the United States.`,
    color: "#3B471C",
  },
  {
    key: "us",
    label: "US Stocks",
    tooltip: `Shares of ownership in companies that are based in the United States.`,
    color: "#457000",
  },
  {
    key: "is",
    label: "International Stocks",
    tooltip: `Shares of ownership in companies that are based outside the United States.`,
    color: "#E0DEC9",
  },
  {
    key: "ub",
    label: "US Bonds",
    tooltip: `Debt securities issued by U.S. entities, such as the government or corporations.`,
    color: "#9E9E91",
  },
  {
    key: "ib",
    label: "International Bonds",
    tooltip: `Debt securities issued by non-U.S. entities, offering potential interest income and diversification across global markets.`,
    color: "#3B471C",
  },
  {
    key: "tips",
    label: "TIPS",
    tooltip: `Treasury Inflation-Protected Securities, which are U.S. government bonds designed to protect against inflation by adjusting the principal value based on changes in the Consumer Price Index.`,
    color: "#3B471C",
  },
];
const constantsData = [
  {
    portfolioId: "P0",
    portfolio: "CASH",
    label: "Cash",
    data: [
      {
        cash: 100,
      },
    ],
  },
  {
    portfolioId: "P9",
    portfolio: "SP500",
    label: "S&P 500",
    data: [
      {
        sp500: 100,
      },
    ],
  },
  {
    portfolioId: "P1",
    portfolio: "LEASTRISK",
    label: "Least Risk",
    data: [
      {
        us: 33,
        is: 17,
        ub: 30,
        ib: 12,
        tips: 8,
      },
    ],
  },
  {
    portfolioId: "P3",
    portfolio: "LOWRISK",
    label: "Low Risk",
    data: [
      {
        us: 45.6,
        is: 21.8,
        ub: 23.8,
        ib: 8.8,
      },
    ],
  },
  {
    portfolioId: "P5",
    portfolio: "MODRISK",
    label: "Moderate Risk",
    data: [
      {
        us: 55.3,
        is: 27,
        ub: 12.6,
        ib: 5.1,
      },
    ],
  },
  {
    portfolioId: "P6",
    portfolio: "HIGHRISK",
    label: "Higher Risk",
    data: [
      {
        us: 58.4,
        is: 31.4,
        ub: 7.4,
        ib: 2.8,
      },
    ],
  },
  {
    portfolioId: "P8",
    portfolio: "MOSTRISK",
    label: "Most Risk",
    data: [
      {
        us: 60,
        is: 40,
      },
    ],
  },
];
const renderTitle = (plan: string, val: string) => {
  const label = labels.find((lb) => lb.key === val);
  if (plan === "LEASTRISK") {
    if (val === "us") {
      return "Stocks";
    }
    if (val === "is") {
      return "INT Stocks";
    }
    if (val === "ub") {
      return "Bonds";
    }
    if (val === "ib") {
      return "INT Bonds";
    }
    if (val === "tips") {
      return "TIPS";
    }
  }
  if (plan === "LOWRISK" || plan === "MODRISK" || plan === "HIGHRISK") {
    if (val === "us") {
      return "US Stocks";
    }
    if (val === "is") {
      return "INT Stocks";
    }
    if (val === "ub") {
      return "US Bonds";
    }
    if (val === "ib") {
      return "INT Bonds";
    }
  }
  return label?.label || "";
};
const renderTooltipTitle = (val: string) => {
  const label = labels.find((lb) => lb.key === val);
  return label?.tooltip || "";
};

const RenderLegend: FC<{ plan: string; data: any[] }> = ({ plan, data }) => {
  const keys = Object.keys(data[0]);
  return (
    <Stack
      direction={"row"}
      justifyContent={keys.length <= 2 ? "start" : "space-between"}
      spacing={keys.length <= 2 ? 1 : -0.5}
    >
      {keys.map((key: string) => {
        const label = labels.find((lb) => lb.key === key);
        let color = label?.color || "#000";
        if (plan === "MOSTRISK") {
          if (key === "is") {
            color = "#3B471C";
          }
        }
        if (plan === "LEASTRISK") {
          if (key === "is") {
            color = "#A4C464";
          }
        }
        return (
          <p key={key}>
            {keys.length > 1 && (
              <CircleIcon
                style={{
                  color: color,
                  fontSize: "8px",
                  marginRight: "2px",
                }}
              />
            )}
            <span
              style={{
                color: "#2E3721",
                fontWeight: 500,
                fontSize: "10px",
                lineHeight: "15px",
                marginRight: "2px",
              }}
            >
              {renderTitle(plan, key)}
            </span>
            <MuiTooltip
              title={renderTooltipTitle(key)}
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -12],
                      },
                    },
                  ],
                },
              }}
            >
              <InfoOutlinedIcon className="w-3" sx={{ color: "#9E9E91" }} />
            </MuiTooltip>
          </p>
        );
      })}
    </Stack>
  );
};
export default function PortfolioSelection() {
  const profile = useAppSelector(selectEmployeeProfile);
  const [portfolio, setPortfolio] = useState<any>(null);
  useEffect(() => {
    if (profile) {
      const portfolioId = profile?.portfolioId;
      if (portfolioId) {
        const item = constantsData.find((f) => f.portfolioId === portfolioId);
        if (item) {
          setPortfolio(item);
        }
      }
    }
  }, [profile]);
  const key = portfolio?.portfolio;
  const data = portfolio?.data;
  const label = portfolio?.label;
  const [currentHover, setCurrentHover] = useState("");
  const navigate = useNavigate();
  const CustomTooltip = ({ active, payload }: any) => {
    if (
      active &&
      payload &&
      payload.length &&
      payload.find((f: any) => f.dataKey === currentHover)
    ) {
      return (
        <Typography
          sx={{
            fontSize: "10px",
            fontWeight: 500,
          }}
        >
          {`${payload.find((f: any) => f.dataKey === currentHover)?.value}%`}
        </Typography>
      );
    }

    return null;
  };
  return (
    <Card
      sx={{
        maxWidth: "100%",
        height: "150px",
        border: "1px solid #E0DEC9",
        borderRadius: "8px",
      }}
      variant="outlined"
    >
      <CardHeader
        sx={{
          paddingBottom: 1,
          marginLeft: 0.5,
        }}
        title={
          <Stack direction="row" justifyContent="space-between">
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: 600,
                color: "#9E9E91",
                lineHeight: "18px",
              }}
            >
              Your Portfolio Selection
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                borderRadius: "8px",
                fontWeight: 700,
                fontSize: "10px",
                maxWidth: "52px",
                maxHeight: "23px",
              }}
              onClick={() => navigate("/dashboard/employee/allocations")}
            >
              Update
            </Button>
          </Stack>
        }
      />
      {portfolio && (
        <CardContent
          sx={{
            paddingTop: 0,
          }}
        >
          <Box ml={0.5}>
            <Typography
              sx={{
                color: "#181C11",
                fontWeight: 600,
                fontSize: "20px",
                lineHeight: "20px",
              }}
            >
              {label}
            </Typography>
          </Box>
          <Box mt={1}>
            <ResponsiveContainer height={30} width={"99.5%"}>
              <BarChart layout="vertical" data={data} stackOffset="expand">
                <XAxis hide type="number" />
                <YAxis type="category" hide />
                <Tooltip
                  cursor={{ fill: "transparent" }}
                  wrapperStyle={{
                    zIndex: 1,
                    marginTop: 20,
                    border: "none",
                    outline: "none",
                  }}
                  content={<CustomTooltip />}
                />
                {key === "CASH" && (
                  <Bar
                    dataKey="cash"
                    fill={"#3B471C"}
                    stackId="solo"
                    barSize={16}
                    onMouseOver={() => setCurrentHover("cash")}
                    radius={[25, 25, 25, 25]}
                  />
                )}
                {key === "SP500" && (
                  <Bar
                    dataKey="sp500"
                    fill={"#3B471C"}
                    stackId="solo"
                    barSize={16}
                    onMouseOver={() => setCurrentHover("sp500")}
                    radius={[25, 25, 25, 25]}
                  />
                )}
                {key === "LEASTRISK" && (
                  <>
                    <Bar
                      dataKey="us"
                      fill={"#457000"}
                      stackId="solo"
                      barSize={16}
                      onMouseOver={() => setCurrentHover("us")}
                      radius={[25, 0, 0, 25]}
                    />
                    <Bar
                      dataKey="is"
                      fill={"#A4C464"}
                      stackId="solo"
                      barSize={16}
                      onMouseOver={() => setCurrentHover("is")}
                    />
                    <Bar
                      dataKey="ub"
                      fill={"#E0DEC9"}
                      stackId="solo"
                      barSize={16}
                      onMouseOver={() => setCurrentHover("ub")}
                    />
                    <Bar
                      dataKey="ib"
                      fill={"#9E9E91"}
                      stackId="solo"
                      barSize={16}
                      onMouseOver={() => setCurrentHover("ib")}
                    />
                    <Bar
                      dataKey="tips"
                      fill={"#3B471C"}
                      stackId="solo"
                      barSize={16}
                      onMouseOver={() => setCurrentHover("tips")}
                      radius={[0, 25, 25, 0]}
                    />
                  </>
                )}
                {key === "LOWRISK" && (
                  <>
                    <Bar
                      dataKey="us"
                      fill={"#457000"}
                      stackId="solo"
                      barSize={16}
                      onMouseOver={() => setCurrentHover("us")}
                      radius={[25, 0, 0, 25]}
                    />
                    <Bar
                      dataKey="is"
                      fill={"#E0DEC9"}
                      stackId="solo"
                      barSize={16}
                      onMouseOver={() => setCurrentHover("is")}
                    />
                    <Bar
                      dataKey="ub"
                      fill={"#9E9E91"}
                      stackId="solo"
                      barSize={16}
                      onMouseOver={() => setCurrentHover("ub")}
                    />
                    <Bar
                      dataKey="ib"
                      fill={"#3B471C"}
                      stackId="solo"
                      barSize={16}
                      onMouseOver={() => setCurrentHover("ib")}
                      radius={[0, 25, 25, 0]}
                    />
                  </>
                )}
                {key === "MODRISK" && (
                  <>
                    <Bar
                      dataKey="us"
                      fill={"#457000"}
                      stackId="solo"
                      barSize={16}
                      onMouseOver={() => setCurrentHover("us")}
                      radius={[25, 0, 0, 25]}
                    />
                    <Bar
                      dataKey="is"
                      fill={"#E0DEC9"}
                      stackId="solo"
                      barSize={16}
                      onMouseOver={() => setCurrentHover("is")}
                    />
                    <Bar
                      dataKey="ub"
                      fill={"#9E9E91"}
                      stackId="solo"
                      barSize={16}
                      onMouseOver={() => setCurrentHover("ub")}
                    />
                    <Bar
                      dataKey="ib"
                      fill={"#3B471C"}
                      stackId="solo"
                      barSize={16}
                      onMouseOver={() => setCurrentHover("ib")}
                      radius={[0, 25, 25, 0]}
                    />
                  </>
                )}
                {key === "HIGHRISK" && (
                  <>
                    <Bar
                      dataKey="us"
                      fill={"#457000"}
                      stackId="solo"
                      barSize={16}
                      onMouseOver={() => setCurrentHover("us")}
                      radius={[25, 0, 0, 25]}
                    />
                    <Bar
                      dataKey="is"
                      fill={"#69AB00"}
                      stackId="solo"
                      barSize={16}
                      onMouseOver={() => setCurrentHover("is")}
                    />
                    <Bar
                      dataKey="ub"
                      fill={"#9E9E91"}
                      stackId="solo"
                      barSize={16}
                      onMouseOver={() => setCurrentHover("ub")}
                    />
                    <Bar
                      dataKey="ib"
                      fill={"#3B471C"}
                      stackId="solo"
                      barSize={16}
                      onMouseOver={() => setCurrentHover("ib")}
                      radius={[0, 25, 25, 0]}
                    />
                  </>
                )}
                {key === "MOSTRISK" && (
                  <>
                    <Bar
                      dataKey="us"
                      fill={"#457000"}
                      stackId="solo"
                      barSize={16}
                      onMouseOver={() => setCurrentHover("us")}
                      radius={[25, 0, 0, 25]}
                    />
                    <Bar
                      dataKey="is"
                      fill={"#3B471C"}
                      stackId="solo"
                      barSize={16}
                      onMouseOver={() => setCurrentHover("is")}
                      radius={[0, 25, 25, 0]}
                    />
                  </>
                )}
              </BarChart>
            </ResponsiveContainer>
          </Box>
          <Box mx={{ zero: 0, xs: 1 }}>
            <RenderLegend data={data} plan={key} />
          </Box>
        </CardContent>
      )}
    </Card>
  );
}
