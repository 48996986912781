import { Box, Button } from '@mui/material';
import { useEffect, useState, createContext, useContext } from 'react';

type TProps = {
  defaultValue: string;
  onChange: (val: string) => void | Promise<void>;
  disableBorder?: boolean;
  children: React.ReactNode;
};

const baseStyleProps = {
  borderRadius: '25px',
  fontSize: '10px',
  maxHeight: '19px',
  boxShadow: 'none',
  whiteSpace: 'nowrap',
  marginBottom: '2px',
  padding: '2px 8px',
};

const selectedStyleProps = {
  backgroundColor: '#3B471C',
  fontWeight: 600,
  ...baseStyleProps,
};

const deSelectedStyleProps = {
  backgroundColor: 'transparent',
  fontWeight: 500,
  color: '#75756F',
  ...baseStyleProps,
};

const ToggleContext = createContext<any>(undefined);

const useToggle = () => {
  return useContext(ToggleContext);
};

const Sub = ({ value, children }: { value: string; children: React.ReactNode }) => {
  const { subType, setSubType } = useToggle();

  const isSelected = value === subType;
  return (
    <Button
      variant={isSelected ? 'contained' : 'text'}
      sx={isSelected ? selectedStyleProps : deSelectedStyleProps}
      onClick={() => setSubType(value)}
    >
      {children}
    </Button>
  );
};

export const Toggle = (props: TProps) => {
  const { defaultValue = '', children } = props;
  const [subType, setSubType] = useState(defaultValue);

  useEffect(() => {
    props.onChange(subType);
  }, [subType]);

  const values = {
    subType,
    setSubType,
  };

  return (
    <ToggleContext.Provider value={values}>
      <Box display="flex" alignItems="center">
        <Box
          sx={{
            minWidth: '117px',
            maxHeight: '27px',
            border: `1px solid ${props.disableBorder ? '#f5f3e8' : '#E0DEC9'}`,
            borderRadius: '25px',
            backgroundColor: '#f5f3e8',
          }}
        >
          <Box mx={'2px'}>{children}</Box>
        </Box>
      </Box>
    </ToggleContext.Provider>
  );
};

Toggle.Sub = Sub;
