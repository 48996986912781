import CompanyAdminLayout from "layout/CompanyAdminLayout";
import { lazy, Suspense } from "react";
import { RouteObject } from "react-router-dom";
import EmployerBankPayroll from "pages/employerBankPayroll";
import EmployerDashboardV2Page from "pages/employerDashboardV2";
const EmployerEditDetailPage = lazy(() => import("pages/employerEditDetail"));
const EmployerNotificationNewPage = lazy(
  () => import("pages/employerNotificationNew")
);
const EmployerSingleNotificationPage = lazy(
  () => import("pages/employerSingleNotification")
);
const EmployerDashboardPage = lazy(() => import("pages/employerDashboard"));
const CompanyDetails = lazy(() => import("pages/employerCompanyDetails"));
const EmployerPlanForm = lazy(() => import("pages/employerPlanForm"));
const EmployerStatements = lazy(() => import("pages/employerStatements"));
// const EmployerFormFilling = lazy(() => import("pages/employerFormFiling"));
const EmployerReport = lazy(() => import("pages/employerReport"));
const EmployerPlanDetails = lazy(() => import("pages/employerPlanDetails"));
const EmployerManageEmployeePage = lazy(
  () => import("pages/employerManageEmployee")
);
const EmployerManagePendingEmployeePage = lazy(
  () => import("pages/employerManagePendingEmployee")
);
const EmployerPendingEmployeeDetailsPage = lazy(
  () => import("pages/employerPendingEmployeeDetails")
);
const EmployerProcessContribution = lazy(
  () => import("pages/employerProcessContribution")
);
const EmployerPayrollPreview = lazy(
  () => import("pages/employerPayrollPreview")
);
const EmployerProcessedPayrollContribution = lazy(
  () => import("pages/employerProcessedPayrollContribution")
);
const EmployerAnnualDisclosures = lazy(
  () => import("pages/employerAnnualDisclosures")
);
const EmployerTustReport = lazy(() => import("pages/employerTrustReport"));
const EmployerProcessedPayrollDetails = lazy(
  () => import("pages/employerProcessedPayrollDetails")
);
const EmployerSettingPage = lazy(() => import("pages/employerSettings"));
const EmployerForfeitureReportPage = lazy(
  () => import("pages/employerForfeitureReport")
);

export const employerRoutes: RouteObject[] = [
  {
    path: "/dashboard", // /dashboard
    element: <CompanyAdminLayout />,
    children: [
      {
        path: "employer", // /dashboard/employer
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <EmployerDashboardV2Page />
              </Suspense>
            ),
          },
          {
            path: "settings",
            element: (
              <Suspense>
                <EmployerSettingPage />
              </Suspense>
            ),
          },
          {
            path: "notification",
            element: (
              <Suspense>
                <EmployerNotificationNewPage />
              </Suspense>
            ),
          },
          {
            path: "notification/:notificationId",
            element: (
              <Suspense>
                <EmployerSingleNotificationPage />
              </Suspense>
            ),
          },
          {
            path: "planforms", // /dashboard/employer/planforms
            element: (
              <Suspense>
                <EmployerPlanForm />
              </Suspense>
            ),
          },
          {
            path: "statements", // /dashboard/employer/statements
            element: (
              <Suspense>
                <EmployerStatements />
              </Suspense>
            ),
          },
          // {
          //   path: "annuallimits", // /dashboard/employer/annuallimits
          //   element: (
          //     <Suspense>
          //       <EmployerAnnualLimits />
          //     </Suspense>
          //   ),
          // },
          {
            path: "reviewplan", // /dashboard/employer/reviewplan
            element: (
              <Suspense>
                <EmployerPlanDetails />
              </Suspense>
            ),
          },
          {
            path: "bankpayroll",
            element: (
              <Suspense>
                <EmployerBankPayroll />
              </Suspense>
            ),
          },
          {
            path: "editdetail",
            element: (
              <Suspense>
                <EmployerEditDetailPage />
              </Suspense>
            ),
          },
          {
            path: "manageemployee",
            element: (
              <Suspense>
                <EmployerManageEmployeePage />
              </Suspense>
            ),
          },
          {
            path: "managependingemployee",
            element: (
              <Suspense>
                <EmployerManagePendingEmployeePage />
              </Suspense>
            ),
          },
          {
            path: "managependingemployee/:empid",
            element: (
              <Suspense>
                <EmployerPendingEmployeeDetailsPage />
              </Suspense>
            ),
          },
          {
            path: "annual-disclosure",
            element: (
              <Suspense>
                <EmployerAnnualDisclosures />
              </Suspense>
            ),
          },
          {
            path: "company",
            element: (
              <Suspense>
                <CompanyDetails />
              </Suspense>
            ),
          },
          {
            path: "reports",
            element: (
              <Suspense>
                <EmployerReport />
              </Suspense>
            ),
          },
          {
            path: "trust-report",
            element: (
              <Suspense>
                <EmployerTustReport />
              </Suspense>
            ),
          },
          {
            path: "forfeiture-report",
            element: (
              <Suspense>
                <EmployerForfeitureReportPage />
              </Suspense>
            ),
          },
          {
            path: "payroll",
            element: (
              <Suspense>
                <EmployerProcessContribution />
              </Suspense>
            ),
          },
          {
            path: "processed-payroll",
            element: (
              <Suspense>
                <EmployerProcessedPayrollContribution />
              </Suspense>
            ),
          },
          {
            path: "processed-payroll/:id",
            element: (
              <Suspense>
                <EmployerProcessedPayrollDetails />
              </Suspense>
            ),
          },
          {
            path: "payrollpreview",
            element: (
              <Suspense>
                <EmployerPayrollPreview />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
];
