import { GridFilterItem } from '@mui/x-data-grid';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DataResponse } from 'core/models/dataResponseModel.model';
import { ProfileDto } from 'core/models/profileDto.model';
import { getPendingEmployees, deletePendingEmployee } from 'core/services/employer.service';

interface EmployeeResponse extends DataResponse {
  content: ProfileDto[];
}

interface IEmployerEmployeeState {
  error: any;
  employees: EmployeeResponse | null;
  loading: boolean;
}

export interface IEmployeesParams {
  companyId: string;
  pageSize: number;
  page: number;
  search: string;
  sortModel: any;
  filterModel: { items: GridFilterItem[] };
}

export const getPendingEmployeesAsync = createAsyncThunk(
  'employerPendingEmployee/getPendingEmployeesAsync',
  async (params: IEmployeesParams): Promise<any> => {
    const response = await getPendingEmployees(params);
    return response;
  }
);

export const deletePendingEmployeeAsync = createAsyncThunk(
  'employerPendingEmployee/deletePendingEmployeeAsync',
  async (params: { id: string }): Promise<any> => {
    const response = await deletePendingEmployee(params);
    return response;
  }
);

const initialState: IEmployerEmployeeState = {
  error: null,
  employees: null,
  loading: false,
};

const employerPendingEmployeeSlice = createSlice({
  name: 'employerPendingEmployee',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPendingEmployeesAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPendingEmployeesAsync.fulfilled, (state, action) => {
        state.employees = action.payload;
        state.loading = false;
      })
      .addCase(getPendingEmployeesAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export default employerPendingEmployeeSlice.reducer;
