import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { EmployeeError, EmployeeProfileResponse, PendingEmployeeResponse } from 'core/models/employeeDto.model';
import { CompanyDto, CompanyPaginationDto } from 'core/models/companyDto.model';
import ApiService from 'core/services/api.service';
import { REQUEST_STATUS } from 'core/constants/roles';

interface ManageEmployeeState {
  companyList: CompanyDto[];
  employeeList: PendingEmployeeResponse[];
  employeeProfile: EmployeeProfileResponse | null;
  employeeProfileError: EmployeeError;
  employeeProfileStatus: string;
  loading: boolean;
}

const initialState: ManageEmployeeState = {
  companyList: [],
  employeeList: [],
  employeeProfile: null,
  employeeProfileError: {} as EmployeeError,
  employeeProfileStatus: REQUEST_STATUS.IDLE,
  loading: false,
};

export const getRetrieveCompanyNamesAndIdsAsync = createAsyncThunk(
  'managePendingEmployee/getRetrieveCompanyNamesAndIdsAsync',
  async (): Promise<CompanyPaginationDto> => {
    const response = await ApiService.getRetrieveCompanyNamesAndIds();
    return response.data;
  }
);

export const getPendingEmployeeByCompanyIdAsync = createAsyncThunk(
  'managePendingEmployee/getPendingEmployeeByCompanyId',
  async (id: string): Promise<any> => {
    const response = await ApiService.getPendingEmployeeByCompanyId(id);
    return response.data;
  }
);

const managePendingEmployeeSlice = createSlice({
  name: 'managePendingEmployee',
  initialState,
  reducers: {
    resetError: (state) => {
      state.employeeProfileError = {};
    },
    setPendingEmployeeProfile: (state, action) => {
      state.employeeProfile = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRetrieveCompanyNamesAndIdsAsync.fulfilled, (state, action) => {
        state.companyList = action.payload.content;
      })
      .addCase(getPendingEmployeeByCompanyIdAsync.fulfilled, (state, action) => {
        state.employeeList = action.payload;
      });
  },
});

const managePendingEmployeeReducer = managePendingEmployeeSlice.reducer;
export default managePendingEmployeeReducer;
