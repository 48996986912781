import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { StatementResponse } from 'core/models/statementsDto.model';
import ApiService from 'core/services/api.service';
import { RootState } from 'states/store';

interface EmployeeStatementsState {
  error: string;
  statements: StatementResponse[];
  year: number;
  loading: boolean;
}

const initialState: EmployeeStatementsState = {
  error: '',
  statements: [],
  year: new Date().getFullYear(),
  loading: false,
};

export const getEmployeeStatementsAsync = createAsyncThunk(
  'employeeStatements/getEmployeeStatementsAsync',
  async ({ id, year }: { id: string; year: number }): Promise<any> => {
    const response = await ApiService.getEmployeeStatements({ id, year });
    return response.data;
  }
);

const employeeStatementsSlice = createSlice({
  name: 'employeeStatements',
  initialState,
  reducers: {
    setYear(state, action) {
      state.year = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmployeeStatementsAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getEmployeeStatementsAsync.fulfilled, (state, action) => {
        state.statements = action.payload;
        state.loading = false;
      })
      .addCase(getEmployeeStatementsAsync.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const { setYear } = employeeStatementsSlice.actions;
export const selectEmployeeStatements = (state: RootState) => state.employeeStatements.statements;
export const selectYear = (state: RootState) => state.employeeStatements.year;
export const selectLoading = (state: RootState) => state.employeeStatements.loading;

const employeeStatementsReducer = employeeStatementsSlice.reducer;
export default employeeStatementsReducer;
