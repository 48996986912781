import { Button as MuiButton, ButtonProps as MuiButtonProps } from '@mui/material';
import { forwardRef } from 'react';
import { CircularProgress } from '@mui/material';
import { appPalette } from 'core/constants';

export type ButtonProps = MuiButtonProps & {
  loading?: boolean;
  sx?: any;
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ loading = false, sx = '', variant = 'contained', color = 'secondary', ...props }, ref) => {
    return (
      <MuiButton
        ref={ref}
        variant={variant}
        color={color}
        sx={{
          minWidth: '120px',
          width: '30%',
          maxWidth: '280px',
          ...sx,
        }}
        {...props}
      >
        {loading ? (
          <CircularProgress
            style={{
              color: variant === 'contained' ? appPalette.white : appPalette.secondary,
              width: '24px',
              height: '24px',
            }}
          />
        ) : (
          props.title || props.children
        )}
      </MuiButton>
    );
  }
);
