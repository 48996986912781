import { useAppSelector } from "states/hooks";
import HeaderBackground from "./HeaderBackground";
import { Box, Stack } from "@mui/material";

export function HeaderDashboard() {
  const people = useAppSelector((state: any) => state.auth.people);
  const isEmployeeDashboard = window.location.pathname.includes(
    "/dashboard/employee"
  );
  return (
    <Box marginBottom={{ zero: 1, xs: 5, sm: 8 }}>
      <Box
        position={"absolute"}
        top={{ zero: 0, sm: -30, esm: -35, xxl: -50, fhd: -80, "2k": -120 }}
        left={0}
        right={0}
        sx={{
          transition: "top 0.3s ease 0s",
        }}
      >
        <HeaderBackground />
      </Box>
      <Box
        position={"relative"}
        top={{ zero: 0, mobile414: 20, sm: "4vw" }}
        zIndex={1}
        sx={{
          transition: "top 0.3s ease 0s",
        }}
      >
        {isEmployeeDashboard ? (
          <div
            style={{
              marginLeft: "20px",
            }}
          >
            <Stack
              direction={{ zero: "column", xs: "row" }}
              spacing={{ zero: 0, xs: 5 }}
              alignItems={{ zero: "baseline", xs: "flex-end" }}
            >
              <Box paddingBottom={1.5}>
                <p className="mb-2 text-heading-1 text-superBlack">
                  Hi {people?.firstName}!
                </p>
              </Box>
            </Stack>
          </div>
        ) : (
          <div
            style={{
              marginLeft: "20px",
            }}
          >
            <p className="mb-4 text-heading-1 text-superBlack">
              Hi {people?.firstName}!
            </p>
            <p className="text-body-13 text-deepGrey">
              Welcome back to your Saveday Dashboard!
            </p>
          </div>
        )}
      </Box>
    </Box>
  );
}
