import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { REQUEST_STATUS } from 'core/constants/roles';
import { getCpaPlanAdmins, updateCpaPlanAdmins } from 'core/services/cpa.service';
import { IPlanAdmin } from './pepPlanAdminSlice';

interface ICpaPlanAdminState {
  getPlanAdminsStatus: string;
  actionPlanAdminsStatus: string;
  admins: IPlanAdmin[];
}

const initialState: ICpaPlanAdminState = {
  getPlanAdminsStatus: REQUEST_STATUS.IDLE,
  actionPlanAdminsStatus: REQUEST_STATUS.IDLE,
  admins: [],
};

export const getCpaPlanAdminsAsync = createAsyncThunk(
  'cpaPlanAdminSlice/getCpaPlanAdminsAsync',
  async (): Promise<any> => {
    const response = await getCpaPlanAdmins();
    return response.data;
  }
);

export const updateCpaPlanAdminsAsync = createAsyncThunk(
  'cpaPlanAdminSlice/updateCpaPlanAdminsAsync',
  async (body: any): Promise<any> => {
    const response = await updateCpaPlanAdmins(body);
    return response.data;
  }
);

const cpaPlanAdminSliceSlice = createSlice({
  name: 'cpaPlanAdminSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCpaPlanAdminsAsync.pending, (state) => {
        state.getPlanAdminsStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(getCpaPlanAdminsAsync.fulfilled, (state, action) => {
        state.getPlanAdminsStatus = REQUEST_STATUS.SUCCESS;
        state.admins = action.payload;
      })
      .addCase(getCpaPlanAdminsAsync.rejected, (state, action) => {
        state.getPlanAdminsStatus = REQUEST_STATUS.ERROR;
      })

      .addCase(updateCpaPlanAdminsAsync.pending, (state) => {
        state.actionPlanAdminsStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(updateCpaPlanAdminsAsync.fulfilled, (state, action) => {
        state.actionPlanAdminsStatus = REQUEST_STATUS.SUCCESS;
        state.admins = action.payload;
      })
      .addCase(updateCpaPlanAdminsAsync.rejected, (state, action) => {
        state.actionPlanAdminsStatus = REQUEST_STATUS.ERROR;
      });
  },
});

export default cpaPlanAdminSliceSlice.reducer;
