function getPath(route: any, params: any): string {
  let path = route;
  if (typeof route === 'object') {
    path = route.path;
  }
  const queryArray: String[] = [];
  const filterArray: String[] = [];
  const routeParams: String[] = [];

  const rParams = path.match(/:\w+/g);
  if (rParams) {
    rParams.forEach((param: any) => {
      routeParams.push(param.substring(1));
    });
  }

  if (params && Object.entries(params).length > 0) {
    Object.keys(params).forEach((key) => {
      if (routeParams.indexOf(key) > -1) {
        path = path.replace(`:${key}`, params[key]);
      } else if (key === 'filter' && params[key]) {
        for (const [queryStringKey, value] of Object.entries(params[key])) {
          if (value) filterArray.push(`${queryStringKey}=${value}`);
        }
        !!filterArray.length && queryArray.push(filterArray.join('&'));
      }
      path += queryArray.length ? `?${queryArray.join('&')}` : '';
    });
  }
  return path;
}

export function getApiPath(route: any, params?: any): string {
  return process.env.REACT_APP_API_URL + getPath(route, params);
}
