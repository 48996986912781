export enum CheckListEnum {
  NAVIGATE = "NAVIGATE",
  COMPLETE_PAYROLL_INTERGRATiON = "COMPLETE_PAYROLL_INTERGRATiON",
  EMPLOYEE_JOURNEY = "EMPLOYEE_JOURNEY",
  BANK_ACCOUNT = "BANK_ACCOUNT",
  UPLOAD_EMPLOYEE_RECORDS = "UPLOAD_EMPLOYEE_RECORDS",
  PROCESS_CONTRIBUTION = "PROCESS_CONTRIBUTION",
}

export enum CheckListTitle {
  NAVIGATE = "Navigate Saveday Dashboard",
  COMPLETE_PAYROLL_INTERGRATiON = "Complete Payroll Integration",
  EMPLOYEE_JOURNEY = '"Employee Journey',
  BANK_ACCOUNT = "Change your bank account",
  UPLOAD_EMPLOYEE_RECORDS = "Upload Employee Records",
  PROCESS_CONTRIBUTION = "Process Contributions",
}
