import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { REQUEST_STATUS } from 'core/constants/roles';
import { ModalTypes } from 'components/employerProcessContributions/EmployerProcessContributionsModal';
import {
  EmployerContributionResponse,
  GetZeroContributionPayload,
  ZeroContributionResponse,
  ContributionDto,
} from 'core/models/EmployerContributionDto.model';
import { PayrollManageDto, UpdatePayrollCheckDateResponse } from 'core/models/payrollDto.model';
import ApiService from 'core/services/api.service';
import EmployerService from 'core/services/employer.service';
import { cloneDeep } from 'lodash';
import { RootState } from 'states/store';

interface EmployerProcessContributions {
  data: EmployerContributionResponse | null;
  isPreview: boolean;
  updatePayCheckDateResponseText: UpdatePayrollCheckDateResponse | null;
  modalType: ModalTypes | null;
  isModalOpen: boolean;
  zeroContribution: ZeroContributionResponse | null;
  modalDisplayList: ModalTypes[];
  payrollList: PayrollManageDto[];
  cloneData: ContributionDto[];
  loading: boolean;
  getProcessPayrollsStatus: string;
  processPayrolls: any;
  deletePendingProcessPayrollsStatus: string;
  tempCurrentPayrollCheckDate: number | null;
  payCheckDate: number | null;
  offCycle: boolean;
  isManual: boolean;
  selectedProcessedPayrollDetails: EmployerContributionResponse | null;
}

const initialState: EmployerProcessContributions = {
  data: null,
  isPreview: false,
  updatePayCheckDateResponseText: null,
  modalType: null,
  isModalOpen: false,
  zeroContribution: null,
  modalDisplayList: [],
  payrollList: [],
  cloneData: [],
  loading: false,
  getProcessPayrollsStatus: REQUEST_STATUS.IDLE,
  processPayrolls: [],
  deletePendingProcessPayrollsStatus: REQUEST_STATUS.IDLE,
  tempCurrentPayrollCheckDate: null,
  payCheckDate: null,
  offCycle: false,
  isManual: false,
  selectedProcessedPayrollDetails: null,
};

export const getContributionsAsync = createAsyncThunk(
  'employerProcessContributions/getContributionsAsync',
  async (): Promise<any> => {
    const response = await EmployerService.getContributions();
    return response.data;
  }
);

export const getZeroContributionAsync = createAsyncThunk(
  'employerProcessContributions/getZeroContributionAsync',
  async ({ payConId, payrollContribution }: GetZeroContributionPayload): Promise<ZeroContributionResponse> => {
    const response = await EmployerService.getZeroContribution({
      payConId,
      payrollContribution,
    });
    return response.data;
  }
);

export const getPayrollContributionByYearAndIdAsync = createAsyncThunk(
  'employerProcessContributions/getPayrollContribution',
  async (payload: { year: string; companyId: string }) => {
    const { year, companyId } = payload;
    const response = await ApiService.getPayrollContribution(year, companyId);
    return response.data;
  }
);

export const getProcessPayrollsAsync = createAsyncThunk(
  'employerProcessContributions/getProcessPayrollsAsync',
  async (payload: { companyId: string; filter: any }) => {
    const response = await ApiService.getProcessPayrolls(payload);
    return response.data;
  }
);

export const getProcessedPayrollDetailsAsync = createAsyncThunk(
  'employerProcessContributions/getProcessedPayrollDetailsAsync',
  async (params: { payrollId: string; companyId: string; filter?: any }) => {
    const response = await ApiService.getProcessedPayrollDetails(params);
    return response.data;
  }
);

export const deletePendingProcessPayrollsAsync = createAsyncThunk(
  'employerProcessContributions/deletePendingProcessPayrollsAsync',
  async (payrollId: string) => {
    const response = await ApiService.deletePendingProcessPayrolls(payrollId);
    return response.data;
  }
);

const employerProcessContributionsSlice = createSlice({
  name: 'employerProcessContributions',
  initialState,
  reducers: {
    setIsModalOpen: (state, action) => {
      state.isModalOpen = action.payload;
    },
    setModalType: (state, action) => {
      state.modalType = action.payload;
    },
    setData: (state, action) => {
      state.data = action.payload;
    },
    setCloneData: (state, action) => {
      state.cloneData = action.payload;
    },
    setModalDisplayList: (state, action) => {
      state.modalDisplayList = action.payload;
    },
    calculateNewTotal: (state) => {
      if (state.data) {
        const newData = { ...state.data };
        newData.contributions.forEach((item) => {
          item.total =
            item.rothContribution +
            item.traditionalContribution +
            item.profitSharing +
            item.loanReplacement +
            item.erMatch;
        });
        const newCurrentGrandTotal = newData.contributions.reduce((pre, curr) => {
          return pre + curr.total;
        }, 0);
        newData.currentGrandTotal = newCurrentGrandTotal;
        state.data = newData;
      }
    },
    setOffCycle: (state, action) => {
      state.offCycle = action.payload;
    },
    setPayrollList: (state, action) => {
      state.payrollList = action.payload;
    },
    setPayCheckDate: (state, action) => {
      state.payCheckDate = action.payload;
    },
    setIsManual: (state, action) => {
      state.isManual = action.payload;
    },
    clearAll: (state) => {
      if (state.data) {
        const newContributions = [...state.data?.contributions];
        if (newContributions.length) {
          newContributions.forEach((item) => {
            item.rothContribution = 0;
            item.traditionalContribution = 0;
            item.erMatch = 0;
            item.loanReplacement = 0;
            item.profitSharing = 0;
            item.total = 0;
          });
        }
        state.data.contributions = newContributions;
        state.data.currentGrandTotal = 0;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContributionsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getContributionsAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getContributionsAsync.fulfilled, (state, action: PayloadAction<EmployerContributionResponse>) => {
        state.data = action.payload;
        state.cloneData = action.payload.contributions;
        state.loading = false;
        state.tempCurrentPayrollCheckDate = action.payload.currentPayrollCheckDate;
        state.data.lastGrandTotal = action.payload.currentGrandTotal;
      })
      .addCase(getZeroContributionAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getZeroContributionAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getZeroContributionAsync.fulfilled, (state, action) => {
        state.loading = false;
        const newZeroContribution = cloneDeep(action.payload);
        state.data?.contributions.forEach((contributionItem) => {
          if (newZeroContribution) {
            const idx = newZeroContribution.annualLimitExceededPersonList.findIndex(
              (item) => item.id === contributionItem.id
            );
            if (idx !== -1) {
              newZeroContribution.annualLimitExceededPersonList[idx] = contributionItem;
            }
          }
        });
        state.zeroContribution = newZeroContribution;
        if (action.payload.annualLimitExceededPersonList.length) {
          state.isModalOpen = true;
          state.modalDisplayList.push(ModalTypes.ExceedAnnualContributionDetectedModal);
        }
        if (state.modalDisplayList.length) {
          state.modalType = state.modalDisplayList.shift() as ModalTypes;
        }
      })
      .addCase(getPayrollContributionByYearAndIdAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPayrollContributionByYearAndIdAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getPayrollContributionByYearAndIdAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.payrollList = action.payload;
      })

      .addCase(getProcessPayrollsAsync.pending, (state) => {
        state.getProcessPayrollsStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(getProcessPayrollsAsync.fulfilled, (state, action) => {
        state.getProcessPayrollsStatus = REQUEST_STATUS.SUCCESS;
        state.processPayrolls = action.payload;
      })
      .addCase(getProcessPayrollsAsync.rejected, (state) => {
        state.getProcessPayrollsStatus = REQUEST_STATUS.ERROR;
      })

      .addCase(deletePendingProcessPayrollsAsync.pending, (state) => {
        state.deletePendingProcessPayrollsStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(deletePendingProcessPayrollsAsync.fulfilled, (state) => {
        state.deletePendingProcessPayrollsStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(deletePendingProcessPayrollsAsync.rejected, (state) => {
        state.deletePendingProcessPayrollsStatus = REQUEST_STATUS.ERROR;
      })
      .addCase(getProcessedPayrollDetailsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProcessedPayrollDetailsAsync.fulfilled, (state, action) => {
        state.selectedProcessedPayrollDetails = action.payload;
        state.loading = false;
      })
      .addCase(getProcessedPayrollDetailsAsync.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  setIsModalOpen,
  setModalType,
  setData,
  setModalDisplayList,
  calculateNewTotal,
  setPayrollList,
  setOffCycle,
  setCloneData,
  setPayCheckDate,
  setIsManual,
  clearAll,
} = employerProcessContributionsSlice.actions;

export const selectEmployerProcessContributionsData = (state: RootState) => state.employerProcessContributions.data;
export const selectEmployerProcessContributionsUpdatePayCheckDateResponseText = (state: RootState) =>
  state.employerProcessContributions.updatePayCheckDateResponseText;
export const selectEmployerProcessContributionsIsModalOpen = (state: RootState) =>
  state.employerProcessContributions.isModalOpen;
export const selectEmployerProcessContributionsModalType = (state: RootState) =>
  state.employerProcessContributions.modalType;
export const selectZeroContribution = (state: RootState) => state.employerProcessContributions.zeroContribution;
export const selectModalDisplayList = (state: RootState) => state.employerProcessContributions.modalDisplayList;

const employerProcessContributionsReducer = employerProcessContributionsSlice.reducer;
export default employerProcessContributionsReducer;
