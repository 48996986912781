import { createSvgIcon } from "@mui/material";

export const Dash = createSvgIcon(
  <svg viewBox="0 0 64 93" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="1" width="63" height="91" rx="11.5" stroke="#E0DEC9" />
    <rect
      x="8"
      y="84.5"
      width="8"
      height="8"
      rx="4"
      transform="rotate(-90 8 84.5)"
      fill="#69AB00"
    />
    <rect
      x="8"
      y="72.5"
      width="8"
      height="40"
      rx="4"
      transform="rotate(-90 8 72.5)"
      fill="#F4F3E9"
    />
    <rect
      x="8"
      y="52.5"
      width="5.33333"
      height="48"
      rx="2.66667"
      transform="rotate(-90 8 52.5)"
      fill="#F4F3E9"
    />
    <rect
      x="8"
      y="43.1666"
      width="5.33333"
      height="24"
      rx="2.66667"
      transform="rotate(-90 8 43.1666)"
      fill="#F4F3E9"
    />
    <rect
      x="8"
      y="33.8334"
      width="5.33333"
      height="40"
      rx="2.66667"
      transform="rotate(-90 8 33.8334)"
      fill="#F4F3E9"
    />
    <rect
      x="8"
      y="16.5"
      width="8"
      height="48"
      rx="4"
      transform="rotate(-90 8 16.5)"
      fill="#E0DEC9"
    />
  </svg>,
  "Dash"
);
