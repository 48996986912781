import { MenuItemProps } from '.';
import { ListItemButton } from '@mui/material';
import NavLink from '../NavLink';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { To, useNavigate } from 'react-router-dom';

interface IMenuListItemProps {
  item: MenuItemProps;
  currentTab?: string;
  handleToggleTab?: Function;
  child?: boolean;
}

export default function MenuListItem(props: IMenuListItemProps) {
  const { item, currentTab, handleToggleTab = () => {}, child = false } = props;
  const { label, icon, childrens = [], callback = undefined, url = '', subLabel } = item;
  const navigate = useNavigate();
  return (
    <>
      {!callback && childrens.length === 0 && (
        <ListItemButton
          component={NavLink}
          end
          to={item.url as To}
          className={['p-0', childrens.length > 0 ? 'has-children' : '', currentTab === label ? 'expanded' : ''].join(
            ' '
          )}
          activeClassName="font-semibold"
        >
          <div className="flex items-center justify-between w-full px-6 py-3">
            <div className={`flex items-center ${child ? 'pl-7' : ''}`}>
              <span className="mr-3">{icon}</span>
              <span className="text-sm whitespace-normal text-white">{label}</span>
            </div>
            {subLabel}
          </div>
        </ListItemButton>
      )}

      {!callback && childrens.length > 0 && (
        <ListItemButton
          className={['p-0', childrens.length > 0 ? 'has-children' : '', currentTab === label ? 'expanded' : ''].join(
            ' '
          )}
          onClick={() => handleToggleTab(item)}
        >
          <div className="flex items-center justify-between w-full px-6 py-3">
            <div className={`flex items-center ${child ? 'pl-7' : ''}`}>
              <span className="mr-3">{icon}</span>
              <span className="text-sm whitespace-normal text-white">{label}</span>
            </div>
            {childrens.length > 0 &&
              (currentTab === label ? <ExpandLess className="w-4 h-4" /> : <ExpandMore className="w-4 h-4" />)}
          </div>
        </ListItemButton>
      )}

      {callback && (
        <ListItemButton
          className={['p-0', childrens.length > 0 ? 'has-children' : '', currentTab === label ? 'expanded' : ''].join(
            ' '
          )}
          onClick={async () => {
            const isNext = await callback();
            if (isNext && url) {
              navigate(url);
            }
          }}
        >
          <div className="flex items-center justify-between w-full px-6 py-3">
            <div className={`flex items-center ${child ? 'pl-7' : ''}`}>
              <span className="mr-3">{icon}</span>
              <span className="text-sm whitespace-normal">{label}</span>
            </div>
          </div>
        </ListItemButton>
      )}
    </>
  );
}
