import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { StateData } from 'core/constants/state-data';
import { calcTotalTaxes } from 'core/functions/employee-distribution';
import {
  DistributionPayload,
  TDistributionBasicInfoResponse,
  TDistributionHistoryItemResponse,
  TDistributionHistoryResponse,
  UpdateDistributionPayload,
} from 'core/models/employeeDistribution.model';
import EmployeeService from 'core/services/employee.service';

interface IDistributionState {
  basicInfo: TDistributionBasicInfoResponse | null;
  loading: boolean;
  historyData: TDistributionHistoryResponse;
  draftDistributionData: TDistributionHistoryItemResponse | null;
  totalTaxes: number;
  distributionType: string;
  selectedState: StateData | null;
}

const initialState: IDistributionState = {
  basicInfo: null,
  loading: false,
  historyData: [],
  draftDistributionData: null,
  totalTaxes: 0,
  distributionType: '',
  selectedState: null,
};

export const getBasicInfoAsync = createAsyncThunk('employeeDistributions/getBasicInfoAsync', async (): Promise<any> => {
  const response = await EmployeeService.getDistributionBasicInfo();
  return response.data;
});

export const getDistributionHistoryAsync = createAsyncThunk(
  'employeeDistributions/getDistributionHistoryAsync',
  async ({ userProfileId }: { userProfileId: string }): Promise<any> => {
    const response = await EmployeeService.getDistributionHistory({
      userProfileId,
    });
    return response.data;
  }
);

export const createDistributionAsync = createAsyncThunk(
  'employeeDistributions/createDistributionAsync',
  async ({ payload }: { payload: DistributionPayload }): Promise<any> => {
    const response = await EmployeeService.createDistribution({
      payload,
    });
    return response.data;
  }
);

export const updateDistributionAsync = createAsyncThunk(
  'employeeDistributions/updateDistributionAsync',
  async ({ payload }: { payload: UpdateDistributionPayload }): Promise<any> => {
    const response = await EmployeeService.updateDistribution({
      payload,
    });
    return response.data;
  }
);

export const getDraftDistributionAsync = createAsyncThunk(
  'employeeDistributions/getDraftDistributionAsync',
  async (): Promise<any> => {
    const response = await EmployeeService.getDraftDistribution();
    return response.data;
  }
);

export const calcNetAmountAsync = createAsyncThunk(
  'employeeDistributions/calcNetAmountAsync',
  async ({ payload }: { payload: any }): Promise<any> => {
    const response = await EmployeeService.calcNetAmount({ payload });
    return response.data;
  }
);

const employeeDistributionsSlice = createSlice({
  name: 'employeeDistributions',
  initialState,
  reducers: {
    updateBasicInfo: (state, action) => {
      const { basicInfo = {} } = state;
      state.basicInfo = { ...basicInfo, ...action.payload };
    },
    updateDraftDistribution: (state, action) => {
      const { draftDistributionData = {} } = state;
      state.draftDistributionData = {
        ...draftDistributionData,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBasicInfoAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBasicInfoAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.basicInfo = action.payload;
      })
      .addCase(getBasicInfoAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getDistributionHistoryAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDistributionHistoryAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.historyData = action.payload;
      })
      .addCase(getDistributionHistoryAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createDistributionAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createDistributionAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createDistributionAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateDistributionAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateDistributionAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateDistributionAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getDraftDistributionAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDraftDistributionAsync.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.draftDistributionData = action.payload;
          if (action.payload?.tax) {
            state.totalTaxes = calcTotalTaxes(action.payload.tax);
          }
        } else {
          state.draftDistributionData = null;
        }
      })
      .addCase(getDraftDistributionAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(calcNetAmountAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(calcNetAmountAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(calcNetAmountAsync.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const { updateBasicInfo, updateDraftDistribution } = employeeDistributionsSlice.actions;

export default employeeDistributionsSlice.reducer;
