import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import { TextFieldProps, TextField } from 'common/components';
import { useDebouncedCallback } from 'hooks/useDebouncedCallBack';
import { useState } from 'react';

type SearchBarProps = Omit<TextFieldProps, 'onChange'> & {
  onChange: (e: string) => void;
};

const SearchBar = ({ onChange, ...props }: SearchBarProps) => {
  const [value, setValue] = useState<string>('');

  const handleChangeSearchValue = useDebouncedCallback((debounceValue: string) => {
    onChange(debounceValue);
  }, 500);

  return (
    <TextField
      sx={{
        '.MuiOutlinedInput-input.MuiInputBase-input': {
          fontWeight: 600,
          color: (theme) => theme.palette.darkest.dark,
          '&::placeholder': {
            fontWeight: 600,
            fontSize: 13,
            color: (theme) => theme.palette.subTitle,
          },
          paddingLeft: 0,
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      placeholder="Search"
      variant="outlined"
      value={value}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value);
        handleChangeSearchValue(e.target.value);
      }}
      {...props}
    />
  );
};

export default SearchBar;
