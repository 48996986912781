import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const activeCampaignId = process.env.REACT_APP_ACTIVE_CAMPAIGN_ID;

declare global {
  interface Window {
    vgo: {
      (...args: any[]): void;
      q?: any[];
    };
  }
}

const loadTrackingScript = () => {
  const script = document.createElement("script");
  script.innerHTML = `
    (function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){
      (e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)
    };e[e.visitorGlobalObjectAlias].l=(new Date).getTime();
    r=t.createElement("script");r.src=o;r.async=true;
    i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)
    })(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo");
    vgo('setAccount', '${activeCampaignId}');
    vgo('setTrackByDefault', true);
    vgo('process');
  `;
  document.body.appendChild(script);
};

const TrackingScript = () => {
  const location = useLocation();

  useEffect(() => {
    if (!window.vgo) {
      loadTrackingScript();
    } else {
      window.vgo("update");
    }
  }, [location]);

  return null;
};

export default TrackingScript;
