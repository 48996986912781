import AccountCircleIcon from 'assets/SVGIcons/AccountCircleIcon';
import DashboardIcon from 'assets/SVGIcons/DashboardIcon';
import { ROLES } from 'core/constants/roles';
import { CookieNames } from 'core/enums/cookie-names.enum';
import { setCookie } from 'core/services/cookie.service';
import AuthLayout from 'layout/AuthLayout';
import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { ISession, useAuth } from 'contexts/AuthProvider';
import { MenuItemProps } from 'common/components/MenuList';
import { useAppSelector } from 'states/hooks';
import { EmployeeStatus } from 'core/enums/employee-status.enum';

interface ISelectRoleLayoutProps {
  children?: React.ReactNode;
}

export default function SelectRoleLayout(props: ISelectRoleLayoutProps) {
  const menu: MenuItemProps[] = [];
  const auth = useAuth();
  const people = useAppSelector((state) => state.auth.people);

  if (auth) {
    const { session, updateSession } = auth;
    if (session) {
      const { allRole } = session as ISession;
      allRole.forEach((roleItem) => {
        const item = {
          url: roleItem.mainPage,
          label: roleItem.currentCompanyName,
          icon: roleItem.currentRole === ROLES.SYSTEM_ADMIN ? <DashboardIcon /> : <AccountCircleIcon />,
          callback: async () => {
            setCookie(CookieNames.CurrentCompanyId, roleItem.currentCompanyId || '');
            setCookie(CookieNames.CurrentRole, roleItem.currentRole);
            setCookie(CookieNames.UserProfileId, roleItem.currentUserProfileId || '');
            await updateSession();
            return true;
          },
        };
        if (
          roleItem &&
          roleItem.currentCompanyId &&
          people &&
          people.profileStatus &&
          people?.profileStatus[roleItem.currentCompanyId] === EmployeeStatus.NEW &&
          roleItem.currentRole === ROLES.PARTICIPANT
        ) {
          return;
        } else {
          menu.push(item as any);
        }
      });
    }
  }

  let makeUpMenu: MenuItemProps[] = [];

  menu.forEach(function (item: MenuItemProps) {
    const existInMakeUpMenu = makeUpMenu.find(function (value: MenuItemProps) {
      return value.label === item.label;
    });
    if (!!existInMakeUpMenu) {
      return;
    }
    const existing = menu.filter(function (value: MenuItemProps) {
      return value.label === item.label;
    });
    if (existing.length > 1) {
      const itemWithChildren: MenuItemProps = {
        label: existing[0].label,
        icon: <AccountCircleIcon />,
        isOpenChildren: true,
        childrens: [],
      };
      existing.forEach((existingItem: MenuItemProps) => {
        const child: MenuItemProps = {
          ...existingItem,
          label: existingItem?.url?.includes('employer')
            ? 'Admin'
            : existingItem?.url?.includes('cpa')
            ? 'Plan Admin Portal'
            : existingItem?.url?.includes('pep')
            ? 'PEP Plan Admin'
            : existingItem.url?.includes('corp')
            ? 'Corporate Platform Plan Admin'
            : 'Employee',
        };
        itemWithChildren.childrens?.push(child);
      });
      makeUpMenu.push(itemWithChildren);
    } else {
      makeUpMenu.push(item);
    }
  });

  return (
    <AuthLayout menu={makeUpMenu}>
      <Outlet />
    </AuthLayout>
  );
}
