import { CashDTO } from './../models/Cash.model';
import { HoldingDto } from 'core/models/HoldingDto.model';
import publicApi from './utils/HttpRequest';
import { EmployeeContributionDto } from 'core/models/EmployeeContributionDto.model';
import { EmployeeQuestionnairesDto } from 'core/models/EmployeeQuestionnairesDto.model';
import { answerDto } from 'core/models/answerDto.model';
import { PortfoliosDTO } from 'core/models/PortfoliosDto.model';
import { UploadFileDto } from 'core/models/UploadFileDto.model';
import { getApiPath } from './utils/routesUtils';
import { API } from './utils/app.routes';
import { EmployeeSignUpDto } from 'core/models/loginDto.model';
import { AxiosRequestConfig } from 'axios';
import { EmployeeStatus } from 'core/enums/employee-status.enum';
import { DistributionPayload, UpdateDistributionPayload } from 'core/models/employeeDistribution.model';
import { OnboardingEmployeeCheckCredentialsPayload } from 'core/models/employeeOnboardingDto.model';

export const holding = async (accountId: string): Promise<HoldingDto[]> => {
  const { data } = await publicApi.get<HoldingDto[]>(`/api/accounts/${accountId}/holdings`);
  return Promise.resolve(data);
};

export const getContributions = async (rothAccountId: string): Promise<EmployeeContributionDto> => {
  const { data } = await publicApi.get<EmployeeContributionDto>(
    `api/contributions/getContributionLimitByYear?userProfileId=${rothAccountId}`
  );
  return Promise.resolve(data);
};

export const getQuestionnaires = async (): Promise<EmployeeQuestionnairesDto> => {
  const { data } = await publicApi.get<EmployeeQuestionnairesDto>(`api/questionnaires/_active`);
  return Promise.resolve(data);
};

export const getCash = async (userId: string): Promise<CashDTO> => {
  const { data } = await publicApi.get<CashDTO>(`/api/accounts/${userId}/cash`);
  return Promise.resolve(data);
};

export const getAnswers = async (profileId: string): Promise<answerDto> => {
  const { data } = await publicApi.get<answerDto>(`/api/answers/${profileId}/_active`);
  return Promise.resolve(data);
};

const getPortfolios = async (): Promise<PortfoliosDTO[]> => {
  const { data } = await publicApi.get<PortfoliosDTO[]>(`/api/portfolios/_active`);
  return Promise.resolve(data);
};

export const updateAnswer = async (payload: { id: string; answers: any[] }): Promise<answerDto> => {
  const { data } = await publicApi.patch<answerDto>(`/api/answers`, payload);
  return Promise.resolve(data);
};

export const getEmployeeImg = async (id: string): Promise<UploadFileDto> => {
  const { data } = await publicApi.get(`/api/files/${id}`);
  if (!data) {
    throw new Error('No data found');
  }
  return Promise.resolve(data);
};

const employeeSignUp = (
  payload: EmployeeSignUpDto,
  params: { token: string; filter?: any },
  config?: AxiosRequestConfig<any>
): Promise<any> => {
  const { token, filter } = params;
  const path = getApiPath(API.EMPLOYEE_SIGN_UP, { token, filter });
  return publicApi.post(path, payload, config);
};

function getCompanyByPerson(payload: EmployeeSignUpDto): Promise<any> {
  const path: string = getApiPath(API.GET_COMPANY_BY_PERSON);
  return publicApi.post(path, payload);
}

function employeeSignUpWithoutToken(
  payload: EmployeeSignUpDto,
  companyId: string,
  config?: AxiosRequestConfig<any>
): Promise<any> {
  const path: string = getApiPath(API.EMPLOYEE_SIGN_UP_WITHOUT_TOKEN, {
    companyId,
  });
  return publicApi.post(path, payload, config);
}

function updateUserProfileStatus(payload: { profileId: string; status: EmployeeStatus }): Promise<any> {
  const { profileId, status } = payload;
  const path: string = getApiPath(API.UPDATE_USER_PROFILE_STATUS, {
    profileId,
    status,
  });
  return publicApi.post(path, payload);
}

function checkTokenValid(token: string): Promise<any> {
  const path: string = getApiPath(API.CHECK_TOKEN_VALID, {
    token,
  });
  return publicApi.get(path);
}

function getSessionByToken(token: string): Promise<any> {
  const path: string = getApiPath('/api/profiles/:token/session', {
    token,
  });
  return publicApi.get(path);
}

function checkAllowOptOutByToken(token: string): Promise<any> {
  const path: string = getApiPath('/api/profiles/:token/allowOptOut', {
    token,
  });
  return publicApi.get(path);
}

function downloadTransations({ filter }: any): Promise<any> {
  const path: string = getApiPath(API.GET_ALL_TRANSACTION_FOR_DOWNLOAD, {
    filter,
  });
  return publicApi.get(path);
}

function updateStatusProfileActive(): Promise<any> {
  const path: string = getApiPath(API.UPDATE_STATUS_PROFILE_ACTIVE);
  return publicApi.patch(path);
}

function getLoans(): Promise<any> {
  return publicApi.get('/api/loans');
}

function requestPayOffLoan(payload: any): Promise<any> {
  return publicApi.post('/api/loans/repayment', payload);
}

function getBasicInformation(): Promise<any> {
  return publicApi.get('/api/loans/basicInfo');
}

function createLoan(payload: any): Promise<any> {
  return publicApi.put('/api/loans', payload);
}

function updateLoan(payload: any): Promise<any> {
  return publicApi.patch(`/api/loans/updateLoanForm`, payload);
}

export function downloadScheduleByLoanId(loanId: string): Promise<any> {
  return publicApi.get(`/api/loans/${loanId}/downloadSchedule`, {
    responseType: 'blob',
  });
}

function getDistributionBasicInfo(): Promise<any> {
  const path = getApiPath(API.DISTRIBUTION_BASIC_INFO);
  return publicApi.get(path);
}

function getDistributionHistory({ userProfileId }: { userProfileId: string }): Promise<any> {
  const path = getApiPath(API.DISTRIBUTION_HISTORY, { userProfileId });
  return publicApi.get(path);
}

function getDraftDistribution(): Promise<any> {
  const path = getApiPath(API.GET_DRAFT_DISTRIBUTION);
  return publicApi.get(path);
}

function createDistribution({ payload }: { payload: DistributionPayload }) {
  const path = getApiPath(API.DISTRIBUTIONS);
  return publicApi.put(path, payload);
}

function updateDistribution({ payload }: { payload: UpdateDistributionPayload }) {
  const path = getApiPath(API.DISTRIBUTIONS);
  return publicApi.patch(path, payload);
}

function calcNetAmount({ payload }: { payload: any }) {
  const path = getApiPath(API.CALC_NET_AMOUNT);
  return publicApi.post(path, payload);
}

function changeAgeBasedPortfolio({ payload }: { payload: any }) {
  const path = getApiPath('/api/profiles/ageBasedPortfolio');
  return publicApi.patch(path, payload);
}

const updateOnboardingStage = async (payload: any) => {
  const path = getApiPath('/api/onboarding/employee/stages');
  return publicApi.patch(path, payload);
};

const onboardingCheckCredentials = ({
  payload,
  filter = {},
}: {
  payload: OnboardingEmployeeCheckCredentialsPayload;
  filter: any;
}) => {
  const path: string = getApiPath('/api/onboarding/employee', { filter });
  return publicApi.post(path, payload, {
    headers: {
      'X-Cs-Session': null,
    },
  });
};

const signOptOutForm = async (payload: any, sessionId: string) => {
  const path = getApiPath('/api/onboarding/employee/signOptOutForm');
  return publicApi.post(path, payload, {
    headers: {
      'X-Cs-Session': sessionId,
    },
  });
};

const downloadForfeitureReports = async ({ companyId, filter = {} }: { companyId: string; filter?: any }) => {
  const path = getApiPath(`/api/companies/${companyId}/retrieveForfeitureReports/download`, { filter });
  return publicApi.get(path, { responseType: 'blob' });
};

const EmployeeService = {
  employeeSignUp,
  employeeSignUpWithoutToken,
  getQuestionnaires,
  getPortfolios,
  getCompanyByPerson,
  updateUserProfileStatus,
  checkTokenValid,
  downloadTransations,
  updateStatusProfileActive,
  getLoans,
  getBasicInformation,
  createLoan,
  updateLoan,
  downloadScheduleByLoanId,
  getDistributionBasicInfo,
  getDistributionHistory,
  createDistribution,
  updateDistribution,
  getDraftDistribution,
  calcNetAmount,
  changeAgeBasedPortfolio,
  updateOnboardingStage,
  onboardingCheckCredentials,
  signOptOutForm,
  getSessionByToken,
  requestPayOffLoan,
  checkAllowOptOutByToken,
  downloadForfeitureReports,
};

export default EmployeeService;
