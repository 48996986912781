import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CookieNames } from 'core/enums/cookie-names.enum';
import {
  IRecoveryAccountDto,
  IRecoveryUpdateAccountPayLoad,
  IRecoveryVerificationPayLoad,
  IVerifiedAccountDto,
  LoginDto,
  LoginWithPathDto,
} from 'core/models/loginDto.model';
import { LoginResponseSuccessDto } from 'core/models/loginResponseSuccessDto.model';
import ApiService from 'core/services/api.service';
import AuthService from 'core/services/auth.service';
import { getCookie, removeAllCookie, setCookie } from 'core/services/cookie.service';
import { RootState } from '../store';
import { UserRoleDto } from './../../core/models/userRoleDto.model';
import { REQUEST_STATUS, ROLES } from 'core/constants/roles';
import { ICookie } from 'contexts/AuthProvider';
import { IRecoveryAccountPayload, PeoplePayload } from 'core/models/employeeDto.model';
import { PeopleDto } from 'core/models/peopleDto.model';
import { AxiosRequestConfig } from 'axios';

interface AuthState {
  info: LoginResponseSuccessDto | null;
  pictureUrl: string;
  lastLogin: string;
  isActiveEmployee: boolean;
  error: string;
  success: boolean;
  loginSuccess: boolean;
  pathSuccess: boolean;
  mainPage: string;
  reCaptchaSiteKey: string;
  people: PeopleDto | null;
  updatePeopleStatus: string;
  forgotPasswordStatus: string;
  resetPasswordStatus: string;
  createCredentialsStatus: string;
  loading: boolean;
  recoveryEmailStatus: string;
  recoveryAccountType: string;
  recoveryAccountToken: string;
  recoveryAccountNewEmail: string;
  recoveryAccountResponse?: IRecoveryAccountDto;
  verifiedAccountResponse?: IVerifiedAccountDto;
}

const initialState: AuthState = {
  info: null,
  pictureUrl: '',
  lastLogin: '',
  isActiveEmployee: false,
  error: '',
  success: false,
  loginSuccess: false,
  pathSuccess: false,
  mainPage: '',
  reCaptchaSiteKey: '',
  people: null,
  updatePeopleStatus: REQUEST_STATUS.IDLE,
  forgotPasswordStatus: REQUEST_STATUS.IDLE,
  resetPasswordStatus: REQUEST_STATUS.IDLE,
  createCredentialsStatus: REQUEST_STATUS.IDLE,
  recoveryEmailStatus: REQUEST_STATUS.IDLE,
  loading: false,
  recoveryAccountType: '',
  recoveryAccountToken: '',
  recoveryAccountNewEmail: '',
  recoveryAccountResponse: undefined,
  verifiedAccountResponse: undefined,
};

export const loginAsync = createAsyncThunk(
  'auth/login',
  async ({
    loginWithPathDto,
    reqParams,
  }: {
    loginWithPathDto: LoginWithPathDto;
    reqParams?: any;
  }): Promise<LoginResponseSuccessDto> => {
    const loginDto: LoginDto = {
      userName: loginWithPathDto.userName,
      password: loginWithPathDto.password,
    };
    const response = await ApiService.login(loginDto);
    return response.data;
  }
);

export const recoveryEmailAsync = createAsyncThunk(
  'auth/recoveryEmailAsync',
  async ({ payload, config }: { payload: any; config: AxiosRequestConfig<any> }): Promise<any> => {
    const response = await ApiService.recoveryEmail(payload, config);
    return response.data;
  }
);

export const resetPasswordAsync = createAsyncThunk(
  'auth/resetPasswordAsync',
  async ({ payload, config }: { payload: any; config: AxiosRequestConfig<any> }): Promise<any> => {
    const response = await ApiService.resetPassword(payload, config);
    return response.data;
  }
);

export const createCredentialsAsync = createAsyncThunk(
  'auth/createCredentialsAsync',
  async ({ payload, config }: { payload: any; config: AxiosRequestConfig<any> }): Promise<any> => {
    const response = await ApiService.createCredentials(payload, config);
    return response.data;
  }
);

export const updateSessionSync = createAsyncThunk(
  'auth/session',
  async (userRole: UserRoleDto): Promise<LoginResponseSuccessDto> => {
    const response = await ApiService.updateSession(userRole);
    return response.data;
  }
);

export const getSessionSync = createAsyncThunk('auth/getSessionSync', async (): Promise<LoginResponseSuccessDto> => {
  const response = await ApiService.getSession();
  return response.data;
});

export const logoutSessionSync = createAsyncThunk('auth/logoutSession', async (): Promise<void> => {
  await ApiService.apiLogout();
});

export const getPeopleSync = createAsyncThunk('auth/getPeopleSync', async (): Promise<any> => {
  const response = await ApiService.getPeople({ profileStatus: true });
  return response.data;
});

export const updatePeopleSync = createAsyncThunk(
  'auth/updatePeopleSync',
  async (payload: PeoplePayload): Promise<any> => {
    const response = await ApiService.updatePeople({ payload });
    return response.data;
  }
);

export const recoveryAccountAsync = createAsyncThunk(
  'auth/recoveryAccount',
  async (body: IRecoveryAccountPayload): Promise<any> => {
    const response = await ApiService.recoveryAccount(body);
    return response.data;
  }
);
export const peopleAccountRecoveryAsync = createAsyncThunk(
  'auth/peopleAccountRecoveryAsync',
  async ({ payload, config }: { payload: any; config?: AxiosRequestConfig<any> }): Promise<any> => {
    const response = await ApiService.postPeopleAccountRecovery(payload, config);
    return response.data;
  }
);

export const patchVerificationCodeAsync = createAsyncThunk(
  'auth/patchVerificationCodeAsync',
  async ({ payload, enableTwoFA }: { payload: IRecoveryVerificationPayLoad; enableTwoFA: boolean }): Promise<any> => {
    let response = null;
    if (!enableTwoFA) {
      response = await ApiService.patchVerificationCode(payload);
    } else {
      response = await ApiService.patchVerification2FACode(payload);
    }
    return response.data;
  }
);
export const postRecoveryUpdateAccountAsync = createAsyncThunk(
  'auth/postRecoveryUpdateAccountAsync',
  async ({ payload }: { payload: IRecoveryUpdateAccountPayLoad }): Promise<any> => {
    const response = await ApiService.postRecoveryUpdateAccount(payload);
    return response.data;
  }
);
export const patchResendVerifyCodeAsync = createAsyncThunk(
  'auth/patchResendVerifyCodeAsync',
  async ({ payload }: { payload: { action: string } }): Promise<any> => {
    const response = await ApiService.patchResendCode(payload);
    return response.data;
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setMainPage: (state, action: PayloadAction<string>) => {
      state.mainPage = action.payload;
    },
    setRecoveryAccountType: (state, action) => {
      state.recoveryAccountType = action.payload;
    },
    setRecoveryAccountToken: (state, action) => {
      state.recoveryAccountToken = action.payload;
    },
    setRecoveryAccountNewEmail: (state, action) => {
      state.recoveryAccountNewEmail = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.loading = true;
        state.loginSuccess = false;
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.loading = false;
        if (
          !action.payload?.values?.role &&
          !action.payload?.values?.systemAdmin &&
          !action.payload?.values?.cpaAdmin &&
          !(action.payload?.id && action.payload?.methods)
        ) {
          state.loginSuccess = false;
          state.info = null;
          state.pictureUrl = '';
          state.lastLogin = '';
          state.error =
            'Oops! Your account doesn’t exist. Please contact Saveday Customer Help Center for further support!';
          removeAllCookie();
          state.mainPage = '/login';
        } else {
          state.loginSuccess = true;
          state.info = action.payload;
          setCookie(CookieNames.XCSSession, action.payload.id);
          if (action.payload?.methods) {
            return;
          }
          const path = AuthService.getPathAfterLoginSuccess(action.payload);
          if (Object.keys(action.meta.arg.reqParams).length) {
            setCookie(CookieNames.RequestParams, action.meta.arg.reqParams);
          }
          state.mainPage = path;
        }
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.loading = false;
        state.loginSuccess = false;
        state.error = JSON.parse(action.error.message as string).error;
        state.info = null;
        state.pictureUrl = '';
        state.lastLogin = '';
        removeAllCookie();
        state.mainPage = '/login';
      })
      .addCase(updateSessionSync.pending, (state) => {
        state.pathSuccess = false;
      })
      .addCase(updateSessionSync.fulfilled, (state, action) => {
        state.info = action.payload;
        state.pathSuccess = true;
        const { currentRole, currentCompanyId, companyIdAndUserProfile, values } =
          action.payload as LoginResponseSuccessDto;
        const selectRoleString = ROLES.SELECT_ROLE as string;
        const loginAs = getCookie(CookieNames.AdminLoginAsUser);
        if (loginAs) {
          if (loginAs === 'cpa' || loginAs === 'corp' || loginAs === 'pep') {
            state.mainPage = `/dashboard/employer`;
          } else if (loginAs === 'sys_cpa' || loginAs === 'sys_corp' || loginAs === 'sys_pep') {
            state.mainPage = `/${loginAs.replace('sys_', '')}/dashboard`;
          } else if (loginAs === 'employer') {
            state.mainPage = `/dashboard/${loginAs}`;
          } else {
            state.mainPage = `/dashboard/${loginAs}`;
          }
        } else {
          if (currentRole === selectRoleString) {
            setCookie(CookieNames.CurrentRole, ROLES.SELECT_ROLE);
            state.mainPage = '/dashboard/selectRole';
            setCookie(CookieNames.UserProfileStatus, action.payload.values.userProfileStatus || '');
          } else {
            const allRole: ICookie[] = AuthService.getAllRole(action.payload);
            let arrayCurrentRoles = allRole.filter((role) => role.currentRole === action.payload.currentRole);
            if (arrayCurrentRoles.length > 1) {
              arrayCurrentRoles = arrayCurrentRoles.filter(
                (role) => role.currentCompanyId === action.payload.currentCompanyId
              );
            }
            if (arrayCurrentRoles.length > 1) {
              arrayCurrentRoles = arrayCurrentRoles.filter(
                (role) => role.currentUserProfileId === action.payload.currentUserProfileId
              );
            }
            if (arrayCurrentRoles.length >= 1) {
              setCookie(CookieNames.CurrentCompanyId, action.payload.currentCompanyId || '');
              setCookie(CookieNames.CurrentRole, action.payload.currentRole);
              setCookie(CookieNames.SystemAdminRole, arrayCurrentRoles[0].systemAdminRole || '');
              setCookie(CookieNames.UserProfileId, arrayCurrentRoles[0].currentUserProfileId || '');
              setCookie(CookieNames.UserProfileStatus, action.payload.values.userProfileStatus || '');
              state.mainPage = arrayCurrentRoles[0].mainPage as string;
            } else {
              let arrayUserProfile = companyIdAndUserProfile[currentCompanyId || ''];
              const roleObject = {
                currentRole,
                currentCompanyId,
                systemAdminRole: values.systemAdminRole,
                currentUserProfileId: arrayUserProfile,
                mainPage:
                  currentRole === ROLES.COMPANY_ADMIN
                    ? '/dashboard/employer'
                    : currentRole === ROLES.PARTICIPANT
                    ? '/dashboard/employee'
                    : currentRole === ROLES.CPA_ADMIN
                    ? '/cpa/dashboard'
                    : currentRole === ROLES.CORP_ADMIN
                    ? '/corp/dashboard' // CORPORATE PLATFORM ADMIN
                    : '/pep/dashboard', // PEP_ADMIN
              };
              setCookie(CookieNames.CurrentCompanyId, roleObject.currentCompanyId || '');
              setCookie(CookieNames.CurrentRole, roleObject.currentRole);
              setCookie(CookieNames.SystemAdminRole, roleObject.systemAdminRole || '');
              setCookie(CookieNames.UserProfileId, roleObject.currentUserProfileId || '');
              setCookie(CookieNames.UserProfileStatus, values.userProfileStatus || '');
              state.mainPage = roleObject.mainPage as string;
            }
          }
        }
      })
      .addCase(updateSessionSync.rejected, (state, action) => {
        state.pathSuccess = false;
        state.error = JSON.parse(action.error.message as string).error;
        state.info = null;
        state.people = null;
        removeAllCookie();
        state.mainPage = '/login';
      })
      .addCase(getSessionSync.pending, (state) => {})
      .addCase(getSessionSync.fulfilled, (state, action) => {
        state.info = action.payload;
      })
      .addCase(getSessionSync.rejected, (state, action) => {
        state.success = true;
        state.info = null;
        state.people = null;
        removeAllCookie();
        state.mainPage = '/login';
      })
      .addCase(logoutSessionSync.pending, (state) => {})
      .addCase(logoutSessionSync.fulfilled, (state, action) => {
        state.success = true;
        state.info = null;
        state.people = null;
        removeAllCookie();
        state.mainPage = '/login';
      })
      .addCase(logoutSessionSync.rejected, (state, action) => {
        state.error = JSON.parse(action.error.message as string).error;
        state.info = null;
        state.people = null;
        removeAllCookie();
        state.mainPage = '/login';
      })
      .addCase(getPeopleSync.pending, (state) => {})
      .addCase(getPeopleSync.fulfilled, (state, action) => {
        state.people = action.payload;
        state.success = true;
        setCookie(CookieNames.PersonId, action.payload.id);
      })
      .addCase(updatePeopleSync.pending, (state) => {
        state.updatePeopleStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(updatePeopleSync.fulfilled, (state, action) => {
        state.people = action.payload;
        state.updatePeopleStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(resetPasswordAsync.pending, (state, action) => {
        state.resetPasswordStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(resetPasswordAsync.fulfilled, (state, action) => {
        state.resetPasswordStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(resetPasswordAsync.rejected, (state, action) => {
        state.resetPasswordStatus = REQUEST_STATUS.ERROR;
      })
      .addCase(createCredentialsAsync.pending, (state, action) => {
        state.createCredentialsStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(createCredentialsAsync.fulfilled, (state, action) => {
        state.createCredentialsStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(createCredentialsAsync.rejected, (state, action) => {
        state.createCredentialsStatus = REQUEST_STATUS.ERROR;
      })
      .addCase(recoveryEmailAsync.pending, (state, action) => {
        state.recoveryEmailStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(recoveryEmailAsync.fulfilled, (state, action) => {
        state.recoveryEmailStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(recoveryEmailAsync.rejected, (state, action) => {
        state.recoveryEmailStatus = REQUEST_STATUS.ERROR;
      })
      .addCase(peopleAccountRecoveryAsync.fulfilled, (state, action) => {
        state.recoveryAccountResponse = action.payload;
      })
      .addCase(patchVerificationCodeAsync.fulfilled, (state, action) => {
        state.verifiedAccountResponse = action.payload;
      })
      .addCase(patchResendVerifyCodeAsync.fulfilled, (state, action) => {
        state.verifiedAccountResponse = action.payload;
      });
  },
});

export const { setMainPage, setRecoveryAccountToken, setRecoveryAccountType, setRecoveryAccountNewEmail } =
  authSlice.actions;

export const selectAuthState = (state: RootState) =>
  !state.auth.info?.values
    ? {
        ...state.auth,
        info: getCookie<LoginResponseSuccessDto>(CookieNames.UserData),
        pictureUrl: getCookie<string>(CookieNames.PictureUrl),
        lastLogin: getCookie(CookieNames.LastLogin),
        isActiveEmployee: getCookie<boolean>(CookieNames.IsActiveEmployee),
      }
    : state.auth;
export const selectError = (state: RootState) => {
  return state.auth.error;
};

const authReducer = authSlice.reducer;
export default authReducer;
