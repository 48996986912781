import { Box, Stack, Typography } from "@mui/material";
import { GridColDef, GridSortModel } from "@mui/x-data-grid";
import { Button, Table } from "common/components";
import { downloadFile } from "core/functions/download-file";
import { formatCurrency } from "core/functions/format-currency";
import { formatPercentage } from "core/functions/format-percentage";
import { EmployeeStatus } from "core/enums/employee-status.enum";
import { EmployeeStatusReport } from "core/models/employerDashboardDto.model";
import EmployerService from "core/services/employer.service";
import { useEffect, useState } from "react";
import { selectAuthState } from "states/auth/authSlice";
import {
  getEmployeeStatusReportAsync,
  selectStatusReport,
} from "states/employerDashboard/employerDashboardSlice";
import { useAppDispatch, useAppSelector } from "states/hooks";
import { setErrorMessage } from "states/snackbarMessage/snackbarMessageSlice";

const reportColumns: GridColDef[] = [
  {
    field: "firstName",
    headerName: "First Name",
    flex: 1,
    align: "left",
    headerAlign: "left",
    minWidth: 100,
    filterable: false,
  },
  {
    field: "lastName",
    headerName: "Last Name",
    flex: 1,
    align: "center",
    headerAlign: "center",
    minWidth: 100,
    filterable: false,
  },
  {
    field: "accountBalance",
    headerName: "Account Balance",
    flex: 2,
    align: "center",
    headerAlign: "center",
    minWidth: 100,
    filterable: false,
    sortable: false,
  },
  {
    field: "tradDeferralRate",
    headerName: "Trad Deferral Rate",
    flex: 2,
    align: "center",
    headerAlign: "center",
    minWidth: 100,
    filterable: false,
    sortable: false,
    renderCell: ({ value }) => formatPercentage(value * 100),
  },
  {
    field: "rothDeferralRate",
    headerName: "Roth Deferral Rate",
    flex: 2,
    align: "center",
    headerAlign: "center",
    minWidth: 100,
    filterable: false,
    sortable: false,
    renderCell: ({ value }) => formatPercentage(value * 100),
  },
];
export default function ParticipantsTable() {
  const statusReportState = useAppSelector(selectStatusReport);
  const dispatch = useAppDispatch();
  const { info } = useAppSelector(selectAuthState);
  const onDownload = async () => {
    if (!info?.currentCompanyId) {
      dispatch(setErrorMessage("Company not found!"));
      return;
    }
    const timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timeZoneId) {
      const response = await EmployerService.employeeStatusReportDownload({
        companyId: info?.currentCompanyId,
        profileStatus: [EmployeeStatus.ACTIVE],
      });
      if (response.status === 200 && response.data?.content) {
        downloadCSV(atob(response.data?.content));
      }
    }
  };
  const downloadCSV = (data: BlobPart) => {
    let fileName = `Employee_census.csv`;
    let blob = new Blob([data], { type: "text/csv" });
    let url = window.URL.createObjectURL(blob);
    downloadFile(url, fileName);
  };
  const mappingDataForReportTable = (data: EmployeeStatusReport[]) => {
    return data.map((item) => ({
      id: item.userProfileId,
      firstName: item.firstName || "",
      lastName: item.lastName || "",
      status: item.status || "",
      accountBalance: formatCurrency(item.accountBalance || 0),
      tradDeferralRate: item.tradDeferralRate || 0,
      rothDeferralRate: item.rothDeferralRate || 0,
      planEnrolledOn: item.planEnrolledOn
        ? new Date(item.planEnrolledOn).getTime() > Date.now()
          ? new Date(item.planEnrolledOn).toLocaleDateString()
          : ""
        : "", // Convert timestamp to normal date
    }));
  };
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const initSort: GridSortModel = [
    {
      field: "firstName",
      sort: "asc",
    },
  ];
  const [sortModel, setSortModel] = useState<GridSortModel>(initSort);
  const [searchKey] = useState<string>("");

  useEffect(() => {
    if (info && info.currentCompanyId) {
      dispatch(
        getEmployeeStatusReportAsync({
          companyId: info.currentCompanyId,
          filter: {
            search: searchKey,
            order: sortModel.length ? sortModel[0].sort : "",
            field: sortModel.length ? sortModel[0].field : "",
            pageSize: paginationModel.pageSize,
            page: paginationModel.page,
            profileStatus: [EmployeeStatus.ACTIVE],
          },
        })
      );
    }
  }, [sortModel, paginationModel, searchKey]);
  return (
    <Box mx={1.5}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography
          fontWeight={600}
          fontSize={"12px"}
          lineHeight={"18px"}
          color={"#9E9E91"}
        >
          Active Participants
        </Typography>
        {(statusReportState.content?.length ?? 0) > 0 && (
          <Button
            onClick={onDownload}
            className="w-[104px] h-[32px]"
            sx={{
              fontWeight: 600,
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            Download
          </Button>
        )}
      </Stack>
      <Box mt={3}>
        <Table
          rows={
            statusReportState.content?.length
              ? mappingDataForReportTable(statusReportState.content)
              : []
          }
          columns={reportColumns}
          rowCount={statusReportState?.totalElements || -1}
          paginationMode="server"
          sortingMode="server"
          onPaginationModelChange={setPaginationModel}
          onSortModelChange={setSortModel}
          emptyLocaleText="No records found"
          sx={{
            "& .MuiDataGrid-cell": {
              fontWeight: 600,
              fontSize: "13px",
              lineHeight: "18px",
              color: "#222917",
              boxSizing: "none",
              border: "none",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              textOverflow: "clip",
              whiteSpace: "break-spaces",
              lineHeight: "16px",
              textAlign: "center",
              fontSize: "11px",
              color: "#181C11",
              fontWeight: 600,
            },
            "& .MuiDataGrid-row": {
              borderRadius: "8px",
              boxShadow: (theme) =>
                `inset 0 -5px 5px -5px ${theme.palette.borderColor}`,
            },
          }}
          initialState={{
            sorting: {
              sortModel: [
                { field: sortModel[0]?.field, sort: sortModel[0]?.sort },
              ],
            },
          }}
        />
      </Box>
    </Box>
  );
}
