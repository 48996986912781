import * as React from 'react';
import { TextField } from 'common/components';
import { NumericFormat, NumberFormatBaseProps } from 'react-number-format';

export const TextPercentage = React.forwardRef<HTMLInputElement, NumberFormatBaseProps<any>>(
  ({ ...restProps }, ref) => {
    return (
      <NumericFormat
        customInput={TextField}
        thousandSeparator={true}
        decimalScale={2}
        suffix={'%'}
        getInputRef={ref}
        {...restProps}
      />
    );
  }
);
