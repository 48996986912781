import { Box } from "@mui/material";
import * as React from "react";
import LiveChatIcon from "assets/SVGIcons/LiveChatIcon";

export default function ContactComponent() {
  return (
    <Box
      sx={{
        display: {
          xs: "none",
          sm: "block",
        },
      }}
    >
      <div className="flex flex-col gap-4 small-12 columns">
        <span className="info-item">
          <a
            href={"https://saveday.zendesk.com/hc/en-us"}
            className="inline-flex gap-1 items-center text-textLink hover:text-textLinkHover"
          >
            <span className="mr-3">
              <LiveChatIcon />
            </span>{" "}
            <span>
              Have questions or want to reach us? Visit our support center.
            </span>
          </a>
        </span>
      </div>
    </Box>
  );
}
