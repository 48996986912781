const CompareArrowIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8_16119)">
        <path
          d="M7.50833 12.1667H1.66667V13.8334H7.50833V16.3334L10.8333 13L7.50833 9.66669V12.1667ZM12.4917 11.3334V8.83335H18.3333V7.16669H12.4917V4.66669L9.16667 8.00002L12.4917 11.3334Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_8_16119">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CompareArrowIcon;
