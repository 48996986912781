import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import { REQUEST_STATUS } from 'core/constants/roles';
import { OnboardingEmployeeStages, OnboardingPlaidStatus, OnboardingStages } from 'core/enums';
import { BusinessResponse, PlanFormData, TransferPlanResponse } from 'core/models/PlanDetailDto.model';
import { Business, ComplianceDisclosureDto } from 'core/models/UploadFileDto.model';
import { CompanyDto, CreateNewEmployerDto } from 'core/models/companyDto.model';
import { OnboardingEmployeeCheckCredentialsPayload } from 'core/models/employeeOnboardingDto.model';
import { ClientCpaSignatureForm, OnboardingCheckCredentialsPayload } from 'core/models/employerOnboardingDto.model';
import { EmployeeSignUpDto } from 'core/models/loginDto.model';
import { DriverLicensesResponse } from 'core/models/peopleDto.model';
import { onboardingConnectPlaid } from 'core/services/api.service';
import EmployeeService from 'core/services/employee.service';
import EmployerService from 'core/services/employer.service';

interface IOnboardingState {
  companyDetails: CompanyDto | null;
  loading: boolean;
  getPlanDocLoading: boolean;
  getPdfTemplateLoading: boolean;
  transferPlanDetails: TransferPlanResponse | null;
  complianceDisclosure: any;
  plaidLinkToken: {
    expiration: number | null;
    linkToken: string | null;
  };
  checkCredentialStatus: REQUEST_STATUS;
  driverLicenses: DriverLicensesResponse[];
  person: {
    firstName: string;
    lastName: string;
    id: string;
    ssn: string;
  } | null;
  updateEmployerStatus: REQUEST_STATUS;
  updateOnboardingPlanTransferStatus: REQUEST_STATUS;
  getEmployerStatus: REQUEST_STATUS;
  multipleBusinesses: BusinessResponse[];
  grantPlaidPermissionStatus: REQUEST_STATUS;
  onboardingConnectPlaidStatus: REQUEST_STATUS;
  sendEmailIncompleteOnboardingStatus: REQUEST_STATUS;
  onboardingUpdateStageStatus: REQUEST_STATUS;
  getSessionByTokenStatus: REQUEST_STATUS;
  onboardingEmployeeSignOptOutFormStatus: REQUEST_STATUS;
  checkTokenValidStatus: REQUEST_STATUS;
  checkAllowOptOutByTokenStatus: REQUEST_STATUS;
}

const initialState: IOnboardingState = {
  companyDetails: null,
  loading: false,
  transferPlanDetails: null,
  plaidLinkToken: {
    expiration: null,
    linkToken: null,
  },
  getPlanDocLoading: false,
  getPdfTemplateLoading: false,
  checkCredentialStatus: REQUEST_STATUS.IDLE,
  driverLicenses: [],
  person: null,
  updateEmployerStatus: REQUEST_STATUS.IDLE,
  updateOnboardingPlanTransferStatus: REQUEST_STATUS.IDLE,
  getEmployerStatus: REQUEST_STATUS.IDLE,
  multipleBusinesses: [],
  complianceDisclosure: null,
  grantPlaidPermissionStatus: REQUEST_STATUS.IDLE,
  onboardingConnectPlaidStatus: REQUEST_STATUS.IDLE,
  sendEmailIncompleteOnboardingStatus: REQUEST_STATUS.IDLE,
  onboardingUpdateStageStatus: REQUEST_STATUS.IDLE,
  getSessionByTokenStatus: REQUEST_STATUS.IDLE,
  onboardingEmployeeSignOptOutFormStatus: REQUEST_STATUS.IDLE,
  checkTokenValidStatus: REQUEST_STATUS.IDLE,
  checkAllowOptOutByTokenStatus: REQUEST_STATUS.IDLE,
};

export const updateEmployerAsync = createAsyncThunk(
  'onboarding/employer/updateEmployerAsync',
  async ({
    request,
    filter,
    config,
  }: {
    request: any;
    filter?: any;
    config?: AxiosRequestConfig<any>;
  }): Promise<any> => {
    const response = await EmployerService.updateEmployer(request, filter, config);
    return response;
  }
);

export const updateOnboardingCompanyAsync = createAsyncThunk(
  'onboarding/employer/updateOnboardingCompanyAsync',
  async ({ request, filter }: { request: any; filter?: any }): Promise<any> => {
    const response = await EmployerService.updateOnboardingCompany(request, filter);
    return response;
  }
);

export const updateAlternativeBankAsync = createAsyncThunk(
  'onboarding/employer/updateOnboardingCompanyAsync',
  async ({ companyId, payload, filter }: { companyId: string; payload: any; filter?: any }): Promise<any> => {
    const response = await EmployerService.updateAlternativeBank(companyId, payload, filter);
    return response;
  }
);

export const getEmployerAsync = createAsyncThunk(
  'onboarding/employer/getEmployerAsync',
  async ({ filter }: { filter?: any }): Promise<any> => {
    const response = await EmployerService.getEmployer({ filter });
    return response.data;
  }
);

export const createNewEmployerAsync = createAsyncThunk(
  'onboarding/employer/createNewEmployerAsync',
  async (params: { payload: CreateNewEmployerDto; config?: AxiosRequestConfig<any> }): Promise<any> => {
    const { payload, config } = params;
    const response = await EmployerService.createEmployer(payload, config);
    return response.data;
  }
);

export const employeeSignUpAsync = createAsyncThunk(
  'onboarding/employee/employeeSignUpAsync',
  async (data: {
    payload: EmployeeSignUpDto;
    params: { token: string; filter?: any };
    config?: AxiosRequestConfig<any>;
  }): Promise<any> => {
    const { payload, params, config } = data;
    const response = await EmployeeService.employeeSignUp(payload, params, config);
    return response.data;
  }
);

export const employeeSignUpWithoutTokenAsync = createAsyncThunk(
  'onboarding/employee/employeeSignUpWithoutTokenAsync',
  async (data: { payload: EmployeeSignUpDto; companyId: string; config?: AxiosRequestConfig<any> }): Promise<any> => {
    const { payload, config, companyId } = data;
    const response = await EmployeeService.employeeSignUpWithoutToken(payload, companyId, config);
    return response.data;
  }
);

export const createComplianceDisclosuresAsync = createAsyncThunk(
  'onboarding/employer/createComplianceDisclosuresAsync',
  async (data: { payload: { complianceDisclosure: ComplianceDisclosureDto } }): Promise<any> => {
    const { payload } = data;
    const response = await EmployerService.createOnboardingComplianceDisclosure(payload);
    return response.data;
  }
);

export const updateComplianceDisclosureAsync = createAsyncThunk(
  'onboarding/employer/updateComplianceDisclosureAsync',
  async (data: {
    payload: {
      complianceDisclosure: ComplianceDisclosureDto;
      stage: OnboardingStages;
    };
  }): Promise<any> => {
    const { payload } = data;
    const response = await EmployerService.updateOnboardingComplianceDisclosure(payload);
    return response.data;
  }
);

export const getMultipleBusinessesAsync = createAsyncThunk(
  'onboarding/employer/getMultipleBusinessesAsync',
  async (): Promise<any> => {
    const response = await EmployerService.getMultipleBusinesses();
    return response.data;
  }
);

export const createMultipleBusinessesAsync = createAsyncThunk(
  'onboarding/employer/createMultipleBusinessesAsync',
  async (data: { payload: { businesses: Business[]; stage: OnboardingStages } }): Promise<any> => {
    const { payload } = data;
    const response = await EmployerService.createMultipleBusinesses(payload);
    return response.data;
  }
);

export const updateMultipleBusinessesAsync = createAsyncThunk(
  'onboarding/employer/updateMultipleBusinessesAsync',
  async (data: { payload: { businesses: Business[]; stage: OnboardingStages } }): Promise<any> => {
    const { payload } = data;
    const response = await EmployerService.updateMultipleBusinesses(payload);
    return response.data;
  }
);

export const checkTokenValidAsync = createAsyncThunk(
  'onboarding/employee/checkTokenValidAsync',
  async (token: string): Promise<any> => {
    const response = await EmployeeService.checkTokenValid(token);
    return response.data;
  }
);

export const getSessionByTokenAsync = createAsyncThunk(
  'onboarding/employee/getSessionByTokenAsync',
  async (token: string): Promise<any> => {
    const response = await EmployeeService.getSessionByToken(token);
    return response.data;
  }
);

export const checkAllowOptOutByTokenAsync = createAsyncThunk(
  'onboarding/employee/checkAllowOptOutByTokenAsync',
  async (token: string): Promise<any> => {
    const response = await EmployeeService.checkAllowOptOutByToken(token);
    return response.data;
  }
);

export const createPlanAsync = createAsyncThunk(
  'onboarding/employer/createPlanAsync',
  async (data: { plan: PlanFormData; stage: OnboardingStages }): Promise<any> => {
    const response = await EmployerService.createOnboardingPlan(data);
    return response.data;
  }
);

export const updatePlanAsync = createAsyncThunk(
  'onboarding/plan/updatePlanAsync',
  async (data: { plan: PlanFormData; stage: OnboardingStages }): Promise<any> => {
    const response = await EmployerService.updateOnboardingPlan(data);
    return response.data;
  }
);

export const getPlaidLinkTokenAsync = createAsyncThunk(
  'onboarding/employer/getPlaidLinkTokenAsync',
  async ({ filter }: { filter?: any }): Promise<any> => {
    const response = await EmployerService.getPlaidLinkToken({ filter });
    return response.data;
  }
);

export const grantPlaidPermissionAsync = createAsyncThunk(
  'onboarding/employer/grantPlaidPermissionAsync',
  async (payload: {
    publicToken: string;
    institutionId: string;
    institutionName: string;
    accountId: string;
  }): Promise<any> => {
    const response = await EmployerService.grantPlaidPermission(payload);
    return response.data;
  }
);

export const grantOnboardingPlaidPermissionAsync = createAsyncThunk(
  'onboarding/employer/grantOnboardingPlaidPermissionAsync',
  async ({
    payload,
  }: {
    payload: {
      plaid: {
        publicToken: string;
        institutionId: string;
        institutionName: string;
        accountId: string;
      };
      stage: OnboardingStages;
    };
  }): Promise<any> => {
    const response = await EmployerService.grantOnboardingPlaidPermission({
      payload,
    });
    return response.data;
  }
);

export const sendEmailIncompleteOnboardingAsync = createAsyncThunk(
  'onboarding/employer/sendEmailIncompleteOnboardingAsync',
  async ({ companyId }: { companyId: string }) => {
    const response = await EmployerService.sendEmailIncompleteOnboarding({
      companyId,
    });
    return response.data;
  }
);

export const convertFTWilliamAsync = createAsyncThunk(
  'onboarding/employer/convertFTWilliamAsync',
  async ({ filter }: { filter: any }): Promise<any> => {
    const response = await EmployerService.convertFTWilliam({
      filter,
    });
    return response.data;
  }
);

export const updateTemplateSignAsync = createAsyncThunk(
  'onboarding/employer/updateTemplateSignAsync',
  async ({
    payload,
    filter,
  }: {
    payload: {
      signature: string;
      tpaAgreement: boolean;
      erisa338InvestmentManagementAgreement: boolean;
      trustAgreement?: boolean;
    };
    filter?: any;
  }): Promise<any> => {
    const response = await EmployerService.updateTemplateSign({
      payload,
      filter,
    });
    return response.data;
  }
);

export const getPDFDocsAsync = createAsyncThunk(
  'onboarding/employer/getPDFDocsAsync',
  async ({ filter }: { filter?: any }): Promise<any> => {
    const response = await EmployerService.getPdfDocs({ filter });
    return response.data;
  }
);

export const storeSignPlanDocumentAsync = createAsyncThunk(
  'onboarding/employer/storeSignPlanDocumentAsync',
  async ({
    payload,
    companyId,
    filter,
  }: {
    payload: { signature: string };
    companyId: string;
    filter: any;
  }): Promise<any> => {
    const response = await EmployerService.storeSignPlanDocument({
      payload,
      companyId,
      filter,
    });
    return response.data;
  }
);

export const cpaClientStoreSignatureAsync = createAsyncThunk(
  'onboarding/employer/cpaClientStoreSignatureAsync',
  async ({ payload, token }: { payload: ClientCpaSignatureForm; token: string }): Promise<any> => {
    const response = await EmployerService.cpaClientStoreSignature({
      payload,
      token,
    });
    return response.data;
  }
);

export const onboardingCheckCredentialsAsync = createAsyncThunk(
  'onboarding/employer/onboardingCheckCredentialsAsync',
  async ({ payload }: { payload: OnboardingCheckCredentialsPayload }): Promise<any> => {
    const response = await EmployerService.onboardingCheckCredentials({
      payload,
    });
    return response.data;
  }
);

export const onboardingUpdateStageAsync = createAsyncThunk(
  'onboarding/employer/onboardingUpdateStageAsync',
  async ({ payload }: { payload: { stage: OnboardingStages } }): Promise<any> => {
    const response = await EmployerService.updateOnboardingStage({
      ...payload,
    });
    return response.data;
  }
);

export const onboardingEmployeeCheckCredentialsAsync = createAsyncThunk(
  'onboarding/employer/onboardingEmployeeCheckCredentialsAsync',
  async ({ payload, filter }: { payload: OnboardingEmployeeCheckCredentialsPayload; filter?: any }): Promise<any> => {
    const response = await EmployeeService.onboardingCheckCredentials({
      payload,
      filter,
    });
    return response.data;
  }
);

export const onboardingEmployeeUpdateStageAsync = createAsyncThunk(
  'onboarding/employee/onboardingEmployeeUpdateStageAsync',
  async ({ payload }: { payload: { stage: OnboardingEmployeeStages } }): Promise<any> => {
    const response = await EmployeeService.updateOnboardingStage({
      ...payload,
    });
    return response.data;
  }
);

export const onboardingEmployeeSignOptOutFormAsync = createAsyncThunk(
  'onboarding/employee/onboardingEmployeeSignOptOutFormAsync',
  async ({ payload, sessionId }: { payload: any; sessionId: string }): Promise<any> => {
    const response = await EmployeeService.signOptOutForm(payload, sessionId);
    return response.data;
  }
);

export const onboardingConnectPlaidAsync = createAsyncThunk(
  'onboarding/employer/onboardingConnectPlaidAsync',
  async ({ payload }: { payload: { plaidStatus: OnboardingPlaidStatus } }): Promise<any> => {
    const response = await onboardingConnectPlaid({
      payload,
    });
    return response.data;
  }
);

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updatePlanAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePlanAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updatePlanAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateOnboardingCompanyAsync.pending, (state) => {
        state.loading = true;
        state.updateEmployerStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(updateOnboardingCompanyAsync.fulfilled, (state) => {
        state.loading = false;
        state.updateEmployerStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(updateOnboardingCompanyAsync.rejected, (state) => {
        state.loading = false;
        state.updateEmployerStatus = REQUEST_STATUS.ERROR;
      })
      .addCase(createNewEmployerAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createNewEmployerAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createNewEmployerAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateEmployerAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateEmployerAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateEmployerAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(employeeSignUpAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(employeeSignUpAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(employeeSignUpAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(employeeSignUpWithoutTokenAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(employeeSignUpWithoutTokenAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(employeeSignUpWithoutTokenAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createPlanAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createPlanAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(createPlanAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getPlaidLinkTokenAsync.pending, (state) => {
        state.loading = true;
        state.plaidLinkToken = initialState.plaidLinkToken;
      })
      .addCase(getPlaidLinkTokenAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(getPlaidLinkTokenAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.plaidLinkToken = action.payload;
      })
      .addCase(grantPlaidPermissionAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(grantPlaidPermissionAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(grantPlaidPermissionAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(sendEmailIncompleteOnboardingAsync.pending, (state) => {
        state.sendEmailIncompleteOnboardingStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(sendEmailIncompleteOnboardingAsync.rejected, (state) => {
        state.sendEmailIncompleteOnboardingStatus = REQUEST_STATUS.ERROR;
      })
      .addCase(sendEmailIncompleteOnboardingAsync.fulfilled, (state) => {
        state.sendEmailIncompleteOnboardingStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(convertFTWilliamAsync.pending, (state) => {
        state.getPlanDocLoading = true;
      })
      .addCase(convertFTWilliamAsync.rejected, (state) => {
        state.getPlanDocLoading = false;
      })
      .addCase(convertFTWilliamAsync.fulfilled, (state) => {
        state.getPlanDocLoading = false;
      })
      .addCase(getPDFDocsAsync.pending, (state) => {
        state.getPdfTemplateLoading = true;
      })
      .addCase(getPDFDocsAsync.rejected, (state) => {
        state.getPdfTemplateLoading = false;
      })
      .addCase(getPDFDocsAsync.fulfilled, (state) => {
        state.getPdfTemplateLoading = false;
      })
      .addCase(storeSignPlanDocumentAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(storeSignPlanDocumentAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(storeSignPlanDocumentAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(cpaClientStoreSignatureAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(cpaClientStoreSignatureAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(cpaClientStoreSignatureAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateTemplateSignAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateTemplateSignAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateTemplateSignAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(onboardingCheckCredentialsAsync.pending, (state) => {
        state.loading = true;
        state.checkCredentialStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(onboardingCheckCredentialsAsync.rejected, (state) => {
        state.loading = false;
        state.checkCredentialStatus = REQUEST_STATUS.ERROR;
      })
      .addCase(onboardingCheckCredentialsAsync.fulfilled, (state) => {
        state.loading = false;
        state.checkCredentialStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(getEmployerAsync.pending, (state) => {
        state.loading = true;
        state.getEmployerStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(getEmployerAsync.rejected, (state) => {
        state.loading = false;
        state.getEmployerStatus = REQUEST_STATUS.ERROR;
      })
      .addCase(getEmployerAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.transferPlanDetails = action.payload.planTransfer;
        state.complianceDisclosure = action.payload.complianceDisclosure;
        state.driverLicenses = action.payload.driverLicenses;
        state.person = action.payload.person;
        state.getEmployerStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(createComplianceDisclosuresAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createComplianceDisclosuresAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createComplianceDisclosuresAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateComplianceDisclosureAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateComplianceDisclosureAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateComplianceDisclosureAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(getMultipleBusinessesAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMultipleBusinessesAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getMultipleBusinessesAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.multipleBusinesses = action.payload;
      })
      .addCase(createMultipleBusinessesAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createMultipleBusinessesAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(createMultipleBusinessesAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(updateMultipleBusinessesAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateMultipleBusinessesAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateMultipleBusinessesAsync.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(onboardingUpdateStageAsync.pending, (state) => {
        state.onboardingUpdateStageStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(onboardingUpdateStageAsync.rejected, (state) => {
        state.onboardingUpdateStageStatus = REQUEST_STATUS.ERROR;
      })
      .addCase(onboardingUpdateStageAsync.fulfilled, (state, action) => {
        state.onboardingUpdateStageStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(onboardingConnectPlaidAsync.pending, (state) => {
        state.loading = true;
        state.onboardingConnectPlaidStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(onboardingConnectPlaidAsync.rejected, (state) => {
        state.loading = false;
        state.onboardingConnectPlaidStatus = REQUEST_STATUS.ERROR;
      })
      .addCase(onboardingConnectPlaidAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.onboardingConnectPlaidStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(onboardingEmployeeCheckCredentialsAsync.pending, (state) => {
        state.loading = true;
        state.checkCredentialStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(onboardingEmployeeCheckCredentialsAsync.fulfilled, (state) => {
        state.loading = false;
        state.checkCredentialStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(onboardingEmployeeCheckCredentialsAsync.rejected, (state) => {
        state.loading = false;
        state.checkCredentialStatus = REQUEST_STATUS.ERROR;
      })
      .addCase(grantOnboardingPlaidPermissionAsync.pending, (state) => {
        state.grantPlaidPermissionStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(grantOnboardingPlaidPermissionAsync.fulfilled, (state) => {
        state.grantPlaidPermissionStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(grantOnboardingPlaidPermissionAsync.rejected, (state) => {
        state.grantPlaidPermissionStatus = REQUEST_STATUS.ERROR;
      })
      .addCase(getSessionByTokenAsync.pending, (state) => {
        state.getSessionByTokenStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(getSessionByTokenAsync.fulfilled, (state) => {
        state.getSessionByTokenStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(getSessionByTokenAsync.rejected, (state) => {
        state.getSessionByTokenStatus = REQUEST_STATUS.ERROR;
      })
      .addCase(onboardingEmployeeSignOptOutFormAsync.pending, (state) => {
        state.onboardingEmployeeSignOptOutFormStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(onboardingEmployeeSignOptOutFormAsync.fulfilled, (state) => {
        state.onboardingEmployeeSignOptOutFormStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(onboardingEmployeeSignOptOutFormAsync.rejected, (state) => {
        state.onboardingEmployeeSignOptOutFormStatus = REQUEST_STATUS.ERROR;
      })
      .addCase(checkTokenValidAsync.pending, (state) => {
        state.checkTokenValidStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(checkTokenValidAsync.fulfilled, (state) => {
        state.checkTokenValidStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(checkTokenValidAsync.rejected, (state) => {
        state.checkTokenValidStatus = REQUEST_STATUS.ERROR;
      })
      .addCase(checkAllowOptOutByTokenAsync.pending, (state) => {
        state.checkAllowOptOutByTokenStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(checkAllowOptOutByTokenAsync.fulfilled, (state) => {
        state.checkAllowOptOutByTokenStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(checkAllowOptOutByTokenAsync.rejected, (state) => {
        state.checkAllowOptOutByTokenStatus = REQUEST_STATUS.ERROR;
      });
  },
});

export default onboardingSlice.reducer;
