import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  EmployerContributionLogsResponse,
  CheckAllCompletedResponse,
  EmployerLoanLogsResponse,
} from 'core/models/employerContributionLogsDto.model';
import EmployerService, {
  deleteNotification,
  getContributionLogs,
  getNotifications,
  readNotification,
  setStatusContributionLogs,
} from 'core/services/employer.service';

interface IEmployerInboxState {
  setStatusContributionLogsStatus: number;
  contributionTasks: EmployerContributionLogsResponse;
  allCompleted: CheckAllCompletedResponse;
  loanTasks: EmployerLoanLogsResponse;
  getNotificationStatus: number;
  deleteNotificationStatus: number;
  readNotificationStatus: number;
  notifications: any;
  notificationDetails: any;
  loading: boolean;
  getLoanTasksStatus: number;
}

export interface IContributionLogsParams {
  companyId: string;
  logId: string;
  status: string;
  pageSize: number;
  page: number;
  search: string;
  sortModel: GridSortModel;
}

export interface ILogsCheckParams {
  companyId: string;
}

export interface INotificationParams {
  notificationId: string;
  pageSize: number;
  page: number;
  field: string;
  sort: string;
}

export const getContributionLogsAsync = createAsyncThunk(
  'employerInbox/getContributionLogsAsync',
  async (params: IContributionLogsParams): Promise<EmployerContributionLogsResponse> => {
    const response = await getContributionLogs(params);
    return response;
  }
);

export const setStatusContributionLogsAsync = createAsyncThunk(
  'employerInbox/setStatusContributionLogsAsync',
  async ({ logId, status }: IContributionLogsParams): Promise<any> => {
    const response = await setStatusContributionLogs(logId, status);
    return response;
  }
);

export const getNotificationAsync = createAsyncThunk(
  'employerInbox/getNotificationAsync',
  async ({ filter }: { filter: any }): Promise<any> => {
    const response = await getNotifications({ filter });
    return response.data;
  }
);

export const deleteNotificationAsync = createAsyncThunk(
  'employerInbox/deleteNotificationAsync',
  async ({ notificationId }: INotificationParams): Promise<any> => {
    const response = await deleteNotification(notificationId);
    return response;
  }
);

export const readNotificationAsync = createAsyncThunk(
  'employerInbox/readNotificationAsync',
  async ({ notificationId }: INotificationParams): Promise<any> => {
    const response = await readNotification(notificationId);
    return response;
  }
);

export const getLoanTasksAsync = createAsyncThunk(
  'employerInbox/getLoanTasksAsync',
  async ({ companyId, filter }: { companyId: string; filter?: any }): Promise<any> => {
    const response = await EmployerService.getLoanTasks({ companyId, filter });
    return response.data;
  }
);

export const updateLoanStatusAsync = createAsyncThunk(
  'employerInbox/updateLoanStatusAsync',
  async ({ filter }: { filter: any }): Promise<any> => {
    const response = await EmployerService.updateLoanStatus({ filter });
    return response.data;
  }
);

const initialState: IEmployerInboxState = {
  loading: false,
  setStatusContributionLogsStatus: -1,
  allCompleted: {
    allCompleted: false,
  },
  contributionTasks: {
    content: [],
    totalElements: 0,
    empty: false,
    first: false,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    totalPages: 0,
    pageable: {
      sort: {
        empty: false,
        unsorted: false,
        sorted: false,
      },
      offset: 0,
      pageNumber: 0,
      pageSize: 0,
      paged: false,
      unpaged: false,
    },
    sort: {
      empty: false,
      unsorted: false,
      sorted: false,
    },
  },
  getNotificationStatus: -1,
  deleteNotificationStatus: -1,
  readNotificationStatus: -1,
  getLoanTasksStatus: -1,
  notifications: null,
  notificationDetails: null,
  loanTasks: {
    content: [],
    totalElements: 0,
    empty: false,
    first: false,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    totalPages: 0,
    pageable: {
      sort: {
        empty: false,
        unsorted: false,
        sorted: false,
      },
      offset: 0,
      pageNumber: 0,
      pageSize: 0,
      paged: false,
      unpaged: false,
    },
    sort: {
      empty: false,
      unsorted: false,
      sorted: false,
    },
  },
};

const employeeInboxSlice = createSlice({
  name: 'employerInbox',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getContributionLogsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getContributionLogsAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.contributionTasks = action.payload;
      })
      .addCase(getContributionLogsAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(setStatusContributionLogsAsync.pending, (state) => {
        state.setStatusContributionLogsStatus = -1;
      })
      .addCase(setStatusContributionLogsAsync.fulfilled, (state, action) => {
        state.setStatusContributionLogsStatus = 1;
        state.contributionTasks = {
          ...state.contributionTasks,
          totalElements: state.contributionTasks.totalElements - 1,
          content: state.contributionTasks.content.filter((log) => log.id !== action.meta.arg.logId),
        };
      })
      .addCase(setStatusContributionLogsAsync.rejected, (state, action) => {
        state.setStatusContributionLogsStatus = 0;
      })
      .addCase(getNotificationAsync.pending, (state) => {
        state.getNotificationStatus = -1;
      })
      .addCase(getNotificationAsync.fulfilled, (state, action) => {
        state.getNotificationStatus = 1;
        state.notifications = action.payload;
      })
      .addCase(getNotificationAsync.rejected, (state, action) => {
        state.getNotificationStatus = 0;
      })
      .addCase(deleteNotificationAsync.pending, (state) => {
        state.deleteNotificationStatus = -1;
      })
      .addCase(deleteNotificationAsync.fulfilled, (state, action) => {
        state.deleteNotificationStatus = 1;
        state.notifications = {
          ...state.notifications,
          totalElements: state.notifications.totalElements - 1,
          content: state.notifications.content.filter(
            (notification: any) => notification.id !== action.meta.arg.notificationId
          ),
        };
      })
      .addCase(deleteNotificationAsync.rejected, (state, action) => {
        state.deleteNotificationStatus = 0;
      })
      .addCase(readNotificationAsync.pending, (state) => {
        state.readNotificationStatus = -1;
      })
      .addCase(readNotificationAsync.fulfilled, (state, action) => {
        state.readNotificationStatus = 1;
        state.notificationDetails = action.payload;
      })
      .addCase(readNotificationAsync.rejected, (state, action) => {
        state.readNotificationStatus = 0;
      })
      .addCase(getLoanTasksAsync.rejected, (state, action) => {
        state.getLoanTasksStatus = 0;
      })
      .addCase(getLoanTasksAsync.pending, (state, action) => {
        state.getLoanTasksStatus = -1;
      })
      .addCase(getLoanTasksAsync.fulfilled, (state, action) => {
        state.getLoanTasksStatus = 1;
        state.loanTasks = action.payload;
      });
  },
});

export default employeeInboxSlice.reducer;
