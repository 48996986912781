import { FC, useEffect, useMemo, useState } from "react";
import TotalYTDToggleButton from "./TotalYTDToggleButton";
import { Box, Card, Stack, Typography, Grid, Paper } from "@mui/material";
import {
  ResponsiveContainer,
  Tooltip as ChartTooltip,
  Bar,
  BarChart,
  XAxis,
  YAxis,
} from "recharts";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import { Tooltip as MuiTooltip } from "common/components";
import _ from "lodash";
import { formatCurrency } from "core/services/utils/dashboardUtils";
import { selectAuthState } from "states/auth/authSlice";
import {
  selectCashBalance,
  getCashBalanceAsync,
} from "states/employerDashboard/employerDashboardSlice";
import { useAppDispatch, useAppSelector } from "states/hooks";
import { CashBalanceDto } from "core/models/employerDashboardDto.model";

const labels = [
  {
    key: "employer",
    label: "Employer Contributions",
    tooltip: `The money that you as the employer has contributed to the account, which can include matching contributions or profit-sharing contributions.`,
    color: "#69AB00",
  },
  {
    key: "employee",
    label: "Employee Contributions",
    tooltip: `Contributions made by your employees from their salary to their 401(k) account`,
    color: "#3B471C",
  },
];

const CustomTooltip = ({ active, payload, currentHover }: any) => {
  if (
    active &&
    payload &&
    payload.length &&
    payload.find((f: any) => f.dataKey === currentHover)
  ) {
    return (
      <Paper elevation={6}>
        <div
          className="flex items-center justify-center mx-1"
          style={{
            backgroundColor: "#fff",
            borderRadius: "4px",
            minHeight: "24px",
            minWidth: "45px",
          }}
        >
          <Typography fontWeight={600} fontSize={"10px"} lineHeight={"16px"}>
            {`${formatCurrency(
              payload.find((f: any) => f.dataKey === currentHover)?.value
            )}`}
          </Typography>
        </div>
      </Paper>
    );
  }

  return null;
};

const safeCast = (source?: number) => {
  return source || 0;
};
const mappingData = (cash: CashBalanceDto) => {
  return {
    allTime: [
      {
        employer: Math.max(0, safeCast(cash?.allTimeEmployerContribution)),
        employee: Math.max(0, safeCast(cash?.allTimeEmployeeContribution)),
      },
    ],
    ytd: [
      {
        employer: Math.max(0, safeCast(cash?.ytdEmployerContribution)),
        employee: Math.max(0, safeCast(cash?.ytdEmployeeContribution)),
      },
    ],
  };
};
const RenderTitle = () => {
  return (
    <Typography
      sx={{
        fontSize: "12px",
        fontWeight: 600,
        color: "beige",
        lineHeight: "16px",
      }}
      gutterBottom
    >
      Total Contributions
      <MuiTooltip
        title="The total amount of money that has been deposited into the account by both the employee and the employer (if there are employer contributions or matching contributions)."
        arrow
      >
        <InfoOutlinedIcon
          className="w-3"
          sx={{ color: "#9E9E91", marginLeft: "4px" }}
        />
      </MuiTooltip>
    </Typography>
  );
};
const RenderLegend: FC<{ data: any[] }> = ({ data }) => {
  if (!data?.length) return null;
  const keys = Object.keys(data[0]);
  return (
    <Stack direction={"row"} justifyContent={"space-evenly"}>
      {keys.map((key: string) => {
        const item = labels.find((lb) => lb.key === key);
        const color = item?.color || "#000";
        const label = item?.label;
        const tooltip = item?.tooltip;
        return (
          <p key={key}>
            {keys.length > 1 && (
              <CircleIcon
                style={{
                  color: color,
                  fontSize: "8px",
                  marginRight: "2px",
                }}
              />
            )}
            <span
              style={{
                color: "#2E3721",
                fontWeight: 500,
                fontSize: "10px",
                lineHeight: "15px",
                marginRight: "2px",
              }}
            >
              {label}
            </span>
            {tooltip && (
              <MuiTooltip
                title={tooltip}
                slotProps={{
                  popper: {
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, -12],
                        },
                      },
                    ],
                  },
                }}
              >
                <InfoOutlinedIcon className="w-3" sx={{ color: "#9E9E91" }} />
              </MuiTooltip>
            )}
          </p>
        );
      })}
    </Stack>
  );
};
export default function TotalContributionsChart() {
  const [subType, setSubType] = useState("total");
  const [currentHover, setCurrentHover] = useState("");
  const dispatch = useAppDispatch();
  const { info } = useAppSelector(selectAuthState);
  const cashBalanceState = useAppSelector(selectCashBalance);
  const [data, setData] = useState<any>();
  const [chart, setChart] = useState<any>();
  const fetchData = () => {
    if (info && info.currentCompanyId) {
      dispatch(getCashBalanceAsync(info.currentCompanyId));
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (cashBalanceState) {
      setData(mappingData(cashBalanceState));
      setChart(subType === "total" ? data?.allTime : data?.ytd);
    }
  }, [cashBalanceState]);

  useEffect(() => {
    if (subType) {
      setChart(subType === "total" ? data?.allTime : data?.ytd);
    }
  }, [subType]);

  const renderRadius = (key: string): [number, number, number, number] => {
    let radius: [number, number, number, number] = [0, 0, 0, 0];
    const line = chart?.length > 0 ? chart[0] : undefined;
    if (key === "employee") {
      if (line?.employee) {
        radius = [0, 25, 25, 0];
      }
      if (!line?.employer) {
        radius = [25, 25, 25, 25];
      }
    }
    if (key === "employer") {
      if (line?.employer) {
        radius = [25, 0, 0, 25];
      }
      if (!line?.employee) {
        radius = [25, 25, 25, 25];
      }
    }
    return radius;
  };
  const totalContribution = useMemo(() => {
    return (
      (subType === "total"
        ? cashBalanceState?.allTimeTotalContribution
        : cashBalanceState?.ytdTotalContribution) || 0
    );
  }, [subType, cashBalanceState]);

  return (
    <Card
      sx={{
        maxWidth: "100%",
        border: "none",
      }}
      variant="outlined"
    >
      <Grid container>
        <Grid container item zero={12} mt={0.5} mb={0.5}>
          <RenderTitle />
        </Grid>
        <Grid item zero={12}>
          <Box
            sx={{
              borderRadius: "8px 40px 40px 8px",
              minHeight: "64px",
              maxWidth: "100%",
              backgroundColor: "#9E9E9114",
            }}
          >
            <Stack
              direction={{ zero: "column", mobile414: "row" }}
              justifyContent={"space-between"}
            >
              <Box mt={3} ml={2}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "25px",
                    lineHeight: "16px",
                  }}
                >
                  {formatCurrency(totalContribution)}
                </Typography>
              </Box>
              <Box
                mt={2.5}
                ml={{ zero: 2, mobile414: 0 }}
                mb={{ zero: 2, mobile414: 0 }}
                mr={{ zero: 0, mobile414: 2.5 }}
              >
                <TotalYTDToggleButton
                  value={subType}
                  onChange={(val) => setSubType(val)}
                />
              </Box>
            </Stack>
          </Box>
        </Grid>
        <Grid item zero={12}>
          <Box mt={3} ml={1}>
            <ResponsiveContainer width="100%" height="99%" minHeight={"30px"}>
              <BarChart layout="vertical" data={chart} stackOffset="expand">
                <XAxis hide type="number" />
                <YAxis type="category" hide />

                <ChartTooltip
                  cursor={{ fill: "transparent" }}
                  wrapperStyle={{
                    zIndex: 1,
                    marginTop: 10,
                    border: "none",
                    outline: "none",
                  }}
                  content={<CustomTooltip currentHover={currentHover} />}
                />

                <Bar
                  dataKey="employer"
                  fill={labels.find((f) => f.key === "employer")?.color}
                  stackId="solo"
                  barSize={16}
                  onMouseOver={() => setCurrentHover("employer")}
                  radius={renderRadius("employer")}
                />
                <Bar
                  dataKey="employee"
                  fill={labels.find((f) => f.key === "employee")?.color}
                  stackId="solo"
                  barSize={16}
                  onMouseOver={() => setCurrentHover("employee")}
                  radius={renderRadius("employee")}
                />
              </BarChart>
            </ResponsiveContainer>
          </Box>
        </Grid>
        <Grid item zero={12}>
          <RenderLegend data={chart} />
        </Grid>
      </Grid>
    </Card>
  );
}
