import { Button, Card, CardActions, CardContent, Grid, Stack, useMediaQuery } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { TEmployeeEditBeneficiaryV2Form } from '.';
import { FormControl, Select, TextField, TextFieldWithMask, TextPercentage, TextFieldSSN } from 'common/components';
import { REGEX_PATTERN } from 'core/constants';
import { STATE_DATA } from 'core/constants/state-data';
import DatePicker from 'common/components/DatePickerNew';

type TProps = {
  form: UseFormReturn<TEmployeeEditBeneficiaryV2Form>;
  index: number;
  type: 'primaryBeneficiary' | 'secondaryBeneficiary';
  onRemoveClick: (index: number) => void;
};

const RELATIONSHIP: { value: string; label: string }[] = [
  { value: 'SPOUSE', label: 'Spouse' },
  { value: 'CHILD', label: 'Child' },
  { value: 'OTHER_FAMILY_MEMBER', label: 'Other Family Member' },
  { value: 'TRUST', label: 'Trust' },
  { value: 'OTHER_ENTITY', label: 'Other Entity' },
];

const BeneficiaryInfo: FC<TProps> = ({ form, index, type, onRemoveClick }) => {
  const archorRef = useRef<HTMLSpanElement | null>(null);
  const archorRefSecondary = useRef<HTMLSpanElement | null>(null);
  const [linePart, setLinePart] = useState(false);
  const { control, register, setValue, watch } = form;
  const isTrust = () => {
    return watch(`${type}.${index}.relationship`) === 'TRUST';
  };
  const extensionBreakpoint = useMediaQuery('(min-width:1177px)');
  const handleResize = () => {
    let width = archorRef.current?.getBoundingClientRect()?.width;
    if (!width) {
      width = archorRefSecondary.current?.getBoundingClientRect()?.width;
    }
    if (width && width > 100) {
      setLinePart(false);
    } else {
      setLinePart(true);
    }
  };
  const customFormLabelProps: any = {
    sx: {
      '.MuiFormLabel-root': {
        minHeight: linePart ? '32px' : '16px',
        overflow: 'visible',
        textOverflow: 'unset',
        textWrap: 'balance',
      },
    },
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize, false);
  }, []);
  useEffect(() => {
    handleResize();
  }, []);
  return (
    <Card variant="outlined" sx={{ borderRadius: 2, border: '1px solid #FCFAFA', bgcolor: '#FCFAFA' }}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item zero={12} xs={4} sm={4} esm={4} md={extensionBreakpoint ? 2 : 3}>
            <Controller
              control={control}
              name={`${type}.${index}.relationship`}
              rules={{
                required: 'This field is required.',
              }}
              render={({ field, fieldState }) => (
                <Select
                  {...field}
                  value={field.value === 'NON_SPOUSE' ? '' : field.value}
                  onChange={field.onChange}
                  label={
                    <span className="text-xs font-semibold text-superBlack">
                      Relationship <span style={{ color: 'red' }}>*</span>
                    </span>
                  }
                  placeholder={
                    field.value === 'NON_SPOUSE' ? (
                      <div className="py-2 overflow-hidden text-primaryColor text-[13px] overflow-ellipsis whitespace-nowrap">
                        Non Spouse
                      </div>
                    ) : (
                      'Select from drop down'
                    )
                  }
                  registerForm={{
                    ...register(`${type}.${index}.relationship`),
                  }}
                  data={RELATIONSHIP}
                  error={fieldState.error?.message}
                  formLabelProps={{
                    className: 'max-h-none mx-4 whitespace-pre-line',
                  }}
                  formLabelStyle={customFormLabelProps}
                  sx={{
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: '#E0DEC9',
                    },
                  }}
                />
              )}
            />
          </Grid>
          {isTrust() && (
            <Grid item zero={12} xs={4} sm={4} esm={4} md={extensionBreakpoint ? 2 : 3}>
              <Controller
                name={`${type}.${index}.trustName`}
                control={control}
                rules={{
                  required: 'Trust Name is required.',
                  pattern: {
                    value: REGEX_PATTERN.TEXT.pattern,
                    message: REGEX_PATTERN.TEXT.message,
                  },
                }}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      {...field}
                      label={<span className="text-xs font-semibold">Trust Name</span>}
                      required
                      placeholder="Type Trust Name"
                      error={fieldState.error?.message}
                      formLabelStyle={customFormLabelProps}
                      sx={{
                        '.MuiOutlinedInput-notchedOutline': {
                          borderColor: '#E0DEC9',
                        },
                      }}
                    />
                  );
                }}
              />
            </Grid>
          )}
          <Grid item zero={12} xs={4} sm={4} esm={4} md={extensionBreakpoint ? 2 : 3}>
            <Controller
              name={`${type}.${index}.firstName`}
              control={control}
              rules={{
                required: 'First Name is required.',
                pattern: {
                  value: REGEX_PATTERN.TEXT.pattern,
                  message: REGEX_PATTERN.TEXT.message,
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    {...field}
                    label={
                      <span ref={archorRefSecondary} className="text-xs font-semibold">
                        {!isTrust() ? `First Name` : `Contact Person First Name`}
                      </span>
                    }
                    placeholder="Type First Name"
                    error={fieldState.error?.message}
                    required
                    formLabelStyle={customFormLabelProps}
                    sx={{
                      '.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#E0DEC9',
                      },
                    }}
                  />
                );
              }}
            />
          </Grid>
          <Grid item zero={12} xs={4} sm={4} esm={4} md={extensionBreakpoint ? 2 : 3}>
            <Controller
              name={`${type}.${index}.lastName`}
              control={control}
              rules={{
                required: 'Last Name is required.',
                pattern: {
                  value: REGEX_PATTERN.TEXT.pattern,
                  message: REGEX_PATTERN.TEXT.message,
                },
              }}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    {...field}
                    label={
                      <span className="text-xs font-semibold">
                        {!isTrust() ? `Last Name` : `Contact Person Last Name`}
                      </span>
                    }
                    placeholder="Type Last Name"
                    error={fieldState.error?.message}
                    required
                    formLabelStyle={customFormLabelProps}
                    sx={{
                      '.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#E0DEC9',
                      },
                    }}
                  />
                );
              }}
            />
          </Grid>
          {!isTrust() && (
            <>
              <Grid item zero={12} xs={4} sm={4} esm={4} md={extensionBreakpoint ? 2 : 3}>
                <Controller
                  name={`${type}.${index}.ssn`}
                  control={control}
                  render={({ field: { value = '', onChange }, fieldState }) => (
                    <TextFieldSSN
                      label={<span ref={archorRef} className="text-xs font-semibold">{`Social Security Number`}</span>}
                      required={!isTrust()}
                      setValue={onChange}
                      value={value}
                      error={fieldState.error?.message as string}
                      formLabelStyle={customFormLabelProps}
                      sx={{
                        '.MuiOutlinedInput-notchedOutline': {
                          borderColor: '#E0DEC9',
                        },
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item zero={12} xs={4} sm={4} esm={4} md={extensionBreakpoint ? 2 : 3}>
                <Controller
                  name={`${type}.${index}.dob`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <FormControl fullWidth>
                      <DatePicker
                        {...field}
                        label={<span className="text-xs font-semibold">{'Date of Birth'}</span>}
                        onAccept={(newValue) => {
                          if (!newValue) {
                            setValue(`${type}.${index}.dob`, '', {
                              shouldDirty: true,
                            });
                          }
                        }}
                        error={fieldState.error?.message}
                        required={isTrust()}
                        formLabelStyle={customFormLabelProps}
                        sx={{
                          '.MuiOutlinedInput-notchedOutline': {
                            borderColor: '#E0DEC9',
                          },
                        }}
                      />
                    </FormControl>
                  )}
                />
              </Grid>
            </>
          )}
          {isTrust() && (
            <Grid item zero={12} xs={4} sm={4} esm={4} md={extensionBreakpoint ? 2 : 3}>
              <Controller
                name={`${type}.${index}.trustDate`}
                control={control}
                render={({ field, fieldState }) => (
                  <FormControl fullWidth>
                    <DatePicker
                      {...field}
                      label={<span className="text-xs font-semibold">{'Trust Date'}</span>}
                      required
                      onAccept={(newValue) => {
                        if (!newValue) {
                          setValue(`${type}.${index}.trustDate`, '', {
                            shouldDirty: true,
                          });
                        }
                      }}
                      error={fieldState.error?.message}
                      formLabelStyle={customFormLabelProps}
                      sx={{
                        '.MuiOutlinedInput-notchedOutline': {
                          borderColor: '#E0DEC9',
                        },
                      }}
                    />
                  </FormControl>
                )}
              />
            </Grid>
          )}
          <Grid item zero={12} xs={4} sm={4} esm={4} md={extensionBreakpoint ? 2 : 3}>
            <Controller
              name={`${type}.${index}.percentage`}
              control={control}
              rules={{
                required: 'Share Precentage is required.',
              }}
              render={({ field, fieldState }) => {
                return (
                  <TextPercentage
                    {...field}
                    label={<span className="text-xs font-semibold">{'Share'}</span>}
                    placeholder="Type % here"
                    error={fieldState.error?.message}
                    required
                    formLabelStyle={customFormLabelProps}
                    sx={{
                      '.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#E0DEC9',
                      },
                    }}
                    onValueChange={({ floatValue }: { floatValue: number }) => {
                      const clampedValue = Math.min(Math.max(floatValue, 0), 100);
                      field.onChange(clampedValue);
                    }}
                    allowNegative={false}
                  />
                );
              }}
            />
          </Grid>
          <Grid item zero={12} marginTop={1}>
            <Stack direction={{ zero: 'column', sm: 'row' }} spacing={1}>
              <Controller
                name={`${type}.${index}.address.street`}
                control={control}
                rules={{
                  required: 'Address Line 1 is required.',
                }}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      {...field}
                      label={<span className="text-xs font-semibold">{'Address Line 1'}</span>}
                      placeholder="Type Here"
                      error={fieldState.error?.message}
                      sx={{
                        '.MuiOutlinedInput-notchedOutline': {
                          borderColor: '#E0DEC9',
                        },
                      }}
                    />
                  );
                }}
              />
              <Controller
                name={`${type}.${index}.address.line2`}
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      {...field}
                      label={<span className="text-xs font-semibold">{'Address Line 2'}</span>}
                      placeholder="Type Here"
                      error={fieldState.error?.message}
                      sx={{
                        '.MuiOutlinedInput-notchedOutline': {
                          borderColor: '#E0DEC9',
                        },
                      }}
                    />
                  );
                }}
              />
              <Controller
                name={`${type}.${index}.address.city`}
                control={control}
                rules={{
                  required: 'City is required.',
                }}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      {...field}
                      label={<span className="text-xs font-semibold">{'City'}</span>}
                      placeholder="Type Here"
                      error={fieldState.error?.message}
                      sx={{
                        '.MuiOutlinedInput-notchedOutline': {
                          borderColor: '#E0DEC9',
                        },
                      }}
                    />
                  );
                }}
              />
              <Controller
                control={control}
                name={`${type}.${index}.address.state`}
                rules={{
                  required: 'State is required.',
                }}
                render={({ field, fieldState }) => (
                  <Select
                    {...field}
                    onChange={field.onChange}
                    label={<span className="text-xs font-semibold">{'State'}</span>}
                    placeholder="Select from list"
                    registerForm={{
                      ...register(`${type}.${index}.address.state`),
                    }}
                    data={STATE_DATA}
                    error={fieldState.error?.message}
                    sx={{
                      '.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#E0DEC9',
                      },
                    }}
                  />
                )}
              />
              <Controller
                control={control}
                name={`${type}.${index}.address.zip`}
                rules={{
                  required: 'Zip code is required.',
                  pattern: {
                    value: /\d{5}/,
                    message: 'Zip code has 5 numbers',
                  },
                }}
                render={({ field: { ref, ...restField }, fieldState }) => {
                  return (
                    <TextFieldWithMask
                      {...restField}
                      label={<span className="text-xs font-semibold">{'Zip code'}</span>}
                      placeholder="Type Here"
                      format="#####"
                      mask=""
                      error={fieldState.error?.message}
                      sx={{
                        '.MuiOutlinedInput-notchedOutline': {
                          borderColor: '#E0DEC9',
                        },
                      }}
                    />
                  );
                }}
              />
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions style={{ width: '100%', justifyContent: 'flex-end' }}>
        <Button
          variant="text"
          className="text-sm font-semibold leading-5"
          sx={{ color: '#FF005C' }}
          onClick={() => onRemoveClick(index)}
        >
          Remove this beneficiary
        </Button>
      </CardActions>
    </Card>
  );
};
export default BeneficiaryInfo;
