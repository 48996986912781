import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CreateErisaBondPayload, FormFilingDto } from 'core/models/formFilingDto.model';
import ApiService from 'core/services/api.service';

interface FormFilingState {
  success: boolean;
  data: FormFilingDto | null;
  loading: boolean;
}

const initialState: FormFilingState = {
  success: false,
  data: null,
  loading: false,
};

export const createErisaBondAsync = createAsyncThunk(
  'formFiling/createErisaBondAsync',
  async (data: CreateErisaBondPayload): Promise<any> => {
    const response = await ApiService.createErisaBond(data);
    return response.data;
  }
);

const formFilingSlice = createSlice({
  name: 'formFiling',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createErisaBondAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(createErisaBondAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createErisaBondAsync.rejected, (state) => {
        state.loading = false;
      });
  },
});

const formFilingSliceReducer = formFilingSlice.reducer;

export default formFilingSliceReducer;
