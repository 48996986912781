import { FormControl, Grid } from '@mui/material';
import { TextField, TextFieldWithMask } from 'common/components';
import { REGEX_PATTERN } from 'core/constants';
import { useAuth } from 'contexts/AuthProvider';
import { Actions } from 'core/enums/action-enum';
import { validate } from 'utils/validate';
import { SelectedOption } from 'pages/adminManageCompanyAdmin';
import { Controller } from 'react-hook-form';
import { useAppSelector } from 'states/hooks';
import { selectAction } from 'states/manageCompanyAdmin/manageCompanyAdminSlice';
import { z } from 'zod';

interface IProps {
  type: SelectedOption | string;
  form?: any;
}

const AddEditCompanyAdminForm = ({ type, form }: IProps) => {
  const {
    register,
    getValues,
    control,
    formState: { errors, isDirty, dirtyFields },
  } = form;

  const isPasswordTouched =
    Object.keys(dirtyFields).includes('password') || Object.keys(dirtyFields).includes('confirmPassword');
  const action = useAppSelector(selectAction);
  const auth = useAuth();
  const isSystemAdmin = auth?.session?.values.systemAdmin;

  return (
    <FormControl className="mt-4">
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <FormControl sx={{ width: '100%' }}>
            <TextField
              registerForm={{
                ...register('firstName', {
                  validate: {
                    min: validate(z.string().nonempty('First name is required')),
                    pattern: validate(z.string().regex(REGEX_PATTERN.TEXT.pattern, REGEX_PATTERN.TEXT.message)),
                  },
                }),
              }}
              label="First Name*"
              placeholder="First Name*"
              error={errors.firstName?.message && JSON.parse(errors.firstName?.message)[0].message}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl sx={{ width: '100%' }}>
            <TextField
              placeholder="Last Name*"
              label="Last Name*"
              registerForm={{
                ...register('lastName', {
                  validate: {
                    min: validate(z.string().nonempty('Last name is required')),
                    pattern: validate(z.string().regex(REGEX_PATTERN.TEXT.pattern, REGEX_PATTERN.TEXT.message)),
                  },
                }),
              }}
              error={errors.lastName?.message && JSON.parse(errors.lastName?.message)[0].message}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl sx={{ width: '100%' }}>
            <TextField
              disabled={!isSystemAdmin}
              placeholder="User Name (8-50 Characters)*"
              label="User Name*"
              registerForm={{
                ...register('userName', {
                  validate: {
                    min: validate(z.string().nonempty('User name is required')),
                    pattern: validate(
                      z.string().regex(REGEX_PATTERN.USER_NAME.pattern, REGEX_PATTERN.USER_NAME.message)
                    ),
                  },
                }),
              }}
              error={errors.userName?.message && JSON.parse(errors.userName?.message)[0].message}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl sx={{ width: '100%' }}>
            <TextField
              placeholder="Job Title*"
              label="Job Title*"
              registerForm={{
                ...register('jobTitle', {
                  validate: {
                    min: validate(z.string().nonempty('Job title is required')),
                  },
                }),
              }}
              error={errors.jobTitle?.message && JSON.parse(errors.jobTitle?.message)[0].message}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl sx={{ width: '100%' }}>
            <TextField
              placeholder="Email*"
              label="Email*"
              registerForm={{
                ...register('email', {
                  validate: {
                    min: validate(z.string().nonempty('Email is required')),
                    pattern: validate(z.string().regex(REGEX_PATTERN.EMAIL.pattern, REGEX_PATTERN.EMAIL.message)),
                  },
                }),
              }}
              error={errors.email?.message && JSON.parse(errors.email?.message)[0].message}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Controller
            control={control}
            name="phone"
            rules={{
              required: 'Phone number is required.',
              pattern: {
                value: REGEX_PATTERN.PHONE_NUMBER.pattern,
                message: REGEX_PATTERN.PHONE_NUMBER.message,
              },
            }}
            render={({ field: { ref, ...restField } }) => (
              <TextFieldWithMask
                {...restField}
                label="Phone number*"
                placeholder="(xxx) xxx xxxx"
                format="(###) ### ####"
                error={errors.phone?.message}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl sx={{ width: '100%' }}>
            <TextField
              placeholder="New Password*"
              label="New Password*"
              type="password"
              autoComplete="new-password"
              registerForm={{
                ...register('password', {
                  validate: {
                    min: validate(
                      (isDirty && action === Actions.EDIT && isPasswordTouched) || action === Actions.CREATE
                        ? z.string().nonempty('New password is required')
                        : z.string()
                    ),
                    pattern: validate(
                      (isDirty && action === Actions.EDIT && isPasswordTouched) || action === Actions.CREATE
                        ? z.string().regex(REGEX_PATTERN.PASSWORD.pattern, REGEX_PATTERN.PASSWORD.message)
                        : z.string()
                    ),
                  },
                }),
              }}
              error={errors.password?.message && JSON.parse(errors.password?.message)[0].message}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl sx={{ width: '100%' }}>
            <TextField
              placeholder="Confirm Password*"
              label="Confirm Password*"
              type="password"
              registerForm={{
                ...register('confirmPassword', {
                  validate: {
                    min: validate(
                      (isDirty && action === Actions.EDIT && isPasswordTouched) || action === Actions.CREATE
                        ? z.string().nonempty('Confirm password is required')
                        : z.string()
                    ),
                    matchPassword: validate(
                      (isDirty && action === Actions.EDIT && isPasswordTouched) || action === Actions.CREATE
                        ? z.string().refine((confirmPassword) => confirmPassword === getValues('password'), {
                            message: 'Passwords must match',
                            path: ['confirmPassword'],
                          })
                        : z.string()
                    ),
                  },
                }),
              }}
              error={errors.confirmPassword?.message && JSON.parse(errors.confirmPassword?.message)[0].message}
            />
          </FormControl>
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default AddEditCompanyAdminForm;
