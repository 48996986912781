import { FC } from 'react';

interface IErrorMassage {
  message: string;
}
export const ErrorMassage: FC<IErrorMassage> = ({ message }) => (
  <p className="mt-2 text-errorColor !text-xs" style={{ marginBottom: 0 }}>
    {message}
  </p>
);
