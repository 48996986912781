import { Typography, Box, Stack, Grid } from "@mui/material";
import { useAppSelector } from "states/hooks";
import { formatCurrency } from "../../core/services/utils/dashboardUtils";
export function AunualLimit() {
  const profileDashboardInfo = useAppSelector(
    (root) => root.manageEmployee.dashboardInfos
  );

  return (
    <Box
      sx={{
        height: "100%",
        paddingTop: 1,
        paddingX: 2,
        maxWidth: "100%",
        border: "1px solid #E0DEC9",
        borderRadius: "8px",
        marginBottom: 2,
      }}
    >
      <Grid container>
        <Grid item zero={12} xs={4}>
          <Box>
            <Typography
              component={"span"}
              color={"#9E9E91"}
              fontSize={"12px"}
              fontWeight={600}
              lineHeight={"18px"}
            >
              Annual Limit
            </Typography>
            <Typography fontWeight={600} fontSize={"20px"} lineHeight={"32px"}>
              {`${formatCurrency(
                (profileDashboardInfo?.contributionLimit || 0) - (profileDashboardInfo?.contributions?.ytdEmployee || 0)
              )}`}
            </Typography>
          </Box>
        </Grid>
        <Grid item zero={12} xs={8}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ width: 1, height: "100%" }}
            mt={{ zero: 1, xs: 0 }}
          >
            <Typography
              component={"span"}
              color={"#75756F"}
              fontSize={"12px"}
              fontWeight={400}
              lineHeight={"18px"}
            >
              *This is how much more you can contribute this year, before you
              have hit the annual limit of {`${formatCurrency(profileDashboardInfo?.contributionLimit || 0)}.`}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
