import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  ChangePasswordPayload,
  EmployeeDetailDto,
  EmployeeError,
  EmployeePortfolioHistoryResponse,
  // EmployeePortfolioHistoryResponse,
  EmployeeProfileDto,
  EmployeeProfilePayload,
  EmployeeProfileResponse,
  EmployeeResponseSuccessDto,
  EnabledTwoFARequest,
  IAuthorizedSignatoryRequest,
  IFilterListEmployee,
  IPortfolioHistoryItem,
  PeoplePayload,
  UserProfileDashboardDto,
  VerifiedSMSCodeRequest,
  VerifiedTwoFARequest,
} from 'core/models/employeeDto.model';
import { CompanyDto } from 'core/models/companyDto.model';
import ApiService from 'core/services/api.service';
import { RootState, store } from 'states/store';
import { REQUEST_STATUS } from 'core/constants/roles';
import { setSuccessMessage } from 'states/snackbarMessage/snackbarMessageSlice';
import { DataResponseDto } from 'core/models/DataResponseDto.model';

interface ManageEmployeeState {
  companyList: CompanyDto[];
  employeeList: EmployeeResponseSuccessDto[];
  employeeProfile: EmployeeProfileResponse | null;
  employeeProfileError: EmployeeError;
  employeeProfileStatus: string;
  twoFA: any;
  getTwoFAStatus: string;
  enabledTwoFAStatus: string;
  verifiedTwoFAStatus: string;
  resendCodeSMSStatus: string;
  deactivateTwoFAStatus: string;
  loading: boolean;
  verifiedSMSCodeStatus: string;
  employees: DataResponseDto<EmployeeDetailDto>;
  employeesByType: IAuthorizedSignatoryRequest[];
  getEmployeeProfileStatus: REQUEST_STATUS;
  portfolioHistories: EmployeePortfolioHistoryResponse<IPortfolioHistoryItem>;
  dashboardInfos?: UserProfileDashboardDto;
}

const initialState: ManageEmployeeState = {
  companyList: [],
  employeeList: [],
  employeeProfile: null,
  employeeProfileError: {} as EmployeeError,
  employeeProfileStatus: REQUEST_STATUS.IDLE,
  twoFA: null,
  getTwoFAStatus: REQUEST_STATUS.IDLE,
  enabledTwoFAStatus: REQUEST_STATUS.IDLE,
  verifiedTwoFAStatus: REQUEST_STATUS.IDLE,
  resendCodeSMSStatus: REQUEST_STATUS.IDLE,
  deactivateTwoFAStatus: REQUEST_STATUS.IDLE,
  verifiedSMSCodeStatus: REQUEST_STATUS.IDLE,
  getEmployeeProfileStatus: REQUEST_STATUS.IDLE,
  loading: false,
  employees: {},
  employeesByType: [],
  portfolioHistories: {
    content: [],
    totalElements: 0,
    empty: false,
    first: false,
    last: false,
    number: 0,
    numberOfElements: 0,
    size: 0,
    totalPages: 0,
    pageable: {
      sort: {
        empty: false,
        unsorted: false,
        sorted: false,
      },
      offset: 0,
      pageNumber: 0,
      pageSize: 0,
      paged: false,
      unpaged: false,
    },
    sort: {
      empty: false,
      unsorted: false,
      sorted: false,
    },
  },
  dashboardInfos: undefined,
};

export const getEmployeeProfileAsync = createAsyncThunk('manageEmployee/getEmployeeProfile', async (): Promise<any> => {
  const response = await ApiService.getProfile();
  return response.data;
});

export const updatePeopleAsync = createAsyncThunk(
  'manageEmployee/updatePeopleAsync',
  async ({ payload, filter }: { payload: PeoplePayload; filter?: any }): Promise<any> => {
    const response = await ApiService.updatePeople({ payload, filter });
    return response.data;
  }
);

export const addParticipantAsync = createAsyncThunk(
  'manageEmployee/addParticipantAsync',
  async ({ companyId, payload }: { companyId: string; payload: EmployeeProfileDto }): Promise<any> => {
    const response = await ApiService.addParticipant({ companyId, payload });
    return response.data;
  }
);

export const updateEmployeeProfileAsync = createAsyncThunk(
  'manageEmployee/updateEmployeeProfileAsync',
  async (payload: EmployeeProfilePayload): Promise<any> => {
    const response = await ApiService.updateProfile(payload);
    return response.data;
  }
);

export const changePasswordAsync = createAsyncThunk(
  'manageEmployee/changePasswordAsync',
  async (body: ChangePasswordPayload): Promise<any> => {
    const response = await ApiService.changePassword(body);
    return response.data;
  }
);

export const getTwoFAAsync = createAsyncThunk('manageEmployee/getTwoFAAsync', async (): Promise<any> => {
  const response = await ApiService.getTwoFA();
  return response.data;
});

export const enableTwoFAAsync = createAsyncThunk(
  'manageEmployee/enableTwoFAAsync',
  async (payload: EnabledTwoFARequest): Promise<any> => {
    const response = await ApiService.enableTwoFA(payload);
    return response.data;
  }
);

export const verifyTwoFAAsync = createAsyncThunk(
  'manageEmployee/verifyTwoFAAsync',
  async (payload: VerifiedTwoFARequest): Promise<any> => {
    const response = await ApiService.verifyTwoFA(payload);
    return response.data;
  }
);

export const verifySMSCodeAsync = createAsyncThunk(
  'manageEmployee/verifySMSCodeAsync',
  async (payload: VerifiedSMSCodeRequest): Promise<any> => {
    const response = await ApiService.verifySMSCode(payload);
    return response.data;
  }
);

export const resendSMSCodeAsync = createAsyncThunk('manageEmployee/resendSMSCodeAsync', async (): Promise<any> => {
  const response = await ApiService.resendSMSCode();
  return response.data;
});

export const resendCodeSMS2FAAsync = createAsyncThunk(
  'manageEmployee/resendCodeSMS2FASAsync',
  async (): Promise<any> => {
    const response = await ApiService.resendCodeSMS2FA();
    return response.data;
  }
);

export const getListEmployeeAsync = createAsyncThunk(
  'manageEmployee/getListEmployeeAsync',
  async (data: IFilterListEmployee): Promise<any> => {
    const response = await ApiService.getListEmployeeByFilter(data);
    return response.data;
  }
);

export const getListPortfolioHistoryAsync = createAsyncThunk(
  'manageEmployee/getListPortfolioHistoryAsync',
  async (filter: Omit<IFilterListEmployee, 'profileStatus'>): Promise<any> => {
    const response = await ApiService.getListPortfolioHistory(filter);
    return response.data;
  }
);

export const getProfileDashBoardInfoAsync = createAsyncThunk(
  'manageEmployee/getProfileDashBoardInfoAsync',
  async (): Promise<UserProfileDashboardDto> => {
    const response = await ApiService.getProfileDashBoardInfo();
    return response.data;
  }
);

const manageEmployeeSlice = createSlice({
  name: 'manageEmployee',
  initialState,
  reducers: {
    setTwoFA: (state, action) => {
      state.twoFA = action.payload;
    },
    setEmployeeProfile: (state, action) => {
      state.employeeProfile = action.payload;
    },
    clearListEmployee: (state) => {
      state.employees = {};
    },
    setEmployeesByType: (state, action) => {
      state.employeesByType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmployeeProfileAsync.fulfilled, (state, action) => {
        state.employeeProfile = action.payload;
        state.getEmployeeProfileStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(getEmployeeProfileAsync.rejected, (state, action) => {
        state.getEmployeeProfileStatus = REQUEST_STATUS.ERROR;
      })
      .addCase(getEmployeeProfileAsync.pending, (state, action) => {
        state.getEmployeeProfileStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(updateEmployeeProfileAsync.pending, (state, action) => {
        state.employeeProfileStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(updateEmployeeProfileAsync.fulfilled, (state, action) => {
        state.employeeProfileStatus = REQUEST_STATUS.SUCCESS;
        state.employeeProfile = action.payload;
      })
      .addCase(updatePeopleAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePeopleAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updatePeopleAsync.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getTwoFAAsync.pending, (state, action) => {
        state.getTwoFAStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(getTwoFAAsync.fulfilled, (state, action) => {
        state.getTwoFAStatus = REQUEST_STATUS.SUCCESS;
        if (action.payload && action.payload.length) state.twoFA = action.payload[0];
      })
      .addCase(getTwoFAAsync.rejected, (state, action) => {
        state.getTwoFAStatus = REQUEST_STATUS.ERROR;
      })

      .addCase(enableTwoFAAsync.pending, (state, action) => {
        state.enabledTwoFAStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(enableTwoFAAsync.fulfilled, (state, action) => {
        state.enabledTwoFAStatus = REQUEST_STATUS.SUCCESS;
        state.twoFA = action.payload;
      })
      .addCase(enableTwoFAAsync.rejected, (state, action) => {
        state.enabledTwoFAStatus = REQUEST_STATUS.ERROR;
      })

      .addCase(verifyTwoFAAsync.pending, (state, action) => {
        state.verifiedTwoFAStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(verifyTwoFAAsync.fulfilled, (state, action) => {
        state.verifiedTwoFAStatus = REQUEST_STATUS.SUCCESS;
        if (action.meta.arg.action === 'ADD_2FA') {
          state.twoFA = { ...state.twoFA, status: 'ACTIVE' };
          setTimeout(() => {
            store.dispatch(setSuccessMessage('Update two-factor authentication successful.'));
          }, 0);
        } else if (action.meta.arg.action === 'REMOVE_2FA') {
          state.twoFA = null;
          setTimeout(() => {
            store.dispatch(setSuccessMessage('Remove two-factor authentication successful.'));
          }, 0);
        }
      })
      .addCase(verifyTwoFAAsync.rejected, (state, action) => {
        state.verifiedTwoFAStatus = REQUEST_STATUS.ERROR;
      })

      .addCase(resendCodeSMS2FAAsync.pending, (state, action) => {
        state.resendCodeSMSStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(resendCodeSMS2FAAsync.fulfilled, (state, action) => {
        state.resendCodeSMSStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(resendCodeSMS2FAAsync.rejected, (state, action) => {
        state.resendCodeSMSStatus = REQUEST_STATUS.ERROR;
      })
      .addCase(verifySMSCodeAsync.pending, (state, action) => {
        state.verifiedSMSCodeStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(verifySMSCodeAsync.fulfilled, (state, action) => {
        state.verifiedSMSCodeStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(verifySMSCodeAsync.rejected, (state, action) => {
        state.verifiedSMSCodeStatus = REQUEST_STATUS.ERROR;
      })
      .addCase(getListEmployeeAsync.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getListEmployeeAsync.fulfilled, (state, action) => {
        state.employees = action.payload;
        state.loading = false;
      })
      .addCase(getListEmployeeAsync.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(resendSMSCodeAsync.pending, (state, action) => {
        state.resendCodeSMSStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(resendSMSCodeAsync.fulfilled, (state, action) => {
        state.resendCodeSMSStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(resendSMSCodeAsync.rejected, (state, action) => {
        state.resendCodeSMSStatus = REQUEST_STATUS.ERROR;
      })
      .addCase(getListPortfolioHistoryAsync.fulfilled, (state, action) => {
        state.portfolioHistories = action.payload;
      })
      .addCase(getProfileDashBoardInfoAsync.fulfilled, (state, action) => {
        state.dashboardInfos = action.payload;
      });
  },
});

export const { setTwoFA, setEmployeeProfile, clearListEmployee, setEmployeesByType } = manageEmployeeSlice.actions;

export const selectEmployeeProfile = (state: RootState) => state.manageEmployee.employeeProfile;
export const selectListEmployeeByType = (state: RootState) => state.manageEmployee.employeesByType;
export const selectListPortfolioHistory = (state: RootState) => state.manageEmployee.portfolioHistories;
const manageEmployeeReducer = manageEmployeeSlice.reducer;
export default manageEmployeeReducer;
