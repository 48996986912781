import { isValidElement } from 'react';

type PageHeaderProps = {
  pageTitle?: string | React.ReactNode;
  subTitle?: string | React.ReactNode;
  extendContent?: React.ReactNode;
  className?: string;
  subTitleClasses?: string;
  subTittleWrapperClasses?: string;
};

export const PageHeader = ({
  pageTitle,
  subTitle,
  extendContent,
  className = 'px-4 sm:px-8 pt-4 sm:pt-10 pb-6 flex justify-between items-center',
  subTitleClasses = '',
  subTittleWrapperClasses = '',
}: PageHeaderProps) => {
  return pageTitle ? (
    <header className={className}>
      <div className="w-full">
        {isValidElement(pageTitle) ? pageTitle : <h1 className="text-2xl font-bold text-superBlack">{pageTitle}</h1>}
        {subTitle && (
          <div
            className={['flex justify-between font-[13px] text-superBlack items-center', subTittleWrapperClasses].join(
              ' '
            )}
          >
            <div className={`text-sm font-normal text-deepGrey mt-2 ${subTitleClasses}`}>{subTitle}</div>
            {extendContent}
          </div>
        )}
      </div>
    </header>
  ) : null;
};
