import { Alert, AlertColor } from "@mui/material";
import { FC, ReactNode } from "react";
import { OnboardingPlaidStatus } from "core/enums";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export type AlertContentProps = {
  type?: OnboardingPlaidStatus;
};

const PlaidAlertContent: FC<AlertContentProps> = ({ type }) => {
  let title: ReactNode | undefined;
  let severity: AlertColor = "success";
  let color = "#25AC65";
  let bgColor = "#E2F3EA";
  switch (type) {
    case OnboardingPlaidStatus.ASSISTED_CONNECTION:
      title = "Looks like your verification was unsuccessful.";
      severity = "error";
      color = "";
      bgColor = "";
      break;
    case OnboardingPlaidStatus.INTEGRATED:
      title =
        "Successfully verified bank account info. We will reach out to you if any further action is needed.";
      severity = "success";
      break;
  }
  if (!title) return null;

  return (
    <Alert
      severity={severity}
      iconMapping={{
        success: <CheckCircleOutlineIcon fontSize="inherit" />,
      }}
      sx={{
        "& .MuiAlert-icon": {
          color,
        },
        backgroundColor: bgColor,
      }}
    >
      <div
        className={`text-sm font-medium`}
        style={{ color, whiteSpace: "pre-line" }}
      >
        {title}
      </div>
    </Alert>
  );
};

export default PlaidAlertContent;
