import { ErrorMassage, TextCurrency, TextPercentage } from "common/components";
import { useDebouncedCallback } from "hooks/useDebouncedCallBack";
import { IAnswers } from "utils/processData";

interface IEmployeeContributionRangeProps {
  answers: IAnswers;
  updateAnswersKeyValue: Function;
  percentageProps: any;
  currencyProps: any;
  title: string;
  disabled?: boolean;
}

export const BaseEmployeeContributionsRange = (
  props: IEmployeeContributionRangeProps
) => {
  const {
    updateAnswersKeyValue,
    title,
    answers,
    percentageProps,
    currencyProps,
    disabled,
  } = props;
  const handleWithoutSymbol = (valueWithSymbol: string) => {
    return valueWithSymbol.replace("$", "").replace(",", "");
  };

  const handleUpdateValue = (updatedValue: number, keyValue: string) => {
    if (updatedValue >= 0) {
      updateAnswersKeyValue(keyValue, updatedValue.toString());
    } else if (isNaN(updatedValue)) {
      <ErrorMassage message={"No Value Entered"} />;
    }
  };

  const handleChangeTextPercentage = useDebouncedCallback(
    (debounceValue: string) => {
      const valueWithoutSymbol = handleWithoutSymbol(debounceValue);
      const updatedValue = parseFloat(valueWithoutSymbol) / 100;
      const keyValue = percentageProps.keyvalue;
      handleUpdateValue(updatedValue, keyValue);
    },
    500
  );

  const handleChangeTextCurrency = useDebouncedCallback(
    (debounceValue: string) => {
      const valueWithoutSymbol = handleWithoutSymbol(debounceValue);
      const updatedValue =
        parseFloat(valueWithoutSymbol) / answers.grossPay.value;
      const keyValue = currencyProps.keyvalue;
      handleUpdateValue(updatedValue, keyValue);
    },
    500
  );

  return (
    <div className="flex flex-col mb-8 gap-2">
      <div className="w-full">
        <span className="text-xs font-semibold pl-4 text-superBlack">
          {title}
        </span>
      </div>
      <div className="flex gap-4 w-full">
        <TextPercentage
          allowNegative={false}
          disabled={disabled}
          value={percentageProps.value}
          min={0}
          max={percentageProps.max}
          step={0.01}
          // onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
          //   const valueWithSymbol = event.target.value;
          //   const valueWithoutSymbol = handleWithoutSymbol(valueWithSymbol);
          //   const updatedValue = parseFloat(valueWithoutSymbol) / 100;
          //   const keyValue = percentageProps.keyvalue;
          //   handleUpdateValue(updatedValue, keyValue);
          // }}
          onChange={(event: React.FocusEvent<HTMLInputElement>) => {
            handleChangeTextPercentage(event.target.value);
          }}
          {...percentageProps}
        />

        <TextCurrency
          allowNegative={false}
          disabled={disabled}
          value={currencyProps.value}
          max={currencyProps.max}
          // onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
          //   const valueWithSymbol = event.target.value;
          //   const valueWithoutSymbol = handleWithoutSymbol(valueWithSymbol);
          //   const updatedValue =
          //     parseFloat(valueWithoutSymbol) / answers.grossPay.value;
          //   const keyValue = currencyProps.keyvalue;
          //   handleUpdateValue(updatedValue, keyValue);
          // }}
          onChange={(event: React.FocusEvent<HTMLInputElement>) => {
            handleChangeTextCurrency(event.target.value);
          }}
          {...currencyProps}
        />
      </div>
    </div>
  );
};
