export const DISTRIBUTION_OPTIONS = [
  { label: 'Hardship', value: 'HARDSHIP' },
  {
    label: 'Withdrawals after Age 59 1⁄2',
    value: 'WITHDRAWAL_59_ONE_HALF',
  },
  {
    label: 'I no longer work for the employer offering Saveday 401(k)',
    value: 'TERMINATION',
  },
  { label: 'Retirement', value: 'RETIREMENT' },
  { label: 'Qualified Domestic Relations Order (QDRO)', value: 'QDRO' },
  { label: 'Disability', value: 'DISABILITY' },
  { label: 'Direct Rollover', value: 'ROLLOVER' },
];

export const DISTRIBUTION_STEPS = [
  { step: 1, title: 'Please ensure that your personal details are correct' },
  { step: 2, title: 'Complete the next form' },
  {
    step: 3,
    title: 'Please take a moment to review the tax withholding policy',
  },
  {
    step: 4,
    title: 'Please read the terms and conditions carefully, before proceeding',
  },
  { step: 5, title: 'Please ensure that your personal details are correct' },
  { step: 6, title: 'Complete the next form' },
  {
    step: 7,
    title: 'Please take a moment to review the tax withholding policy',
  },
  {
    step: 8,
    title: 'Please read the terms and conditions carefully, before proceeding',
  },
  {
    step: 9,
    title:
      'Before you begin, this notice contains important information about rolling over your vested funds from Saveday 401K account to Traditional IRA, ROTH IRA, or another qualified retirement plan. You can refer to your Summary Plan Description for additional information.',
  },
  {
    step: 10,
    title: 'Please ensure that your personal details are correct',
  },
  { step: 11, title: 'Complete the next form' },
  {
    step: 12,
    title: 'Please take a moment to go through the terms and conditions',
  },
];
