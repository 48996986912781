import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RiskQuestionResponse } from 'core/models/riskQuestionDto.model';
import ApiService from 'core/services/api.service';
import { RootState } from 'states/store';

interface EmployeeRishQuestionState {
  loading: boolean;
  success: boolean;
  error: string;
  currentAnswer: RiskQuestionResponse;
}

const initialState: EmployeeRishQuestionState = {
  loading: false,
  success: false,
  error: '',
  currentAnswer: {},
};

export const createAnswerDataAsync = createAsyncThunk(
  'riskQuestion/createAnswerDataAsync',
  async (profileId: string): Promise<any> => {
    const response = await ApiService.createAnswerData(profileId);
    return response.data;
  }
);

const riskQuestionSlice = createSlice({
  name: 'riskQuestion',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createAnswerDataAsync.pending, (state) => {
        state.loading = true;
        state.success = false;
      })
      .addCase(createAnswerDataAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.currentAnswer = action.payload;
        state.success = true;
      })
      .addCase(createAnswerDataAsync.rejected, (state, action) => {
        state.loading = false;
        state.success = false;
        state.error = 'Your account is not sufficient for this action. Please contact admin team for further support.';
      });
  },
});

export const selectActiveAnswers = (state: RootState) => state.riskQuestion.currentAnswer;

const riskQuestionReducer = riskQuestionSlice.reducer;
export default riskQuestionReducer;
