import React from 'react';
import { Tabs as MuiTabs, TabsProps as MuiTabsProps } from '@mui/material';

type TTabsProps = MuiTabsProps & {};

export const Tabs = ({ sx, ...props }: TTabsProps) => {
  return (
    <>
      <MuiTabs
        sx={{
          minHeight: 0,
          '& .MuiTabs-flexContainer': {
            position: 'relative',
            zIndex: 1,
          },
          '& .MuiTabs-indicator': {
            height: '100%',
            borderRadius: '50px',
          },
          ...sx,
        }}
        {...props}
      />
    </>
  );
};
