import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { REQUEST_STATUS } from 'core/constants/roles';
import { AccountDetailsDto, AccountDetailsPayload } from 'core/models/accountManagementDto.model';
import { CompanyDto, CompanyPaginationDto } from 'core/models/companyDto.model';
import {
  EmployeeResponseSuccessDto,
  IClientItem,
  IPartnerItem,
  IUserProfileResponse,
  IClientPartnerFilter,
} from 'core/models/employeeDto.model';
import ApiService from 'core/services/api.service';
import { RootState } from 'states/store';
import { DEFAULT_PAGE } from 'core/constants/partner-dashboard';
import {
  ILateContributionFilter,
  ILateSubmissionFilter,
  LateContributionItem,
  LateSubmissionsItem,
} from 'core/models/LateContribution.model';
import { Filter, Pagination } from 'core/models/pagination.model';

interface AccountManagementState {
  companyList: CompanyDto[];
  employeeList: EmployeeResponseSuccessDto[];
  accountDetailList: AccountDetailsDto[];
  userProfile: IUserProfileResponse | null;
  getUserProfileStatus: REQUEST_STATUS;
  companyPartners: IPartnerItem[];
  shortClients: IClientItem[];
  listClient: Pagination<IClientItem>;
  lateContributions: Pagination<LateContributionItem>;
  lateSubmissions: Pagination<LateSubmissionsItem>;
}

const initialState: AccountManagementState = {
  companyList: [],
  employeeList: [],
  accountDetailList: [],
  userProfile: null,
  getUserProfileStatus: REQUEST_STATUS.IDLE,
  companyPartners: [],
  shortClients: [],
  listClient: DEFAULT_PAGE,
  lateContributions: DEFAULT_PAGE,
  lateSubmissions: DEFAULT_PAGE,
};

const getRetrieveCompanyNamesAndIdsAsync = createAsyncThunk(
  'accountManagement/getRetrieveCompanyNamesAndIdsAsync',
  async (): Promise<CompanyPaginationDto> => {
    const response = await ApiService.getRetrieveCompanyNamesAndIds();
    return response.data;
  }
);

const getEmployeeByCompanyIdAsync = createAsyncThunk(
  'accountManagement/getEmployeeByCompanyIdAsync',
  async (id: string): Promise<any> => {
    const response = await ApiService.getEmployeeByCompanyId(id);
    return response.data;
  }
);

const getAccountDetailsAsync = createAsyncThunk(
  'accountManagement/getAccountDetailsAsync',
  async (payload: AccountDetailsPayload): Promise<any> => {
    const response = await ApiService.getAccountDetails(payload);
    return response.data;
  }
);

const getEmployeeDetailsAsync = createAsyncThunk(
  'accountManagement/getEmployeeDetailsAsync',
  async (personId: string): Promise<any> => {
    const response = await ApiService.getEmployeeDetails(personId);
    return response.data;
  }
);

export const getCompanyPartnersAsync = createAsyncThunk(
  'accountManagement/getCompanyPartnersAsync',
  async (filter: Partial<Filter>): Promise<any> => {
    const response = await ApiService.getCompanyPartners(filter);
    return response.data;
  }
);

export const getShortClientsAsync = createAsyncThunk(
  'accountManagement/getShortClientsAsync',
  async (): Promise<any> => {
    const response = await ApiService.getPartnersShortInfo();
    return response.data;
  }
);

export const getListClientAsync = createAsyncThunk(
  'accountManagement/getListClientAsync',
  async (filter: Partial<IClientPartnerFilter>): Promise<any> => {
    const response = await ApiService.getListClient(filter);
    return response.data;
  }
);

export const getListLateContributionAsync = createAsyncThunk(
  'accountManagement/getListLateContributionAsync',
  async (filter: Partial<ILateContributionFilter>): Promise<any> => {
    const response = await ApiService.getListLateContribution(filter);
    return response.data;
  }
);

export const getListLateSubmissionsAsync = createAsyncThunk(
  'accountManagement/getListLateSubmissionsAsync',
  async (filter: Partial<ILateSubmissionFilter>): Promise<any> => {
    const response = await ApiService.getListLateSubmission(filter);
    return response.data;
  }
);

export const updateListLateContributionAsync = createAsyncThunk(
  'accountManagement/updateListLateContributionAsync',
  async (payload: Partial<LateContributionItem>): Promise<any> => {
    const response = await ApiService.updateLateContribution(payload);
    return response.data;
  }
);

const accountManagementSlice = createSlice({
  name: 'accountManagement',
  initialState,
  reducers: {
    clearListLateContribution: (state) => {
      state.lateContributions = DEFAULT_PAGE;
    },
    clearListLateSubmission: (state) => {
      state.lateSubmissions = DEFAULT_PAGE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRetrieveCompanyNamesAndIdsAsync.fulfilled, (state, action) => {
        state.companyList = action.payload.content;
      })
      .addCase(getEmployeeByCompanyIdAsync.fulfilled, (state, action) => {
        state.employeeList = action.payload;
      })
      .addCase(getAccountDetailsAsync.fulfilled, (state, action) => {
        if (action.payload.length) {
          state.accountDetailList = action.payload;
        }
      })

      .addCase(getEmployeeDetailsAsync.pending, (state, action) => {
        state.getUserProfileStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(getEmployeeDetailsAsync.fulfilled, (state, action) => {
        state.userProfile = action.payload;
        state.getUserProfileStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(getEmployeeDetailsAsync.rejected, (state, action) => {
        state.getUserProfileStatus = REQUEST_STATUS.ERROR;
      })
      .addCase(getCompanyPartnersAsync.fulfilled, (state, action) => {
        state.companyPartners = action.payload;
      })
      .addCase(getShortClientsAsync.fulfilled, (state, action) => {
        state.shortClients = action.payload;
      })
      .addCase(getListClientAsync.fulfilled, (state, action) => {
        state.listClient = action.payload;
      })
      .addCase(getListLateContributionAsync.fulfilled, (state, action) => {
        state.lateContributions = action.payload;
      })
      .addCase(getListLateSubmissionsAsync.fulfilled, (state, action) => {
        state.lateSubmissions = action.payload;
      });
  },
});

export const selectCompanyPartners = (state: RootState) => state.accountManagement.companyPartners;

export const selectShortClient = (state: RootState) => state.accountManagement.shortClients;

export const selectListClient = (state: RootState) => state.accountManagement.listClient;

export const selectListLateContribution = (state: RootState) => state.accountManagement.lateContributions;

export const selectListLateSubmission = (state: RootState) => state.accountManagement.lateSubmissions;

export const { clearListLateContribution, clearListLateSubmission } = accountManagementSlice.actions;

const accountManagementReducer = accountManagementSlice.reducer;
export default accountManagementReducer;
