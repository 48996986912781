import { PayFrequencyValue } from 'core/enums/pay-frequency.enum';

export const payFrequenceOptions = [
  {
    label: 'Select Payroll Frequency',
    value: '',
  },
  {
    label: 'Weekly',
    value: PayFrequencyValue.WEEKLY,
  },
  {
    label: 'Bi Weekly',
    value: PayFrequencyValue.BI_WEEKLY,
  },
  {
    label: 'Semi Monthly',
    value: PayFrequencyValue.SEMI_MONTHLY,
  },
  {
    label: 'Monthly',
    value: PayFrequencyValue.MONTHLY,
  },
  {
    label: 'Quarterly',
    value: PayFrequencyValue.QUARTERLY,
  },
  {
    label: 'Semi Annually',
    value: PayFrequencyValue.SEMI_ANNUALLY,
  },
  {
    label: 'Annually',
    value: PayFrequencyValue.ANNUALLY,
  },
];
