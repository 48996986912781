import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CONFIG } from 'core/constants';
import { REQUEST_STATUS } from 'core/constants/roles';
import { EditProfileDto, IFeedbackItem } from 'core/models/employeeDto.model';
import { ProfileDto } from 'core/models/profileDto.model';
import ApiService, { getEmployeeDetails } from 'core/services/api.service';
import { RootState } from 'states/store';

interface EmployeeState {
  profile: ProfileDto | null;
  isResetModalOpen: boolean;
  isInvitedModalOpen: boolean;
  loading: boolean;
  updateProfileStatus: REQUEST_STATUS;
  feedbacks: IFeedbackItem[];
  webVersion?: string;
}

const initialState: EmployeeState = {
  profile: null,
  isResetModalOpen: false,
  isInvitedModalOpen: false,
  loading: false,
  updateProfileStatus: REQUEST_STATUS.IDLE,
  feedbacks: [],
  webVersion: CONFIG.FE_VERSION,
};

export const getProfileAsync = createAsyncThunk(
  'employee/getProfileAsync',
  async ({ personId }: { personId: string | undefined }): Promise<any> => {
    const response = await getEmployeeDetails(personId);
    return response.data;
  }
);

export const updateProfileAsync = createAsyncThunk(
  'employee/updateProfileAsync',
  async (payload: EditProfileDto): Promise<any> => {
    const response = await ApiService.updateProfile(payload);
    return response.data;
  }
);

export const getFeedbacksAsync = createAsyncThunk('employee/getFeedbacksAsync', async (): Promise<any> => {
  const response = await ApiService.getFeedbacks();
  return response.data;
});

export const updateFeedbacksAsync = createAsyncThunk(
  'employee/updateFeedbacksAsync',
  async (feedbacks: IFeedbackItem[]): Promise<any> => {
    const response = await ApiService.updateFeedbacks(feedbacks);
    return response.data;
  }
);

const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    setIsResetModalOpen: (state, action) => {
      state.isResetModalOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileAsync.fulfilled, (state, action) => {
        state.profile = action.payload;
      })
      .addCase(updateProfileAsync.pending, (state) => {
        state.loading = true;
        state.updateProfileStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(updateProfileAsync.fulfilled, (state) => {
        state.loading = false;
        state.updateProfileStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(updateProfileAsync.rejected, (state) => {
        state.loading = false;
        state.updateProfileStatus = REQUEST_STATUS.ERROR;
      })
      .addCase(getFeedbacksAsync.fulfilled, (state, action) => {
        state.feedbacks = action.payload.content;
      });
  },
});

export const { setIsResetModalOpen } = employeeSlice.actions;

export const selectEmployeeProfile = (state: RootState) => state.employee.profile;
export const selectIsResetModalOpen = (state: RootState) => state.employee.isResetModalOpen;
export const selectFeedbacks = (state: RootState) => state.employee.feedbacks;
export const selectWebVersion = (state: RootState) => state.employee.webVersion;
const employeeReducer = employeeSlice.reducer;
export default employeeReducer;
