/* eslint-disable no-useless-escape */
export const REGEX_PATTERN = {
  // AT LEAST 8 CHARACTERS 1 UPPERCASE, 1 LOWERCASE, 1 SPECIAL CHARACTER, 1 NUMBER
  PASSWORD: {
    pattern:
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[~!@#$%^&*-+=\{}\[\]:;_()"'<>,.?/\\])(?=\S+$).{8,50}$/i,
    message:
      "Password must be more than 8 characters, a combination of at least one uppercase, one lowercase letter, one number and one special character",
  },
  AT_LEAST_ONE_LOWERCASE: {
    pattern: /^(?=.*[a-z])/,
    message: "At least one lowercase letter",
  },
  AT_LEAST_ONE_UPPERCASE: {
    pattern: /^(?=.*[A-Z])/,
    message: "At least one uppercase letter",
  },
  AT_LEAST_ONE_NUMBER: {
    pattern: /^(?=.*[0-9])/,
    message: "At least one number",
  },
  AT_LEAST_ONE_SPECIAL_CHARACTER: {
    pattern: /^(?=.*[~!@#$%^&*-+=\{}\[\]:;_()"'<>,.?/\\]+)/,
    message: "At least one special character",
  },
  // EMAIL
  EMAIL: {
    pattern: /^[^\s@]+@[^\s@]+\.[^\s@.]{2,}$/i,
    message: "Please enter a valid email in this format you@domain.com",
  },
  // 8-15 characters contain number and letter only.
  USER_NAME: {
    pattern: /^[a-zA-Z0-9-_]{8,50}$/i,
    message:
      "Please enter 8-50 characters. Username can only be a combination of letters and numbers",
  },
  //2 ~ 50 letters of a-z, A-Z, 0-9, Period, Single quote, Whitespace, or Hyphen
  TEXT: {
    pattern: /^[a-zA-Z0-9 .'-]{2,50}$/i,
    message:
      "Please enter 2 ~ 50 letters of a-z, A-Z, 0-9, Period, Single quote, Whitespace, or Hyphen",
  },
  TEXT_150: {
    pattern: /^.{2,150}$/i,
    message: "Please enter 2 ~ 150 characters",
  },
  // number only
  NUMBER: {
    pattern: /^[0-9\b]+$/i,
  },
  // number only
  PHONE_NUMBER: {
    pattern: /^\(\d{3}\) \d{3} \d{4}$/i,
    message: "Phone number does not valid",
  },
  // 4 digits - zipPlus4
  ZIP_PLUS_4: {
    pattern: /^\d{4}$/i,
    message: "Please enter 4 digits",
  },
  // 5 digits - ZIP CODE
  ZIP: {
    pattern: /^\d{5}$/i,
    message: "Please enter 5 digits",
  },
  LAST_FOUR_SSN: {
    pattern: /^\d{4}$/i,
    message: "Please enter last 4 digits of SSN",
  },
  // SSN xxx-xx-xxxx
  SSN: {
    pattern: /^\d{3}-\d{2}-\d{4}$/,
    message: "Please enter a valid SSN",
  },
  PARTIALLY_HIDE_SSN: {
    pattern: /^XXX-XX-\d{4}$/,
    message: "Please enter a valid SSN",
  },
  // EIN xx-xxxxxxx
  EIN: {
    pattern: /^\d{2}-\d{7}/i,
    message: "Please enter 9 digits",
  },
  DOMAIN: {
    pattern: /^(?!-)[A-Za-z0-9-]+([-.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/,
    message: "Please enter a valid in this format domain.com",
  },
  BUSINESS_NAME: {
    pattern: /^[a-zA-Z0-9 .,'-]{2,50}$/i,
    message:
      "Please enter 2 ~ 50 letters of a-z, A-Z, 0-9, Period, Comma, Single quote, Whitespace, or Hyphen",
  },
};
