import { useContext, createContext } from 'react';
import { TypeButton } from '../components/employerManagePlan/BaseManagePlan';

interface SchemaProps {
  setSchema: (prev: any) => any;
  children: React.ReactNode;
  initSchema: any;
  name?: TypeButton;
  typePrimarySchema: string;
}

const initSchemaContext = {
  resetSchema: (value?: any, type?: TypeButton) => {},
  removeSchema: (value?: any, type?: TypeButton) => {},
  updateSchema: (value?: any, type?: TypeButton) => {},
  mergeSchema: (value?: any, type?: TypeButton) => {},
  replaceSchema: (value?: any, type?: TypeButton) => {},
};

const CreateSchemaContext = createContext(initSchemaContext);

export const useSchemaContext = () => {
  return useContext(CreateSchemaContext);
};
export const SchemaContext = (props: SchemaProps) => {
  const { setSchema, children, initSchema, typePrimarySchema = TypeButton.SUBMIT } = props;

  const resetSchema = (value?: any, type = TypeButton.SUBMIT) => {
    setSchema((prev: any) => ({ ...prev, [type]: value || initSchema }));
  };

  const removeSchema = (value: any, type = TypeButton.SUBMIT) => {
    setSchema((prev: any) => ({
      ...prev,
      [type]: prev[type].omit(value),
    }));
  };

  const updateSchema = (value: any, type = TypeButton.SUBMIT) => {
    setSchema((prev: any) => ({
      ...prev,
      [type]: prev[type].extend(value),
    }));
  };

  const mergeSchema = (value: any, type = TypeButton.SUBMIT) => {
    setSchema((prev: any) => ({
      ...prev,
      [type]: prev[type].merge(value),
    }));
  };

  const replaceSchema = (value: any, type = TypeButton.SUBMIT) => {
    setSchema((prev: any) => ({
      ...prev,
      [type]: value,
    }));
  };

  const value: any = {
    removeSchema,
    resetSchema,
    updateSchema,
    mergeSchema,
    replaceSchema,
    typePrimarySchema,
  };

  return <CreateSchemaContext.Provider value={value}>{children}</CreateSchemaContext.Provider>;
};
