import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { FormHelperText, Grid, IconButton, InputAdornment } from '@mui/material';
import { Button, ErrorMassage, FormControl, RadioButtonGroup, TextField, PasswordField } from 'common/components';
import DatePickerComponent from 'common/components/DatePickerNew';
import { CookieNames } from 'core/enums/cookie-names.enum';
import { getCookie, removeAllCookie, removeCookie, setCookie } from 'core/services/cookie.service';
import moment, { Moment } from 'moment';
import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'states/hooks';
import CheckIcon from '@mui/icons-material/Check';
import { z } from 'zod';
import { validatePasswordMessages } from 'pages/resetPassword';
import { EMPLOYEE_SCREENS, REGEX_PATTERN } from 'core/constants';
import { zodResolver } from '@hookform/resolvers/zod';
import ReCAPTCHA from 'react-google-recaptcha';
import { employeeSignUpWithoutTokenAsync, onboardingEmployeeCheckCredentialsAsync } from 'states/onboardingSlice';
import { RequestStatus } from 'core/enums/request-status.enum';
import { CredentialCodes, ErrorType, OnboardingEmployeeStages } from 'core/enums';
import { ROLES } from 'core/constants/roles';
import { createAnswerDataAsync } from 'states/employeeRiskQuestion/employeeRiskQuestionSlice';
import { setErrorMessage } from 'states/snackbarMessage/snackbarMessageSlice';
import { formatDateSendToServer } from 'core/functions/format-date';
import SelectYourCompanyModal from './SelectYourCompanyModal';
import { useAuth } from 'contexts/AuthProvider';
import CreateYourLoginErrorModal from './CreateYourLoginErrorModal';
import { getEmployeeProfileAsync, selectEmployeeProfile } from 'states/manageEmployee/manageEmployeeSlice';
import { EmployeeStatus } from 'core/enums/employee-status.enum';

interface IYourLoginWithInfo {
  userName: string;
  password: string;
  confirmPassword: string;
  firstName?: string;
  lastName?: string;
  workEmail?: string;
  personalEmail?: string;
  dob?: Moment | null;
  ssn?: string;
  isWorkEmailPrimary: string;
  reCaptchaToken: string;
}

type Props = {
  setStep: Dispatch<SetStateAction<number>>;
  getStep?: (screen: string) => number;
};
const CreateYourLoginWithInfo: FC<Props> = ({ setStep, getStep }) => {
  const dispatch = useAppDispatch();
  const auth = useAuth();
  const loading = useAppSelector((state) => state.onboarding.loading);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const userProfileIdCookie = getCookie(CookieNames.UserProfileId);
  const sessionIdCookie = getCookie(CookieNames.XCSSession);
  const reCaptchaRef = useRef<any>(null);
  const [companyId, setCompanyId] = useState<string>('');
  const [companyList, setCompanyList] = useState<any[]>([]);
  const [isMultipleCompanySelectOpen, setIMultipleCompanySelectOpen] = useState<boolean>(false);
  const [isErrorOpen, setIsErrorOpen] = useState<boolean>(false);
  const [profiles, setProfiles] = useState<any>();
  const [isNeedToCreateCert, setIsNeedToCreateCert] = useState<boolean>(false);
  const profile = useAppSelector(selectEmployeeProfile);

  const zodSchema = z
    .object({
      userName: z.string().superRefine((value, ctx) => {
        if (sessionIdCookie && userProfileIdCookie) return true;
        if (value === '') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Username is required',
          });
        }
        if (!REGEX_PATTERN.USER_NAME.pattern.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: REGEX_PATTERN.USER_NAME.message,
          });
        }
      }),
      password: z.string().superRefine((value, ctx) => {
        if (sessionIdCookie && userProfileIdCookie) return true;
        if (value === '') {
          ctx.addIssue({
            message: 'Password is required',
            code: z.ZodIssueCode.too_small,
            minimum: 8,
            exact: false,
            inclusive: true,
            type: 'string',
          });
        }
        if (value.length < 8) {
          ctx.addIssue({
            message: 'At least 8 characters',
            code: z.ZodIssueCode.custom,
          });
        }
        if (!REGEX_PATTERN.AT_LEAST_ONE_LOWERCASE.pattern.test(value)) {
          ctx.addIssue({
            message: REGEX_PATTERN.AT_LEAST_ONE_LOWERCASE.message,
            code: z.ZodIssueCode.custom,
          });
        }
        if (!REGEX_PATTERN.AT_LEAST_ONE_UPPERCASE.pattern.test(value)) {
          ctx.addIssue({
            message: REGEX_PATTERN.AT_LEAST_ONE_UPPERCASE.message,
            code: z.ZodIssueCode.custom,
          });
        }
        if (!REGEX_PATTERN.AT_LEAST_ONE_NUMBER.pattern.test(value)) {
          ctx.addIssue({
            message: REGEX_PATTERN.AT_LEAST_ONE_NUMBER.message,
            code: z.ZodIssueCode.custom,
          });
        }
        if (!REGEX_PATTERN.AT_LEAST_ONE_SPECIAL_CHARACTER.pattern.test(value)) {
          ctx.addIssue({
            message: REGEX_PATTERN.AT_LEAST_ONE_SPECIAL_CHARACTER.message,
            code: z.ZodIssueCode.custom,
          });
        }
      }),
      confirmPassword: z.string().superRefine((value, ctx) => {
        if (sessionIdCookie && userProfileIdCookie) return true;
        if (value === '') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Password is required',
          });
        }
        if (!REGEX_PATTERN.PASSWORD.pattern.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: REGEX_PATTERN.PASSWORD.message,
          });
        }
      }),
      workEmail: z.string().superRefine((value, ctx) => {
        if (sessionIdCookie && userProfileIdCookie) return true;
        if (value === '') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Email is required',
          });
        }
        if (!REGEX_PATTERN.EMAIL.pattern.test(value) && !sessionIdCookie) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: REGEX_PATTERN.EMAIL.message,
          });
        }
      }),
      personalEmail: z.string().superRefine((value, ctx) => {
        if (sessionIdCookie && userProfileIdCookie) return true;
        if (value === '') return;
        if (!REGEX_PATTERN.EMAIL.pattern.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: REGEX_PATTERN.EMAIL.message,
          });
        }
      }),
      isWorkEmailPrimary: z.string().refine(
        (value) => {
          if (value === '') return false;
          return true;
        },
        { message: 'This question is required the answer.' }
      ),
      firstName: z.string().superRefine((value, ctx) => {
        if (sessionIdCookie && userProfileIdCookie) {
          return true;
        }
        if (value === '') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'First name is required',
          });
        }
        if (!REGEX_PATTERN.TEXT.pattern.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: REGEX_PATTERN.TEXT.message,
          });
        }
      }),
      lastName: z.string().superRefine((value, ctx) => {
        if (sessionIdCookie && userProfileIdCookie) {
          return true;
        }
        if (value === '') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'Last name is required',
          });
        }
        if (!REGEX_PATTERN.TEXT.pattern.test(value)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: REGEX_PATTERN.TEXT.message,
          });
        }
      }),
      dob: z.custom().refine(
        (value) => {
          if (sessionIdCookie && userProfileIdCookie) {
            return true;
          } else {
            return !!value;
          }
        },
        { message: 'Please enter Date of Birth' }
      ),
      ssn: z.string().superRefine((value, ctx) => {
        if (sessionIdCookie && userProfileIdCookie) return true;
        if (value === '') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: 'SSN is required',
          });
        }
        if (!REGEX_PATTERN.LAST_FOUR_SSN.pattern.test(value)) {
          setValue('ssn', value.replace(/[^0-9]/g, ''));
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: REGEX_PATTERN.LAST_FOUR_SSN.message,
          });
        }
      }),
      reCaptchaToken: z.string().refine((value) => value, { message: 'Please check the reCaptcha' }),
    })
    .refine(
      (schema) => {
        if (userProfileIdCookie && sessionIdCookie) return true;
        if (schema.password && schema.confirmPassword) return schema.password === schema.confirmPassword;
        return true;
      },
      {
        path: ['confirmPassword'],
        message: 'Passwords do not match',
      }
    )
    .refine(
      (schema) => {
        if (userProfileIdCookie && sessionIdCookie) return true;
        if (schema.isWorkEmailPrimary === 'no') {
          if (schema.personalEmail === '') {
            return false;
          }
          if (!REGEX_PATTERN.EMAIL.pattern.test(schema.personalEmail)) {
            return false;
          }
        }
        return true;
      },
      {
        path: ['personalEmail'],
        message: 'Personal Email invalid',
      }
    );

  const DEFAULT_VALUES: IYourLoginWithInfo = {
    userName: profile?.person?.userName || '',
    password: '',
    confirmPassword: '',
    firstName: profile?.person?.firstName || '',
    lastName: profile?.person?.lastName || '',
    workEmail: profile?.workEmail || '',
    personalEmail: profile?.person?.personalEmail || '',
    dob: profile?.person?.dob ? moment.utc(profile?.person?.dob) : null,
    ssn: '',
    isWorkEmailPrimary:
      typeof profile?.workEmailPrimary === 'boolean' ? (profile?.workEmailPrimary ? 'yes' : 'no') : '',
    reCaptchaToken: '',
  };

  const {
    handleSubmit,
    register,
    setError,
    control,
    watch,
    setValue,
    reset,
    formState: { errors, dirtyFields, isValid: isFormValid },
  } = useForm<IYourLoginWithInfo>({
    defaultValues: DEFAULT_VALUES,
    resolver: zodResolver(zodSchema),
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    mode: 'onChange',
  });

  const checkValid = () => {
    if (userProfileIdCookie && sessionIdCookie) {
      return true;
    }
    return isFormValid;
  };

  const isValid = checkValid();

  const createAnswerAndSetCookie = async (payload: any, cId?: string) => {
    const {
      id: sessionId,
      currentUserProfileId,
      values: { personId },
    } = payload;
    setCookie(CookieNames.PersonId, personId);
    setCookie(CookieNames.XCSSession, sessionId);
    setCookie(CookieNames.UserProfileId, currentUserProfileId);
    setCookie(CookieNames.CurrentCompanyId, cId || companyId);
    setCookie(CookieNames.CurrentRole, ROLES.PARTICIPANT);
    await dispatch(createAnswerDataAsync(currentUserProfileId));
  };

  const onSubmitHandler = async (values: IYourLoginWithInfo) => {
    //Reset cookies
    if (userProfileIdCookie && profile?.person.userName) {
      if (!watch('reCaptchaToken')) {
        dispatch(setErrorMessage('Please check the reCaptcha'));
      } else {
        setStep(EMPLOYEE_SCREENS.AccountPreference);
        setCookie(CookieNames.OnboardingStep, EMPLOYEE_SCREENS.AccountPreference);
      }
    } else if (userProfileIdCookie && !profile?.person.userName) {
      dispatch(setErrorMessage('Something went wrong. Please try again!'));
      removeAllCookie();
      reset();
      setStep(EMPLOYEE_SCREENS.CreateYourLogin);
      setCookie(CookieNames.OnboardingStep, EMPLOYEE_SCREENS.CreateYourLogin);
    } else {
      await checkYourInfoAsync(values);
    }
    reCaptchaRef?.current?.reset();
  };

  const checkYourInfoAsync = async (values: IYourLoginWithInfo) => {
    setCompanyId('');
    setCompanyList([]);
    removeCookie(CookieNames.XCSSession);
    removeCookie(CookieNames.PersonId);
    removeCookie(CookieNames.UserProfileId);
    removeCookie(CookieNames.CurrentCompanyId);
    removeCookie(CookieNames.CurrentRole);
    setProfiles([]);
    setCompanyList([]);
    const infoPayload: any = {
      person: {
        workEmail: values.workEmail,
        personalEmail: values?.isWorkEmailPrimary === 'no' ? values.personalEmail : values.workEmail,
        firstName: values.firstName,
        lastName: values.lastName,
        dob: values.dob ? formatDateSendToServer(values.dob) : '',
        ssn: window.btoa(values.ssn || ''),
      },
    };
    const checkInfoResponse = await dispatch(
      onboardingEmployeeCheckCredentialsAsync({
        payload: infoPayload,
      })
    );
    if (checkInfoResponse.meta.requestStatus === RequestStatus.FULFILLED) {
      const code = checkInfoResponse?.payload?.code;
      if (code !== CredentialCodes.USER_MATCHED) {
        dispatch(
          setErrorMessage(
            'Please review the data you entered. If it is correct, please reach out to your plan administrator, to make the necessary corrections.'
          )
        );
        return;
      } else {
        const currentSession = checkInfoResponse?.payload?.session;
        const userProfileStatus = currentSession?.values?.userProfileStatus;
        if (userProfileStatus === EmployeeStatus.NEW) {
          dispatch(
            setErrorMessage(
              'Please review the data you entered. If it is correct, please reach out to your plan administrator, to make the necessary corrections.'
            )
          );
          return;
        }
        let isNeedToCreateCert = false;
        const loginInfoPayload: any = {
          person: {
            email: values.workEmail,
            userName: values.userName,
            password: values.password,
          },
        };
        const checkLoginInfoResponse = await dispatch(
          onboardingEmployeeCheckCredentialsAsync({
            payload: loginInfoPayload,
          })
        );
        if (checkLoginInfoResponse.meta.requestStatus === RequestStatus.FULFILLED) {
          const loginCode = checkLoginInfoResponse?.payload?.code;
          if (loginCode === CredentialCodes.INVALID_CREDENTIAL || loginCode === CredentialCodes.EMAIL_NOT_FOUND) {
            dispatch(
              setErrorMessage(
                'Please review the data you entered. If it is correct, please reach out to your plan administrator, to make the necessary corrections.'
              )
            );
            return;
          } else if (loginCode === CredentialCodes.USERNAME_NOT_FOUND) {
            //sign up
            setIsNeedToCreateCert(true);
            isNeedToCreateCert = true;
          } else if (loginCode === CredentialCodes.USER_MATCHED) {
            if (!checkInfoResponse?.payload?.profiles?.length) {
              setIsErrorOpen(true);
              return;
            }
            //valid
            dispatch(
              setErrorMessage(
                'The credential along with this email is already taken. If it’s you, select “Continue where you left off” instead.'
              )
            );
            return;
          }
        } else {
          setIsErrorOpen(true);
          return;
        }
        const { companies, profiles } = checkInfoResponse.payload;
        setProfiles(profiles);
        if (!profiles?.length) {
          setIsErrorOpen(true);
          return false;
        }
        if (profiles.length === 1) {
          const targetCompanyId = profiles[0].companyId;
          setCompanyId(targetCompanyId);
          setCookie(CookieNames.CurrentCompanyId, targetCompanyId);
          await processWithCompanyId(profiles, targetCompanyId, isNeedToCreateCert);
        } else if (profiles.length > 1) {
          const remainCompanies = companies.filter((cp: any) =>
            profiles.map((pf: any) => pf.companyId).includes(cp.id)
          );
          if (!remainCompanies.length) {
            setIsErrorOpen(true);
            return false;
          }
          setCompanyList(remainCompanies);
          setIMultipleCompanySelectOpen(true);
        }
      }
    } else {
      setIsErrorOpen(true);
      return;
    }
  };

  const signUpWithoutTokenAsync = async (cId: string) => {
    const values = watch();
    const payload: any = {
      password: values.password,
      userName: values.userName,
      workEmail: values.workEmail,
      personalEmail: values.personalEmail,
      workEmailPrimary: values.isWorkEmailPrimary === 'yes' ? true : false,
      firstName: values.firstName,
      lastName: values.lastName,
      dob: values.dob ? formatDateSendToServer(values.dob) : '',
      ssn: values.ssn,
    };
    const response: any = await dispatch(
      employeeSignUpWithoutTokenAsync({
        payload,
        companyId: cId,
        config: { headers: { ReCaptcha: values.reCaptchaToken } },
      })
    );
    if (response.meta.requestStatus === RequestStatus.FULFILLED) {
      await createAnswerAndSetCookie(response.payload, cId);
    } else if (response.meta.requestStatus === RequestStatus.REJECTED) {
      const errorResponse: any = JSON.parse(response.error.message as string);
      if (errorResponse.errorType === ErrorType.DUPLICATED_FIELD) {
        const fieldName = errorResponse.error.split(' ')[1];
        dispatch(setErrorMessage(`The ${fieldName} is already used. Please use another ${fieldName}!`));
      }
    }
  };

  const onConfirmedCompany = async () => {
    setCookie(CookieNames.CurrentCompanyId, companyId);
    setIMultipleCompanySelectOpen(false);
    await processWithCompanyId(profiles, companyId, isNeedToCreateCert);
  };

  const onCancel = () => {
    setIMultipleCompanySelectOpen(false);
    removeCookie(CookieNames.XCSSession);
    removeCookie(CookieNames.CurrentRole);
    removeCookie(CookieNames.CurrentCompanyId);
  };

  const processWithCompanyId = async (profileList: any, cId: string, isNeedToCreateCert: boolean) => {
    if (!profileList) return;
    setCookie(CookieNames.CurrentCompanyId, cId);
    if (isNeedToCreateCert) {
      await signUpWithoutTokenAsync(cId);
    }
    const targetProfile = profileList?.find((item: any) => item?.companyId === cId);
    await auth?.updateSession();
    const res = await dispatch(getEmployeeProfileAsync());
    if (
      (res?.payload?.status === EmployeeStatus.ACTIVE && res?.payload?.person.password !== undefined) ||
      res?.payload?.status === EmployeeStatus.EMPLOYMENT_ENDED
    ) {
      dispatch(
        setErrorMessage(
          'Your account is not associated with any invitation. If you already have the account, please login or contact to Saveday team instead.'
        )
      );
      return;
    }
    if (targetProfile?.onboardingLatestStage !== OnboardingEmployeeStages.EP_CREATE_CREDENTIAL) {
      if (getStep) {
        setStep(getStep(targetProfile?.onboardingLatestStage));
        setCookie(CookieNames.OnboardingStep, getStep(targetProfile?.onboardingLatestStage));
      } else {
        setStep(EMPLOYEE_SCREENS.AccountPreference);
        setCookie(CookieNames.OnboardingStep, EMPLOYEE_SCREENS.AccountPreference);
      }
      return;
    } else {
      setStep(EMPLOYEE_SCREENS.AccountPreference);
      setCookie(CookieNames.OnboardingStep, EMPLOYEE_SCREENS.AccountPreference);
    }
  };

  useEffect(() => {
    if (profile?.id) {
      reset(DEFAULT_VALUES);
    }
  }, [profile?.id]);

  return (
    <>
      <form className="my-4" onSubmit={handleSubmit(onSubmitHandler)}>
        <Grid container spacing={2}>
          <>
            <Grid item zero={12} className="text-md font-semibold text-primary mt-2">
              Please verify your identity
            </Grid>
            <Grid item zero={12}>
              <TextField
                label={
                  <>
                    <span className="text-xs font-semibold">Work Email</span>{' '}
                    <span className="text-xs font-normal">(the one you received this email to)</span>
                  </>
                }
                registerForm={{ ...register('workEmail') }}
                placeholder="Type email address"
                error={errors.workEmail?.message}
                disabled={userProfileIdCookie}
                required={true}
              />
            </Grid>
            <Grid item zero={12} sm={6}>
              <TextField
                label={<span className="text-xs font-semibold">{`First Name`}</span>}
                registerForm={{ ...register('firstName') }}
                placeholder="Type first name"
                disabled={userProfileIdCookie}
                error={errors.firstName?.message}
                required={true}
              />
            </Grid>
            <Grid item zero={12} sm={6}>
              <TextField
                label={<span className="text-xs font-semibold">{`Last Name`}</span>}
                registerForm={{ ...register('lastName') }}
                placeholder="Type last name"
                disabled={userProfileIdCookie}
                error={errors.lastName?.message}
                required={true}
              />
            </Grid>
            <Grid item zero={12} sm={6}>
              <Controller
                name="dob"
                control={control}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <DatePickerComponent
                      {...field}
                      label={<span className="text-xs font-semibold">{`Date of Birth`}</span>}
                      onAccept={(newValue) => {
                        if (!newValue) {
                          setValue('dob', null, { shouldDirty: true });
                        }
                      }}
                      disabled={userProfileIdCookie}
                      error={errors?.dob?.message as string}
                      required={true}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item zero={12} sm={6}>
              <Controller
                control={control}
                name="ssn"
                render={({ field, fieldState }) =>
                  userProfileIdCookie ? (
                    <PasswordField
                      {...field}
                      value="****"
                      label="SSN"
                      disabled
                      required
                      inputProps={{ maxLength: 4 }}
                      InputProps={{
                        endAdornment: <></>,
                      }}
                    />
                  ) : (
                    <TextField
                      {...field}
                      onChange={(e) => {
                        field.onChange(e.target.value.replaceAll(/\D/g, ''));
                      }}
                      label={
                        <span className="text-xs font-semibold">{`Last four digits of your Social Security Number`}</span>
                      }
                      placeholder="Type last 4 digits of SSN"
                      error={fieldState.error?.message}
                      disabled={userProfileIdCookie}
                      inputProps={{ maxLength: 4 }}
                      required={true}
                    />
                  )
                }
              />
            </Grid>
          </>
          <Grid item zero={12} className="text-md font-semibold text-primary mt-2">
            Create your Saveday login credentials
          </Grid>
          <Grid item zero={12} sm={6}>
            <TextField
              registerForm={{ ...register('userName') }}
              placeholder="Type username"
              error={errors.userName?.message}
              label={<span className="text-xs font-semibold">{`Username`}</span>}
              disabled={userProfileIdCookie}
              required={true}
            />
          </Grid>
          <Grid item zero={12} sm={6}>
            <TextField
              label={<span className="text-xs font-semibold">{`Personal Email`}</span>}
              registerForm={{ ...register('personalEmail') }}
              placeholder="mail@example.com"
              error={errors.personalEmail?.message}
              disabled={userProfileIdCookie}
              required={watch('isWorkEmailPrimary') === 'no'}
            />
          </Grid>
          <Grid item zero={12} sm={6}>
            <>
              {userProfileIdCookie ? (
                <PasswordField
                  value="*********"
                  label="Password"
                  disabled
                  required
                  InputProps={{
                    endAdornment: <></>,
                  }}
                />
              ) : (
                <TextField
                  label={<span className="text-xs font-semibold">{`Password`}</span>}
                  type={showPassword ? 'text' : 'password'}
                  placeholder="Type password here"
                  registerForm={{ ...register('password') }}
                  autoComplete="new-password"
                  disabled={userProfileIdCookie}
                  required={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={errors.password?.type === z.ZodIssueCode.too_small ? errors.password?.message : ''}
                />
              )}
              {dirtyFields.password &&
                Object.entries(validatePasswordMessages).map(([type, message]) => (
                  <div className="flex gap-1 items-center mt-2" key={type}>
                    <CheckIcon
                      className={[
                        'text-xl',
                        ((errors.password?.types?.custom as string[]) || []).includes(message)
                          ? 'text-primary'
                          : 'text-secondary',
                      ].join(' ')}
                    />
                    <p className="text-primary text-xs">{message}</p>
                  </div>
                ))}
            </>
          </Grid>
          <Grid item zero={12} sm={6}>
            {userProfileIdCookie ? (
              <PasswordField
                value="*********"
                label="Confirm Password"
                disabled
                required
                InputProps={{
                  endAdornment: <></>,
                }}
              />
            ) : (
              <TextField
                label={<span className="text-xs font-semibold">{`Confirm Password`}</span>}
                type={showConfirmPassword ? 'text' : 'password'}
                placeholder="Type password here"
                registerForm={{ ...register('confirmPassword') }}
                disabled={userProfileIdCookie}
                required={true}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowConfirmPassword(!showConfirmPassword)} edge="end">
                        {showConfirmPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={errors.confirmPassword?.message}
              />
            )}
          </Grid>
          <Grid item className="flex items-center" zero={12} md={9}>
            <div className="text-xs text-primary">
              Please specify the email address you prefer for us to use as your primary contact:
            </div>
          </Grid>
          <Grid item className="flex items-center flex-col" zero={12} md={3}>
            <Controller
              control={control}
              name="isWorkEmailPrimary"
              render={({ field, fieldState }) => {
                return (
                  <>
                    <RadioButtonGroup
                      {...field}
                      disabled={userProfileIdCookie}
                      row
                      sx={{
                        justifyContent: {
                          zero: 'flex-start',
                          md: 'flex-end',
                        },
                        gap: 2,
                        '.MuiFormControlLabel-root': {
                          fontSize: '14px !important',
                          fontWeight: '600 !important',
                          color: (theme) => theme.palette.normalText,
                          margin: 0,
                        },
                      }}
                      options={[
                        { label: 'Work', value: 'yes' },
                        { label: 'Personal', value: 'no' },
                      ]}
                      error={!!fieldState.error?.message}
                    />
                    {fieldState.error?.message && (
                      <FormHelperText
                        className="w-full sm:text-right text-left text-xs text-errorColor mt-2"
                        error={!!fieldState.error.message}
                      >
                        {fieldState.error.message}
                      </FormHelperText>
                    )}
                  </>
                );
              }}
            />
          </Grid>
          <Grid item zero={12}>
            <Controller
              name="reCaptchaToken"
              control={control}
              render={({ field: { onChange } }) => {
                return (
                  <ReCAPTCHA
                    className="w-full"
                    ref={reCaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
                    onChange={onChange}
                    onExpired={() => {
                      onChange(null);
                      setError('reCaptchaToken', {
                        message: 'Expire, Please check the reCaptcha',
                      });
                    }}
                  />
                );
              }}
            />
            {errors.reCaptchaToken?.message && <ErrorMassage message={errors.reCaptchaToken?.message} />}
          </Grid>
        </Grid>

        <div className="w-full text-right mt-10">
          <Button
            variant="contained"
            color="secondary"
            disabled={!isValid || loading}
            type="submit"
            sx={{
              width: '100%',
              maxWidth: {
                zero: '100%',
                sm: '220px',
              },
            }}
          >
            Next
          </Button>
        </div>
      </form>
      <SelectYourCompanyModal
        isOpen={isMultipleCompanySelectOpen}
        onClose={onConfirmedCompany}
        onCancel={onCancel}
        companyId={companyId}
        setCompanyId={setCompanyId}
        companyList={companyList}
      />
      <CreateYourLoginErrorModal isOpen={isErrorOpen} onClose={() => setIsErrorOpen(!isErrorOpen)} />
    </>
  );
};

export default CreateYourLoginWithInfo;
