import { FormHelperText, FormLabelProps } from '@mui/material';
import { Button, FormControl } from 'common/components';
import { appPalette } from 'core/constants';
import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from 'react';
import ReactSignatureCanvas, { ReactSignatureCanvasProps } from 'react-signature-canvas';
import { resizeBase64Image } from 'core/functions/converter';

type Props = ReactSignatureCanvasProps & {
  error?: string;
  onClear?: () => void;
  onChange?: (value: string) => void;
  width?: number;
  label?: string;
  formLabelProps?: FormLabelProps;
  value?: string;
  required?: boolean;
};

const SignatureCanvas = forwardRef<any, Props>(
  ({ error, onClear, onChange, width = 450, label, formLabelProps, value, ...props }, ref) => {
    const wrapperRef = useRef<any>(null);
    const signatureRef = React.useRef<any>(null);
    const [canvasWidth, setCanvasWidth] = useState<number>(wrapperRef?.current?.clientWidth);
    const [signValue, setSignValue] = useState<string>(value || '');

    let debounce: any;
    useEffect(() => {
      const onResize = () => {
        clearTimeout(debounce);
        debounce = setTimeout(function () {
          setCanvasWidth(wrapperRef?.current?.clientWidth);
          onClearHandler();
        }, 350);
      };
      onResize();
      setCanvasWidth(wrapperRef?.current?.clientWidth);
      window.addEventListener('resize', onResize);
      return () => {
        window.removeEventListener('resize', onResize);
      };
    }, []);

    const onClearHandler = () => {
      if (onClear) {
        onClear();
      }
      signatureRef?.current?.clear();
      setSignValue('');
    };

    useEffect(() => {
      if (onChange) {
        onChange(signValue);
      }
    }, [signValue]);

    useImperativeHandle(ref, () => ({
      ...signatureRef.current,
    }));

    return (
      <FormControl
        label={label}
        fullWidth
        formLabelProps={formLabelProps}
        sx={{
          '.MuiFormLabel-root': {
            height: '20px',
            maxHeight: '20px',
            textAlign: 'left',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        }}
        required={props.required}
      >
        <div className="relative w-full h-full" ref={wrapperRef}>
          <div className="absolute right-0 top-0">
            <Button variant="text" className="px-4 min-w-0 w-auto" onClick={onClearHandler}>
              Clear
            </Button>
          </div>
          <ReactSignatureCanvas
            ref={signatureRef}
            clearOnResize={false}
            onEnd={() => {
              const base64Sign = signatureRef.current.toDataURL();
              resizeBase64Image(base64Sign).then((res) => setSignValue(res));
            }}
            canvasProps={{
              height: 150,
              width: canvasWidth,
              style: {
                borderRadius: '0.5rem',
                border: `1px solid
                  ${!!error ? appPalette.errorColor : appPalette.borderColor}`,
              },
              ...props.canvasProps,
            }}
            {...props}
          />
          {error && <FormHelperText className="text-errorColor ml-0">{error}</FormHelperText>}
        </div>
      </FormControl>
    );
  }
);

export default SignatureCanvas;
