import { Button, Typography } from "@mui/material";
import { deepClone } from "@mui/x-data-grid/utils/utils";
import BasicModal from "common/components/BasicModal";
import { RequestStatus } from "core/enums/request-status.enum";
import { ContributionDto } from "core/models/EmployerContributionDto.model";
import { PayRollDto } from "core/models/payrollDto.model";
import { useEffect } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import {
  getZeroContributionAsync,
  selectEmployerProcessContributionsData,
  selectEmployerProcessContributionsIsModalOpen,
  selectEmployerProcessContributionsModalType,
  selectEmployerProcessContributionsUpdatePayCheckDateResponseText,
  selectModalDisplayList,
  selectZeroContribution,
  setIsModalOpen,
  setModalDisplayList,
  setModalType,
  setPayrollList,
} from "states/employerProcessContributions/employerProcessContributionsSlice";
import { useAppDispatch, useAppSelector } from "states/hooks";
import {
  removePayrollContributionAsync,
  updatePayrollContributionAsync,
} from "states/payroll/payrollSlice";
import { setSuccessMessage } from "states/snackbarMessage/snackbarMessageSlice";

type Props = {
  isSystemAdmin?: boolean;
};

export enum ModalTypes {
  UpdatePayCheckDateModal = "UpdatePayCheckDateModal",
  ZeroContributionDetectedModal = "ZeroContributionDetectedModal",
  WrongContributionTypeDetectedModal = "WrongContributionTypeDetectedModal",
  ExceedAnnualContributionDetectedModal = "ExceedAnnualContributionDetectedModal",
  DeleteContributionModal = "DeleteContributionModal",
  TerminatedContributionPersonDetected = "TerminatedContributionPersonDetected",
}

const RenderUpdatePayCheckDateModalContent = () => {
  const updatePayCheckDateResponse = useAppSelector(
    selectEmployerProcessContributionsUpdatePayCheckDateResponseText
  );

  return (
    <>
      <Typography className="text-darkGreen text-xl font-bold">
        {updatePayCheckDateResponse?.responseCode || ""}
      </Typography>
      <Typography className="text-primary text-sm font-normal mt-6">
        {updatePayCheckDateResponse?.responsesText || ""}
      </Typography>
    </>
  );
};

const RenderZeroContributionDetectedModalContent = ({
  data,
}: {
  data: ContributionDto[];
}) => {
  return (
    <>
      <Typography className="text-secondary text-xl font-bold">
        We detected $0.00 contributions
      </Typography>
      <Typography className="text-primary text-sm font-normal mt-6">
        We detected $0.00 contribution for the following employees. Please click
        "Proceed" to continue or click "Edit" to make changes.
      </Typography>
      <div className="mt-4 max-h-[200px] overflow-auto">
        {!!data.length &&
          data.map((item: ContributionDto) => (
            <Typography
              key={item.id}
              className="text-primary text-sm font-normal max-w-[400px] overflow-hidden whitespace-nowrap text-ellipsis"
            >
              {item.firstName} {item.lastName}
            </Typography>
          ))}
      </div>
    </>
  );
};

const RenderWrongContributionTypeDetectedModalContent = ({
  data,
}: {
  data: ContributionDto[];
}) => {
  return (
    <>
      <Typography className="text-secondary text-xl font-bold">
        We detected Wrong contribution type
      </Typography>
      <Typography className="text-primary text-sm font-normal mt-6">
        Wrong contribution type for the following employees. Please click
        "Proceed" to continue or click "Edit" to make changes.
      </Typography>
      <div className="mt-4 max-h-[200px] overflow-auto">
        {!!data.length &&
          data.map((item: ContributionDto) => (
            <Typography
              key={item.id}
              className="text-primary text-sm font-normal max-w-[400px] overflow-hidden whitespace-nowrap text-ellipsis"
            >
              {item.firstName} {item.lastName}
            </Typography>
          ))}
      </div>
    </>
  );
};

const RenderExceededAnnualLimitsDetectedModalContent = ({
  data,
}: {
  data: ContributionDto[];
}) => {
  return (
    <>
      <Typography className="text-secondary text-xl font-bold">
        We detected excess annual contributions
      </Typography>
      <Typography className="text-primary text-sm font-normal mt-6">
        Adding contributions for the following employees will excess their
        annual contributions limit. Please adjust the contributions for the
        following employees. If you still wish to proceed, click "Proceed".
      </Typography>
      <div className="mt-4 max-h-[200px] overflow-auto">
        {!!data.length &&
          data.map((item: ContributionDto) => (
            <Typography
              key={item.id}
              className="text-primary text-sm font-normal max-w-[400px] overflow-hidden whitespace-nowrap text-ellipsis"
            >
              {item.firstName} {item.lastName}
            </Typography>
          ))}
      </div>
    </>
  );
};

const RenderTerminatedPersonDetectedModalContent = ({
  data,
}: {
  data: ContributionDto[];
}) => {
  return (
    <>
      <Typography className="text-secondary text-xl font-bold">
        We detected Terminated employees
      </Typography>
      <Typography className="text-primary text-sm font-normal mt-6">
        The following employees are listed as Terminated for this company.
        Please click "Proceed" to continue or click "Edit" to make changes.
      </Typography>
      <div className="mt-4 max-h-[200px] overflow-auto">
        {!!data.length &&
          data.map((item: ContributionDto) => (
            <Typography
              key={item.id}
              className="text-primary text-sm font-normal max-w-[400px] overflow-hidden whitespace-nowrap text-ellipsis"
            >
              {item.firstName} {item.lastName}
            </Typography>
          ))}
      </div>
    </>
  );
};

const RenderDeleteContributionModalContent = ({
  contributionId,
}: {
  contributionId: string;
}) => {
  return (
    <>
      <Typography className="text-primary text-sm text-center">
        Are you sure you want to delete - {contributionId}
      </Typography>
    </>
  );
};

const EmployerProcessContributionsModal: React.FC<Props> = ({
  isSystemAdmin = false,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const data = useAppSelector(selectEmployerProcessContributionsData);
  const zeroContribution = useAppSelector(selectZeroContribution);
  const isModalOpen = useAppSelector(
    selectEmployerProcessContributionsIsModalOpen
  );
  const modalType = useAppSelector(selectEmployerProcessContributionsModalType);
  const modalTypeList = useAppSelector(selectModalDisplayList);
  const isDeleteModal = modalType === ModalTypes.DeleteContributionModal;
  const loading = useAppSelector((state) => state.payroll.loading);
  const employerProcessContributionLoading = useAppSelector(state => state.employerProcessContributions.loading);

  useEffect(() => {
    return () => {
      dispatch(setIsModalOpen(false));
      dispatch(setModalDisplayList([]));
    };
  }, []);

  const resetModalState = () => {
    dispatch(setIsModalOpen(false));
    dispatch(setModalType(null));
    dispatch(setModalDisplayList([]));
    dispatch(setPayrollList([]));
  };

  const onShowModal = async () => {
    const newModalTypeList = [...modalTypeList];
    if (newModalTypeList.length > 0) {
      const modalType = newModalTypeList.shift();
      dispatch(setModalDisplayList(newModalTypeList));
      dispatch(setModalType(modalType));
    } else {
      if (!isSystemAdmin) {
        navigate({
          pathname: "/dashboard/employer/payrollpreview",
          search: createSearchParams({
            initial: "false",
            profitSharing: "true",
            payrollNav: "true",
          }).toString(),
        });
      } else {
        if (data) {
          const payload = deepClone(data) as PayRollDto;
          const response = await dispatch(
            updatePayrollContributionAsync(payload)
          );
          if (response.meta.requestStatus === RequestStatus.FULFILLED) {
            navigate("/admin/managepayroll");
          }
        }
      }
      resetModalState();
    }
  };

  const onProceed = async () => {
    if (modalType === ModalTypes.UpdatePayCheckDateModal) {
      const response: any = await dispatch(
        getZeroContributionAsync({
          payConId: null,
          payrollContribution: data,
        })
      );
      if (response.meta.requestStatus === RequestStatus.FULFILLED) {
        const {
          annualLimitExceededPersonList,
        } = response.payload;
        if (
          !annualLimitExceededPersonList.length
        ) {
          navigate({
            pathname: "/dashboard/employer/payrollpreview",
            search: createSearchParams({
              initial: "false",
              profitSharing: "true",
              payrollNav: "true",
            }).toString(),
          });
        }
      }
    } else {
      onShowModal();
    }
  };

  const onDeleteContribution = async (id: string | undefined) => {
    const response = await dispatch(removePayrollContributionAsync(id));
    if (response.meta.requestStatus === RequestStatus.FULFILLED) {
      dispatch(setSuccessMessage(`Delete payroll ${id} successfully!`));
      resetModalState();
      navigate("/admin/managepayroll");
    }
  };

  return (
    <BasicModal
      isOpen={isModalOpen}
      handleClose={() => resetModalState()}
      className={["w-[550px]"].join(
        `${modalType === ModalTypes.DeleteContributionModal
          ? "text-center"
          : "text-right"
        }`
      )}
    >
      {modalType === ModalTypes.UpdatePayCheckDateModal && (
        <RenderUpdatePayCheckDateModalContent />
      )}
      {modalType === ModalTypes.WrongContributionTypeDetectedModal && (
        <RenderWrongContributionTypeDetectedModalContent
          data={zeroContribution?.wrongContributionTypeList || []}
        />
      )}
      {modalType === ModalTypes.ExceedAnnualContributionDetectedModal && (
        <RenderExceededAnnualLimitsDetectedModalContent
          data={zeroContribution?.annualLimitExceededPersonList || []}
        />
      )}
      {modalType === ModalTypes.ZeroContributionDetectedModal && (
        <RenderZeroContributionDetectedModalContent
          data={zeroContribution?.zeroContributionPersonList || []}
        />
      )}
      {modalType === ModalTypes.TerminatedContributionPersonDetected && (
        <RenderTerminatedPersonDetectedModalContent
          data={zeroContribution?.terminatedContributionPersonList || []}
        />
      )}
      {modalType === ModalTypes.DeleteContributionModal && (
        <RenderDeleteContributionModalContent contributionId={data?.id || ""} />
      )}
      <div
        className={["mt-6", isDeleteModal ? "text-center" : "text-right"].join(
          " "
        )}
      >
        <Button
          className="w-[100px]"
          variant="outlined"
          color="secondary"
          onClick={() => resetModalState()}
        >
          {isDeleteModal ? "Cancel" : "Edit"}
        </Button>
        <Button
          className="ml-4 w-[100px]"
          variant="contained"
          color="secondary"
          disabled={loading || employerProcessContributionLoading}
          onClick={
            isDeleteModal
              ? () => onDeleteContribution(data?.id)
              : () => onProceed()
          }
        >
          {isDeleteModal ? "Continue" : "Proceed"}
        </Button>
      </div>
    </BasicModal>
  );
};

export default EmployerProcessContributionsModal;
