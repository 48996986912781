export const formatCurrency = (value: number | string, options?: any) => {
  if (value === undefined || value === null) {
    return "N/A";
  }
  return Number(value).toLocaleString(
    "en-US",
    options
      ? options
      : {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
  );
};

// ex: 0E-10 to 0
export const castStringToDecimal = (value: string) => {
  if (!value) {
    return "N/A";
  } else {
    return value.toLowerCase().includes("e") ? 0 : value;
  }
};

export const formatCurrencyToNumber = (value?: string) => {
  if (value === undefined) {
    return 0;
  }
  return Number(value.replace(/[^0-9.-]+/g, ""));
};
