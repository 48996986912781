import BasicModal, { ModalProps } from 'common/components/BasicModal';
import React, { useEffect, useRef, useState } from 'react';
import ReceiveCodeOptions from './block/ReceiveCodeOptions';
import FormVerifiedCode from './block/FormVerifiedCode';
import { EnabledTwoFARequest } from 'core/models/employeeDto.model';
import { useAppDispatch, useAppSelector } from 'states/hooks';
import { enableTwoFAAsync, selectEmployeeProfile } from 'states/manageEmployee/manageEmployeeSlice';
import ReceiveCodeByAPP from './block/ReceiveCodeByAPP';
import ReceiveCodeBySMS from './block/ReceiveCodeBySMS';
import { TWO_FA_METHOD, TWO_FA_STATUS } from 'core/constants/roles';
import { useDetectKeyboardOpen } from 'hooks/useDetectKeyboard';

interface IModalTwoFAProps {
  title: string;
  subTitle?: string;
  isOpen: boolean;
  action: string;
  onClose: (response?: any) => Promise<void> | void;
}

const ModalTwoFA = ({ title, subTitle, isOpen, action, onClose }: IModalTwoFAProps) => {
  const dispatch = useAppDispatch();
  const employeeProfile = useAppSelector(selectEmployeeProfile);
  const twoFA = useAppSelector((state) => state.manageEmployee.twoFA);
  const [method, setMethod] = useState(twoFA?.method || '');
  const elementRef = useRef<HTMLElement | any>();
  const isDetectVirtualKeyboard = useDetectKeyboardOpen();

  const handleOnChange = (e: any, option: string) => {
    setMethod(option);
    if (option === TWO_FA_METHOD.AUTH_APP) {
      handleEnabledTwoFA(TWO_FA_METHOD.AUTH_APP);
    }
  };
  const handleEnabledTwoFA = (method: string, phoneNumber?: string) => {
    const payload: EnabledTwoFARequest = {
      personId: employeeProfile?.personId || '',
      method,
    };
    if (phoneNumber) {
      payload.phoneNumber = phoneNumber;
    }
    dispatch(enableTwoFAAsync(payload));
  };

  useEffect(() => {
    if (isOpen && twoFA?.method) {
      setMethod(twoFA.method);
    }
  }, [isOpen, twoFA]);
  useEffect(() => {
    if (isOpen && twoFA?.status === TWO_FA_STATUS.ACTIVE && twoFA?.method === TWO_FA_METHOD.SMS) {
      // dispatch(resendCodeSMSAsync()); // This is double sending
    }
  }, [isOpen]);

  useEffect(() => {
    if (isDetectVirtualKeyboard) {
      setTimeout(() => {
        elementRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  }, [isDetectVirtualKeyboard]);

  const basicModalProps: ModalProps = {
    isOpen,
    modalProps: {
      className: isDetectVirtualKeyboard ? 'h-3/5' : '',
    },
    className: 'w-full max-w-[640px] min-w-0 text-darkGreen max-h-full overflow-y-auto',
  };
  if (!method) {
    basicModalProps.handleClose = onClose;
  }
  return (
    <>
      <BasicModal {...basicModalProps}>
        <div className="text-center mb-6">
          <h3 className="font-bold text-superBlack">{title}</h3>
          {subTitle && <p className="text-deepGrey text-sm mt-4">{subTitle}</p>}
        </div>
        <ReceiveCodeOptions
          onChange={handleOnChange}
          isDisabled={twoFA?.status === TWO_FA_STATUS.ACTIVE}
          value={method}
        />
        <div className="text-center">
          {method === TWO_FA_METHOD.SMS && <ReceiveCodeBySMS handleEnabledTwoFA={handleEnabledTwoFA} />}
          {method === TWO_FA_METHOD.AUTH_APP && <ReceiveCodeByAPP />}
          {method === TWO_FA_METHOD.BACKUP_CODE && (
            <p className="text-sm text-primary">Enter the 6-digit your backup code:</p>
          )}
        </div>
        {method && (
          <FormVerifiedCode
            method={method}
            action={action}
            onCancel={async () => {
              await onClose();
            }}
            onSuccess={async (response: any) => {
              await onClose(response);
            }}
          />
        )}
        <div ref={elementRef} className="mt-5" />
        {twoFA?.status === TWO_FA_STATUS.ACTIVE && (
          <div className="text-left rounded-lg border border-solid border-mediumGrey pt-3 pb-2 text-sm font-normal mt-6">
            <p className="text-darkGreen px-4">
              <strong className="font-semibold">
                {method !== TWO_FA_METHOD.BACKUP_CODE ? 'Having problem?' : 'Don’t have a backup code?'}
              </strong>
            </p>
            <span
              className="max-w-full w-auto px-4 py-1 h-auto font-medium text-textLink hover:text-textLinkHover hover:cursor-pointer"
              onClick={() => {
                if (method !== TWO_FA_METHOD.BACKUP_CODE) {
                  setMethod(TWO_FA_METHOD.BACKUP_CODE);
                } else {
                  window.location.href = 'mailto:support@saveday.com';
                }
              }}
            >
              {method !== TWO_FA_METHOD.BACKUP_CODE ? 'Use a backup code instead!' : 'Contact our support team!'}
            </span>
          </div>
        )}
      </BasicModal>
    </>
  );
};

export default ModalTwoFA;
