const LogoutIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_131_824)">
        <path
          d="M10.8334 14.6667L12 13.5L9.83337 11.3333H18.3334V9.66667L9.83337 9.66667L12 7.5L10.8334 6.33333L6.66671 10.5L10.8334 14.6667ZM3.33337 4.66667H10V3H3.33337C2.41671 3 1.66671 3.75 1.66671 4.66667V16.3333C1.66671 17.25 2.41671 18 3.33337 18L10 18V16.3333H3.33337L3.33337 4.66667Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_131_824">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LogoutIcon;
