import { Grid, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'states/hooks';
import { Select, PageHeader, Button } from 'common/components';
import { completeFinchAuth, disconnectFinch, uploadFile } from 'core/services/api.service';
import { useFinchConnect } from '@tryfinch/react-connect';
import { useEffect, useState } from 'react';
import { getCookie } from 'core/services/cookie.service';
import { CookieNames } from 'core/enums/cookie-names.enum';
import {
  editCompanyDetailsAsync,
  getCompanyDetailsAsync,
  getFinchProvidersAsync,
} from 'states/companyDetails/companyDetailsSlice';
import { RequestStatus } from 'core/enums/request-status.enum';
import { setSuccessMessage, setErrorMessage } from 'states/snackbarMessage/snackbarMessageSlice';
import { useAuth } from 'contexts/AuthProvider';
import { ROLES } from 'core/constants/roles';
import { BankAccountDto, EditCompanyDto, PayrollDto } from 'core/models/companyDto.model';
import { getPlaidLinkTokenAsync } from 'states/onboardingSlice';
import { useLocation } from 'react-router-dom';
import BasicModal from 'common/components/BasicModal';
import AlertContent from 'components/employerBankAndPayroll/AlertContent';

import useDebounce from 'hooks/useDebounce';
import _ from 'lodash';
import CustomAutocomplete from 'components/employerBankAndPayroll/CustomAutoComplete';
import { OnboardingFinchStatus, OnboardingPlaidStatus } from 'core/enums';
import { ProviderStatus } from 'components/employerBankAndPayroll/CustomOptionLabel';
import { LoadingComponent } from 'components/employerManagePlan/LoadingComponent';
import PlaidConnectionComp from 'components/employerBankAndPayroll/PlaidConnectionComp';
import { ProofOfBankAccount } from 'components/employerOnboarding/AlternativeBankAccountVerification';
import { UploadPlanRequest } from 'core/models/UploadPlanRequest.model';
import { FileTypeEnum } from 'components/employerManagePlan/UploadPlan';
import { updateUploadStatus, uploadFileToS3 } from 'core/services/api-upload-file.service';
import { PayFrequencyValue } from 'core/enums/pay-frequency.enum';
import { payFrequenceOptions } from 'core/constants/pay-frequence';

export type FormValueType = {
  bankAccount: {
    bankName: string;
    bankAccountNumber: string;
    bankRoutingNumber: string;
    bankAccountName: string;
    proofOfBankAccount: ProofOfBankAccount;
  };
  payroll: {
    payFrequency?: PayFrequencyValue;
    payrollProvider: string;
  };
};

export interface IFinchProviderItem {
  clientsUsing?: number;
  connectionStatus?: ProviderStatus | string;
  createdOn?: number;
  fromFinch?: boolean;
  id?: string;
  providerName?: string;
  providerId?: string;
  updatedBy?: string;
}

export const defaultPagingnation: {
  page: number;
  pageSize: number;
} = {
  page: 0,
  pageSize: 1000,
};

export enum PlaidStatusTemp {
  DEFAULT = 'Default',
  UN_SUCCESSFUL = 'Unsuccessful',
  USED_ALTERNATIVE_VERIFICATION = 'UsedAlternativeVerfication',
}

export const mappingDataForAutocomplete = (data: any[]) => {
  if (data?.length) {
    const options = data.map((item) => ({
      ...item,
      label: item?.providerName,
      value: item?.id,
      status: item?.connectionStatus || '',
    }));
    return options;
  }
  return [];
};
const EmployerBankPayroll = () => {
  const [isDisconnectModalOpen, setIsDisconnectModalOpen] = useState<boolean>(false);
  const companyDetailsState = useAppSelector((state) => state.companyDetails.companyDetails);
  const dispatch = useAppDispatch();
  const cookieCurrentRole = getCookie<ROLES>(CookieNames.CurrentRole);
  const companyId = getCookie<string>(CookieNames.CurrentCompanyId);
  const auth = useAuth();
  const location = useLocation();
  const isSystemAdmin = auth?.session?.values.systemAdmin;
  const isCompanyAdminOfCurrentCompany = auth?.session?.isCompanyAdminOfCurrentCompany;
  const isCompanyAdmin = cookieCurrentRole === ROLES.COMPANY_ADMIN;
  const [open, setOpen] = useState<boolean>(false);
  const [paginationModel, setPaginationModel] = useState<{
    page: number;
    pageSize: number;
  }>(defaultPagingnation);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentAutoCompleteDisplay, setCurrentAutoCompleteDisplay] = useState<string>('');
  const [shouldClear, setShouldClear] = useState<boolean>(false);
  const searchText = useDebounce(currentAutoCompleteDisplay, 500);
  const [finchProviders, setFinchProviders] = useState<IFinchProviderItem[]>([]);
  const [finchProviderTotal, setFinchProviderTotal] = useState<number>(0);
  const [finchProviderId, setFinchProviderId] = useState<string>('');
  const [selectedProvider, setSelectedProvider] = useState<IFinchProviderItem>();
  const [finchLoading, setFinchLoading] = useState(false);
  const [showModalSave, setShowModal] = useState(false);
  const [modalDone, setModalDone] = useState(false);
  const [isUsedAlternativeStatus, setUsedAlternativeStatus] = useState(PlaidStatusTemp.DEFAULT);
  const [loading, setLoading] = useState(false);
  const finchStatus = companyDetailsState?.finchOnboardingStatus as OnboardingFinchStatus;
  const plaidStatus = companyDetailsState?.plaidOnboardingStatus as OnboardingPlaidStatus;
  const proofOfBankAccountValue = {
    name: '',
    size: 0,
    type: '',
    url: companyDetailsState?.bankAccount?.proofOfBankAccount ?? '',
    updated: false,
    file: null,
  };

  const form = useForm<FormValueType>({
    mode: 'all',
    defaultValues: {
      payroll: {
        payFrequency: PayFrequencyValue.WEEKLY,
        payrollProvider: '',
      },
      bankAccount: {
        bankName: companyDetailsState?.bankAccount?.bankName || '',
        bankAccountName: '',
        bankAccountNumber: '',
        bankRoutingNumber: '',
        proofOfBankAccount: proofOfBankAccountValue,
      },
    },
  });
  const { handleSubmit, reset, control, watch, getValues } = form;

  const initValue = async () => {
    if (companyDetailsState?.payroll?.payrollProvider) {
      const response = await dispatch(
        getFinchProvidersAsync({
          search: companyDetailsState?.payroll?.payrollProvider,
          ...paginationModel,
          companyId,
        })
      );
      if (response.meta.requestStatus === RequestStatus.FULFILLED) {
        const data = response.payload?.content || [];
        const dataFilter = data.find((item: IFinchProviderItem) => item?.fromFinch);
        setFinchProviderId(dataFilter?.providerId || 'other');
        setSelectedProvider(dataFilter);
        setCurrentAutoCompleteDisplay(dataFilter?.providerName || 'Other');
        setShouldClear(!dataFilter?.providerName);
      }
    }
  };

  useEffect(() => {
    if (isCompanyAdminOfCurrentCompany && !companyDetailsState?.plaidConnected) {
      dispatch(getPlaidLinkTokenAsync({ filter: { suffixUri: location.pathname } }));
    }
    handleReset();
  }, [companyDetailsState]);

  useEffect(() => {
    dispatch(getCompanyDetailsAsync({ id: companyId }));
  }, []);

  const fetchFinchProvider = async () => {
    setIsLoading(true);
    try {
      const otherOption = {
        providerName: 'Other',
        providerId: 'other',
        id: 'other',
        connectionStatus: '',
      };
      const hasOtherOption = new RegExp(searchText, 'gi').test(otherOption.providerName);
      const response = await dispatch(
        getFinchProvidersAsync({
          search: searchText === 'Other' ? '' : searchText,
          ...paginationModel,
          companyId,
        })
      );
      const data = response.payload;
      const convertedContents = data?.content || [];
      let newFinchProviders = [
        ...(searchText && paginationModel.page === 0 ? [] : finchProviders),
        ...convertedContents,
      ];
      newFinchProviders = newFinchProviders.filter((f) => f.fromFinch);
      newFinchProviders = _.uniqBy(newFinchProviders, (ep) => ep.id);
      setFinchProviders([...(hasOtherOption ? [otherOption] : []), ...newFinchProviders]);
      setFinchProviderTotal(data ? data?.totalElements + (hasOtherOption ? 1 : 0) : 0);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (open) {
      fetchFinchProvider();
    }
  }, [searchText, JSON.stringify(paginationModel), open]);

  const updateCompanyDetail = async (payload: EditCompanyDto) => {
    if (!isSystemAdmin && !isCompanyAdmin) return;
    if (!companyDetailsState) return;

    // don't allow company admin edit bankAccount
    if (!isCompanyAdmin) {
      delete payload.bankAccount;
    }

    const res = await dispatch(
      editCompanyDetailsAsync({
        editCompanyDetailsBody: payload,
      })
    );

    if (res.meta.requestStatus === RequestStatus.FULFILLED) {
      if (isUsedAlternativeStatus !== PlaidStatusTemp.USED_ALTERNATIVE_VERIFICATION) {
        dispatch(setSuccessMessage('Update successfully!'));
      }
    } else {
      dispatch(setErrorMessage('Something went wrong!'));
    }
    return res;
  };

  const normalizeData = (payload: { payroll: PayrollDto; bankAccount: Omit<BankAccountDto, 'proofOfBankAccount'> }) => {
    return {
      id: companyId,
      payroll: {
        ...companyDetailsState?.payroll,
        payFrequency: payload.payroll.payFrequency,
        payrollProvider: payload.payroll.payrollProvider,
        payrollProviderId: finchProviderId,
      },
      bankAccount: {
        ...companyDetailsState?.bankAccount,
        bankAccountNumber: payload.bankAccount.bankAccountNumber,
        bankName: payload.bankAccount.bankName,
        bankRoutingNumber: payload.bankAccount.bankRoutingNumber,
        bankAccountName: payload.bankAccount.bankAccountName,
      },
    };
  };

  const isValidConnectToPayroll = () => {
    return (
      !finchStatus ||
      [
        OnboardingFinchStatus.DISCONNECTED,
        OnboardingFinchStatus.ASSISTED_CONNECTION,
        OnboardingFinchStatus.SKIPPED,
        OnboardingFinchStatus.NaN,
      ].includes(finchStatus)
    );
  };

  const handleUploadFile = async (fileInfo: ProofOfBankAccount) => {
    if (fileInfo.file && fileInfo.updated) {
      try {
        const payload: UploadPlanRequest = {
          name: fileInfo.name,
          mime: fileInfo.type,
          size: fileInfo.size,
          fileType: FileTypeEnum.EMPLOYER_PROOF_BANK_ACCOUNT,
        };
        if (companyId) {
          payload.companyId = companyId;
        }
        const { data } = await uploadFile(payload);
        const { id, url } = data;
        await uploadFileToS3({ url, file: fileInfo.file });
        const res = await updateUploadStatus(id);
        return res;
      } catch (exception) {
        console.error(exception);
      }
    }
  };

  const handleShowNotification = async (proofOfBankAccount: ProofOfBankAccount) => {
    if (isUsedAlternativeStatus === PlaidStatusTemp.USED_ALTERNATIVE_VERIFICATION) {
      await handleUploadFile(proofOfBankAccount);
      setModalDone(true);
      dispatch(getCompanyDetailsAsync({ id: companyId }));
    }
  };

  const onSubmit = async (data: FormValueType) => {
    if (isValidConnectToPayroll() && selectedProvider?.connectionStatus === ProviderStatus.INTERGRATION_AVAILABLE) {
      setShowModal(true);
    } else {
      let message = '';
      setLoading(true);
      const payload = normalizeData({
        payroll: data.payroll,
        bankAccount: data.bankAccount,
      });
      if (
        plaidStatus === OnboardingPlaidStatus.NaN &&
        !companyDetailsState?.bankAccount &&
        isUsedAlternativeStatus !== PlaidStatusTemp.USED_ALTERNATIVE_VERIFICATION
      ) {
        message = 'Please verify using Plaid or used alternative verification!';
      }
      if (isUsedAlternativeStatus === PlaidStatusTemp.UN_SUCCESSFUL) {
        message = 'Please re-verify another Bank account or used alternative verification!';
      }
      if (message) {
        dispatch(setErrorMessage(message));
        setLoading(false);
        return;
      }
      const res = await updateCompanyDetail(payload);
      if (res?.meta.requestStatus === RequestStatus.FULFILLED) {
        await handleShowNotification(data.bankAccount.proofOfBankAccount);
      }
      setLoading(false);
    }
  };

  const onSuccess = async ({ code }: { code: string }) => {
    setFinchLoading(true);
    const payroll = getValues('payroll');
    const bankAccount = getValues('bankAccount');
    if (!companyId) {
      return;
    }

    const payload = normalizeData({
      payroll,
      bankAccount,
    });

    try {
      const res = await completeFinchAuth({ code, id: companyId });
      if (res.data === 'OK') {
        const response = await updateCompanyDetail(payload);
        if (response?.meta.requestStatus === RequestStatus.FULFILLED) {
          await handleShowNotification(bankAccount.proofOfBankAccount);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setFinchLoading(false);
      setShowModal(false);
    }
  };

  const onSkip = async () => {
    const payroll = getValues('payroll');
    const bankAccount = getValues('bankAccount');
    if (!companyId) {
      return;
    }
    const payload = normalizeData({
      payroll,
      bankAccount,
    });

    const response = await updateCompanyDetail(payload);
    if (response?.meta.requestStatus === RequestStatus.FULFILLED) {
      await handleShowNotification(bankAccount.proofOfBankAccount);
    }
    setShowModal(false);
  };

  const onError = ({ errorMessage }: { errorMessage: string }) => console.error(errorMessage);
  const onClose = () => console.log('User exited Finch Connect');

  const products = ['company', 'directory', 'individual', 'employment', 'benefits', 'payment', 'pay_statement'];
  if (process.env.REACT_APP_FINCH_SANDBOX !== 'true') {
    products.push(...['ssn']);
  }
  const { open: finchConnectOpen } = useFinchConnect({
    clientId: process.env.REACT_APP_FINCH_CLIENT_ID || '',
    products,
    manual: true,
    sandbox: process.env.REACT_APP_FINCH_SANDBOX === 'true' ? 'finch' : false,
    onSuccess,
    onError,
    onClose,
    payrollProvider: finchProviderId,
  });
  const openLink = () => {
    window.open('https://savedaycom.notion.site/Payroll-Integration-Guide-499771e35b5d4d7186540ddcb86ca68b', '_blank');
  };

  const handleReset = () => {
    initValue();
    reset({
      payroll: {
        payrollProvider: companyDetailsState?.payroll?.payrollProvider || currentAutoCompleteDisplay,
        payFrequency: companyDetailsState?.payroll?.payFrequency,
      },
      bankAccount: {
        bankName: companyDetailsState?.bankAccount?.bankName || '',
        bankAccountNumber: companyDetailsState?.bankAccount?.bankAccountNumber || '',
        bankRoutingNumber: companyDetailsState?.bankAccount?.bankRoutingNumber || '',
        bankAccountName: companyDetailsState?.bankAccount?.bankAccountName || '',
        proofOfBankAccount: proofOfBankAccountValue,
      },
    });
  };
  const handleFinchDisconnect = async () => {
    if (!companyId) {
      return;
    }

    await disconnectFinch(companyId);

    dispatch(getCompanyDetailsAsync({ id: companyId }));
    setIsDisconnectModalOpen(false);
  };

  const disabledInput = () => {
    if (isValidConnectToPayroll()) {
      return false;
    }
    return [
      OnboardingFinchStatus.CONNECTED,
      OnboardingFinchStatus.PENDING,
      OnboardingFinchStatus.PROCESSING,
      OnboardingFinchStatus.NO_ACCOUNT_SET_UP,
      OnboardingFinchStatus.ERROR_PERMISSIONS,
    ].includes(finchStatus);
  };

  const renderButton = () => {
    const disabled = () => {
      if (
        finchStatus === OnboardingFinchStatus.PENDING ||
        finchStatus === OnboardingFinchStatus.PROCESSING ||
        !selectedProvider ||
        ['', ProviderStatus.CURRENTLY_UNAVAILABLE].includes(selectedProvider?.connectionStatus as string)
      ) {
        return true;
      }
      return false;
    };
    if (
      isValidConnectToPayroll() ||
      [
        OnboardingFinchStatus.PENDING,
        OnboardingFinchStatus.PROCESSING,
        OnboardingFinchStatus.CONNECTION_NOT_WORKING,
      ].includes(finchStatus)
    ) {
      return (
        <Button className="w-full min-w-[182px]" disabled={disabled()} onClick={() => finchConnectOpen()}>
          Connect To Payroll
        </Button>
      );
    }
    if (
      [
        OnboardingFinchStatus.ERROR_PERMISSIONS,
        OnboardingFinchStatus.NO_ACCOUNT_SET_UP,
        OnboardingFinchStatus.CONNECTED,
      ].includes(finchStatus)
    ) {
      return (
        <Button className="w-full min-w-[182px]" onClick={() => setIsDisconnectModalOpen(true)}>
          Disconnect Payroll
        </Button>
      );
    } else if ([OnboardingFinchStatus.REAUTH].includes(finchStatus)) {
      return (
        <Button className="w-full min-w-[182px]" onClick={() => finchConnectOpen()}>
          Re-authenticate
        </Button>
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PageHeader pageTitle="Bank and Payroll" subTitle="You can edit your payroll and bank information" />
      <div className="px-4 pb-8 sm:px-8 md:space-y-8">
        <div className="pt-4 space-y-8">
          <div className="space-y-4">
            <h2 className="text-sm font-bold text-superBlack">Payroll Details</h2>
            <h2 className="text-xs text-deepGrey">
              Payroll integration refers to the process of seamlessly connecting your company’s payroll system with
              Saveday’s platform. This integration allows for the automatic transfer of relevant data between the two
              systems, streamlining processes like deduction changes, contribution tracking, and payroll processing. To
              learn more about how the integration works and to find answers to frequently asked questions, click the
              “Learn more” button.
            </h2>
            <h2 className="text-xs text-deepGrey">
              Select your payroll provider from the list below to see if Saveday can offer a seamless integration.
            </h2>
          </div>
          {finchStatus && (
            <>
              <AlertContent type={finchStatus} />
            </>
          )}
          <Grid container zero={12} spacing={2}>
            <Grid item zero={12} sm={6}>
              <Controller
                control={control}
                name="payroll.payrollProvider"
                rules={{
                  required: 'Payroll Provider Required',
                }}
                render={({ field, fieldState }) => {
                  return (
                    <CustomAutocomplete
                      {...field}
                      label="Payroll Provider"
                      ListboxProps={{
                        style: { maxHeight: 200, overflow: 'auto' },
                      }}
                      totalElements={finchProviderTotal}
                      inputValue={currentAutoCompleteDisplay}
                      options={mappingDataForAutocomplete(finchProviders)}
                      onOpen={() => {
                        setOpen(true);
                        if (shouldClear) {
                          setCurrentAutoCompleteDisplay('');
                          field.onChange('');
                        }
                      }}
                      loading={isLoading}
                      onClose={() => {
                        setOpen(false);
                        setPaginationModel(defaultPagingnation);
                        setIsLoading(false);
                      }}
                      disabled={disabledInput()}
                      required
                      sortable
                      placeholder="See which providers Saveday can integrate with"
                      error={fieldState.error?.message}
                      onChange={(_event, value: any, _reason) => {
                        setCurrentAutoCompleteDisplay(value?.label || '');
                        field.onChange(value?.label);
                        const prov = finchProviders.find((item) => item.id === value?.value);
                        if (prov) {
                          setFinchProviderId(prov?.providerId || '');
                          setSelectedProvider(prov);
                        } else {
                          setSelectedProvider(undefined);
                          setFinchProviderId('');
                        }
                        setIsLoading(false);
                      }}
                      onInputChange={(event: any, value, _reason) => {
                        if (['input', 'clear'].includes(_reason)) {
                          field.onChange(value);
                          setCurrentAutoCompleteDisplay(value || '');
                          setFinchProviders([]);
                          setPaginationModel(defaultPagingnation);
                          setFinchProviderId('');
                          setSelectedProvider(undefined);
                        }
                      }}
                      onLoadMoreData={() => {
                        if (finchProviderTotal <= (paginationModel.page + 1) * defaultPagingnation.pageSize) {
                          return;
                        }
                        setPaginationModel({
                          page: paginationModel.page + 1,
                          pageSize: defaultPagingnation.pageSize,
                        });
                      }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item zero={12} sm={6}>
              <Controller
                control={control}
                name="payroll.payFrequency"
                rules={{
                  required: 'Payroll Frequency Required',
                }}
                render={({ field, fieldState }) => {
                  return (
                    <Select
                      {...field}
                      label="Payroll Frequency"
                      data={payFrequenceOptions}
                      placeholder="Select Payroll Frequency"
                      required
                      error={fieldState.error?.message}
                    />
                  );
                }}
              />
            </Grid>
            <Grid container item spacing={2} justifyContent="end" className="mt-0">
              <Grid item>{renderButton()}</Grid>
              <Grid item>
                <Button className="w-full min-w-[182px]" variant="outlined" color="secondary" onClick={openLink}>
                  Learn more
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className="space-y-4">
          <PlaidConnectionComp
            form={form}
            setUsedAlternativeStatus={setUsedAlternativeStatus}
            isUsedAlternativeStatus={isUsedAlternativeStatus}
          />
        </div>
        {(!!isSystemAdmin || !!isCompanyAdmin) && (
          <div className="my-4 mr-4 space-x-4 text-end">
            <Button
              className="submit-button"
              variant="outlined"
              color="secondary"
              style={{
                width: '182px',
              }}
              onClick={() => handleReset()}
            >
              Cancel
            </Button>
            <Button
              className="submit-button"
              variant="contained"
              type="submit"
              color="secondary"
              style={{
                width: '182px',
              }}
              disabled={
                !watch('payroll.payrollProvider') &&
                isUsedAlternativeStatus !== PlaidStatusTemp.USED_ALTERNATIVE_VERIFICATION
              }
            >
              Save
            </Button>
          </div>
        )}
      </div>
      <BasicModal
        isOpen={isDisconnectModalOpen}
        handleClose={() => setIsDisconnectModalOpen(false)}
        className="w-full max-w-[550px]"
      >
        <div className="text-sm text-center text-primary">
          Are you sure you want to disconnect your payroll? This action will halt the synchronization of payroll data
          with our system. To reconnect or add a new payroll provider, follow the integration steps in the settings.
        </div>
        <div className="flex items-center justify-center gap-2 mt-6">
          <Button variant="outlined" color="secondary" onClick={() => setIsDisconnectModalOpen(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleFinchDisconnect()}
            className="min-w-[170px] whitespace-nowrap"
          >
            Confirm Disconnect
          </Button>
        </div>
      </BasicModal>
      <BasicModal isOpen={showModalSave} handleClose={() => setShowModal(false)} className="w-full max-w-[550px]">
        <div className="text-sm text-center text-primary">
          You currently haven’t connected to the selected Payroll Provider yet. Would you like to make the connection
          now?
        </div>
        <div className="flex items-center justify-center gap-2 mt-6">
          <Button variant="outlined" color="secondary" onClick={onSkip}>
            Skip
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => finchConnectOpen()}
            className="min-w-[170px] whitespace-nowrap"
          >
            Connect To Payroll
          </Button>
        </div>
      </BasicModal>
      <BasicModal isOpen={modalDone} handleClose={() => setModalDone(false)} className="w-full max-w-[800px]">
        <Typography className="text-xl font-bold text-center text-primary">All Done!</Typography>
        <div className="mt-2 text-sm text-center text-primary">
          We have received your request to change bank accounts. Our team will promptly commence processing this
          request, and we'll reach out to you as soon as it's complete.
        </div>
        <div className="flex items-center justify-center gap-2 mt-6">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setModalDone(false)}
            className="min-w-[170px] whitespace-nowrap"
          >
            Done
          </Button>
        </div>
      </BasicModal>
      <LoadingComponent isLoading={!!finchLoading || loading} />
    </form>
  );
};
export default EmployerBankPayroll;
