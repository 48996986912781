// We need to import lodash throttle if we want to throttle our scroll events
// import throttle from 'lodash.throttle';

import { throttle } from "lodash";
import React from "react";

const useWindowScrollPosition = (options?: any) => {
  const { throttleMs = 100 } = options || {};
  const [scroll, setScroll] = React.useState({
    x: window.scrollX,
    y: window.scrollY,
  });

  const handle = throttle(() => {
    setScroll({
      x: window.pageXOffset,
      y: window.pageYOffset,
    });
  }, throttleMs);

  React.useEffect(() => {
    window.addEventListener("scroll", handle);

    return () => {
      window.removeEventListener("scroll", handle);
    };
  }, []);

  return scroll;
};
export default useWindowScrollPosition;
