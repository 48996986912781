import { FormControl, Typography } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { Select } from "common/components";
import { Controller, useFormContext } from "react-hook-form";
import { answerDto } from "core/models/answerDto.model";
import { formatPercent } from "utils/number";

const options = [
  {
    label: "Yes",
    value: "YES",
  },
  {
    label: "No",
    value: "NO",
  },
  {
    label: "Not sure",
    value: "NOT_SURE",
  },
];

export const EmployeeTaxBracket = ({ active }: { active?: answerDto }) => {
  const { control } = useFormContext();
  return (
    <div className="pt-8">
      <div className="text-2xl text-center text-primary">
        <p className="font-medium leading-4 text-primary pb-4">
          Current Tax Bracket (Federal:{" "}
          {formatPercent(active?.answers[69], {
            minimumFractionDigits: 2,
          })}
          ,{" "}
          {active?.answers[70] && (
            <span>
              State:{" "}
              <span>
                {formatPercent(active?.answers[70], {
                  minimumFractionDigits: 2,
                })}
                )
              </span>
            </span>
          )}
        </p>
        <div className="px-8 space-y-4">
          <div className="flex">
            <div className="flex items-center gap-2 w-8/12">
              <Typography
                variant="h6"
                fontSize="14px"
                className="text-darkGreen leading-5"
              >
                Taking in consideration the state you wish to retire in, do you
                expect to be in a higher tax bracket?
              </Typography>
              <HelpIcon className="text-secondary" />
            </div>
            <div className="space-y-4 w-5/12 flex justify-end">
              <FormControl className="w-[230px] ">
                <Controller
                  control={control}
                  name="answer_71"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        label="Payroll Frequency"
                        value={value || undefined}
                        data={options}
                        placeholder="Please Select"
                        onChange={onChange}
                      />
                    );
                  }}
                />
              </FormControl>
            </div>
          </div>
          <div className="flex">
            <div className="flex items-center gap-2 w-7/12">
              <Typography
                variant="h6"
                fontSize="14px"
                className="text-darkGreen leading-5"
              >
                Can you wait at least five(5) years before you need to start
                collecting your retirement money?
              </Typography>
              <HelpIcon className="text-secondary" />
            </div>
            <div className="space-y-4 w-5/12 flex justify-end">
              <FormControl className="w-[230px] ">
                <Controller
                  control={control}
                  name="answer_72"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        label="Payroll Frequency"
                        value={value || undefined}
                        data={options}
                        placeholder="Please Select"
                        onChange={onChange}
                      />
                    );
                  }}
                />
              </FormControl>
            </div>
          </div>
          <div className="flex">
            <div className="flex items-center gap-2 w-7/12">
              <Typography variant="h6"
                fontSize="14px"
                className="text-darkGreen leading-5">
                Can you afford to reduce your take home pay now to avoid taxes
                later?
              </Typography>
              <HelpIcon className="text-secondary" />
            </div>
            <div className="space-y-4 w-5/12 flex justify-end">
              <FormControl className="w-[230px] ">
                <Controller
                  control={control}
                  name="answer_73"
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <Select
                        label="Payroll Frequency"
                        value={value || undefined}
                        data={options}
                        placeholder="Please Select"
                        onChange={onChange}
                      />
                    );
                  }}
                />
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
