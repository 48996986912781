import { FC, ReactNode } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { Tooltip } from '../Tooltip';
import { appPalette } from 'core/constants';

type SubTitleT = {
  title: string;
  className?: string;
  tooltip?: boolean;
  tooltipContent?: string;
  tooltipPlacement?: string;
  tooltipIcon?: ReactNode | any;
  tooltipClassName?: string;
  iconColor?: string;
  disabled?: boolean;
  jsxTooltip?: JSX.Element;
};
export const SubTitle: FC<SubTitleT> = (props) => {
  return (
    <div
      className={[
        'font-semibold flex gap-1',
        props.className ? props.className : '',
        props.disabled ? 'pointer-events-none text-disabled' : 'text-normalText',
      ].join(' ')}
    >
      <div>{props.title}</div>
      {props.jsxTooltip}
      {props.tooltip && (
        <Tooltip title={props.tooltipContent}>
          {props.tooltipIcon ? (
            props.tooltipIcon
          ) : (
            <ErrorIcon htmlColor={props.disabled ? appPalette.disabled : props.iconColor} />
          )}
        </Tooltip>
      )}
    </div>
  );
};
