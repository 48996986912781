import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { REQUEST_STATUS } from 'core/constants/roles';
import { DataResponse } from 'core/models/dataResponseModel.model';
import ApiService from 'core/services/api.service';

type FinchConnectionState = {
  finchConnections: FinchConnectionsResponse | null;
  finchConnectionsTempData: FinchConnectionsResponse | null;
  loading: boolean;
  companyFinchDetails: CompanyFinchDetails | null;
  finchProviders: FinchProvidersResponse | null;
  payrollProviders: {
    providerName: string;
    logoUrl?: string;
    iconUrl?: string;
  }[];
  getFinchConnectionsStatus: REQUEST_STATUS;
  manualSyncFinchStatus: REQUEST_STATUS;
};

type CompanyFinchDetails = {
  companyId: string;
  companyName: string;
  accessTokenCreatedDate: number;
  lastFourAccessToken: string;
  paymentsDays: number;
  payments: Payment[];
  paymentsStatusCode: number;
  dataPending: boolean;
  lastCensusSyncedDate: number;
  lastPayrollSyncedDate: number;
  finchCompanyId: string;
  firstDataSyncDate: number;
  firstIntegratedDate: number;
};

type Payment = {
  payment: FinchPayment;
  payrollContribution: PayrollContribution;
};

type FinchPayment = {
  id: string;
  finchPaymentId: string;
  grossPay: number;
  netPay: number;
  employeeTraditionalSum: number;
  employeeRothSum: number;
  employerTraditionalSum: number;
  employerRothSum: number;
  loanSum: number;
};

type PayrollContribution = {
  id: string;
  currentPayrollCheckDate: number;
};

type FinchProviders = {
  createdOn: number;
  updatedBy: string;
  id: string;
  providerId: string;
  providerName: string;
  iconUrl: string;
  logoUrl: string;
  manual: string;
};

type FinchConnection = {
  id: string;
  companyName: string;
  finch: {
    alert: string;
    finchStatus: string;
    manual?: boolean;
  };
  payroll?: {
    payrollProvider: string;
    payFrequency?: string;
    lastPayrollDate?: number;
    lastPayrollAdmin?: string;
  };
};

type FinchProvidersResponse = {
  content: FinchProviders[];
} & DataResponse;

type FinchConnectionsResponse = {
  content: FinchConnection[];
} & DataResponse;

export const getFinchConnectionsAsync = createAsyncThunk(
  'finchConnection/getFinchConnectionAsync',
  async ({ filter }: { filter?: any }): Promise<any> => {
    const response = await ApiService.getFinchConnections({ filter });
    return response.data;
  }
);

export const getFinchProvidersAsync = createAsyncThunk(
  'finchConnection/getFinchProvidersAsync',
  async ({ filter }: { filter?: any }): Promise<any> => {
    const response = await ApiService.getFinchProviders({ filter });
    return response.data;
  }
);

export const getPayrollProvidersAsync = createAsyncThunk(
  'finchConnection/getPayrollProvidersAsync',
  async ({ filter }: { filter?: any }): Promise<any> => {
    const response = await ApiService.getPayrollProviders({ filter });
    return response.data;
  }
);

export const downloadFinchPaymentAsync = createAsyncThunk(
  'finchConnection/downloadFinchPaymentAsync',
  async ({ companyId, paymentId }: { companyId: string; paymentId: string }): Promise<any> => {
    const response = await ApiService.downloadFinchPayment({
      companyId,
      paymentId,
    });
    return response.data;
  }
);

export const manualSyncFinchAsync = createAsyncThunk(
  'finchConnection/manualSyncFinchAsync',
  async ({ companyId }: { companyId: string }): Promise<any> => {
    const response = await ApiService.manualSyncFinch({ companyId });
    return response.data;
  }
);

export const getCompanyFinchDetailsAsync = createAsyncThunk(
  'finchConnection/getCompanyFinchDetailsAsync',
  async ({ companyId, filter }: { companyId: string; filter?: any }): Promise<any> => {
    const response = await ApiService.getCompanyFinchDetails({
      companyId,
      filter,
    });
    return response.data;
  }
);

const initialState: FinchConnectionState = {
  finchConnections: null,
  loading: false,
  companyFinchDetails: null,
  finchProviders: null,
  payrollProviders: [],
  finchConnectionsTempData: null,
  getFinchConnectionsStatus: REQUEST_STATUS.IDLE,
  manualSyncFinchStatus: REQUEST_STATUS.IDLE,
};

const finchConnectionSlice = createSlice({
  name: 'finchConnection',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFinchConnectionsAsync.pending, (state) => {
        state.loading = true;
        state.getFinchConnectionsStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(getFinchConnectionsAsync.fulfilled, (state, action) => {
        state.getFinchConnectionsStatus = REQUEST_STATUS.SUCCESS;
        state.finchConnections = action.payload;
        state.finchConnectionsTempData = action.payload;
        state.loading = false;
      })
      .addCase(getFinchConnectionsAsync.rejected, (state) => {
        state.loading = false;
        state.getFinchConnectionsStatus = REQUEST_STATUS.ERROR;
      })
      .addCase(getPayrollProvidersAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPayrollProvidersAsync.fulfilled, (state, action) => {
        state.payrollProviders = action.payload;
        state.loading = false;
      })
      .addCase(getPayrollProvidersAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getFinchProvidersAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFinchProvidersAsync.fulfilled, (state, action) => {
        state.finchProviders = action.payload;
        state.loading = false;
      })
      .addCase(getFinchProvidersAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCompanyFinchDetailsAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCompanyFinchDetailsAsync.fulfilled, (state, action) => {
        state.companyFinchDetails = action.payload;
        state.loading = false;
      })
      .addCase(getCompanyFinchDetailsAsync.rejected, (state) => {
        state.loading = false;
      })
      .addCase(manualSyncFinchAsync.pending, (state) => {
        state.manualSyncFinchStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(manualSyncFinchAsync.fulfilled, (state, action) => {
        state.manualSyncFinchStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(manualSyncFinchAsync.rejected, (state) => {
        state.manualSyncFinchStatus = REQUEST_STATUS.ERROR;
      });
  },
});

export default finchConnectionSlice.reducer;
