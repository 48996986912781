import { Button } from 'common/components';
import AuthCode, { AuthCodeRef } from 'react-auth-code-input';
import { VerifiedTwoFARequest, VerifyCodeForm } from 'core/models/employeeDto.model';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useRef } from 'react';
import { REQUEST_STATUS } from 'core/constants/roles';
import { useAppDispatch, useAppSelector } from 'states/hooks';
import { verifyTwoFAAsync } from 'states/manageEmployee/manageEmployeeSlice';
import { RequestStatus } from 'core/enums/request-status.enum';

interface IFormVerifiedCodeProps {
  onCancel?: Function;
  onSuccess?: Function;
  action: string;
  method: string;
}

const FormVerifiedCode = ({ onCancel = () => {}, onSuccess = () => {}, action, method }: IFormVerifiedCodeProps) => {
  const AuthInputRef = useRef<AuthCodeRef>(null);
  const verifiedTwoFAStatus = useAppSelector((state) => state.manageEmployee.verifiedTwoFAStatus);
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    reset: resetForm,
    formState: { errors },
  } = useForm<VerifyCodeForm>({
    reValidateMode: 'onChange',
    defaultValues: {
      verifyCode: '',
    },
  });
  useEffect(() => {
    AuthInputRef.current?.clear();
  }, [method]);

  const handleOnVerify = async (values: VerifyCodeForm) => {
    const payload: VerifiedTwoFARequest = {
      action,
      data: [
        {
          code: values.verifyCode,
          method,
        },
      ],
    };
    const response = await dispatch(verifyTwoFAAsync(payload));
    if (response.meta.requestStatus === RequestStatus.FULFILLED) {
      onSuccess(response);
    }
  };

  const isLoading = verifiedTwoFAStatus === REQUEST_STATUS.SENDING;
  return (
    <div className="text-center mt-6">
      <form
        onSubmit={handleSubmit(handleOnVerify)}
        onReset={() => {
          AuthInputRef.current?.clear();
          resetForm({
            verifyCode: '',
          });
          onCancel();
        }}
      >
        <Controller
          control={control}
          name="verifyCode"
          rules={{
            required: true,
            pattern: /\d{6}/,
          }}
          render={({ field: { ref, ...restField } }) => {
            return (
              <AuthCode
                {...restField}
                ref={AuthInputRef}
                containerClassName="grid grid-cols-6 gap-2 xs:gap-4 xs:w-[420px] w-full mx-auto"
                inputClassName={[
                  'focus:border-secondary focus:outline-secondary border border-solid',
                  'rounded-lg text-center h-12 text-darkGreen font-semibold text-sm',
                  errors.verifyCode ? 'border-errorColor' : 'border-borderColor',
                ].join(' ')}
                allowedCharacters="numeric"
              />
            );
          }}
        />
        <div className="flex items-center mt-8">
          <Button className="w-1/2 max-w-full" variant="outlined" color="secondary" type="reset" disabled={isLoading}>
            Cancel
          </Button>
          <Button
            className="w-1/2 max-w-full ml-6"
            type="submit"
            variant="contained"
            color="secondary"
            disabled={isLoading}
            loading={isLoading}
          >
            Submit code
          </Button>
        </div>
      </form>
    </div>
  );
};

export default FormVerifiedCode;
