import { Button, PageHeader } from 'common/components';
import { EmployeeTaxBracket } from 'components/employeePlancomparison';
import { FormProvider, useForm } from 'react-hook-form';
import { FormHelperText } from '@mui/material';
import { useEffect, useState } from 'react';
import { EmployeeContributionDto } from 'core/models/EmployeeContributionDto.model';
import { getProfile } from 'core/services/api.service';
import { getContributions, updateAnswer } from 'core/services/employee.service';
import { answerDto } from 'core/models/answerDto.model';
import { getAnswers } from 'core/services/employee.service';
import { useNavigate } from 'react-router-dom';
// import { EmployeeTaxBracketTable } from "components/employeePlancomparison/EmployeeTaxBracketTable";

type FormValueType = {
  answer_71: string;
  answer_72: string;
  answer_73: string;
};

type EmployeePlancomparisonState = {
  comparison?: EmployeeContributionDto;
  active?: answerDto;
};

const EmployeePlancomparison = () => {
  const [data, setData] = useState<EmployeePlancomparisonState>();
  const method = useForm<FormValueType>({
    defaultValues: {
      answer_71: String(data?.active?.answers[71]),
      answer_72: String(data?.active?.answers[72]),
      answer_73: String(data?.active?.answers[73]),
    },
  });

  useEffect(() => {
    method.reset({
      answer_71: String(data?.active?.answers[71]),
      answer_72: String(data?.active?.answers[72]),
      answer_73: String(data?.active?.answers[73]),
    });
  }, [data?.active?.answers]);

  const fetch = async () => {
    const profile = await getProfile().then((res) => res.data);
    if (profile) {
      Promise.all([getContributions(String(profile.rothAccountId)), getAnswers(profile?.id)]).then(
        ([comparison, active]) => {
          setData({
            comparison,
            active,
          });
        }
      );
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  const {
    handleSubmit,
    formState: { errors },
  } = method;
  const navigate = useNavigate();
  const onSubmit = (values: FormValueType) => {
    const answers = (data?.active?.answers ?? []).map((el, idx) => {
      if (idx === 71) return values.answer_71;
      if (idx === 72) return values.answer_72;
      if (idx === 73) return values.answer_73;
      return null;
    });
    if (data?.active?.id) {
      updateAnswer({
        answers,
        id: data?.active?.id,
      }).then(() => {
        navigate('/dashboard/employee/contributions');
      });
    }
  };
  return (
    <div>
      <PageHeader
        pageTitle="Your Contributions"
        subTitle="Another neat thing about 401(k)s is that your contributions are deducted automatically from your pay. You do have a choice between deducting them before taxes, after taxes(called Roth) or a combination of both. Not sure what to do? Answer a few questions below and we can help with a suggestion.
        "
      />
      <FormProvider {...method}>
        <form className="py-8" onSubmit={handleSubmit(onSubmit)}>
          <div className="px-8 py-2">{/* <EmployeeTaxBracketTable /> */}</div>
          <EmployeeTaxBracket active={data?.active} />
          <div className="text-center space-x-4 pt-4">
            <Button
              variant="outlined"
              color="secondary"
              classes={{ root: 'lg:w-[280px]' }}
              onClick={() => navigate(-1)}
            >
              Previous
            </Button>
            <Button color="secondary" variant="contained" classes={{ root: 'lg:w-[280px]' }} type="submit">
              {' '}
              Save & Continue
            </Button>
          </div>
          {Object.keys(errors).length > 0 && (
            <FormHelperText error className="text-center py-4">
              There was an issue submitting your info:
            </FormHelperText>
          )}
        </form>
        <p className="font-medium text-xs text-primary leading-5 px-8 pb-4">
          * This limitation is by individual, rather than plan. You can split your annual elective deferrals between
          designated Roth contributions and traditional pre-tax contributions, but your combined contributions cannot
          exceed the deferral limit - $22500 in 2023 ($25,000 if you eligible for catch-up contributions).
        </p>
      </FormProvider>
    </div>
  );
};
export default EmployeePlancomparison;
