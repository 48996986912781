import React from 'react';
import { ISingleAndDoubleClick, useSingleAndDoubleClick } from 'hooks/useSingleAndDoubleClick';
import { Button, ButtonProps } from 'common/components';

export const SingleDoubleElement = ({
  actionSimpleClick,
  actionDoubleClick,
  delay,
  children,
  btnProps,
}: ISingleAndDoubleClick & {
  children: React.ReactNode;
  btnProps?: ButtonProps;
}) => {
  const baseProps = btnProps || {};
  const click = useSingleAndDoubleClick({
    actionSimpleClick,
    actionDoubleClick,
    delay,
  });

  return (
    <Button onClick={click} {...baseProps}>
      {children}
    </Button>
  );
};
