import { Visibility, VisibilityOff } from '@mui/icons-material';
import { FormHelperText, IconButton, InputAdornment, FormLabelProps } from '@mui/material';
import MuiTextField, { TextFieldProps as MUITextFieldProps } from '@mui/material/TextField';
import useId from '@mui/material/utils/useId';
import { ReactNode, forwardRef, useState } from 'react';

import clsx from 'clsx';
import { FormControl } from '../FormControl';
import { appPalette } from 'core/constants';

type TextFieldProps = {
  error?: ReactNode | string | boolean;
  registerForm?: any;
  onlyNumber?: boolean;
  formLabelProps?: FormLabelProps;
  required?: boolean;
} & Omit<MUITextFieldProps, 'error'>;

export const PasswordField = forwardRef(
  (
    {
      className,
      label,
      id: propId,
      error,
      registerForm,
      onlyNumber,
      sx,
      formLabelProps,
      required = false,
      ...props
    }: TextFieldProps,
    ref
  ) => {
    const id = useId(propId);

    const [showPassword, setShowPassword] = useState<boolean>(false);
    return (
      <FormControl
        label={label}
        id={id}
        fullWidth
        required={required}
        formLabelProps={formLabelProps}
        sx={{
          '.MuiFormLabel-asterisk': {
            color: 'red',
          },
          '.MuiFormLabel-root': {
            maxHeight: '16px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        }}
      >
        <MuiTextField
          inputRef={ref}
          error={!!error}
          inputProps={{ id }}
          className={clsx(className)}
          color="secondary"
          sx={{
            'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
              WebkitAppearance: 'none',
              margin: 0,
            },
            '.MuiOutlinedInput-input.MuiInputBase-input': {
              fontWeight: 600,
              color: (theme) => theme.palette.primaryColor,
              '&::placeholder': {
                fontWeight: 600,
                fontSize: 13,
                color: (theme) => theme.palette.mediumGrey,
              },
            },
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: (theme) => theme.palette.borderColor,
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: (theme) => `${theme.palette.mediumGreen} !important`,
            },
            '.Mui-disabled': {
              WebkitTextFillColor: appPalette.beige,
              cursor: 'not-allowed',
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: (theme) => `${theme.palette.mediumGrey} !important`,
              },
            },
            '.Mui-disabled.Mui-error': {
              WebkitTextFillColor: appPalette.beige,
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: (theme) => `${theme.palette.errorColor} !important`,
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: (theme) => `${theme.palette.errorColor} !important`,
              },
            },
            'input[type=number]': {
              MozAppearance: 'textfield',
            },
            'input::-ms-reveal': {
              display: 'none',
            },
            ...sx,
          }}
          onKeyDown={onlyNumber ? (evt) => evt.key === 'e' && evt.preventDefault() : undefined}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...registerForm}
          {...props}
        />
        {error && typeof error !== 'boolean' && (
          <FormHelperText className="text-errorColor ml-0">{error}</FormHelperText>
        )}
      </FormControl>
    );
  }
);
