export enum EmployerMatchEnum {
  BASIC_SAFE_HARBOR = "BASIC_SAFE_HARBOR",
  ENHANCED_SAFE_HARBOR_AT_SIX_PERCENT = "ENHANCED_SAFE_HARBOR_AT_SIX_PERCENT",
  DISCRETIONARY_MATCH = "DISCRETIONARY_MATCH",
  NON_ELECTIVE_SAFE_HARBOR_CONTRIBUTION = "NON_ELECTIVE_SAFE_HARBOR_CONTRIBUTION",
}

export enum ServiceRequirementEnum {
  IMMEDIATELY_UPON_HIRE = "IMMEDIATELY_UPON_HIRE",
  SIX_MONTHS_AFTER_HIRE = "SIX_MONTHS_AFTER_HIRE",
  ONE_YEAR_AFTER_HIRE = "ONE_YEAR_AFTER_HIRE",
}

export enum HarborContributionEnum {
  ONE_HUNDRED = "ONE_HUNDRED",
  THREE_PERCENT = "THREE_PERCENT",
}

export enum ContributionStartDateEnum {
  AS_SOON_AS_POSSIBLE = "AS_SOON_AS_POSSIBLE",
  ON_SELECTED_DATE = "ON_SELECTED_DATE",
}
