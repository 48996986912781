import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { PendingEmployeeDetailForm, PendingEmployeeResponse } from 'core/models/employeeDto.model';
import ApiService, { getPendingEmployeeDetails } from 'core/services/api.service';

interface PendingEmployeeState {
  profile: PendingEmployeeResponse | null;
  isResetModalOpen: boolean;
  isInvitedModalOpen: boolean;
  loading: boolean;
}

const initialState: PendingEmployeeState = {
  profile: null,
  isResetModalOpen: false,
  isInvitedModalOpen: false,
  loading: false,
};

export const getPendingProfileAsync = createAsyncThunk(
  'pendingEmployee/getPendingProfileAsync',
  async ({ pendingUserId }: { pendingUserId: string | undefined }): Promise<any> => {
    const response = await getPendingEmployeeDetails(pendingUserId);
    return response.data;
  }
);

export const updatePendingEmployeeDetailsAsync = createAsyncThunk(
  'pendingEmployee/updatePendingEmployeeDetails',
  async (payload: PendingEmployeeDetailForm & { id?: string }): Promise<any> => {
    const response = await ApiService.updatePendingEmployeeDetails(payload);
    return response.data;
  }
);

const pendingEmployeeSlice = createSlice({
  name: 'pendingEmployee',
  initialState,
  reducers: {
    setEmployeeProfile: (state, action) => {
      state.profile = action.payload;
    },
    setPendingEmployeeProfile: (state, action) => {
      state.profile = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPendingProfileAsync.fulfilled, (state, action) => {
      state.profile = action.payload;
    });
  },
});

export const { setPendingEmployeeProfile } = pendingEmployeeSlice.actions;

const pendingEmployeeReducer = pendingEmployeeSlice.reducer;
export default pendingEmployeeReducer;
