import { Tab, Tabs } from '@mui/material';
import { appPalette } from 'core/constants';
import React from 'react';
import { PageHeader } from 'common/components';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import TabDetails from 'components/employeeProfile/TabDetails';
import ConnectPlaidEmployee from 'common/components/ConnectPlaidEmployee';
import EmployeeEditBeneficiaryV2 from 'components/employeeEditBeneficiaryV2';
import TabSecurity from 'components/employeeProfile/TabSecurity';

enum EmployeeTabName {
  PROFILE = 'profile',
  UPDATE_PASSWORD = 'update-password',
  BANK_ACCOUNT = 'bank-account',
  BENEFICIARY = 'beneficiary',
}

const EmployeeSettings = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  let tabIndex = searchParams.get('tab') || EmployeeTabName.PROFILE;

  React.useEffect(() => {
    if (!Object.values(EmployeeTabName).includes(tabIndex as EmployeeTabName)) {
      Object.values(EmployeeTabName).filter((tabName: EmployeeTabName) =>
        tabIndex.includes(tabName) ? (tabIndex = tabName) : ''
      );
      navigate(`/dashboard/employee/settings?tab=${tabIndex}`, {
        replace: true,
      });
    }
  }, []);

  return (
    <div>
      <PageHeader
        pageTitle="Settings"
        subTitle="Following is what we know about you till now. You can tell us more by filling out the blank fields or come back at any time and make changes."
      />
      <div className="w-full px-8">
        <Tabs
          TabIndicatorProps={{
            style: {
              backgroundColor: appPalette.secondary,
            },
          }}
          onChange={(event: React.SyntheticEvent, newValue: EmployeeTabName) => {
            navigate(`/dashboard/employee/settings?tab=${newValue}`, {
              replace: true,
            });
          }}
          value={tabIndex}
          sx={{
            borderBottom: '1px solid #DFE0E2',
            '& .MuiTab-root': {
              color: `${appPalette.darkest}!important`,
              fontWeight: 600,
              fontSize: 13,
            },
            '& .Mui-selected': {
              color: `${appPalette.secondary}!important`,
              fontWeight: 600,
              fontSize: 13,
            },
          }}
        >
          <Tab label="Profile" className="capitalize" value={EmployeeTabName.PROFILE} />
          <Tab label="Update Password" className="capitalize" value={EmployeeTabName.UPDATE_PASSWORD} />
          <Tab label="Bank Account" className="capitalize" value={EmployeeTabName.BANK_ACCOUNT} />
          <Tab label="Beneficiaries" className="capitalize" value={EmployeeTabName.BENEFICIARY} />
        </Tabs>
        <div className="mt-6 w-full">
          {tabIndex === EmployeeTabName.PROFILE && <TabDetails />}
          {tabIndex === EmployeeTabName.BANK_ACCOUNT && <ConnectPlaidEmployee />}
          {tabIndex === EmployeeTabName.BENEFICIARY && <EmployeeEditBeneficiaryV2 />}
          {tabIndex === EmployeeTabName.UPDATE_PASSWORD && <TabSecurity />}
        </div>
        <div className="my-6">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default EmployeeSettings;
