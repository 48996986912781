import { Box, Typography } from "@mui/material";
import { Button } from "common/components";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const OnboardingLandingPage = () => {
  const navigate = useNavigate();
  const refContent = useRef(null);
  const [isReady, setIsReady] = useState<boolean>(false);

  useEffect(() => {
    let debounce: any;
    const onResize = () => {
      clearTimeout(debounce);
      debounce = setTimeout(function () {
        if (refContent.current) {
          const elementContent = refContent.current as HTMLElement;
          const fatherContent = elementContent.parentElement;
          elementContent.style.minHeight = `${
            (fatherContent?.clientHeight ?? 0) -
            parseFloat(fatherContent?.style.paddingBottom ?? "0")
          }px`;
          if (!isReady) {
            setIsReady(true);
          }
        }
      }, 350);
    };
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);
  return (
    <div className="h-full flex items-center justify-center" ref={refContent}>
      {isReady && (
        <div className="flex gap-6 sm:gap-4 zero:flex-col sm:flex-row px-8 mt-4 sm:mt-0 items-center justify-center h-[210px]">
          <Box
            sx={{
              borderRadius: 4,
              padding: "40px 24px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              boxShadow: "0px 16px 24px 16px rgba(0, 0, 0, 0.09)",
              flex: 1,
              maxHeight: 210,
              height: "100%",
              backgroundColor: (theme) => theme.palette.white,
            }}
          >
            <Typography
              variant="h1"
              className="text-primary font-semibold text-base"
            >
              For Employees
            </Typography>
            <Typography className="text-primary text-sm mt-1">
              If you are an employee looking to join your company's 401(k) plan,
              click here to setup your account.
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              className="mt-6"
              onClick={() => navigate("/onboarding/employee/type")}
            >
              Start
            </Button>
          </Box>
          <Box
            sx={{
              borderRadius: 4,
              padding: "40px 24px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              flex: 1,
              maxHeight: 210,
              height: "100%",
              boxShadow: "0px 16px 24px 16px rgba(0, 0, 0, 0.09)",
              backgroundColor: (theme) => theme.palette.white,
            }}
          >
            <Typography
              variant="h1"
              className="text-primary font-semibold text-base"
            >
              For Businesses
            </Typography>
            <Typography className="text-primary text-sm mt-1">
              If you are a business owner, looking to create a 401(k) plan for
              your company, click here.
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              className="mt-6"
              onClick={() => navigate("/onboarding/employer/type")}
            >
              Start
            </Button>
          </Box>
        </div>
      )}
    </div>
  );
};

export default OnboardingLandingPage;
