import { Outlet } from 'react-router-dom';
import Footer from 'common/components/Footer';

import './styles.scss';
import MainLayout from 'layout/MainLayout';
import { AppBar, Container, Toolbar } from '@mui/material';
import SAVEDAY_LOGO from 'assets/images/saveday-horizontal-white.png';

const HomeLayout = () => {
  return (
    <MainLayout>
      <div className="home-main-wrapper">
        <AppBar
          position="fixed"
          sx={{
            backgroundColor: (theme) => theme.palette.primaryColor,
            boxShadow: '0px 2px 10px rgba(83, 86, 89, 0.1)',
          }}
        >
          <Toolbar sx={{ height: '64px' }}>
            <Container className="flex items-center justify-between">
              <div className="w-[120px] h-[32px]">
                <a href="https://www.saveday.com/">
                  <img src={SAVEDAY_LOGO} className="h-full" alt="saveday-logo" />
                </a>
              </div>
              <a className="text-white text-base" href="/login">
                Log-in
              </a>
            </Container>
          </Toolbar>
        </AppBar>
        <main className="content">
          <Outlet />
        </main>
        <footer className="footer sticky bottom-0 w-full">
          <Footer />
        </footer>
      </div>
    </MainLayout>
  );
};

export default HomeLayout;
