import { GridFilterItem } from '@mui/x-data-grid';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { REQUEST_STATUS } from 'core/constants/roles';
import { DataResponse } from 'core/models/dataResponseModel.model';
import { ProfileDto } from 'core/models/profileDto.model';
import EmployerService, { getEmployees, updateStatusEmployees } from 'core/services/employer.service';

interface EmployeeResponse extends DataResponse {
  content: ProfileDto[];
}

interface IEmployerEmployeeState {
  getEmployeesStatus: string;
  updateStatusEmployeesStatus: string;
  error: any;
  employees: EmployeeResponse | null;
  onBehalfOptOutParticipantStatus: REQUEST_STATUS;
}

export interface IEmployeesParams {
  companyId: string;
  pageSize: number;
  page: number;
  search: string;
  sortModel: any;
  filterModel: { items: GridFilterItem[] };
  ignoreAdmin?: boolean;
}

export interface IUpdateStatusEmployeesParams {
  toStatus: string;
  selectedEmployees: any[];
}

export const getEmployeesAsync = createAsyncThunk(
  'employerEmployee/getEmployeesAsync',
  async (params: IEmployeesParams): Promise<any> => {
    const response = await getEmployees(params);
    return response;
  }
);

export const onBehalfOptOutParticipantAsync = createAsyncThunk(
  'employerEmployee/onBehalfOptOutParticipantAsync',
  async ({ payload }: { payload: any }): Promise<any> => {
    const response = await EmployerService.onBehalfOptOutParticipant({
      payload,
    });
    return response;
  }
);

export const updateStatusEmployeesAsync = createAsyncThunk(
  'employerEmployee/updateStatusEmployeesAsync',
  async (params: IUpdateStatusEmployeesParams): Promise<any> => {
    const response = await updateStatusEmployees(params);
    return response;
  }
);

const initialState: IEmployerEmployeeState = {
  getEmployeesStatus: REQUEST_STATUS.IDLE,
  updateStatusEmployeesStatus: REQUEST_STATUS.IDLE,
  error: null,
  employees: null,
  onBehalfOptOutParticipantStatus: REQUEST_STATUS.IDLE,
};

const employeeEmployeeSlice = createSlice({
  name: 'employerEmployee',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEmployeesAsync.pending, (state) => {
        state.getEmployeesStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(getEmployeesAsync.fulfilled, (state, action) => {
        state.getEmployeesStatus = REQUEST_STATUS.SUCCESS;
        state.employees = action.payload;
      })
      .addCase(updateStatusEmployeesAsync.pending, (state) => {
        state.updateStatusEmployeesStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(updateStatusEmployeesAsync.fulfilled, (state, action) => {
        state.updateStatusEmployeesStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(updateStatusEmployeesAsync.rejected, (state) => {
        state.updateStatusEmployeesStatus = REQUEST_STATUS.ERROR;
      })
      .addCase(onBehalfOptOutParticipantAsync.pending, (state) => {
        state.onBehalfOptOutParticipantStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(onBehalfOptOutParticipantAsync.fulfilled, (state, action) => {
        state.onBehalfOptOutParticipantStatus = REQUEST_STATUS.SUCCESS;
      })
      .addCase(onBehalfOptOutParticipantAsync.rejected, (state) => {
        state.onBehalfOptOutParticipantStatus = REQUEST_STATUS.ERROR;
      });
  },
});

export default employeeEmployeeSlice.reducer;
