import ParticipantLayout from 'layout/ParticipantLayout';
import { lazy, Suspense } from 'react';
import { RouteObject } from 'react-router-dom';
import EmployeeContributions from 'pages/employeeContributionsNew';
import EmployeePlancomparison from 'pages/employeePlancomparison';
import EmployeeSettings from 'pages/employeeSettings';
import EmployeeDashboardV2Page from 'pages/employeeDashboardV2';
const EmployeeCalculatorPage = lazy(() => import('pages/employeeCalculator'));
const EmployerNotificationPage = lazy(() => import('pages/employerNotification'));
const EmployerSingleNotificationPage = lazy(() => import('pages/employerSingleNotification'));
const EmployeeTransactions = lazy(() => import('pages/employeeTransactions'));
const EmployeeRolloverNewPage = lazy(() => import('components/employeeRolloverNew/EmployeeRolloverNewPage'));

const EmployeeRolloverNew = lazy(() => import('pages/employeeRolloverNew'));
const EmployeeAllocationsPage = lazy(() => import('pages/employeeAllocations'));
const EmployeePlanDetails = lazy(() => import('pages/employeePlanDetails'));
const EmployeePlanForm = lazy(() => import('pages/employeePlanForm'));
const EmployeeLoansPage = lazy(() => import('pages/employeeLoans'));
const EmployeeLoanFormPage = lazy(() => import('pages/employeeLoanForm'));
const EmployeeStatements = lazy(() => import('pages/employeeStatements'));
const EmployeeDistributionsPage = lazy(() => import('pages/employeeDistributions'));
const EmployeeDistributionsForm = lazy(() => import('pages/employeeDistributionForm'));

export const employeeRoutes: RouteObject[] = [
  {
    path: '/dashboard', // /dashboard
    element: <ParticipantLayout />,
    children: [
      {
        path: 'employee',
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <EmployeeDashboardV2Page />
              </Suspense>
            ),
          },
          {
            path: 'settings',
            element: (
              <Suspense>
                <EmployeeSettings />
              </Suspense>
            ),
          },
          {
            path: 'notification',
            element: (
              <Suspense>
                <EmployerNotificationPage />
              </Suspense>
            ),
          },
          {
            path: 'notification/:notificationId',
            element: (
              <Suspense>
                <EmployerSingleNotificationPage />
              </Suspense>
            ),
          },
          {
            path: 'transactions',
            element: (
              <Suspense>
                <EmployeeTransactions />
              </Suspense>
            ),
          },
          {
            path: 'contributions',
            element: (
              <Suspense>
                <EmployeeContributions />
              </Suspense>
            ),
          },
          {
            path: 'plancomparison',
            element: (
              <Suspense>
                <EmployeePlancomparison />
              </Suspense>
            ),
          },
          {
            path: 'calculator',
            element: (
              <Suspense>
                <EmployeeCalculatorPage />
              </Suspense>
            ),
          },
          {
            path: 'rollover',
            element: (
              <Suspense>
                <EmployeeRolloverNew />
              </Suspense>
            ),
            children: [
              {
                path: '',
                element: (
                  <Suspense>
                    <EmployeeRolloverNewPage />
                  </Suspense>
                ),
              },
              {
                path: 'account-statement',
                element: (
                  <Suspense>
                    <EmployeeRolloverNewPage />
                  </Suspense>
                ),
              },
              {
                path: 'signature',
                element: (
                  <Suspense>
                    <EmployeeRolloverNewPage />
                  </Suspense>
                ),
              },
              {
                path: 'mail-instructions',
                element: (
                  <Suspense>
                    <EmployeeRolloverNewPage />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: 'allocations',
            element: (
              <Suspense>
                <EmployeeAllocationsPage />
              </Suspense>
            ),
          },
          {
            path: 'distributions',
            element: (
              <Suspense>
                <EmployeeDistributionsPage />
              </Suspense>
            ),
          },
          {
            path: 'distributions-form',
            element: (
              <Suspense>
                <EmployeeDistributionsForm />
              </Suspense>
            ),
          },
          {
            path: 'plandetails',
            element: (
              <Suspense>
                <EmployeePlanDetails />
              </Suspense>
            ),
          },
          {
            path: 'planforms',
            element: (
              <Suspense>
                <EmployeePlanForm />
              </Suspense>
            ),
          },
          {
            path: 'loans',
            element: (
              <Suspense>
                <EmployeeLoansPage />
              </Suspense>
            ),
          },
          {
            path: 'loan-request-form',
            element: (
              <Suspense>
                <EmployeeLoanFormPage />
              </Suspense>
            ),
          },
          {
            path: 'statements',
            element: (
              <Suspense>
                <EmployeeStatements />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
];
