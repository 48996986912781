import LinkedInIcon from "@mui/icons-material/LinkedIn";
import React from "react";

const Footer = () => {
  return (
    <div className="py-5 px-8 flex items-center justify-between bg-darkest">
      <div className="text-beigeBorderColor text-sm pr-2">
        This website is operated and maintained by DCP 360, Inc d/b/a Saveday.
        By accessing the website and any pages thereof, you agree to be bound by
        its{" "}
        <a
          className="underline underline-offset-2 text-lighter"
          href="/policies/terms"
          target="_blank"
        >
          Terms of Service
        </a>{" "}
        and{" "}
        <a
          className="underline-offset-2 underline text-lighter"
          href="/policies/privacy"
          target="_blank"
        >
          Privacy Policy
        </a>
      </div>
      <div className="flex gap-6 text-white items-center mr-14">
        <a
          className="icon w-6 h-6 flex justify-center cursor-pointer fill-white"
          href="https://www.linkedin.com/company/saveday "
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedInIcon />
        </a>
      </div>
    </div>
  );
};
export default Footer;
