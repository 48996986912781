import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { REQUEST_STATUS } from 'core/constants/roles';
import { PlanDetailDto } from 'core/models/PlanDetailDto.model';
import PEPService from 'core/services/pep.service';

interface IMasterPlanState {
  getMasterPlansStatus: string;
  actionMasterPlansStatus: string;
  masterPlans: PlanDetailDto[];
}

const initialState: IMasterPlanState = {
  getMasterPlansStatus: REQUEST_STATUS.IDLE,
  actionMasterPlansStatus: REQUEST_STATUS.IDLE,
  masterPlans: [],
};

export const getMasterPlansAsync = createAsyncThunk('pepMasterPlan/getMasterPlansAsync', async (): Promise<any> => {
  const response = await PEPService.getMasterPlans();
  return response.data;
});

export const updateMasterPlansAsync = createAsyncThunk(
  'pepMasterPlan/updateMasterPlansAsync',
  async (body: any): Promise<any> => {
    const response = await PEPService.updateMasterPlans(body);
    return response.data;
  }
);

const pepMasterPlanSlice = createSlice({
  name: 'pepMasterPlan',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMasterPlansAsync.pending, (state) => {
        state.getMasterPlansStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(getMasterPlansAsync.fulfilled, (state, action) => {
        state.getMasterPlansStatus = REQUEST_STATUS.SUCCESS;
        state.masterPlans = action.payload;
      })
      .addCase(getMasterPlansAsync.rejected, (state, action) => {
        state.getMasterPlansStatus = REQUEST_STATUS.ERROR;
      })

      .addCase(updateMasterPlansAsync.pending, (state) => {
        state.actionMasterPlansStatus = REQUEST_STATUS.SENDING;
      })
      .addCase(updateMasterPlansAsync.fulfilled, (state, action) => {
        state.actionMasterPlansStatus = REQUEST_STATUS.SUCCESS;
        state.masterPlans = action.payload;
      })
      .addCase(updateMasterPlansAsync.rejected, (state, action) => {
        state.actionMasterPlansStatus = REQUEST_STATUS.ERROR;
      });
  },
});

export default pepMasterPlanSlice.reducer;
