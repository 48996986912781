import { FormLabel as MuiFormLabel, FormLabelProps } from '@mui/material';
import clsx from 'clsx';

export const FormLabel = ({ children, className, id, ...props }: FormLabelProps) => {
  return (
    <MuiFormLabel className={clsx('text-xs font-bold text-superBlack', className)} {...props}>
      {children}
    </MuiFormLabel>
  );
};
