import { configureStore, combineReducers, PayloadAction, CombinedState, createAction } from '@reduxjs/toolkit';
import manageEmployeeReducer from 'states/manageEmployee/manageEmployeeSlice';
import managePendingEmployeeReducer from 'states/managePendingEmployee/managePendingEmployeeSlice';
import manageEmployerReducer from 'states/manageEmployer/manageEmployerSlice';
import resetPasswordReducer from 'states/reset-password/resetPasswordSlice';
import employeeTransactionsReducer from 'states/employeeTransactions/employeeTransactionsSlice';
import employerAnnualLimitsReducer from 'states/employerAnnualLimits/employerAnnualLimitsSlice';
import riskQuestionReducer from 'states/employeeRiskQuestion/employeeRiskQuestionSlice';
import planSliceReducer from './manage-plan/managePlanSlice';
import authReducer, { logoutSessionSync } from 'states/auth/authSlice';
import employeeStatementsSlice from 'states/employeeStatements/employeeStatementsSlice';
import employerStatementsSlice from 'states/employerStatements/employerStatementsSlice';
import employeeAccountsSlice from './employeeAccountsSlice';
import manageCompanyAdminSlice from './manageCompanyAdmin/manageCompanyAdminSlice';
import snackbarMessageSlice from './snackbarMessage/snackbarMessageSlice';
import formFilingSliceReducer from './formFiling/formFilingSlice';
import companyDetailsSlice from './companyDetails/companyDetailsSlice';
import employerInboxSlice from './employerInboxSlice';
import employerEmployeeSlice from './employerEmployeeSlice';
import employerReportSlice from './employerReport/employerReportSlice';
import employerDashboardSlice from './employerDashboard/employerDashboardSlice';
import employerProcessContributionsSlice from './employerProcessContributions/employerProcessContributionsSlice';
import employerPayrollPreviewSlice from './employerPayrollPreview/employerPayrollPreviewSlice';
import accountManagementReducer from './accountManagement/accountManagementSlice';
import employeeSlice from './employee/employeeSlice';
import payrollSlice from './payroll/payrollSlice';
import onboardingSlice from './onboardingSlice';
import employerPendingEmployeeSlice from './employerPendingEmployeeSlice';
import pendingEmployeeSlice from './pendingEmployee/pendingEmployeeSlice';
import employeeLoanSlice from './employeeLoanSlice';
import cpaClientSlice from './cpaClientSlice';
import employeeDistributionsSlice from './employeeDistributionsSlice';
import pepMasterPlanReducer from './pepMasterPlanSlice';
import pepPlanAdminReducer from './pepPlanAdminSlice';
import cpaPlanAdminReducer from './cpaPlanAdminSlice';
import corpPlanAdminReducer from './corpPlanAdminSlice';
import finchConnectionReducer from './finchConnectionSlice';

const combinedReducer = combineReducers({
  manageEmployee: manageEmployeeReducer,
  managePendingEmployee: managePendingEmployeeReducer,
  accountManagement: accountManagementReducer,
  manageEmployer: manageEmployerReducer,
  auth: authReducer,
  resetPassword: resetPasswordReducer,
  employeeTransactions: employeeTransactionsReducer,
  employerAnnualLimits: employerAnnualLimitsReducer,
  riskQuestion: riskQuestionReducer,
  plan: planSliceReducer,
  formFiling: formFilingSliceReducer,
  employeeStatements: employeeStatementsSlice,
  employerStatements: employerStatementsSlice,
  employerDashboard: employerDashboardSlice,
  employeeAccounts: employeeAccountsSlice,
  manageCompanyAdmin: manageCompanyAdminSlice,
  snackbarMessage: snackbarMessageSlice,
  companyDetails: companyDetailsSlice,
  employerInbox: employerInboxSlice,
  employerEmployee: employerEmployeeSlice,
  report: employerReportSlice,
  employerProcessContributions: employerProcessContributionsSlice,
  employerPayrollPreview: employerPayrollPreviewSlice,
  employee: employeeSlice,
  payroll: payrollSlice,
  onboarding: onboardingSlice,
  employerPendingEmployee: employerPendingEmployeeSlice,
  pendingEmployee: pendingEmployeeSlice,
  employeeLoan: employeeLoanSlice,
  cpaClient: cpaClientSlice,
  distributions: employeeDistributionsSlice,
  pepMasterPlan: pepMasterPlanReducer,
  pepPlanAdmin: pepPlanAdminReducer,
  cpaPlanAdmin: cpaPlanAdminReducer,
  corpPlanAdmin: corpPlanAdminReducer,
  finchConnection: finchConnectionReducer,
});

const rootReducer = (state: CombinedState<any>, action: PayloadAction<any>) => {
  if (action.type === 'store/reset') {
    state = undefined;
  }
  if (action.type === logoutSessionSync.fulfilled.toString()) {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export const resetAllState = createAction<undefined>('store/reset');

export const store = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof combinedReducer>;
