import { Divider } from '@mui/material';
import { TWO_FA_STATUS } from 'core/constants/roles';
import React from 'react';
import { useAppSelector } from 'states/hooks';

interface IReceiveCodeByAPPProps {}

const ReceiveCodeByAPP = (props: IReceiveCodeByAPPProps) => {
  const twoFA = useAppSelector((state) => state.manageEmployee.twoFA);
  return (
    <>
      {twoFA && twoFA.qrCode && twoFA.status !== TWO_FA_STATUS.ACTIVE && (
        <>
          <div className="flex justify-center">
            <img src={atob(twoFA.qrCode)} alt="" width="200" height="200" />
          </div>
          <p className="text-darkGreen text-xs font-semibold">Scan the QR code into your app</p>
          <Divider className="my-6 border-borderColor" />
        </>
      )}
      <p className="text-sm text-primary">Enter the 6-digit authentication code generated by your app:</p>
    </>
  );
};

export default ReceiveCodeByAPP;
