import AccountCircleIcon from 'assets/SVGIcons/AccountCircleIcon';
import AccountTreeIcon from 'assets/SVGIcons/AccountTreeIcon';
import DashboardIcon from 'assets/SVGIcons/DashboardIcon';
import SettingIcon from 'assets/SVGIcons/SettingIcon';
import AuthLayout from 'layout/AuthLayout';
import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { getCookie } from 'core/services/cookie.service';
import { CookieNames } from 'core/enums/cookie-names.enum';
import { AuthContextType, useAuth } from 'contexts/AuthProvider';
import { ROLES } from 'core/constants/roles';
import PieChartIcon from 'assets/SVGIcons/PieChartIcon';

interface ISystemAdminLayoutProps {
  children?: React.ReactNode;
}

export default function SystemAdminLayout(props: ISystemAdminLayoutProps) {
  let menu = [
    {
      url: '/admin/manage-employers',
      label: 'Manage Employer',
      icon: <DashboardIcon />,
    },
    {
      url: '/admin/manage-participants',
      label: 'Manage Participants',
      icon: <SettingIcon />,
    },
    {
      url: '/admin/manage-pending-employees',
      label: 'Manage Pending Employee',
      icon: <AccountCircleIcon />,
    },
    // {
    //   url: "/admin/manage-cpa",
    //   label: "Manage CPA",
    //   icon: <AccountCircleIcon />,
    // },
    {
      url: '/admin/selectcompany',
      label: 'Manage Plans',
      icon: <AccountTreeIcon />,
    },
    {
      label: 'Operations',
      icon: <PieChartIcon />,
      childrens: [
        {
          url: '/admin/managepayroll',
          label: 'Payroll Contributions',
          icon: <PieChartIcon />,
        },
        {
          url: '/admin/finch-connection',
          label: 'Finch Connections',
          icon: <PieChartIcon />,
        },
        {
          url: '/admin/partner-dashboard',
          label: 'Partner Dashboard',
          icon: <PieChartIcon />,
        },
        {
          url: '/admin/late-contribution-report?tab=late-contribution-report',
          label: 'Late Contributions Report',
          icon: <PieChartIcon />,
        },
      ],
    },
  ];

  const cookieSystemAdminRole = getCookie(CookieNames.SystemAdminRole);
  if (cookieSystemAdminRole) {
    const itemsToKeep = cookieSystemAdminRole.systemAdminTabs;
    if (itemsToKeep && itemsToKeep.length > 0) {
      menu = menu
        .map((obj) => {
          if (obj.childrens) {
            const filteredChildren = obj.childrens.filter((child) => itemsToKeep.includes(child.url));
            return {
              ...obj,
              childrens: filteredChildren,
            };
          }
          return obj;
        })
        .filter((obj) => {
          if (obj.childrens) {
            return obj.childrens.length > 0;
          }
          return true;
        });
    }
  }

  const { session } = useAuth() as AuthContextType;

  return (
    <AuthLayout menu={menu}>
      {session && session.currentRole === ROLES.SYSTEM_ADMIN && (
        <>
          <Outlet />
        </>
      )}
    </AuthLayout>
  );
}
