import { GridColDef } from "@mui/x-data-grid";
import { formatTimestamp, sortByYMD } from "core/functions/table-helpers";
import { Option } from "core/models/Option.model";
import { formatCurrency } from "utils/number";

export enum EmployerReportType {
  ActivePlanParticipants = "active plan participants",
  ContributionsReport = "getAllContributions",
  PendingParticipantsReport = "pending participants report",
  YearToDateContribution = "ytdContributions",
  SummaryReport = "summaryReport",
  AccountBalance = "accountBalance",
}

export enum ReportTimeRange {
  Last30Days = "Last 30 Days",
  Last90Days = "Last 90 Days",
  Last120Days = "Last 120 Days",
  Custom = "Custom Time Period",
}

export enum TypeOfEmployerReport {
  ActivePlanParticipants = "active_plan_participants",
  ContributionsReport = "contributions",
  PendingParticipantsReport = "pending_participants",
  YearToDateContribution = "ytd_contributions",
  SummaryReport = "summaryReport",
  AccountBalance = "account_balance",
}

export const timePeriodOptions: Option[] = [
  {
    label: ReportTimeRange.Last30Days,
    value: ReportTimeRange.Last30Days,
  },
  {
    label: ReportTimeRange.Last90Days,
    value: ReportTimeRange.Last90Days,
  },
  {
    label: ReportTimeRange.Last120Days,
    value: ReportTimeRange.Last120Days,
  },
  {
    label: ReportTimeRange.Custom,
    value: ReportTimeRange.Custom,
  },
];

export const initialFilter = {
  from: "",
  to: "",
  search: "",
  yearToDate: "",
  timePeriod: "",
};

const formatterAndSorterDate = {
  valueFormatter: ({ value }: { value: number }) => {
    return formatTimestamp(value);
  },
  sortComparator: (v1: number, v2: number) => {
    return sortByYMD(v1, v2);
  },
};

const formatterCurrency = {
  valueFormatter: ({ value }: { value: number | string }) => {
    return typeof value === "number" ? formatCurrency(value) : value;
  },
};

export const activePlanColumns: GridColDef[] = [
  {
    field: "ssn",
    headerName: "SSN",
    flex: 1,
    align: "center",
    headerAlign: "center",
    minWidth: 100,
  },
  {
    field: "firstName",
    headerName: "First Name",
    flex: 1,
    align: "center",
    headerAlign: "center",
    minWidth: 100,
  },
  {
    field: "lastName",
    headerName: "Last Name",
    flex: 1,
    align: "center",
    headerAlign: "center",
    minWidth: 100,
  },
  {
    field: "dateOfBirth",
    headerName: "Date of Birth",
    flex: 1,
    align: "center",
    headerAlign: "center",
    minWidth: 100,
    ...formatterAndSorterDate,
  },
  {
    field: "dateOfHire",
    headerName: "Date of Hire",
    flex: 1,
    align: "center",
    headerAlign: "center",
    minWidth: 100,
    ...formatterAndSorterDate,
  },
  {
    field: "planEnrollment",
    headerName: "Plan Enrollment Date",
    flex: 1,
    align: "center",
    headerAlign: "center",
    minWidth: 100,
    ...formatterAndSorterDate,
  },
];

export const allContributionColumns: GridColDef[] = [
  {
    field: "ssn",
    headerName: "SSN",
    flex: 1,
    align: "center",
    headerAlign: "center",
    minWidth: 120,
    filterable: false,
  },
  {
    field: "firstName",
    headerName: "First Name",
    flex: 1,
    align: "center",
    headerAlign: "center",
    minWidth: 100,
    filterable: false,
  },
  {
    field: "lastName",
    headerName: "Last Name",
    flex: 1,
    align: "center",
    headerAlign: "center",
    minWidth: 100,
    filterable: false,
  },
  {
    field: "payrollCheckDate",
    headerName: "Payroll Check Date",
    flex: 1,
    align: "center",
    headerAlign: "center",
    minWidth: 150,
    filterable: false,
    ...formatterAndSorterDate,
  },
  {
    field: "tradVal",
    headerName: "Pre-tax $",
    flex: 1,
    align: "center",
    headerAlign: "center",
    minWidth: 100,
    filterable: false,
    ...formatterCurrency,
  },
  {
    field: "rothVal",
    headerName: "Roth $",
    flex: 1,
    align: "center",
    headerAlign: "center",
    minWidth: 100,
    filterable: false,
    ...formatterCurrency,
  },
  {
    field: "matchVal",
    headerName: "ER/SH Match $",
    flex: 1,
    align: "center",
    headerAlign: "center",
    minWidth: 120,
    filterable: false,
    ...formatterCurrency,
  },
  {
    field: "profitShareVal",
    headerName: "Profit Sharing $",
    flex: 1,
    align: "center",
    headerAlign: "center",
    minWidth: 140,
    filterable: false,
    ...formatterCurrency,
  },
  {
    field: "loanVal",
    headerName: "Loan RePayment $",
    flex: 1,
    align: "center",
    headerAlign: "center",
    minWidth: 150,
    filterable: false,
    ...formatterCurrency,
  },
  {
    field: "totalAmount",
    headerName: "Total $",
    flex: 1,
    align: "center",
    headerAlign: "center",
    minWidth: 100,
    sortable: false,
    filterable: false,
    ...formatterCurrency,
  },
];

export const pendingColumns: GridColDef[] = [
  {
    field: "ssn",
    headerName: "SSN",
    flex: 1,
    align: "center",
    headerAlign: "center",
    minWidth: 100,
  },
  {
    field: "firstName",
    headerName: "First Name",
    flex: 1,
    align: "center",
    headerAlign: "center",
    minWidth: 100,
  },
  {
    field: "lastName",
    headerName: "Last Name",
    flex: 1,
    align: "center",
    headerAlign: "center",
    minWidth: 100,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    align: "center",
    headerAlign: "center",
    minWidth: 100,
  },
  {
    field: "addedOn",
    headerName: "Added On",
    flex: 1,
    align: "center",
    headerAlign: "center",
    minWidth: 100,
    ...formatterAndSorterDate,
  },
];

const isDateCol = (field: string) => {
  const POSSIBLE_DATE_FIELD = ["As of Date"];
  return POSSIBLE_DATE_FIELD.includes(field);
};

const isCurrencyCol = (field: string) => {
  const POSSIBLE_CURRENCY_FIELD = [
    "Trad Acc Value",
    "Roth Acc Value",
    "Account Balance",
    "YTD Traditional",
    "YTD Employee Total",
    "YTD Employer Total",
    "YTD Loan",
    "YTD Matching",
    "YTD ProfitSharing",
    "YTD RollOver",
    "YTD Roth",
    "YTD Total",
  ];
  return POSSIBLE_CURRENCY_FIELD.includes(field);
};

export const getDynamicColumns = (
  headerList?: Array<string>,
  enableSort: boolean = true
): GridColDef[] => {
  if (!headerList) return [];

  return headerList.map((item) => {
    const extra = {
      ...(isDateCol(item) ? formatterAndSorterDate : {}),
      ...(isCurrencyCol(item) ? formatterCurrency : {}),
    };

    return {
      field: item,
      headerName: item,
      flex: 1,
      align: "center",
      headerAlign: "center",
      minWidth: 120,
      filterable: false,
      sortable: enableSort,
      ...extra,
    };
  });
};

/**
 *
 * @param item object {key: '1', key2: '2'}
 * @param fieldsToNum Array of keys of item, those fields need to be converted into number
 * @param shouldNotConvertInputFields if true, those "fieldsToNum" will NOT be converted to number, others will. Default is false
 * @returns
 */
export const convertFieldsToNumIfPossible = <T>(
  item: T,
  fieldsToNum: (keyof T)[],
  shouldNotConvertInputFields = false
): T => {
  const converted = {} as any;
  for (const k in item) {
    const key = k as keyof T;
    let shouldConvertToNum = fieldsToNum.includes(key);
    if (shouldNotConvertInputFields) {
      shouldConvertToNum = !shouldConvertToNum;
    }

    if (shouldConvertToNum) {
      converted[key] = isNaN(Number(item[key])) ? item[key] : Number(item[key]);
    } else {
      converted[key] = item[key];
    }
  }
  return converted;
};
