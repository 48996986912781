import { formatCurrency } from 'utils/number';
import { IAnswers } from 'utils/processData';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

const renderLegend = (props: any) => {
  const { payload } = props;

  return (
    <ul className="flex gap-4 justify-center mt-10">
      {payload.map((entry: any, index: number) => (
        <li className="flex rounded-full items-center gap-2" key={`item-${index}`}>
          <>
            <span
              className="w-4 h-4 inline-block rounded"
              style={{
                backgroundColor: entry.color,
              }}
            ></span>
            <span className="text-primary">{entry.value}</span>
          </>
        </li>
      ))}
    </ul>
  );
};

export const formatYAxisValue = (value: number) => {
  return value >= 1000 ? `${formatCurrency(+value / 1000)}K` : formatCurrency(value);
};

interface IEmployeePaycheckProps {
  answers: IAnswers;
  preTaxAmountInt: number;
  postTaxAmountInt: number;
}

const renderLabelFormat = (value: number) => <span className="text-primary">{value} years</span>;

export const EmployeePaycheck = (props: IEmployeePaycheckProps) => {
  const { answers } = props;
  const dataChart = (answers.avgMarketProject.value || []).map((el: string, index: number) => {
    return {
      name: index + 1,
      uv: Number(el),
      pv: Number(answers.poorMarketProject.value[index] || 0),
    };
  });

  return (
    // <Box
    //   sx={(theme) => ({
    //     display: "grid",
    //     gap: "24px",
    //     gridTemplateColumns: "1.5fr 1fr",
    //     padding: "32px 0",
    //     borderBottom: "1px solid #E3F2E3",
    //     alignItems: "center",
    //     [theme.breakpoints.down(1024)]: {
    //       display: "block",
    //     },
    //   })}
    // >
    <div className="mb-4 md:m-0 w-full zero:min-w-[900px] rounded-3xl p-6 space-y-6">
      <span className="font-semibold text-xs pl-10 text-primary">* Saving Projection for the Next 30 Years</span>
      <ResponsiveContainer width="100%" height={450}>
        <BarChart
          data={dataChart}
          barSize={8}
          margin={{
            top: 20,
            right: 5,
            left: 25,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            dataKey="name"
            tickLine={{ stroke: '#3B471C', fill: '#3B471C' }}
            axisLine={{ stroke: '#3B471C', fill: '#3B471C' }}
            tick={{ fontSize: 10 }}
          />
          <YAxis
            tickLine={{ stroke: '#3B471C', fill: '#3B471C' }}
            axisLine={{ stroke: '#3B471C', fill: '#3B471C' }}
            tick={{ fontSize: 10 }}
            tickFormatter={formatYAxisValue}
          />
          <Tooltip
            formatter={(value: ValueType, name: NameType) => {
              return [formatCurrency(Number(value)), name];
            }}
            labelFormatter={(value) => {
              return renderLabelFormat(value);
            }}
          />
          <Legend content={renderLegend} />
          <Bar dataKey="pv" name="Poor Market" fill="#69AB00">
            {dataChart?.map((entry: any, index: number) => (
              <Cell key={`cell-${index}`} />
            ))}
          </Bar>
          <Bar dataKey="uv" name="Average Market" fill="#3B471C">
            {dataChart?.map((entry: any, index: number) => (
              <Cell key={`cell-${index}`} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
    // </Box>
  );
};
