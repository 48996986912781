import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import AccountCircleIcon from 'assets/SVGIcons/AccountCircleIcon';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { ROLES } from 'core/constants/roles';
import { AuthContextType, useAuth } from 'contexts/AuthProvider';
import AuthLayout from 'layout/AuthLayout';
import * as React from 'react';
import { Outlet } from 'react-router-dom';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CheckLiskPopUp from 'components/employerDashboard/CheckLiskPopUp';
import { useAppDispatch, useAppSelector } from 'states/hooks';
import { getPendingEmployeesAsync } from 'states/employerPendingEmployeeSlice';
import { getCookie } from 'core/services/cookie.service';
import { CookieNames } from 'core/enums/cookie-names.enum';

interface ICompanyAdminLayoutProps {
  children?: React.ReactNode;
}

export default function CompanyAdminLayout(props: ICompanyAdminLayoutProps) {
  const pendingNotificationCount = useAppSelector((state) => state.manageEmployer.pendingNotificationsCount);
  const employees = useAppSelector((state) => state.employerPendingEmployee.employees);
  const dispatch = useAppDispatch();
  const sessionId = getCookie(CookieNames.XCSSession);
  const fetchData = () => {
    if (sessionId) {
      dispatch(
        getPendingEmployeesAsync({
          companyId: getCookie(CookieNames.CurrentCompanyId),
          pageSize: 10,
          page: 0,
          search: '',
          sortModel: [],
          filterModel: {
            items: [],
          },
        })
      );
    }
  };

  const menu = [
    {
      url: '/dashboard/employer',
      label: 'Home',
      icon: <HomeOutlinedIcon />,
    },
    {
      label: 'Settings',
      icon: <SettingsOutlinedIcon />,
      url: '/dashboard/employer/settings?tab=company-details',
    },
    {
      label: 'Notifications',
      icon: <NotificationsNoneOutlinedIcon />,
      url: '/dashboard/employer/notification?tab=contributions',
      subLabel: pendingNotificationCount ? (
        <div className="border border-solid px-2 py-1 text-white text-xs border-secondary bg-secondary rounded text-center">
          {pendingNotificationCount}
        </div>
      ) : null,
    },
    {
      label: 'Employees',
      icon: <AccountCircleIcon />,
      childrens: [
        // {
        //   url: "/dashboard/employer/addemployee",
        //   label: "Add Employees",
        //   icon: <AccountCircleIcon />,
        // },
        {
          url: '/dashboard/employer/manageemployee',
          label: 'Manage Employees',
          icon: <AccountCircleIcon />,
        },
        {
          url: '/dashboard/employer/managependingemployee',
          label: 'Manage Pending Employees',
          icon: <AccountCircleIcon />,
          subLabel:
            (employees?.totalElements as number) > 0 ? (
              <div className="border border-solid px-2 py-1 text-white text-xs border-secondary bg-secondary rounded text-center">
                {employees?.totalElements}
              </div>
            ) : null,
        },
      ],
    },
    {
      label: 'Payroll',
      icon: <AttachMoneyIcon />,
      childrens: [
        {
          url: '/dashboard/employer/payroll',
          label: 'Process Contributions',
          icon: <AttachMoneyIcon />,
        },
        {
          url: '/dashboard/employer/processed-payroll',
          label: 'Processed Payrolls',
          icon: <AttachMoneyIcon />,
        },
      ],
    },
    {
      label: 'Plan Administration',
      icon: <TextSnippetOutlinedIcon />,
      childrens: [
        {
          url: '/dashboard/employer/reviewplan',
          label: 'Plan Details',
          icon: <TextSnippetOutlinedIcon />,
        },
        {
          url: '/dashboard/employer/annual-disclosure',
          label: 'Annual Disclosures',
          icon: <TextSnippetOutlinedIcon />,
        },
        {
          url: '/dashboard/employer/reports',
          label: 'Participant Reports',
          icon: <TextSnippetOutlinedIcon />,
        },
        {
          url: '/dashboard/employer/trust-report',
          label: 'Trust Report',
          icon: <TextSnippetOutlinedIcon />,
        },
        {
          url: '/dashboard/employer/forfeiture-report',
          label: 'Forfeiture Report',
          icon: <TextSnippetOutlinedIcon />,
        },
        {
          url: '/dashboard/employer/statements',
          label: 'Statements',
          icon: <TextSnippetOutlinedIcon />,
        },
        // {
        //   url: "/dashboard/employer/annuallimits",
        //   label: "Annual Limits",
        //   icon: <TextSnippetOutlinedIcon />,
        // },
      ],
    },
  ];

  const { session } = useAuth() as AuthContextType;

  React.useEffect(() => {
    if (session) {
      fetchData();
    }
  }, [session]);

  return (
    <AuthLayout menu={menu}>
      {session && session.currentRole === ROLES.COMPANY_ADMIN && (
        <>
          <CheckLiskPopUp />
          <Outlet />
        </>
      )}
    </AuthLayout>
  );
}
