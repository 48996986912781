import DoneIcon from "@mui/icons-material/Done";
import { ICheckListItemProps } from "core/models/popupCheckListDto.model";

export const CheckListItem = (props: ICheckListItemProps) => {
  const { children, step, title, valid, link = "", onClick, ...rest } = props;
  let stypeIncomplete = {
    borderColor: "border-beigeBorderColor",
    bgBorder: "bg-lightBeige",
    titleColor: "text-primary",
    textBorderColor: "text-beige",
    fontWeight: "font-medium",
  };
  if (valid) {
    stypeIncomplete = {
      ...stypeIncomplete,
      borderColor: "border-lightGreen",
      bgBorder: "bg-lightGreen",
      titleColor: "text-beige",
      textBorderColor: "text-beige",
      fontWeight: "font-normal",
    };
  }
  return (
    <div {...rest} className="flex items-center gap-2 mt-2">
      <div
        className={`border border-solid ${stypeIncomplete.borderColor} ${stypeIncomplete.bgBorder} rounded-full p-2 text-xs font-normal h-8 w-8 flex justify-center items-center ${stypeIncomplete.textBorderColor}`}
      >
        {valid ? <DoneIcon className="text-active w-4" /> : step}
      </div>
      <span
        className={`text-xs ${stypeIncomplete.fontWeight} ${
          stypeIncomplete.titleColor
        } ${link ? "hover:text-secondary cursor-pointer" : ""}`}
        onClick={onClick}
      >
        {title}
      </span>
    </div>
  );
};
