import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
} from '@mui/material';
import { ReactElement, forwardRef, useEffect, useRef } from 'react';

export interface IRadioOption {
  id?: string;
  label: string;
  value: number | string | boolean;
  defaultValue?: any;
  note?: string;
}

type Props = {
  name?: string;
  label?: ReactElement<any, any> | string;
  options: IRadioOption[];
  classes?: string;
  itemClasses?: string;
  defaultValue?: any;
  value: any;
  fontWeight?: string;
  fontSize?: string;
  error?: any;
  helperText?: string;
  disabled?: boolean;
  radioProps?: any;
  required?: boolean;
} & RadioGroupProps;

export const RadioButtonGroup = forwardRef(
  (
    {
      name,
      label,
      options,
      classes,
      itemClasses,
      defaultValue,
      value,
      disabled,
      fontWeight,
      fontSize,
      error,
      helperText,
      radioProps,
      required = false,
      ...props
    }: Props,
    ref
  ) => {
    useEffect(() => {
      if (value) {
        radioGroupRef.current.value = value;
      } else {
        radioGroupRef.current.value = null;
      }
    }, [value]);
    const radioGroupRef = useRef<any>({ current: null });
    return (
      <FormControl className={classes} fullWidth>
        {label && (
          <FormLabel
            id="radio-buttons-group-label"
            className="font-bold text-xs mx-4 text-superBlack"
            required={required}
          >
            {label}
          </FormLabel>
        )}
        <RadioGroup
          aria-labelledby="radio-buttons-group-label"
          value={value}
          defaultValue={defaultValue}
          name={name || 'radio-buttons-group'}
          ref={ref}
          className="w-full"
          {...props}
        >
          {Boolean(options.length) &&
            options.map((item: IRadioOption) => (
              <FormControlLabel
                sx={{
                  '& .MuiFormControlLabel-label': {
                    fontWeight: fontWeight ?? 600,
                    fontSize: fontSize ?? 13,
                    color: (theme) => theme.palette.darkest.dark,
                  },
                  '&.Mui-disabled': {
                    cursor: 'not-allowed',
                  },
                }}
                key={Math.random()}
                className={`text-primary font-semibold text-sm ${itemClasses}`}
                value={item.value}
                control={
                  <Radio
                    sx={{
                      color: (theme) => `${error ? theme.palette.errorColor : theme.palette.lightBeige} !important`,
                      '&.Mui-checked': {
                        color: (theme) => `${theme.palette.secondaryColor} !important`,
                      },
                    }}
                    {...radioProps}
                    disabled={disabled}
                  />
                }
                label={item.label}
                itemID={item.id}
              />
            ))}
        </RadioGroup>
        {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
        {error && typeof error !== 'boolean' && (
          <FormHelperText className="text-errorColor ml-0">{error}</FormHelperText>
        )}
      </FormControl>
    );
  }
);
