import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "states/store";
import App from "App";
import reportWebVitals from "reportWebVitals";
import "./styles/index.scss";
import { datadogLogs } from "@datadog/browser-logs";

datadogLogs.init({
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || "",
  site: "us5.datadoghq.com",
  forwardErrorsToLogs: true,
  forwardConsoleLogs: "all",
  service: "dcp360-ui-" + process.env.REACT_APP_ENVIRONMENT,
  env: process.env.REACT_APP_ENVIRONMENT,
});

const container = document.getElementById("root")!;
const root = createRoot(container);
// requestInterceptor;
// responseInterceptor;

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
