import { Alert } from '@mui/material';
import { RequestStatus } from 'core/enums/request-status.enum';
import { useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'states/hooks';
import { getPlaidLinkTokenAsync, grantPlaidPermissionAsync } from 'states/onboardingSlice';
import { Button } from 'common/components';
import BankAccountForm from './BankAccountForm';
import { getEmployeeProfileAsync, selectEmployeeProfile } from 'states/manageEmployee/manageEmployeeSlice';
// import { useAuth } from "context/AuthProvider";
import { useForm } from 'react-hook-form';
import { BankAccountDto } from 'core/models/companyDto.model';
import BasicModal from '../BasicModal';
import { LoadingComponent } from 'components/employerManagePlan/LoadingComponent';

type Props = {};

interface IBankAccountFormProps {
  bankAccount: BankAccountDto;
}

const DEFAULT_VALUES = {
  bankAccount: {
    bankAccountType: '',
    bankRoutingNumber: '',
    bankAccountNumber: '',
    bankName: '',
  },
};

const ConnectPlaidEmployee = (props: Props) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [plaidConnected, setPlaidConnected] = useState(true);
  const dispatch = useAppDispatch();
  const location = useLocation();
  // const auth = useAuth();
  const plaidLinkToken = useAppSelector((state) => state.onboarding.plaidLinkToken);
  const loadingGetToken = useAppSelector((state) => state.onboarding.loading);
  const profile = useAppSelector(selectEmployeeProfile);
  const isSystemAdmin = false;
  const form = useForm<Partial<IBankAccountFormProps>>({
    defaultValues: DEFAULT_VALUES,
  });
  const [initLinkToken, setInitLinkToken] = useState(false);
  const { reset } = form;

  const handlePlaidSuccess = async (public_token: any, metadata: any) => {
    const response = await dispatch(
      grantPlaidPermissionAsync({
        publicToken: public_token,
        institutionId: metadata.institution?.institution_id as string,
        institutionName: metadata.institution?.name as string,
        accountId: metadata.account_id as string,
      })
    );
    if (response.meta.requestStatus === RequestStatus.FULFILLED) {
      dispatch(getEmployeeProfileAsync());
      setPlaidConnected(true);
    }
    setIsModalOpen(false);
  };

  const config = {
    token: plaidLinkToken.linkToken || '',
    onSuccess: handlePlaidSuccess,
  };

  const formProps = {
    form,
    isSystemAdmin,
  };

  const { open } = usePlaidLink(config);

  useEffect(() => {
    if (profile && profile?.bankAccount) {
      setPlaidConnected(true);
      reset({
        bankAccount: {
          bankRoutingNumber: profile?.bankAccount?.bankRoutingNumber,
          bankAccountNumber: profile?.bankAccount?.bankAccountNumber,
          bankName: profile?.bankAccount?.bankName,
        },
      });
    } else {
      setPlaidConnected(false);
    }
  }, [profile]);

  const handleOpen = async () => {
    if (!plaidLinkToken.linkToken) {
      const res = await dispatch(getPlaidLinkTokenAsync({ filter: { suffixUri: location.pathname } }));
      if (res.meta.requestStatus === RequestStatus.FULFILLED) {
        setInitLinkToken(true);
      }
    } else {
      open();
    }
  };

  useEffect(() => {
    if (initLinkToken) {
      open();
      setInitLinkToken(false);
    }
  }, [initLinkToken]);

  return (
    <>
      <h2 className="text-xl font-bold text-primary mb-1">Bank Details</h2>
      {plaidConnected ? (
        <>
          <Alert>
            Successfully verified bank account info. We will reach out to you if any further action is needed.
          </Alert>
          <Button
            className="py-4 space-x-2 mt-4"
            variant="outlined"
            color="secondary"
            classes={{ root: 'w-[300px]' }}
            type="button"
            onClick={() => setIsModalOpen(true)}
          >
            Connect New Bank Account
          </Button>
          <div className="mt-4">
            <BankAccountForm {...formProps} />
          </div>
        </>
      ) : (
        <>
          <p className="text-sm">
            At Saveday we use Plaid to verify your bank account information. Click connect and follow the prompts to
            complete the verification.{' '}
            <span
              className="font-medium text-textLink hover:text-textLinkHover hover:cursor-pointer"
              onClick={handleOpen}
            >
              Verify your Account
            </span>
          </p>
        </>
      )}
      <BasicModal isOpen={isModalOpen} handleClose={() => setIsModalOpen(false)} className="w-full max-w-[450px]">
        <div className="text-sm text-center text-darkest">
          In order to connect to a new bank account, we’ll have to disconnect from your current bank account. Would you
          like to proceed?
        </div>
        <div className="flex items-center justify-center gap-4 mt-6">
          <Button variant="outlined" color="secondary" onClick={() => setIsModalOpen(false)}>
            Cancel
          </Button>
          <Button variant="contained" color="secondary" onClick={handleOpen}>
            Proceed
          </Button>
        </div>
      </BasicModal>
      <LoadingComponent isLoading={loadingGetToken} />
    </>
  );
};

export default ConnectPlaidEmployee;
