import {
  FormControl as MuiFormControl,
  FormControlProps as MuiFormControlProps,
  FormLabel,
  FormLabelProps,
} from '@mui/material';
import clsx from 'clsx';
import { ReactNode } from 'react';

type FormControlProps = {
  label?: ReactNode;
  classes?: MuiFormControlProps['classes'] & {
    label?: string;
  };
  formLabelProps?: FormLabelProps;
} & MuiFormControlProps;

export const FormControl = ({
  label,
  children,
  className,
  id,
  classes,
  formLabelProps,
  ...props
}: FormControlProps) => {
  return (
    <MuiFormControl fullWidth className={clsx('space-y-2', className)} {...props}>
      {label && (
        <FormLabel
          htmlFor={id}
          className={clsx('mx-4 text-xs font-bold text-superBlack', classes?.label)}
          {...formLabelProps}
        >
          {label}
        </FormLabel>
      )}
      {children}
    </MuiFormControl>
  );
};
