import { Grid, Paper } from "@mui/material";
import PortfolioSelection from "components/employeeDashboard/PortfolioSelection";
import { RateAndGains } from "components/employeeDashboard/RateAndGains";
import { AunualLimit } from "components/employeeDashboard/AunualLimit";
import TraditionalRoth from "components/employeeDashboard/TraditionalRoth";
import TotalContributionByAccount from "components/employeeDashboard/TotalContributionByAccount";
import EmployeeNotificationRealeaseModal from "components/employeeReleaseFeedback/EmployeeNotificationRealeaseModal";
import { useEffect } from "react";
import { useAppDispatch } from "states/hooks";
import { getProfileDashBoardInfoAsync } from "states/manageEmployee/manageEmployeeSlice";
import TotalContributionsChart from "components/employeeDashboard/TotalContributionsChart";
import PlanAssets from "components/employeeDashboard/PlanAssets";
import TotalRothTradBalanceChart from "components/employeeDashboard/TotalRothTradBalanceChart";

const EmployeeDashboardV2Page = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getProfileDashBoardInfoAsync());
  }, []);
  return (
    <div className="flex flex-col">
      <Paper
        elevation={4}
        sx={{
          margin: { zero: 1.5, xs: 3.5 },
          padding: { zero: 2, xs: 3 },
          border: "5px",
        }}
      >
        <Grid container spacing={1}>
          <Grid item zero={12} lg={5}>
            <Grid container>
              <Grid item zero={12} mb={2.5}>
                <PlanAssets />
                <TotalRothTradBalanceChart />
              </Grid>
              <Grid item zero={12}>
                <TotalContributionsChart />
              </Grid>
            </Grid>
          </Grid>
          <Grid item zero={12} lg={7}>
            <TotalContributionByAccount />
          </Grid>
          <Grid item zero={12} sm={6}>
            <PortfolioSelection />
          </Grid>
          <Grid item zero={12} sm={6}>
            <TraditionalRoth />
          </Grid>
          <Grid item zero={12} sm={6}>
            <RateAndGains />
          </Grid>
          <Grid item zero={12} sm={6}>
            <AunualLimit />
          </Grid>
        </Grid>
      </Paper>
      <EmployeeNotificationRealeaseModal />
    </div>
  );
};

export default EmployeeDashboardV2Page;
