import {
  Button,
  PageHeader,
  RadioButtonGroup,
  Select,
  TaxBracketTableModal,
  DialogContainer,
  Tooltip,
} from 'common/components';
import {
  EmployeePaycheck,
  BaseEmployeeContributionsRange,
  BaseTotalContribution,
} from 'components/employeeContributions';
import { AuthContextType, useAuth } from 'contexts/AuthProvider';
import { EmployeeContributionDto } from 'core/models/EmployeeContributionDto.model';
import { EmployeeQuestionnairesDto } from 'core/models/EmployeeQuestionnairesDto.model';
import { CompanyDto } from 'core/models/companyDto.model';
import { getCompanyDetails, getProfile, updateProfile } from 'core/services/api.service';
import { getContributions, getQuestionnaires } from 'core/services/employee.service';
import { useDialog } from 'hooks/useDialog';
import { formatPercent } from 'utils/number';
import { formatCurrency } from 'core/functions/format-currency';
import { IAnswers, buildAnswers } from 'utils/processData';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getActiveAnswerAsync, updateActiveAnswerAsync } from 'states/employeeAccountsSlice';
import { useAppDispatch, useAppSelector } from 'states/hooks';
import { setCookie } from 'core/services/cookie.service';
import { CookieNames } from 'core/enums/cookie-names.enum';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { EmployeeProfileDto } from 'core/models/employeeDto.model';
import { setErrorMessage } from 'states/snackbarMessage/snackbarMessageSlice';
import { RadioGroupType } from 'core/enums/radio-group-type.enum';

const AUTO_ESCALATIONS_OPTIONS = [
  { value: 0.01, label: '1%' },
  { value: 0.02, label: '2%' },
  { value: 0.03, label: '3%' },
];

const RADIO_OPTIONS = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
];

const EmployeeContributions = () => {
  const { openDialog } = useDialog();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [radioValue, setRadioValue] = useState('no');
  const [selectedValue, setSelectedValue] = useState<number | undefined>(0.01);
  const [userProfile, setProfile] = useState<EmployeeProfileDto>();
  const [data, setData] = useState<{
    contributions?: EmployeeContributionDto;
    questionnaires?: EmployeeQuestionnairesDto;
    company?: CompanyDto;
    currentRothAmount?: number;
    currentTraditionalAmount?: number;
  }>();

  const RenderTitle = () => {
    return (
      <div className="p-2">
        Want a truly hands-off approach? Saveday recommends you take advantage of automatic escalation, where we
        increase your contribution 1%, 2% or 3% every year on your Saveday anniversary until you reach the maximum
        amount allowed of 10%. If you prefer not to do this, you can proceed without checking the box.
      </div>
    );
  };

  const onSubmit = async () => {
    openDialog((close) => {
      return (
        <>
          <DialogContainer
            close={close}
            className="max-w-md space-x-4 text-center"
            renderActions={(close) => {
              return (
                <>
                  <Button className="submit-button" variant="outlined" color="secondary" onClick={close}>
                    Cancel
                  </Button>
                  <Button
                    className="submit-button"
                    variant="contained"
                    color="secondary"
                    onClick={async () => {
                      const { error } = (await confirmAnswers()) as any;
                      if (!error) {
                        const params = {
                          autoEscalate: radioValue === 'yes',
                          id: activeAnswer.userProfileId,
                          planType: answers.planType.value,
                          autoEscalatePercentage: selectedValue,
                        };
                        await updateProfile(params);
                        setCookie(CookieNames.UserProfileStatus, 'ACTIVE');
                        navigate('/dashboard/employee');
                        close();
                      }
                    }}
                  >
                    YES
                  </Button>
                </>
              );
            }}
            children={
              <span className="text-darkest">
                Your contributions will be updated to{' '}
                {isNaN(answers.traditionalContribution.value)
                  ? ''
                  : `Traditional pre-tax: ${formatPercent(answers.traditionalContribution.value)}${
                      isNaN(answers.rothContribution.value) ? '' : ' and '
                    }`}
                {isNaN(answers.rothContribution.value)
                  ? ''
                  : `Roth post-tax: ${formatPercent(answers.rothContribution.value)}`}{' '}
                It may take at least 1-2 pay cycles for the changes to be reflected in your account.
              </span>
            }
          />
        </>
      );
    });
  };

  const fetch = async () => {
    const profile = await getProfile().then((res) => res.data);
    if (profile) {
      setProfile(profile);
      Promise.all([
        getContributions(String(profile.rothAccountId)),
        getQuestionnaires(),
        getCompanyDetails(profile.companyId),
      ]).then(([contributions, questionnaires, company]) => {
        setData({
          contributions,
          questionnaires,
          company: company.data,
          currentRothAmount: profile.rothContributionPercentage,
          currentTraditionalAmount: profile.traditionalContributionPercentage,
        });
      });
    }
  };

  const initValue = () => {
    const autoEscalate = userProfile?.autoEscalate ? 'yes' : 'no';
    const autoEscalatePercentage = userProfile?.autoEscalatePercentage ?? 0.01;
    if (autoEscalate) {
      setSelectedValue(autoEscalatePercentage);
    }
    setRadioValue(autoEscalate);
  };

  useEffect(() => {
    fetch();
  }, []);

  useEffect(() => {
    if (userProfile) {
      initValue();
    }
  }, [userProfile]);

  const dispatch = useAppDispatch();
  const employeeAccounts = useAppSelector((state) => state.employeeAccounts);
  const { session } = useAuth() as AuthContextType;

  useEffect(() => {
    if (session) {
      dispatch(getActiveAnswerAsync(session.currentUserProfileId));
    }
  }, []);

  const updateAnswers = (payload: any) => {
    const defaultPayload = {
      id: employeeAccounts.activeAnswer.id,
      ...payload,
    };
    return dispatch(updateActiveAnswerAsync(defaultPayload));
  };

  const sendAnswers = (payload: any) => {
    const defaultPayload = {
      id: employeeAccounts.activeAnswer.id,
      ...payload,
    };
    return dispatch(updateActiveAnswerAsync(defaultPayload));
  };

  const updateAnswersKeyValue = (key: number, value: any) => {
    const tmp = [];
    tmp[key] = value;
    updateAnswers({ answers: tmp });
  };

  const confirmAnswers = async () => {
    const editableAnswers = activeAnswer.answers.map((value: any, index: number) => {
      if (data?.questionnaires?.editable[index]) {
        return value;
      } else {
        return null;
      }
    });
    return await sendAnswers({ answers: editableAnswers, confirm: true });
  };

  let { activeAnswer } = employeeAccounts;
  if (!activeAnswer) {
    activeAnswer = {};
    activeAnswer.answers = Array(104).fill(0);
  }

  let { savedAnswer } = employeeAccounts;
  if (!savedAnswer) {
    savedAnswer = {};
    savedAnswer.answers = Array(104).fill(0);
  }

  const answers: IAnswers = buildAnswers(activeAnswer.answers);

  const preTaxAmountInt =
    answers.planType.value === RadioGroupType.TRADITIONAL || answers.planType.value === RadioGroupType.BOTH
      ? answers.grossPay.value - answers.traditionalContributionAmount.value
      : answers.grossPay.value;

  const postTaxAmountInt = preTaxAmountInt - answers.federalTaxAmount.value - answers.stateTaxAmount.value;

  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (data && employeeAccounts.activeAnswer.id) {
      setIsReady(true);
    }
  }, [data, employeeAccounts.activeAnswer]);

  useEffect(() => {
    if (isReady) {
      const tmp = [];
      if (
        data &&
        typeof data.currentRothAmount !== 'undefined' &&
        data?.currentRothAmount.toString() !== activeAnswer.answers[78]
      ) {
        tmp[78] = data?.currentRothAmount.toString();
      }
      if (
        data &&
        typeof data.currentTraditionalAmount !== 'undefined' &&
        data?.currentTraditionalAmount.toString() !== activeAnswer.answers[79]
      ) {
        tmp[79] = data?.currentTraditionalAmount.toString();
      }
      if (tmp.length > 0 && employeeAccounts.activeAnswer !== null) {
        const defaultPayload = {
          id: employeeAccounts.activeAnswer.id,
          ...{ answers: tmp },
        };
        dispatch(updateActiveAnswerAsync(defaultPayload));
      }
    }
  }, [isReady]);

  const basecontributionRangeProps = {
    answers,
    updateAnswersKeyValue,
  };

  const handleDisabled = useMemo(() => {
    let obj = {
      traditional: true,
      roth: true,
    };

    if (answers) {
      if (answers.companyPlanType.value === RadioGroupType.TRADITIONAL) {
        return {
          ...obj,
          traditional: false,
        };
      }
      if (answers.planType.value === RadioGroupType.TRADITIONAL) {
        return {
          ...obj,
          traditional: false,
        };
      }

      if (answers.planType.value === RadioGroupType.ROTH) {
        return {
          ...obj,
          roth: false,
        };
      }
      return {
        traditional: false,
        roth: false,
      };
    }
    return obj;
  }, [answers]);

  const traditionalProps = {
    ...basecontributionRangeProps,
    title: 'Traditional 401(K)',
    percentageProps: {
      value: +answers.traditionalContribution.value * 100,
      max: (1 - answers.rothContribution.value) * 100,
      keyvalue: answers.traditionalContribution.key,
    },
    currencyProps: {
      value: +answers.traditionalContributionAmount.value
        ? formatCurrency(answers.traditionalContributionAmount.value, 0)
        : 0,
      keyvalue: answers.traditionalContribution.key,
    },
    disabled: handleDisabled.traditional,
  };

  const rothProps = {
    ...basecontributionRangeProps,
    title: 'Roth 401(K)',
    percentageProps: {
      value: +answers.rothContribution.value * 100,
      max: (1 - answers.traditionalContribution.value) * 100,
      keyvalue: answers.rothContribution.key,
    },
    currencyProps: {
      value: +answers.rothContributionAmount.value ? formatCurrency(answers.rothContributionAmount.value, 0) : 0,
      keyvalue: answers.rothContribution.key,
    },
    disabled: handleDisabled.roth,
  };
  const traditionalTotalProps = {
    value: formatCurrency(+(answers?.paycheckContribution?.value ?? 0), 0),
    bgColor: 'lightGreen',
    title: 'Total contribution',
  };

  const rothTotalProps = {
    value: formatCurrency(
      +((answers?.paycheckContribution?.value ?? 0) + (answers?.employerMatch?.value ?? 0)) *
        answers?.payrollFrequency?.value ?? 0,
      0
    ),
    title: 'In one year you will have saved, including any employer match',
    bgColor: 'lightGreen',
  };

  const RenderSubtitle = () => {
    return (
      <>
        <span className="mr-1 text-body-13 text-deepGrey">
          You can contribute up to $23,000.00 per year, and if you are over 50 years old it is $30,500.00 or 100% of
          your pay, whichever is less. You have the flexibility to select from multiple retirement savings options,
          including a Traditional 401(k), Roth 401(k), or a combination of both. If you are unable to choose any of
          these options, it simply means that your employer has opted out of providing that particular form of
          contribution.
        </span>
        <span
          className="cursor-pointer text-textLink hover:text-textLinkHover text-body-13"
          onClick={() => setIsOpen(true)}
        >
          To learn more about the differences between Traditional 401(k) and Roth 401(k) please click here
        </span>
      </>
    );
  };

  const onRadioChange = (e: any) => {
    const answer = e.target.value;
    let autoEscalatePercentage = undefined;
    if (answer === 'yes') {
      autoEscalatePercentage = userProfile?.autoEscalatePercentage ?? 0.01;
    }
    setSelectedValue(autoEscalatePercentage);
    setRadioValue(answer);
  };

  return (
    <>
      <PageHeader
        pageTitle="Contributions"
        subTitle={<RenderSubtitle />}
        // subTitleClasses="max-w-[850px]"
      />
      <div className="mx-8">
        <div className="flex flex-col gap-2 text-sm">
          <p className="font-bold text-primary">What amount would you like to contribute towards your retirement?</p>
        </div>
        <div className="flex gap-4 mt-8 xs:flex-row zero:flex-col">
          <div className="flex-1">
            <BaseEmployeeContributionsRange {...traditionalProps} />
            <BaseEmployeeContributionsRange {...rothProps} />
          </div>

          <div className="flex-1">
            <BaseTotalContribution {...traditionalTotalProps} />
            <BaseTotalContribution {...rothTotalProps} />
          </div>
        </div>
        <div className="flex items-center justify-between zero:flex-col xs:flex-row">
          {/* Auto-escalation now requires both auto-enroll and auto-escalate to be true */}
          {data?.company?.planIsAutoEnroll && data?.company?.planIsAutoEscalate && (
            <div className="flex items-center w-full zero:gap-2 zero:flex-col xs:flex-row xs:gap-4">
              <div className="flex gap-2">
                <p className="text-sm font-bold text-primary">Would you like to have auto-escalation on?</p>
                <Tooltip title={<RenderTitle />}>
                  <InfoOutlinedIcon className="w-5 text-beige" />
                </Tooltip>
              </div>
              <RadioButtonGroup
                row
                classes="radio-button-group flex w-auto"
                itemClasses="mr-8"
                onChange={onRadioChange}
                options={RADIO_OPTIONS}
                value={radioValue}
              />
              {radioValue === 'yes' && (
                <div className="zero:w-full zero:mb-4 xs:w-[150px] xs:mb-0">
                  <Select
                    value={selectedValue}
                    data={AUTO_ESCALATIONS_OPTIONS}
                    onChange={(e) => {
                      setSelectedValue(e.target.value);
                    }}
                  />
                </div>
              )}
            </div>
          )}
          <Button
            onClick={() => {
              if (parseFloat(answers.traditionalContribution.value) + parseFloat(answers.rothContribution.value) > 1) {
                dispatch(setErrorMessage('Contributions total must be below 100%'));
              } else {
                onSubmit();
              }
            }}
            className="max-w-full rounded-lg zero:w-full xs:w-auto xs:max-w-auto"
            color="secondary"
            variant="contained"
          >
            Save
          </Button>
        </div>
      </div>
      <EmployeePaycheck answers={answers} preTaxAmountInt={preTaxAmountInt} postTaxAmountInt={postTaxAmountInt} />
      <TaxBracketTableModal isOpen={isOpen} handleClose={() => setIsOpen(false)} />
    </>
  );
};

export default EmployeeContributions;
