import CloseIcon from "@mui/icons-material/Close";
import { Alert, AlertColor, IconButton } from "@mui/material";
import { CustomContentProps, closeSnackbar } from "notistack";
import React from "react";

enum AlertVariant {
  success = "success",
  warning = "warning",
  error = "error",
}

const CustomAlert = React.forwardRef((props: CustomContentProps, ref: any) => {
  let color = "";
  let backgroundColor = "";
  switch (props.variant) {
    case AlertVariant.error:
      color = "#EA0B5B";
      backgroundColor = "#FFD9EA";
      break;
    case AlertVariant.warning:
      color = "#EDA814";
      backgroundColor = "#FEF5E1";
      break;
    case AlertVariant.success:
      color = "#25AC65";
      backgroundColor = "#E2F3EA";
      break;
  }
  return (
    <Alert
      ref={ref}
      severity={props.variant as AlertColor}
      style={props.style}
      sx={{
        color: color,
        backgroundColor: backgroundColor,
        "& .MuiSvgIcon-root": {
          color: color,
        },
      }}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => closeSnackbar(props.id)}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
    >
      {props?.message}
    </Alert>
  );
});

export default CustomAlert;
