export enum ROLES {
  PARTICIPANT = 'PARTICIPANT',
  COMPANY_ADMIN = 'COMPANY_ADMIN',
  SELECT_ROLE = 'SELECT_ROLE',
  SYSTEM_ADMIN = 'SYSTEM_ADMIN',
  CPA_ADMIN = 'CPA_ADMIN',
  PEP_ADMIN = 'PEP_ADMIN',
  CORP_ADMIN = 'CORP_ADMIN',
}

export enum REQUEST_STATUS {
  IDLE = 'idle',
  SENDING = 'sending',
  ERROR = 'error',
  SUCCESS = 'success',
}

export enum VERIFY_2FA_ACTION {
  ADD_2FA = 'ADD_2FA',
  REMOVE_2FA = 'REMOVE_2FA',
  SIGN_IN = 'SIGN_IN',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  RECOVERY_EMAIL = 'RECOVERY_EMAIL',
  RECOVERY_ACCOUNT = 'RECOVERY_ACCOUNT',
}

export enum TWO_FA_STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum TWO_FA_METHOD {
  SMS = 'SMS',
  AUTH_APP = 'AUTH_APP',
  BACKUP_CODE = 'BACKUP_CODE',
}
