export interface StateData {
  value: string;
  label: string;
}

export const STATE_DATA: StateData[] = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'District Of Columbia' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WI', label: 'Wisconsin' },
  { value: 'WY', label: 'Wyoming' },
];

export const getStateName = (code: any) => {
  const state = STATE_DATA.find((item) => item.value === code);
  return state?.label || '';
};

export enum IndustryEnum {
  AGRICULTURE_FORESTRY_HUNTING_FISHING = 'AGRICULTURE_FORESTRY_HUNTING_FISHING',
  MINING = 'MINING',
  UTILITIES = 'UTILITIES',
  CONSTRUCTION = 'CONSTRUCTION',
  MANUFACTURING = 'MANUFACTURING',
  WHOLESALE_TRADE = 'WHOLESALE_TRADE',
  RETAIL_TRADE = 'RETAIL_TRADE',
  TRANSPORTATION_AND_WAREHOUSING = 'TRANSPORTATION_AND_WAREHOUSING',
  INFORMATION = 'INFORMATION',
  DATA_PROCESSING_SERVICES = 'DATA_PROCESSING_SERVICES',
  REAL_ESTATE_AND_RENTAL_LEASING = 'REAL_ESTATE_AND_RENTAL_LEASING',
  PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_SERVICES = 'PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_SERVICES',
  MANAGEMENT_OF_COMPANIES_AND_ENTERPRISES = 'MANAGEMENT_OF_COMPANIES_AND_ENTERPRISES',
  WASTE_MANAGEMENT_AND_REMEDIATION_SERVICES = 'WASTE_MANAGEMENT_AND_REMEDIATION_SERVICES',
  EDUCATIONAL_SERVICES = 'EDUCATIONAL_SERVICES',
  HEALTHCARE_AND_SOCIAL_ASSISTANCE = 'HEALTHCARE_AND_SOCIAL_ASSISTANCE',
  ARTS_ENTERTAINMENT_AND_RECREATION = 'ARTS_ENTERTAINMENT_AND_RECREATION',
  ACCOMMODATION_AND_FOOD_SERVICES = 'ACCOMMODATION_AND_FOOD_SERVICES',
  OTHER_SERVICES = 'OTHER_SERVICES',
  FINANCE_AND_INSURANCE = 'FINANCE_AND_INSURANCE',
}

export const INDUSTRY_LIST = [
  {
    label: 'Agriculture, forestry, hunting and fishing',
    value: 'AGRICULTURE_FORESTRY_HUNTING_FISHING',
  },
  {
    label: 'Mining',
    value: 'MINING',
  },
  {
    label: 'Utilities',
    value: 'UTILITIES',
  },
  {
    label: 'Construction',
    value: 'CONSTRUCTION',
  },
  {
    label: 'Manufacturing',
    value: 'MANUFACTURING',
  },
  {
    label: 'Wholesale Trade',
    value: 'WHOLESALE_TRADE',
  },
  {
    label: 'Retail Trade',
    value: 'RETAIL_TRADE',
  },
  {
    label: 'Transportation & Warehousing',
    value: 'TRANSPORTATION_AND_WAREHOUSING',
  },
  {
    label: 'Information',
    value: 'INFORMATION',
  },
  {
    label: 'Data Processing Services',
    value: 'DATA_PROCESSING_SERVICES',
  },
  {
    label: 'Finance And Insurance',
    value: 'FINANCE_AND_INSURANCE',
  },
  {
    label: 'Real Estate & Rental Leasing',
    value: 'REAL_ESTATE_AND_RENTAL_LEASING',
  },
  {
    label: 'Professional, Scientific, and Technical Services',
    value: 'PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_SERVICES',
  },
  {
    label: 'Management of Companies and Enterprises',
    value: 'MANAGEMENT_OF_COMPANIES_AND_ENTERPRISES',
  },
  {
    label: 'Administrative and Support Services',
    value: 'ADMINISTRATIVE_AND_SUPPORT_SERVICES',
  },
  {
    label: 'Waste Management and Remediation Services',
    value: 'WASTE_MANAGEMENT_AND_REMEDIATION_SERVICES',
  },
  {
    label: 'Educational Services',
    value: 'EDUCATIONAL_SERVICES',
  },
  {
    label: 'Healthcare and Social Assistance',
    value: 'HEALTHCARE_AND_SOCIAL_ASSISTANCE',
  },
  {
    label: 'Arts, Entertainment, and Recreation',
    value: 'ARTS_ENTERTAINMENT_AND_RECREATION',
  },
  {
    label: 'Accommodation and Food Services',
    value: 'ACCOMMODATION_AND_FOOD_SERVICES',
  },
  {
    label: 'Other Services',
    value: 'OTHER_SERVICES',
  },
];

export const BUSINESS_CODE_LIST = {
  AGRICULTURE_FORESTRY_HUNTING_FISHING: [
    {
      label: '110000 - Agriculture, forestry, hunting and fishing',
      value: '110000',
    },
    {
      label: '111000 - Crop production',
      value: '111000',
    },
  ],
  MINING: [
    {
      label: '211110 - Oil and gas extraction',
      value: '211110',
    },
    {
      label: '211120 - Crude petroleum extraction',
      value: '211120',
    },
    {
      label: '211130 - Natural gas extraction',
      value: '211130',
    },
    {
      label: '212000 - Mining (except oil and gas)',
      value: '212000',
    },
  ],
  UTILITIES: [
    {
      label: '221000 - Utilities',
      value: '221000',
    },
  ],
  CONSTRUCTION: [
    {
      label: '230000 - Construction',
      value: '230000',
    },
    {
      label: '236000 - Construction of buildings',
      value: '236000',
    },
  ],
  MANUFACTURING: [
    {
      label: '310000 - Manufacturing',
      value: '310000',
    },
    {
      label: '323100 - Printing and related support activities',
      value: '323100',
    },
    {
      label: '339110 - Medical equipment and supplies manufacturing',
      value: '339110',
    },
  ],
  WHOLESALE_TRADE: [
    {
      label: '423000 - Merchant wholesalers, durable goods',
      value: '423000',
    },
    {
      label: '424000 - Merchant wholesalers, nondurable goods',
      value: '424000',
    },
  ],
  RETAIL_TRADE: [
    {
      label: '441100 - Automobile dealers',
      value: '441100',
    },
    {
      label: '442000 - Furniture and home furnishings stores',
      value: '442000',
    },
    {
      label: '444100 - Building materials and supplies dealers',
      value: '444100',
    },
    {
      label: '445100 - Grocery stores',
      value: '445100',
    },
    {
      label: '445200 - Specialty food stores',
      value: '445200',
    },
    {
      label: '446110 - Pharmacies and drug stores',
      value: '446110',
    },
    {
      label: '446199 - All other health and personal care stores',
      value: '446199',
    },
    {
      label: '448000 - Clothing and clothing accessories stores',
      value: '448000',
    },
    {
      label: '451110 - Sporting goods stores',
      value: '451110',
    },
    {
      label: '451211 - Book stores',
      value: '451211',
    },
    {
      label: '452000 - General merchandise stores',
      value: '452000',
    },
    {
      label: '453000 - Miscellaneous store retailers',
      value: '453000',
    },
    {
      label: '453220 - Gift, novelty, and souvenir stores',
      value: '453220',
    },
    {
      label: '453310 - Used merchandise stores',
      value: '453310',
    },
    {
      label: '454110 - Electronic shopping and mail-order houses',
      value: '454110',
    },
  ],
  TRANSPORTATION_AND_WAREHOUSING: [
    {
      label: '480000 - Transportation',
      value: '480000',
    },
    {
      label: '485000 - Transit and ground passenger transportation',
      value: '485000',
    },
    {
      label: '493000 - Warehousing and storage',
      value: '493000',
    },
  ],
  INFORMATION: [
    {
      label: '511110 - Newspaper publishers (except Internet)',
      value: '511110',
    },
    {
      label: '511120 - Periodical publishers (except Internet)',
      value: '511120',
    },
    {
      label: '511130 - Book publishers (except Internet)',
      value: '511130',
    },
    {
      label: '511140 - Directory and mailing list publishers (except Internet)',
      value: '511140',
    },
    {
      label: '511190 - Other publishers (except Internet)',
      value: '511190',
    },
    {
      label: '512000 - Motion picture and sound recording industries',
      value: '512000',
    },
    {
      label: '515100 - Radio and television broadcasting (except Internet)',
      value: '515100',
    },
    {
      label:
        '517000 - Telecommunications (including paging, cellular, satellite, cable, other telecommunications, and Internet service providers)',
      value: '517000',
    },
    {
      label: '519100 - Other information services (including news syndicates and libraries)',
      value: '519100',
    },
    {
      label: '519130 - Internet Publishing and Broadcast',
      value: '519130',
    },
  ],
  DATA_PROCESSING_SERVICES: [
    {
      label: '518210 - Data Processing, Hosting, and Related Services',
      value: '518210',
    },
  ],
  FINANCE_AND_INSURANCE: [
    {
      label: '523000 - Securities, commodity contracts, and other financial investments, and related activities',
      value: '523000',
    },
    {
      label: '523920 - Portfolio management',
      value: '523920',
    },
    {
      label: '523930 - Investment advice',
      value: '523930',
    },
    {
      label: '524113 - Direct life insurance carriers',
      value: '524113',
    },
    {
      label: '524114 - Direct health and medical insurance carriers',
      value: '524114',
    },
    {
      label: '524126 - Direct property and casualty insurance carriers',
      value: '524126',
    },
    {
      label: '524130 - Reinsurance carriers',
      value: '524130',
    },
    {
      label: '524292 - Third-party administration of insurance and pension funds',
      value: '524292',
    },
    {
      label: '524298 - All other insurance-related activities',
      value: '524298',
    },
    {
      label: '525100 - Insurance and employee benefit funds',
      value: '525100',
    },
    {
      label: '525920 - Trusts, estates, and agency accounts',
      value: '525920',
    },
    {
      label: '525990 - Other Financial vehicles (including mortgage REITs)',
      value: '525990',
    },
  ],
  REAL_ESTATE_AND_RENTAL_LEASING: [
    {
      label: '531110 - Lessors of residential buildings and dwellings (including equity REITs)',
      value: '531110',
    },
    {
      label: '531120 - Lessors of nonresidential buildings (except minwarehouses) (including equity REITs)',
      value: '531120',
    },
    {
      label: '531190 - Lessors of other real estate property (including equity REITs)',
      value: '531190',
    },
    {
      label: '531310 - Real estate property managers',
      value: '531310',
    },
    {
      label: '531390 - Other activities related to realestate',
      value: '531390',
    },
    {
      label: '532000 - Rental and leasing services',
      value: '532000',
    },
    {
      label: '532420 - Office machinery and equipment rental and leasing',
      value: '532420',
    },
    {
      label: '533110 - Lessors of nonfinancial intangible assets (except copyrighted works)',
      value: '533110',
    },
  ],
  PROFESSIONAL_SCIENTIFIC_AND_TECHNICAL_SERVICES: [
    {
      label: '541300 - Architectural, engineering, and related services',
      value: '541300',
    },
    {
      label: '541400 - Specialized design services',
      value: '541400',
    },
    {
      label: '541500 - Surveying and mapping services',
      value: '541500',
    },
    {
      label: '541600 - Geophysical and seismic surveying services',
      value: '541600',
    },
    {
      label: '541700 - Scientific research and development services',
      value: '541700',
    },
    {
      label: '541800 - Laboratory testing services',
      value: '541800',
    },
    {
      label: '541910 - Marketing research and public opinion polling',
      value: '541910',
    },
    {
      label: '541920 - Management consulting services',
      value: '541920',
    },
    {
      label: '541930 - Environmental consulting services',
      value: '541930',
    },
    {
      label: '541940 - Other scientific and technical consulting services',
      value: '541940',
    },
    {
      label: '541990 - All other professional, scientific, and technical services',
      value: '541990',
    },
  ],
  MANAGEMENT_OF_COMPANIES_AND_ENTERPRISES: [
    {
      label: '551111 - Offices of bank holding companies',
      value: '551111',
    },
    {
      label: '551112 - Offices of other holding companies',
      value: '551112',
    },
  ],
  ADMINISTRATIVE_AND_SUPPORT_SERVICES: [
    {
      label: '561000 - Administrative and support services',
      value: '561000',
    },
    {
      label: '561300 - Employment services',
      value: '561300',
    },
    {
      label: '561439 - Other business service centers',
      value: '561439',
    },
    {
      label: '561499 - All other business support services',
      value: '561499',
    },
    {
      label: '561500 - Travel arrangement and reservation services',
      value: '561500',
    },
    {
      label: '561520 - Tour operators',
      value: '561520',
    },
    {
      label: '561700 - Services to buildings and dwellings',
      value: '561700',
    },
  ],
  WASTE_MANAGEMENT_AND_REMEDIATION_SERVICES: [
    {
      label: '562000 - Waste management and remediation services (sanitary services)',
      value: '562000',
    },
  ],
  EDUCATIONAL_SERVICES: [
    {
      label: '611420 - Computer training',
      value: '611420',
    },
    {
      label: '611430 - Professional and management development training',
      value: '611430',
    },
    {
      label: '611600 - Other schools and instruction',
      value: '611600',
    },
    {
      label: '611710 - Educational support services',
      value: '611710',
    },
  ],
  HEALTHCARE_AND_SOCIAL_ASSISTANCE: [
    {
      label: '621110 - Offices of physicians',
      value: '621110',
    },
    {
      label: '621300 - Offices of other health practitioners',
      value: '621300',
    },
    {
      label: '621400 - Outpatient care centers',
      value: '621400',
    },
    {
      label: '621500 - Medical and diagnostic laboratories',
      value: '621500',
    },
    {
      label: '621610 - Home health care services',
      value: '621610',
    },
    {
      label: '621910 - Ambulance services',
      value: '621910',
    },
    {
      label: '621990 - All other ambulatory health care services',
      value: '621990',
    },
    {
      label: '623000 - Nursing and residential care facilities',
      value: '623000',
    },
    {
      label: '623990 - Other residential care facilities',
      value: '623990',
    },
    {
      label: '624100 - Individual and family services',
      value: '624100',
    },
    {
      label: '624110 - Community centers (except rec.only), youth Adoption agencies',
      value: '624110',
    },
    {
      label: '624200 - Community food and housing, and emergency and other relief services',
      value: '624200',
    },
    {
      label: '624210 - Meal delivery programs, Soup kitchens, or Food banks',
      value: '624210',
    },
    {
      label: '624310 - Vocational rehabilitation services',
      value: '624310',
    },
    {
      label: '624410 - Child day care services',
      value: '624410',
    },
  ],
  ARTS_ENTERTAINMENT_AND_RECREATION: [
    {
      label: '713110 - Amusement and theme parks',
      value: '713110',
    },
    {
      label: '713200 - Gambling industries',
      value: '713200',
    },
    {
      label: '713910 - Golf courses and country clubs',
      value: '713910',
    },
    {
      label: '713940 - Fitness and recreational sports centers',
      value: '713940',
    },
    {
      label:
        '713990 - All other amusement and recreation industries (including skiing facilities, marinas, and bowling centers)',
      value: '713990',
    },
  ],
  ACCOMMODATION_AND_FOOD_SERVICES: [
    {
      label: '721000 - Accommodation',
      value: '721000',
    },
    {
      label: '721110 - Hotels (except casino hotels) and motels',
      value: '721110',
    },
    {
      label: '721210 - RV (recreational vehicle) parks and recreational camps',
      value: '721210',
    },
    {
      label: '721310 - Rooming and boarding houses, dormitories, and workers’ camps',
      value: '721310',
    },
    {
      label: '722320 - Caterers',
      value: '722320',
    },
    {
      label: '722440 - Drinking places (alcoholic beverages)',
      value: '722440',
    },
    {
      label: '722511 - Full-service restaurants',
      value: '722511',
    },
    {
      label: '722513 - Limited-service restaurants',
      value: '722513',
    },
    {
      label: '722514 - Cafeterias and buffets',
      value: '722514',
    },
    {
      label: '722515 - Snack and non-alcoholic beverage bars',
      value: '722515',
    },
  ],
  OTHER_SERVICES: [
    {
      label: '811000 - Repair and maintenance',
      value: '811000',
    },
    {
      label: '812300 - Drycleaning and laundry services',
      value: '812300',
    },
    {
      label: '812900 - Other personal services',
      value: '812900',
    },
    {
      label: '812930 - Parking lots and garages',
      value: '812930',
    },
    {
      label: '900001 - Investment activities of section 501(c)(7), (9), or (17) organizations',
      value: '900001',
    },
    {
      label: '900002 - Rental of personal property',
      value: '900002',
    },
    {
      label: '900003 - Passive income activities with controlled organizations',
      value: '900003',
    },
  ],
};
