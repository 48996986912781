import { STATE_DATA } from './state-data';

interface SelectOption {
  id: string | number;
  label: string;
  value: string | number;
}

export const MARITAL_OPTIONS: SelectOption[] = [
  { id: 0, label: 'Select Marital Status', value: 'INITIAL' },
  { id: 1, label: 'Single', value: 'SINGLE' },
  { id: 2, label: 'Married', value: 'MARRIED' },
  { id: 3, label: 'Divorced', value: 'DIVORCED' },
  { id: 4, label: 'Widowed', value: 'WIDOWED' },
];

const STATE_OPTIONS: SelectOption[] = STATE_DATA.map((data, index) => {
  return {
    id: index,
    label: data.label,
    value: data.value,
  };
});

export const USSTATE_OPTIONS = [
  {
    id: STATE_OPTIONS.length,
    label: 'Select State',
    value: 'INITIAL',
  },
  ...STATE_OPTIONS,
];

export const SELECT_EMPLOYEE_STATUS = [
  { label: 'All', value: 'ALL' },
  { label: 'New', value: 'NEW' },
  { label: 'Invited', value: 'INVITED' },
  { label: 'Active', value: 'ACTIVE' },
  { label: 'Terminated', value: 'EMPLOYMENT_ENDED' },
];

export const SELECT_ACTION_EMPLOYEE_STATUS_AUTO_ENROLL = [
  { label: 'Reactivate Employee', value: 'reactivate' },
  { label: 'Resend Invite To Employee', value: 'invite' },
  { label: 'Terminate Employee', value: 'terminate' },
  { label: 'Opt Out Employee', value: 'optout' },
];

export const SELECT_ACTION_EMPLOYEE_STATUS_FINCH_AUTO_ENROLL = [
  { label: 'Resend Invite To Employee', value: 'invite' },
  { label: 'Opt Out Employee', value: 'optout' },
];

export const SELECT_ACTION_EMPLOYEE_STATUS = [
  { label: 'Reactivate Employee', value: 'reactivate' },
  { label: 'Resend Invite To Employee', value: 'invite' },
  { label: 'Terminate Employee', value: 'terminate' },
];

export const SELECT_ACTION_EMPLOYEE_STATUS_FINCH = [{ label: 'Resend Invite To Employee', value: 'invite' }];
