export enum PlanTypes {
  ELIGIBILITY_AND_CONTRIBUTION = "ELIGIBILITY_AND_CONTRIBUTION",
  EMPLOYEE_CONTRIBUTION = "EMPLOYEE_CONTRIBUTION",
  ENFORCE_YOUR_RIGHTS = "ENFORCE_YOUR_RIGHTS",
  ASSISTANCE_WITH_YOUR_QUESTIONS = "ASSISTANCE_WITH_YOUR_QUESTIONS",
  FEES = "FEES",
  HARDSHIP_WITHDRAWALS = "HARDSHIP_WITHDRAWALS",
  AMENDMENT_TERMINATION = "AMENDMENT_TERMINATION",
  TRADITIONAL_401_K_PLAN = "TRADITIONAL_401_K_PLAN",
  ROTH_401_K_PLAN = "ROTH_401_K_PLAN",
  DISCLAIMER = "DISCLAIMER",
  _401_K_DIRECT_ROLLOVER_FORM_DIRECTIONS = "401_K_DIRECT_ROLLOVER_FORM_DIRECTIONS",
  _401_K_EXPLANATION_OF_YOUR_ERISA_RIGHTS = "401_K_EXPLANATION_OF_YOUR_ERISA_RIGHTS",
  LOANS = "LOANS",
  HIGHLIGHTS = "HIGHLIGHTS",
  SUMMARY = "SUMMARY",
  BOOKLET = "BOOKLET",
  EXECUTION = "EXECUTION",
  BOTH = "BOTH",
  TRADITIONAL = "TRADITIONAL",
  ROTH = "ROTH",
  FILE_5500 = "FILE_5500",
  SAR = "SAR",
  QDIA = "QDIA",
  SAFE_HARBOR_NOTICE = "SAFE_HARBOR_NOTICE",
  TPAA_PLAN = "TPAA_PLAN",
  ERISA_338_IMA_PLAN = "ERISA_338_IMA_PLAN",
  ADOPTION_AGREEMENT = "ADOPTION_AGREEMENT",
}
