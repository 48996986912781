import { Grid } from '@mui/material';
import { TextField } from 'common/components';

interface IBankAccountForm {
  isSystemAdmin?: boolean | null;
  form: any;
}

function BankAccountForm(props: IBankAccountForm) {
  const { isSystemAdmin, form } = props;
  const {
    register,
    formState: { errors },
  } = form;

  return (
    <Grid container zero={12} spacing={2}>
      <Grid item zero={12} lg={4}>
        <TextField
          disabled={!isSystemAdmin}
          label="Bank routing"
          placeholder="Routing Number"
          {...register('bankAccount.bankRoutingNumber', {
            minLength: 9,
            maxLength: 9,
            required: true,
          })}
          error={errors?.bankAccount?.bankRoutingNumber && 'Bank routing number invalid'}
        />
      </Grid>
      <Grid item zero={12} lg={4}>
        <TextField
          disabled={!isSystemAdmin}
          label="Account Number"
          placeholder="Account Number "
          error={errors.bankAccount?.bankAccountNumber?.message}
          {...register('bankAccount.bankAccountNumber', {
            required: 'Bank account number required',
          })}
        />
      </Grid>
      <Grid item zero={12} lg={4}>
        <TextField
          disabled={!isSystemAdmin}
          placeholder="Bank Name "
          label="Bank Name"
          {...register('bankAccount.bankName', {
            required: 'Bank account name required',
          })}
          error={errors.bankAccount?.bankName?.message}
        />
      </Grid>
    </Grid>
  );
}

export default BankAccountForm;
