import React from "react";
import { useFormContext } from "react-hook-form";
import BaseRadioFormComp from "./BaseRadioFormComp";
import ManagerPlanBorder from "./ManageBorder";
import RadioComp from "./RadioComp";

export const reguatoryRadioName = {
  currentOrFormer: "regulatoryDisclosure.currentOrFormerPEPOfPublicOfficial",
  FFI31CFR: "regulatoryDisclosure.representFFI31CFR",
  FI31CFR103175: "regulatoryDisclosure.representFFI31CFR103175",
};

const RADIO_LIST = [
  {
    id: "1",
    description:
      "Are you a current or former politically exposed person of public official (Includes U.S. and foreign individuals)?",
    name: reguatoryRadioName.currentOrFormer,
  },
  {
    id: "2",
    description:
      "Do you represent a foreign financial institution (FFI) as defined by title 31 of the code of federal regulations?",
    name: reguatoryRadioName.FFI31CFR,
  },
  {
    id: "3",
    description:
      "Do you represent a foreign financial institution (FFI) as defined by 31 C.F.R 103.175(h)?",
    name: reguatoryRadioName.FI31CFR103175,
  },
];

const RegulatoryDisclosures = ({
  isEdit,
  isCreatePlan,
}: {
  isEdit: boolean;
  isCreatePlan?: boolean;
}) => {
  const { control } = useFormContext();

  return (
    <>
      <BaseRadioFormComp title="Regulatory Disclosures">
        <ManagerPlanBorder
          className={isCreatePlan ? "pointer-events-none" : ""}
        >
          {RADIO_LIST.map((item) => (
            <RadioComp {...item} key={item.id} control={control} />
          ))}
        </ManagerPlanBorder>
      </BaseRadioFormComp>
    </>
  );
};

export default RegulatoryDisclosures;
