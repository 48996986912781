import { Box, Typography, Button } from "@mui/material";
import BasicModal from "common/components/BasicModal";
import { FC } from "react";

type TCreateYourLoginErrorModalProps = {
  isOpen: boolean;
  onClose: () => void;
};
const CreateYourLoginErrorModal: FC<TCreateYourLoginErrorModalProps> = ({
  isOpen,
  onClose,
}) => {
  return (
    <BasicModal isOpen={isOpen} className="w-full max-w-[450px]">
      <Box>
        <Typography className="text-darkGreen text-xl font-bold text-center">
          OOPS!
        </Typography>
        <Typography className="text-primary text-sm font-normal mt-6 text-center">
          Your account must be associated with invited status to move forward.
          If you've already signed up please login or contact your company admin
          for further support.
        </Typography>
        <div className="mt-6 flex gap-4 items-center justify-center">
          <Button variant="contained" color="secondary" onClick={onClose}>
            Got it!
          </Button>
        </div>
      </Box>
    </BasicModal>
  );
};
export default CreateYourLoginErrorModal;
